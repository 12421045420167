import React from "react";
import BoostListing from "../../postpropertypage/skipPost/BoostListing";
import BoostListingstep2 from "../../postpropertypage/skipPost/BoostListingstep2";
import BoostListingStep3 from "../../postpropertypage/skipPost/BoostListingStep3";

const MainBoostingPage = ({
  bostingIndex,
  boostToggle,
  singlePropertyId,
  setChangeIndexValue,
  setselectBoostValue,
  selectBoost
}) => {
  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return (
          <BoostListing
            boostToggle={boostToggle}
            setChangeIndexValue={setChangeIndexValue}
          />
        );
      case 1:
        return (
          <BoostListingstep2
            boostToggle={boostToggle}
            setChangeIndexValue={setChangeIndexValue}
            setselectBoostValue={setselectBoostValue}
          />
        );
      case 2:
        return (
          <BoostListingStep3
            boostToggle={boostToggle}
            setChangeIndexValue={setChangeIndexValue}
            setselectBoostValue={setselectBoostValue}
            selectBoost={selectBoost}
            singlePropertyId={singlePropertyId}
          />
        );
      default:
        return (
          <BoostListing
            boostToggle={boostToggle}
            setChangeIndexValue={setChangeIndexValue}
          
          />
        );
        break;
    }
  };

  return <>{renderSwitch(bostingIndex)}</>;
};

export default MainBoostingPage;
