import React from 'react'
import Header from '../../components/layouts/Header'
import Footer from '../../components/layouts/Footer'

const PrivacyPolicy = () => {
  return (
    <>
      <div>
        <Header/>
        <div style={{ paddingLeft: "23%", marginTop: "100px", marginBottom: "24px", marginLeft: "-65%" }}>
          <a href="" style={{ fontsize: "12px", color: "#42526E" }}>Home > </a>
          <a href="" style={{ fontsize: "12px", color: "#42526E" }}>Privacy Policy</a>
        </div>
        <div className='css-1dbjc4n privacy_ploicy'>
          <div className='css-1dbjc4n r-xovx4i r-1xfd6ze r-bijgke'>
            <div className='css-1dbjc4n r-1awozwy r-6koalj r-eqz5dr'>
              <div className='css-1dbjc4n r-1uopjct r-145lgeb r-64el8z r-1kz6sp'>
                <div className='css-1dbjc4n r-1mlwlqe r-1uopjct r-145lgeb r-64el8z r-1udh08x r-1kz6sp r-417010 safety_guide'>
                  <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                  >
                    <img src="./assets/images/all/privacy.png" alt="" />
                  </div>
                </div>
              </div>
              <div className='css-1dbjc4n r-7sv4c2'></div>
              <h1 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-1x35g6 r-13uqrnb r-b88u0q r-37tt59 r-1v456y7' style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "18px" }}>
                Mygoldenhomes Policy
              </h1>
            </div>
          </div>
          <div className="css-1dbjc4n">
            <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Privacy Policy</h2>
            <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
              This Privacy policy is subject to the terms of the Site Policy (User agreement) of Mygoldenhomes. This policy is effective from the date and time a user registers with Mygoldenhomes by filling up the Registration form and accepting the terms and conditions laid out in the Site Policy.
            </div>
            <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
              In order to provide a personalised browsing experience, Mygoldenhomes may collect personal information from you. Additionally some of our websites may require you to complete a registration form or seek some information from you. When you let us have your preferences, we will be able to deliver or allow you to access the most relevant information that meets your end.
            </div>
            <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
              To extend this personalized experience Mygoldenhomes may track the IP address of a user's computer and save certain information on your system in the form of cookies. A user has the option of accepting or declining the cookies of this website by changing the settings of your browser
            </div>
            <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
              The personal information provided by the users to Mygoldenhomes will not be provided to third parties without previous consent of the user concerned. Information of a general nature may however be revealed to external parties
            </div>
            <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
              Every effort will be made to keep the information provided by users in a safe manner, the information will be displayed on the website will be done so only after obtaining consent from the users. Any user browsing the site generally is not required to disclose his identity or provide any information about him/her, it is only at the time of registration you will be required to furnish the details in the registration form.
            </div>
            <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
              A full user always has the option of not providing the information which is not mandatory. You are solely responsible for maintaining confidentiality of the User password and user identification and all activities and transmission performed by the User through his user identification and shall be solely responsible for carrying out any online or off-line transaction involving credit cards / debit cards or such other forms of instruments or documents for making such transactions and IEIL assumes no responsibility or liability for their improper use of information relating to such usage of credit cards / debit cards used by the subscriber online / off-line.
            </div>
            <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
              Mygoldenhomes shall in order to provide seamless video upload and streaming services to users, utilize 3rd party services such as You-tube API Services and shall not pull any metadata of the video provided by the user. We suggest you check up the Google Privacy Policy at (http://www.google.com/policies/privacy) to help you understand what data Google holds about you and how they process it.
            </div>
            <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
              IEIL provides you the ability to keep your personal information accurate and up-to-date. If at any time you would like to (a) rectify, update, correct or delete your personal information; (b) obtain confirmation on whether or not your personal information is processed by it; c) access your personal information or exercise your right to data portability; or d) exercise your right to restrict the continuing disclosure of your personal information to any third party by IEIL in certain circumstances, you are requested to contact us at feedback@Mygoldenhomes.com. We will require you to provide a valid proof of your identity, in order to ensure that your rights are respected.
            </div>
            <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
              You agree that IEIL may use personal information about you to improve its marketing and promotional efforts, to analyze site usage, improve the Site's content and product offerings, and customise the Site's content, layout, and services. These uses improve the Site and better tailor it to meet your needs, so as to provide you with a smooth, efficient, safe and customised experience while using the Site.
            </div>
            <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
              You agree that IEIL may use your personal information to contact you and deliver information to you that, in some cases, are targeted to your interests, such as targeted banner advertisements, administrative notices, product offerings, and communications relevant to your use of the Site. By accepting the User Agreement and Privacy Policy, you expressly agree to receive this information. If you do not wish to receive these communications, we encourage you to opt out of the receipt of certain communications in your profile. You may make changes to your profile at any time, if you wish to delete your account on Mygoldenhomes.com, you may do so by writing an email to feedback@Mygoldenhomes.com or by using the settings available in your account. It is the belief of IEIL that privacy of a person can be best guaranteed by working in conjunction with the Law enforcement authorities.
            </div>
            <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
              All IEIL websites including Mygoldenhomes fully comply with all Indian Laws applicable. IEIL has always cooperated with all law enforcement inquires. IEIL may disclose all or part of your personal details in response to a request from the law enforcement authorities or in a case of bonafide requirement to prevent an imminent breach of the law
            </div>
          </div>
        </div>
        <div className='css-1dbjc4n r-11c0sde'>
          <h4 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-b88u0q r-vrz42v' style={{ fontWeight: "bold", marginBottom: "5px", fontWeight: "600", fontSize: "24px", justifyContent: "center", alignItems: "center", display: "flex" }}>Need to get in touch?</h4>
        </div>
        <div className='css-1dbjc4n r-6koalj r-18u37iz r-1777fci r-1kidu6m terms_conditionbottom_main'>
          <div className='css-1dbjc4n r-r3u836 r-1xfd6ze r-rs99b7 r-1rw7m1n r-1t2hasf r-1aminmm r-18f9sac r-1b3ntt7 terms_conditionbottom_mainin'>
            <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-b88u0q r-waer2z'>TOLL FREE NUMBER</div>
            <div className='css-1dbjc4n r-1awozwy r-18u37iz'>
              <div className='css-1dbjc4n r-1hjwoze r-y3t9qe r-19qrga8 r-hudz2g r-ktdv3k r-12ym1je' style={{ marginRight: "15px" }}>
                <div className='css-1dbjc4n r-1mlwlqe r-1hjwoze r-1udh08x r-12ym1je r-417010'>
                  <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                    style={{ backgroundImage: 'url(&quot;/universalhp/img/callIcon.shared.png&quot;)' }}></div>
                  <img src="/assets/images/all/callIcon.png" alt="" />
                </div>
              </div>
              <div className='css-1dbjc4n'>
                <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'>9 AM - 8 PM IST</div>
                <div className='css-901oao r-gi2tka r-98loyc r-ubezar r-13uqrnb r-1kfrs79 r-waer2z'>+91 90634 83711</div>
              </div>
            </div>
          </div>
          <div className='css-1dbjc4n r-r3u836 r-1xfd6ze r-rs99b7 r-1t2hasf r-1y8ork0 r-c9eks5 r-1b3ntt7 terms_conditions_mainin1'>
            <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-b88u0q r-waer2z' style={{ marginBottom: "5px" }}>INTERNATIONAL NUMBERS</div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz terms_conditionbottom_maininner'>
              <div className='css-1dbjc4n r-1awozwy r-18u37iz' style={{ marginRight: "5px" }}>
                <div className='css-1dbjc4n r-1hjwoze r-y3t9qe r-19qrga8 r-hudz2g r-ktdv3k r-12ym1je' style={{ marginRight: "5px", marginLeft: "5px" }}>
                  <div className='css-1dbjc4n r-1mlwlqe r-1hjwoze r-1udh08x r-12ym1je r-417010'>
                    <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                      style={{ backgroundImage: 'url(&quot;/universalhp/img/callIcon.shared.png&quot;)' }}></div>
                    <img src="/assets/images/all/callIcon.png" alt="" />
                  </div>
                </div>
                <div className='css-1dbjc4n'>
                  <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'><div dir="auto" class="css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z">United Arab Emirates</div></div>
                  <div className='css-901oao r-gi2tka r-98loyc r-ubezar r-13uqrnb r-1kfrs79 r-waer2z'>+91 906348 37119</div>
                </div>
              </div>
              <div className='css-1dbjc4n r-1awozwy r-18u37iz' style={{ marginRight: "5px" }}>
                <div className='css-1dbjc4n r-1hjwoze r-y3t9qe r-19qrga8 r-hudz2g r-ktdv3k r-12ym1je' style={{ marginRight: "5px", marginLeft: "5px" }}>
                  <div className='css-1dbjc4n r-1mlwlqe r-1hjwoze r-1udh08x r-12ym1je r-417010'>
                    <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                      style={{ backgroundImage: 'url(&quot;/universalhp/img/callIcon.shared.png&quot;)' }}></div>
                    <img src="/assets/images/all/callIcon.png" alt="" />
                  </div>
                </div>
                <div className='css-1dbjc4n'>
                  <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'>United States of America</div>
                  <div className='css-901oao r-gi2tka r-98loyc r-ubezar r-13uqrnb r-1kfrs79 r-waer2z'>1800-41-99099</div>
                </div>
              </div>
              <div className='css-1dbjc4n r-1awozwy r-18u37iz' style={{ marginRight: "5px" }}>
                <div className='css-1dbjc4n r-1hjwoze r-y3t9qe r-19qrga8 r-hudz2g r-ktdv3k r-12ym1je' style={{ marginRight: "5px", marginLeft: "5px" }}>
                  <div className='css-1dbjc4n r-1mlwlqe r-1hjwoze r-1udh08x r-12ym1je r-417010'>
                    <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                      style={{ backgroundImage: 'url(&quot;/universalhp/img/callIcon.shared.png&quot;)' }}></div>
                    <img src="/assets/images/all/callIcon.png" alt="" />
                  </div>
                </div>
                <div className='css-1dbjc4n'>
                  <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'>United Kingdom</div>
                  <div className='css-901oao r-gi2tka r-98loyc r-ubezar r-13uqrnb r-1kfrs79 r-waer2z'>1800-41-99099</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='css-1dbjc4n r-eu3ka'></div>
        <Footer/>
      </div >
    </>
  )
}

export default PrivacyPolicy