import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../components/layouts/Header";
import { Link, useNavigate } from "react-router-dom";
import UserDashBoard from "./dashboard/UserDashBoard";
import EditProfile from "./dashboard/EditProfile";
import Messages from "./dashboard/Messages";
import AgentsList from "./dashboard/AgentsList";
import MyListings from "./dashboard/MyListings";
import Bookings from "./dashboard/Bookings";
import Reviews from "./dashboard/Reviews";
import AddProperty from "./dashboard/AddProperty";
import { useDispatch, useSelector } from "react-redux";
import {
  pageStatusChange,
  setuserIdRemove,
} from "../redux/property/PropertySlice";
import { authActions } from "../redux/athentication/Athentication";
import AllListings from "./dashboard/AllListings";
import DashBoard from "./dashboard/DashBoard";
import AllProducts from "./dashboard/AllProducts";
import EMailers from "./dashboard/EMailers";
import Banners from "./dashboard/Banners";
import PlainListings from "./dashboard/PlainListings";
import BasicListings from "./dashboard/BasicListings";
import PlatinumListings from "./dashboard/PlatinumListings";
import PremiumListings from "./dashboard/PremiumListings";
import Wallet from "./dashboard/Wallet";
import AllLeades from "./dashboard/AllLeades";
import GetLeades from "./dashboard/GetLeades";
import SubscriptionStatus from "./dashboard/SubscriptionStatus";
import ManagePayments from "./dashboard/ManagePayments";
import CreditUsageHistory from "./dashboard/CreditUsageHistory";
import FSLBooking from "./dashboard/FSLBooking";
import OmniAdsPerformance from "./dashboard/OmniAdsPerformance";
import PropertyAlerts from "./dashboard/PropertyAlerts";
import MyLeadsModelBody from "./dashboard/components/MyLeadsModelBody";
import OPTDealerResponse from "./dashboard/components/OPTDealerResponse";
import ChangePassword from "./dashboard/components/ChangePassword";
import AllResponseListing from "./dashboard/AllResponseListing";
import ResponseBySingleProperty from "./dashboard/components/ResponseBySingleProperty";

const MainDashboard = () => {
  const { loginData, isAuth } = useSelector((store) => store.Athentication);
  const { pageStatus } = useSelector((store) => store.Property);

  const [isopennav, setIsOpenNav] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) navigate("/");
  }, [isAuth]);

  const logoutClick = () => {
    dispatch(authActions.signout());
    dispatch(setuserIdRemove());
    navigate("/");
    window.location.reload(false);
  };

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return (
          <>
            <DashBoard />
          </>
        );
      case 1:
        return (
          <>
            <AllProducts />
          </>
        );
      case 2:
        return (
          <>
            <EMailers />
          </>
        );
      case 3:
        return (
          <>
            <Banners />
          </>
        );
      case 4:
        return (
          <>
            <AllListings />
          </>
        );
      case 5:
        return (
          <>
            <PlainListings />
          </>
        );
      case 6:
        return (
          <>
            <BasicListings />
          </>
        );
      case 7:
        return (
          <>
            <PlatinumListings />
          </>
        );
      case 8:
        return (
          <>
            <PremiumListings />
          </>
        );
      case 9:
        return (
          <>
            <Wallet />
          </>
        );
      case 10:
        return (
          <>
            <AllLeades />
          </>
        );
      case 11:
        return (
          <>
            <GetLeades />
          </>
        );
      case 12:
        return (
          <>
            <SubscriptionStatus />
          </>
        );
      case 13:
        return (
          <>
            <ManagePayments />
          </>
        );
      case 14:
        return (
          <>
            <CreditUsageHistory />
          </>
        );
      case 15:
        return (
          <>
            <FSLBooking />
          </>
        );
      case 16:
        return (
          <>
            <OmniAdsPerformance />
          </>
        );
      case 17:
        return (
          <>
            <PropertyAlerts />
          </>
        );
      case 18:
        return (
          <>
            <AllLeades />
          </>
        );
      case 19:
        return <>{/* <AllLeades /> */}</>;
      case 20:
        return <>{/* <AllLeades /> */}</>;
      case 21:
        return (
          <>
            <AllResponseListing />
          </>
        );
      case 22:
        return (
          <>
            <ResponseBySingleProperty />
          </>
        );

      default:
        return (
          <>
            <DashBoard />
          </>
        );
        break;
    }
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [myleadsmodal, setMyLeadsModal] = useState(false);
  const toggle1 = () => setMyLeadsModal(!myleadsmodal);

  const [optdealer, setOptDealerl] = useState(false);
  const toggle2 = () => setOptDealerl(!optdealer);

  const [changepassword, setChangePassword] = useState(false);
  const toggle3 = () => setChangePassword(!changepassword);

  const [isactivecom, setIsActiveCom] = useState(false);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setIsActiveCom(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      <Header />
      <div>
        <div className="dashbard-menu-wrap">
          <div className="dashbard-menu-close">
            <i className="fal fa-times" />
          </div>
          <div className="dashbard-menu-container">
            <div className="user-profile-menu">
              <a onClick={() => dispatch(pageStatusChange(0))}>
                <h3>MyGoldenHomes</h3>
              </a>
              <ul className="no-list-style">
                <li>
                  <Link to="" className="user-profile-act manage_listinghead">
                    MANAGE LISTINGS
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to=""
                    className="user-profile-act"
                    onClick={() => dispatch(pageStatusChange(1))}
                  >
                    <i className="fal fa-chart-line" />
                    All Products
                  </Link>
                </li> */}
                <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(4))}>
                    <i className="fal fa-users" /> All Properties
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(4))}>
                    <i className="fal fa-plus" />
                    Basic Listings
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(4))}>
                    <i className="fal fa-plus" />
                    Silver Listings
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(4))}>
                    <i className="fal fa-plus" />
                    Gold Listings
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(4))}>
                    <i className="fal fa-plus" />
                    Platinum Listings
                  </Link>
                </li>
                {/* <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(5))}>
                    <i className="fal fa-plus" />
                    Plain Listings
                  </Link>
                </li> */}

                <li>  
                  <Link to="" onClick={() => dispatch(pageStatusChange(9))}>
                    <i className="fal fa-wallet" /> Packages
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="fal fa-plus" /> All Projects
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="fal fa-plus" /> Super Campaign
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="fal fa-plus" /> BBC
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="fal fa-plus" /> SAB
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="fal fa-plus" /> NP/FP/PG
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="fal fa-plus" /> E-Mailers
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="fal fa-plus" /> Omini Ads
                  </Link>
                </li>
              </ul>
            </div>

            <div className="user-profile-menu">
              <h3>MANAGE RESPONSES</h3>
              <ul className="no-list-style">
                {/* <li>
                  <Link
                    to=""
                    className="user-profile-act"
                    onClick={() => dispatch(pageStatusChange(1))}
                  >
                    <i className="fal fa-chart-line" />
                    All Products
                  </Link>
                </li> */}

                <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(21))}>
                    <i className="fal fa-users" /> All Responses
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(21))}>
                    <i className="fal fa-plus" />
                    Basic Listings
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(21))}>
                    <i className="fal fa-plus" />
                    Silver Listings
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(21))}>
                    <i className="fal fa-plus" />
                    Gold Listings
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(21))}>
                    <i className="fal fa-plus" />
                    Platinum Listings
                  </Link>
                </li>

                {/* <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(10))}>
                    <i className="fal fa-envelope" /> All Leads
                  </Link>
                </li> */}
                <li>
                  <Link
                    to=""
                    onClick={() => dispatch(pageStatusChange())}
                  ></Link>
                </li>
                {/* <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(11))}>
                    <i className="fal fa-envelope" /> Get Leads
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(12))}>
                    <i className="fal fa-envelope" /> Subscription Status
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(13))}>
                    <i className="fal fa-envelope" /> Manage Payments
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(14))}>
                    <i className="fal fa-envelope" /> Credit Usage History
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(15))}>
                    <i className="fal fa-envelope" /> FSL Booking
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(16))}>
                    <i className="fal fa-envelope" /> Omni Ads Performance
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(17))}>
                    <i className="fal fa-envelope" /> Property Alerts
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="" onClick={() => dispatch(pageStatusChange(18))}>
                    <i className="fal fa-envelope" /> Shortlist
                  </Link>
                </li> */}
                {/* <li>
                  <div>
                    <i style={{ color: "#000", display: "block", fontWeight: "500", position: "relative", padding: "10px 20px 10px 20px", fontSize: "13px" }} onClick={toggle1}>My Leads</i>
                  </div>
                  <Modal isOpen={myleadsmodal} toggle={toggle1} className='myleads_modalmain'>
                    <div className='myleads_model-wrapper'>
                      <ModalHeader toggle={toggle1}></ModalHeader>
                      <ModalBody>
                        <MyLeadsModelBody />
                      </ModalBody>
                    </div>
                  </Modal>
                </li>
                <li>
                  <div>
                    <i style={{ color: "#000", display: "block", fontWeight: "500", position: "relative", padding: "10px 20px 10px 20px", fontSize: "13px" }} onClick={toggle2}>Opt out Dealer Response</i>
                  </div>
                  <Modal isOpen={optdealer} toggle={toggle2} className='opt_dealermodalmain' style={{}}>
                    <div className='opt_dealermodel-wrapper'>
                      <ModalHeader toggle={toggle2}></ModalHeader>
                      <ModalBody>
                        <OPTDealerResponse />
                      </ModalBody>
                    </div>
                  </Modal>
                </li>
                <li>
                  <div>
                    <i style={{ color: "#000", display: "block", fontWeight: "500", position: "relative", padding: "10px 20px 10px 20px", fontSize: "13px" }} onClick={toggle3}>Change Password</i>
                  </div>
                  <Modal isOpen={changepassword} toggle={toggle1} className='changepassword_modalmain' style={{}}>
                    <div className='changepassword_model-wrapper'>
                      <ModalHeader toggle={toggle3}></ModalHeader>
                      <ModalBody>
                        <ChangePassword />
                      </ModalBody>
                    </div>
                  </Modal>
                </li> */}

                <li>
                  <Link to="" onClick={(e) => logoutClick(e)}>
                    <i className="fal fa-envelope" /> Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="dashbard-menu-footer">
            © 2023 Dexterous Technology | All rights reserved.
          </div>
        </div>

        <div
          className="dashboard-content"
          style={{ background: "#f5f7fb" }}
          ref={menuRef}
        >
          <div
            className="dashboard-menu-btn color-bg"
            style={{ marginTop: 20 }}
          >
            <span onClick={(e) => setIsOpenNav(!isopennav)}>
              {isopennav ? (
                <i className="fas fa-times" />
              ) : (
                <i className="fas fa-bars" />
              )}
            </span>
            Dasboard Menu
          </div>
          {isopennav && (
            <>
              <div
                className="dashbard-menu-container"
                style={{
                  top: "135px",
                  width: "250px",
                  marginLeft: "28px",
                  zIndex: "9",
                  background: "#fff",
                  position: "fixed",
                  overflowY: "scroll",
                }}
              >
                <div className="user-profile-menu">
                  <a onClick={() => dispatch(pageStatusChange(0))}>
                    <h3>MyGoldenHomes</h3>
                  </a>
                  <ul className="no-list-style">
                    <li>
                      <Link to="" className="manage_listinghead">
                        MANAGE LISTINGS
                      </Link>
                    </li>
                    {/* <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link
                        to=""
                        className="user-profile-act"
                        onClick={() => dispatch(pageStatusChange(1))}
                      >
                        <i className="fal fa-chart-line" />
                        All Products
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(2))}>
                        <i className="fal fa-user-edit" /> E-Mailers
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(3))}>
                        <i className="fal fa-envelope" /> Banners
                      </Link>
                    </li> */}
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(4))}>
                        <i className="fal fa-users" /> All Properties
                      </Link>
                    </li>
                    {/* <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(5))}>
                        <i className="fal fa-plus" />
                        Plain Listings
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(6))}>
                        <i className="fal fa-envelope" /> Basic Listings
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(7))}>
                        <i className="fal fa-envelope" /> Platinum Listings
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(8))}>
                        <i className="fal fa-envelope" /> Premium Listings
                      </Link>
                    </li> */}
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(9))}>
                        <i className="fal fa-wallet" /> Wallet
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="user-profile-menu">
                  <h3>MANAGE RESPONSES</h3>
                  <ul className="no-list-style">
                    {/* <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link
                        to=""
                        className=""
                        onClick={() => dispatch(pageStatusChange(1))}
                      >
                        <i className="fal fa-chart-line" />
                        All Products
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(2))}>
                        <i className="fal fa-user-edit" /> E-Mailers
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(3))}>
                        <i className="fal fa-envelope" /> Banners
                      </Link>
                    </li> */}
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link
                        to=""
                        onClick={() => dispatch(pageStatusChange(21))}
                      >
                        <i className="fal fa-users" /> All Responses
                      </Link>
                    </li>
                    {/* <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(5))}>
                        <i className="fal fa-plus" />
                        Plain Listings
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(6))}>
                        <i className="fal fa-envelope" /> Basic Listings
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(7))}>
                        <i className="fal fa-envelope" /> Platinum Listings
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link to="" onClick={() => dispatch(pageStatusChange(8))}>
                        <i className="fal fa-envelope" /> Premium Listings
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link
                        to=""
                        onClick={() => dispatch(pageStatusChange(10))}
                      >
                        <i className="fal fa-envelope" /> All Leads
                      </Link>
                    </li>
                    <li>
                      <Link
                        to=""
                        onClick={() => dispatch(pageStatusChange())}
                      ></Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link
                        to=""
                        onClick={() => dispatch(pageStatusChange(11))}
                      >
                        <i className="fal fa-envelope" /> Get Leads
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link
                        to=""
                        onClick={() => dispatch(pageStatusChange(12))}
                      >
                        <i className="fal fa-envelope" /> Subscription Status
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link
                        to=""
                        onClick={() => dispatch(pageStatusChange(13))}
                      >
                        <i className="fal fa-envelope" /> Manage Payments
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link
                        to=""
                        onClick={() => dispatch(pageStatusChange(14))}
                      >
                        <i className="fal fa-envelope" /> Credit Usage History
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link
                        to=""
                        onClick={() => dispatch(pageStatusChange(15))}
                      >
                        <i className="fal fa-envelope" /> FSL Booking
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link
                        to=""
                        onClick={() => dispatch(pageStatusChange(16))}
                      >
                        <i className="fal fa-envelope" /> Omni Ads Performance
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link
                        to=""
                        onClick={() => dispatch(pageStatusChange(17))}
                      >
                        <i className="fal fa-envelope" /> Property Alerts
                      </Link>
                    </li>
                    <li onClick={(e) => setIsOpenNav(!isopennav)}>
                      <Link
                        to=""
                        onClick={() => dispatch(pageStatusChange(18))}
                      >
                        <i className="fal fa-envelope" /> Shortlist
                      </Link>
                    </li>
                    <li>
                      <div>
                        <i
                          style={{
                            color: "#000",
                            display: "block",
                            fontWeight: "500",
                            position: "relative",
                            padding: "10px 20px 10px 20px",
                            fontSize: "13px",
                          }}
                          onClick={toggle1}
                        >
                          My Leads
                        </i>
                      </div>
                      <Modal
                        isOpen={myleadsmodal}
                        toggle={toggle1}
                        className="myleads_modalmain"
                      >
                        <div className="myleads_model-wrapper">
                          <ModalHeader toggle={toggle1}></ModalHeader>
                          <ModalBody>
                            <MyLeadsModelBody />
                          </ModalBody>
                        </div>
                      </Modal>
                    </li>
                    <li>
                      <div>
                        <i
                          style={{
                            color: "#000",
                            display: "block",
                            fontWeight: "500",
                            position: "relative",
                            padding: "10px 20px 10px 20px",
                            fontSize: "13px",
                          }}
                          onClick={toggle2}
                        >
                          Opt out Dealer Response
                        </i>
                      </div>
                      <Modal
                        isOpen={optdealer}
                        toggle={toggle2}
                        className="opt_dealermodalmain"
                        style={{}}
                      >
                        <div className="opt_dealermodel-wrapper">
                          <ModalHeader toggle={toggle2}></ModalHeader>
                          <ModalBody>
                            <OPTDealerResponse />
                          </ModalBody>
                        </div>
                      </Modal>
                    </li>
                    <li>
                      <div>
                        <i
                          style={{
                            color: "#000",
                            display: "block",
                            fontWeight: "500",
                            position: "relative",
                            padding: "10px 20px 10px 20px",
                            fontSize: "13px",
                          }}
                          onClick={toggle3}
                        >
                          Change Password
                        </i>
                      </div>
                      <Modal
                        isOpen={changepassword}
                        toggle={toggle1}
                        className="changepassword_modalmain"
                        style={{}}
                      >
                        <div className="changepassword_model-wrapper">
                          <ModalHeader toggle={toggle3}></ModalHeader>
                          <ModalBody>
                            <ChangePassword />
                          </ModalBody>
                        </div>
                      </Modal>
                    </li> */}

                    <li>
                      <Link to="" onClick={(e) => logoutClick(e)}>
                        <i className="fal fa-envelope" /> Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="dashbard-menu-footer">
                © 2023 Dexterous Technology | All rights reserved.
              </div>
            </>
          )}

          <div
            className="container dasboard-container"
            style={{ marginTop: 20 }}
          >
            {/* <div className="dashboard-title fl-wrap">
              <div className="dashboard-title-item">
                <span>Dashboard</span>
              </div>
              <div className="dashbard-menu-header">
                <div className="dashbard-menu-avatar fl-wrap">
                  <img src="./assets/images/avatar/5.jpg" alt="" />
                  <h4>
                    Welcome, <span>{loginData.name}</span>
                  </h4>
                </div>
                <a
                  className="log-out-btn tolt"
                  data-microtip-position="bottom"
                  data-tooltip="Log Out"
                  
                >
                  <i className="far fa-power-off" />
                </a>
              </div>

              <div className="tfp-det-container">
                <div className="tfp-btn">
                  <span>Your Tariff Plan : </span> <strong>Extended</strong>
                </div>
                <div className="tfp-det">
                  <p>
                    You Are on <Link to="">Extended</Link> . Use link bellow to
                    view details or upgrade.
                  </p>
                  <Link to="" className="tfp-det-btn color-bg">
                    Details
                  </Link>
                </div>
              </div>
            </div> */}
            <div className="component_hideMobile component_showme">
              {/* <div className="component__lastVisit">
                <span>
                  Last Visited: <b>04:47 PM | 27 Sep, 2023</b>
                </span>
              </div> */}
              <div className="component__links">
                <Link to="/leadtosearch" className="pageComponent">
                  LEAD SEARCH
                </Link>
                <span>&nbsp;</span>
                <Link to="/simplepricing" className="pageComponent">
                  BUY OUR SERVICES
                </Link>
                <span>&nbsp;</span>
                <Link
                  to="/postPropertyHome"
                  className="pageComponent"
                  onClick={() => dispatch(pageStatusChange(9))}
                >
                  POST A PROPERTY
                </Link>
                <span>&nbsp;</span>
                <Link
                  // to="/customerservice"
                  className="pageComponent"
                >
                  CUSTOMER SERVICE
                </Link>
              </div>
            </div>

            {renderSwitch(pageStatus)}
          </div>

          {/* dashboard-footer */}
          {/* <div className="dashboard-footer">
            <div className="dashboard-footer-links fl-wrap">
              <span>Helpfull Links:</span>
              <ul>
                <li>
                  <Link to="">About </Link>
                </li>
                <li>
                  <Link to="">Blog</Link>
                </li>
                <li>
                  <Link to="">Pricing Plans</Link>
                </li>
                <li>
                  <Link to="">Contacts</Link>
                </li>
                <li>
                  <Link to="">Help Center</Link>
                </li>
              </ul>
            </div>
            <a href="#main" className="dashbord-totop custom-scroll-link">
              <i className="fas fa-caret-up" />
            </a>
          </div> */}
        </div>
        <div className="limit-box fl-wrap" />
      </div>
    </>
  );
};

export default MainDashboard;
