

import React from "react";
import { useSelector } from "react-redux";
import HideAlreadySeen from "./HideAlreadySeen";
import Verified from "./Verified";
import Budget from "./Budget";
import PropertyType from "./PropertyType";
import Bhk from "./Bhk";
import ConstructionStatus from "./ConstructionStatus";
import PostedBy from "./PostedBy";
import Area from "./Area";
import Localities from "./Localities";
import NewProjectsSocieites from "./NewProjectsSocieites";
import PurchaseType from "./PurchaseType";
import Amenities from "./Amenities";
import Photos from "./Photos";
import Videos from "./Videos";
import Bathrooms from "./Bathrooms";
import FurnishingStatus from "./FurnishingStatus";
import RERAApproved from "./RERAApproved";


const MainFilterComponent = ({ filterComponentIndex }) => {
  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return <HideAlreadySeen />;
      case 1:
        return <Verified />;
      case 2:
        return <Budget />;
      case 3:
        return <PropertyType />;
      case 4:
        return <Bhk />;
      case 5:
        return <ConstructionStatus />;
      case 6:
        return <PostedBy />;
      case 7:
        return <Area />;
      case 8:
        return <Localities />;
      case 9:
        return <NewProjectsSocieites />;
      case 10:
        return <PurchaseType />;
      case 11:
        return <Amenities />;
      case 12:
        return <Photos />;
      case 13:
        return <Videos />;
      case 14:
        return <Bathrooms />;
      case 15:
        return <FurnishingStatus />;
      case 16:
        return <RERAApproved />;
      default:
        return <HideAlreadySeen />;
        break;
    }
  };

  return <>{renderSwitch(filterComponentIndex)}</>;
};

export default MainFilterComponent;
