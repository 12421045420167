import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setShow_model } from "../../redux/athentication/Athentication";
import { toast } from "react-toastify";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";
import { TextField } from "@mui/material";
import "./popup.css";

export function PopupComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [city, setCity] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState(" ");
  const [btnPress, setbtnPress] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  useEffect(() => {
    if (btnPress === true) {
      if (name === "") {
        setNameError("Please enter first name");
      } else {
        setNameError("");
      }
      if (email === "") {
        setEmailError("Please enter email");
      } else {
        setEmailError("");
      }
      if (mobile === "") {
        setMobileError("Please enter mobile number");
      } else {
        setMobileError("");
      }
    }
  }, [btnPress, name, email, mobile]);

  const validateEmail = (e) => {
    let inputValue = e.target.value;
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (!emailRegex.test(inputValue)) {
      setMessage("*Error! you have entered invalid email.");
    } else {
      setMessage("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNameError("");
    setEmailError("");
    setMobileError("");
    if (name === "") {
      setNameError("Please enter first name");
    } else {
      setNameError("");
    }
    if (email === "") {
      setEmailError("Please enter email");
    } else {
      setEmailError("");
    }
    if (mobile === "") {
      setMobileError("Please enter mobile number");
    } else {
      setMobileError("");
    }
    if (name !== "" && email !== "" && mobile !== "") {
      const formdata = {
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        email: email,
        mobile: mobile,
        location: location,
        city: city,
      };
      const googleData = await axios.post(
        `${Baseurl}/api/v1/googledata/new`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (googleData.data.success) {
        toast.success("Submitted Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setIsModalOpen(false);
        dispatch(setShow_model(true));
        navigate("/");
        setName("");
        setEmail("");
        setMobile("");
      }
    }
  };

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(setShow_model(true));
    navigate("/");
  };

  const renderModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Modal
      visible={isModalOpen}
      // maskClosable={false}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <form action className="form_main">
        {/* <div className="logo">
          <img src="/assets/images/logo41.png" alt="easyclass" />
        </div> */}
        <div className="heading1">
          <h2>Get Started</h2>
        </div>
        <div className="inputContainer">
          <input
            type="text"
            autoComplete="off"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={`inputField ${nameError !== "" ? "inputContent" : ""}`}
          />
        </div>
        <br />
        <div className="inputContainer">
          <input
            type="text"
            autoComplete="off"
            placeholder="Enter your email"
            className={`inputField ${emailError !== "" ? "inputContent" : ""}`}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
            onBlur={(e) => {
              validateEmail(e);
            }}
            onFocus={(e) => setMessage("")}
          />
        </div>
        <br />
        <div
          style={{
            color: "red",
            fontWeight: "500",
            fontSize: "12px",
            zIndex: "9999",
          }}
        >
          {message}
        </div>
        <div className="inputContainer">
          <input
            type="text"
            autoComplete="off"
            className={`inputField ${mobileError !== "" ? "inputContent" : ""}`}
            placeholder="Mobile Number"
            minLength={10}
            maxLength={10}
            value={mobile}
            onChange={(e) => setMobile(e.target.value.replace(/\D/g, ""))}
          />
        </div>
        <br />
        <div className="inputContainer">
          <input
            type="text"
            autoComplete="off"
            className="inputField"
            placeholder="Enter Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <br />
        <div className="inputContainer">
          <input
            type="text"
            autoComplete="off"
            className="inputField"
            placeholder="Enter City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <br />
        <button
          id="button"
          onClick={(e) => handleSubmit(e)}
          style={{ backgroundColor: "#0078db" }}
        >
          Submit
        </button>
      </form>
      <div className="ant-modal-footer"></div>
    </Modal>
  );
}
