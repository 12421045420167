import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';


// import required modules
import { FreeMode } from 'swiper/modules';

const ExploreRealEstate = () => {
    return (
        <>
            <div className='realestate-container'>
                <div className='realestate-head'>
                    <p>TOP CITIES</p>
                    <h1>Explore Real Estate in Popular Indian Cities</h1>
                </div>
                <div className='realestate-swiper'>
                    <Swiper
                        slidesPerView={1}
                        grid={{
                            rows: 2,
                        }}
                        spaceBetween={30}
                        pagination={{
                            clickable: false,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            480: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            640: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            840: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            1000: {
                                slidesPerView: 1,
                                spaceBetween: 0,
                            },
                        }}
                        modules={[FreeMode]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className='estate-card'>
                                    <div className='col'>
                                        <div className='col-inner-estate'>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Delhi.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Delhi / NCR</h2>
                                                    <p>124,000+ Properties</p>
                                                </div>
                                            </div>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Mumbai.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Mumbai</h2>
                                                    <p>72,000+ Properties</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='col-inner-estate'>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Banglore.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Bangalore</h2>
                                                    <p>33,000+ Properties</p>
                                                </div>
                                            </div>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Hyderabad.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Hyderabad</h2>
                                                    <p>26,000+ Properties</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col col-estate'>
                                        <div className='col-inner-estate'>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Pune.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Pune</h2>
                                                    <p>35,000+ Properties</p>
                                                </div>
                                            </div>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Kolkata.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Kolkata</h2>
                                                    <p>23,000+ Properties</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='col-inner-estate'>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Chennai.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Chennai</h2>
                                                    <p>30,000+ Properties</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className='estate-card'>
                                    <div className='col'>
                                        <div className='col-inner-estate'>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Delhi.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Delhi / NCR</h2>
                                                    <p>124,000+ Properties</p>
                                                </div>
                                            </div>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Mumbai.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Mumbai</h2>
                                                    <p>72,000+ Properties</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='col-inner-estate'>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Banglore.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Bangalore</h2>
                                                    <p>33,000+ Properties</p>
                                                </div>
                                            </div>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Hyderabad.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Hyderabad</h2>
                                                    <p>26,000+ Properties</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col col-estate'>
                                        <div className='col-inner-estate'>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Pune.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Pune</h2>
                                                    <p>35,000+ Properties</p>
                                                </div>
                                            </div>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Kolkata.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Kolkata</h2>
                                                    <p>23,000+ Properties</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='col-inner-estate'>
                                            <div className='estate-inner-box-1'>
                                                <div className='estate-col-img'>
                                                    <img src='/assets/images/sliderImg/Chennai.jpg' alt=''></img>
                                                </div>
                                                <div className='estate-col-text'>
                                                    <h2>Chennai</h2>
                                                    <p>30,000+ Properties</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default ExploreRealEstate