import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageStatusChange } from "../../redux/property/PropertySlice";
import PropertyAll from "./latestProperty/PropertyAll";
import SellProperty from "./latestProperty/SellProperty";
import RentProperty from "./latestProperty/RentProperty";
import { Link } from "react-router-dom";

const LatestProperties = () => {
  const { pageStatus } = useSelector((store) => store.Property);

  const dispatch = useDispatch();

  console.log(pageStatus, "pageStatus");

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return (
          <>
            <PropertyAll />
          </>
        );
      case 1:
        return (
          <>
            <SellProperty />
          </>
        );
      case 2:
        return (
          <>
            <RentProperty />
          </>
        );
      default:
        return (
          <>
            <PropertyAll />
          </>
        );
        break;
    }
  };

  return (
    <>
      {/*  breadcrumbs */}
      <div className="breadcrumbs fw-breadcrumbs sp-brd fl-wrap">
        <div className="container">
          <div className="breadcrumbs-list">
            {/* <Link to="/">Home</Link> <span>Home Page</span> */}
          </div>
        </div>
      </div>
      {/*  breadcrumbs end  */}

      <section className="gray-bg small-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="section-title fl-wrap">
                <h4>Browse Hot Offers</h4>
                <h2>Latest Properties</h2>
              </div>
            </div>
            <div className="col-md-8">
              <div className="listing-filters gallery-filters">
                <Link
                  to=""
                  className={
                    pageStatus === 0
                      ? "gallery-filter gallery-filter-active"
                      : "gallery-filter"
                  }
                  data-filter="*"
                  onClick={() => dispatch(pageStatusChange(0))}
                >
                  <span>All Categories</span>
                </Link>

                <Link
                  to=""
                  className={
                    pageStatus === 1
                      ? "gallery-filter gallery-filter-active"
                      : "gallery-filter"
                  }
                  data-filter=".for_sale"
                  onClick={() => dispatch(pageStatusChange(1))}
                >
                  <span>For Sale</span>
                </Link>
                <Link
                  to=""
                  className={
                    pageStatus === 2
                      ? "gallery-filter gallery-filter-active"
                      : "gallery-filter"
                  }
                  data-filter=".for_rent"
                  onClick={() => dispatch(pageStatusChange(2))}
                >
                  <span>For Rent</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="grid-item-holder gallery-items gisp ">
            {renderSwitch(pageStatus)}
          </div>
        </div>
        {/* <div className="container">
          <a to="" className="btn float-btn small-btn color-bg">
            View All Properties
          </a>
        </div> */}
      </section>
    </>
  );
};

export default LatestProperties;
