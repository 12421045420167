import React, { useState, useEffect, useRef } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa6";
import {
  BiSolidInfoCircle,
  BiSolidTraffic,
  BiSolidStar,
  BiSolidStarHalf,
  BiLike,
  BiDislike,
} from "react-icons/bi";
import { TiEye } from "react-icons/ti";

import { BiSearch } from "react-icons/bi";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { IoStar } from "react-icons/io5";
import { IoStarOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import LeadSearchModifyModel from "./components/responseModel/LeadSearchModifyModel";
import { useDispatch, useSelector } from "react-redux";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import moment from "moment/moment";
import { ColorRing } from "react-loader-spinner";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropertyDetailsModel from "../home/latestProperty/PropertyDetailsModel";
import HeaderNew from "../../components/layouts/HeaderNew";

const LeadsearchResult = () => {
  const { leadesSearchObjects } = useSelector((state) => state.Search);
  const { loginData } = useSelector((store) => store.Athentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fetchallLeads, setFetchallLeads] = useState([]);
  const [showfetchallLeads, setShowFetchallLeads] = useState([]);
  const [loadingdata, setLoadingdata] = useState(true);

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [singleProperty, setSingleProperty] = useState("");
  const [newBooking, setNewBooking] = useState(false);
  const [resale, setResale] = useState(false);
  const [newProject, setNewProject] = useState(false);
  const [withInThreeMonths, setWithInThreeMonths] = useState(false);
  const [withInSixMonths, setWithInSixMonths] = useState(false);
  const [withInOneYear, setWithInOneYear] = useState(false);
  const [withInOneToTwoYears, setWithInOneToTwoYears] = useState(false);
  const [withInTwoToThreeYears, setWithInTwoToThreeYears] = useState(false);
  const [readyToMove, setReadyToMove] = useState(false);
  const [withInOneToThreeYears, setWithInOneToThreeYears] = useState(false);
  const [decisionTime, setDecisionTime] = useState("");
  const [onlyNRI, setOnlyNRI] = useState(false);

  const toggle = () => setModal(!modal);
  const toggle2 = () => setModal2(!modal2);

  const closePropDetailModal = () => {
    setModal2(false);
  };

  const changeView = () => {};

  const toggleClick = async (e, leads) => {
    if (leads !== "") {
      const url = `${Baseurl}/api/v1/property/${leads.propertyName}`;
      const fetchProperty = await axios.get(url);
      if (fetchProperty.data.success === true) {
        let property = fetchProperty.data.property;
        setModal2(!modal2);
        setSingleProperty(property);
      }
    }
  };

  useEffect(() => {
    let fetchLeads = [];
    let citySearch = leadesSearchObjects.searchArray;

    for (let index = 0; index < citySearch.length; index++) {
      const element = citySearch[index];
      try {
        const fetchLeadsbycity = async () => {
          const url = `${Baseurl}/api/v1/viewed/leadesbycity/${element}`;
          const fetchleadsData = await axios.get(url);
          if (fetchleadsData.data.success) {
            let finalLeads = [];
            let leads = fetchleadsData.data.leadesBycity;
            finalLeads = [...finalLeads, ...leads];
            fetchLeads = [...fetchLeads, ...finalLeads];
            setFetchallLeads(fetchLeads);
          }
        };
        fetchLeadsbycity();
      } catch (error) {}
    }
    for (let index = 0; index < citySearch.length; index++) {
      const element = citySearch[index];
      try {
        const fetchLeadsbylocality = async () => {
          const url = `${Baseurl}/api/v1/viewed/leadesbylocality/${element}`;
          const fetchleadsData = await axios.get(url);
          if (fetchleadsData.data.success) {
            let finallead = fetchLeads;
            let property = fetchleadsData.data.leadesByLocality;
            finallead = [...finallead, ...property];
            fetchLeads = [...fetchLeads, ...finallead];
            setFetchallLeads(fetchLeads);
          }
        };
        fetchLeadsbylocality();
      } catch (error) {}
    }
    setLoadingdata(false);
  }, [leadesSearchObjects]);

  useEffect(() => {
    if (fetchallLeads.length > 0) {
      let getfetchLeads = fetchallLeads;
      if (
        leadesSearchObjects.selectbuyrent !== "" &&
        getfetchLeads.length > 0
      ) {
        getfetchLeads = getfetchLeads.filter(
          (lead) => lead.category === leadesSearchObjects.selectbuyrent
        );
        getfetchLeads = getfetchLeads;
      }

      // if (leadesSearchObjects.selectedSubcat.length === 0) {
      //   getfetchLeads = getfetchLeads;
      // } else {
      //   let subcatLead = [];
      //   for (
      //     let index = 0;
      //     index < leadesSearchObjects.selectedSubcat.length;
      //     index++
      //   ) {
      //     const subcat = leadesSearchObjects.selectedSubcat[index];
      //     let filtersubcat = getfetchLeads.filter(
      //       (property) => property.subCategory === subcat
      //     );
      //     subcatLead = [...subcatLead, ...filtersubcat];
      //   }
      //   getfetchLeads = subcatLead;
      // }
      setLoadingdata(false);
      setShowFetchallLeads(getfetchLeads);
    }
  }, []);

  return (
    <>
      <HeaderNew />
      <div className="container leadsearch_main" >
        <div className="lead-top">
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 className="leadsearchheading">Lead Search</h4>
          </div>
          <div>
            <p className="text">Credits Balance</p>
            <p className="text">
              <font color="#20a286">0/0</font>
            </p>
          </div>
        </div>
        <div className="lead-navigation">
          <div>
            <ul className="navigation-main">
              <li style={{ background: "#fff" }}>Residential</li>
              <li>Commercial</li>
              <li>Auto Alerts</li>
              <li>Purchased Leads</li>
              <li>Credit Usage</li>
            </ul>
          </div>
          <div
            style={{
              display: "flex",
              // alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="backtext">
              <FaChevronLeft size={10} /> Go back to My Golden Homes
            </p>
          </div>
        </div>

        <div className="leadsearched">
          <div>
            <p className="leadsearched-text">
              Your Searched for : <b>All {leadesSearchObjects.selectSupcat}</b>{" "}
              for <b>{leadesSearchObjects.selectbuyrent}</b> in{" "}
              <b>
                Hyderabad |{" "}
                <font color="#20a286">
                  {fetchallLeads.length} matching leads
                </font>
              </b>
            </p>
          </div>
          <div>
            <button
              className="modify-button"
              // onClick={() => toggle()}
              onClick={() => setModal(!modal)}
            >
              Modify Search
            </button>
            <p className="sort-text">
              Sort by : Relevance <FaChevronDown size="12" />
            </p>
          </div>

          {modal === true ? (
            <>
              <div className="modalopen">
                <LeadSearchModifyModel toggle={toggle} />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="mainBoxes">
          <div className="firstbox">
            <div>
              <p className="refine-search">REFINE SEARCH</p>
              <div className="transaction">
                <p>Transaction Type</p>
              </div>
              <div>
                <p className="checkboxtext">
                  <input
                    type="checkbox"
                    value={newBooking}
                    checked={newBooking === true}
                    onChange={() => setNewBooking(!newBooking)}
                  />{" "}
                  New Booking
                </p>
                <p className="checkboxtext">
                  <input
                    type="checkbox"
                    value={resale}
                    checked={resale === true}
                    onChange={() => setResale(!resale)}
                  />{" "}
                  Resale
                </p>
              </div>

              <div className="transaction">
                <p>Possession Time</p>
              </div>
              <div>
                <p className="checkboxtext">
                  <input
                    type="checkbox"
                    value={newProject}
                    checked={newProject === true}
                    onChange={() => setNewProject(!newProject)}
                  />{" "}
                  New Project
                </p>
                <p className="checkboxtext">
                  <input
                    type="checkbox"
                    value={withInThreeMonths}
                    checked={withInThreeMonths === true}
                    onChange={() => setWithInThreeMonths(!withInThreeMonths)}
                  />{" "}
                  Within 3 Months
                </p>
                <p className="checkboxtext">
                  <input
                    type="checkbox"
                    value={withInSixMonths}
                    checked={withInSixMonths === true}
                    onChange={() => setWithInSixMonths(!withInSixMonths)}
                  />{" "}
                  Within 6 Months
                </p>
                <p className="checkboxtext">
                  <input
                    type="checkbox"
                    value={withInOneYear}
                    checked={withInOneYear === true}
                    onChange={() => setWithInOneYear(!withInOneYear)}
                  />{" "}
                  Within 1 Year
                </p>
                <p className="checkboxtext">
                  <input
                    type="checkbox"
                    value={withInOneToTwoYears}
                    checked={withInOneToTwoYears === true}
                    onChange={() =>
                      setWithInOneToTwoYears(!withInOneToTwoYears)
                    }
                  />{" "}
                  Within 1 - 2 Years
                </p>
                <p className="checkboxtext">
                  <input
                    type="checkbox"
                    value={withInTwoToThreeYears}
                    checked={withInTwoToThreeYears === true}
                    onChange={() =>
                      setWithInTwoToThreeYears(!withInTwoToThreeYears)
                    }
                  />{" "}
                  Within 2 - 3 Years
                </p>
                <p className="checkboxtext">
                  <input
                    type="checkbox"
                    value={readyToMove}
                    checked={readyToMove === true}
                    onChange={() => setReadyToMove(!readyToMove)}
                  />{" "}
                  Ready To Move
                </p>
                <p className="checkboxtext">
                  <input
                    type="checkbox"
                    value={withInOneToThreeYears}
                    checked={withInOneToThreeYears === true}
                    onChange={() =>
                      setWithInOneToThreeYears(!withInOneToThreeYears)
                    }
                  />{" "}
                  Within 1 - 3 Years
                </p>
              </div>

              <div className="transaction">
                <p>Decision Time</p>
              </div>
              <div>
                <p className="checkboxtext">
                  <input
                    type="radio"
                    checked={decisionTime === "Immediate"}
                    onChange={() => setDecisionTime("Immediate")}
                    name="immediate"
                    value="1"
                  />{" "}
                  Immediate
                </p>
                <p className="checkboxtext">
                  <input
                    type="radio"
                    checked={decisionTime === "Within 1 month"}
                    onChange={() => setDecisionTime("Within 1 month")}
                    name="immediate"
                    value="2"
                  />{" "}
                  Within 1 month
                </p>
                <p className="checkboxtext">
                  <input
                    type="radio"
                    checked={decisionTime === "Within 3 months"}
                    onChange={() => setDecisionTime("Within 3 months")}
                    name="immediate"
                    value="3"
                  />{" "}
                  Within 3 months
                </p>
                <p className="checkboxtext">
                  <input
                    type="radio"
                    checked={decisionTime === "Not Sure"}
                    onChange={() => setDecisionTime("Not Sure")}
                    name="immediate"
                    value="4"
                  />{" "}
                  Not Sure
                </p>
              </div>

              <div className="transaction">
                <p>NRI</p>
              </div>
              <div>
                <p className="checkboxtext">
                  <input
                    type="checkbox"
                    value={onlyNRI}
                    checked={onlyNRI === true}
                    onChange={() => setOnlyNRI(!onlyNRI)}
                  />{" "}
                  Only NRI
                </p>
              </div>
            </div>
          </div>
          <div className="secondbox">
            {loadingdata ? (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                </div>
              </>
            ) : (
              <>
                {fetchallLeads.map((lead, index) => (
                  <div className="secondbox-mainbox my-2" key={index}>
                    <div className="second_one">
                      <div className="deepstars">
                        <p className="deepmala-main">{lead.propertyName}</p>
                        <div style={{ display: "flex" }}>
                          <p className="star">5.0 </p>
                          <BiSolidStar color="#f5b241" size={20} />
                          <BiSolidStar color="#f5b241" size={20} />
                          <BiSolidStar color="#f5b241" size={20} />
                          <BiSolidStar color="#f5b241" size={20} />
                          <BiSolidStarHalf color="#f5b241" size={20} />
                        </div>
                      </div>
                      <div className="receivedbox">
                        <p className="received">
                          Received :{" "}
                          <b className="datetext">
                            {moment(lead.createdAt).format("Do MMM YY")}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="second_two">
                      <div>
                        <p>
                          <b>Residential Project - {lead.propertyName}</b> for{" "}
                          <b>{lead.category}</b>
                        </p>
                        <p>
                          City : <b>{lead.city}</b> | Locality :{" "}
                          <b>{lead.locality}</b>
                        </p>
                      </div>
                      <div>
                        <img
                          src="./assets/images/logo2.png"
                          alt="img"
                          className="image"
                        />
                      </div>
                    </div>

                    <div className="second_three">
                      <div className="boxes">
                        <p>Min Budget</p>
                        <p>
                          <b>{lead.price}</b>
                        </p>
                      </div>
                      <div className="boxes">
                        <p>Bedrooms</p>
                        <p>
                          <b>{lead.noofBedrooms} BHK</b>
                        </p>
                      </div>
                      <div className="boxes">
                        <p>Possession Date</p>
                        <p>
                          <b>Immediate</b>
                        </p>
                      </div>
                      <div className="boxes">
                        <p>Projects</p>
                        <p>
                          <b>{lead.propertyName}</b>
                        </p>
                      </div>
                      <div className="boxes">
                        <p>Decision Time</p>
                        <p>
                          <b>-----</b>
                        </p>
                      </div>
                      <div className="boxes">
                        <p>Preferred Call Time</p>
                        <p>
                          <b>Any Time</b>
                        </p>
                      </div>
                      <div className="boxes">
                        <p>Transaction Type</p>
                        <p>
                          <b>Resale</b>
                        </p>
                      </div>
                    </div>

                    <div className="second_four">
                      <div>
                        <p>Comments :---</p>
                        <p style={{ color: "#0078db" }}>
                          View more requirements from this buyer{" "}
                          <FaChevronDown size="13" />
                        </p>
                      </div>
                    </div>

                    <div className="second_five">
                      <div className="second_five_box">
                        <p>
                          <font color="#20a286">3.0</font> Credit(s)
                        </p>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <TiEye size="20" color="gray" /> <p> 0 Time(s)</p>
                        </div>
                        {loginData.creadits >= 1 ? (
                          <>
                            <button
                              className="viewcontact-button"
                              onClick={(e) => toggleClick(e, lead)}
                            >
                              View Contact
                            </button>
                          </>
                        ) : (
                          <>
                            <button className="viewcontact-button">
                              View Contact
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}

      <div className="geodir-category-footer fl-wrap">
        <Modal
          isOpen={modal2}
          toggle={toggle2}
          className="modalmainnew"
          style={{}}
        >
          <div className="model-wrappernew">
            <ModalHeader toggle={toggle2}></ModalHeader>
            <ModalBody>
              <PropertyDetailsModel
                closePropdet={closePropDetailModal}
                propertyprop={singleProperty}
                changeView={changeView}
              />
            </ModalBody>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default LeadsearchResult;
