import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsFillSearchHeartFill, BsMicFill } from "react-icons/bs";
import { BiCurrentLocation } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import { Badge } from "react-bootstrap";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { searchPost } from "../../../redux/search/SearchSlice";
import ReactSlider from "react-slider";

const CommercialSearchComponent = () => {
  const { isAuth, loginData } = useSelector((store) => store.Athentication);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();

  const [showResults, setShowResults] = useState(false);

  const [toggle, setToggle] = useState(false);

  const [budgettoggle, setbudgetToggle] = useState(false);
  const [bedroomtoggle, setbedroomToggle] = useState(false);
  const [constructiontoggle, setconstructionToggle] = useState(false);
  const [postedbytoggle, setpostedbyToggle] = useState(false);

  const [readyOffices, setReadyOffices] = useState(true);
  const [shop, setShop] = useState(true);
  const [agricultural, setAgricultural] = useState(true);
  const [warehouse, setWarehouse] = useState(true);
  const [factory, setFactory] = useState(true);
  const [others, setOthers] = useState(true);
  const [bareOffice, setBareOffice] = useState(true);
  const [commercial, setCommercial] = useState(true);
  const [industrial, setIndustrial] = useState(true);
  const [cold, setCold] = useState(true);
  const [hotel, setHotel] = useState(true);
  const [foodCourt, setFoodCourt] = useState(true);
  const [restaurant, setRestaurant] = useState(true);
  const [multiplex, setMultiplex] = useState(true);
  const [plot, setPlot] = useState(true);
  const [coworking, setCoworking] = useState(true);
  const [business, setBusiness] = useState(true);
  const [leaseSpace, setLeaseSpace] = useState(true);


  const [search, setSearch] = useState("");
  const [showresult, setShowresult] = useState(false);

  const [buychange, setBuychange] = useState(true);
  const [rentchange, setRentchange] = useState(false);
  const [pgcolivingChange, setPgcolivingChange] = useState(false);
  const [commerChange, setcommerChange] = useState(false);
  const [coworkingChange, setCoworkingChange] = useState(false);
  const [plotChange, setPlotChange] = useState(false);
  const [projectchange, setProjectchange] = useState(false);

  const [supercat, setSupercat] = useState("Residential");
  const [cat, setCat] = useState("Sell");
  const [checkboxArray, setCheckboxArray] = useState([]);
  const [propertySearch, setPropertySearch] = useState([]);
  const [propertySearchCity, setPropertySearchCity] = useState([]);
  const [propertySearchLocality, setPropertySearchLocality] = useState([]);
  const [propertySearchProject, setPropertySearchProject] = useState([]);

  const [rkBhk1, setrkBhk1] = useState(false);
  const [Bhk2, setBhk2] = useState(false);
  const [Bhk3, setBhk3] = useState(false);
  const [Bhk4, setBhk4] = useState(false);
  const [Bhk5, setBhk5] = useState(false);

  const [underConstruction, setUnderConstruction] = useState(false);
  const [readytoMove, setReadytoMove] = useState(false);

  const [owner, setOwner] = useState(false);
  const [deler, setDeler] = useState(false);
  const [builder, setBuilder] = useState(false);

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  //for search bar strt
  useEffect(() => {
    try {
      if (search.length >= 2) {
        const fetchdata = async () => {
          const url = `${Baseurl}/api/v1/property/allPropertylocalitycity/${search}`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.properties.slice(0, 5);
              setPropertySearch(searchresult);
            }
          } catch (error) { }
        };

        fetchdata();
      }
    } catch (error) { }

    try {
      if (search.length >= 2) {
        const fetchPropertyByProjects = async () => {
          const url = `${Baseurl}/api/v1/property/allPropertyproject/${search}`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.properties;
              setPropertySearchProject(searchresult);
            }
          } catch (error) { }
        };

        fetchPropertyByProjects();
      }
    } catch (error) { }

    setShowResults(true);
  }, [search]);
  //for search bar end

  const residential = (e, value) => {
    setBuychange(false);
    setRentchange(false);
    setPgcolivingChange(false);
    setcommerChange(false);
    setCoworkingChange(false);
    setPlotChange(false);
    setProjectchange(false);

    setSupercat("Residential");
    // setSubcat("All");
    if (value === "Buy") {
      setCat("Sell");
      setBuychange(true);
    } else if (value === "Rent") {
      setCat("Rent");
      setRentchange(true);
    } else if (value === "PG/Co-living") {
      setCat("PG/Co-living");
      setPgcolivingChange(true);
    } else if (value === "Coworking") {
      setCat("Coworking");
      setCoworkingChange(true);
    } else if (value === "Plots/Land") {
      setCat("Plots/Land");
      setPlotChange(true);
    } else if (value === "Projects") {
      setCat("Projects");
      setProjectchange(true);
    }
  };
  const commercialClick = () => {
    setcommerChange(!commerChange);
    if (commerChange === false) {
      setCat("Commercial");
      setBuychange(false);
      setRentchange(false);
      setPgcolivingChange(false);
      setCoworkingChange(false);
      setPlotChange(false);
      setProjectchange(false);
    }
  };

  //==================for checkbox start===================

  const checkboxChange = (e, value) => {
    const checkvalue = e.target.checked;
    setReadyOffices(checkvalue);

    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange8 = (e, value) => {
    const checkvalue = e.target.checked;
    setBareOffice(checkvalue);

    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };

  const checkboxChange1 = (e, value) => {
    const checkvalue = e.target.checked;
    setShop(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange2 = (e, value) => {
    const checkvalue = e.target.checked;
    setAgricultural(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange3 = (e, value) => {
    const checkvalue = e.target.checked;
    setCommercial(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange4 = (e, value) => {
    const checkvalue = e.target.checked;
    setIndustrial(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange5 = (e, value) => {
    const checkvalue = e.target.checked;
    setCold(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange6 = (e, value) => {
    const checkvalue = e.target.checked;
    setLeaseSpace(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange7 = (e, value) => {
    const checkvalue = e.target.checked;
    setFoodCourt(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange9 = (e, value) => {
    const checkvalue = e.target.checked;
    setWarehouse(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange10 = (e, value) => {
    const checkvalue = e.target.checked;
    setFactory(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange11 = (e, value) => {
    const checkvalue = e.target.checked;
    setOthers(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange12 = (e, value) => {
    const checkvalue = e.target.checked;
    setRestaurant(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange13 = (e, value) => {
    const checkvalue = e.target.checked;
    setMultiplex(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange14 = (e, value) => {
    const checkvalue = e.target.checked;
    setPlot(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange15 = (e, value) => {
    const checkvalue = e.target.checked;
    setCoworking(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange16 = (e, value) => {
    const checkvalue = e.target.checked;
    setBusiness(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange17 = (e, value) => {
    const checkvalue = e.target.checked;
    setHotel(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };

  //==================for checkbox end===================

  const searchRedirect = (e, data) => {
    e.preventDefault();

    navigate(`/property-info/${data.apartment}/${data.propertyID}`);

    if (isAuth) {
      const formdata = {
        userId: loginData._id,
        name: loginData.name,
        userMobile: loginData.mobile,
        propertyId: data._id,
        city: data.city,
        locality: data.locality,
        apartment: data.apartment,
      };
      dispatch(searchPost(formdata));
      setShowResults(false);
    }
  };
  const projectRedirect = (e, data) => {
    e.preventDefault();

    navigate(`/project-info/${data.apartment}`);

    if (isAuth) {
      const formdata = {
        userId: loginData._id,
        name: loginData.name,
        userMobile: loginData.mobile,
        propertyId: data._id,
        city: data.city,
        locality: data.locality,
        apartment: data.apartment,
      };
      dispatch(searchPost(formdata));
      setShowResults(false);
    }
  };
  const searchChange = (e) => {
    const result = e.target.value;
    setSearch(result);
  };

  const searchClick = () => {
    let mysubcat = "All";
    if (checkboxArray.length > 0) {
      mysubcat = checkboxArray.join();
      mysubcat = mysubcat.replaceAll(",", "-");
      mysubcat = mysubcat.replaceAll("/", "-");
      mysubcat = mysubcat.replaceAll(" ", "-");
    }

    navigate(`/property-search/${supercat}/${cat}/${mysubcat}/${search}`);
    setShowresult(false);
  };

  const searchsubmit = () => {
    let mysubcat = "All";
    if (checkboxArray.length > 0) {
      mysubcat = checkboxArray.join();
      mysubcat = mysubcat.replaceAll(",", "-");
      mysubcat = mysubcat.replaceAll("/", "-");
      mysubcat = mysubcat.replaceAll(" ", "-");
    }

    navigate(`/property-search/${supercat}/${cat}/${mysubcat}/${search}`);
    setShowresult(false);
  };
  return (
    <>
      <div className="dropdownbox "  style={{paddingTop:"0"}}>
        <div className="">
          <p className="">Property Types</p>
        </div>

        <div className="check-label">
          <div className="parentlabel">
            <div className="childlabel">
              <input
                type="checkbox"
                id="flat"
                name="flat"
                value={readyOffices}
                checked={readyOffices === true}
                onChange={(e) => checkboxChange(e, "Ready Offices")}
              />
              <label className="" htmlFor="flat">
                Ready to move offices
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="residential"
                name="residential"
                value={shop}
                checked={shop === true}
                onChange={(e) => checkboxChange1(e, "Shops & Retail")}
              />
              <label className="" htmlFor="residential">
                Shops & Retail
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="serviced"
                name="serviced"
                value={agricultural}
                checked={agricultural === true}
                onChange={(e) => checkboxChange2(e, "Agricultural/Farm Land")}
              />
              <label className="" htmlFor="serviced">
                Agricultural/Farm Land
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="serviced"
                name="serviced"
                value={warehouse}
                checked={warehouse === true}
                onChange={(e) => checkboxChange9(e, "Warehouse")}
              />
              <label className="" htmlFor="serviced">
                Warehouse
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="serviced"
                name="serviced"
                value={factory}
                checked={factory === true}
                onChange={(e) => checkboxChange10(e, "Factory & Manufacturing")}
              />
              <label className="" htmlFor="serviced">
                Factory & Manufacturing
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="serviced"
                name="serviced"
                value={others}
                checked={others === true}
                onChange={(e) => checkboxChange11(e, "Others")}
              />
              <label className="" htmlFor="serviced">
                Others
              </label>
            </div>
          </div>
          <div className="parentlabel">
            <div className="childlabel">
              <input
                type="checkbox"
                id="flat"
                name="flat"
                value={bareOffice}
                checked={bareOffice === true}
                onChange={(e) => checkboxChange8(e, "Bare shell offices")}
              />
              <label className="" htmlFor="flat">
                Bare shell offices
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="independent"
                name="independent"
                value={commercial}
                checked={commercial === true}
                onChange={(e) =>
                  checkboxChange3(e, "Commercial/Inst. Land")
                }
              />
              <label className="" htmlFor="independent">
                Commercial/Inst. Land
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="studio"
                name="studio"
                value={industrial}
                checked={industrial === true}
                onChange={(e) => checkboxChange4(e, "Industrial Land/Plots")}
              />
              <label className="" htmlFor="studio">
                Industrial Land/Plots
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="studio"
                name="studio"
                value={cold}
                checked={cold === true}
                onChange={(e) => checkboxChange5(e, "Cold Storage")}
              />
              <label className="" htmlFor="studio">
                Cold Storage
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="studio"
                name="studio"
                value={hotel}
                checked={hotel === true}
                onChange={(e) => checkboxChange17(e, "Hotel/Resorts")}
              />
              <label className="" htmlFor="studio">
                Hotel/Resorts
              </label>
            </div>
          </div>

          <div
            className="parentlabel"
            style={{ borderLeft: "1px solid gray", paddingLeft: "20px" }}
          >
            <div style={{ display: 'flex' }}>
              <div className="">
                <p className="">Investment Options</p>
              </div>
              <div className="clear-div" style={{ marginLeft: '10px' }}>
                <p className="">Clear</p>
              </div>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="villa"
                name="villa"
                value={leaseSpace}
                checked={leaseSpace === true}
                onChange={(e) => checkboxChange6(e, "Pre Leased Spaces")}
              />
              <label className="" htmlFor="villa">
                Pre Leased Spaces
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="farmhouse"
                name="farmhouse"
                value={foodCourt}
                checked={foodCourt === true}
                onChange={(e) => checkboxChange7(e, "Food Courts")}
              />
              <label className="" htmlFor="farmhouse">
                Food Courts
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="other"
                name="other"
                value={restaurant}
                checked={restaurant === true}
                onChange={(e) => checkboxChange12(e, "Restaurants")}
              />
              <label className="" htmlFor="other">
                Restaurants
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="other"
                name="other"
                value={multiplex}
                checked={multiplex === true}
                onChange={(e) => checkboxChange13(e, "Multiplexes")} />
              <label className="" htmlFor="other">
                Multiplexes
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="other"
                name="other"
                value={plot}
                checked={plot === true}
                onChange={(e) => checkboxChange14(e, "SCO Plots")}
              />
              <label className="" htmlFor="other">
                SCO Plots
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="other"
                name="other"
                value={coworking}
                checked={coworking === true}
                onChange={(e) => checkboxChange15(e, "Co-working")}
              />
              <label className="" htmlFor="other">
                Co-working
              </label>
            </div>
            <div className="childlabel">
              <input
                type="checkbox"
                id="other"
                name="other"
                value={business}
                checked={business === true}
                onChange={(e) => checkboxChange16(e, "Business Center")}
              />
              <label className="" htmlFor="other">
                Business Center
              </label>
            </div>
          </div>
        </div>
        <div className="label-caption">
          <p>
            Looking for commercial properties ?<a href="#">Click here</a>
          </p>
          <hr />
        </div>
        <div className="dropdown-lists">

          <div className="parent-box">
            <div
              className={toggle === "Budget" ?
                "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                : "main-boxes radioInput_textOnly__1r7GL "}
              onClick={() => setToggle("Budget")}
            >
              <label className="">Budget</label>
              {toggle === "Budget" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>
            {toggle === 'Budget' ? (
              <>
                <div className="range-box">
                  <div className="range-head">
                    <h6 className="">Select Price Range</h6>
                  </div>
                  <div className="range-bar">
                    <label htmlFor="customRange2" className="">
                      0 - 100 Crores+
                    </label>
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="example-thumb"
                      trackClassName="example-track"
                      defaultValue={[0, 1000000000]}
                      max={1000000000}
                      min={0}
                      renderThumb={(props, state) => (
                        <div {...props}>{state.valueNow}</div>
                      )}
                    // onChange={(value, index) => setPropertyPrice(value)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className="parent-box">
            <div
              className={toggle === "Area" ?
                "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                : "main-boxes radioInput_textOnly__1r7GL "}
              onClick={() => setToggle("Area")}
            >
              <label className="">Area </label>
              {toggle === "Area" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>
            {toggle === "Area" ? (
              <>
                <div className="range-box">
                  <div className="range-head">
                    <h6 className="">Select Area Range</h6>
                  </div>
                  <div className="range-bar">
                    <label htmlFor="customRange2" className="">
                      0 Sq.Ft - 10000 Sq.Ft
                    </label>
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="example-thumb"
                      trackClassName="example-track"
                      defaultValue={[0, 10000]}
                      max={10000}
                      min={0}
                      renderThumb={(props, state) => (
                        <div {...props}>{state.valueNow}</div>
                      )}
                    // onChange={(value, index) => setPropertyPrice(value)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className="parent-box">
            <div
              className={toggle === "Construction" ?
                "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                : "main-boxes radioInput_textOnly__1r7GL "}
              onClick={() => setToggle("Construction")}
            >
              <label className="">Construction Status</label>
              {toggle === "Construction" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>
            {toggle === "Construction" ? (
              <>
                <div className="bedroombox">
                  <div className="construction-head">
                    <h6 className="">Construction Status</h6>
                  </div>
                  <div className="construction-boxes"
                    style={{ marginTop: '-2px' }}>
                    <div
                      className={
                        underConstruction === true
                          ? "  tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0 "
                          : "  tags-and-chips_textOnly__2k10B pageComponent "
                      }
                      onClick={() => {
                        setUnderConstruction(!underConstruction);
                      }}
                    >
                      <label className="">+ Under Construction</label>
                    </div>
                    <div
                      className={
                        readytoMove === true
                          ? "  tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0 "
                          : "  tags-and-chips_textOnly__2k10B pageComponent "
                      }
                      onClick={() => {
                        setReadytoMove(!readytoMove);
                      }}
                    >
                      <label className="">+ Ready to move</label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className="parent-box">
            <div
              className={toggle === "Posted" ?
                "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                : "main-boxes radioInput_textOnly__1r7GL "}
              onClick={() => setToggle("Posted")}
            >
              <label className="">Posted By</label>
              {toggle === "Posted" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>
            {toggle === "Posted" ? (
              <>
                <div className="bedroombox">
                  <div className="posted-head" >
                    <h6 className="">Posted By</h6>
                  </div>
                  <div className="posted-main"
                    style={{ marginTop: '-2px' }}>
                    <div
                      className={
                        owner === true
                          ? "  tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0 "
                          : "  tags-and-chips_textOnly__2k10B pageComponent "
                      }
                      onClick={() => {
                        setOwner(!owner);
                      }}
                    >
                      <label className="">+ Owner</label>
                    </div>
                    <div
                      className={
                        builder === true
                          ? "  tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0 "
                          : "  tags-and-chips_textOnly__2k10B pageComponent "
                      }
                      onClick={() => {
                        setBuilder(!builder);
                      }}
                    >
                      <label className="">+ Builder</label>
                    </div>
                    <div
                      className={
                        deler === true
                          ? "  tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0 "
                          : "  tags-and-chips_textOnly__2k10B pageComponent "
                      }
                      onClick={() => {
                        setDeler(!deler);
                      }}
                    >
                      <label className="">+ Dealer</label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className=""></div>
        </div>
      </div>
    </>
  );
};

export default CommercialSearchComponent;
