import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { FcApproval, FcSms } from "react-icons/fc";

const PropertyAdvertiserModel = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div>
        <div className="newhead-one">
          <h4>You are requesting to view advertiser details.</h4>
        </div>

        <div className="newmodel-container-main newmain-top col-10">
          <div className="model-container-inner i_inner01 col-5">
            <h3 className="newmain-head-posted">POSTED BY BUILDER :</h3>
            <div className="newmodel-container-inner1">
              <h4>
                <span className="approval_icon">
                  <FcApproval />
                </span>
                +91 8008152569, golden@gmail.com
              </h4>
              <p>Sales Team</p>
            </div>
          </div>
          <div className="model-container-inner newmodelinner-sub col-5">
            <h3 className="newmain-head-posted">POSTED ON 06TH APR, 2023:</h3>
            <div className="newmodel-container-inner2">
              <h4>₹ 65.04 Lac to 78 Lac |THE ESPINO by Avantika</h4>
              <p>1084 - 1300 SQ.FT. | 2 BHK RESIDENTIAL APARTMENT</p>
            </div>
          </div>
        </div>
        <div className="usermodel-containermain_bottom">
          <span className="sms_icon">
            <FcSms />
          </span>
          <h4> Was your call with builder helpful?</h4>
          <ul>
            <li>Yes</li>
            <li>No</li>
            <li>Didn't pickup</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PropertyAdvertiserModel;
