import React, { useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import moment from "moment/moment";

import { Autoplay, FreeMode, Navigation } from "swiper/modules";
import { useSelector } from "react-redux";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const BoostedProperty = () => {
  const { loginData } = useSelector((store) => store.Athentication);
  const { userViewPropertyAll } = useSelector(
    (store) => store.UserViewProperty
  );
  const [allListing, setAllListing] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [boostProperties, setBoostProperties] = useState([]);
  const [bycity, setBycity] = useState("Hyderabad");
  const [propertySearch, setPropertySearch] = useState([]);

  useEffect(() => {
    try {
      const fetchdata = async () => {
        const url = `${Baseurl}/api/v1/property/boostedpropertybycity/${bycity}`;
        const fetchProperty = await axios.get(url);
        try {
          if (fetchProperty.data.success === true) {
            const searchresult = fetchProperty.data.propertybycity;
            setBoostProperties(searchresult);
          }
        } catch (error) {}
      };

      fetchdata();
    } catch (error) {}
  }, []);
  

  return (
    <>
      {boostProperties.length > 0 ? (
        <>
          <div className="recom-property">
            <div className="recom-head">
              <h1>Top Properties</h1>
              {/* <p>Curated especially for you</p> */}
            </div>
            <Link to="">
              <div className="recommended-swiper">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={30}
                  freeMode={true}
                  pagination={{
                    clickable: false,
                  }}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },

                    480: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },

                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },

                    840: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },

                    1000: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                  }}
                  modules={[Autoplay, FreeMode, Navigation]}
                  className="mySwiper"
                >
                  {boostProperties
                    .slice(0)
                    .reverse()
                    .map((property, index) => (
                      <SwiperSlide key={index}>
                        <div className="slick-slide-item">
                          <div className="">
                            <article className="geodir-category-listing fl-wrap">
                              <div className="geodir-category-img fl-wrap  agent_card">
                                {property.DeskImg.length > 0 ? (
                                  <>
                                    <Link
                                      to={`/property-info/${property.apartment}/${property.propertyID}`}
                                      className="geodir-category-img_item"
                                    >
                                      <img src={property.DeskImg[0]} alt="" />
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link
                                      to={`/property-info/${property.apartment}/${property.propertyID}`}
                                      className="geodir-category-img_item"
                                    >
                                      <img
                                        src="/assets/images/noimage1.png"
                                        alt=""
                                      />
                                    </Link>
                                  </>
                                )}

                                <div className="agent-card-social fl-wrap">
                                  <ul>
                                    <li>
                                      <Link to="" target="_blank">
                                        <i className="fa fa-star" />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                                <div className="listing-rating">
                                  <span className="re_stars-title price">
                                    ₹ {property.visiblePrice}
                                  </span>
                                </div>
                              </div>
                              <div className="geodir-category-content fl-wrap">
                                <div
                                  className="card-verified tolt"
                                  data-microtip-position="left"
                                  data-tooltip="Verified"
                                ></div>
                                <div className="agent_card-title fl-wrap">
                                  <h4>
                                    <Link
                                      to={`/property-info/${property.apartment}/${property.propertyID}`}
                                    >
                                      {property.apartment}
                                    </Link>
                                  </h4>
                                  <h5>
                                    <Link to="">{property.locality}</Link>
                                  </h5>
                                </div>
                                <div className="geodir-category-footer fl-wrap">
                                  <Link>{property.subCategory}</Link>
                                  <Link>
                                    {moment(property.updatedAt).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </Link>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </Link>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default BoostedProperty;
