import React from "react";
import { FcPhoneAndroid, FcInfo } from "react-icons/fc";
import { GrMail } from "react-icons/gr";
import { Link } from "react-router-dom";

const AllProducts = () => {
  return (
    <>
      <div className="pageComponent">
        <ul className="component__pageFilters component__fs_13">
          <li className="">
            <div className="activationStatus">
              <label className="">ACTIVATION STATUS</label>
              <ol className="component__filterList">
                <Link to="/all"></Link>
                <li className="component__activefilter ">Active</li>
                <li className="null">Reported</li>
                <li className="null">Underscreening</li>
                <li className="null">Expired</li>
                <li className="null">Deleted</li>
              </ol>
            </div>
          </li>
          <li className="sortFilters">
            <div className="">
              <span className="component__slidingCategory">Sort</span>
              <select className="component__selectInput">
                <option
                  value="New/Newest First"
                  className="component__selectInput"
                >
                  Newest First
                </option>
                <option value="New/Newest First">Expiring Last</option>
                <option value="Old/Oldest First">Oldest First</option>
                <option value="EXPIRING_FIRST/Expiring First">
                  Expiring First
                </option>
              </select>
            </div>
          </li>
          <li className="progListingFilter">
            <div className="">
              <span className="component__slidingCategory">
                Listing/Projects
              </span>
              <select className="component__selectInput">
                <option value="" className="component__selectInput">
                  Listing/Projects
                </option>
                <option value="Listings/Listings">Listings</option>
                <option value="Projects/Projects">Projects</option>
              </select>
            </div>
          </li>
        </ul>
      </div>

      <div className="component__allListingInfo">
        <div className="component__listingCount">
          <span>0</span> Active Products
        </div>
      </div>

      <div className="component__responceWrapper">
        <div className="pageComponent"></div>
        <div className="fslBooking__noResult">
          <h1>Sorry, No Results !</h1>
          <p>We cannot find the item you are searching for.</p>
          <div className="fslBooking__noResults"></div>
        </div>
      </div>

      <div className="getInTouch__getInTouch">
        <h4>FOR QUERIES YOU CAN REACH US AT:</h4>
        <div className="getInTouch__block">
          <ul>
            <li>
              <i className="getInTouch__icon">
                <FcPhoneAndroid />
              </i>
              <p>
                <b>Call us at:</b> 1800 41 99099
              </p>
              <p>
                For international numbers click
                <a>here</a>
              </p>
              <p>
                <b>SMS:</b> BUY as 56070
              </p>
            </li>
            <li className="getInTouch__mail">
              <i className="getInTouch__icon">
                <GrMail />
              </i>
              <p>
                <b>Mail us for Sales/Service/General Enquiries:</b>
                <a>services@mygoldenhomes.com</a>
              </p>
              <p>
                For international numbers click
                <a>here</a>
              </p>
            </li>
            <li className="getInTouch__request">
              <i className="getInTouch__icon">
                <FcInfo />
              </i>
              <p>
                <b>Request for Information:</b>
                <br />
                Ask us for information about our services.
                <a>Request Info</a>
              </p>
              <p>
                For international numbers click
                <a>here</a>
              </p>
            </li>
          </ul>
        </div>
        <div className="getInTouch__greyblock">
          <b>Office & Locations :</b>
          <a>Click here</a>
          for Branch Address
        </div>
      </div>
    </>
  );
};

export default AllProducts;
