import React from 'react'
import Header from '../../components/layouts/Header'
import Footer from '../../components/layouts/Footer'

const AboutUs = () => {
  return (
    <>
      <div>
        <Header/>
        <div style={{ paddingLeft: "23%", marginTop: "100px", marginBottom: "24px", marginLeft: "-65%" }}>
          <a href="" style={{ fontsize: "12px", color: "#42526E" }}>Home > </a>
          <a href="" style={{ fontsize: "12px", color: "#42526E" }}>About Us</a>
        </div>
        <div className='about_uscontent css-1dbjc4n'>
          <div className='css-1dbjc4n'>
            <div className='css-1dbjc4n r-xovx4i r-1xfd6ze r-bijgke'>
              <div className='css-1dbjc4n r-1awozwy r-6koalj r-eqz5dr'>
                <div className='css-1dbjc4n r-1uopjct r-145lgeb r-64el8z r-1kz6sp'>
                  <div className='css-1dbjc4n r-1mlwlqe r-1uopjct r-145lgeb r-64el8z r-1udh08x r-1kz6sp r-417010'>
                    <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                    >
                      <img src="./assets/images/all/AboutUs.shared.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className='css-1dbjc4n r-7sv4c2'></div>
                <h1 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-1x35g6 r-13uqrnb r-b88u0q r-37tt59 r-1v456y7' style={{ fontWeight: "bold", marginBottom:"10px"}}>
                  About Us
                </h1>
              </div>
            </div>
            <div className='css-1dbjc4n r-y47klf r-b5h31w r-145ew9a'>
              <div className='css-1dbjc4n r-db3y9k'>
                <div className='css-901oao r-um3v3g r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'>
                  Launched in 2005, Mygoldenhomes.com, India’s No. 1 property portal, deals with every aspect of the consumers’ needs in the real estate industry.
                  It is an online forum where buyers, sellers and brokers/agents can exchange information about real estate properties quickly, effectively and inexpensively.
                  At Mygoldenhomes.com, you can advertise a property, search for a property, browse through properties, build your own property microsite,
                  and keep yourself updated with the latest news and trends making headlines in the realty sector.
                </div>
              </div>
              <div className='css-1dbjc4n r-eu3ka'></div>
              <div className='css-1dbjc4n'>
                <h2 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59' style={{ fontWeight: "bold", marginBottom: "5px" }}> ✨ Why Mygoldenhomes.com?</h2>
                <div className='css-901oao r-um3v3g r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z r-knv0ih'>
                  At present, Mygoldenhomes.com prides itself for having around nine lakh property listings spanning across 600+ cities in India. Of all, the website held over 5.7 lakh paid listings at the end of FY 2018-19.
                  In addition to providing an online platform to real estate developers, brokers and property owners for listing their property for sale, purchase or rent, Mygoldenhomes.com offers advertisement stints such as microsites, banners,
                  home page links and project pages to the clients for better visibility and branding in the market.
                </div>
                <div className='css-901oao r-um3v3g r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'>
                  With the ever-evolving online search behaviour, Mygoldenhomes.com shares updated information pertinent to real estate activities,
                  assisting prospective buyers to make informed buying decision. We make online property search easier,
                  quicker and smarter!
                </div>
              </div>
              <div className='css-1dbjc4n r-eu3ka'></div>
              <div className='css-1dbjc4n r-1awozwy r-xovx4i r-1dzdj1l r-6koalj r-18u37iz r-98loyc r-13uqrnb r-117bsoe r-1oqcu8e awards_recognition'>
                <div className='css-1dbjc4n r-uvuy5l r-1mf7evn r-h10h58 r-1w1cp9x r-uahozn'>
                  <div className='css-1dbjc4n r-1mlwlqe r-uvuy5l r-1mf7evn r-h10h58 r-1w1cp9x r-1udh08x r-uahozn r-417010'>
                    <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw' style={{ backgroundIimage: 'url(&quot;/universalhp/img/award.shared.png&quot;)' }}>
                      <img src="./assets/images/all/award.shared.png" alt="" />
                    </div>
                  </div>
                </div>
                <h3 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-vrz42v r-13qz1uu' style={{ fontWeight: "bold" }}>Awards & Recognitions</h3>
              </div>
              <div className='css-1dbjc4n'>
                <div className='css-1dbjc4n r-6koalj r-18u37iz'>
                  <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
                  <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px" }}>
                    PropTech Mobile App of the Year Award 2019
                  </div>
                </div>
                <div className='css-1dbjc4n r-6koalj r-18u37iz'>
                  <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
                  <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px" }}>
                    Mygoldenhomes.com won the award for ‘Personalized User Journey
                  </div>
                </div>
                <div className='css-1dbjc4n r-6koalj r-18u37iz'>
                  <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
                  <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px" }}>
                    Best Mobile Appies Award 2015
                  </div>
                </div>
                <div className='css-1dbjc4n r-6koalj r-18u37iz'>
                  <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
                  <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px" }}>
                    Mygoldenhomes.com won the award for having the ‘Most Innovative Mobile App’ in the real estate category
                  </div>
                </div>
                <div className='css-1dbjc4n r-6koalj r-18u37iz'>
                  <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
                  <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px" }}>
                    CMO ASIA Awards 2012
                  </div>
                </div>
                <div className='css-1dbjc4n r-6koalj r-18u37iz'>
                  <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
                  <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px" }}>
                    Mygoldenhomes.com was awarded the ‘Most Admired Real Estate Website of the Year’ at the 3rd CMO Asia Awards for excellence in the real estate segment.
                  </div>
                </div>
                <div className='css-1dbjc4n r-6koalj r-18u37iz'>
                  <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
                  <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px" }}>
                    BCI Awards 2012
                  </div>
                </div>
                <div className='css-1dbjc4n r-6koalj r-18u37iz'>
                  <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
                  <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px" }}>
                    Mygoldenhomes.com was recognised as the ‘Best Real Estate Portal’ in 2012.
                  </div>
                </div>
                <div className='css-1dbjc4n r-6koalj r-18u37iz'>
                  <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
                  <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px" }}>
                    Accommodation Times Awards 2012
                  </div>
                </div>
                <div className='css-1dbjc4n r-6koalj r-18u37iz'>
                  <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
                  <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px" }}>
                    Mygoldenhomes.com was announced the ‘Best Online Realty Portal’ by the Accommodation Times in 2012.
                  </div>
                </div>
              </div>
              <div className='css-1dbjc4n r-2o02ov'>
                <div className='css-901oao r-1wy1o97 r-1b43r93 r-1kfrs79 r-15d164r'>
                  Connect with us:
                </div>
                <div className='css-1dbjc4n r-6koalj r-18u37iz'>
                  <div className='css-1dbjc4n r-1r8g8re r-1b7u577 r-2yi16 r-1qi8awa r-1acpoxo'>
                    <div className='css-1dbjc4n r-1jkafct r-1mlwlqe r-1r8g8re r-1b7u577 r-2yi16 r-1qi8awa r-1udh08x r-1acpoxo r-417010'>
                      <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw' style={{ backgroundImage: 'url(&quot;/universalhp/img/twitter.shared.png&quot;)' }}></div>
                      <img src="/assets/images/all/twitter1.PNG" alt="" />
                    </div>
                  </div>
                  <div className='css-1dbjc4n r-1r8g8re r-1b7u577 r-2yi16 r-1qi8awa r-1acpoxo'>
                    <div className='css-1dbjc4n r-1jkafct r-1mlwlqe r-1r8g8re r-1b7u577 r-2yi16 r-1qi8awa r-1udh08x r-1acpoxo r-417010'>
                      <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw' style={{ backgroundImage: 'url(&quot;/universalhp/img/twitter.shared.png&quot;)' }}></div>
                      <img src="/assets/images/all/fb1.png" alt="" />
                    </div>
                  </div>
                  <div className='css-1dbjc4n r-1r8g8re r-1b7u577 r-2yi16 r-1qi8awa r-1acpoxo'>
                    <div className='css-1dbjc4n r-1jkafct r-1mlwlqe r-1r8g8re r-1b7u577 r-2yi16 r-1qi8awa r-1udh08x r-1acpoxo r-417010'>
                      <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw' style={{ backgroundImage: 'url(&quot;/universalhp/img/twitter.shared.png&quot;)' }}></div>
                      <img src="/assets/images/all/youtube1.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className='css-1dbjc4n r-eu3ka'></div>
            </div>
          </div>
        </div>
        <Footer/>
      </div >
    </>
  )
}

export default AboutUs