import React from "react";

const Amenities = () => {
  const amenities = [
    { id: "1", name: "Parking" },
    { id: "2", name: "Lift" },
    { id: "3", name: "Power Backup" },
    { id: "4", name: "Park" },
    { id: "5", name: "Gymnasium" },
    { id: "6", name: "Club house" },
    { id: "7", name: "Security Personnel" },
    { id: "8", name: "Swimming Pool" },
    { id: "9", name: "Gas Pipeline" },
  ];
  return (
    <div className="mainbarfiltercomponents">
      {amenities.map((amenitie, index) => (
        <div className="propertyboxes" key={index}>
          <input type="checkbox" />
          <p className="propertytextpara">{amenitie.name}</p>
        </div>
      ))}

      <div>
        <p className="clearalltext">Select All</p>
      </div>
    </div>
  );
};

export default Amenities;
