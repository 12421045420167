import React from 'react'

const PropertyAlerts = () => {
  return (
    <>
      <div className='content c'>
        <div className='pAlertBox'>
          <div className='pAhead'>
            <div className='lf f20 mt10 b'>Property Alerts</div>
            <input type="button" className='rf f13 yellowSubmit' value={"Create New Alert"} />
            <div className='clr pBdrBtm mt50'></div>
          </div>
          <div className='f24 textc mt20 mb20 grey3 b'>How do property alerts work?</div>
          <div className='invite'>
            <div className='cBxWpr clearfix'>
              <div className='cBox'>
                <div className='mt50'>
                  <i className='myIcn ni1'><img src="/assets/images/all/camImg1.png" alt="" /></i>
                  <div className='clearfix p5 ml30'>
                    <div className='f45 lf b'>1</div>
                    <div className='lf wd1'>Tell us your needs</div>
                  </div>
                </div>
              </div>
              <div className='cBox'>
                <div className='mt50'>
                  <i className='myIcn ni2'><img src="/assets/images/all/camImg1.png" alt="" /></i>
                  <div className='clearfix p5 ml30'>
                    <div className='f45 lf b'>2</div>
                    <div className='lf wd1'>We match properties</div>
                  </div>
                </div>
              </div>
              <div className='cBox'>
                <div className='mt50'>
                  <i className='myIcn ni3'></i>
                  <div className='clearfix p5 ml30'>
                    <div className='f45 lf b'>3</div>
                    <div className='lf wd1'>You get properties to your inbox</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PropertyAlerts