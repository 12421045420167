import React, { useEffect, useState } from "react";
import { BsStar, BsArrowRight } from "react-icons/bs";
import { MdViewInAr } from "react-icons/md";
import { BsChevronDown, BsGraphUp, BsDropletFill } from "react-icons/bs";
import { FaLongArrowAltRight } from "react-icons/fa";
import {
  BiSolidInfoCircle,
  BiSolidTraffic,
  BiSolidStar,
  BiSolidStarHalf,
  BiLike,
  BiDislike,
} from "react-icons/bi";
import {
  MdOutlineVerticalAlignTop,
  MdAddRoad,
  MdEnergySavingsLeaf,
  MdLocalDining,
} from "react-icons/md";
import {
  AiFillLike,
  AiFillDislike,
  AiFillCar,
  AiFillSafetyCertificate,
} from "react-icons/ai";
import { FiArrowRight } from "react-icons/fi";
import { SlGraph } from "react-icons/sl";
import { GiFlowerPot } from "react-icons/gi";
import { HiBuildingOffice2 } from "react-icons/hi2";
import FloorPlansSwiper from "./PropertyInfo/FloorPlansSwiper";
import RecomPropertySwiper from "./PropertyInfo/RecomPropertySwiper";
import SellersMayContact from "./PropertyInfo/SellersMayContact";
import LocationAdventages from "./PropertyInfo/LocationAdventages";
import ReviewsByResidents from "./PropertyInfo/ReviewsByResidents";
import ProjectByAliens from "./PropertyInfo/ProjectByAliens";
import InterestingReads from "./PropertyInfo/InterestingReads";
import RelatedToYourSearch from "./PropertyInfo/RelatedToYourSearch";

const SwiperRecommendedProperty = () => {
  const [addClass, setAddClass] = useState("");

  window.addEventListener("scroll", () => {
    const fixedElement = document.querySelector(".fixed-element");
    const scrollY = window.scrollY;

    // if (scrollY >= 300 && scrollY <= 3850) {
    //   fixedElement.add("sticky-active");
    // } else {
    //   fixedElement.remove("sticky-active");
    // }
  });

  return (
    <>
      <div className="content">
        <section
          className="hidden-section  single-hero-section"
          data-scrollax-parent="true"
          id="sec1"
        >
          <div className="bg-wrap bg-parallax-wrap-gradien">
            <div
              className="bg par-elem "
              data-bg="/assets/images/all/blog/2.jpg"
              data-scrollax="properties: { translateY: '30%' }"
            />
          </div>
          <div className="container">
            <div className="list-single-opt_header fl-wrap">
              <ul className="list-single-opt_header_cat">
                <li>
                  <a href="#" className="cat-opt color-bg">
                    Rent
                  </a>
                </li>
                <li>
                  <a href="#" className="cat-opt blue-bg">
                    Sale
                  </a>
                </li>
                <li>
                  <a href="#" className="cat-opt color-bg">
                    Apartment
                  </a>
                </li>
              </ul>
            </div>
            <div className="list-single-header-item no-bg-list_sh fl-wrap">
              <div className="row">
                <div className="col-md-12">
                  <h1>
                    House in Financial District
                    <span
                      className="verified-badge tolt"
                      data-microtip-position="bottom"
                      data-tooltip="Verified"
                    >
                      <i className="fas fa-check" />
                    </span>
                  </h1>
                  <div className="geodir-category-location fl-wrap">
                    <a href="#">
                      <i className="fas fa-map-marker-alt" /> 70 Bright St New
                      York, USA
                    </a>
                    <div
                      className="listing-rating card-popup-rainingvis"
                      data-starrating2={4}
                    >
                      <span className="re_stars-title">Good</span>
                    </div>
                  </div>
                  <div className="share-holder hid-share">
                    <a href="#" className="share-btn showshare sfcs">
                      <i className="fas fa-share-alt" /> Share
                    </a>
                    <div className="share-container  isShare" />
                  </div>
                </div>
              </div>
              <div className="list-single-header-footer fl-wrap">
                <div
                  className="list-single-header-price"
                  data-propertyprise={50500}
                >
                  <strong>Price:</strong>
                  <span>$</span>50.500
                </div>
                <div className="list-single-header-date">
                  <span>Date:</span>20.05.2020
                </div>
                <div className="list-single-stats">
                  <ul className="no-list-style">
                    <li>
                      <span className="viewed-counter">
                        <i className="fas fa-eye" /> Viewed - 156
                      </span>
                    </li>
                    <li>
                      <span className="bookmark-counter">
                        <i className="fas fa-heart" /> Bookmark - 24
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="breadcrumbs fw-breadcrumbs smpar fl-wrap">
          <div className="container">
            <div className="breadcrumbs-list">
              <a href="#">Home</a>
              <span>Property Single</span>
            </div>
            <div className="show-more-snopt smact">
              <i className="fal fa-ellipsis-v" />
            </div>
            <div className="show-more-snopt-tooltip">
              <a href="#sec15" className="custom-scroll-link">
                <i className="fas fa-comment-alt" /> Write a review
              </a>
              <a href="#">
                <i className="fas fa-exclamation-triangle" /> Report
              </a>
            </div>
            <div className="like-btn">
              <i className="fas fa-heart" /> Save
            </div>
          </div>
        </div>
        <div className="gray-bg small-padding fl-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="swiper-recom-prpty">
                  <div className="swiper-recom-prpty-left">
                    <div className="swiper-recom-prpty-left-images">
                      <div className="swiper-recom-prpty-left-images-inner1">
                        <img src="/assets/images/sliderImg/Img011.jpg" alt="" />
                      </div>
                      <div className="swiper-recom-prpty-left-images-inner2">
                        <img src="/assets/images/sliderImg/Img022.jpg" alt="" />
                        <img src="/assets/images/sliderImg/Img033.jpg" alt="" />
                      </div>
                    </div>
                    <div className="swiper-recom-prpty-left-content">
                      <p>
                        Home › Projects in Hyderabad › Gachibowli › Aliens Space
                        Station
                      </p>
                      <div className="recom-prpty-left-content-head">
                        <div className="recom-prpty-left-content-head-img-inner">
                          <div className="recom-prpty-left-content-head-img">
                            <img
                              src="/assets/images/sliderImg/1e19sl7t.png"
                              alt=""
                            />
                          </div>
                          <div className="recom-prpty-left-content-head-text">
                            <h1>
                              Aliens Space Station <BsStar />
                            </h1>
                            <p>Gachibowli, Hyderabad</p>
                          </div>
                        </div>
                        <div className="recom-prpty-left-content-head-button">
                          <button className="sml-btn">View Number</button>
                        </div>
                      </div>
                    </div>
                    <div className="prpty-left-content1">
                      <div className="prpty-left-content1-inner1">
                        <p>No Brokerage</p>
                      </div>
                      <div className="prpty-left-content1-inner2">
                        <p>
                          <MdViewInAr /> 3D Floor Plans Available
                        </p>
                      </div>
                      <div className="prpty-left-content1-inner3">
                        <p>+40 Top Facilities</p>
                      </div>
                    </div>
                    <div className="prpty-left-content2">
                      <div className="prpty-left-content2-inner">
                        <div className="prpty-left-content2-inner-head">
                          <h2>CONSTRUCTION STATUS</h2>
                        </div>
                        <div className="prpty-left-content2-inner-sub">
                          <div className="content2-inner-text">
                            <h1>Under Construction</h1>
                            <p>Completion in Sep, 2024</p>
                          </div>
                          <div className="content2-inner-icon">
                            <BsChevronDown />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="prpty-left-content3">
                      <RecomPropertySwiper />
                    </div>
                    <div className="prpty-left-content4">
                      <FloorPlansSwiper />
                    </div>
                    <div className="prpty-left-content5">
                      <SellersMayContact />
                    </div>
                    <div className="prpty-left-content6">
                      <div className="topfacilities">
                        <div className="topfacilities-head">
                          <h2>Top Facilities</h2>
                          <a>View All (48)</a>
                        </div>
                        <p className="topfacilities-subhead">
                          Aliens Space Station Hyderabad presents an exclusive
                          opportunity to own a stunning home that offers all
                          kinds of amenities and facilities. This includes a
                          mini theatre, swimming pool, and easy access to golf
                          course. It also has an atm, an exclusive offering only
                          for Aliens Space Station residents . Aliens Space
                          Station has an excellent combination of comfort and
                          convenience to suit every requirement as well as need.
                        </p>
                        <div classname="topfacilities-item">
                          <div className="topfacilities-cards">
                            <div className="topfacilities-subcards">
                              <div className="topfacilities-card-img">
                                <div className="topfacilities-card-facilities">
                                  <img
                                    src="/assets/images/sliderImg/mini_theatre.png"
                                    alt=""
                                  />
                                  <div>Mini Therater</div>
                                </div>
                                <div className="bg-different"></div>
                              </div>
                            </div>
                            <div className="topfacilities-subcards">
                              <div className="topfacilities-card-img">
                                <div className="topfacilities-card-facilities">
                                  <img
                                    src="/assets/images/sliderImg/swimming_pool.png"
                                    alt=""
                                  />
                                  <div>Swimming Pool</div>
                                </div>
                                <div className="bg-different1"></div>
                              </div>
                            </div>
                            <div className="topfacilities-subcards">
                              <div className="topfacilities-card-img">
                                <div className="topfacilities-card-facilities">
                                  <img
                                    src="/assets/images/sliderImg/golf_course.png"
                                    alt=""
                                  />
                                  <div>Golf Course</div>
                                </div>
                                <div className="bg-different"></div>
                              </div>
                            </div>
                            <div className="topfacilities-subcards">
                              <div className="topfacilities-card-img">
                                <div className="topfacilities-card-facilities">
                                  <img
                                    src="/assets/images/sliderImg/atm.png"
                                    alt=""
                                  />
                                  <div>Atm</div>
                                </div>
                                <div className="bg-different1"></div>
                              </div>
                            </div>
                            <div className="topfacilities-subcards">
                              <div className="topfacilities-card-img">
                                <div className="topfacilities-card-facilities">
                                  <img
                                    src="/assets/images/sliderImg/barbecue.png"
                                    alt=""
                                  />
                                  <div>Barbecue</div>
                                </div>
                                <div className="bg-different"></div>
                              </div>
                            </div>
                            <div className="topfacilities-subcards">
                              <div className="topfacilities-card-img">
                                <div className="topfacilities-card-facilities">
                                  <img
                                    src="/assets/images/sliderImg/restaurant.png"
                                    alt=""
                                  />
                                  <div>Restaurant</div>
                                </div>
                                <div className="bg-different1"></div>
                              </div>
                            </div>
                            <div className="topfacilities-subcards">
                              <div className="topfacilities-card-img">
                                <div className="topfacilities-card-facilities">
                                  <img
                                    src="/assets/images/sliderImg/sauna.png"
                                    alt=""
                                  />
                                  <div>Suana</div>
                                </div>
                                <div className="bg-different"></div>
                              </div>
                            </div>
                            <div className="topfacilities-subcards">
                              <div className="topfacilities-card-img">
                                <div className="topfacilities-card-facilities">
                                  <img
                                    src="/assets/images/sliderImg/spa.png"
                                    alt=""
                                  />
                                  <div>Spa</div>
                                </div>
                                <div className="bg-different1"></div>
                              </div>
                            </div>
                            <div className="topfacilities-subcards">
                              <div className="topfacilities-card-img">
                                <div className="topfacilities-card-facilities">
                                  <img
                                    src="/assets/images/sliderImg/day_care_centre.png"
                                    alt=""
                                  />
                                  <div>Daycare Centre</div>
                                </div>
                                <div className="bg-different"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="topfacilities-bottomtext">
                          <span>View Facility Photos</span>
                          <i>
                            <FaLongArrowAltRight />
                          </i>
                        </div>
                      </div>
                      <div className="prpty-left-content7">
                        <LocationAdventages />
                      </div>
                      <div className="prpty-left-content8">
                        <div>
                          <h2 className="left-content8-title">
                            More about Aliens Space Station
                          </h2>
                        </div>
                        <div className="left-content8-body">
                          <span>
                            Check out Aliens Space Station in Gachibowli, one of
                            the upcoming under-construction housing societies in
                            Hyderabad. There are apartments for sale in Aliens
                            Space Station. This society will have all basic
                            facilities and amenities to suit homebuyer’s needs
                            and requirements. Brought to you by Aliens Group,
                            Aliens Space Station is scheduled for possession in
                            Sep, 2024.
                          </span>
                        </div>
                        <a className="left-content8-footer">
                          <div className="left-content8-footer-head">
                            <span>Interiors and Other Specifications</span>
                            <i>
                              <FaLongArrowAltRight />
                            </i>
                          </div>
                          <div className="left-content8-footer-caption">
                            FOUNDATION Reinforced concrete raft foundation SUPER
                            STRUCTURE RCC shear walls framed structure,
                            resistant to wind and earthquake (Zone 2) Transverse
                            slab introduced at Level 1 to have more column free
                            space and unhindered view at Ground level leading to
                            efficient car parking management at basement
                          </div>
                          <hr></hr>
                        </a>
                      </div>

                      <div className="prpty-left-content10">
                        <div className="priceinsights">
                          <div className="priceinsights-head">
                            <h2>Top Facilities</h2>
                            <p>
                              For Aliens Space Station, Gachibowli, Hyderabad
                            </p>
                          </div>
                          <ul className="priceinsights-wrap">
                            <li className="priceinsights-wrap-in">
                              <div className="priceinsights-wrap-img">
                                <img
                                  src="/assets/images/sliderImg/D11.png"
                                  alt=""
                                />
                              </div>
                              <div className="priceinsights-wrap-div">
                                <div className="priceinsights-wrap-text">
                                  <h2>Property Prices decreased by 7.3%</h2>
                                  <a>
                                    in Aliens Space Station since last one year
                                  </a>
                                </div>
                              </div>
                            </li>
                            <li className="priceinsights-wrap-in">
                              <div className="priceinsights-wrap-img">
                                <img
                                  src="/assets/images/sliderImg/D12.png"
                                  alt=""
                                />
                              </div>
                              <div className="priceinsights-wrap-div">
                                <div className="priceinsights-wrap-text">
                                  <h2>
                                    Property price range starts from 1.12 Cr
                                  </h2>
                                  <a>but may vary across property types</a>
                                </div>
                              </div>
                            </li>
                            <li className="priceinsights-wrap-in">
                              <div className="priceinsights-wrap-img">
                                <img
                                  src="/assets/images/sliderImg/D13.png"
                                  alt=""
                                />
                              </div>
                              <div className="priceinsights-wrap-div">
                                <div className="priceinsights-wrap-text">
                                  <h2>Property Prices increased by 32.6%</h2>
                                  <a>in Gachibowli since last one year</a>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prpty-left-content11">
                        <div className="checkproperty-banner">
                          <img
                            src="/assets/images/sliderImg/Banner11.png"
                            alt=""
                          />
                          <div>
                            Check Property Rates & Trends for any locality or
                            society
                          </div>
                          <button>
                            See Price Trends <FaLongArrowAltRight />
                          </button>
                        </div>
                        <div className="checkproperty-banner-report">
                          <i>
                            <BiSolidInfoCircle />
                          </i>
                          <span className="banner-report-text">
                            If the project details provided on 99acres are
                            incorrect,
                          </span>
                          <span className="banner-report-text-here">
                            Report here
                          </span>
                        </div>
                      </div>
                      <div className="prpty-left-content12">
                        <div className="explore-gachibowli">
                          <div className="gachibowli-head">
                            <div>Explore Gachibowli</div>
                            <a>West Hyderabad | Pincode - 500032</a>
                          </div>
                          <div className="gachibowli-rating">
                            <div className="gachibowli-rating-in">
                              <div className="h-badge1">
                                <span>#1 in Top 100 in West Hyderabad</span>
                              </div>
                              <div className="h-badge2">
                                <span>
                                  <i>
                                    <MdOutlineVerticalAlignTop />
                                  </i>
                                  32.6% YoY
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="gachibowli-table">
                            <div className="gachibowli-table-col">
                              <div className="gachibowli-table-col-head">
                                <div className="table-col-headicon">
                                  <i className="ai-like">
                                    <AiFillLike />
                                  </i>
                                </div>
                                <div className="table-col-head">
                                  What's great here!
                                </div>
                              </div>
                              <div className="gachibowli-table-col-subhead">
                                <div className="table-col-headicon">
                                  <i>
                                    <SlGraph />
                                  </i>
                                </div>
                                <div className="table-col-subhead">
                                  Gachibowli is a popular
                                  commercial-cum-residential hub offering
                                  housing in the premium price segment
                                </div>
                              </div>
                              <div className="gachibowli-table-col-subhead">
                                <div className="table-col-headicon">
                                  <i>
                                    <MdAddRoad />
                                  </i>
                                </div>
                                <div className="table-col-subhead">
                                  Located along the Nehru Outer Ring Road, the
                                  area is well connected to NH-44 ad NH-65
                                </div>
                              </div>
                              <div className="gachibowli-table-col-subhead">
                                <div className="table-col-headicon">
                                  <i>
                                    <BsGraphUp />
                                  </i>
                                </div>
                                <div className="table-col-subhead">
                                  Rental demand is largely driven by the IT
                                  workforce and the student population of ISB
                                  and the University of Hyderabad
                                </div>
                              </div>
                              <div className="gachibowli-table-col-subhead">
                                <div className="table-col-headicon">
                                  <i>
                                    <BsGraphUp />
                                  </i>
                                </div>
                                <div className="table-col-subhead">
                                  Rental demand is largely driven by the IT
                                  workforce and the student population of ISB
                                  and the University of Hyderaba...
                                  <span>more</span>
                                </div>
                              </div>
                            </div>
                            <div className="gachibowli-table-col">
                              <div className="gachibowli-table-col-head">
                                <div className="table-col-headicon">
                                  <i className="ai-dislike">
                                    <AiFillDislike />
                                  </i>
                                </div>
                                <div className="table-col-head">
                                  What needs attention!
                                </div>
                              </div>
                              <div className="gachibowli-table-col-subhead">
                                <div className="table-col-headicon">
                                  <i>
                                    <BsDropletFill />
                                  </i>
                                </div>
                                <div className="table-col-subhead">
                                  Limited water supply in Gachibowli results in
                                  dependence on water tankers
                                </div>
                              </div>
                              <div className="gachibowli-table-col-subhead">
                                <div className="table-col-headicon">
                                  <i>
                                    <MdEnergySavingsLeaf />
                                  </i>
                                </div>
                                <div className="table-col-subhead">
                                  Waterlogging and power cuts are common
                                  occurrences during monsoons
                                </div>
                              </div>
                              <div className="gachibowli-table-col-subhead">
                                <div className="table-col-headicon">
                                  <i>
                                    <BiSolidTraffic />
                                  </i>
                                </div>
                                <div className="table-col-subhead">
                                  Heavy vehicular movement leads to traffic
                                  congestion in a few parts of the locale
                                </div>
                              </div>
                              <div className="gachibowli-table-col-subhead">
                                <div className="table-col-headicon">
                                  <i>
                                    <HiBuildingOffice2 />
                                  </i>
                                </div>
                                <div className="table-col-subhead">
                                  Garbage burning near Masjid Banda is a
                                  frequent complaint raised by the resident...
                                  <span>more</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 fixed-element fixed-element1">
                <div className="box-widget fl-wrap sticky-active sticky-close">
                  <div className="profile-widget">
                    <div className="profile-widget-in">
                      <div className="recom-prpty-right-swiper-card">
                        <div className="recom-prpty-right-swiper-card-text-top swiper-recom-prpty-right">
                          <div className="recom-prpty-right-swiper-card-text-top-in">
                            <div className="recom-prpty-right-swiper-card-text-top-in-img">
                              <img
                                src="/assets/images/sliderImg/D12.png"
                                alt=""
                              />
                            </div>
                            <h2>
                              Why you should consider Aliens Space Station?
                            </h2>
                          </div>
                          <ul className="recom-prpty-right-swiper-card-text">
                            <li className="right-swiper-card-text-top-in-text">
                              <p>
                                Double height car parking - Dual Car Parking per
                                car park possible by adding a mechanical car
                                parking.
                              </p>
                            </li>
                            <li className="right-swiper-card-text-top-in-text">
                              <p>
                                5th generation Home automation system is
                                available in flat and electrical points
                              </p>
                            </li>
                            <li className="right-swiper-card-text-top-in-text">
                              <p>
                                80% open Area and only 20% covered area with
                                only one flat per floor
                              </p>
                            </li>
                            <div className="right-viewmore-link">
                              <h2>
                                View 4 more
                                <i>
                                  <BsArrowRight />
                                </i>
                              </h2>
                            </div>
                          </ul>
                          <div className="recom-prpty-right-footer">
                            <div className="recom-prpty-right-foot-text">
                              <div className="recom-prpty-right-foot-text-in">
                                <div className="foot-name-bold">
                                  DEVELOPED BY
                                </div>
                                <div className="foot-name-semibold">
                                  Aliens Developers Pvt Ltd
                                </div>
                              </div>
                              <div className="recom-prpty-right-foot-img">
                                <img
                                  src="/assets/images/sliderImg/H11.jpeg"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="prpty-right-footer-button">
                              <button>Contact Builder</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="prpty-left-content13 fl-wrap">
                <div className="locality-reviews">
                  <div className="locality-reviews-head">
                    <h2>Locality Reviews</h2>
                    <a>for Gachibowli, Hyderabad</a>
                  </div>
                  <div className="reviews-wrap">
                    <div className="reviews-left-wrap">
                      <div className="reviews-wrap-comp">
                        <div className="reviews-ratingarea">
                          <div className="inner-ratingarea">
                            <div className="inner-bold">4.4</div>
                            <div className="inner-small">/5</div>
                          </div>
                          <div className="star-ratings">
                            <div className="star-ratings-inner">
                              <div className="star-in">
                                <BiSolidStar />
                              </div>
                              <div className="star-in">
                                <BiSolidStar />
                              </div>
                              <div className="star-in">
                                <BiSolidStar />
                              </div>
                              <div className="star-in">
                                <BiSolidStar />
                              </div>
                              <div className="star-in">
                                <BiSolidStarHalf />
                              </div>
                            </div>
                          </div>
                          <div className="star-ratings-average">
                            Average Rating
                          </div>
                          <div className="star-ratings-count">
                            (3843 Total Reviews)
                          </div>
                        </div>
                        <div className="reviews-ratingwrap">
                          <div className="ratingwrap-component">
                            <div className="ratingwrap-component-inner rating-level-wrap">
                              <div className="reviews-row-wrap">
                                <div style={{ width: "41.478%" }}></div>
                              </div>
                              <div className="reviews-row-wrap-text">
                                <div className="row-wrap-number">5</div>
                                <div className="row-wrap-star">
                                  <BiSolidStar />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ratingwrap-component">
                            <div className="ratingwrap-component-inner">
                              <div className="reviews-row-wrap">
                                <div style={{ width: "38.478%" }}></div>
                              </div>
                              <div className="reviews-row-wrap-text">
                                <div className="row-wrap-number">4</div>
                                <div className="row-wrap-star">
                                  <BiSolidStar />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ratingwrap-component">
                            <div className="ratingwrap-component-inner">
                              <div className="reviews-row-wrap">
                                <div style={{ width: "50.478%" }}></div>
                              </div>
                              <div className="reviews-row-wrap-text">
                                <div className="row-wrap-number">3</div>
                                <div className="row-wrap-star">
                                  <BiSolidStar />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ratingwrap-component">
                            <div className="ratingwrap-component-inner">
                              <div className="reviews-row-wrap">
                                <div style={{ width: "7.478%" }}></div>
                              </div>
                              <div className="reviews-row-wrap-text">
                                <div className="row-wrap-number">2</div>
                                <div className="row-wrap-star">
                                  <BiSolidStar />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ratingwrap-component">
                            <div className="ratingwrap-component-inner">
                              <div className="reviews-row-wrap">
                                <div style={{ width: "5.478%" }}></div>
                              </div>
                              <div className="reviews-row-wrap-text">
                                <div className="row-wrap-number">1</div>
                                <div className="row-wrap-star">
                                  <BiSolidStar />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="reviews-ratingfooter">
                          See how ratings are calculated
                        </div>
                      </div>
                    </div>
                    <div className="reviews-right-wrap">
                      <div>
                        <div className="right-wrap-head">
                          <span>Ratings by Features</span>
                          <i>
                            <BiSolidInfoCircle />
                          </i>
                        </div>
                        <ul>
                          <li>
                            <div className="rating-features-container">
                              <div className="rating-features-circle">
                                <div className="features-circle-in">
                                  <i>
                                    <GiFlowerPot />
                                  </i>
                                </div>
                                <div className="rating-features-text">
                                  <div className="rating-features-text-head">
                                    Environment
                                  </div>
                                  <div className="rating-features-text-subhead">
                                    4.2 out of 5
                                  </div>
                                </div>
                              </div>
                              <div className="rating-features-circle">
                                <div className="features-circle-in">
                                  <i>
                                    <AiFillCar />
                                  </i>
                                </div>
                                <div className="rating-features-text">
                                  <div className="rating-features-text-head">
                                    Connectivity
                                  </div>
                                  <div className="rating-features-text-subhead">
                                    4.4 out of 5
                                  </div>
                                </div>
                              </div>
                              <div className="rating-features-circle">
                                <div className="features-circle-in">
                                  <i>
                                    <AiFillSafetyCertificate />
                                  </i>
                                </div>
                                <div className="rating-features-text">
                                  <div className="rating-features-text-head">
                                    Safety
                                  </div>
                                  <div className="rating-features-text-subhead">
                                    4.2 out of 5
                                  </div>
                                </div>
                              </div>
                              <div className="rating-features-circle">
                                <div className="features-circle-in">
                                  <i>
                                    <MdLocalDining />
                                  </i>
                                </div>
                                <div className="rating-features-text">
                                  <div className="rating-features-text-head">
                                    Lifestyle
                                  </div>
                                  <div className="rating-features-text-subhead">
                                    4.4 out of 5
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div className="list-down-head">Popular Mentions</div>
                        <div>
                          <div className="popular-mentions">
                            <div className="popular-mentions-in">
                              <div className="postive-mentions"></div>
                              <div className="pink-bar-mentions"></div>
                            </div>
                            <div className="popular-mentions-text">
                              <div className="popular-mentions-headtext">
                                100% Positive Mentions
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="popular-mentions-review-count">
                          <div className="popular-mentions-openion">
                            <i>
                              <BiLike />
                            </i>
                            Likes
                          </div>
                          <ul className="popular-mentions-list">
                            <li>
                              <div>
                                Good Public Transport<span>(2389)</span>
                              </div>
                              {/* <div>2389 mentions</div> */}
                            </li>
                            <li>
                              <div>
                                Easy Cab/Auto Availability<span>(1898)</span>
                              </div>
                              {/* <div>1898 mentions</div> */}
                            </li>
                            <li>
                              <div>
                                Well-maintained Roads<span>(1456)</span>
                              </div>
                              {/* <div>1456 mentions</div> */}
                            </li>
                            <li>
                              <div>
                                Metro Connectivity nearby <span>(1176)</span>
                              </div>
                              {/* <div>1176 mentions</div> */}
                            </li>
                            <li>
                              <div>
                                Markets at a walkable distance
                                <span>(1148)</span>
                              </div>
                              {/* <div>1148 mentions</div> */}
                            </li>
                            <li>
                              <div>
                                Good Schools are nearby (1342)
                                <span>(1342)</span>
                              </div>
                              {/* <div>1342 mentions</div> */}
                            </li>
                          </ul>
                        </div>
                        <div className="popular-mentions-review-count">
                          <div className="popular-mentions-openion">
                            <i>
                              <BiDislike />
                            </i>
                            DisLikes
                          </div>
                          <ul className="popular-mentions-list">
                            <li>
                              <div>
                                Frequent Traffic Jams<span>(1212)</span>
                              </div>
                              {/* <div>1212 mentions</div> */}
                            </li>
                            <li>
                              <div>
                                High Pollution<span>(930)</span>
                              </div>
                              {/* <div>930 mentions</div> */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prpty-left-content14 fl-wrap">
                <div className="reviewsby-residents">
                  <ReviewsByResidents />
                </div>
              </div>
              <div className="reviews-feedback fl-wrap">
                <div className="reviews-feedback-left">
                  <label>Is this helpful?</label>
                  <span>
                    Yes
                    <i>
                      <AiFillLike />
                    </i>
                  </span>
                  <span>
                    No
                    <i>
                      <AiFillDislike />
                    </i>
                  </span>
                </div>
                <div className="reviews-feedback-button">
                  <button>Review your Society / Locality</button>
                </div>
              </div>
              <div className="reviews-banner prpty-left-content15 fl-wrap">
                <div className="reviews-banner-body">
                  <img src="/assets/images/sliderImg/Banner1.png" alt="" />
                  <div>
                    <div className="reviews-banner-title">
                      Read reviews by local residents for any locality or
                      society
                    </div>
                    <div className="body-large ng500"></div>
                  </div>
                  <button className="reviews-banner-button">
                    <span>See Ratings & Reviews</span>
                    <i>
                      <FiArrowRight />
                    </i>
                  </button>
                </div>
              </div>
              <div className="prpty-left-content16 fl-wrap">
                <div className="aliens-developers">
                  <div className="aliens-developers-title">
                    <h2>About Aliens Developers Pvt Ltd</h2>
                  </div>
                  <div className="aliens-developers-subtitle">
                    <div className="aliens-developers-img">
                      <img src="/assets/images/sliderImg/H11.jpeg" alt="" />
                    </div>
                    <div>
                      <div className="aliens-first-child">
                        <a>Aliens Developers Pvt Ltd</a>
                      </div>
                      <div className="aliens-second-child">
                        <button>Contact Builder</button>
                      </div>
                    </div>
                  </div>
                  <div className="aliens-developers-content">
                    <p>
                      Aliens developers established in 2003 is an off spring
                      concern of aliens group, a growing property development
                      and management company in the real estate scenario of
                      Hyderabad, India. Aliens developers believe in providing
                      futuristic experience to every customer through its ultra
                      modern visions. Alie...<span>more</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="prpty-left-content17 fl-wrap">
                <ProjectByAliens />
              </div>
              <div className="prpty-left-content17 fl-wrap">
                <div className="frequently-aq">
                  <div className="frequently-aq-head">
                    <div className="frequently-aq-head-title">
                      <h5>Frequently Asked Questions</h5>
                    </div>
                  </div>
                  <div className="faq-wrap">
                    <div className="faq-question-block">
                      <div className="faq-question-logo">
                        <span className="caption-faq">Q</span>
                      </div>
                      <div>
                        <div className="faq-question-bold">
                          Which is the closest metro station to Aliens Space
                          Station?
                        </div>
                        <div className="faq-subquestion-bold">
                          The nearest metro station to Aliens Space Station is
                          Miyapur Metro Station.
                        </div>
                      </div>
                    </div>
                    <div className="faq-question-block">
                      <div className="faq-question-logo">
                        <span className="caption-faq">Q</span>
                      </div>
                      <div>
                        <div className="faq-question-bold">
                          Which is the closest metro station to Aliens Space
                          Station?
                        </div>
                        <div className="faq-subquestion-bold">
                          The nearest metro station to Aliens Space Station is
                          Miyapur Metro Station.
                        </div>
                      </div>
                    </div>
                    <div className="faq-question-block">
                      <div className="faq-question-logo">
                        <span className="caption-faq">Q</span>
                      </div>
                      <div>
                        <div className="faq-question-bold">
                          Which is the closest metro station to Aliens Space
                          Station?
                        </div>
                        <div className="faq-subquestion-bold">
                          The nearest metro station to Aliens Space Station is
                          Miyapur Metro Station.
                        </div>
                      </div>
                    </div>
                    <div className="faq-question-block">
                      <div className="faq-question-logo">
                        <span className="caption-faq">Q</span>
                      </div>
                      <div>
                        <div className="faq-question-bold">
                          Which is the closest metro station to Aliens Space
                          Station?
                        </div>
                        <div className="faq-subquestion-bold">
                          The nearest metro station to Aliens Space Station is
                          Miyapur Metro Station.
                        </div>
                      </div>
                    </div>
                    <div className="faq-question-block">
                      <div className="faq-question-logo">
                        <span className="caption-faq">Q</span>
                      </div>
                      <div>
                        <div className="faq-question-bold">
                          Which is the closest metro station to Aliens Space
                          Station?
                        </div>
                        <div className="faq-subquestion-bold">
                          The nearest metro station to Aliens Space Station is
                          Miyapur Metro Station.
                        </div>
                      </div>
                    </div>
                    <div className="faq-question-block">
                      <div className="faq-question-logo">
                        <span className="caption-faq">Q</span>
                      </div>
                      <div>
                        <div className="faq-question-bold">
                          Which is the closest metro station to Aliens Space
                          Station?
                        </div>
                        <div className="faq-subquestion-bold">
                          The nearest metro station to Aliens Space Station is
                          Miyapur Metro Station.
                        </div>
                      </div>
                    </div>
                    <div className="faq-question-block">
                      <div className="faq-question-logo">
                        <span className="caption-faq">Q</span>
                      </div>
                      <div>
                        <div className="faq-question-bold">
                          Which is the closest metro station to Aliens Space
                          Station?
                        </div>
                        <div className="faq-subquestion-bold">
                          The nearest metro station to Aliens Space Station is
                          Miyapur Metro Station.
                        </div>
                      </div>
                    </div>
                    <div className="faq-question-block">
                      <div className="faq-question-logo">
                        <span className="caption-faq">Q</span>
                      </div>
                      <div>
                        <div className="faq-question-bold">
                          Which is the closest metro station to Aliens Space
                          Station?
                        </div>
                        <div className="faq-subquestion-bold">
                          The nearest metro station to Aliens Space Station is
                          Miyapur Metro Station.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prpty-left-content18 fl-wrap">
                <InterestingReads />
              </div>
              <div className="prpty-left-content19 fl-wrap">
                <div className="aliens-space-station">
                  <div className="aliens-space-station-head">
                    <h1>Aliens Space Station - Quick Links</h1>
                  </div>
                  <div className="aliens-space-head-pills">
                    <div>
                      <h3 className="text-only">
                        Aliens Space Station Floor Plan
                      </h3>
                    </div>
                    <div>
                      <h3 className="text-only">
                        Aliens Space Station Photos and Videos
                      </h3>
                    </div>
                    <div>
                      <h3 className="text-only">
                        Aliens Space Station Directions
                      </h3>
                    </div>
                    <div>
                      <h3 className="text-only">
                        Aliens Space Station Amenities
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prpty-left-content20 fl-wrap">
                <RelatedToYourSearch />
              </div>
              <div className="prpty-left-content21 fl-wrap">
                <section className="prpty-left-footer-content">
                  <p>
                    The material and information contained herein is for general
                    informational purposes only, and does not constitute an
                    endorsement/warranty/representati...<span>More</span>
                  </p>
                  <div>
                    <p>A naukri.com group website</p>
                    <p>
                      Copyright © 2023 Dextorious India Ltd. All rights reserved
                    </p>
                  </div>
                </section>
              </div>

              <div className="fl-wrap limit-box" />
              <div className="listing-carousel-wrapper carousel-wrap fl-wrap">
                <div className="list-single-main-item-title">
                  <h3>Similar Properties</h3>
                </div>
                <div className="listing-carousel carousel">
                  <div className="slick-slide-item">
                    <div className="listing-item">
                      <article className="geodir-category-listing fl-wrap">
                        <div className="geodir-category-img fl-wrap">
                          <a
                            href="listing-single.html"
                            className="geodir-category-img_item"
                          >
                            <img src="/assets/images/all/3.jpg" alt />
                            <div className="overlay" />
                          </a>
                          <div className="geodir-category-location">
                            <a href="#4" className="map-item">
                              <i className="fas fa-map-marker-alt" /> 70 Bright
                              St New York, USA
                            </a>
                          </div>
                          <ul className="list-single-opt_header_cat">
                            <li>
                              <a href="#" className="cat-opt blue-bg">
                                Sale
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cat-opt color-bg">
                                Apartment
                              </a>
                            </li>
                          </ul>
                          <a
                            href="#"
                            className="geodir_save-btn tolt"
                            data-microtip-position="left"
                            data-tooltip="Save"
                          >
                            <span>
                              <i className="fal fa-heart" />
                            </span>
                          </a>
                          <div className="geodir-category-listing_media-list">
                            <span>
                              <i className="fas fa-camera" /> 8
                            </span>
                          </div>
                        </div>
                        <div className="geodir-category-content fl-wrap">
                          <h3>
                            <a href="listing-single.html">
                              Gorgeous house for sale
                            </a>
                          </h3>
                          <div className="geodir-category-content_price">
                            $ 600,000
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in pulvinar neque. Nulla finibus
                            lobortis pulvinar. Donec a consectetur nulla.
                          </p>
                          <div className="geodir-category-content-details">
                            <ul>
                              <li>
                                <i className="fal fa-bed" />
                                <span>3</span>
                              </li>
                              <li>
                                <i className="fal fa-bath" />
                                <span>2</span>
                              </li>
                              <li>
                                <i className="fal fa-cube" />
                                <span>450 ft2</span>
                              </li>
                            </ul>
                          </div>
                          <div className="geodir-category-footer fl-wrap">
                            <a href="agent-single.html" className="gcf-company">
                              <img src="/assets/images/avatar/2.jpg" alt />
                              <span>By Liza Rose</span>
                            </a>
                            <div
                              className="listing-rating card-popup-rainingvis tolt"
                              data-microtip-position="top"
                              data-tooltip="Good"
                              data-starrating2={4}
                            />
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                  <div className="slick-slide-item">
                    <div className="listing-item">
                      <article className="geodir-category-listing fl-wrap">
                        <div className="geodir-category-img fl-wrap">
                          <a
                            href="listing-single.html"
                            className="geodir-category-img_item"
                          >
                            <img src="/assets/images/all/1.jpg" alt />
                            <div className="overlay" />
                          </a>
                          <div className="geodir-category-location">
                            <a href="#4" className="map-item">
                              <i className="fas fa-map-marker-alt" /> 40 Journal
                              Square , NJ, USA
                            </a>
                          </div>
                          <ul className="list-single-opt_header_cat">
                            <li>
                              <a href="#" className="cat-opt blue-bg">
                                Sale
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cat-opt color-bg">
                                Apartment
                              </a>
                            </li>
                          </ul>
                          <a
                            href="#"
                            className="geodir_save-btn tolt"
                            data-microtip-position="left"
                            data-tooltip="Save"
                          >
                            <span>
                              <i className="fal fa-heart" />
                            </span>
                          </a>
                          <div className="geodir-category-listing_media-list">
                            <span>
                              <i className="fas fa-camera" /> 47
                            </span>
                          </div>
                        </div>
                        <div className="geodir-category-content fl-wrap">
                          <h3>
                            <a href="listing-single.html">Luxury Family Home</a>
                          </h3>
                          <div className="geodir-category-content_price">
                            $ 300,000
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in pulvinar neque. Nulla finibus
                            lobortis pulvinar. Donec a consectetur nulla.
                          </p>
                          <div className="geodir-category-content-details">
                            <ul>
                              <li>
                                <i className="fal fa-bed" />
                                <span>4</span>
                              </li>
                              <li>
                                <i className="fal fa-bath" />
                                <span>2</span>
                              </li>
                              <li>
                                <i className="fal fa-cube" />
                                <span>460 ft2</span>
                              </li>
                            </ul>
                          </div>
                          <div className="geodir-category-footer fl-wrap">
                            <a href="agent-single.html" className="gcf-company">
                              <img src="/assets/images/avatar/1.jpeg" alt />
                              <span>By Anna Lips</span>
                            </a>
                            <div
                              className="listing-rating card-popup-rainingvis tolt"
                              data-microtip-position="top"
                              data-tooltip="Excellent"
                              data-starrating2={5}
                            />
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                  <div className="slick-slide-item">
                    <div className="listing-item">
                      <article className="geodir-category-listing fl-wrap">
                        <div className="geodir-category-img fl-wrap">
                          <a
                            href="listing-single.html"
                            className="geodir-category-img_item"
                          >
                            <img src="/assets/images/all/9.jpg" alt />
                            <div className="overlay" />
                          </a>
                          <div className="geodir-category-location">
                            <a href="#4" className="map-item">
                              <i className="fas fa-map-marker-alt" /> 34-42
                              Montgomery St , NY, USA
                            </a>
                          </div>
                          <ul className="list-single-opt_header_cat">
                            <li>
                              <a href="#" className="cat-opt blue-bg">
                                Sale
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cat-opt color-bg">
                                Apartment
                              </a>
                            </li>
                          </ul>
                          <a
                            href="#"
                            className="geodir_save-btn tolt"
                            data-microtip-position="left"
                            data-tooltip="Save"
                          >
                            <span>
                              <i className="fal fa-heart" />
                            </span>
                          </a>
                          <div className="geodir-category-listing_media-list">
                            <span>
                              <i className="fas fa-camera" /> 4
                            </span>
                          </div>
                        </div>
                        <div className="geodir-category-content fl-wrap">
                          <h3>
                            <a href="listing-single.html">
                              Gorgeous house for sale
                            </a>
                          </h3>
                          <div className="geodir-category-content_price">
                            $ 120,000
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in pulvinar neque. Nulla finibus
                            lobortis pulvinar. Donec a consectetur nulla.
                          </p>
                          <div className="geodir-category-content-details">
                            <ul>
                              <li>
                                <i className="fal fa-bed" />
                                <span>2</span>
                              </li>
                              <li>
                                <i className="fal fa-bath" />
                                <span>1</span>
                              </li>
                              <li>
                                <i className="fal fa-cube" />
                                <span>220 ft2</span>
                              </li>
                            </ul>
                          </div>
                          <div className="geodir-category-footer fl-wrap">
                            <a href="agent-single.html" className="gcf-company">
                              <img src="/assets/images/avatar/3.jpg" alt />
                              <span>By Mark Frosty</span>
                            </a>
                            <div
                              className="listing-rating card-popup-rainingvis tolt"
                              data-microtip-position="top"
                              data-tooltip="Good"
                              data-starrating2={4}
                            />
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                  <div className="slick-slide-item">
                    <div className="listing-item">
                      <article className="geodir-category-listing fl-wrap">
                        <div className="geodir-category-img fl-wrap">
                          <a
                            href="listing-single.html"
                            className="geodir-category-img_item"
                          >
                            <img src="/assets/images/all/6.jpg" alt />
                            <div className="overlay" />
                          </a>
                          <div className="geodir-category-location">
                            <a href="#4" className="map-item">
                              <i className="fas fa-map-marker-alt" /> W 85th St,
                              New York, USA
                            </a>
                          </div>
                          <ul className="list-single-opt_header_cat">
                            <li>
                              <a href="#" className="cat-opt blue-bg">
                                Sale
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cat-opt color-bg">
                                Apartment
                              </a>
                            </li>
                          </ul>
                          <a
                            href="#"
                            className="geodir_save-btn tolt"
                            data-microtip-position="left"
                            data-tooltip="Save"
                          >
                            <span>
                              <i className="fal fa-heart" />
                            </span>
                          </a>
                          <div className="geodir-category-listing_media-list">
                            <span>
                              <i className="fas fa-camera" /> 13
                            </span>
                          </div>
                        </div>
                        <div className="geodir-category-content fl-wrap">
                          <h3>
                            <a href="listing-single.html">
                              Contemporary Apartment
                            </a>
                          </h3>
                          <div className="geodir-category-content_price">
                            $ 1,600,000
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in pulvinar neque. Nulla finibus
                            lobortis pulvinar. Donec a consectetur nulla.
                          </p>
                          <div className="geodir-category-content-details">
                            <ul>
                              <li>
                                <i className="fal fa-bed" />
                                <span>4</span>
                              </li>
                              <li>
                                <i className="fal fa-bath" />
                                <span>1</span>
                              </li>
                              <li>
                                <i className="fal fa-cube" />
                                <span>550 ft2</span>
                              </li>
                            </ul>
                          </div>
                          <div className="geodir-category-footer fl-wrap">
                            <a href="agent-single.html" className="gcf-company">
                              <img src="/assets/images/avatar/4.jpg" alt />
                              <span>By Bill Trust</span>
                            </a>
                            <div
                              className="listing-rating card-popup-rainingvis tolt"
                              data-microtip-position="top"
                              data-tooltip="Excellent
                                                      "
                              data-starrating2={5}
                            />
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
                <div className="swiper-button-prev lc-wbtn lc-wbtn_prev">
                  <i className="far fa-angle-left" />
                </div>
                <div className="swiper-button-next lc-wbtn lc-wbtn_next">
                  <i className="far fa-angle-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwiperRecommendedProperty;
