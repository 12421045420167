import React, { useEffect, useState } from "react";
import { IoMdCall } from "react-icons/io";
import {
  BiArrowBack,
  BiPlus,
  BiMinus,
  BiInfoCircle,
  BiChevronDown,
} from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { setPostPropertyStepsStatus, setPropertyProfile } from "../../../../redux/property/PropertySlice";
import AddRoomDetailsTemp from "../commonComponent/AddRoomDetailsTemp";
import AddAreaDetailsTemp from "../commonComponent/AddAreaDetailsTemp";
import OtherRoomsTemp from "../commonComponent/OtherRoomsTemp";
import FurnishingTemp from "../commonComponent/FurnishingTemp";
import ReversedParkingTemp from "../commonComponent/ReversedParkingTemp";
import FloorDetailsTemp from "../commonComponent/FloorDetailsTemp";
import AvaliableFromTemp from "../commonComponent/AvaliableFromTemp";

const PgFlatPrpertypTemp = () => {
  const { PropertyProfile, BasicDetails,singleProperty } = useSelector(
    (state) => state.Property
  );


  const dispatch = useDispatch();

  const [iscontent, setIsContent] = useState(false);

  const [noofBedrooms, setNoofBedrooms] = useState(singleProperty.noofBedrooms);
  const [addmoreBedroomsvalue, setAddmoreBedroomsValue] = useState("");
  const [addmoreBedroomsShow, setAddmoreBedroomsShow] = useState(false);

  const [noofBathrooms, setNoofBathrooms] = useState(singleProperty.noofBathrooms);
  const [addmoreBathroomsvalue, setAddmoreBathroomsValue] = useState("");
  const [addmoreBathroomsShow, setAddmoreBathroomsShow] = useState(false);

  const [balconies, setBalconies] = useState(singleProperty.balconies);

  const [typeofRoom, setTypeofRoom] = useState(singleProperty.typeofRoom);

  const [noOfpeoplesharing, setNoOfpeoplesharing] = useState(singleProperty.noOfpeoplesharing);

  const [noOfBedsinPG, setnoOfBedsinPG] = useState(singleProperty.noOfBeds);
  const [noOfAvailbleBedsinPG, setnoOfAvailbleBedsinPG] = useState(singleProperty.noOfAvailbleBeds);

  const [AttachedBathrooms, setAttachedBathrooms] = useState(singleProperty.AttachedBathrooms);
  const [Attachedbalcony, setAttachedbalcony] = useState(singleProperty.Attachedbalcony);

  const [availableDate, setavailableDate] = useState(singleProperty.availableDate);

  const [availableFor, setavailableFor] = useState(singleProperty.availableFor);

  const [carpetArea, setCarpetArea] = useState(singleProperty.carpetArea);
  const [typeofCarpetArea, setTypeofCarpetArea] = useState(singleProperty.carpetAreaMesurmentType);
  const [typeofCarpetAreaShow, setTypeofCarpetAreaShow] = useState(false);

  const [builtupArea, setBuiltupArea] = useState(singleProperty.buildupArea);
  const [typeofBuiltupArea, setTypeofBuiltupArea] = useState(singleProperty.buildupAreaMesurmentType);
  const [typeofBuiltupAreaShow, setTypeofBuiltupAreaShow] = useState(false);
  const [typeofBuiltupAreaBox, setTypeofBuiltupAreaBox] = useState(false);

  const [superBuiltupArea, setSuperBuiltupArea] = useState(singleProperty.superbuildupArea);
  const [typeofSuperBuiltupArea, setTypeofSuperBuiltupArea] =
    useState(singleProperty.superbuildupAreaMesurmentType);
  const [typeofSuperBuiltupAreaShow, setTypeofSuperBuiltupAreaShow] =
    useState(false);
  const [typeofSuperBuiltupAreaBox, setTypeofSuperBuiltupAreaBox] =
    useState(false);

  const [plotArea, setPlotArea] = useState(singleProperty.plotArea);
  const [typeofPlotArea, setTypeofPlotArea] = useState(singleProperty.plotAreaMesurmentType);

  const [poojaroom, setPoojaroom] = useState(singleProperty.poojaRoom);
  const [studyroom, setStudyroom] = useState(singleProperty.studyRoom);
  const [servantroom, setServantroom] = useState(singleProperty.servantRoom);
  const [storeroom, setStoreroom] = useState(singleProperty.storeRoom);


  const [furnish, setFurnish] = useState(Boolean(0));
  const [semifurnish, setSemifurnish] = useState(Boolean(0));
  const [unfurnish, setUnfurnish] = useState(Boolean(1));
  const [furnishedStatus, setFurnishedStatus] = useState("Un-furnished");

  const [light, setLight] = useState(singleProperty.numberoflight);
  const [fans, setFans] = useState(singleProperty.numberoffans);
  const [ac, setAc] = useState(singleProperty.numberofac);
  const [tv, setTv] = useState(singleProperty.numberoftv);
  const [beds, setBeds] = useState(singleProperty.numberofbeds);
  const [wardrobe, setWardrobe] = useState(singleProperty.numberofwardrobe);
  const [geyser, setGeyser] = useState(singleProperty.numberofgeyser);
  const [sofa, setSofa] = useState(singleProperty.sofa);
  const [washingMachine, setWashingMachine] = useState(singleProperty.washingMachine);
  const [stove, setStove] = useState(singleProperty.stove);
  const [fridge, setFridge] = useState(singleProperty.fridge);
  const [waterPurifier, setWaterPurifier] = useState(singleProperty.waterPurifier);
  const [microwave, setMicrowave] = useState(singleProperty.microwave);
  const [modularKitchen, setModularKitchen] = useState(singleProperty.modularKitchen);
  const [chimney, setChimney] = useState(singleProperty.chimney);
  const [dinningTable, setDinningTable] = useState(singleProperty.dinningTable);
  const [curtains, setCurtains] = useState(singleProperty.curtains);
  const [exhaustFan, setExhaustFan] = useState(singleProperty.exhaustFan);

  const [coveredParking, setCoveredParking] = useState(singleProperty.numberofCoveredParking);
  const [openParking, setOpenParking] = useState(singleProperty.numberofOpenParking);

  const [totalFloors, setTotalFloors] = useState(singleProperty.noofFloor);
  const [propertyonFloors, setPropertyonFloors] = useState(singleProperty.propertyOnFloor);
  const [propertyonFloorsShow, setPropertyonFloorsShow] = useState(false);
  const [typeofPropertyonFloors, setTypeofPropertyonFloors] = useState("");

  const [readyToMove, setReadyToMove] = useState(false);
  const [underConstruction, setUnderConstruction] = useState(false);
  const [abilityStatus, setAbilityStatus] = useState(false);

  const [ageofProperty, setAgeofProperty] = useState(singleProperty.propertyAge);

  const [expectedby, setExpectedby] = useState(singleProperty.propertyPossessionBy);
  const [expectedbyShow, setExpectedbyShow] = useState(false);
  const [yearofExpectedBy, setYearofExpectedBy] = useState("");

  //errors

  const [roomDetailsError, setRoomDetailsError] = useState("");
  const [areaDetailsError, setAreaDetailsError] = useState("");
  const [floorDetailsError, setFloorDetailsError] = useState("");
  const [abilityError, setAbilityError] = useState("");

  const [abilityStatusError, setAbilityStatusError] = useState("");

  const [BedroomsError, setBedroomsError] = useState("");
  const [BathroomsError, setBathroomsError] = useState("");
  const [BalconiesError, setBalconiesError] = useState("");

  const [TotalFloorError, setTotalFloorError] = useState("");
  const [propertyonFloorError, setPropertyonFloorError] = useState("");

  const [availabilityDateError, setavailabilityDateError] = useState("");

  const [availabilityForError, setavailabilityForError] = useState("");

  const [typeofRoomError, settypeofRoomError] = useState("");
  const [noOfpeoplesharingError, setnoOfpeoplesharingError] = useState("");
  const [bedavailabilityinPgError, setbedavailabilityinPgError] = useState("");

  useEffect(() => {
    if (PropertyProfile !== "") {
      setNoofBedrooms(PropertyProfile.noofBedrooms);
      setAddmoreBedroomsValue(PropertyProfile.addmoreBedroomsvalue);
      setAddmoreBedroomsShow(PropertyProfile.addmoreBedroomsShow);

      setNoofBathrooms(PropertyProfile.noofBathrooms);
      setAddmoreBathroomsValue(PropertyProfile.addmoreBathroomsvalue);
      setAddmoreBathroomsShow(PropertyProfile.addmoreBathroomsShow);

      setBalconies(PropertyProfile.balconies);

      setNoOfpeoplesharing(PropertyProfile.noOfpeoplesharing);

      setnoOfBedsinPG(PropertyProfile.noOfBedsinPG);
      setnoOfAvailbleBedsinPG(PropertyProfile.noOfAvailbleBedsinPG);

      setAttachedBathrooms(PropertyProfile.AttachedBathrooms);
      setAttachedbalcony(PropertyProfile.Attachedbalcony);

      setavailableDate(PropertyProfile.availableDate);

      setavailableFor(PropertyProfile.availableFor);

      setCarpetArea(PropertyProfile.carpetArea);
      setTypeofCarpetArea(PropertyProfile.typeofCarpetArea);
      setTypeofCarpetAreaShow(PropertyProfile.typeofCarpetAreaShow);

      setBuiltupArea(PropertyProfile.builtupArea);
      setTypeofBuiltupArea(PropertyProfile.typeofBuiltupArea);
      setTypeofBuiltupAreaShow(PropertyProfile.typeofBuiltupAreaShow);
      setTypeofBuiltupAreaBox(PropertyProfile.typeofBuiltupAreaBox);

      setSuperBuiltupArea(PropertyProfile.superBuiltupArea);
      setTypeofSuperBuiltupArea(PropertyProfile.typeofSuperBuiltupArea);
      setTypeofSuperBuiltupAreaShow(PropertyProfile.typeofSuperBuiltupAreaShow);
      setTypeofSuperBuiltupAreaBox(PropertyProfile.typeofSuperBuiltupAreaBox);

      setPoojaroom(PropertyProfile.poojaroom);
      setStudyroom(PropertyProfile.studyroom);
      setServantroom(PropertyProfile.servantroom);
      setStoreroom(PropertyProfile.storeroom);

      setFurnish(PropertyProfile.furnish);
      setSemifurnish(PropertyProfile.semifurnish);
      setUnfurnish(PropertyProfile.unfurnish);

      setLight(PropertyProfile.light);
      setFans(PropertyProfile.fans);
      setAc(PropertyProfile.ac);
      setTv(PropertyProfile.tv);
      setBeds(PropertyProfile.beds);
      setWardrobe(PropertyProfile.wardrobe);
      setGeyser(PropertyProfile.geyser);
      setSofa(PropertyProfile.sofa);
      setWashingMachine(PropertyProfile.washingMachine);
      setStove(PropertyProfile.stove);
      setFridge(PropertyProfile.fridge);
      setWaterPurifier(PropertyProfile.waterPurifier);
      setMicrowave(PropertyProfile.microwave);
      setModularKitchen(PropertyProfile.modularKitchen);
      setChimney(PropertyProfile.chimney);
      setDinningTable(PropertyProfile.dinningTable);
      setCurtains(PropertyProfile.curtains);
      setExhaustFan(PropertyProfile.exhaustFan);

      setCoveredParking(PropertyProfile.coveredParking);
      setOpenParking(PropertyProfile.openParking);

      setTotalFloors(PropertyProfile.totalFloors);
      setPropertyonFloors(PropertyProfile.propertyonFloors);
      setPropertyonFloorsShow(PropertyProfile.propertyonFloorsShow);

      setReadyToMove(PropertyProfile.readyToMove);
      setUnderConstruction(PropertyProfile.underConstruction);

      setAgeofProperty(PropertyProfile.ageofProperty);

      setExpectedby(PropertyProfile.expectedby);
      setExpectedbyShow(PropertyProfile.expectedbyShow);
    }
  }, [PropertyProfile]);

  const getBedRoom = (value) => {
    setNoofBedrooms(value);
  };
  const getBathroom = (value) => {
    setNoofBathrooms(value);
  };
  const getBalcony = (value) => {
    setBalconies(value);
  };
  const carpetAreaChange = (e) => {
    const result = e;
    setCarpetArea(result);
  };

  const builtupChange = (e) => {
    const result = e;
    setBuiltupArea(result);
  };
  const plotAreaChange = (e) => {
    const result = e;
    setPlotArea(result);
  };

  const availableFromDate = (e) => {
    const result = e;
    setavailableDate(result);
  };

  const superbuiltupChange = (e) => {
    const result = e;
    setSuperBuiltupArea(result);
  };

  const carpetAreaMesurment = (e) => {
    const result = e;
    setTypeofCarpetArea(result);
  };

  const builtupMesurment = (e) => {
    const result = e;
    setTypeofBuiltupArea(result);
  };
  const superbuiltupMesurment = (e) => {
    const result = e;
    setTypeofSuperBuiltupArea(result);
  };
  const plotAreaMesurment = (e) => {
    const result = e;
    setTypeofPlotArea(result);
  };

  const getpoojaroom = (value) => {
    setPoojaroom(value);
  };
  const getstudyroom = (value) => {
    setStudyroom(value);
  };
  const getservantroom = (value) => {
    setServantroom(value);
  };
  const getstoreroom = (value) => {
    setStoreroom(value);
  };

  const getFurnishingDetails = (value) => {
    setFurnishedStatus(value);
  };

  const getFurnishingType = (value) => {
    setFurnishedStatus(value);
    setWardrobe(value.wardrobe);
    setAc(value.ac);
    setChimney(value.chimney);
    setGeyser(value.geyser);
    setMicrowave(value.microwave);
    setLight(value.light);
    setFans(value.fan);
    setTv(value.tv);
    setBeds(value.beds);
    setCurtains(value.curtain);
    setExhaustFan(value.exhaustfan);
    // setStudyTable(value.studyTable);
    setModularKitchen(value.modularKitchen);
    setWashingMachine(value.washingMachine);
    setStove(value.stove);
    setFridge(value.fridge);
    setDinningTable(value.dinningtable);
    setWaterPurifier(value.waterpurifier);
    setSofa(value.sofa);
  };

  const getCoverParking = (value) => {
    setCoveredParking(value);
  };
  const getOpenParking = (value) => {
    setOpenParking(value);
  };

  const getNumberOfFloors = (value) => {
    setTotalFloors(value);
  };
  const getPropertyOnFloors = (value) => {
    setPropertyonFloors(value);
  };

  useEffect(() => {
    if (noofBedrooms !== "") {
      setBedroomsError("");
    }
    if (noofBathrooms !== "") {
      setBathroomsError("");
    }
    if (balconies !== "") {
      setBalconiesError("");
    }
    if (carpetArea !== "" && typeofCarpetArea !== "") {
      setAreaDetailsError("");
    }
    if (totalFloors !== "" && propertyonFloors !== "") {
      setFloorDetailsError("");
    }
    if (readyToMove !== true) {
      setAbilityError("");
    }
    if (ageofProperty !== "") {
      setAbilityStatusError("");
    }
    if (availableDate !== "") {
      setavailabilityDateError("");
    }
    if (availableFor !== "") {
      setavailabilityForError("");
    }
    if (typeofRoom !== "") {
      settypeofRoomError("");
    }
    if (noOfpeoplesharing !== "") {
      setnoOfpeoplesharingError("");
    }
    if (noOfAvailbleBedsinPG !== "") {
      setbedavailabilityinPgError("");
    }
  }, [
    noofBedrooms,
    noofBathrooms,
    balconies,
    carpetArea,
    typeofCarpetArea,
    totalFloors,
    propertyonFloors,
    readyToMove,
    underConstruction,
    ageofProperty,
    expectedby,
    availableDate,
    availableFor,
    typeofRoom,
    noOfpeoplesharing,
    noOfAvailbleBedsinPG,
  ]);

  const propertyProfileClick = async (e) => {
    e.preventDefault();

    setRoomDetailsError("");
    setBedroomsError("");
    setBathroomsError("");
    setBalconiesError("");
    setAreaDetailsError("");
    setFloorDetailsError("");
    setAbilityError("");
    setAbilityStatusError("");
    setavailabilityDateError("");
    setavailabilityForError("");
    settypeofRoomError("");
    setavailabilityForError("");
    setnoOfpeoplesharingError("");
    setbedavailabilityinPgError("");

    if (noofBedrooms !== "" || noofBathrooms !== "" || balconies !== "") {
      setRoomDetailsError("");
    } else {
      setRoomDetailsError("All fields are required");
    }
    if (noofBedrooms !== "") {
      setBedroomsError("");
    } else {
      setBedroomsError("Please specify bedrooms count");
    }
    if (noofBathrooms !== "") {
      setBathroomsError("");
    } else {
      setBathroomsError("Please specify bathrooms count");
    }
    if (balconies !== "") {
      setBalconiesError("");
    } else {
      setBalconiesError("Please specify balconies count");
    }
    if (carpetArea !== "" && typeofCarpetArea !== "") {
      setAreaDetailsError("");
    } else {
      setAreaDetailsError("Atleast one area type mandatory");
    }

    if (totalFloors !== "" && propertyonFloors !== "") {
      setFloorDetailsError("");
    } else {
      setFloorDetailsError("All fields are required");
    }

    if (ageofProperty !== "") {
      setAbilityStatusError("");
    } else {
      setAbilityStatusError("All fields are required");
    }
    if (availableDate !== "") {
      setavailabilityDateError("");
    } else {
      setavailabilityDateError("All fields are required");
    }
    if (availableFor !== "") {
      setavailabilityForError("");
    } else {
      setavailabilityForError("All fields are required");
    }
    if (typeofRoom !== "") {
      settypeofRoomError("");
    } else {
      settypeofRoomError("All fields are required");
    }
    if (noOfpeoplesharing !== "") {
      setnoOfpeoplesharingError("");
    } else {
      setnoOfpeoplesharingError("All fields are required");
    }
    if (noOfAvailbleBedsinPG !== "") {
      setbedavailabilityinPgError("");
    } else {
      setbedavailabilityinPgError("All fields are required");
    }

    if (
      noofBedrooms !== "" &&
      noofBathrooms !== "" &&
      balconies !== "" &&
      carpetArea !== "" &&
      typeofCarpetArea !== "" &&
      availableDate !== "" &&
      availableFor !== "" &&
      totalFloors !== ""
      //&& propertyonFloors !== ""
    ) {
      if (readyToMove !== true || underConstruction !== true) {
        if (ageofProperty !== "" || expectedby !== "") {
          const propertyProfile = {
            noofBedrooms: noofBedrooms,
            addmoreBedroomsvalue: addmoreBedroomsvalue,
            addmoreBedroomsShow: addmoreBedroomsShow,

            noofBathrooms: noofBathrooms,
            addmoreBathroomsvalue: addmoreBathroomsvalue,
            addmoreBathroomsShow: addmoreBathroomsShow,
            balconies: balconies,

            carpetArea: carpetArea,
            typeofCarpetArea: typeofCarpetArea,
            typeofCarpetAreaShow: typeofCarpetAreaShow,

            builtupArea: builtupArea,
            typeofBuiltupArea: typeofBuiltupArea,
            typeofBuiltupAreaShow: typeofBuiltupAreaShow,
            typeofBuiltupAreaBox: typeofBuiltupAreaBox,

            superBuiltupArea: superBuiltupArea,
            typeofSuperBuiltupArea: typeofSuperBuiltupArea,
            typeofSuperBuiltupAreaShow: typeofSuperBuiltupAreaShow,
            typeofSuperBuiltupAreaBox: typeofSuperBuiltupAreaBox,

            plotArea: plotArea,
            plotAreaMesurmentType: typeofPlotArea,

            typeofRoom: typeofRoom,
            noOfpeoplesharing: noOfpeoplesharing,
            noOfBeds: noOfBedsinPG,
            noOfAvailbleBeds: noOfAvailbleBedsinPG,
            AttachedBathrooms: AttachedBathrooms,
            Attachedbalcony: Attachedbalcony,

            availableFor: availableFor,
            availableDate: availableDate,

            poojaroom: poojaroom,
            studyroom: studyroom,
            servantroom: servantroom,
            storeroom: storeroom,

            furnish: furnish,
            semifurnish: semifurnish,
            unfurnish: unfurnish,

            light: light,
            fans: fans,
            ac: ac,
            tv: tv,
            beds: beds,
            wardrobe: wardrobe,
            geyser: geyser,
            sofa: sofa,
            washingMachine: washingMachine,
            stove: stove,
            fridge: fridge,
            waterPurifier: waterPurifier,
            microwave: microwave,
            modularKitchen: modularKitchen,
            chimney: chimney,
            dinningTable: dinningTable,
            curtains: curtains,
            exhaustFan: exhaustFan,

            coveredParking: coveredParking,
            openParking: openParking,

            totalFloors: totalFloors,
            propertyonFloors: propertyonFloors,
            propertyonFloorsShow: propertyonFloorsShow,

            readyToMove: readyToMove,
            underConstruction: underConstruction,
            ageofProperty: ageofProperty,

            expectedby: expectedby,
            expectedbyShow: expectedbyShow,
          };
       
          dispatch(setPropertyProfile(propertyProfile));
          dispatch(setPostPropertyStepsStatus(3));
        }
      }
    }
  };

  return (
    <>
      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA">
          <div className="page_ppf_step1__1icHe  pageComponent">
            <div className="PrimaryDetailsHeader-desktopHeader">
              <div className="desktopHeader_iconInlineHead__W8_RL">
                <div className="caption_subdued_medium">
                  <span
                    className="desktopHeader_backArrow__1lVxQ iconS_PPFDesk_24 icon_backGrey"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  >
                    <BiArrowBack />
                  </span>
                  <div style={{ fontSize: "14px" }}>Back</div>
                </div>
                <div className="desktopHeader_text__3iyVt">
                  <div className="undefined title_l_semiBold">
                    Tell us about your property
                  </div>
                </div>
              </div>

              <div className="FloorPlans-widget"></div>
              <div className="Configuration-widget">
                <AddRoomDetailsTemp
                  getBedRoom={getBedRoom}
                  getBathroom={getBathroom}
                  getBalcony={getBalcony}
                  roomDetailsError={roomDetailsError}
                  noOfBedroomsError={BedroomsError}
                  noOfBathroomsError={BathroomsError}
                  noOfBalconiesError={BalconiesError}
                />

                <AddAreaDetailsTemp
                  carpetAreaChange={carpetAreaChange}
                  carpetAreaMesurment={carpetAreaMesurment}
                  builtupChange={builtupChange}
                  builtupMesurment={builtupMesurment}
                  superbuiltupChange={superbuiltupChange}
                  superbuiltupMesurment={superbuiltupMesurment}
                  plotAreaChange={plotAreaChange}
                  plotAreaMesurment={plotAreaMesurment}
                  areaDetailsError={areaDetailsError}
                />

                <div className="Configuration-widget">
                  <div className="AdditionalRooms-widget">
                    <div className="widgetbox_white_box__1v7xz undefined false false">
                      <div className="widgetbox_header_text__2BXc_">
                        <div className="xidHeading_PageHeadingwrap__3z0kL">
                          <h4>
                            <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                              <div>
                                <span className="xidHeading_headingText__Ulc2a">
                                  Room Type
                                </span>
                              </div>
                            </div>
                          </h4>
                        </div>
                      </div>
                      <div className="false">
                        <div
                          id="furnish-input"
                          className="pageComponent"
                          style={{ textAlign: "left" }}
                        >
                          <div id="furnish">
                            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                              <div
                                id="1"
                                className={
                                  typeofRoom === "Sharing"
                                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                    : "pageComponent radioInput_textOnly__1r7GL "
                                }
                                onClick={() => {
                                  setTypeofRoom("Sharing");
                                }}
                              >
                                <span>Sharing</span>
                              </div>
                              <div
                                id="2"
                                className={
                                  typeofRoom === "Private"
                                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                    : "pageComponent radioInput_textOnly__1r7GL "
                                }
                                onClick={() => {
                                  setTypeofRoom("Private");
                                }}
                              >
                                <span>Private</span>
                              </div>
                            </div>
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {typeofRoomError}
                            </p>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div className="widgetbox_header_text__2BXc_">
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <div>
                              <span
                                className="xidHeading_headingText__Ulc2a"
                                style={{ display: "inline" }}
                              >
                                How many people can share this room?
                              </span>
                            </div>
                          </div>
                        </h4>
                      </div>
                    </div>
                    <div className="false">
                      <div
                        id="bedroomNum-input"
                        className="pageComponent"
                        style={{ textAlign: "left" }}
                      >
                        <div
                          className="tagWrapper_tags_wrapper__KIRJJ  multiple_input"
                          id="bedroomNum"
                        >
                          <div
                            className={
                              noOfpeoplesharing === "2"
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL "
                            }
                            onClick={() => setNoOfpeoplesharing("2")}
                            id="0"
                          >
                            <span>2</span>
                          </div>
                          <div
                            className={
                              noOfpeoplesharing === "3"
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL "
                            }
                            onClick={() => setNoOfpeoplesharing("3")}
                            id="1"
                          >
                            <span>3</span>
                          </div>
                          <div
                            className={
                              noOfpeoplesharing === "4"
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL "
                            }
                            onClick={() => setNoOfpeoplesharing("4")}
                            id="2"
                          >
                            <span>4</span>
                          </div>
                          <div
                            className={
                              noOfpeoplesharing === "5"
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL "
                            }
                            onClick={() => setNoOfpeoplesharing("5")}
                            id="3"
                          >
                            <span>4+</span>
                          </div>

                          <p style={{ color: "red", fontSize: "12px" }}>
                            {noOfpeoplesharingError}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="Location-widget">
                    <div className="widgetbox_white_box__1v7xz undefined undefined undefined">
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <div>
                              <span
                                className="xidHeading_headingText__Ulc2a"
                                style={{ display: "inline" }}
                              >
                                Capacity and Availability
                                <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                                  (Optional)
                                </em>
                              </span>
                            </div>
                          </div>
                        </h4>
                      </div>
                      <div className="location_fieldsWrapper__3sary">
                        <div className="city_citySuggestor__21uXN">
                          <div className="suggestor_suggestor__pe1Hh">
                            <div className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined">
                              <div className="">
                                <input
                                  type="text"
                                  placeholder=" Total no. of beds in PG"
                                  id="project"
                                  value={noOfBedsinPG}
                                  onChange={(e) =>
                                    setnoOfBedsinPG(e.target.value)
                                  }
                                />
                                <div></div>
                                <div className="textInput_placeholder__1KRsF caption_strong_large textInput_placeholderWithoutError__3FjSB">
                                  <span className="undefined"></span>
                                </div>
                              </div>
                            </div>
                            <div className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined">
                              <div className="">
                                <input
                                  type="text"
                                  id="project"
                                  placeholder="Total no. of beds available in PG"
                                  value={noOfAvailbleBedsinPG}
                                  // style={{ border: "1px solid red" }}
                                  onChange={(e) =>
                                    setnoOfAvailbleBedsinPG(e.target.value)
                                  }
                                />
                                <p style={{ color: "red", fontSize: "12px" }}>
                                  {bedavailabilityinPgError}
                                </p>
                                <div></div>
                                <div>
                                  {/* <span className='textInput_errMsgTxt__OXfZT'>Please enter Locality</span> */}
                                </div>
                                <div className="textInput_placeholder__1KRsF caption_strong_large textInput_placeholderWithoutError__3FjSB">
                                  <span className="undefined"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div id="priceRangeText-input">
                        <div className="caption_strong_medium"></div>
                      </div>
                      <div className="PriceIncludeFieldsWrapper-widget">
                        <div
                          className="dummyFormWidget_inlineContainer__12ide"
                          style={{ textAlign: "left", marginBottom: "20px" }}
                        >
                          <div className="pageComponent" id="inclusive-input">
                            <div className="toggleInput_checkbox_wrapper__3MxZY  false">
                              <div>
                                <input
                                  type="checkbox"
                                  checked={AttachedBathrooms === true}
                                  onChange={() =>
                                    setAttachedBathrooms(!AttachedBathrooms)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                                <label>Attached Bathroom</label>
                              </div>
                            </div>
                          </div>
                          <div
                            className="pageComponent"
                            id="taxGovtCharges-input"
                          >
                            <div className="toggleInput_checkbox_wrapper__3MxZY  false">
                              <div>
                                <input
                                  type="checkbox"
                                  checked={Attachedbalcony === true}
                                  onChange={() =>
                                    setAttachedbalcony(!Attachedbalcony)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                                <label>Attached Balcony</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="PropertyAvailability-widget">
                    <div className="widgetbox_white_box__1v7xz undefined false false">
                      <div className="Age-widget">
                        <div className="false">
                          <div className="widgetbox_white_box__1v7xz undefined false false">
                            <div className="widgetbox_header_text__2BXc_">
                              <div className="xidHeading_PageHeadingwrap__3z0kL">
                                <h4>
                                  <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                                    <div>
                                      <span className="xidHeading_headingText__Ulc2a">
                                        Age of property
                                      </span>
                                    </div>
                                  </div>
                                </h4>
                              </div>
                              <p style={{ color: "red" }}>{abilityError}</p>
                            </div>
                          </div>
                          <div id="age-input" style={{ textAlign: "left" }}>
                            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                              <div
                                className={
                                  ageofProperty === "0-1 years"
                                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                    : "pageComponent radioInput_textOnly__1r7GL "
                                }
                                onClick={() => setAgeofProperty("0-1 years")}
                                id="1"
                              >
                                <span>0-1 years</span>
                              </div>
                              <div
                                className={
                                  ageofProperty === "1-5 years"
                                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                    : "pageComponent radioInput_textOnly__1r7GL "
                                }
                                onClick={() => setAgeofProperty("1-5 years")}
                                id="2"
                              >
                                <span>1-5 years</span>
                              </div>
                              <div
                                className={
                                  ageofProperty === "5-10 years"
                                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                    : "pageComponent radioInput_textOnly__1r7GL "
                                }
                                onClick={() => setAgeofProperty("5-10 years")}
                                id="3"
                              >
                                <span>5-10 years</span>
                              </div>
                              <div
                                className={
                                  ageofProperty === "10+ years"
                                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                    : "pageComponent radioInput_textOnly__1r7GL "
                                }
                                onClick={() => setAgeofProperty("10+ years")}
                                id="4"
                              >
                                <span>10+ years</span>
                              </div>
                            </div>
                            <p style={{ color: "red" }}>{abilityStatusError}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="AdditionalRooms-widget">
                    <div className="widgetbox_white_box__1v7xz undefined false false">
                      <div className="widgetbox_header_text__2BXc_">
                        <div className="xidHeading_PageHeadingwrap__3z0kL">
                          <h4>
                            <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                              <div>
                                <span className="xidHeading_headingText__Ulc2a">
                                  Available For
                                </span>
                              </div>
                            </div>
                          </h4>
                        </div>
                      </div>
                      <div className="false">
                        <div
                          id="furnish-input"
                          className="pageComponent"
                          style={{ textAlign: "left" }}
                        >
                          <div id="furnish">
                            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                              <div
                                id="1"
                                className={
                                  availableFor === "Boys"
                                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                    : "pageComponent radioInput_textOnly__1r7GL "
                                }
                                onClick={() => {
                                  setavailableFor("Boys");
                                }}
                              >
                                <span>Boys</span>
                              </div>
                              <div
                                id="2"
                                className={
                                  availableFor === "Girls"
                                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                    : "pageComponent radioInput_textOnly__1r7GL "
                                }
                                onClick={() => {
                                  setavailableFor("Girls");
                                }}
                              >
                                <span>Girls</span>
                              </div>
                              <div
                                id="1"
                                className={
                                  availableFor === "Any"
                                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                    : "pageComponent radioInput_textOnly__1r7GL "
                                }
                                onClick={() => {
                                  setavailableFor("Any");
                                }}
                              >
                                <span>Any</span>
                              </div>
                            </div>
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {availabilityForError}
                            </p>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <OtherRoomsTemp
                  getpoojaroom={getpoojaroom}
                  getstudyroom={getstudyroom}
                  getservantroom={getservantroom}
                  getstoreroom={getstoreroom}
                />

                <FurnishingTemp
                  getFurnishingDetails={getFurnishingDetails}
                  getFurnishingType={getFurnishingType}
                  setLight={setLight}
                  setFans={setFans}
                  setAc={setAc}
                  setTv={setTv}
                  setBeds={setBeds}
                  setWardrobe={setWardrobe}
                  setGeyser={setGeyser}
                  setSofa={setSofa}
                  setWashingMachine={setWashingMachine}
                  setStove={setStove}
                  setFridge={setFridge}
                  setWaterPurifier={setWaterPurifier}
                  setMicrowave={setMicrowave}
                  setModularKitchen={setModularKitchen}
                  setChimney={setChimney}
                  setDinningTable={setDinningTable}
                  setCurtains={setCurtains}
                  setExhaustFan={setExhaustFan}
                />

                <ReversedParkingTemp
                  getCoverParking={getCoverParking}
                  getOpenParking={getOpenParking}
                />

                <FloorDetailsTemp
                  getNumberOfFloors={getNumberOfFloors}
                  getPropertyOnFloors={getPropertyOnFloors}
                  floorDetailsError={floorDetailsError}
                />

                <AvaliableFromTemp
                  availableFromDate={availableFromDate}
                  availabilityDateError={availabilityDateError}
                />
              </div>
            </div>
            <div id="Location-widget">
              <div id="PrimaryDetailsButtonsWrapper-widget">
                <div className="dummyFormWidget_flexContainer__2vVMv">
                  <div
                    id="PrimaryDetailsButton-button"
                    className="pageComponent"
                  >
                    <div>
                      <div className="buttonInput_continue_btn__1gb5M ">
                        <button
                          type="button"
                          className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                          onClick={(e) => propertyProfileClick(e)}
                        >
                          <span>
                            <span>Continue</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PgFlatPrpertypTemp;
