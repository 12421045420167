import React from "react";
import ReactSlider from "react-slider";

const Area = () => {
  const square = [
    { id: "1", name: "sq.ft." },
    { id: "1", name: "sq.yards"},
    { id: "2", name: "sq.m" },
    { id: "3", name: "acres" },
    { id: "4", name: "marla"},
    { id: "5", name: "cents"},
    { id: "19", name: "bigha" },
    { id: "6", name: "kottah"},
    { id: "7", name: "kanal" },
    { id: "8", name: "grounds" },
    { id: "9", name: "ares" },
    { id: "10", name: "biswa" },
    { id: "11", name: "guntha" },
    { id: "12", name: "aankadam" },
    { id: "13", name: "hectares" },
    { id: "14", name: "rood" },
    { id: "15", name: "chataks" },
    { id: "16", name: "perch" },
  ];

  return (
    <div className="mainbarfiltercomponents">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p className="budgettext">Area</p>
        <p className="inrtext">sq.yards</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #eee",
          paddingBottom: "10px",
        }}
      >
        <div className="circularborder">
          <p className="inrtext zerotext">0 sq.</p>
        </div>
        <div>
          <p className="inrtext">-</p>
        </div>
        <div className="circularborder">
          <p className="inrtext hundredtext">4000+ sq.</p>
        </div>
      </div>
      <div className="sliderpartmain">
        <div className="slidernumberingarea">
          {square.map((area, index) => (
            <div key={index}>
              <p>{area.name}</p>
            </div>
          ))}
        </div>
      </div>

      {/* <div
        style={{
          height: "50vh",
          width: "100%",
          // background: "red",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactSlider
          className="vertical-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          defaultValue={[0, 1000000000]}
          max={1000000000}
          min={0}
          step={500000}
          orientation="vertical"
          invert
          pearling
        />
      </div> */}

      {/* <div className="flex-between" style={{ margin: "15px 0px 0px 12px" }}>
        <ReactSlider
          className="vertical-slider"
          //vertical-slider
          thumbClassName="example-thumb"
          trackClassName="example-track"
          defaultValue={[0, 1000000000]}
          max={1000000000}
          min={0}
          step={500000}
          // renderThumb={(props, state) => (
          //   <div {...props}>{state.valueNow}</div>
          // )}
          // onChange={(value, index) => propertyPriceChange(value)}
        />
      </div> */}
    </div>
  );
};

export default Area;
