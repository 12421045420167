import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FcPhoneAndroid, FcInfo, FcIdea } from "react-icons/fc";
import { GrMail } from "react-icons/gr";
import { MdDoNotDisturbAlt } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { CiShare1 } from "react-icons/ci";
import { HiPencil } from "react-icons/hi2";
import { MdDelete } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CircularProgress from "../../postpropertypage/componenets/CircularProgress";
import RecallListingsModal from "./propertyReCalmodel/RecallListingsModal";
import BoostPropertyModel from "./propertyReCalmodel/BoostPropertyModel";
import {
  pageStatusChange,
  propertyUpdate,
  setSinglePropertyResponse,
} from "../../../redux/property/PropertySlice";
import BoostListing from "../../postpropertypage/skipPost/BoostListing";
import MainBoostingPage from "./MainBoostingPage";

const All = (props) => {
  const { propertyAllById } = useSelector((store) => store.Property);
  const { searchProperties } = useSelector((store) => store.Search);

  const { loginData } = useSelector((store) => store.Athentication);
  const { userViewPropertyAll } = useSelector(
    (store) => store.UserViewProperty
  );
  const [allListing, setAllListing] = useState([]);
  const [searchProperty, setSearchProperty] = useState([]);
  const [modal, setModal] = useState(false);
  const [boostmodal, setBoostModal] = useState(false);
  const [singlePropertyId, setsinglePropertyId] = useState("");

  const [progress, setProgress] = useState(0);
  const [bostingIndex, setBostingIndex] = useState(0);
  const [selectBoost, setselectBoost] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let search = searchProperties;
    let searchList = [];
    if (search.length > 1) {
      let singleProperty = propertyAllById.filter((property) =>
        property.locality.toLowerCase().match(search.toLowerCase())
      );
      searchList = [...searchList, ...singleProperty];
      let singleProperty1 = propertyAllById.filter((property) =>
        property.propertyID.toLowerCase().match(search.toLowerCase())
      );
      searchList = [...searchList, ...singleProperty1];

      setSearchProperty(searchList);
    } else {
      setSearchProperty(propertyAllById);
    }
  }, [searchProperties, propertyAllById]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Simulating progress update
      setProgress((prevProgress) =>
        prevProgress < 100 ? prevProgress + 10 : 0
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const toggle = () => setModal(!modal);
  const boostToggle = () => setBoostModal(!boostmodal);

  const boostClick = (property) => {
    const propId = property._id;
    setsinglePropertyId(propId);
  };
  const deleteUpdateClick = (property) => {
    const formData = {
      propertyId: property._id,
      propertyStatus: "Deleted",
    };

    dispatch(propertyUpdate(formData));
  };
  const editPropertyClick = (property) => {
    const formData = {
      propertyId: property._id,
      propertyStatus: "Deleted",
    };
    navigate(`/edit-property/${property._id}`);
  };

  const setChangeIndexValue = (value) => {
    setBostingIndex(value);
  };
  const setselectBoostValue = (value) => {
    setselectBoost(value);
  };

  const propertyResponseClick = (e, property) => {
    navigate("/dashboard");
    dispatch(pageStatusChange(22));
    dispatch(setSinglePropertyResponse(property));
  };

  return (
    <>
      <div className="component__allListingInfo">
        <div className="component__listingCount">
          {searchProperty.length > 1 ? (
            <>
              <span>{searchProperty.length}</span> Properties
            </>
          ) : (
            <>
              <span>{searchProperty.length}</span> Property
            </>
          )}
        </div>
      </div>

      <div className="false component__premiumFilters">
        <span className="component__mg_ryt_20">Showing In:</span>
        <div className="tags-and-chips__textOnly undefined  tags-and-chips__active pageComponent tags-and-chips__mr2 pageComponent">
          <span className="tags-and-chips__badgeParent "></span>
          <span>ALL</span>
          <i className="iconS_Common_20 icon_closeBlue ">
            <AiOutlineClose />
          </i>
        </div>
        <label className="component__clearAll">Clear All Filters</label>
      </div>

      <Modal isOpen={modal} toggle={toggle} className="modalmainnew" style={{}}>
        <div
          className="model-wrappernew"
          style={{ height: "auto", width: "auto" }}
        >
          {/* <ModalHeader toggle={toggle}></ModalHeader> */}
          <ModalBody>
            <RecallListingsModal toggle={toggle} />
          </ModalBody>
        </div>
      </Modal>

      <Modal
        isOpen={boostmodal}
        toggle={boostToggle}
        className="modalmainPopUp"
      >
        <div
          className="model-wrapperPopUp"
          style={{ height: "auto", width: "auto" }}
        >
          <ModalBody>
            <MainBoostingPage
              bostingIndex={bostingIndex}
              boostToggle={boostToggle}
              setChangeIndexValue={setChangeIndexValue}
              singlePropertyId={singlePropertyId}
              setselectBoostValue={setselectBoostValue}
              selectBoost={selectBoost}
            />
          </ModalBody>
        </div>
      </Modal>

      {searchProperty.length > 0 ? (
        <>
          {searchProperty.map((property, index) => (
            <div className="component__card" key={index}>
              <div className="component__card_container1">
                <div className="card_in01" style={{ display: "flex" }}>
                  {property.apartment === "" ? (
                    <>
                      <label>
                        <input type="checkbox" />
                        <em style={{ fontWeight: "bold", color: "#000" }}>
                          {property.apartment +
                            " " +
                            property.subCategory +
                            " for sale in "}
                        </em>
                        <br />
                        {"  "}
                        {property.locality +
                          " , " +
                          property.city +
                          " , " +
                          property.availabilityStatus}
                      </label>
                    </>
                  ) : (
                    <>
                      <label>
                        <input type="checkbox" />
                        <em style={{ fontWeight: "bold", color: "#000" }}>
                          {property.apartment +
                            " " +
                            property.noofBedrooms +
                            " BHK " +
                            property.subCategory +
                            " for sale in "}
                        </em>
                        <br />
                        {"  "}
                        {property.locality +
                          " , " +
                          property.city +
                          " , " +
                          property.availabilityStatus}
                      </label>
                    </>
                  )}
                </div>
                <div
                  className="card_in02 cardpremium"
                  style={{ alignItems: "center" }}
                >
                  <span
                    className="me-1 premuiumtext"
                    onClick={() => setModal(!modal)}
                  >
                    {/* PREMIUM */}
                    {loginData.packageType}
                  </span>
                  <i>
                    <CiShare1 />
                  </i>
                </div>
                <div className="card_in03 subuserbox">
                  <div className="card-assign-align">
                    <span className="span_getleadssubuser">
                      Assign to Subuser
                    </span>
                  </div>

                  <div className="iconsflexbox">
                    <i
                      className="iconspremium"
                      onClick={() => editPropertyClick(property)}
                      style={{ cursor: "pointer" }}
                    >
                      <HiPencil />
                    </i>
                    <i
                      className="iconspremium"
                      onClick={() => deleteUpdateClick(property)}
                      style={{ cursor: "pointer" }}
                    >
                      <MdDelete />
                    </i>
                    <i className="iconspremium">
                      <MdDoNotDisturbAlt />
                    </i>
                  </div>
                </div>
              </div>
              <div className="component__card_container2">
                <p>
                  price: <span>Rs {property.visiblePrice},</span>| Super
                  built-up Area :<span> {property.carpetArea} sq.ft.</span>
                </p>
              </div>
              <div className="component__card_container3">
                <p>
                  <span className="span_number">{property.propertyID} : </span>
                  <span className="span_expired coloringRoot">
                    <font color="green">{property.propertyStatus}</font>
                  </span>
                  | Posted On :
                  <span className="span_date">
                    {moment(property.createdAt).format("DD MMMM YYYY")}
                  </span>
                </p>
              </div>
              <div
                className="component__card_container3"
                style={{ display: "flex" }}
              >
                <p>
                  Expiry On:
                  <span>
                    {moment(property.ExpiryDate).format("DD MMMM YYYY")}
                  </span>
                </p>
                <p className="ms-3">
                  <u>Extend Duration</u>
                </p>
              </div>
              <div className="card_container4-1" style={{ marginLeft: "10px" }}>
                <div className="row d-flex" style={{ display: "flex" }}>
                  <div className="col-4 col-md-4 col-sm-4 col-xs-4 cardboxone">
                    <div className="subcardboxone">
                      <div>
                        <img
                          src="../assets/images/caution.png"
                          alt="caution"
                          className="cautionimage"
                        />
                      </div>
                      <div>
                        <h6 className="propertytext">Property score is good</h6>
                        <p className="the-add-text">
                          The add might get ignored
                        </p>
                      </div>
                    </div>
                    <div className="d-flex subcardboxone">
                      <p className="navblue-text">Add photos</p>
                      <p className="navblue-text">Get Verified</p>
                    </div>
                  </div>
                  <div className="col-4 col-md-4 col-sm-4 col-xs-4 cardboxtwo">
                    {/* <div
                    style={{
                      border: "3px solid green",
                      borderRadius: "50px",
                      width: "40px",
                      height: "40px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        color: "#000",
                        fontSize: "12px",
                        fontWeight: "500",
                        margin: 0,
                        textAlign: "center",
                      }}
                    >
                      100%
                    </p>
                  </div> */}
                    <div className="qualityScoreWidget_propertyScore__28KaJ mainbarcircular">
                      <div className="qualityScoreWidget_scoreGraph__3nVN7 circularbarsizing">
                        <div
                          className="circular_progress"
                          style={{ zIndex: "9" }}
                        >
                          <CircularProgress percentage={95} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 col-md-4 col-sm-4 col-xs-4 cardboxthree">
                    <div className="cardtext">
                      Locality Percentile:{" "}
                      <span className="me-1">
                        <font color="red" className="cardtext">
                          33.33%
                        </font>
                      </span>
                      <FaInfoCircle />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ms-3 summary-text-box">
                <div className="col-4 col-md-4 col-sm-12 col-xs-12 deatilsboxone">
                  {/* Summary view 2 Details view {property.noofPropertyViewed} */}
                  <div className="m-1">
                    Summary view {property.Contactview} Details view{" "}
                    {property.Detailsview}
                  </div>

                  <div
                    className="r-1loqt21"
                    onClick={(e) => propertyResponseClick(e, property)}
                  >
                    <u>View {property.noofPropertyViewed} Responses</u>
                  </div>
                </div>
                <div className="col-4 col-md-4 col-sm-12 col-xs-12 detailsboxtwo">
                  <div className="m-1">Listing Price: 1 Credits</div>
                  {/* <div>
                    <u>View Past Performance</u>
                  </div> */}
                </div>
                <div
                  className="col-4 col-md-4 col-sm-12 col-xs-12 detailsboxthree"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setBoostModal(!boostmodal);
                    boostClick(property);
                  }}
                >
                  <u>Boost Listing</u>
                </div>
              </div>
              <div className="component__card_container5 bottomcard-design">
                <div className="">
                  <h4>
                    <span className="card_icon_idea">
                      <FcIdea />
                    </span>
                    Need more buyers/tenants for your property, simply
                    <span className="span_getleads"> Get Leads</span>
                  </h4>
                </div>
                <div className="">
                  <span className="span_getleads-assign">
                    Assign to Subuser
                  </span>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <div className="component__responceWrapper">
            <div className="pageComponent"></div>
            <div className="fslBooking__noResult">
              <h1>Sorry, No Results !</h1>
              <p>We cannot find the item you are searching for.</p>
              <div className="fslBooking__noResults"></div>
            </div>
          </div>
        </>
      )}

      <div className="component__card_bottomresult">
        <p>Displaying 1 - 1 of 1 results</p>
      </div>
    </>
  );
};

export default All;
