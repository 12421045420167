import React from "react";
import { IoMdClose } from "react-icons/io";
import MainFilterComponent from "./FilterComponents/MainFilterComponent";

const MainBottomSheetComponent = ({
  filterComponentIndex,
  changeFilterCompoIndex,
  onClose,
}) => {
  return (
    <div>
      <div className="rbstopcontent">
        <div style={{ display: "flex" }}>
          <IoMdClose size={25} onClick={() => onClose()} />
          <p className="filtertext">Filters</p>
        </div>

        <p className="clearalltext">Clear All</p>
      </div>

      <div className="mainrbscontainer">
        <div className="slidervsbox">
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(0)}>
            <p> Hide already seen</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(1)}>
            <p> Verified</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(2)}>
            <p> Budget</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(3)}>
            <p> Property Type</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(4)}>
            <p>BHK</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(5)}>
            <p>Construction Status</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(6)}>
            <p> Posted by</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(7)}>
            <p> Area</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(8)}>
            <p> Localities</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(9)}>
            <p> New Projects / Societies</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(10)}>
            <p>Purchase type</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(11)}>
            <p>Amenities</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(12)}>
            <p>Photos</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(13)}>
            <p>Videos</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(14)}>
            <p>Bathrooms</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(15)}>
            <p>Furnishing status</p>
          </div>
          <div className="rbsnav" onClick={() => changeFilterCompoIndex(16)}>
            <p>RERA Approved</p>
          </div>
        </div>
        <div className="mainrbsbox">
          <MainFilterComponent filterComponentIndex={filterComponentIndex} />
        </div>
      </div>

      <div className="bottombuttonsboxes">
        <div className="cancelboxes">
          <p onClick={() => onClose()}>Cancel</p>
        </div>
        <div className="seeallpropertiesboxes">
          <button onClick={() => onClose()}>See All Properties</button>
        </div>
      </div>
    </div>
  );
};

export default MainBottomSheetComponent;
