import React, { useEffect, useRef, useState } from "react";
import { IoMdCall } from "react-icons/io";
import {
  BiArrowBack,
  BiCurrentLocation,
  BiSolidInfoCircle,
} from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import HeaderNew from "../../../components/layouts/HeaderNew";
import { render } from "react-dom";

// Import react-circular-progressbar module and styles
import { CircularProgressbar } from "react-circular-progressbar";
import {
  setLocalityScooring,
  setLocationDetails,
  setPostPropertyStepsStatus,
  setpropertyScooring,
  setaminitiesScooring,
  setbasicdetailScooring,
  setmainpropertyScooring,
  setphotoScooring,
  setpricingScooring,
} from "../../../redux/property/PropertySlice";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";



const LocationDetails = () => {
  const {
    LocationDetails,
    basicdetailScooring,
    mainpropertyScooring,
    photoScooring,
    pricingScooring,
    aminitiesScooring,
  } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const mainref = useRef();
  const loclityref = useRef();
  const apartmentref = useRef();

  const [propertySearch, setPropertySearch] = useState([]);
  const [distictcity, setDistictcity] = useState([]);
  const [city, setCity] = useState("");
  const [showdistictcity, setShowDistictcity] = useState([]);
  const [showCitypop, setShowCitypop] = useState(false);

  const [apptandlcltbyCity, setApptandlcltbyCity] = useState([]);

  const [localitybyCity, setLocalitybyCity] = useState([]);
  const [showLocalitybyCity, setShowLocalitybyCity] = useState([]);
  const [showLocality, setShowLocality] = useState(false);

  const [apartmentbyCity, setApartmentbyCity] = useState([]);
  const [showApartmentbyCity, setShowApartmentbyCity] = useState([]);
  const [showApartment, setShowApartment] = useState(false);

  const [apartment, setApartment] = useState("");
  const [locality, setLocality] = useState("");
  const [subLocality, setSubLocality] = useState("");
  const [housenumber, setHousenumber] = useState("");
  const [locationDetailsError, setLocationDetailsError] = useState("");

  const [cityResults, setCityShowResults] = useState(false);
  const [localityResults, setLocalityShowResults] = useState(false);
  const [apartmentResults, setApartmentResults] = useState(false);

  //--------------------CITY Start--------------------------------//

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/property/allcities`;
      const fetchProperty = await axios.get(url);
      try {
        if (fetchProperty.data.success === true) {
          const cityresult = fetchProperty.data.cities;
          setDistictcity(cityresult);
        }
      } catch (error) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (city !== "") {
      const cityall = distictcity.filter((item) =>
        item.toLowerCase().match(city.toLowerCase())
      );
      setShowDistictcity(cityall);
    }
  }, [city]);

  const selectCityClick = (e, city) => {
    setCity(city);
    console.log(city);
    const fetchData1 = async () => {
      const url = `${Baseurl}/api/v1/property/apartmentnew/${city}`;
      const fetchProperty = await axios.get(url);
      try {
        if (fetchProperty.data.success === true) {
          const apartmentsresult = fetchProperty.data.apartments;
          setApptandlcltbyCity(apartmentsresult);
        }
      } catch (error) {}
    };
    fetchData1();
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/property/alllocalities/${city}`;
      const fetchProperty = await axios.get(url);
      try {
        if (fetchProperty.data.success === true) {
          const localitiesresult = fetchProperty.data.localities;
          setLocalitybyCity(localitiesresult);
        }
      } catch (error) {}
    };
    fetchData();
  };
  const cityBlur = () => {
    let mycity =
      city.charAt(0).toUpperCase() + city.slice(1).trim().toLowerCase();
    setCity(mycity);
    const fetchData1 = async () => {
      const url = `${Baseurl}/api/v1/property/apartmentnew/${mycity}`;

      const fetchProperty = await axios.get(url);

      try {
        if (fetchProperty.data.success === true) {
          const apartmentsresult = fetchProperty.data.apartments;
          if (apartmentsresult.length > 0) {
            setApptandlcltbyCity(apartmentsresult);
            setShowApartmentbyCity(apartmentsresult);
          }
        }
      } catch (error) {}
    };
    fetchData1();
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/property/alllocalities/${mycity}`;
      const fetchProperty = await axios.get(url);
      try {
        if (fetchProperty.data.success === true) {
          const localitiesresult = fetchProperty.data.localities;
          setLocalitybyCity(localitiesresult);
        }
      } catch (error) {}
    };
    fetchData();
  };

  //--------------------CITY End--------------------------------//
  //--------------------APARTMENT Start--------------------------------//

  useEffect(() => {
    if (apartment !== "") {
      const apartmentall = apptandlcltbyCity.filter((item) =>
        item.apartment.toLowerCase().match(apartment.toLowerCase())
      );
      setShowApartmentbyCity(apartmentall);
    }
  }, [apartment]);

  const apartmentClick = (e, apartment) => {
    setApartment(apartment.apartment);
    setLocality(apartment.locality);
  };

  const apartmentBlur = () => {
    let myapartment =
      apartment.charAt(0).toUpperCase() +
      apartment.slice(1).trim().toLowerCase();
    setApartment(myapartment);
  };

  //--------------------APARTMENT End--------------------------------//
  //--------------------LOCALITY Start--------------------------------//
  useEffect(() => {
    if (locality !== "") {
      const localotyall = localitybyCity.filter((item) =>
        item.toLowerCase().match(locality.toLowerCase())
      );
      setShowLocalitybyCity(localotyall);
    }
  }, [locality]);

  const localityClick = (e, locality) => {
    setLocality(locality);
  };

  useEffect(() => {
    if (LocationDetails !== "") {
      setApartment(LocationDetails.apartment);
      setCity(LocationDetails.city);
      setLocality(LocationDetails.locality);
      setHousenumber(LocationDetails.housenumber);
    }
  }, [LocationDetails]);

  useEffect(() => {
    let totalcount = 0;
    let cityperchnage = 0;
    if (city !== "") {
      cityperchnage = 5;
    } else {
      cityperchnage = 0;
    }
    let apartmentcount = 0;
    if (apartment !== "") {
      apartmentcount = 5;
    } else {
      apartmentcount = 0;
    }
    let localityCount = 0;
    if (locality !== "") {
      localityCount = 5;
    } else {
      localityCount = 0;
    }
    let housenumbercount = 0;
    if (housenumber !== "") {
      housenumbercount = 5;
    } else {
      housenumbercount = 0;
    }
    totalcount =
      cityperchnage + apartmentcount + localityCount + housenumbercount;
    dispatch(setLocalityScooring(totalcount));
    dispatch(setbasicdetailScooring(basicdetailScooring));
    dispatch(setphotoScooring(photoScooring));
    dispatch(setpricingScooring(pricingScooring));
    dispatch(setaminitiesScooring(aminitiesScooring));
    dispatch(setmainpropertyScooring(mainpropertyScooring));

    dispatch(setpropertyScooring());
  }, [city, apartment, locality, housenumber]);

  const locationDetailsClick = async (e) => {
    e.preventDefault();
    if (city !== " "  && locality !== "") {
      const locationDetails = {
        city: city,
        apartment: apartment,
        locality: locality,
        subLocality: subLocality,
        housenumber: housenumber,
      };
      dispatch(setLocationDetails(locationDetails));
      dispatch(setPostPropertyStepsStatus(2));
    } else {
      setLocationDetailsError("Please enter locality Details");
    }
  };

  useEffect(() => {
    const clickoutside = (e) => {
      if (
        cityResults &&
        mainref.current &&
        !mainref.current.contains(e.target)
      ) {
        setCityShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [cityResults]);

  useEffect(() => {
    const clickoutside = (e) => {
      if (
        apartmentResults &&
        loclityref.current &&
        !loclityref.current.contains(e.target)
      ) {
        setApartmentResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [apartmentResults]);

  useEffect(() => {
    const clickoutside = (e) => {
      if (
        localityResults &&
        loclityref.current &&
        !loclityref.current.contains(e.target)
      ) {
        setLocalityShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [localityResults]);

  return (
    <>
      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA">
          <div className="page_ppf_step1__1icHe  pageComponent">
            <div className="PrimaryDetailsHeader-desktopHeader">
              <div className="desktopHeader_iconInlineHead__W8_RL">
                <div className="caption_subdued_medium">
                  <span
                    className="desktopHeader_backArrow__1lVxQ iconS_PPFDesk_24 icon_backGrey"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  >
                    <BiArrowBack />
                  </span>
                  <div style={{ fontSize: "14px" }}>Back</div>
                </div>
                <div className="desktopHeader_text__3iyVt">
                  <div className="undefined title_l_semiBold">
                    Where is your property located?
                  </div>
                  <div className="caption_subdued_large">
                    An accurate location helps you connect with the right buyers
                  </div>
                </div>
              </div>
            </div>
            <div id="Location-widget">
              <div className="widgetbox_white_box__1v7xz undefined undefined undefined">
                <div className="location_fieldsWrapper__3sary">
                  <div className="city_citySuggestor__21uXN">
                    <div className="suggestor_suggestor__pe1Hh">
                      <div className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined">
                        <div className="textInput_iconsWrapper__2cgSX">
                          <div className="city_detectLocationWrapper__1Le9J">
                            <span className="detectLocation_rightText__3ew7q pageComponent">
                              <span className="hyperlinks_medium">
                                <i>
                                  <BiCurrentLocation />
                                </i>
                                Pick my location
                              </span>
                            </span>
                          </div>
                          <div className="screeningActions_iconWrapper__dB1NM"></div>
                        </div>
                        <div className="">
                          <input
                            type="text"
                            placeholder="City"
                            id="city"
                            autoComplete="off"
                            ref={mainref}
                            style={{ textTransform: "capitalize" }}
                            value={city}
                            onChange={(e) => {
                              setCity(e.target.value);
                              setShowCitypop(true);
                              setCityShowResults(true);
                            }}
                            onBlur={(e) => cityBlur(e)}
                          />
                          <div>
                            {cityResults &&
                            city.length > 0 &&
                            showCitypop &&
                            showdistictcity.length > 0 ? (
                              <>
                                <div
                                  className=""
                                  style={{
                                    textAlign: "start",
                                    border: "1px solid #eee",
                                    backgroundColor: "#fff",
                                    padding: "5px",
                                    zIndex: "99",
                                    position: "absolute",
                                    left: "0",
                                    right: "0",
                                    height: "15vh",
                                    overflowY: "scroll",
                                  }}
                                >
                                  <ul>
                                    {showdistictcity.map((city, index) => (
                                      <li
                                        style={{ paddingTop: "8px" }}
                                        key={index}
                                        onClick={(e) => {
                                          selectCityClick(e, city);
                                          setShowCitypop(false);
                                        }}
                                      >
                                        {city}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className="textInput_placeholder__1KRsF caption_strong_large textInput_placeholderWithoutError__3FjSB">
                            <span className="undefined"></span>
                          </div>
                        </div>
                      </div>
                      <div className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined">
                        <div className="">
                          <input
                            type="text"
                            placeholder=" Apartment / Society (Optional)"
                            id="project"
                            autoComplete="off"
                            style={{ textTransform: "capitalize" }}
                            ref={apartmentref}
                            value={apartment}
                            onChange={(e) => {
                              setApartment(e.target.value);
                              setShowApartment(true);
                              setApartmentResults(true);
                            }}
                            onBlur={(e) => apartmentBlur(e)}
                          />
                          {apartmentResults &&
                          city.length > 0 &&
                          showApartment &&
                          showApartmentbyCity.length > 0 ? (
                            <>
                              <div
                                className=""
                                style={{
                                  textAlign: "start",
                                  border: "1px solid #eee",
                                  backgroundColor: "#fff",
                                  padding: "5px",
                                  zIndex: "99",
                                  position: "absolute",
                                  left: "0",
                                  right: "0",
                                  height: "20vh",
                                  overflowY: "scroll",
                                }}
                              >
                                <ul>
                                  {showApartmentbyCity.map(
                                    (apartment, index2) => (
                                      <li
                                        style={{ paddingTop: "8px" }}
                                        key={index2}
                                        onClick={(e) => {
                                          apartmentClick(e, apartment);
                                          setShowApartment(false);
                                        }}
                                      >
                                        {apartment.apartment +
                                          "," +
                                          apartment.locality}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div className="textInput_placeholder__1KRsF caption_strong_large textInput_placeholderWithoutError__3FjSB">
                            <span className="undefined"></span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined"
                        style={{ position: "relative" }}
                      >
                        <div className="">
                          <input
                            type="text"
                            id="project"
                            placeholder="Locality"
                            autoComplete="off"
                            style={{ textTransform: "capitalize" }}
                            ref={loclityref}
                            value={locality}
                            onChange={(e) => {
                              setLocality(e.target.value);
                              setShowLocality(true);
                              setLocalityShowResults(true);
                            }}
                          />
                          {localityResults &&
                          city.length > 0 &&
                          showLocality &&
                          showLocalitybyCity.length > 0 ? (
                            <>
                              <div
                                className=""
                                style={{
                                  textAlign: "start",
                                  border: "1px solid #eee",
                                  backgroundColor: "#fff",
                                  padding: "5px",
                                  zIndex: "99",
                                  position: "absolute",
                                  left: "0",
                                  right: "0",
                                  height: "20vh",
                                  overflowY: "scroll",
                                }}
                              >
                                <ul>
                                  {showLocalitybyCity.map(
                                    (locality, index1) => (
                                      <li
                                        style={{ paddingTop: "8px" }}
                                        key={index1}
                                        onClick={(e) => {
                                          localityClick(e, locality);
                                          setShowLocality(false);
                                        }}
                                      >
                                        {locality}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div>
                            {/* <span className='textInput_errMsgTxt__OXfZT'>Please enter Locality</span> */}
                          </div>
                          <div className="textInput_placeholder__1KRsF caption_strong_large textInput_placeholderWithoutError__3FjSB">
                            <span className="undefined"></span>
                          </div>
                        </div>
                      </div>
                      <div className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined">
                        <div className="">
                          <div className="textInput_iconsWrapper__2cgSX">
                            {/* <i className='iconS_PPFDesk_24 icon_redInfo'><BiSolidInfoCircle/></i> */}
                            <div className="screeningActions_iconWrapper__dB1NM"></div>
                          </div>
                          <input
                            type="text"
                            id="project"
                            style={{ textTransform: "capitalize" }}
                            placeholder="Sub-locality(Optional)"
                            value={subLocality}
                            onChange={(e) => setSubLocality(e.target.value)}
                          />
                          <div></div>
                          <div>
                            {/* <span className='textInput_errMsgTxt__OXfZT'>Please enter Locality</span> */}
                          </div>
                          <div className="textInput_placeholder__1KRsF caption_strong_large textInput_placeholderWithoutError__3FjSB">
                            <span className="undefined"></span>
                          </div>
                        </div>
                      </div>
                      <div className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined">
                        <div className="">
                          <div className="textInput_iconsWrapper__2cgSX">
                            {/* <i className='iconS_PPFDesk_24 icon_redInfo'><BiSolidInfoCircle/></i> */}
                            <div className="screeningActions_iconWrapper__dB1NM"></div>
                          </div>
                          <input
                            type="text"
                            id="project"
                            style={{ textTransform: "capitalize" }}
                            placeholder="House No.(Optional)"
                            value={housenumber}
                            onChange={(e) => {
                              setHousenumber(e.target.value);
                            }}
                          />
                          <div></div>
                          <div>
                            {/* <span className='textInput_errMsgTxt__OXfZT'>Please enter Locality</span> */}
                          </div>
                          <div className="textInput_placeholder__1KRsF caption_strong_large textInput_placeholderWithoutError__3FjSB">
                            <span className="undefined"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p style={{ color: "red" }}>{locationDetailsError}</p>
              <div id="PrimaryDetailsButtonsWrapper-widget">
                <div className="dummyFormWidget_flexContainer__2vVMv">
                  <div
                    id="PrimaryDetailsButton-button"
                    className="pageComponent"
                  >
                    <div>
                      <div className="buttonInput_continue_btn__1gb5M ">
                        <button
                          type="button"
                          className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                          onClick={(e) => locationDetailsClick(e)}
                        >
                          <span>
                            <span>Continue</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          id="rightSection"
          className="rightSection_rightSection__36zjv"
          style={{ minHeight: "calc(100vh - 54px)" }}
        >
          <div className="sectionInfo_contentWrap__35b6q">
            <img
              src="./assets/images/all/mapland.png"
              alt=""
              style={{ width: "160px" }}
            />
            <div className="sectionInfo_textSection__Zm4lg sectionInfo_textCenter__1-nk4">
              <div className="list_header_semiBold">
                Why we need an accurate location?
              </div>
              <div className="caption_strong_large sectionInfo_subText__1alcp">
                Location is the most important for Buyer’s. By capturing a
                detailed location we ensure we get you geniune enquiries.
              </div>
              <div></div>
            </div>
          </div>
          <div className="needHelp_helpBox__1b_kG" id="helpBox">
            <div className="needHelp_needHelpWrap__1eIlT">
              <div className="needHelp_helpHead__2mtNV">
                <i className="iconS_PPFDesk_16 icon_callIcon">
                  <IoMdCall />
                </i>
                <div className="caption_subdued_large">Need help ? </div>
              </div>
              <div className="caption_subdued_medium">
                You can email us at
                <a className="hyperlinks_small"> service@mygoldenhomes.com </a>
                or call us at
                <a className="hyperlinks_small"> 1800 41 99099</a>
                (IND Toll-Free).
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default LocationDetails;
