import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropertyDetailsModel from "./PropertyDetailsModel";
import { pageStatusChange } from "../../../redux/property/PropertySlice";
import { toast } from "react-toastify";

const PropertyAll = () => {
  const { propertyAll } = useSelector((store) => store.Property);
  const { isAuth, loginData } = useSelector((store) => store.Athentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [singleProperty, setSingleProperty] = useState("");
  const toggle = () => setModal(!modal);

  const closePropDetailModal = () => {
    setModal(false);
  };

  const toggleClick = (e, property) => {
    setModal(!modal);
    setSingleProperty(property);
  };

  const verifyLogin = () => {
    toast.success("Please log in to access this content.", {
      position: "top-center",
      autoClose: 1500,
      className: "custom_toast",
    });
  };

  const changeView = () => {};
  return (
    <>
      {propertyAll
        .slice(0, 12)
        .reverse()
        .map((property, index) => (
          <div className="gallery-item" key={index}>
            <div className="">
              <article className="geodir-category-listing fl-wrap">
                <div className="geodir-category-img fl-wrap">
                  {property.DeskImg.length > 0 ? (
                    <>
                      <Link
                        to={`/property-info/${property.apartment}/${property.propertyID}`}
                        className="geodir-category-img_item"
                      >
                        <img src={property.DeskImg[0]} alt="" />
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        to={`/property-info/${property.apartment}/${property.propertyID}`}
                        className="geodir-category-img_item"
                      >
                        <img src="/assets/images/noimage1.png" alt="" />
                      </Link>
                    </>
                  )}

                  <div className="geodir-category-location">
                    <Link
                      to={`/property-info/${property.apartment}/${property.propertyID}`}
                      className="single-map-item tolt"
                    >
                      <i className="fas fa-map-marker-alt" />
                      <span>
                        {property.subCategory + " , " + property.state}
                      </span>
                    </Link>
                  </div>
                  <ul className="list-single-opt_header_cat">
                    <li>
                      <Link to="" className="cat-opt blue-bg">
                        {property.category === "Sell" ? (
                          <>Buy</>
                        ) : (
                          <>{property.category}</>
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="" className="cat-opt color-bg">
                        {property.subCategory}
                      </Link>
                    </li>
                  </ul>

                  {/* <Link
                    to=""
                    className="geodir_save-btn tolt"
                    data-microtip-position="left"
                    data-tooltip="Save"
                  >
                    <span>
                      <i className="fal fa-heart" />
                    </span>
                  </Link>
                  <Link
                    to=""
                    className="compare-btn tolt"
                    data-microtip-position="left"
                    data-tooltip="Compare"
                  >
                    <span>
                      <i className="fal fa-random" />
                    </span>
                  </Link> */}

                  <div className="geodir-category-listing_media-list">
                    <span>
                      <i className="fas fa-camera" /> {property.DeskImg.length}
                    </span>
                  </div>
                </div>
                <div className="geodir-category-content fl-wrap">
                  <Link
                    to={`/property-info/${property.apartment}/${property.propertyID}`}
                  >
                    <h3
                      className="title-sin_item"
                      style={{
                        color: "#000",
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                    >
                      {property.apartment}
                    </h3>
                  </Link>
                  <div className="geodir-category-content_price">
                    ₹ {property.visiblePrice}
                  </div>
                  <p style={{ color: "#4d4d4d", fontWeight: "400" }}>
                    {property.unicProperty}
                  </p>
                  <div className="geodir-category-content-details">
                    <ul>
                      <li>
                        <i className="fal fa-bed" />
                        <span>{property.noofBedrooms}</span>
                      </li>
                      <li>
                        <i className="fal fa-bath" />
                        <span>{property.noofBathrooms}</span>
                      </li>
                      <li>
                        <i className="fal fa-cube" />
                        <span>
                          {property.carpetArea +
                            " " +
                            property.carpetAreaMesurmentType}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="geodir-category-footer fl-wrap">
                    {isAuth ? (
                      <>
                        {loginData.creadits >= 1 ? (
                          <>
                            <Link
                              onClick={(e) => toggleClick(e, property)}
                              className="btn float-btn small-btn color-bg"
                              style={{ color: "#fff" }}
                            >
                              View Phone Number
                            </Link>
                            <Link
                              to=""
                              onClick={(e) => toggleClick(e, property)}
                              className="btn float-btn small-btn color-bg"
                              style={{ color: "#fff" }}
                            >
                              Contact {property.postUserPosition}
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link
                              className="btn float-btn small-btn color-bg"
                              style={{ color: "#fff" }}
                              onClick={() => {
                                navigate("/dashboard");
                                dispatch(pageStatusChange(9));
                              }}
                            >
                              View Phone Number
                            </Link>
                            <Link
                              className="btn float-btn small-btn color-bg"
                              style={{ color: "#fff" }}
                              onClick={() => {
                                navigate("/dashboard");
                                dispatch(pageStatusChange(9));
                              }}
                            >
                              Contact {property.postUserPosition}
                            </Link>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Link
                          className="btn float-btn small-btn color-bg"
                          style={{ color: "#fff" }}
                          onClick={() => verifyLogin()}
                        >
                          View Phone Number
                        </Link>
                        <Link
                          className="btn float-btn small-btn color-bg"
                          style={{ color: "#fff" }}
                          onClick={() => verifyLogin()}
                        >
                          Contact {property.postUserPosition}
                        </Link>
                      </>
                    )}

                    <Modal
                      isOpen={modal}
                      toggle={toggle}
                      className="modalmain"
                      style={{}}
                    >
                      <div className="model-wrapper">
                        <ModalHeader toggle={toggle}></ModalHeader>
                        <ModalBody>
                          <PropertyDetailsModel
                            closePropdet={closePropDetailModal}
                            propertyprop={singleProperty}
                            changeView={changeView}
                          />
                        </ModalBody>
                      </div>
                    </Modal>
                  </div>
                </div>
              </article>
            </div>
          </div>
        ))}
    </>
  );
};

export default PropertyAll;
