import React, { useRef, useState } from "react";
import { BiTime } from 'react-icons/bi';
import { BsChevronRight } from 'react-icons/bs';
import { Link } from "react-router-dom";

const RecentelySearched = () => {

        const [activeItem, setActiveItem] = useState('recentsearches');

        const handleItemClick = (item) => {
            setActiveItem(item);
        };

        return (
            <>
                <div className='recently-searched-main'>
                    <div className='recently-searched-active-page'>
                        <div className='searched-active-page-tabs'>
                            <div className={`tab_child active-page-tab-child page-tab-text ${activeItem === 'recentsearches' ? 'active' : ''}`}
                                onClick={() => handleItemClick('recentsearches')}>
                                <Link to='/recentelysearched'>11 Recent Searches</Link>
                            </div>
                            <div className={`tab_child active-page-tab-child page-tab-text ${activeItem === 'viewed' ? 'active' : ''}`}
                                onClick={() => handleItemClick('viewed')}>
                                <Link to='/viewed'>11 Viewed</Link>
                            </div>
                            <div className={`tab_child active-page-tab-child page-tab-text ${activeItem === 'shortlisted' ? 'active' : ''}`}
                                onClick={() => handleItemClick('shortlisted')}>
                                <Link to='/shortlisted'>0 Shortlisted</Link>
                            </div>
                            <div className={`tab_child active-page-tab-child page-tab-text ${activeItem === 'contacted' ? 'active' : ''}`}
                                onClick={() => handleItemClick('contacted')}>
                                <Link to='/contacted'>0 Contacted</Link>
                            </div>
                        </div>
                        <div className='searched-active-page-content'>
                            <div>
                                <div className='gap'>
                                    <div className='active-page-search-wrap'>
                                        <div className='page-tab-text-bold'>Today -Saturday (16 Sept 2023)</div>
                                        <div className='page-tab-subtext-box'>
                                            <i><BiTime /></i>
                                            <div className='page-tab-subtext-text'>Buy in   Hyderabad    , Independent/Builder Floor</div>
                                            <i><BsChevronRight /></i>
                                        </div>
                                        <div className='spacer'></div>
                                        <div className='page-tab-text-bold'>Tuesday (12 Sept 2023)</div>
                                        <div className='page-tab-subtext-box'>
                                            <i><BiTime /></i>
                                            <div className='page-tab-subtext-text'>Buy in Hyderabad , Residential Apartment</div>
                                            <i><BsChevronRight /></i>
                                        </div>

                                        <div className='spacer'></div>

                                        <div className='page-tab-text-bold'>Friday (08 Sept 2023)</div>
                                        <div className='page-tab-subtext-box'>
                                            <i><BiTime /></i>
                                            <div className='page-tab-subtext-text'>Rent in Kondapur, Hyderabad</div>
                                            <i><BsChevronRight /></i>
                                        </div>
                                        <div className='page-tab-subtext-box'>
                                            <i><BiTime /></i>
                                            <div className='page-tab-subtext-text'>Buy in Hyderabad , Independent House/Villa</div>
                                            <i><BsChevronRight /></i>
                                        </div>
                                        <div className='page-tab-subtext-box'>
                                            <i><BiTime /></i>
                                            <div className='page-tab-subtext-text'>Buy in Hyderabad , Residential Apartment</div>
                                            <i><BsChevronRight /></i>
                                        </div>
                                        <div className='page-tab-subtext-box'>
                                            <i><BiTime /></i>
                                            <div className='page-tab-subtext-text'>Buy in Delhi / NCR , Residential Land</div>
                                            <i><BsChevronRight /></i>
                                        </div>

                                        <div className='spacer'></div>

                                        <div className='page-tab-text-bold'>Wednesday (06 Sept 2023)</div>
                                        <div className='page-tab-subtext-box'>
                                            <i><BiTime /></i>
                                            <div className='page-tab-subtext-text'>Buy Projects in, Hyderabad , Independent/Builder Floor+ 1 more</div>
                                            <i><BsChevronRight /></i>
                                        </div>
                                        <div className='page-tab-subtext-box'>
                                            <i><BiTime /></i>
                                            <div className='page-tab-subtext-text'>Buy Projects in, Hyderabad , Independent/Builder Floor+ 1 more</div>
                                            <i><BsChevronRight /></i>
                                        </div>
                                        <div className='page-tab-subtext-box'>
                                            <i><BiTime /></i>
                                            <div className='page-tab-subtext-text'>Buy Projects in, Hyderabad , Independent/Builder Floor+ 1 more</div>
                                            <i><BsChevronRight /></i>
                                        </div>
                                        <div className='page-tab-subtext-box'>
                                            <i><BiTime /></i>
                                            <div className='page-tab-subtext-text'>Buy Projects in, Hyderabad , Independent/Builder Floor+ 1 more</div>
                                            <i><BsChevronRight /></i>
                                        </div>
                                        <div className='page-tab-subtext-box'>
                                            <i><BiTime /></i>
                                            <div className='page-tab-subtext-text'>Buy in Chennai</div>
                                            <i><BsChevronRight /></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    export default RecentelySearched