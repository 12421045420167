
import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

const WillingtoRentOutTemp = (props) => {
  const { PropertyProfile, BasicDetails,singleProperty } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [family, setFamily] = useState(singleProperty.family);
  const [man, setMan] = useState(singleProperty.man);
  const [women, setWomen] = useState(singleProperty.women);
  return (
    <>
      <div className="Furnishing-widget">
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span className="xidHeading_headingText__Ulc2a">
                      Willing to rent out to
                    </span>
                  </div>
                </div>
              </h4>
            </div>
          </div>
          <div className="false">
            <div
              id="additionalRooms-input"
              className="pageComponent"
              style={{ textAlign: "left" }}
            >
              <div className="checkBox_tags_wrapper__2gksy undefined multiple_input">
                <div
                  className={
                    family === true
                      ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                      : "tags-and-chips_textOnly__2k10B pageComponent"
                  }
                  onClick={() => {
                    setFamily(!family);
                    props.willingToRentFamily(!family);
                  }}
                  id="1"
                >
                  {family === true ? (
                    <>
                      {" "}
                      <i className="iconS_Common_20 icon_blueRightTick">
                        <img src="./assets/images/all/tick.png" alt="" />
                      </i>
                    </>
                  ) : (
                    <>
                      <i className="iconS_PPF_20 icon_plusIcon">
                        <BiPlus />
                      </i>
                    </>
                  )}
                  <span>Family</span>
                </div>
                <div
                  className={
                    man === true
                      ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                      : "tags-and-chips_textOnly__2k10B pageComponent"
                  }
                  onClick={() => {
                    setMan(!man);
                    props.willingToRentFamily(!man);
                  }}
                  id="2"
                >
                  {man === true ? (
                    <>
                      {" "}
                      <i className="iconS_Common_20 icon_blueRightTick">
                        <img src="./assets/images/all/tick.png" alt="" />
                      </i>
                    </>
                  ) : (
                    <>
                      <i className="iconS_PPF_20 icon_plusIcon">
                        <BiPlus />
                      </i>
                    </>
                  )}
                  <span>Single Man</span>
                </div>
                <div
                  className={
                    women === true
                      ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                      : "tags-and-chips_textOnly__2k10B pageComponent"
                  }
                  onClick={() => {
                    setWomen(!women);
                    props.willingToRentFamily(!women);
                  }}
                  id="3"
                >
                  {women === true ? (
                    <>
                      {" "}
                      <i className="iconS_Common_20 icon_blueRightTick">
                        <img src="./assets/images/all/tick.png" alt="" />
                      </i>
                    </>
                  ) : (
                    <>
                      <i className="iconS_PPF_20 icon_plusIcon">
                        <BiPlus />
                      </i>
                    </>
                  )}
                  <span>Single Women</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WillingtoRentOutTemp;
