import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { BiCheck } from "react-icons/bi";
import { BiSolidLike } from "react-icons/bi";
import { FaAnglesDown } from "react-icons/fa6";
import { Baseurl } from "../../../config/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setcurrentorderDetails,
  setselectBossPlan,
} from "../../../redux/athentication/Athentication";

const BossPlans = () => {
  const { loginData } = useSelector((store) => store.Athentication);
  const [bossPlans, setBossPlans] = useState(false);

  const [selectedRadio, setSelectedRadio] = useState("01");
  const [selectedValue, setSelectedvalue] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRadioChange = (e, data) => {
    setSelectedRadio(e.target.value);
    setSelectedvalue(data);
  };

  const count50 = [
    {
      id: "01",
      mounts: "3 Months",
      mountsselect: 3,
      counts: "100",
      price: "2000",
      totalPrice: "3000",
      offerPersentage: "33.33",
      package: "Basic Package",
    },
    {
      id: "02",
      mounts: "6 Months",
      mountsselect: 6,
      counts: "200",
      price: "3000",
      totalPrice: "6000",
      offerPersentage: "50",
      package: "Silver Package",
    },
    {
      id: "03",
      mounts: "9 Months",
      mountsselect: 9,
      counts: "300",
      price: "5000",
      totalPrice: "9000",
      offerPersentage: "44.44",
      package: "Gold Package",
    },
    {
      id: "04",
      mounts: "12 Months / 1 Year",
      mountsselect: 12,
      counts: "500",
      price: "9000",
      totalPrice: "18000",
      offerPersentage: "50",
      package: "Platinum Package",
    },
  ];

  useEffect(() => {
    if (selectedValue === "") {
      const findada = count50.find((item) => item.id === selectedRadio);
      setSelectedvalue(findada);
    }
  }, [selectedRadio, selectedValue]);

  const generateOrderId = () => {
    const currentTimestamp = new Date().getTime();
    const randomThreeDigitNumber = Math.floor(100 + Math.random() * 900);
    const orderId = `${currentTimestamp}${randomThreeDigitNumber}`;
    return orderId.slice(0, 12);
  };

  const PaymentClick = async (e) => {
    e.preventDefault();
    let orderId = "";
    orderId = generateOrderId();
    if (orderId !== "" && selectedValue !== "") {
      const formData = {
        order_id: orderId,
        // amount: "1.0",
        amount: String(selectedValue.price),
        customer_id: loginData._id,
        customer_email: loginData.email,
        customer_phone: loginData.mobile,
        payment_page_client_id: "hdfcmaster",
        action: "paymentPage",
        return_url: "https://mygoldenhomes.com/payment-process",
        description: "Complete your payment",
        first_name: loginData.name,
        last_name: "",
        noOfmonth: String(selectedValue.mountsselect),
        noOfPosting: String(selectedValue.counts),
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };

      const payment = await axios.post(
        `${Baseurl}/api/v1/credithistory/paymentgateway`,
        formData,
        config
      );
      if (payment.data.success === true) {
        dispatch(setcurrentorderDetails(payment.data.response));
        dispatch(setselectBossPlan(selectedValue));
        // const paymenturl = currentOrder.payment_link;

        window.location.replace(`${payment.data.response.payment_links.web}`);
      }
    }
  };

  return (
    <>
      <div className="BossContainer">
        <div className="container">
          <div className="header">
            <h2>Package Supply Solutions</h2>
            <p>Post every month & grow business</p>
          </div>
          <div className="headerparttwo">
            <h2>BOSS</h2>
            <p>Broker Owner Supply Solution</p>
          </div>
          <div className="bossimagemisleanous">
            <img
              src="/assets/images/LandingBoc.png"
              alt="img"
              className="bossimagenoew"
            />
          </div>
          <div className="BossPlanBoxes">
            <div className="mobileview">
              <h3>Choose your plan</h3>
              <div className="navigate">
                <div className="itemone bossactive">
                  <p>25 Contacts /mo.</p>
                </div>
                <div className="itemtwo">
                  <p>50 Contacts /mo.</p>
                </div>
              </div>
            </div>

            <div className="BossPlanBoxtwo">
              <div>
                <div className="BossplanTopBox">
                  {/* <p className="bosstwentyfive">BOSS 50</p> */}
                  <p className="contactspermonthstwentyfive">
                    {selectedValue.package}
                  </p>
                  <p className="contactspermonthspost">
                    {selectedValue.counts} Posts
                  </p>
                  <p className="starterplansaccess">
                    Big saving plan to access posts
                  </p>
                </div>
                <div className="BossPlanSelectPlans">
                  {count50.map((data, index) => (
                    <div
                      // className="BossPlanmonthly"
                      className={`BossPlanmonthly ${
                        selectedRadio === data.id ? "selected" : ""
                      }`}
                      key={index}
                      onClick={() => {
                        setSelectedvalue(data);
                        setSelectedRadio(data.id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div style={{ display: "flex" }}>
                        <div>
                          <input
                            type="radio"
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            name="fifty"
                            value={data.id}
                            checked={selectedRadio === data.id}
                            onChange={(e) => {
                              handleRadioChange(e, data);
                            }}
                          />
                        </div>
                        <div>
                          <p className="monthsnumtext">{data.mounts}</p>
                          <p className="savepertext">
                            SAVE {data.offerPersentage}%
                          </p>
                          <p className="access25contacts">
                            <BiCheck
                              size={20}
                              style={{ verticalAlign: "bottom" }}
                            />
                            Access {data.counts} posts
                          </p>

                          <p className="access25contacts packagetexthighlight">
                            <BiCheck
                              size={20}
                              style={{ verticalAlign: "bottom" }}
                            />
                            {data.counts} Posts
                          </p>
                          {/* <p className="access25contacts packagetexthighlight">
                            <BiCheck
                              size={20}
                              style={{ verticalAlign: "bottom" }}
                            />
                            {data.package}
                          </p> */}
                        </div>
                      </div>
                      <div
                        style={{
                          marginRight: "5px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="pricingtextmain">
                          <span className="supertag">₹</span>
                          {data.price}
                        </p>
                        <p className="pricingtextsub">₹{data.totalPrice}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="buybutton2">
                  <button
                    className="mainbutton2"
                    type="button"
                    onClick={(e) => PaymentClick(e)}
                  >
                    Buy {selectedRadio && `${selectedValue.mounts}`} plan
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="misleanous">
            <p className="introducttext">
              Introductory Prices | Prices inclusive of GST
            </p>
            <p className="knowbenifits">
              Know Benefits <FaAnglesDown color="#0068bf" />
            </p>
          </div>

          <div className="BossPlanMainseconddiv">
            <div>
              <p className="keybenfitsheading">KEY BENEFITS</p>
              <h3 className="howbossheading">
                How BOSS helps dealers like you
              </h3>
            </div>
            <div className="mainbossdescdiv row">
              <div className="descriptiondivboss col-6 col-lg-6 col-md-6 col-sm-12 colxs-12">
                <div className="mainboximgcontent">
                  <div className="subplansimg">
                    <img
                      src="/assets/images/manager.png"
                      alt="image"
                      className="imagesubbossplan"
                    />
                  </div>
                  <div>
                    <p className="headertext">
                      <span className="numbering">01.</span> Get an edge over
                      competition
                    </p>
                    <p className="paragraphboss">
                      Limit competition from local dealers and maximise your
                      chance of securing owner mandates.
                    </p>
                  </div>
                </div>
                <div className="mainboximgcontent">
                  <div className="subplansimg">
                    <img
                      src="/assets/images/operator.png"
                      alt="image"
                      className="imagesubbossplan"
                    />
                  </div>
                  <div>
                    <p className="headertext">
                      <span className="numbering">02.</span> Enjoy uninterrupted
                      access
                    </p>
                    <p className="paragraphboss">
                      Reach out to more relevant owners every month
                    </p>
                  </div>
                </div>
                <div className="mainboximgcontent">
                  <div className="subplansimg">
                    <img
                      src="/assets/images/credit-card.png"
                      alt="image"
                      className="imagesubbossplan"
                    />
                  </div>
                  <div>
                    <p className="headertext">
                      <span className="numbering">03.</span> No middleman
                      brokerage
                    </p>
                    <p className="paragraphboss">
                      Get direct access to 600+ active owners without any
                      third-party involvement
                    </p>
                  </div>
                </div>
                <div className="mainboximgcontent">
                  <div className="subplansimg">
                    <img
                      src="/assets/images/handshake.png"
                      alt="image"
                      className="imagesubbossplan"
                    />
                  </div>
                  <div>
                    <p className="headertext">
                      <span className="numbering">04.</span> Close property
                      deals faster
                    </p>
                    <p className="paragraphboss">
                      Connect with high-intent active owners and close deals
                      quickly
                    </p>
                  </div>
                </div>
              </div>
              <div className="descbossimg col-6 col-lg-6 col-md-6 col-sm-12 colxs-12">
                <img
                  src="/assets/images/bossplans.png"
                  alt="image"
                  className="bossplansimage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BossPlans;
