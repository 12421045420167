import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

const ParkingType = (props) => {
  const { PropertyProfile, BasicDetails } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();

  const [parkingType, setParkingType] = useState(false);
  const [publicParking, setPublicParking] = useState(false);
  const [privateParking, setPrivateParking] = useState(false);

  useEffect(() => {
    if (PropertyProfile !== "") {
      setParkingType(PropertyProfile.parkingType);
      setPublicParking(PropertyProfile.publicParking);
      setPrivateParking(PropertyProfile.privateParking);
    }
  }, [PropertyProfile]);

  return (
    <>
      <div id="LocatedNear-widget Preference_widget_11">
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span
                      className="xidHeading_headingText__Ulc2a"
                      style={{ display: "inline" }}
                    >
                      Parking Type
                    </span>
                  </div>
                </div>
              </h4>
            </div>
            <p style={{ color: "red" }}>{props.parkingError}</p>
            <div className="false">
              <div
                className="pagecomponent amenties-widget_inner"
                id="ownership-input"
                style={{ textAlign: "left" }}
              >
                <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                  <div
                    className={
                      privateParking === true
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL"
                    }
                    onClick={() => {
                      setPrivateParking(!privateParking);
                      props.parkingPrivateType(!privateParking);
                      setParkingType(false);
                      props.parkingType(false);
                    }}
                    id="1"
                  >
                    {privateParking === true ? (
                      <>
                        {" "}
                        <i className="iconS_Common_20 icon_blueRightTick">
                          <img src="./assets/images/all/tick.png" alt="" />
                        </i>
                      </>
                    ) : (
                      <>
                        <i className="iconS_PPF_20 icon_plusIcon">
                          <BiPlus />
                        </i>
                      </>
                    )}
                    <span>Private Parking</span>
                  </div>
                </div>
                <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                  <div
                    className={
                      publicParking == true
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL"
                    }
                    onClick={() => {
                      setPublicParking(!publicParking);
                      props.parkingPublicType(!publicParking);
                      setParkingType(false);
                      props.parkingType(false);
                    }}
                    id="2"
                  >
                    {publicParking === true ? (
                      <>
                        {" "}
                        <i className="iconS_Common_20 icon_blueRightTick">
                          <img src="./assets/images/all/tick.png" alt="" />
                        </i>
                      </>
                    ) : (
                      <>
                        <i className="iconS_PPF_20 icon_plusIcon">
                          <BiPlus />
                        </i>
                      </>
                    )}
                    <span>Public Parking</span>
                  </div>
                </div>
                <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                  <div
                    className={
                      parkingType == true
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL"
                    }
                    onClick={() => {
                      setParkingType(true);
                      props.parkingType(true);
                      setPublicParking(false);
                      props.parkingPublicType(false);
                      setPrivateParking(false);
                      props.parkingPrivateType(false);
                    }}
                    id="3"
                  >
                    {parkingType === true ? (
                      <>
                        {" "}
                        <i className="iconS_Common_20 icon_blueRightTick">
                          <img src="./assets/images/all/tick.png" alt="" />
                        </i>
                      </>
                    ) : (
                      <>
                        <i className="iconS_PPF_20 icon_plusIcon">
                          <BiPlus />
                        </i>
                      </>
                    )}
                    <span>Not Available</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParkingType;
