
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const NoticePeriodMonthsTemp = (props) => {
  const { PropertyProfile, PriceotherDetails,singleProperty } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [notiesMonths, setNoticeMonths] = useState(singleProperty.notiesMonths);

  useEffect(() => {
    if (PriceotherDetails !== "") {
      setNoticeMonths(PriceotherDetails.notiesMonths);
    }
  }, [PriceotherDetails]);

  return (
    <>
      <div className="Ownership-widget" style={{ marginTop: "10px" }}>
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div
            className="widgetbox_header_text__2BXc_"
            style={{ marginBottom: "-20px" }}
          >
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Months Of Notice{" "}
                  </span>
                  <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                    (Optional)
                  </em>
                </div>
              </h4>
            </div>
          </div>
        </div>
        <div className="false">
          <div
            className="pagecomponent"
            id="ownership-input"
            style={{ textAlign: "left" }}
          >
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  notiesMonths === "None"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setNoticeMonths("None");
                  props.setNoticeMonths("None");
                }}
                id="1"
              >
                <span>None</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  notiesMonths === "1"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setNoticeMonths("1");
                  props.setNoticeMonths("1");
                }}
                id="2"
              >
                <span>1 Month</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  notiesMonths === "2"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setNoticeMonths("2");
                  props.setNoticeMonths("2");
                }}
                id="3"
              >
                <span>2 Months</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  notiesMonths === "3"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setNoticeMonths("3");
                  props.setNoticeMonths("3");
                }}
                id="4"
              >
                <span>3 Months</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  notiesMonths === "4"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setNoticeMonths("4");
                  props.setNoticeMonths("4");
                }}
                id="5"
              >
                <span>4 Months</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  notiesMonths === "5"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setNoticeMonths("5");
                  props.setNoticeMonths("5");
                }}
                id="6"
              >
                <span>5 Months</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  notiesMonths === "6"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setNoticeMonths("6");
                  props.setNoticeMonths("6");
                }}
                id="7"
              >
                <span>6 Months</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticePeriodMonthsTemp;
