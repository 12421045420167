import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  creditHistory: [],

  creditHistoryLoading: true,
};

export const getCreditHistoryById = createAsyncThunk(
  "CreditHistory/getCreditHistory",
  async (id, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/credithistory/${id}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("CreditHistory Not Found");
    }
  }
);

export const creditHistoryPost = createAsyncThunk(
  "CreditHistory/creditHistoryPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/credithistory/new`;

      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Credithistory Not create");
    }
  }
);

const CreditHistorySlice = createSlice({
  name: "CreditHistory",
  initialState,
  extraReducers: {
    [creditHistoryPost.pending]: (state) => {
      state.creditHistoryLoading = true;
    },

    [creditHistoryPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.creditHistory = [
          ...state.creditHistory,
          action.payload.creditHistory,
        ];
      }
      state.creditHistoryLoading = false;
    },

    [creditHistoryPost.rejected]: (state, action) => {
      state.creditHistoryLoading = true;
    },
  },
});

export default CreditHistorySlice.reducer;
