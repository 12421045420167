import React from "react";

const PostedBy = () => {
  const propertyPost = [
    { id: "1", name: "Owner" },
    { id: "2", name: "Builder" },
    { id: "3", name: "Dealer" },
    { id: "4", name: "Featured Dealer" },
  ];
  return (
    <div className="mainbarfiltercomponents">
      {propertyPost.map((posted, index) => (
        <div className="propertyboxes" key={index}>
          <input type="checkbox" />
          <p className="propertytextpara">{posted.name}</p>
        </div>
      ))}

      <div>
        <p className="clearalltext">Select All</p>
      </div>
    </div>
  );
};

export default PostedBy;