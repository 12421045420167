
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const YourOfficeSetupTemp = (props) => {
  const { PropertyProfile, PriceotherDetails,singleProperty } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [businessType, setBusinessType] = useState(singleProperty.officeUsedfor);

  useEffect(() => {
    if (PriceotherDetails !== "") {
      setBusinessType(PriceotherDetails.businessType);
      props.getOfficeUsed(PriceotherDetails.businessType);
    }
  }, [PriceotherDetails]);

  return (
    <>
      <div className="Officeusedfor-widget" style={{ marginTop: "10px" }}>
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div
            className="widgetbox_header_text__2BXc_"
            style={{ marginBottom: "-20px" }}
          >
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Your office was previously used for
                  </span>
                  <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                    (Optional)
                  </em>
                </div>
                {/* <div className="caption_strong_small">
                  <em className="xidHeading_subLabel__1Fc9h">
                    * You can select upto 3
                  </em>
                </div> */}
              </h4>
            </div>
          </div>
        </div>
        <div className="false">
          <div id="DepositValue-input">
            <div className="splitWidget_inputFiledWrap__1vX9m located_inside undefined">
              <div className="pageComponent" id="carpet-input">
                <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                  <div className="false">
                    <input
                      type="text"
                      placeholder="Enter office used for"
                      value={businessType}
                      onChange={(e) => {
                        setBusinessType(e.target.value);
                        props.getOfficeUsed(e.target.value);
                      }}
                    />
                  </div>
                  <div className="numberInput_iconsWrapper__3Kpm5">
                    <i></i>
                    <div className="screeningActions_iconWrapper__dB1NM"></div>
                  </div>
                </div>
              </div>
              <div className="pageComponent" id="carpet-input">
                <div style={{}}>
                  <div className="selectInput_custom_input_wrapper__MqLE9 false  pageComponent">
                    <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                    <div
                      className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf "
                      style={{ border: "none" }}
                      
                    >
                    </div>
                    {/* <div className="selectInput_iconsWrapper__1L2kR">

                      <span className="icon_downArrow">
                        <img
                          src="/assets/images/all/arrow_down.png"
                          alt=""
                        />
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourOfficeSetupTemp;
