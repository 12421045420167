import React, { useEffect, useState } from "react";
import { IoMdCall } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";
import { toast } from "react-toastify";
import {
  setBasicDetails,
  setLocalityScooring,
  setPostPropertyStepsStatus,
  setaminitiesScooring,
  setbasicdetailScooring,
  setmainpropertyScooring,
  setphotoScooring,
  setpricingScooring,
  setpropertyScooring,
} from "../../../redux/property/PropertySlice";

const percentage = 66;

const BasicDetails = () => {
  const {
    BasicDetails,
    basicdetailScooring,
    LocalityScooring,
    photoScooring,
    pricingScooring,
    aminitiesScooring,
    mainpropertyScooring,
  } = useSelector((state) => state.Property);
  const { catTotal, categoryLoading } = useSelector((state) => state.Category);
  const { loginData, isAuth } = useSelector((store) => store.Athentication);
  const { superCatTotal, supercatLoading } = useSelector(
    (state) => state.superCategory
  );

  const { subCatTotal, subcatLoading } = useSelector(
    (state) => state.SubCategory
  );
  const dispatch = useDispatch();

  const [supercatName, setSupercatName] = useState("");
  const [supercatId, setSupercatId] = useState("");
  const [catbySupercat, setCatBySupercat] = useState([]);
  const [catName, setCatName] = useState("");
  const [catId, setCatId] = useState("");
  const [subcatbyCat, setSubcatbyCat] = useState([]);
  const [subcatName, setSubcatName] = useState("");
  const [subcatId, setSubcatId] = useState("");

  const [typeOfland, setTypeOfland] = useState("");
  const [reatailSubshop, setReatailSubshop] = useState("");

  const [basicDetailsError, setBasicDetailsError] = useState("");

  useEffect(() => {
    if (supercatLoading === false) {
      if (BasicDetails !== "") {
        setSupercatName(BasicDetails.supercatName);
        setSupercatId(BasicDetails.supercatId);
      } else {
        setSupercatName(superCatTotal[0].name);
        setSupercatId(superCatTotal[0]._id);
      }
    }
  }, [supercatLoading, superCatTotal, BasicDetails]);

  useEffect(() => {
    if (categoryLoading === false) {
      const categories = catTotal.filter(
        (cat) => cat.superCategoryId === supercatId
      );
      if (categories.length > 0) {
        setCatBySupercat(categories);
        setCatName(categories[0].name);
        setCatId(categories[0]._id);
      }
    }
  }, [supercatId, catTotal, categoryLoading, BasicDetails]);

  useEffect(() => {
    if (subcatLoading === false) {
      const subcategories = subCatTotal.filter(
        (cat) => cat.categoryId === catId
      );
      if (subcategories.length > 0) {
        setSubcatbyCat(subcategories);
      }
    }
  }, [catId, subCatTotal, subcatLoading]);

  useEffect(() => {
    if (BasicDetails !== "") {
      setSupercatName(BasicDetails.supercatName);
      setSupercatId(BasicDetails.supercatId);
      setCatName(BasicDetails.catName);
      setCatId(BasicDetails.catId);
      setSubcatName(BasicDetails.subcatName);
      setSubcatId(BasicDetails.subcatId);
    }
  }, [BasicDetails]);
  useEffect(() => {
    if (subcatName !== "") {
      dispatch(setbasicdetailScooring(12));
      dispatch(setLocalityScooring(LocalityScooring));
      dispatch(setphotoScooring(photoScooring));
      dispatch(setpricingScooring(pricingScooring));
      dispatch(setaminitiesScooring(aminitiesScooring));
      dispatch(setmainpropertyScooring(mainpropertyScooring));
    } else {
      dispatch(setbasicdetailScooring(0));
      dispatch(setLocalityScooring(LocalityScooring));
      dispatch(setphotoScooring(photoScooring));
      dispatch(setpricingScooring(pricingScooring));
      dispatch(setaminitiesScooring(aminitiesScooring));
      dispatch(setmainpropertyScooring(mainpropertyScooring));
    }
    dispatch(setpropertyScooring());
  }, [subcatName]);

  const nextStepClick = async (e) => {
    e.preventDefault();
    setBasicDetailsError("");
    if (
      supercatName !== "" &&
      supercatId !== "" &&
      catName !== "" &&
      catId !== "" &&
      subcatName !== "" &&
      subcatId !== ""
    ) {
      const basicDetails = {
        supercatName: supercatName,
        supercatId: supercatId,
        catName: catName,
        catId: catId,
        subcatName: subcatName,
        subcatId: subcatId,
        typeOfland: typeOfland,
        reatailSubshop: reatailSubshop,
      };
     
      dispatch(setBasicDetails(basicDetails));
      dispatch(setPostPropertyStepsStatus(1));
    } else {
      setBasicDetailsError(
        "Please select the type of property you wise to advertise"
      );
    }
  };

  const typeofPlots = [
    { name: "Residential Plot/ Land" },
    { name: "Agricultural Land/Farm Land" },
    { name: "Agriculture Lands/Farm House" },
  ];
  const typeofLands = [
    { name: "Residential Lands" },
    { name: "Agricultural Land/Farm Land" },
    { name: "Agriculture Lands/Farm House" },
  ];
  //commercial subcat start====
  const typeofOffice = [
    { name: "Ready to move office space" },
    { name: "Bare shell office space" },
    { name: "Co-working office space" },
  ];
  const typeofRetail = [
    { name: "Commercial Shops" },
    { name: "Commercial Showrooms" },
  ];
  const typeofRetailShop = [
    { name: "Mall" },
    { name: "Commercial Project" },
    { name: "Residential Project" },
    { name: "Retail Complex/Building" },
    { name: "Market / High Street" },
    { name: "Others" },
  ];
  const typeofLands1 = [
    { name: "Commertial Land/Inst. Land" },
    { name: "Agricultural/Farm Land" },
    { name: "Industrial Lands/Plots" },
  ];
  const typeofStorage = [{ name: "Ware House" }, { name: "Cold Storage" }];
  const typeofIndustry = [{ name: "Factory" }, { name: "Manufacturing" }];
  const typeofHospitality = [
    { name: "Hotel/Resorts" },
    { name: "Guest-House/Banquet-Halls" },
  ];
  //commercial subcat end====
  return (
    <>
      {/* <HeaderNew /> */}
      <div className="Basicdetails_main null" style={{ marginTop: "0px" }}>
        <div className="false ppfDesktop_formSection__ZzvPA Basicdetails_main_in">
          <div className="page_ppf_step1__1icHe  pageComponent">
            <div className="PrimaryDetailsHeader-desktopHeader">
              <div className="desktopHeader_iconInlineHead__W8_RL">
                <div className="desktopHeader_text__3iyVt">
                  <div className="undefined title_l_semiBold">
                    Welcome back {loginData.name},
                    <br />
                    Fill out basic details
                  </div>
                </div>
              </div>
            </div>
            <div id="PlotLandBanner-widget"></div>
            <div id="PreLeasedBanner-widget"></div>
            <div id="Preference-widget Preference-widget_11">
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <div>
                          <span
                            className="xidHeading_headingText__Ulc2a"
                            style={{ display: "inline" }}
                          >
                            I'm looking to
                          </span>
                        </div>
                      </div>
                    </h4>
                  </div>
                </div>
                <div className="false">
                  <div className="pageComponent" style={{ textAlign: "left" }}>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      {catbySupercat.map((cat, index) => (
                        <div
                          className={
                            catId === cat._id
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL "
                          }
                          id="s"
                          key={index}
                          onClick={() => {
                            setCatId(cat._id);
                            setCatName(cat.name);
                            setSubcatId("");
                            setSubcatName("");
                            setTypeOfland("");
                          }}
                        >
                          <span>{cat.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="ListingCategory-widget">
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <div className="">
                          <span
                            className="xidHeading_headingText__Ulc2a"
                            style={{ display: "inline" }}
                          >
                            What kind of property do you have?
                          </span>
                        </div>
                      </div>
                    </h4>
                  </div>
                </div>
                <div className="false">
                  <div id="resCom-input" className="pageComponent">
                    <div className="radioInput_inline_radio__qveVO radioInput_marginBtm__VxOrF ">
                      {superCatTotal.map((superCat, index2) => (
                        <div
                          className="pageComponent"
                          data-label="VALUE"
                          style={{ marginRight: "13px" }}
                          key={index2}
                        >
                          <div>
                            <input
                              type="radio"
                              label="Residential"
                              // design="system"
                              value={superCat._id}
                              checked={supercatId === superCat._id}
                              onChange={() => {
                                setSupercatName(superCat.name);
                                setSupercatId(superCat._id);
                                setSubcatId("");
                                setSubcatName("");
                                setTypeOfland("");
                              }}
                            />
                            <label htmlFor="" style={{ marginLeft: "5px" }}>
                              {superCat.name}
                            </label>
                          </div>
                        </div>
                      ))}

                      {/* <div className="pageComponent" data-label="VALUE">
                        <div>
                          <input
                            type="radio"
                            label="Commercial"
                            design="system"
                          />
                          <label htmlFor="" style={{ marginLeft: "5px" }}>
                            Commercial
                          </label>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="PropertyType-widget" id="residential">
                <div className="widgetbox_white_box__1v7xz undefined false false">
                  <div className="false">
                    <div
                      className="superPropertyType-input Preference-widget_inner"
                      style={{ textAlign: "left" }}
                    >
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        {subcatbyCat.map((subcat, index3) => (
                          <div
                            className={
                              subcatId === subcat._id
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL "
                            }
                            id="1"
                            key={index3}
                            onClick={() => {
                              setSubcatId(subcat._id);
                              setSubcatName(subcat.name);
                            }}
                          >
                            <span>{subcat.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="PropertyType-widget PropertyType-widget_inner" id="commercial">
                <div className="widgetbox_white_box__1v7xz undefined false false">
                  <div className="false">
                    <div
                      className="superPropertyType-input"
                      style={{ textAlign: "left" }}
                    >
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className="pageComponent radioInput_textOnly__1r7GL"
                          id="Office"
                        >
                          <span>Office</span>
                        </div>
                        <div
                          className="pageComponent radioInput_textOnly__1r7GL"
                          id="Retail"
                        >
                          <span>Retail</span>
                        </div>
                        <div
                          className="pageComponent radioInput_textOnly__1r7GL"
                          id="Plot / Land"
                        >
                          <span>Plot / Land</span>
                        </div>
                        <div
                          className="pageComponent radioInput_textOnly__1r7GL"
                          id="Storage"
                        >
                          <span>Storage</span>
                        </div>
                        <div
                          className="pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          id="Industry"
                        >
                          <span>Industry</span>
                        </div>
                        <div
                          className="pageComponent radioInput_textOnly__1r7GL"
                          id="Hospitality"
                        >
                          <span>Hospitality</span>
                        </div>
                        <div
                          className="pageComponent radioInput_textOnly__1r7GL"
                          id="7"
                        >
                          <span>Other</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            {supercatName === "Residential" ? (
              <>
                {subcatName === "Plot" ? (
                  <>
                    <div id="Preference-widget Preference-widget_11">
                      <div className="widgetbox_white_box__1v7xz undefined false false">
                        <div className="widgetbox_header_text__2BXc_">
                          <div className="xidHeading_PageHeadingwrap__3z0kL">
                            <h4>
                              <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                                <div>
                                  <span
                                    className="xidHeading_headingText__Ulc2a"
                                    style={{ display: "inline" }}
                                  >
                                    What kind of plot / land is it?
                                  </span>
                                </div>
                              </div>
                            </h4>
                          </div>
                        </div>
                        <div className="false">
                          <div
                            className="pageComponent"
                            style={{ textAlign: "left" }}
                          >
                            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                              {typeofPlots.map((land, index4) => (
                                <div
                                  className={
                                    typeOfland === land.name
                                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                      : "pageComponent radioInput_textOnly__1r7GL "
                                  }
                                  id="s"
                                  key={index4}
                                  onClick={() => {
                                    setTypeOfland(land.name);
                                  }}
                                >
                                  <span>{land.name}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {subcatName === "Land" ? (
                  <>
                    <div id="Preference-widget Preference-widget_11">
                      <div className="widgetbox_white_box__1v7xz undefined false false">
                        <div className="widgetbox_header_text__2BXc_">
                          <div className="xidHeading_PageHeadingwrap__3z0kL">
                            <h4>
                              <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                                <div>
                                  <span
                                    className="xidHeading_headingText__Ulc2a"
                                    style={{ display: "inline" }}
                                  >
                                    What kind of plot / land is it?
                                  </span>
                                </div>
                              </div>
                            </h4>
                          </div>
                        </div>
                        <div className="false">
                          <div
                            className="pageComponent"
                            style={{ textAlign: "left" }}
                          >
                            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                              {typeofLands.map((land, index5) => (
                                <div
                                  className={
                                    typeOfland === land.name
                                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                      : "pageComponent radioInput_textOnly__1r7GL "
                                  }
                                  id="s"
                                  key={index5}
                                  onClick={() => {
                                    setTypeOfland(land.name);
                                  }}
                                >
                                  <span>{land.name}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            
            {supercatName === "Commercial" ? (
              <>
                {subcatName === "Office" ? (
                  <>
                    <div id="Preference-widget Preference-widget_11">
                      <div className="widgetbox_white_box__1v7xz undefined false false">
                        <div className="widgetbox_header_text__2BXc_">
                          <div className="xidHeading_PageHeadingwrap__3z0kL">
                            <h4>
                              <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                                <div>
                                  <span
                                    className="xidHeading_headingText__Ulc2a"
                                    style={{ display: "inline" }}
                                  >
                                    What kind of office is it?
                                  </span>
                                </div>
                              </div>
                            </h4>
                          </div>
                        </div>
                        <div className="false">
                          <div
                            className="pageComponent"
                            style={{ textAlign: "left" }}
                          >
                            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                              {typeofOffice.map((ofc, index6) => (
                                <div
                                  className={
                                    typeOfland === ofc.name
                                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                      : "pageComponent radioInput_textOnly__1r7GL "
                                  }
                                  id="s"
                                  key={index6}
                                  onClick={() => {
                                    setTypeOfland(ofc.name);
                                  }}
                                >
                                  <span>{ofc.name}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {subcatName === "Retail" ? (
                  <>
                    <div id="Preference-widget Preference-widget_11">
                      <div className="widgetbox_white_box__1v7xz undefined false false">
                        <div className="widgetbox_header_text__2BXc_">
                          <div className="xidHeading_PageHeadingwrap__3z0kL">
                            <h4>
                              <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                                <div>
                                  <span
                                    className="xidHeading_headingText__Ulc2a"
                                    style={{ display: "inline" }}
                                  >
                                    What type of retail space do you have ?
                                  </span>
                                </div>
                              </div>
                            </h4>
                          </div>
                        </div>
                        <div className="false">
                          <div
                            className="pageComponent"
                            style={{ textAlign: "left" }}
                          >
                            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                              {typeofRetail.map((retail, index7) => (
                                <div
                                  className={
                                    typeOfland === retail.name
                                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                      : "pageComponent radioInput_textOnly__1r7GL "
                                  }
                                  id="s"
                                  key={index7}
                                  onClick={() => {
                                    setTypeOfland(retail.name);
                                  }}
                                >
                                  <span>{retail.name}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {typeOfland === "Commercial Shops" ||
                    typeOfland === "Commercial Showrooms" ? (
                      <>
                        <div id="Preference-widget Preference-widget_11">
                          <div className="widgetbox_white_box__1v7xz undefined false false">
                            <div className="widgetbox_header_text__2BXc_">
                              <div className="xidHeading_PageHeadingwrap__3z0kL">
                                <h4>
                                  <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                                    <div>
                                      <span
                                        className="xidHeading_headingText__Ulc2a"
                                        style={{ display: "inline" }}
                                      >
                                        What type of retail space do you have ?
                                      </span>
                                    </div>
                                  </div>
                                </h4>
                              </div>
                            </div>
                            <div className="false">
                              <div
                                className="pageComponent"
                                style={{ textAlign: "left" }}
                              >
                                <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                                  {typeofRetailShop.map((retail, index8) => (
                                    <div
                                      className={
                                        reatailSubshop === retail.name
                                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                          : "pageComponent radioInput_textOnly__1r7GL "
                                      }
                                      id="s"
                                      key={index8}
                                      onClick={() => {
                                        setReatailSubshop(retail.name);
                                      }}
                                    >
                                      <span>{retail.name}</span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {subcatName === "Plot" || subcatName === "Land" ? (
                  <>
                    <div id="Preference-widget Preference-widget_11">
                      <div className="widgetbox_white_box__1v7xz undefined false false">
                        <div className="widgetbox_header_text__2BXc_">
                          <div className="xidHeading_PageHeadingwrap__3z0kL">
                            <h4>
                              <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                                <div>
                                  <span
                                    className="xidHeading_headingText__Ulc2a"
                                    style={{ display: "inline" }}
                                  >
                                    What kind of plot / land is it?
                                  </span>
                                </div>
                              </div>
                            </h4>
                          </div>
                        </div>
                        <div className="false">
                          <div
                            className="pageComponent"
                            style={{ textAlign: "left" }}
                          >
                            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                              {typeofLands1.map((land, index8) => (
                                <div
                                  className={
                                    typeOfland === land.name
                                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                      : "pageComponent radioInput_textOnly__1r7GL "
                                  }
                                  id="s"
                                  key={index8}
                                  onClick={() => {
                                    setTypeOfland(land.name);
                                  }}
                                >
                                  <span>{land.name}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {subcatName === "Storage" ? (
                  <>
                    <div id="Preference-widget Preference-widget_11">
                      <div className="widgetbox_white_box__1v7xz undefined false false">
                        <div className="widgetbox_header_text__2BXc_">
                          <div className="xidHeading_PageHeadingwrap__3z0kL">
                            <h4>
                              <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                                <div>
                                  <span
                                    className="xidHeading_headingText__Ulc2a"
                                    style={{ display: "inline" }}
                                  >
                                    What kind of storage is it ?
                                  </span>
                                </div>
                              </div>
                            </h4>
                          </div>
                        </div>
                        <div className="false">
                          <div
                            className="pageComponent"
                            style={{ textAlign: "left" }}
                          >
                            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                              {typeofStorage.map((storage, index9) => (
                                <div
                                  className={
                                    typeOfland === storage.name
                                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                      : "pageComponent radioInput_textOnly__1r7GL "
                                  }
                                  id="s"
                                  key={index9}
                                  onClick={() => {
                                    setTypeOfland(storage.name);
                                  }}
                                >
                                  <span>{storage.name}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {subcatName === "Industry" ? (
                  <>
                    <div id="Preference-widget Preference-widget_11">
                      <div className="widgetbox_white_box__1v7xz undefined false false">
                        <div className="widgetbox_header_text__2BXc_">
                          <div className="xidHeading_PageHeadingwrap__3z0kL">
                            <h4>
                              <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                                <div>
                                  <span
                                    className="xidHeading_headingText__Ulc2a"
                                    style={{ display: "inline" }}
                                  >
                                    What kind of industry is it ?
                                  </span>
                                </div>
                              </div>
                            </h4>
                          </div>
                        </div>
                        <div className="false">
                          <div
                            className="pageComponent"
                            style={{ textAlign: "left" }}
                          >
                            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                              {typeofIndustry.map((industry, index10) => (
                                <div
                                  className={
                                    typeOfland === industry.name
                                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                      : "pageComponent radioInput_textOnly__1r7GL "
                                  }
                                  id="s"
                                  key={index10}
                                  onClick={() => {
                                    setTypeOfland(industry.name);
                                  }}
                                >
                                  <span>{industry.name}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {subcatName === "Hospitality" ? (
                  <>
                    <div id="Preference-widget Preference-widget_11">
                      <div className="widgetbox_white_box__1v7xz undefined false false">
                        <div className="widgetbox_header_text__2BXc_">
                          <div className="xidHeading_PageHeadingwrap__3z0kL">
                            <h4>
                              <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                                <div>
                                  <span
                                    className="xidHeading_headingText__Ulc2a"
                                    style={{ display: "inline" }}
                                  >
                                    What kind of hospitality is it ?
                                  </span>
                                </div>
                              </div>
                            </h4>
                          </div>
                        </div>
                        <div className="false">
                          <div
                            className="pageComponent"
                            style={{ textAlign: "left" }}
                          >
                            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                              {typeofHospitality.map((hospital, index11) => (
                                <div
                                  className={
                                    typeOfland === hospital.name
                                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                      : "pageComponent radioInput_textOnly__1r7GL "
                                  }
                                  id="s"
                                  key={index11}
                                  onClick={() => {
                                    setTypeOfland(hospital.name);
                                  }}
                                >
                                  <span>{hospital.name}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            <p style={{ color: "red" }}>{basicDetailsError}</p>
            <div id="SubPropertyType-widget"></div>
            <div id="NumberOfProperties-widget"></div>
            <div id="ShopLocatedIn-widget"></div>

            <div id="PrimaryDetailsButtonsWrapper-widget">
              <div className="dummyFormWidget_flexContainer__2vVMv">
                <div id="PrimaryDetailsButton-button" className="pageComponent">
                  <div>
                    <div className="buttonInput_continue_btn__1gb5M ">
                      <button
                        type="button"
                        className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                        onClick={(e) => nextStepClick(e)}
                      >
                        <span>
                          <span>Continue</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div id='rightSection' className='rightSection_rightSection__36zjv' style={{ minHeight: "calc(100vh - 54px)" }}>
                    <div className='needHelp_helpBox__1b_kG' id='helpBox'>
                        <div className='needHelp_needHelpWrap__1eIlT'>
                            <div className='needHelp_helpHead__2mtNV'>
                                <i className='iconS_PPFDesk_16 icon_callIcon'><IoMdCall /></i>
                                <div className='caption_subdued_large'>Need help ? </div>
                            </div>
                            <div className='caption_subdued_medium' style={{width:"100%"}}>
                                You can email us at
                                <a className='hyperlinks_small'> service@mygoldenhomes.com </a>
                                or call us at
                                <a className='hyperlinks_small'> 1800 41 99099</a>
                                (IND Toll-Free).
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    </>
  );
};

export default BasicDetails;
