import React from 'react'
import Header from '../../components/layouts/Header'
import Footer from '../../components/layouts/Footer'

const SafetyGuide = () => {
  return (
    <>
      <div>
        <Header/>
        <div style={{ paddingLeft: "23%", marginTop: "100px", marginBottom: "24px", marginLeft: "-65%" }}>
          <a href="" style={{ fontsize: "12px", color: "#42526E" }}>Home  </a>
          <a href="" style={{ fontsize: "12px", color: "#42526E" }}>Safety Guide</a>
        </div>
        <div className='css-1dbjc4n safety_guide'>
          <div className='css-1dbjc4n r-xovx4i r-1xfd6ze r-bijgke'>
            <div className='css-1dbjc4n r-1awozwy r-6koalj r-eqz5dr'>
              <div className='css-1dbjc4n r-1uopjct r-145lgeb r-64el8z r-1kz6sp'>
                <div className='css-1dbjc4n r-1mlwlqe r-1uopjct r-145lgeb r-64el8z r-1udh08x r-1kz6sp r-417010 safety_guide'>
                  <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                  >
                    <img src="./assets/images/all/safety.png" alt="" />
                  </div>
                </div>
              </div>
              <div className='css-1dbjc4n r-7sv4c2'></div>
              <h1 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-1x35g6 r-13uqrnb r-b88u0q r-37tt59 r-1v456y7' style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Safety Guide
              </h1>
            </div>
          </div>
          <div className='css-1dbjc4n' style={{ marginTop: "20px" }}>
            <h2 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59' style={{ fontWeight: "bold", marginBottom: "5px", fontWeight: "600", fontSize: "24px" }}>Beware - Use your Judgment</h2>
            <div className='css-901oao r-um3v3g r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z r-knv0ih' style={{ fontSize: "14px", fontWeight: "400px", lineHeight: "22px" }}>
              It is in the interests of a user to exercise his/her best judgment to assess properties' and owners' authenticity. The claims being made may or may not be correct,
              a user is therefore advised to independently verify the authenticity of the claims being made. To this end, we have compiled a checklist which may help a user avoid deceit.
              We recommend that all our users MUST go through the checklist (mentioned below) prior to purchasing or renting a house.
            </div>
          </div>
          <div className='css-1dbjc4n r-eu3ka'></div>
          <div className='css-1dbjc4n r-1awozwy r-xovx4i r-1dzdj1l r-6koalj r-18u37iz r-98loyc r-13uqrnb r-117bsoe r-1oqcu8e' style={{ backgroundColor: "rgb(255, 251, 243)" }}>
            <div className='css-1dbjc4n r-uvuy5l r-1mf7evn r-h10h58 r-1w1cp9x r-uahozn'>
              <div className='css-1dbjc4n r-1mlwlqe r-uvuy5l r-1mf7evn r-h10h58 r-1w1cp9x r-1udh08x r-uahozn r-417010'>
                <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw' style={{ backgroundIimage: 'url(&quot;/universalhp/img/award.shared.png&quot;)' }}>
                  <img src="./assets/images/all/locked.png" alt="" />
                </div>
              </div>
            </div>
            <h3 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-vrz42v r-13qz1uu' style={{ fontSize: "20px", color: "rgb(4, 21, 51)" }}>How to Avoid Real Estate Fraud?</h3>
          </div>
          <div className='css-1dbjc4n' style={{ marginLeft: "40px" }}>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Do not send money online to a 'supposed landlord/owner' using UPI, net banking, debit/credit cards, etc. if you have not met them personally.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                We do not recommend paying any property visit charges, gate pass charges, booking amount etc. over a call.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                In case a broker is showing you the property, make sure that you meet the owner or have a web session with him.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Do not handover any part of the consideration/rent to the broker.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Be wary of disputed land titles.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Be suspicious of realty investments promising high returns (due to infra development) with little/no risk.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Visit the property personally to check the interior and exterior condition; conduct due diligence.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Opt for properties that have at least 3-4 clear photos of bedrooms, bath and kitchen area.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Familiarise yourself with the neighbourhood, before moving in.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Talk to some neighbours and make sure that the one who is showing you the property is the actual owner.
              </div>
            </div>
          </div>
          <div className='css-1dbjc4n' style={{ marginTop: "20px" }}>
            <h2 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59' style={{ fontWeight: "bold", marginBottom: "5px", fontWeight: "600", fontSize: "24px" }}>For a Buyer/Investor</h2>
            <div className='css-901oao r-um3v3g r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z r-knv0ih' style={{ fontSize: "14px", fontWeight: "400px", lineHeight: "22px" }}>
              The buyer should conduct checks and establish authenticity of any property, or project, its title, built up area (in standardized unit), suitability for buying in a form and manner deemed appropriate at his or her COST.
              Mygoldenhomes.com being an advertising medium does not and can not vouch for authenticity of content on its site. A lot of content is uploaded by the advertiser and we do not filter or verify the same. If a complaint is made, we will take remedial measures.
              <br />Certain points to bear in mind:
            </div>
          </div>
          <div className='css-1dbjc4n'>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Conduct a comprehensive check of ownership to verify developer's/owners' credentials.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Visit the project site/property personally, to ensure that the broker's or developer's claim is genuine and that the property is not an 'imaginary' one.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Opt for a builder with good track record, checking the past record of the promoters may be helpful.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Beware of false title documents. Loans may have been secured against properties that may be disputed or have remained vacant for long.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                It is always advisable that proper due diligence on the property is conducted prior to entering into a transaction related to a real estate.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Keep in mind that open areas - lobby, park, terrace, pool, gymnasium, and lift/elevator - are not a part of Floor Space Index (FSI) or Floor Area Ratio (FAR).
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                The Real Estate (Regulation and Development) Act 2016 and its related provisions and their applicability to the project.
              </div>
            </div>
          </div>
          <div className='css-1dbjc4n' style={{ marginTop: "20px" }}>
            <h2 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59' style={{ fontWeight: "bold", marginBottom: "5px", fontWeight: "600", fontSize: "24px" }}>For a Tenant</h2>
            <div className='css-901oao r-um3v3g r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z r-knv0ih' style={{ fontSize: "14px", fontWeight: "400px", lineHeight: "22px" }}>
              Certain points to bear in mind:
            </div>
          </div>
          <div className='css-1dbjc4n'>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Visit the accommodation/property and meet the landlord.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                We strongly recommend to NEVER PAY any property visit charges, gate pass charges, booking amount, etc. for visiting a property.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Check all the rooms, including the kitchen and bath area for plumbing problems, such as fixture leaks and seepages, clogged drains and pipelines.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Find out about the security deposit in a particular area before making the payment. Unscrupulous individuals may ask for unjustified amounts of money.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Ascertain access, safety and security.
              </div>
            </div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz'>
              <div className='css-901oao r-um3v3g r-98loyc r-1enofrn r-13uqrnb r-16dba41 r-waer2z'>•</div>
              <div className='css-901oao r-um3v3g r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z' style={{ paddingLeft: "5px", fontSize: "14px", lineHeight: "22px", fontWeight: "400" }}>
                Ascertain all areas in standardized units.
              </div>
            </div>
          </div>
          <div className='css-1dbjc4n r-eu3ka'></div>
          <div className='css-1dbjc4n r-r3u836 r-1xfd6ze r-rs99b7 r-98loyc r-13uqrnb r-mlyl9t r-le9fof'>
            <div className='css-1dbjc4n r-1awozwy r-xovx4i r-1dzdj1l r-6koalj r-18u37iz r-98loyc r-13uqrnb r-117bsoe r-1oqcu8e' style={{ background: "#fff", padding: "0%", margin: "5px", }}>
              <div style={{ marginRight: "5px" }}>
                <img src="./assets/images/all/gpp_maybe.png" alt="" />
              </div>
              <h3 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-vrz42v r-13qz1uu' style={{ fontSize: "20px", color: "rgb(4, 21, 51)" }}>How to Avoid Real Estate Fraud?</h3>
            </div>
            <div className='css-901oao r-1514gq1 r-98loyc r-1enofrn r-13uqrnb r-14yzgew r-1b5uinu r-puj83k r-11g3r6m r-1knelpx' style={{ margin: "5px", padding: "20px" }}>
              Mygoldenhomes.com is a platform which acts a medium for allowing people having converging interests involving real estate transactions,
              namely the buyer/tenant and owner/broker.
              <br />
              <br />
              Mygoldenhomes.com is merely a preliminary medium of contact and exchange of information,
              users are strongly advised to have independent third party verifications (whether marked verified or not) prior to proceeding with any transactions involving real estate. The onus of finding a genuine property,
              be it for purchase or rental purpose, lies on the user.
              <br />
              <br />
              For further information, you are advised to check the
              <span className='css-901oao css-16my406 r-gi2tka r-1enofrn r-1kfrs79 r-14yzgew' style={{ color: "rgb(43, 121, 212)" }}> terms and conditions </span>
              <br />
              regulating use of Mygoldenhomes.com
            </div>
          </div>
          <div className='css-1dbjc4n r-eu3ka'></div>
          <div className='css-1dbjc4n r-11c0sde'>
            <h4 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-b88u0q r-vrz42v' style={{ fontWeight: "bold", marginBottom: "5px", fontWeight: "600", fontSize: "24px", justifyContent: "center", alignItems: "center", display: "flex" }}>Need to get in touch?</h4>
          </div>
          <div className='css-1dbjc4n r-6koalj r-18u37iz r-1777fci r-1kidu6m terms_conditionbottom_main'>
            <div className='css-1dbjc4n r-r3u836 r-1xfd6ze r-rs99b7 r-1rw7m1n r-1t2hasf r-1aminmm r-18f9sac r-1b3ntt7 terms_conditionbottom_mainin'>
              <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-b88u0q r-waer2z'>TOLL FREE NUMBER</div>
              <div className='css-1dbjc4n r-1awozwy r-18u37iz'>
                <div className='css-1dbjc4n r-1hjwoze r-y3t9qe r-19qrga8 r-hudz2g r-ktdv3k r-12ym1je' style={{ marginRight: "15px" }}>
                  <div className='css-1dbjc4n r-1mlwlqe r-1hjwoze r-1udh08x r-12ym1je r-417010'>
                    <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                      style={{ backgroundImage: 'url(&quot;/universalhp/img/callIcon.shared.png&quot;)' }}></div>
                    <img src="/assets/images/all/callIcon.png" alt="" />
                  </div>
                </div>
                <div className='css-1dbjc4n'>
                  <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'>9 AM - 8 PM IST</div>
                  <div className='css-901oao r-gi2tka r-98loyc r-ubezar r-13uqrnb r-1kfrs79 r-waer2z'>+91 90634 83711</div>
                </div>
              </div>
            </div>
            <div className='css-1dbjc4n r-r3u836 r-1xfd6ze r-rs99b7 r-1t2hasf r-1y8ork0 r-c9eks5 r-1b3ntt7' style={{padding:"10px"}}>
              <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-b88u0q r-waer2z' style={{marginBottom:"5px"}}>INTERNATIONAL NUMBERS</div>
              <div className='css-1dbjc4n r-6koalj r-18u37iz terms_conditionbottom_maininner'>
                <div className='css-1dbjc4n r-1awozwy r-18u37iz' style={{marginRight:"5px"}}>
                  <div className='css-1dbjc4n r-1hjwoze r-y3t9qe r-19qrga8 r-hudz2g r-ktdv3k r-12ym1je' style={{ marginRight: "5px", marginLeft:"5px" }}>
                    <div className='css-1dbjc4n r-1mlwlqe r-1hjwoze r-1udh08x r-12ym1je r-417010'>
                      <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                        style={{ backgroundImage: 'url(&quot;/universalhp/img/callIcon.shared.png&quot;)' }}></div>
                      <img src="/assets/images/all/callIcon.png" alt="" />
                    </div>
                  </div>
                  <div className='css-1dbjc4n'>
                    <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'>9 AM - 8 PM IST</div>
                    <div className='css-901oao r-gi2tka r-98loyc r-ubezar r-13uqrnb r-1kfrs79 r-waer2z'>1800-41-99099</div>
                  </div>
                </div>
                <div className='css-1dbjc4n r-1awozwy r-18u37iz' style={{marginRight:"5px"}}>
                <div className='css-1dbjc4n r-1hjwoze r-y3t9qe r-19qrga8 r-hudz2g r-ktdv3k r-12ym1je' style={{ marginRight: "5px", marginLeft:"5px" }}>
                  <div className='css-1dbjc4n r-1mlwlqe r-1hjwoze r-1udh08x r-12ym1je r-417010'>
                    <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                      style={{ backgroundImage: 'url(&quot;/universalhp/img/callIcon.shared.png&quot;)' }}></div>
                    <img src="/assets/images/all/callIcon.png" alt="" />
                  </div>
                </div>
                <div className='css-1dbjc4n'>
                  <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'>9 AM - 8 PM IST</div>
                  <div className='css-901oao r-gi2tka r-98loyc r-ubezar r-13uqrnb r-1kfrs79 r-waer2z'>1800-41-99099</div>
                </div>
              </div>
              <div className='css-1dbjc4n r-1awozwy r-18u37iz' style={{marginRight:"5px"}}>
                <div className='css-1dbjc4n r-1hjwoze r-y3t9qe r-19qrga8 r-hudz2g r-ktdv3k r-12ym1je' style={{ marginRight: "5px", marginLeft:"5px" }}>
                  <div className='css-1dbjc4n r-1mlwlqe r-1hjwoze r-1udh08x r-12ym1je r-417010'>
                    <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                      style={{ backgroundImage: 'url(&quot;/universalhp/img/callIcon.shared.png&quot;)' }}></div>
                    <img src="/assets/images/all/callIcon.png" alt="" />
                  </div>
                </div>
                <div className='css-1dbjc4n'>
                  <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'>9 AM - 8 PM IST</div>
                  <div className='css-901oao r-gi2tka r-98loyc r-ubezar r-13uqrnb r-1kfrs79 r-waer2z'>1800-41-99099</div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className='css-1dbjc4n r-eu3ka'></div>
        </div>
        <Footer/>
      </div>
    </>
  )
}

export default SafetyGuide