import React, { useEffect, useState } from "react";
import { FaRupeeSign } from "react-icons/fa";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoIosCheckmark } from "react-icons/io";
import { PiHouseLight } from "react-icons/pi";
import moment from "moment/moment";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropertyDetailsModel from "../home/latestProperty/PropertyDetailsModel";
import { useSelector } from "react-redux";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const OfficeComSubbanner = (props) => {
  const { loginData } = useSelector((store) => store.Athentication);

  const [property, setProperty] = useState({});
  const [postMobile, setPostMobile] = useState("");
  const [propertyImagesWithName, setPropertyImagesWithName] = useState([]);

  const toggle = () => setModal(!modal);
  const [modal, setModal] = useState(false);
  const [singleProperty, setSingleProperty] = useState("");

  const [alredyViewed, setAlredyViewed] = useState(true);
  const [tempLoading, setTempLoading] = useState(true);

  useEffect(() => {
    const singleProperty = props.singlProperty;
    setProperty(singleProperty);
    setPostMobile(singleProperty.postUserMobile);
    setPropertyImagesWithName(singleProperty.titleImg);
  }, [props.singlProperty]);

  const closePropDetailModal = () => {
    setModal(false);
  };

  const toggleClick = (e) => {
    setModal(!modal);
    setSingleProperty(property);
  };
  const changeView = () => {};

  useEffect(() => {
    const viewResponseClick = async () => {
      const formVerifyData = {
        propertyId: property._id,
        userId: loginData._id,
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const verifyurl = `${Baseurl}/api/v1/viewed/viewedpropertyverifybyuser`;
      const propertyVerify = await axios.post(
        verifyurl,
        formVerifyData,
        config
      );
      if (propertyVerify.data.success === true) {
        if (propertyVerify.data.userViewedProperty.length > 0) {
          setAlredyViewed(false);
          setTempLoading(false);
        } else {
          setTempLoading(false);
        }
      } else {
        setTempLoading(false);
      }
    };
    viewResponseClick();
  }, [property]);

  return (
    <div>
      <div className="container main-subbanner">
        <div className="row">
          <div className="col-lg-9">
            <div className="paragraph">
              {/* <p>
                Home › Property in Hyderabad › House for sale in Hyderabad ›
                House for sale in Kondapur › 4 BHK House for sale in Kondapur
              </p> */}
            </div>
            <div className="sub-varify">
              <i>
                <IoIosCheckmark />
              </i>

              <p
                style={{
                  paddingRight: "3px",
                  color: "#fff",
                  paddingTop: "5px",
                  fontSize: "10px",
                }}
              >
                Verified
              </p>
              <i>
                <IoIosInformationCircleOutline />
              </i>
            </div>

            <div className="header-part ">
              <div className="header-part2">
                <div className="header-croce">
                  <div className="header-croce3">
                    <i>
                      <FaRupeeSign />
                    </i>
                  </div>
                  <div className="header-croce1">
                    <h1>{property.visiblePrice}</h1>
                  </div>
                </div>
                {/* <div className="estimated">
                  <a href="">Estimated EMI ₹7,13,562</a>
                </div> */}
              </div>

              <div className="bedroom">
                {/* <div className="" style={{ textAlign: "left" }}>
                  {property.apartment}
                </div> */}
                <div className="Independent">
                  {property.typeOfland !== "" ? (
                    <>
                      <h2 style={{ fontSize: "22px", textAlign: "left" }}>
                        {property.typeOfland} for{" "}
                        {property.category === "Sell"
                          ? "Sale"
                          : property.category}
                      </h2>
                    </>
                  ) : (
                    <>
                      <h2 style={{ fontSize: "22px", textAlign: "left" }}>
                        {property.superCategory} {property.subCategory} for{" "}
                        {property.category === "Sell"
                          ? "Sale"
                          : property.category}
                      </h2>
                    </>
                  )}

                  <h2 style={{ textAlign: "left" }}>
                    in{" "}
                    {property.locality +
                      ", " +
                      property.city +
                      ", " +
                      property.state}
                  </h2>
                </div>
              </div>
            </div>

            <div className="sub-header">
              <div className="rerastatu">
                RERA STATUS
                {/* <i>
                  <IoIosInformationCircleOutline />
                </i> */}
              </div>
              {/* <div className="registred">REGISTERED</div> */}
              <div className="registern">
                <b>Property ID</b>: {property.propertyID}
              </div>
            </div>
          </div>
          <div
            className="col-lg-3"
            style={{
              marginTop: "20px",
              border: ".3px solid #ddd",
              padding: "10px",
            }}
          >
            <div className="box-header">
              <div className="imges">
                <img
                  src="https://static.99acres.com//universalapp/img/fd-default.png"
                  style={{ width: "70px", height: "70px" }}
                />
              </div>
              <div className="sreevi">
                {/* <p>SREE VIGHNESWARA REALTY PVT. LTD </p> */}
                <h2 style={{ textAlign: "left" }}>{property.postUserName}</h2>
                <div className="featured-header">
                  <div className="featured" style={{ textAlign: "left" }}>
                    {property.postUserPosition}
                  </div>
                  {/* <div className="membes">Member Since Sep, 2021</div> */}
                </div>
              </div>
            </div>
            <div className="" onClick={(e) => toggleClick(e)}>
              <button className="sub-bannerbtn">
                Contact {property.postUserPosition}
              </button>
            </div>
            <Modal
              isOpen={modal}
              toggle={toggle}
              className="modalmainnew"
              style={{}}
            >
              <div className="model-wrappernew">
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                  <PropertyDetailsModel
                    closePropdet={closePropDetailModal}
                    propertyprop={singleProperty}
                    changeView={changeView}
                  />
                </ModalBody>
              </div>
            </Modal>

            {alredyViewed === false ? (
              <>
                <div className="main-ready">
                  <div className="ready">
                    <div className="sub-ready">
                      <i>
                        <PiHouseLight />
                      </i>

                      <p>Posted By {property.postUserMobile}</p>
                    </div>
                  </div>
                  <div className="posted">{property.postUserEmail}</div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfficeComSubbanner;
