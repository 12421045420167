import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  subCatTotal: [],

  isLoading: true,
  subcatLoading: true,
};

export const getSubCategory = createAsyncThunk(
  "SubCategory/getSubCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcategory/all`;
      const resp = await axios(url);
      return resp.data.subcategory;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory Not Found");
    }
  }
);
  
const SubCategorySlice = createSlice({
  name: "SubCategory",
  initialState,
  extraReducers: {
    [getSubCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [getSubCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subcatLoading = false;
      state.subCatTotal = action.payload;
    },
    [getSubCategory.rejected]: (state) => {
      state.isLoading = true;
    },
  },
});

export default SubCategorySlice.reducer;
