import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsFillSearchHeartFill, BsMicFill } from "react-icons/bs";
import { BiCurrentLocation } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import { Badge } from "react-bootstrap";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { searchPost } from "../../../redux/search/SearchSlice";
import { setPropertySearchBySelectionCat } from "../../../redux/property/PropertySlice";

const CommercialtwoSearchComponent = () => {
  const { isAuth, loginData } = useSelector((store) => store.Athentication);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();

  const [showResults, setShowResults] = useState(false);

  const [toggle, setToggle] = useState(false);
  const [budgettoggle, setbudgetToggle] = useState(false);
  const [bedroomtoggle, setbedroomToggle] = useState(false);
  const [constructiontoggle, setconstructionToggle] = useState(false);
  const [postedbytoggle, setpostedbyToggle] = useState(false);

  const [noofSeatsToggle, setNoofSeatsToggle] = useState(false);
  const [noofSeatsSelectToggle, setNoofSeatsSelectToggle] = useState(false);
  const [seatTypesToggle, setSeatTypesToggle] = useState(false);
  const [plansBudgetToggle, setPlansBudgetToggle] = useState(false);
  const [situatedInToggle, setSituatedInToggle] = useState(false);
  const [businessTypeToggle, setBusinessTypeToggle] = useState(false);

  const [shop, setShop] = useState(false);
  const [showroom, setShowroom] = useState(false);
  const [commercialLand, setCommercialLand] = useState(false);
  const [warehouse, setWarehouse] = useState(false);
  const [agriculturalLand, setAgriculturalLand] = useState(false);
  const [coldStorage, setColdStorage] = useState(false);
  const [industrialLand, setIndustrialLand] = useState(false);
  const [hotel, setHotel] = useState(false);
  const [manufacturing, setManufacturing] = useState(false);
  const [apartment, setApartment] = useState(false);
  const [factory, setFactory] = useState(false);
  const [other, setOther] = useState(false);
  const [flat, setFlat] = useState(false);
  const [land, setLand] = useState(false);
  const [services, setServices] = useState(false);
  const [independent, setIndependent] = useState(false);
  const [studio, setStudio] = useState(false);
  const [house, setHouse] = useState(false);
  const [farm, setFarm] = useState(false);

  const [search, setSearch] = useState("");
  const [showresult, setShowresult] = useState(false);

  const [buychange, setBuychange] = useState(true);
  const [rentchange, setRentchange] = useState(false);
  const [pgcolivingChange, setPgcolivingChange] = useState(false);
  const [commerChange, setcommerChange] = useState(false);
  const [coworkingChange, setCoworkingChange] = useState(false);
  const [plotChange, setPlotChange] = useState(false);
  const [projectchange, setProjectchange] = useState(false);

  const [supercat, setSupercat] = useState("Residential");
  const [cat, setCat] = useState("Sell");
  const [checkboxArray, setCheckboxArray] = useState([]);

  const [propertySearch, setPropertySearch] = useState([]);
  const [propertySearchCity, setPropertySearchCity] = useState([]);
  const [propertySearchLocality, setPropertySearchLocality] = useState([]);
  const [propertySearchProject, setPropertySearchProject] = useState([]);

  const [commercialGetByData, setCommercialGetByData] = useState("");
  const [propertyTypes, setPropertyTypes] = useState("officespaces");
  const [officeSpaceType, setOfficeSpaceType] = useState("");


  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const handleToggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
  };

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  useEffect(() => {
    if (commercialGetByData !== "") {
      dispatch(setPropertySearchBySelectionCat(commercialGetByData));
    }
  }, [commercialGetByData]);

  //for search bar strt
  useEffect(() => {
    try {
      if (search.length >= 2) {
        const fetchdata = async () => {
          const url = `${Baseurl}/api/v1/property/allPropertylocalitycity/${search}`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.properties.slice(0, 5);
              setPropertySearch(searchresult);
            }
          } catch (error) {}
        };

        fetchdata();
      }
    } catch (error) {}

    try {
      if (search.length >= 2) {
        const fetchPropertyByProjects = async () => {
          const url = `${Baseurl}/api/v1/property/allPropertyproject/${search}`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.properties;
              setPropertySearchProject(searchresult);
            }
          } catch (error) {}
        };

        fetchPropertyByProjects();
      }
    } catch (error) {}

    setShowResults(true);
  }, [search]);
  //for search bar end

  const residential = (e, value) => {
    setBuychange(false);
    setRentchange(false);
    setPgcolivingChange(false);
    setcommerChange(false);
    setCoworkingChange(false);
    setPlotChange(false);
    setProjectchange(false);

    setSupercat("Residential");
    // setSubcat("All");
    if (value === "Buy") {
      setCat("Sell");
      setBuychange(true);
    } else if (value === "Rent") {
      setCat("Rent");
      setRentchange(true);
    } else if (value === "PG/Co-living") {
      setCat("PG/Co-living");
      setPgcolivingChange(true);
    } else if (value === "Coworking") {
      setCat("Coworking");
      setCoworkingChange(true);
    } else if (value === "Plots/Land") {
      setCat("Plots/Land");
      setPlotChange(true);
    } else if (value === "Projects") {
      setCat("Projects");
      setProjectchange(true);
    }
  };
  const commercialClick = () => {
    setcommerChange(!commerChange);
    if (commerChange === false) {
      setCat("Commercial");
      setBuychange(false);
      setRentchange(false);
      setPgcolivingChange(false);
      setCoworkingChange(false);
      setPlotChange(false);
      setProjectchange(false);
    }
  };

  //==================for checkbox start===================

  const checkboxChange = (e, value) => {
    const checkvalue = e.target.checked;
    setShop(checkvalue);

    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange8 = (e, value) => {
    const checkvalue = e.target.checked;
    setShowroom(checkvalue);

    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };

  const checkboxChange1 = (e, value) => {
    const checkvalue = e.target.checked;
    setCommercialLand(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange2 = (e, value) => {
    const checkvalue = e.target.checked;
    setWarehouse(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange3 = (e, value) => {
    const checkvalue = e.target.checked;
    setAgriculturalLand(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange4 = (e, value) => {
    const checkvalue = e.target.checked;
    setColdStorage(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange5 = (e, value) => {
    const checkvalue = e.target.checked;
    setIndustrialLand(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange6 = (e, value) => {
    const checkvalue = e.target.checked;
    setHotel(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange7 = (e, value) => {
    const checkvalue = e.target.checked;
    setManufacturing(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange9 = (e, value) => {
    const checkvalue = e.target.checked;
    setFactory(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };
  const checkboxChange10 = (e, value) => {
    const checkvalue = e.target.checked;
    setOther(checkvalue);
    if (checkvalue === true) {
      let checkboxList = [...checkboxArray, value];
      setCheckboxArray(checkboxList);
    } else {
      let checkboxList = checkboxArray.filter((data) => data !== value);
      setCheckboxArray(checkboxList);
    }
  };

  //==================for checkbox end===================

  const searchRedirect = (e, data) => {
    e.preventDefault();

    navigate(`/property-info/${data.apartment}/${data.propertyID}`);

    if (isAuth) {
      const formdata = {
        userId: loginData._id,
        name: loginData.name,
        userMobile: loginData.mobile,
        propertyId: data._id,
        city: data.city,
        locality: data.locality,
        apartment: data.apartment,
      };
      dispatch(searchPost(formdata));
      setShowResults(false);
    }
  };
  const projectRedirect = (e, data) => {
    e.preventDefault();

    navigate(`/project-info/${data.apartment}`);

    if (isAuth) {
      const formdata = {
        userId: loginData._id,
        name: loginData.name,
        userMobile: loginData.mobile,
        propertyId: data._id,
        city: data.city,
        locality: data.locality,
        apartment: data.apartment,
      };
      dispatch(searchPost(formdata));
      setShowResults(false);
    }
  };
  const searchChange = (e) => {
    const result = e.target.value;
    setSearch(result);
  };

  const searchClick = () => {
    let mysubcat = "All";
    if (checkboxArray.length > 0) {
      mysubcat = checkboxArray.join();
      mysubcat = mysubcat.replaceAll(",", "-");
      mysubcat = mysubcat.replaceAll("/", "-");
      mysubcat = mysubcat.replaceAll(" ", "-");
    }

    navigate(`/property-search/${supercat}/${cat}/${mysubcat}/${search}`);
    setShowresult(false);
  };

  const searchsubmit = () => {
    let mysubcat = "All";
    if (checkboxArray.length > 0) {
      mysubcat = checkboxArray.join();
      mysubcat = mysubcat.replaceAll(",", "-");
      mysubcat = mysubcat.replaceAll("/", "-");
      mysubcat = mysubcat.replaceAll(" ", "-");
    }

    navigate(`/property-search/${supercat}/${cat}/${mysubcat}/${search}`);
    setShowresult(false);
  };
  return (
    <>
      <div className="dropdownbox "  style={{paddingTop:"0"}}>
        <div className="check-label">
          <div className="parentlabel">
            <div className="childlabel">
              <input
                type="radio"
                id="Buy"
                name="Buy"
                value={commercialGetByData}
                checked={commercialGetByData === "Buy"}
                onClick={() => setCommercialGetByData("Buy")}
              />
              <label
                className=""
                htmlFor="Buy"
                onClick={() => setCommercialGetByData("Buy")}
              >
                Buy
              </label>
            </div>
          </div>
          <div className="parentlabel">
            <div className="childlabel">
              <input
                type="radio"
                id="Lease"
                name="Lease"
                value={commercialGetByData}
                checked={commercialGetByData === "Lease"}
                onClick={() => setCommercialGetByData("Lease")}
              />
              <label
                className=""
                htmlFor="Lease"
                onClick={() => setCommercialGetByData("Lease")}
              >
                Lease
              </label>
            </div>
          </div>

          <div className="parentlabel">
            <div className="childlabel">
              <input
                type="radio"
                id="Invest"
                name="Invest"
                value={commercialGetByData}
                checked={commercialGetByData === "Invest"}
                onClick={() => setCommercialGetByData("Invest")}
              />
              <label
                className=""
                htmlFor="Invest"
                onClick={() => setCommercialGetByData("Invest")}
              >
                Invest
              </label>
            </div>
          </div>
        </div>

        {commercialGetByData === "Lease" ? (
          <>
            <div className="">
              <p className="">Property Types</p>
            </div>
            <div className="check-label">
              <div className="parentlabel">
                <div className="childlabel">
                  <input
                    type="radio"
                    id="officespaces"
                    name="officespaces"
                    value={propertyTypes}
                    checked={propertyTypes === "officespaces"}
                    onClick={() => setPropertyTypes("officespaces")}
                  />
                  <label
                    className=""
                    htmlFor="officespaces"
                    onClick={() => setPropertyTypes("officespaces")}
                  >
                    Office Spaces
                  </label>
                </div>
              </div>
              <div className="parentlabel">
                <div className="childlabel">
                  <input
                    type="radio"
                    id="shopretial"
                    name="shopretial"
                    value={propertyTypes}
                    checked={propertyTypes === "shopretial"}
                    onClick={() => setPropertyTypes("shopretial")}
                  />
                  <label
                    className=""
                    htmlFor="shopretial"
                    onClick={() => setPropertyTypes("shopretial")}
                  >
                    Shops & Retail
                  </label>
                </div>
              </div>
              <div className="parentlabel">
                <div className="childlabel">
                  <input
                    type="radio"
                    id="othercommspaces"
                    name="othercommspaces"
                    value={propertyTypes}
                    checked={propertyTypes === "othercommspaces"}
                    onClick={() => setPropertyTypes("othercommspaces")}
                  />
                  <label
                    className=""
                    htmlFor="othercommspaces"
                    onClick={() => setPropertyTypes("othercommspaces")}
                  >
                    Other commercial spaces
                  </label>
                </div>
              </div>
              <div className="parentlabel">
                <div className="childlabel">
                  <input
                    type="radio"
                    id="factorymanufacturing"
                    name="factorymanufacturing"
                    value={propertyTypes}
                    checked={propertyTypes === "factorymanufacturing"}
                    onClick={() => setPropertyTypes("factorymanufacturing")}
                  />
                  <label
                    className=""
                    htmlFor="factorymanufacturing"
                    onClick={() => setPropertyTypes("factorymanufacturing")}
                  >
                    Factory & Manufacturing
                  </label>
                </div>
              </div>
            </div>

            {propertyTypes === "officespaces" ? (
              <>
                <div className="">
                  <p className=""> Office space type</p>
                </div>
                <div className="check-label">
                  <div className="parentlabel">
                    <div className="childlabel">
                      <input
                        type="radio"
                        id="readytomove"
                        name="readytomove"
                        value={officeSpaceType}
                        checked={officeSpaceType === "readytomove"}
                        onClick={() => setOfficeSpaceType("readytomove")}
                      />
                      <label className="" htmlFor="readytomove">
                        Ready to move offices
                      </label>
                    </div>
                  </div>
                  <div className="parentlabel">
                    <div className="childlabel">
                      <input
                        type="radio"
                        id="bareshelloffices"
                        name="bareshelloffices"
                        value={officeSpaceType}
                        checked={officeSpaceType === "bareshelloffices"}
                        onClick={() => setOfficeSpaceType("bareshelloffices")}
                      />
                      <label className="" htmlFor="bareshelloffices">
                        Bare shell offices
                      </label>
                    </div>
                  </div>
                  <div className="parentlabel">
                    <div className="childlabel">
                      <input
                        type="radio"
                        id="coworkingofficespace"
                        name="coworkingofficespace"
                        value={officeSpaceType}
                        checked={officeSpaceType === "coworkingofficespace"}
                        onClick={() =>
                          setOfficeSpaceType("coworkingofficespace")
                        }
                      />
                      <label className="" htmlFor="coworkingofficespace">
                        Co-working office space
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {propertyTypes === "shopretial" ? (
              <>
                <div className="">
                  <p className="">Shops & Retail</p>
                </div>
                <div className="check-label">
                  <div className="parentlabel">
                    <div className="childlabel">
                      <input
                        type="checkbox"
                        id="flat"
                        name="flat"
                        value={shop}
                        checked={shop === true}
                        onChange={(e) => checkboxChange(e, "Shops")}
                      />
                      <label className="" htmlFor="flat">
                        Shops
                      </label>
                    </div>
                  </div>
                  <div className="parentlabel">
                    <div className="childlabel">
                      <input
                        type="checkbox"
                        id="flat"
                        name="flat"
                        value={showroom}
                        checked={showroom === true}
                        onChange={(e) => checkboxChange8(e, "Showrooms")}
                      />
                      <label className="" htmlFor="flat">
                        Showrooms
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {propertyTypes === "othercommspaces" ? (
              <>
                <div className="">
                  <p className="">Other commercial spaces</p>
                </div>
                <div className="check-label">
                  <div className="parentlabel">
                    <div className="childlabel">
                      <input
                        type="checkbox"
                        id="flat"
                        name="flat"
                        value={commercialLand}
                        checked={commercialLand === true}
                        onChange={(e) =>
                          checkboxChange1(e, "Commercial/Inst. Land")
                        }
                      />
                      <label className="" htmlFor="flat">
                        Commercial/Inst. Land
                      </label>
                    </div>
                    <div className="childlabel">
                      <input
                        type="checkbox"
                        id="flat"
                        name="flat"
                        value={warehouse}
                        checked={warehouse === true}
                        onChange={(e) => checkboxChange2(e, "Warehouse")}
                      />
                      <label className="" htmlFor="flat">
                        Warehouse
                      </label>
                    </div>
                    <div className="childlabel">
                      <input
                        type="checkbox"
                        id="flat"
                        name="flat"
                        value={other}
                        checked={other === true}
                        onChange={(e) => checkboxChange10(e, "Others")}
                      />
                      <label className="" htmlFor="flat">
                        Others
                      </label>
                    </div>
                  </div>
                  <div className="parentlabel">
                    <div className="childlabel">
                      <input
                        type="checkbox"
                        id="flat"
                        name="flat"
                        value={agriculturalLand}
                        checked={agriculturalLand === true}
                        onChange={(e) =>
                          checkboxChange3(e, "Agricultural/Farm Land")
                        }
                      />
                      <label className="" htmlFor="flat">
                        Agricultural/Farm Land
                      </label>
                    </div>
                    <div className="childlabel">
                      <input
                        type="checkbox"
                        id="flat"
                        name="flat"
                        value={coldStorage}
                        checked={coldStorage === true}
                        onChange={(e) => checkboxChange4(e, "Cold Storage")}
                      />
                      <label className="" htmlFor="flat">
                        Cold Storage
                      </label>
                    </div>
                  </div>
                  <div className="parentlabel">
                    <div className="childlabel">
                      <input
                        type="checkbox"
                        id="flat"
                        name="flat"
                        value={industrialLand}
                        checked={industrialLand === true}
                        onChange={(e) =>
                          checkboxChange5(e, "Industrial Land/Plots")
                        }
                      />
                      <label className="" htmlFor="flat">
                        Industrial Land/Plots
                      </label>
                    </div>
                    <div className="childlabel">
                      <input
                        type="checkbox"
                        id="flat"
                        name="flat"
                        value={hotel}
                        checked={hotel === true}
                        onChange={(e) => checkboxChange6(e, "Hotel/Resorts")}
                      />
                      <label className="" htmlFor="flat">
                        Hotel/Resorts
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {propertyTypes === "factorymanufacturing" ? (
              <>
                <div className="">
                  <p className="">Factory & Manufacturing</p>
                </div>
                <div className="check-label">
                  <div className="parentlabel">
                    <div className="childlabel">
                      <input
                        type="checkbox"
                        id="flat"
                        name="flat"
                        value={manufacturing}
                        checked={manufacturing === true}
                        onChange={(e) => checkboxChange7(e, "Manufacturing")}
                      />
                      <label className="" htmlFor="flat">
                        Manufacturing
                      </label>
                    </div>
                  </div>
                  <div className="parentlabel">
                    <div className="childlabel">
                      <input
                        type="checkbox"
                        id="flat"
                        name="flat"
                        value={factory}
                        checked={factory === true}
                        onChange={(e) => checkboxChange9(e, "Factory")}
                      />
                      <label className="" htmlFor="flat">
                        Factory
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        <div className="label-caption">
          <p>
            Looking for commercial properties ?<a href="#">Click here</a>
          </p>
          <hr />
        </div>
        <div
          className="dropdown-lists"
          style={{ flexWrap: "wrap", paddingRight: 0 }}
        >
          {/* {commercialGetByData === "Lease" &&
          propertyTypes === "officespaces" ? (
            <>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbudgetToggle(!budgettoggle)}
                >
                  <label className="">Budget</label>
                  {!budgettoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {budgettoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      <div className="range-head">
                        <h6 className="">Select Price Range</h6>
                      </div>
                      <div className="range-bar">
                        <label htmlFor="customRange2" className="">
                          0 - 1.25 Crores
                        </label>
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>

              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbedroomToggle(!bedroomtoggle)}
                >
                  <label className="">Area </label>
                  {!bedroomtoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {bedroomtoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      <div className="range-head">
                        <h6 className="">Select Area Range</h6>
                      </div>
                      <label htmlFor="customRange2" className="">
                        sq.ft.
                      </label>
                      <div className="range-bar">
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>

              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setpostedbyToggle(!postedbytoggle)}
                >
                  <label className="">Posted By</label>
                  {!postedbytoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {postedbytoggle ? (
                  <>
                    <div className="bedroombox">
                      <div className="posted-head">
                        <h6 className="">Posted By</h6>
                      </div>
                      <div className="posted-main">
                        <div className="boxes">
                          <label className="">+ Owner</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Builder</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Dealer</label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )} */}
          {propertyTypes === "officespaces" &&
          officeSpaceType === "readytomove" ? (
            <>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbudgetToggle(!budgettoggle)}
                >
                  <label className="">Budget</label>
                  {!budgettoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {budgettoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      <div className="range-head">
                        <h6 className="">Select Price Range</h6>
                      </div>
                      <div className="range-bar">
                        <label htmlFor="customRange2" className="">
                          0 - 1.25 Crores
                        </label>
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>

              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setNoofSeatsToggle(!noofSeatsToggle)}
                >
                  <label className="">Number of Seats</label>
                  {!noofSeatsToggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {noofSeatsToggle ? (
                  <>
                    <div
                      className="bedroombox"
                      style={{ height: "initial", paddingBottom: "10px" }}
                    >
                      <div className="posted-head">
                        <h6 className="">Number of Seats</h6>
                      </div>
                      <input type="text" placeholder="Number of Seats" />
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>

              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setpostedbyToggle(!postedbytoggle)}
                >
                  <label className="">Show Grade A offices</label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {propertyTypes === "officespaces" &&
          officeSpaceType === "bareshelloffices" ? (
            <>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbudgetToggle(!budgettoggle)}
                >
                  <label className="">Budget</label>
                  {!budgettoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {budgettoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      {" "}
                      <div className="range-head">
                        <h6 className="">Select Price Range</h6>
                      </div>
                      <div className="range-bar">
                        <label htmlFor="customRange2" className="">
                          0 - 1.25 Crores
                        </label>
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbedroomToggle(!bedroomtoggle)}
                >
                  <label className="">Area </label>
                  {!bedroomtoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {bedroomtoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      <div className="range-head" style={{ display: "flex" }}>
                        <h6 className="">Select Area Range </h6>
                        <label
                          htmlFor="customRange2"
                          className=""
                          onClick={handleToggleDropdown}
                          style={{ marginLeft: "5px", position: "relative" }}
                        >
                          {selectedOption || "sq.ft."}
                          <BsChevronDown className="arrowicon" />
                        </label>
                      </div>

                      {isDropdownOpen && (
                        <ul
                          className="dropdown-content"
                          style={{
                            background: "#fff",
                            border: "1px solid #ddd",
                            padding: "10px",
                            width: "10%",
                            position: "absolute",
                            top: "40px",
                            left: "17%",
                          }}
                        >
                          <li onClick={() => handleOptionClick("sq.ft.")}>
                            sq.ft.
                          </li>
                          <li onClick={() => handleOptionClick("sq.yards")}>
                            sq.yards
                          </li>
                          <li onClick={() => handleOptionClick("acres")}>
                            acres
                          </li>
                          <li onClick={() => handleOptionClick("marla")}>
                            marla
                          </li>
                          <li onClick={() => handleOptionClick("cents")}>
                            cents
                          </li>
                          <li onClick={() => handleOptionClick("sq.m")}>
                            sq.m
                          </li>
                        </ul>
                      )}
                      <div className="range-bar">
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>

              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setpostedbyToggle(!postedbytoggle)}
                >
                  <label className="">Show Grade A offices</label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {propertyTypes === "officespaces" &&
          officeSpaceType === "coworkingofficespace" ? (
            <>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() =>
                    setNoofSeatsSelectToggle(!noofSeatsSelectToggle)
                  }
                >
                  <label className="">No. of seats</label>
                  {!noofSeatsSelectToggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
              </div>
              {noofSeatsSelectToggle ? (
                <>
                  <div className="bedroombox">
                    <div className="posted-head">
                      <h6 className="">Number of seats</h6>
                    </div>
                    <div className="posted-main">
                      <div className="boxes">
                        <label className="">1</label>
                      </div>
                      <div className="boxes">
                        <label className="">2-5</label>
                      </div>
                      <div className="boxes">
                        <label className="">6-10</label>
                      </div>
                      <div className="boxes">
                        <label className="">11-20</label>
                      </div>
                      <div className="boxes">
                        <label className="">21-50</label>
                      </div>
                      <div className="boxes">
                        <label className="">51-100</label>
                      </div>
                      <div className="boxes">
                        <label className="">101-500</label>
                      </div>
                      <div className="boxes">
                        <label className="">500+</label>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setSeatTypesToggle(!seatTypesToggle)}
                >
                  <label className="">Seat Types</label>
                  {!seatTypesToggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {seatTypesToggle ? (
                  <>
                    <div className="bedroombox mediabedroom">
                      <div className="bedroom-head">
                        <h6 className="">Seat Type</h6>
                      </div>
                      <div className="boxes-parent">
                        <div className="boxes">
                          <label className="">+ Hot Desk </label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Meeting Rooms</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Dedicated Desk </label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Private Cabin </label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Private Office </label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Multi-Utility spaces </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setPlansBudgetToggle(!plansBudgetToggle)}
                >
                  <label className="">Plans / Budget</label>
                  {!plansBudgetToggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {plansBudgetToggle ? (
                  <>
                    <div className="bedroombox">
                      <div className="construction-head">
                        <h6 className="">Choose your plan to adjust pricing</h6>
                      </div>
                      <div className="construction-boxes">
                        <div className="boxes">
                          <label className="">Hourly</label>
                        </div>
                        <div className="boxes">
                          <label className="">Daily</label>
                        </div>
                        <div className="boxes">
                          <label className="">Weekly</label>
                        </div>
                        <div className="boxes">
                          <label className="">Monthly</label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          {propertyTypes === "shopretial" ? (
            <>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbudgetToggle(!budgettoggle)}
                >
                  <label className="">Budget</label>
                  {!budgettoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {budgettoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      {" "}
                      <div className="range-head">
                        <h6 className="">Select Price Range</h6>
                      </div>
                      <div className="range-bar">
                        <label htmlFor="customRange2" className="">
                          0 - 1.25 Crores
                        </label>
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbedroomToggle(!bedroomtoggle)}
                >
                  <label className="">Area </label>
                  {!bedroomtoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {bedroomtoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      <div className="range-head" style={{ display: "flex" }}>
                        <h6 className="">Select Area Range </h6>
                        <label
                          htmlFor="customRange2"
                          className=""
                          onClick={handleToggleDropdown}
                          style={{ marginLeft: "5px", position: "relative" }}
                        >
                          {selectedOption || "sq.ft."}
                          <BsChevronDown className="arrowicon" />
                        </label>
                      </div>

                      {isDropdownOpen && (
                        <ul
                          className="dropdown-content"
                          style={{
                            background: "#fff",
                            border: "1px solid #ddd",
                            padding: "10px",
                            width: "10%",
                            position: "absolute",
                            top: "40px",
                            left: "17%",
                          }}
                        >
                          <li onClick={() => handleOptionClick("sq.ft.")}>
                            sq.ft.
                          </li>
                          <li onClick={() => handleOptionClick("sq.yards")}>
                            sq.yards
                          </li>
                          <li onClick={() => handleOptionClick("acres")}>
                            acres
                          </li>
                          <li onClick={() => handleOptionClick("marla")}>
                            marla
                          </li>
                          <li onClick={() => handleOptionClick("cents")}>
                            cents
                          </li>
                          <li onClick={() => handleOptionClick("sq.m")}>
                            sq.m
                          </li>
                        </ul>
                      )}
                      <div className="range-bar">
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setSituatedInToggle(!situatedInToggle)}
                >
                  <label className="">Situated in</label>
                  {!situatedInToggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {situatedInToggle ? (
                  <>
                    <div className="bedroombox">
                      <div className="posted-head">
                        <h6 className="">Situated in</h6>
                      </div>
                      <div className="posted-main">
                        <div className="boxes">
                          <label className="">+ Malls </label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Commercial Projects</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Residential Project</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Retail Complex/Building</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Market/High Street</label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setBusinessTypeToggle(!businessTypeToggle)}
                >
                  <label className="">Business Type</label>
                  {!businessTypeToggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {businessTypeToggle ? (
                  <>
                    <div className="bedroombox2">
                      <div className="posted-head">
                        <h6 className="">Business Type</h6>
                      </div>

                      <div className="posted-main2">
                        <div className="boxes2">
                          <label className="">+ Jewellery </label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Gym</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Grocery</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Meat</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Clinic</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Stationery</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Mobile</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Clothes</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Footwear</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Medical</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Salon/Spa</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Fast Food</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Coffee</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ ATM</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Juice</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Sweet</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Bakery</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Boutique</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Pub/Bar</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Restaurants</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Tea Stall</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Dental Clinic</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Cloud Kitchen</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Other business type</label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          {propertyTypes === "othercommspaces" ? (
            <>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbudgetToggle(!budgettoggle)}
                >
                  <label className="">Budget</label>
                  {!budgettoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {budgettoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      <div className="range-head">
                        <h6 className="">Select Price Range</h6>
                      </div>
                      <div className="range-bar">
                        <label htmlFor="customRange2" className="">
                          0 - 1.25 Crores
                        </label>
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>

              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbedroomToggle(!bedroomtoggle)}
                >
                  <label className="">Area </label>
                  {!bedroomtoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {bedroomtoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      <div className="range-head" style={{ display: "flex" }}>
                        <h6 className="">Select Area Range </h6>
                        <label
                          htmlFor="customRange2"
                          className=""
                          onClick={handleToggleDropdown}
                          style={{ marginLeft: "5px", position: "relative" }}
                        >
                          {selectedOption || "sq.ft."}
                          <BsChevronDown className="arrowicon" />
                        </label>
                      </div>

                      {isDropdownOpen && (
                        <ul
                          className="dropdown-content"
                          style={{
                            background: "#fff",
                            border: "1px solid #ddd",
                            padding: "10px",
                            width: "10%",
                            position: "absolute",
                            top: "40px",
                            left: "17%",
                          }}
                        >
                          <li onClick={() => handleOptionClick("sq.ft.")}>
                            sq.ft.
                          </li>
                          <li onClick={() => handleOptionClick("sq.yards")}>
                            sq.yards
                          </li>
                          <li onClick={() => handleOptionClick("acres")}>
                            acres
                          </li>
                          <li onClick={() => handleOptionClick("marla")}>
                            marla
                          </li>
                          <li onClick={() => handleOptionClick("cents")}>
                            cents
                          </li>
                          <li onClick={() => handleOptionClick("sq.m")}>
                            sq.m
                          </li>
                        </ul>
                      )}
                      <div className="range-bar">
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>

              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setpostedbyToggle(!postedbytoggle)}
                >
                  <label className="">Posted By</label>
                  {!postedbytoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {postedbytoggle ? (
                  <>
                    <div className="bedroombox">
                      <div className="posted-head">
                        <h6 className="">Posted By</h6>
                      </div>
                      <div className="posted-main">
                        <div className="boxes">
                          <label className="">+ Owner</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Builder</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Dealer</label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          {propertyTypes === "factorymanufacturing" ? (
            <>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbudgetToggle(!budgettoggle)}
                >
                  <label className="">Budget</label>
                  {!budgettoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {budgettoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      <div className="range-head">
                        <h6 className="">Select Price Range</h6>
                      </div>
                      <div className="range-bar">
                        <label htmlFor="customRange2" className="">
                          0 - 1.25 Crores
                        </label>
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>

              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbedroomToggle(!bedroomtoggle)}
                >
                  <label className="">Area </label>
                  {!bedroomtoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {bedroomtoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      <div className="range-head" style={{ display: "flex" }}>
                        <h6 className="">Select Area Range </h6>
                        <label
                          htmlFor="customRange2"
                          className=""
                          onClick={handleToggleDropdown}
                          style={{ marginLeft: "5px", position: "relative" }}
                        >
                          {selectedOption || "sq.ft."}
                          <BsChevronDown className="arrowicon" />
                        </label>
                      </div>

                      {isDropdownOpen && (
                        <ul
                          className="dropdown-content"
                          style={{
                            background: "#fff",
                            border: "1px solid #ddd",
                            padding: "10px",
                            width: "10%",
                            position: "absolute",
                            top: "40px",
                            left: "17%",
                          }}
                        >
                          <li onClick={() => handleOptionClick("sq.ft.")}>
                            sq.ft.
                          </li>
                          <li onClick={() => handleOptionClick("sq.yards")}>
                            sq.yards
                          </li>
                          <li onClick={() => handleOptionClick("acres")}>
                            acres
                          </li>
                          <li onClick={() => handleOptionClick("marla")}>
                            marla
                          </li>
                          <li onClick={() => handleOptionClick("cents")}>
                            cents
                          </li>
                          <li onClick={() => handleOptionClick("sq.m")}>
                            sq.m
                          </li>
                        </ul>
                      )}
                      <div className="range-bar">
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>

              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setpostedbyToggle(!postedbytoggle)}
                >
                  <label className="">Posted By</label>
                  {!postedbytoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {postedbytoggle ? (
                  <>
                    <div className="bedroombox">
                      <div className="posted-head">
                        <h6 className="">Posted By</h6>
                      </div>
                      <div className="posted-main">
                        <div className="boxes">
                          <label className="">+ Owner</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Builder</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Dealer</label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setSituatedInToggle(!situatedInToggle)}
                >
                  <label className="">Situated in</label>
                  {!situatedInToggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {situatedInToggle ? (
                  <>
                    <div className="bedroombox">
                      <div className="posted-head">
                        <h6 className="">Situated in</h6>
                      </div>
                      <div className="posted-main">
                        <div className="boxes">
                          <label className="">+ Malls </label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Commercial Projects</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Residential Project</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Retail Complex/Building</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Market/High Street</label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setBusinessTypeToggle(!businessTypeToggle)}
                >
                  <label className="">Business Type</label>
                  {!businessTypeToggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {businessTypeToggle ? (
                  <>
                    <div className="bedroombox2">
                      <div className="posted-head">
                        <h6 className="">Business Type</h6>
                      </div>

                      <div className="posted-main2">
                        <div className="boxes2">
                          <label className="">+ Jewellery </label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Gym</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Grocery</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Meat</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Clinic</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Stationery</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Mobile</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Clothes</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Footwear</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Medical</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Salon/Spa</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Fast Food</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Coffee</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ ATM</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Juice</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Sweet</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Bakery</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Boutique</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Pub/Bar</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Restaurants</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Tea Stall</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Dental Clinic</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Cloud Kitchen</label>
                        </div>
                        <div className="boxes2">
                          <label className="">+ Other business type</label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          {commercialGetByData === "Invest" ? (
            <>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbudgetToggle(!budgettoggle)}
                >
                  <label className="">Budget</label>
                  {!budgettoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {budgettoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      <div className="range-head">
                        <h6 className="">Select Price Range</h6>
                      </div>
                      <div className="range-bar">
                        <label htmlFor="customRange2" className="">
                          0 - 1.25 Crores
                        </label>
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          {commercialGetByData === "Buy" ? (
            <>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbudgetToggle(!budgettoggle)}
                >
                  <label className="">Budget</label>
                  {!budgettoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {budgettoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      <div className="range-head">
                        <h6 className="">Select Price Range</h6>
                      </div>
                      <div className="range-bar">
                        <label htmlFor="customRange2" className="">
                          0 - 1.25 Crores
                        </label>
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setbedroomToggle(!bedroomtoggle)}
                >
                  <label className="">Area </label>
                  {!bedroomtoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {bedroomtoggle ? (
                  <>
                    <div className="range-box" style={{ height: "initial" }}>
                      <div className="range-head" style={{ display: "flex" }}>
                        <h6 className="">Select Area Range </h6>
                        <label
                          htmlFor="customRange2"
                          className=""
                          onClick={handleToggleDropdown}
                          style={{ marginLeft: "5px", position: "relative" }}
                        >
                          {selectedOption || "sq.ft."}
                          <BsChevronDown className="arrowicon" />
                        </label>
                      </div>

                      {isDropdownOpen && (
                        <ul
                          className="dropdown-content"
                          style={{
                            background: "#fff",
                            border: "1px solid #ddd",
                            padding: "10px",
                            width: "10%",
                            position: "absolute",
                            top: "40px",
                            left: "17%",
                          }}
                        >
                          <li onClick={() => handleOptionClick("sq.ft.")}>
                            sq.ft.
                          </li>
                          <li onClick={() => handleOptionClick("sq.yards")}>
                            sq.yards
                          </li>
                          <li onClick={() => handleOptionClick("acres")}>
                            acres
                          </li>
                          <li onClick={() => handleOptionClick("marla")}>
                            marla
                          </li>
                          <li onClick={() => handleOptionClick("cents")}>
                            cents
                          </li>
                          <li onClick={() => handleOptionClick("sq.m")}>
                            sq.m
                          </li>
                        </ul>
                      )}
                      <div className="range-bar">
                        <div className="range-holder">
                          <input
                            type="range"
                            className=""
                            min="0"
                            max="100"
                            id="customRange2"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>

              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setconstructionToggle(!constructiontoggle)}
                >
                  <label className="">Construction Status</label>
                  {!constructiontoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {constructiontoggle ? (
                  <>
                    <div className="bedroombox">
                      <div className="construction-head">
                        <h6 className="">Construction Status</h6>
                      </div>
                      <div className="construction-boxes">
                        <div className="boxes">
                          <label className="">+ Under Construction</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Ready to move</label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
              <div className="parent-box">
                <div
                  className="main-boxes"
                  onClick={() => setpostedbyToggle(!postedbytoggle)}
                >
                  <label className="">Posted By</label>
                  {!postedbytoggle ? (
                    <>
                      <BsChevronDown className="arrowicon" />
                    </>
                  ) : (
                    <>
                      <BsChevronUp className="arrowicon" />
                    </>
                  )}
                </div>
                {postedbytoggle ? (
                  <>
                    <div className="bedroombox">
                      <div className="posted-head">
                        <h6 className="">Posted By</h6>
                      </div>
                      <div className="posted-main">
                        <div className="boxes">
                          <label className="">+ Owner</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Builder</label>
                        </div>
                        <div className="boxes">
                          <label className="">+ Dealer</label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          <div className=""></div>
        </div>
      </div>
    </>
  );
};

export default CommercialtwoSearchComponent;
