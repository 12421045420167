import React from "react";

const FurnishingStatus = () => {
  const furnishingStatus = [
    { id: "1", name: "Semifurnished" },
    { id: "2", name: "Un Furnishied" },
    { id: "3", name: "Furnishied" },
  ];
  return (
    <div className="mainbarfiltercomponents">
      {furnishingStatus.map((furnish, index) => (
        <div className="propertyboxes" key={index}>
          <input type="checkbox" />
          <p className="propertytextpara">{furnish.name}</p>
        </div>
      ))}

      <div>
        <p className="clearalltext">Select All</p>
      </div>
    </div>
  );
};

export default FurnishingStatus;