import React, { useEffect, useState } from "react";
import { FcPhoneAndroid, FcInfo } from "react-icons/fc";
import { GrMail } from "react-icons/gr";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import All from "./components/All";
import { listPageStatusChange } from "../../redux/property/PropertySlice";
import Active from "./components/Active";
import Reported from "./components/Reported";
import UnderScreening from "./components/UnderScreening";
import Expired from "./components/Expired";
import Deleted from "./components/Deleted";
import Boosted from "./components/Boosted";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";
import { setSearchProperties } from "../../redux/search/SearchSlice";

const AllListings = () => {
  const { listpageStatus, propertyAllById } = useSelector(
    (store) => store.Property
  );

  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [searchProperty, setSearchProperty] = useState([]);

  const searchChange = (e) => {
    setSearch(e.target.value);
    dispatch(setSearchProperties(e.target.value));
  };

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return (
          <>
            <All />
          </>
        );
      case 1:
        return (
          <>
            <Active />
          </>
        );
      case 2:
        return (
          <>
            <Reported />
          </>
        );
      case 3:
        return (
          <>
            <UnderScreening />
          </>
        );
      case 4:
        return (
          <>
            <Expired />
          </>
        );
      case 5:
        return (
          <>
            <Deleted />
          </>
        );
      case 6:
        return (
          <>
            <Boosted />
          </>
        );

      default:
        return (
          <>
            <All />
          </>
        );
        break;
    }
  };

  return (
    <>
      <div className="pageComponent">
        <ul className="component__pageFilters_allistings component__fs_13 ">
          <li className="">
            <div className="activationStatus">
              <label className="">ACTIVATION STATUS</label>
              <ol className="component__filterList">
                <li
                  className={
                    listpageStatus === 0 ? "component__activefilter" : "null"
                  }
                  onClick={() => dispatch(listPageStatusChange(0))}
                >
                  ALL
                </li>
                <li
                  className={
                    listpageStatus === 1 ? "component__activefilter" : "null"
                  }
                  onClick={() => dispatch(listPageStatusChange(1))}
                >
                  Active
                </li>
                <li
                  className={
                    listpageStatus === 6 ? "component__activefilter" : "null"
                  }
                  onClick={() => dispatch(listPageStatusChange(6))}
                >
                  Boosted
                </li>
                <li
                  className={
                    listpageStatus === 2 ? "component__activefilter" : "null"
                  }
                  onClick={() => dispatch(listPageStatusChange(2))}
                >
                  Reported
                </li>
                <li
                  className={
                    listpageStatus === 3 ? "component__activefilter" : "null"
                  }
                  onClick={() => dispatch(listPageStatusChange(3))}
                >
                  Underscreening
                </li>
                <li
                  className={
                    listpageStatus === 4 ? "component__activefilter" : "null"
                  }
                  onClick={() => dispatch(listPageStatusChange(4))}
                >
                  Expired
                </li>
                <li
                  className={
                    listpageStatus === 5 ? "component__activefilter" : "null"
                  }
                  onClick={() => dispatch(listPageStatusChange(5))}
                >
                  Deleted
                </li>
              </ol>
            </div>
          </li>
          <li className="localityFilterMy99">
            <div className="">
              <div className="search-my">
                <i
                  className="search-icon"
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    verticalAlign: "middle",
                    borderBottom: "solid 1px #ccc",
                    padding: "3px 5px",
                  }}
                >
                  <CiSearch />
                </i>
                <input
                  className="component__noInputBorder component__localityFilterMy99"
                  type="text"
                  placeholder="Enter Locality"
                  value={search}
                  onChange={(e) => searchChange(e)}
                />
              </div>
            </div>
          </li>
          {/* <li className="sortFilters">
            <div className="">
              <span className="component__slidingCategory">Sort</span>
              <select className="component__selectInput">
                <option
                  value="New/Newest First"
                  className="component__selectInput"
                >
                  Newest First
                </option>
                <option value="New/Newest First">Expiring Last</option>
                <option value="Old/Oldest First">Oldest First</option>
                <option value="EXPIRING_FIRST/Expiring First">
                  Expiring First
                </option>
              </select>
            </div>
          </li>
          <li className="progListingFilter">
            <div className="">
              <span className="component__slidingCategory">
                Listing/Projects
              </span>
              <select className="component__selectInput">
                <option value="" className="component__selectInput">
                  Listing/Projects
                </option>
                <option value="Listings/Listings">Listings</option>
                <option value="Projects/Projects">Projects</option>
              </select>
            </div>
          </li> */}
        </ul>
      </div>
      {renderSwitch(listpageStatus)}

      <div className="getInTouch__getInTouch">
        <h4>FOR QUERIES YOU CAN REACH US AT:</h4>
        <div className="getInTouch__block">
          <ul>
            <li>
              <i className="getInTouch__icon">
                <FcPhoneAndroid />
              </i>
              <p>
                <b>Call us at:</b> 1800 41 99099
              </p>
              <p>
                For international numbers click
                <a>here</a>
              </p>
              <p>
                <b>SMS:</b> BUY as 56070
              </p>
            </li>
            <li className="getInTouch__mail">
              <i className="getInTouch__icon">
                <GrMail />
              </i>
              <p>
                <b>Mail us for Sales/Service/General Enquiries:</b>
                <a>services@mygoldenhomes.com</a>
              </p>
              <p>
                For international numbers click
                <a>here</a>
              </p>
            </li>
            <li className="getInTouch__request">
              <i className="getInTouch__icon">
                <FcInfo />
              </i>
              <p>
                <b>Request for Information:</b>
                <br />
                Ask us for information about our services.
                <a>Request Info</a>
              </p>
              <p>
                For international numbers click
                <a>here</a>
              </p>
            </li>
          </ul>
        </div>
        <div className="getInTouch__greyblock">
          <b>Office & Locations :</b>
          <a>Click here</a>
          for Branch Address
        </div>
      </div>
    </>
  );
};

export default AllListings;
