import React from 'react'

const Contacted = () => {
  return (
    <>
      <div className='contacted-outer'>
        <div className='contacted-inner'>
          <div className='contacted-main'>
            <div className='contacted-spacer'></div>
            <div className='contacted-image'>
              <img src="/assets/images/sliderImg/recentActivityPhone.png" alt="" />
            </div>
            <div className='contacted-text'>You haven’t contacted anyone lately!</div>
            <div className='contacted-subtext'>You will see the list of properties / projects here, where you have contacted the advertiser</div>
            <div className='contacted-spacer'></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contacted