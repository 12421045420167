
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsQuestionCircle } from "react-icons/bs";

const percentage = 66;

const OwnershipTemp = (props) => {
  const { PropertyProfile, PriceotherDetails, singleProperty } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [typeOfOwnerShip, setTypeOfOwnerShip] = useState(singleProperty.ownerShip);

  //errors
  const [ownerSelectionError, setOwnerSelectionError] = useState("");

  useEffect(() => {
    if (PriceotherDetails !== "") {
      setTypeOfOwnerShip(PriceotherDetails.typeOfOwnerShip);
    }
  }, [PriceotherDetails]);

  return (
    <>
      <div className="Ownership-widget" style={{ marginTop: "10px" }}>
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div
            className="widgetbox_header_text__2BXc_"
            style={{ marginBottom: "-20px" }}
          >
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Ownership{" "}
                    <i>
                      {" "}
                      <BsQuestionCircle />
                    </i>
                  </span>
                </div>
              </h4>
            </div>
            <p style={{ color: "red" }}>{props.ownerSelectionError}</p>
          </div>
        </div>
        <div className="false">
          <div
            className="pagecomponent"
            id="ownership-input"
            style={{ textAlign: "left" }}
          >
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  typeOfOwnerShip === "Freehold"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setTypeOfOwnerShip("Freehold");

                  props.getOwnership("Freehold")
                }}
                id="1"
              >
                <span>Freehold</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  typeOfOwnerShip === "Leasehold"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setTypeOfOwnerShip("Leasehold");

                  props.getOwnership("Leasehold")
                }}
                id="2"
              >
                <span>Leasehold</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  typeOfOwnerShip === "Co-operative society"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setTypeOfOwnerShip("Co-operative society");

                  props.getOwnership("Co-operative society")
                }}
                id="3"
              >
                <span>Co-operative society</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  typeOfOwnerShip === "Power of Attorney"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setTypeOfOwnerShip("Power of Attorney");

                  props.getOwnership("Power of Attorney")
                }}
                id="4"
              >
                <span>Power of Attorney</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnershipTemp;




