import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./PropertyImages.css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { useLocation } from "react-router-dom";

const PropertyImages = () => {
  const location = useLocation();
  const { propertyImages } = location.state || [];
  const [thumbsSwiper, setThumbsSwiper] = useState("");

  return (
    <div>
      <Swiper
        spaceBetween={10}
        navigation={true}
        thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
        modules={[FreeMode, Navigation, Thumbs]}
        className="swippercss custom-swipper"
        
      >
        {propertyImages &&
          propertyImages.map((image, index) => (
            <SwiperSlide key={index} className="swippercss_slide">
              <img
                src={image}
                alt={`Property ${index}`}
                className="ImageProperties"
              />
            </SwiperSlide>
          ))}
      </Swiper>

      <div className="smallswiper">
        <Swiper
          onSwiper={(swiper) => {
            setThumbsSwiper(swiper);
          }}
          slidesPerView={15}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper1"
          // spaceBetween={2}
        >
          {propertyImages &&
            propertyImages.map((image, index) => (
              <SwiperSlide key={index} className="swippercss_slide">
                <img
                  src={image}
                  alt={`Thumbnail ${index}`}
                  className="ImageProperties2"
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PropertyImages;
