import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { IoSyncOutline } from "react-icons/io5";
import { useEffect } from "react";

export function SkipToPost({
  toggleClick,
  propertyPostClick,
  setSkipPageChange,
  setexperiMounthChange,
  setNoOfBoostingChange,
}) {
  const { loginData } = useSelector((store) => store.Athentication);
  const [numberOfBosting, setnumberOfBosting] = useState(0);
  const [ExperieMounth, setExperieMounth] = useState(2);
  const [ExperieMounth1, setExperieMounth1] = useState("");
  const [ExperieMounth2, setExperieMounth2] = useState("");
  const [ExperieMounth3, setExperieMounth3] = useState("");

  useEffect(() => {
    let today1 = new Date();
    today1.setMonth(today1.getMonth() + 2);
    setExperieMounth1(today1);
    let today2 = new Date();
    today2.setMonth(today2.getMonth() + 4);
    setExperieMounth2(today2);
    let today3 = new Date();
    today3.setMonth(today3.getMonth() + 6);
    setExperieMounth3(today3);
  }, []);

  return (
    <>
      <div className="skip-to-post">
        <div className="skip-to-post-top">
          <div className="pb-2">
            Uh-oh! Narsingi has tough competition ! Here's a summary
          </div>
          <div className="skip-to-post-advertise-details">
            <div>
              <img
                src="https://www.99acres.com/universalapp/img/AboutMyProperty2.png"
                alt=""
              />
            </div>
            <div>
              <div>No. of Listings</div>
              <div style={{ fontSize: "14px", fontWeight: "600" }}>332</div>
            </div>
            <div>
              <div>No. of Advertisers</div>
              <div style={{ fontSize: "14px", fontWeight: "600" }}>179</div>
            </div>
            <div>
              <div>Boost Used By</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    marginRight: "4px",
                  }}
                >
                  251/179
                </div>{" "}
                advertisers
              </div>
            </div>
            <div>
              <div>Credit used by top 10%</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    marginRight: "4px",
                  }}
                >
                  689
                </div>{" "}
                credits
              </div>
            </div>
          </div>
        </div>
        <div className="skip-to-post-top-midpart">
          <div style={{ fontWeight: "600" }}>Boost your listing</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="listing-box"
              onClick={() => {
                setnumberOfBosting(1);
                setexperiMounthChange(1);
              }}
            >
              <input
                type="radio"
                name="boosting"
                checked={numberOfBosting === 1}
              />
              <div>
                <div>1 Listing</div>
                {/* <div>159 credits</div> */}
              </div>
            </div>
            <div
              className="listing-box"
              onClick={() => {
                setnumberOfBosting(3);
                setexperiMounthChange(3);
              }}
            >
              <input
                type="radio"
                name="boosting"
                id=""
                checked={numberOfBosting === 3}
              />
              <div>
                <div>3 Listing</div>
                {/* <div>159 credits</div> */}
              </div>
            </div>
            <div
              className="listing-box"
              onClick={() => {
                setnumberOfBosting(4);
                setexperiMounthChange(4);
              }}
            >
              <input
                type="radio"
                name="boosting"
                id=""
                checked={numberOfBosting === 4}
              />
              <div>
                <div>3 Listing</div>
                {/* <div>159 credits</div> */}
              </div>
            </div>
            <div
              className="listing-box"
              onClick={() => {
                setnumberOfBosting(5);
                setexperiMounthChange(5);
              }}
            >
              <input
                type="radio"
                name="boosting"
                id=""
                checked={numberOfBosting === 5}
              />
              <div>
                <div>5 Listing</div>
                {/* <div>159 credits</div> */}
              </div>
            </div>
            <div className="listing-last-box">
              <div>Or, hoose your credits</div>
              <div className="listing-last-box-inner">
                Enter credit value
                <input type="number" name="" id="" value="0" />
              </div>
            </div>
          </div>
        </div>
        <div className="skip-to-post-bottom-midpart row">
          <div className="skip-to-post-bottom-midpart-leftpanel col-lg-5">
            <div></div>
            <div className="title">Auto-Extend</div>
            <div>Save time and effort</div>
            <p>
              Activate now and pay in the day of extension you are always in
              control, disable anytime
            </p>
          </div>
          <div className="skip-to-post-bottom-midpart-rightpanel col-lg-7">
            <div className="font-small-bold pt-2 pb-2">
              Choose <IoSyncOutline />
              autoextend duration
            </div>
            <div className="listing-plans-head">
              <div
                className="listing-plan"
                onClick={() => {
                  setExperieMounth(2);
                  setNoOfBoostingChange(2);
                }}
                style={{ cursor: "pointer" }}
              >
                <input
                  type="radio"
                  name="expere"
                  id=""
                  checked={ExperieMounth === 2}
                />
                <div>
                  <div className="font-small-bold">Add 2 months</div>
                  <div>
                    Listing live till{" "}
                    {moment(ExperieMounth1).format("Do MMM YY")}
                  </div>
                </div>
                <div className="font-small-bold pt-2">1 Listing Credits</div>
              </div>
              <div
                className="listing-plan"
                onClick={() => {
                  setExperieMounth(4);
                  setNoOfBoostingChange(4);
                }}
                style={{ cursor: "pointer" }}
              >
                <input
                  type="radio"
                  name="expere"
                  id=""
                  checked={ExperieMounth === 4}
                />
                <div>
                  <div className="font-small-bold">Add 4 months</div>
                  <div>
                    Listing live till{" "}
                    {moment(ExperieMounth2).format("Do MMM YY")}
                  </div>
                </div>
                <div className="font-small-bold pt-2">2 Listing Credits</div>
              </div>
              <div
                className="listing-plan"
                onClick={() => {
                  setExperieMounth(6);
                  setNoOfBoostingChange(2);
                }}
                style={{ cursor: "pointer" }}
              >
                <input
                  type="radio"
                  name="expere"
                  id=""
                  checked={ExperieMounth === 6}
                />
                <div>
                  <div className="font-small-bold">Add 6 months</div>
                  <div>
                    Listing live till{" "}
                    {moment(ExperieMounth3).format("Do MMM YY")}
                  </div>
                </div>
                <div className="font-small-bold pt-2">3 Listing Credits</div>
              </div>
            </div>
          </div>
        </div>
        <div className="skip-to-post-bottom">
          <p>
            By clicking on submit, i confirm tha the prperty details including
            price, photos are correct. And thi spropertyis avilable for sell /
            rent. In case of any <br /> discreperncies 99acres can take strict
            action including deleting the listing. I also terms And conditions.
          </p>
          <div>
            <button
              style={{
                border: "1px solid #0077df",
                color: "#0077df",
                backgroundColor: "#fff",
                cursor: "pointer",
                margin: "0 1vh",
              }}
              onClick={(e) => propertyPostClick(e,0,2)}
            >
              Skip & post
            </button>
            {ExperieMounth > 2 || numberOfBosting > 0 ? (
              <>
                <button
                  style={{
                    backgroundColor: "#0077df",
                    border: "1px solid #0077df",
                    color: "#fff",
                    cursor: "pointer",
                    margin: "0 1vh",
                  }}
                  onClick={(e) =>
                    propertyPostClick(e, numberOfBosting, ExperieMounth)
                  }
                >
                  Apply & Post
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
