import React from "react";
import { useSelector } from "react-redux";
import BuySearchComponent from "./searchComponent/BuySearchComponent";
import RentSearchComponent from "./searchComponent/RentSearchComponent";
import PGCoLivingSearchComponent from "./searchComponent/PGCoLivingSearchComponent";
import MainComersialPage from "./searchComponent/MainComersialPage";
import CoworkingSearchComponent from "./searchComponent/CoworkingSearchComponent";
import PlotLandSearchComponent from "./searchComponent/PlotLandSearchComponent";
import ProjectsSearchComponent from "./searchComponent/ProjectsSearchComponent";

const MainSearchcomponents = (props) => {
  const { searchComponentStatus } = useSelector((state) => state.Search);
  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return (
          <BuySearchComponent
            getDetails={props.getDetails}
            getSubcatDetails={props.getSubcatDetails}
            getBudgetDetails={props.getBudgetDetails}
            getBedRoomDetails={props.getBedRoomDetails}
            getConustructionDetails={props.getConustructionDetails}
            getPostedDetailsDetails={props.getPostedDetailsDetails}
          />
        );
      case 1:
        return (
          <RentSearchComponent
            getSubcatDetails={props.getSubcatDetails}
            getBudgetDetails={props.getBudgetDetails}
            getBedRoomDetails={props.getBedRoomDetails}
            getPostedDetailsDetails={props.getPostedDetailsDetails}
            getFurnishedDetails={props.getFurnishedDetails}
          />
        );
      case 2:
        return (
          <PGCoLivingSearchComponent
            getSubcatDetails={props.getSubcatDetails}
            getBudgetDetails={props.getBudgetDetails}
            getBedRoomDetails={props.getBedRoomDetails}
            getPostedDetailsDetails={props.getPostedDetailsDetails}
            getSharingDetails={props.getSharingDetails}
            getavailabilityDetails={props.getavailabilityDetails}
          />
        );
      case 3:
        return <MainComersialPage />;
      case 4:
        return (
          <CoworkingSearchComponent
            getBudgetDetails={props.getBudgetDetails}
            getNoSeatDetails={props.getNoSeatDetails}
            getSeatTypeDetails={props.getSeatTypeDetails}
            getPlansDetails={props.getPlansDetails}
          />
        );
      case 5:
        return (
          <PlotLandSearchComponent
            getBudgetDetails={props.getBudgetDetails}
            getAreaDetails={props.getAreaDetails}
            getLandDetails={props.getLandDetails}
            getPostedDetailsDetails={props.getPostedDetailsDetails}
          />
        );
      case 6:
        return (
          <ProjectsSearchComponent
            getBudgetDetails={props.getBudgetDetails}
            getBedRoomDetails={props.getBedRoomDetails}
            getProjectStatusDetails={props.getProjectStatusDetails}
          />
        );
      default:
        return (
          <BuySearchComponent
            getDetails={props.getDetails}
            getSubcatDetails={props.getSubcatDetails}
            getBudgetDetails={props.getBudgetDetails}
            getBedRoomDetails={props.getBedRoomDetails}
            getConustructionDetails={props.getConustructionDetails}
            getPostedDetailsDetails={props.getPostedDetailsDetails}
          />
        );
        break;
    }
  };
  return <>{renderSwitch(searchComponentStatus)}</>;
};

export default MainSearchcomponents;
