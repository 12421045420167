import React, { useEffect, useState } from "react";
import { IoMdCall } from "react-icons/io";
import {
  BiArrowBack,
  BiPlus,
  BiMinus,
  BiInfoCircle,
  BiChevronDown,
} from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import { render } from "react-dom";
import { GrCircleQuestion } from "react-icons/gr";

// Import react-circular-progressbar module and styles
import { CircularProgressbar } from "react-circular-progressbar";
import {
  setLocalityScooring,
  setPostPropertyStepsStatus,
  setPropertyProfile,
  setaminitiesScooring,
  setbasicdetailScooring,
  setmainpropertyScooring,
  setphotoScooring,
  setpricingScooring,
  setpropertyScooring,
} from "../../../../redux/property/PropertySlice";
import AddRoomDetailsTemp from "../commonComponent/AddRoomDetailsTemp";
import AddAreaDetailsTemp from "../commonComponent/AddAreaDetailsTemp";
import OtherRoomsTemp from "../commonComponent/OtherRoomsTemp";
import FurnishingTemp from "../commonComponent/FurnishingTemp";
import ReversedParkingTemp from "../commonComponent/ReversedParkingTemp";
import FloorDetailsTemp from "../commonComponent/FloorDetailsTemp";
import AvaliableFromTemp from "../commonComponent/AvaliableFromTemp";
import AvaliabilityStatusTemp from "../commonComponent/AvaliabilityStatusTemp";
import WillingtoRentOutTemp from "../commonComponent/WillingtoRentOutTemp";
import AreYouOkBrokersTemp from "../commonComponent/AreYouOkBrokersTemp";

const PropertyProfileTemp = () => {
  const {
    singleProperty,
    PropertyProfile,
    BasicDetails,
    basicdetailScooring,
    LocalityScooring,
    photoScooring,
    pricingScooring,
    aminitiesScooring,
  } = useSelector((state) => state.Property);
  const dispatch = useDispatch();

  const [iscontent, setIsContent] = useState(false);

  const [noofBedrooms, setNoofBedrooms] = useState(singleProperty.noofBedrooms);
  const [addmoreBedroomsvalue, setAddmoreBedroomsValue] = useState("");
  const [addmoreBedroomsShow, setAddmoreBedroomsShow] = useState(false);

  const [noofBathrooms, setNoofBathrooms] = useState(singleProperty.noofBathrooms);
  const [addmoreBathroomsvalue, setAddmoreBathroomsValue] = useState("");
  const [addmoreBathroomsShow, setAddmoreBathroomsShow] = useState(false);

  const [balconies, setBalconies] = useState(singleProperty.balconies);

  const [carpetArea, setCarpetArea] = useState(singleProperty.carpetArea);
  const [typeofCarpetArea, setTypeofCarpetArea] = useState(singleProperty.carpetAreaMesurmentType);
  const [typeofCarpetAreaShow, setTypeofCarpetAreaShow] = useState(false);

  const [builtupArea, setBuiltupArea] = useState(singleProperty.buildupArea);
  const [typeofBuiltupArea, setTypeofBuiltupArea] = useState(singleProperty.buildupAreaMesurmentType);
  const [typeofBuiltupAreaShow, setTypeofBuiltupAreaShow] = useState(false);
  const [typeofBuiltupAreaBox, setTypeofBuiltupAreaBox] = useState(false);

  const [superBuiltupArea, setSuperBuiltupArea] = useState(singleProperty.superbuildupArea);
  const [typeofSuperBuiltupArea, setTypeofSuperBuiltupArea] =
    useState(singleProperty.superbuildupAreaMesurmentType);
  const [typeofSuperBuiltupAreaShow, setTypeofSuperBuiltupAreaShow] =
    useState(false);
  const [typeofSuperBuiltupAreaBox, setTypeofSuperBuiltupAreaBox] =
    useState(false);

  const [plotArea, setplotArea] = useState(singleProperty.plotArea);
  const [typeofplotArea, setTypeofplotArea] = useState(singleProperty.plotAreaMesurmentType);
  const [typeofPlotAreaBox, setTypeofPlotAreaBox] = useState(false);

  const [poojaroom, setPoojaroom] = useState(singleProperty.poojaRoom);
  const [studyroom, setStudyroom] = useState(singleProperty.studyRoom);
  const [servantroom, setServantroom] = useState(singleProperty.servantRoom);
  const [storeroom, setStoreroom] = useState(singleProperty.storeRoom);

  const [furnish, setFurnish] = useState(Boolean(0));
  const [semifurnish, setSemifurnish] = useState(Boolean(0));
  const [unfurnish, setUnfurnish] = useState(Boolean(1));
  const [furnishedStatus, setFurnishedStatus] = useState("Un-furnished");

  const [light, setLight] = useState(singleProperty.numberoflight);
  const [fans, setFans] = useState(singleProperty.numberoffans);
  const [ac, setAc] = useState(singleProperty.numberofac);
  const [tv, setTv] = useState(singleProperty.numberoftv);
  const [beds, setBeds] = useState(singleProperty.numberofbeds);
  const [wardrobe, setWardrobe] = useState(singleProperty.numberofwardrobe);
  const [geyser, setGeyser] = useState(singleProperty.numberofgeyser);
  const [sofa, setSofa] = useState(singleProperty.sofa);
  const [washingMachine, setWashingMachine] = useState(singleProperty.washingMachine);
  const [stove, setStove] = useState(singleProperty.stove);
  const [fridge, setFridge] = useState(singleProperty.fridge);
  const [waterPurifier, setWaterPurifier] = useState(singleProperty.waterPurifier);
  const [microwave, setMicrowave] = useState(singleProperty.microwave);
  const [modularKitchen, setModularKitchen] = useState(singleProperty.modularKitchen);
  const [chimney, setChimney] = useState(singleProperty.chimney);
  const [dinningTable, setDinningTable] = useState(singleProperty.dinningTable);
  const [curtains, setCurtains] = useState(singleProperty.curtains);
  const [exhaustFan, setExhaustFan] = useState(singleProperty.exhaustFan);


  const [coveredParking, setCoveredParking] = useState(singleProperty.numberofCoveredParking);
  const [openParking, setOpenParking] = useState(singleProperty.numberofOpenParking);


  const [totalFloors, setTotalFloors] = useState(singleProperty.noofFloor);
  const [propertyonFloors, setPropertyonFloors] = useState(singleProperty.propertyOnFloor);
  const [propertyonFloorsShow, setPropertyonFloorsShow] = useState(false);
  const [typeofPropertyonFloors, setTypeofPropertyonFloors] = useState("");

  const [readyToMove, setReadyToMove] = useState(false);
  const [underConstruction, setUnderConstruction] = useState(false);
  const [abilityStatus, setAbilityStatus] = useState(false);

  const [ageofProperty, setAgeofProperty] = useState(singleProperty.propertyAge);

  const [expectedby, setExpectedby] = useState(singleProperty.propertyPossessionBy);
  const [expectedbyShow, setExpectedbyShow] = useState(false);
  const [yearofExpectedBy, setYearofExpectedBy] = useState("");

  const [availableDate, setavailableDate] = useState(singleProperty.availableDate);

  const [family, setFamily] = useState(singleProperty.family);
  const [man, setMan] = useState(singleProperty.man);
  const [women, setWomen] = useState(singleProperty.women);

  const [areyouOk, setAreyouOk] = useState(singleProperty.areyouOk);

  //errors

  const [roomDetailsError, setRoomDetailsError] = useState("");
  const [areaDetailsError, setAreaDetailsError] = useState("");
  const [floorDetailsError, setFloorDetailsError] = useState("");
  const [abilityError, setAbilityError] = useState("");

  const [abilityStatusError, setAbilityStatusError] = useState("");

  const [BedroomsError, setBedroomsError] = useState("");
  const [BathroomsError, setBathroomsError] = useState("");
  const [BalconiesError, setBalconiesError] = useState("");

  const [availabilityError, setavailabilityError] = useState("");

  const [selectAgeError, setSelectedAgeError] = useState("");
  const [timePossessionError, settimePossessionError] = useState("");

  const [TotalFloorError, setTotalFloorError] = useState("");
  const [propertyonFloorError, setPropertyonFloorError] = useState("");
  const [floorDetails, setFloorDetails] = useState([]);

  const [availabilityDateError, setavailabilityDateError] = useState("");

  const getBedRoom = (value) => {
    setNoofBedrooms(value);
  };
  const getBathroom = (value) => {
    setNoofBathrooms(value);
  };
  const getBalcony = (value) => {
    setBalconies(value);
  };
  const carpetAreaChange = (e) => {
    const result = e;
    setCarpetArea(result);
  };

  const builtupChange = (e) => {
    const result = e;
    setBuiltupArea(result);
  };

  const superbuiltupChange = (e) => {
    const result = e;
    setSuperBuiltupArea(result);
  };

  const carpetAreaMesurment = (e) => {
    const result = e;
    setTypeofCarpetArea(result);
  };

  const builtupMesurment = (e) => {
    const result = e;
    setTypeofBuiltupArea(result);
  };
  const superbuiltupMesurment = (e) => {
    const result = e;
    setTypeofSuperBuiltupArea(result);
  };

  const getpoojaroom = (value) => {
    setPoojaroom(value);
  };
  const getstudyroom = (value) => {
    setStudyroom(value);
  };
  const getservantroom = (value) => {
    setServantroom(value);
  };
  const getstoreroom = (value) => {
    setStoreroom(value);
  };

  const getFurnishingDetails = (value) => {
    setFurnishedStatus(value);
  };

  const getFurnishingType = (value) => {
    setFurnishedStatus(value);
    setWardrobe(value.wardrobe);
    setAc(value.ac);
    setChimney(value.chimney);
    setGeyser(value.geyser);
    setMicrowave(value.microwave);
    setLight(value.light);
    setFans(value.fan);
    setTv(value.tv);
    setBeds(value.beds);
    setCurtains(value.curtain);

    setExhaustFan(value.exhaustfan);
    // setStudyTable(value.studyTable);
    setModularKitchen(value.modularKitchen);
    setWashingMachine(value.washingMachine);
    setStove(value.stove);
    setFridge(value.fridge);
    setDinningTable(value.dinningtable);
    setWaterPurifier(value.waterpurifier);
    setSofa(value.sofa);
  };

  const getCoverParking = (value) => {
    setCoveredParking(value);
  };
  const getOpenParking = (value) => {
    setOpenParking(value);
  };

  const getNumberOfFloors = (value) => {
    setTotalFloors(value);
  };
  const getPropertyOnFloors = (value) => {
    setPropertyonFloors(value);
  };

  const getPropertyAvailability = (value) => {
    setAbilityStatus(value);
  };

  const getPropertyAge = (value) => {
    setAgeofProperty(value);
  };
  const getPropertyExpectedYear = (value) => {
    setYearofExpectedBy(value);
  };
  const getPropertyExpectedMonth = (value) => {
    setExpectedby(value);
  };
  const constructionOnProperty = (e) => {
    const result = e;
    setUnderConstruction(result);
  };
  const availableFromDate = (e) => {
    const result = e;
    setavailableDate(result);
  };
  const willingToRentMen = (e) => {
    const result = e;
    setMan(result);
  };
  const willingToRentWomen = (e) => {
    const result = e;
    setWomen(result);
  };
  const willingToRentFamily = (e) => {
    const result = e;
    setFamily(result);
  };
  const areYouOk = (e) => {
    const result = e;
    setAreyouOk(result);
  };
  useEffect(() => {
    if (PropertyProfile !== "") {
      setNoofBedrooms(PropertyProfile.noofBedrooms);
      setAddmoreBedroomsValue(PropertyProfile.addmoreBedroomsvalue);

      setNoofBathrooms(PropertyProfile.noofBathrooms);
      setAddmoreBathroomsValue(PropertyProfile.addmoreBathroomsvalue);

      setBalconies(PropertyProfile.balconies);

      setCarpetArea(PropertyProfile.carpetArea);
      setTypeofCarpetArea(PropertyProfile.typeofCarpetArea);

      setBuiltupArea(PropertyProfile.builtupArea);
      setTypeofBuiltupArea(PropertyProfile.typeofBuiltupArea);
      setTypeofBuiltupAreaBox(PropertyProfile.typeofBuiltupAreaBox);

      setSuperBuiltupArea(PropertyProfile.superBuiltupArea);
      setTypeofSuperBuiltupArea(PropertyProfile.typeofSuperBuiltupArea);
      setTypeofSuperBuiltupAreaBox(PropertyProfile.typeofSuperBuiltupAreaBox);

      setPoojaroom(PropertyProfile.poojaroom);
      setStudyroom(PropertyProfile.studyroom);
      setServantroom(PropertyProfile.servantroom);
      setStoreroom(PropertyProfile.storeroom);

      setFurnish(PropertyProfile.furnish);
      setSemifurnish(PropertyProfile.semifurnish);
      setUnfurnish(PropertyProfile.unfurnish);
      setFurnishedStatus(PropertyProfile.furnishedStatus);

      setLight(PropertyProfile.light);
      setFans(PropertyProfile.fans);
      setAc(PropertyProfile.ac);
      setTv(PropertyProfile.tv);
      setBeds(PropertyProfile.beds);
      setWardrobe(PropertyProfile.wardrobe);
      setGeyser(PropertyProfile.geyser);

      setSofa(PropertyProfile.sofa);
      setWashingMachine(PropertyProfile.washingMachine);
      setStove(PropertyProfile.stove);
      setFridge(PropertyProfile.fridge);
      setWaterPurifier(PropertyProfile.waterPurifier);
      setMicrowave(PropertyProfile.microwave);
      setModularKitchen(PropertyProfile.modularKitchen);
      setChimney(PropertyProfile.chimney);
      setDinningTable(PropertyProfile.dinningTable);
      setCurtains(PropertyProfile.curtains);
      setExhaustFan(PropertyProfile.exhaustFan);

      setCoveredParking(PropertyProfile.coveredParking);
      setOpenParking(PropertyProfile.openParking);

      setTotalFloors(PropertyProfile.totalFloors);
      setPropertyonFloors(PropertyProfile.propertyonFloors);
      setTypeofPropertyonFloors(PropertyProfile.typeofPropertyonFloors);

      setReadyToMove(PropertyProfile.readyToMove);
      setUnderConstruction(PropertyProfile.underConstruction);
      setAbilityStatus(PropertyProfile.abilityStatus);

      setAgeofProperty(PropertyProfile.ageofProperty);

      setExpectedby(PropertyProfile.expectedby);
      setYearofExpectedBy(PropertyProfile.yearofExpectedBy);
    }
  }, [PropertyProfile]);

  const areaMesurments = [
    { name: "sq.ft." },
    { name: "sq.yards" },
    { name: "sq.m." },
    { name: "acres" },
    { name: "marla" },
    { name: "cents" },
    { name: "bigha" },
    { name: "kottah" },
    { name: "kanal" },
    { name: "grounds" },
    { name: "ares" },
    { name: "biswa" },
    { name: "guntha" },
    { name: "aankadam" },
    { name: "hectares" },
    { name: "rood" },
    { name: "chataks" },
    { name: "perch" },
  ];

  const underConstructions = [
    { id: "1", name: "Within 3 Months" },
    { id: "2", name: "Within 6 Months" },
    { id: "3", name: "By 2024" },
    { id: "4", name: "By 2025" },
    { id: "5", name: "By 2026" },
    { id: "6", name: "By 2027" },
  ];

  useEffect(() => {
    if (noofBedrooms !== "" || noofBathrooms !== "" || balconies !== "") {
      setRoomDetailsError("");
    }
    if (noofBedrooms !== "") {
      setBedroomsError("");
    }
    if (noofBathrooms !== "") {
      setBathroomsError("");
    }
    if (balconies !== "") {
      setBalconiesError("");
    }
    if (carpetArea !== "" && typeofCarpetArea !== "") {
      setAreaDetailsError("");
    }
    if (totalFloors !== "" && propertyonFloors !== "") {
      setFloorDetailsError("");
    }

    if (abilityStatus !== "") {
      setAbilityStatusError("");
    }

    if (readyToMove !== true || underConstruction !== true) {
      setAbilityError("");
    }
    if (ageofProperty !== "" || expectedby !== "") {
      setAbilityStatusError("");
      setSelectedAgeError("");
      settimePossessionError("");
    }
  }, [
    noofBedrooms,
    noofBathrooms,
    balconies,
    carpetArea,
    typeofCarpetArea,
    totalFloors,
    propertyonFloors,
    abilityStatus,
    readyToMove,
    underConstruction,
    ageofProperty,
    expectedby,
  ]);
  useEffect(() => {
    let count = 0;
    let countTotal = 0;
    let countnoofBedrooms = 0;

    if (noofBedrooms !== "") {
      countnoofBedrooms = 2;
    } else {
      countnoofBedrooms = 0;
    }

    let countnoofBathrooms = 0;
    if (noofBathrooms !== "") {
      countnoofBathrooms = 2;
    } else {
      countnoofBathrooms = 0;
    }
    let countbalconies = 0;
    if (balconies !== "") {
      countbalconies = 2;
    } else {
      countbalconies = 0;
    }

    let countcarpetArea = 0;
    if (carpetArea !== "" && typeofCarpetArea !== "") {
      countcarpetArea = 5;
    } else {
      countcarpetArea = 0;
    }

    let countotherrooms = 0;
    if (
      poojaroom === true ||
      studyroom === true ||
      servantroom === true ||
      storeroom === true
    ) {
      countotherrooms = 5;
    } else {
      countotherrooms = 0;
    }
    let countFurnish = 0;
    if (furnish === true || semifurnish === true || unfurnish === true) {
      countFurnish = 5;
    } else {
      countFurnish = 0;
    }

    let counttotalFloors = 0;
    if (totalFloors !== "") {
      counttotalFloors = 3;
    } else {
      counttotalFloors = 0;
    }
    let counttotalFloorson = 0;
    if (propertyonFloors !== "") {
      counttotalFloorson = 2;
    } else {
      counttotalFloorson = 0;
    }

    let countabilityStatus = 0;
    if (abilityStatus !== "") {
      countabilityStatus = 9;
    } else {
      countabilityStatus = 0;
    }

    // let countreadyToMove = 0;
    // if (readyToMove !== true || underConstruction !== true) {
    //   countreadyToMove = 5;
    // } else {
    //   countreadyToMove = 0;
    // }

    // let countageofProperty = 0;
    // if (ageofProperty !== "" || expectedby !== "") {
    //   countageofProperty = 5;
    // } else {
    //   countageofProperty = 0;
    // }
    countTotal =
      countnoofBedrooms +
      countnoofBathrooms +
      countbalconies +
      countcarpetArea +
      countotherrooms +
      countFurnish +
      counttotalFloors +
      counttotalFloorson +
      countabilityStatus;

    dispatch(setbasicdetailScooring(basicdetailScooring));
    dispatch(setLocalityScooring(LocalityScooring));
    dispatch(setmainpropertyScooring(countTotal));
    dispatch(setphotoScooring(photoScooring));
    dispatch(setpricingScooring(pricingScooring));
    dispatch(setaminitiesScooring(aminitiesScooring));
    dispatch(setpropertyScooring());
  }, [
    noofBedrooms,
    noofBathrooms,
    balconies,
    carpetArea,
    typeofCarpetArea,
    totalFloors,
    propertyonFloors,
    abilityStatus,
    readyToMove,
    underConstruction,
    ageofProperty,
    expectedby,
    poojaroom,
    studyroom,
    servantroom,
    storeroom,
    furnish,
    semifurnish,
    unfurnish,
  ]);

  const totalfloorClick = (e) => {
    if (e.target.value <= 90) {
      setTotalFloors(e.target.value.replace(/^[0]+$/, 0));
      if (e.target.value > 0) {
        let floorDetails = [
          { id: 1, name: "Basement" },
          { id: 2, name: "Lower Ground" },
          { id: 3, name: "Ground" },
        ];
        let formdata = {};
        for (let index = 1; index <= e.target.value; index++) {
          formdata = {
            id: 3 + index,
            name: index,
          };
          floorDetails = [...floorDetails, formdata];
        }
        setFloorDetails(floorDetails);
      } else {
        let floorDetails = [
          { id: 1, name: "Basement" },
          { id: 2, name: "Lower Ground" },
          { id: 3, name: "Ground" },
        ];
        setFloorDetails(floorDetails);
      }
    }
  };
  useEffect(() => {
    if (totalFloors === 0 && totalFloors !== "") {
      let floorDetails = [
        { id: 1, name: "Basement" },
        { id: 2, name: "Lower Ground" },
        { id: 3, name: "Ground" },
      ];
      setFloorDetails(floorDetails);
    }
  }, [totalFloors]);

  const propertyProfileClick = async (e) => {
    e.preventDefault();

    setRoomDetailsError("");
    setBedroomsError("");
    setBathroomsError("");
    setBalconiesError("");
    setAreaDetailsError("");
    setFloorDetailsError("");
    setAbilityError("");
    setAbilityStatusError("");

    if (noofBedrooms !== "" || noofBathrooms !== "" || balconies !== "") {
      setRoomDetailsError("");
    } else {
      setRoomDetailsError("All fields are required");
    }
    if (noofBedrooms !== "") {
      setBedroomsError("");
    } else {
      setBedroomsError("Please specify bedrooms count");
    }
    if (noofBathrooms !== "") {
      setBathroomsError("");
    } else {
      setBathroomsError("Please specify bathrooms count");
    }
    if (balconies !== "") {
      setBalconiesError("");
    } else {
      setBalconiesError("Please specify balconies count");
    }
    if (carpetArea !== "" && typeofCarpetArea !== "") {
      setAreaDetailsError("");
    } else {
      setAreaDetailsError("Atleast one area type mandatory");
    }

    if (
      BasicDetails.subcatName === "Independent House" ||
      BasicDetails.subcatName === "Villa" ||
      BasicDetails.subcatName === "Farmhouse"
    ) {
    } else {
      if (totalFloors !== "" && propertyonFloors !== "") {
        setFloorDetailsError("");
      } else {
        setFloorDetailsError("All fields are required");
      }
    }

    if (abilityStatus !== "") {
      setAbilityStatusError("");
    } else {
      setAbilityStatusError("All fields are required");
    }

    if (readyToMove !== true || underConstruction !== true) {
      setAbilityError("");
    } else {
      setAbilityError("All fields are required");
    }
    if (ageofProperty !== "" || expectedby !== "") {
      setAbilityStatusError("");
      setSelectedAgeError("");
      settimePossessionError("");
    } else {
      setAbilityStatusError("All fields are required");
      setSelectedAgeError("Select Property Age");
      settimePossessionError("Select Expected Date");
    }

    if (
      noofBedrooms !== "" &&
      noofBathrooms !== "" &&
      balconies !== "" &&
      carpetArea !== "" &&
      typeofCarpetArea !== "" &&
      totalFloors !== ""
      //&& propertyonFloors !== ""
    ) {
      if (readyToMove !== true || underConstruction !== true) {
        if (ageofProperty !== "" || expectedby !== "") {
          const propertyProfile = {
            noofBedrooms: noofBedrooms,
            addmoreBedroomsvalue: addmoreBedroomsvalue,
            addmoreBedroomsShow: addmoreBedroomsShow,

            noofBathrooms: noofBathrooms,
            addmoreBathroomsvalue: addmoreBathroomsvalue,
            addmoreBathroomsShow: addmoreBathroomsShow,
            balconies: balconies,

            carpetArea: carpetArea,
            typeofCarpetArea: typeofCarpetArea,
            typeofCarpetAreaShow: typeofCarpetAreaShow,

            builtupArea: builtupArea,
            typeofBuiltupArea: typeofBuiltupArea,
            typeofBuiltupAreaShow: typeofBuiltupAreaShow,
            typeofBuiltupAreaBox: typeofBuiltupAreaBox,

            superBuiltupArea: superBuiltupArea,
            typeofSuperBuiltupArea: typeofSuperBuiltupArea,
            typeofSuperBuiltupAreaShow: typeofSuperBuiltupAreaShow,
            typeofSuperBuiltupAreaBox: typeofSuperBuiltupAreaBox,

            plotArea: plotArea,
            typeofplotArea: typeofplotArea,
            typeofPlotAreaBox: typeofPlotAreaBox,

            poojaroom: poojaroom,
            studyroom: studyroom,
            servantroom: servantroom,
            storeroom: storeroom,

            furnish: furnish,
            semifurnish: semifurnish,
            unfurnish: unfurnish,

            light: light,
            fans: fans,
            ac: ac,
            tv: tv,
            beds: beds,
            wardrobe: wardrobe,
            geyser: geyser,
            sofa: sofa,
            washingMachine: washingMachine,
            stove: stove,
            fridge: fridge,
            waterPurifier: waterPurifier,
            microwave: microwave,
            modularKitchen: modularKitchen,
            chimney: chimney,
            dinningTable: dinningTable,
            curtains: curtains,
            exhaustFan: exhaustFan,

            coveredParking: coveredParking,
            openParking: openParking,
            totalFloors: totalFloors,
            propertyonFloors: propertyonFloors,
            propertyonFloorsShow: propertyonFloorsShow,

            readyToMove: readyToMove,
            underConstruction: underConstruction,
            ageofProperty: ageofProperty,
            expectedby: expectedby,
            expectedbyShow: expectedbyShow,

            availableDate: availableDate,

            family: family,
            man: man,
            women: women,

            areyouOk: areyouOk,
          };
          dispatch(setPropertyProfile(propertyProfile));
          dispatch(setPostPropertyStepsStatus(3));
        }
      }
    }
  };

  return (
    <>
      {/* <HeaderNew /> */}
      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA">
          <div className="page_ppf_step1__1icHe  pageComponent">
            <div className="PrimaryDetailsHeader-desktopHeader">
              <div className="desktopHeader_iconInlineHead__W8_RL">
                <div className="caption_subdued_medium">
                  <span
                    className="desktopHeader_backArrow__1lVxQ iconS_PPFDesk_24 icon_backGrey"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  >
                    <BiArrowBack />
                  </span>
                  <div style={{ fontSize: "14px" }}>Back</div>
                </div>
                <div className="desktopHeader_text__3iyVt">
                  <div className="undefined title_l_semiBold">
                    Tell us about your property
                  </div>
                </div>
              </div>
              <div className="FloorPlans-widget"></div>
              <div className="Configuration-widget">
                <AddRoomDetailsTemp
                  getBedRoom={getBedRoom}
                  getBathroom={getBathroom}
                  getBalcony={getBalcony}
                  roomDetailsError={roomDetailsError}
                  noOfBedroomsError={BedroomsError}
                  noOfBathroomsError={BathroomsError}
                  noOfBalconiesError={BalconiesError}
                />

                <AddAreaDetailsTemp
                  carpetAreaChange={carpetAreaChange}
                  builtupChange={builtupChange}
                  superbuiltupChange={superbuiltupChange}
                  carpetAreaMesurment={carpetAreaMesurment}
                  builtupMesurment={builtupMesurment}
                  superbuiltupMesurment={superbuiltupMesurment}
                  areaDetailsError={areaDetailsError}
                />

                <OtherRoomsTemp
                  getpoojaroom={getpoojaroom}
                  getstudyroom={getstudyroom}
                  getservantroom={getservantroom}
                  getstoreroom={getstoreroom}
                />

                <FurnishingTemp
                  getFurnishingDetails={getFurnishingDetails}
                  getFurnishingType={getFurnishingType}
                  setLight={setLight}
                  setFans={setFans}
                  setAc={setAc}
                  setTv={setTv}
                  setBeds={setBeds}
                  setWardrobe={setWardrobe}
                  setGeyser={setGeyser}
                  setSofa={setSofa}
                  setWashingMachine={setWashingMachine}
                  setStove={setStove}
                  setFridge={setFridge}
                  setWaterPurifier={setWaterPurifier}
                  setMicrowave={setMicrowave}
                  setModularKitchen={setModularKitchen}
                  setChimney={setChimney}
                  setDinningTable={setDinningTable}
                  setCurtains={setCurtains}
                  setExhaustFan={setExhaustFan}
                />

                <ReversedParkingTemp
                  getCoverParking={getCoverParking}
                  getOpenParking={getOpenParking}
                />

                <FloorDetailsTemp
                  getNumberOfFloors={getNumberOfFloors}
                  getPropertyOnFloors={getPropertyOnFloors}
                  floorDetailsError={floorDetailsError}
                />

                <AvaliabilityStatusTemp
                  getPropertyAvailability={getPropertyAvailability}
                  getPropertyAge={getPropertyAge}
                  abilityStatusError={abilityStatusError}
                  abilityError={abilityError}
                  availabilityError={availabilityError}
                  selectAgeError={selectAgeError}
                  timePossessionError={timePossessionError}
                  getPropertyExpectedYear={getPropertyExpectedYear}
                  getPropertyExpectedMonth={getPropertyExpectedMonth}
                  constructionOnProperty={constructionOnProperty}
                />

                {BasicDetails.catName === "Rent" ||
                BasicDetails.catName === "Lease" ? (
                  <>
                    <AvaliableFromTemp availableFromDate={availableFromDate} />

                    <WillingtoRentOutTemp
                      willingToRentMen={willingToRentMen}
                      willingToRentFamily={willingToRentFamily}
                      willingToRentWomen={willingToRentWomen}
                    />

                    <AreYouOkBrokersTemp areYouOk={areYouOk} />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div id="Location-widget">
              <div id="PrimaryDetailsButtonsWrapper-widget">
                <div className="dummyFormWidget_flexContainer__2vVMv">
                  <div
                    id="PrimaryDetailsButton-button"
                    className="pageComponent"
                  >
                    <div>
                      <div className="buttonInput_continue_btn__1gb5M ">
                        <button
                          type="button"
                          className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                          onClick={(e) => propertyProfileClick(e)}
                        >
                          <span>
                            <span>Continue</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyProfileTemp;
