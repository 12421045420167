import React, { useEffect, useState } from "react";
import Subrecommenddation from "./Subrecommenddation";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/pagination";
import "swiper/css/navigation";
//  import "./styles.css";

// import required modules
import { Autoplay, Keyboard, Scrollbar } from "swiper/modules";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { Link } from "react-router-dom";

const Recommendation = (props) => {
  const { rentProperty } = useSelector((store) => store.Property);

  const [property, setProperty] = useState({});
  const [postMobile, setPostMobile] = useState("");
  const [propertyImagesWithName, setPropertyImagesWithName] = useState([]);

  useEffect(() => {
    const singleProperty = props.singlProperty;
    setProperty(singleProperty);
    setPostMobile(singleProperty.postUserMobile);
    setPropertyImagesWithName(singleProperty.titleImg);
  }, [props.singlProperty]);

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div className="container">
        <h1 style={{ fontSize: "25px", fontWeight: "bold", marginTop: "30px" }}>
          Similar Properties
        </h1>
        <Swiper
          keyboard={{
            enabled: true,
          }}
          draggable={true}
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: false,
          // }}
          pagination={{
            clickable: true,
          }}
          modules={[Keyboard, Scrollbar, Autoplay]}
          breakpoints={{
            360: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            470: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            580: {
              slidesPerView: 3,
              spaceBetween: 4,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 4,
            },
            1080: {
              slidesPerView: 5,
              spaceBetween: 4,
            },
          }}
          className="mySwiper"
        >
          {rentProperty.map((rent, index) => (
            <SwiperSlide key={index}>
              <Link to={`/property-info/${rent.apartment}/${rent.propertyID}`}>
                <div className="">
                  <div className="card cards">
                    <div className="img-body">
                      <img
                        src={rent.DeskImg[0]}
                        class="card-img-top imgs"
                        alt="..."
                        style={{ height: "140px" }}
                      />
                      <div className="img-text">
                        {rent.postUserName} ({rent.postUserPosition}){" "}
                        {moment(rent.createdAt).format("Do MMM YY")}
                      </div>
                    </div>
                    <div className="card-body custom-body">
                      <h5 className="card-title"> ₹ 8.02 - 9.37 Cr, 4 BHK</h5>
                      <p
                        classN="card-text"
                        style={{
                          textTransform: "capitalize",
                          fontSize: "16px",
                          height: "44px",
                        }}
                      >
                        <b>{rent.apartment}</b>
                      </p>
                      <p
                        style={{
                          textTransform: "capitalize",
                          fontSize: "14px",
                        }}
                      >
                        {rent.locality + " ," + rent.city}{" "}
                      </p>
                    </div>
                    {/* <div className="card-body custom-btn">
                    <h2>
                      <a href="">Enquire Now</a>
                    </h2>
                  </div> */}
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* <Subrecommenddation /> */}
    </div>
  );
};

export default Recommendation;
