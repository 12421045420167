import React from "react";
import HomeBanner from "./home/HomeBanner";
import LatestProperties from "./home/LatestProperties";
import ChooseProperties from "./home/ChooseProperties";
import BestCities from "./home/BestCities";
import OurAgents from "./home/OurAgents";
import Achivement from "./home/Achivement";
import OurClientSays from "./home/OurClientSays";
import Newslatter from "./home/Newslatter";
import BHKchoiceInMind from "./home/BHKchoiceInMind";
import InsightsandTools from "./home/InsightsandTools";
import PopularBuilders from "./home/PopularBuilders";
import RecommendedProperties from "./home/RecommendedProperties";
import ApartmentsVillas from "./home/ApartmentsVillas";
import MoveInNow from "./home/MoveInNow";
import CheckPropertyRates from "./home/CheckPropertyRates";
import ExploreOurServices from "./home/ExploreOurServices";
import ExploreRealEstate from "./home/ExploreRealEstate";
import CuratedPgCollections from "./home/CuratedPgCollections";
import HomesByFurnishing from "./home/HomesByFurnishing";
import RecentelySearched from "./PropertyInfo/RecentelySearched";
import PostProperty from "./PropertyInfo/PostProperty";
import { Link } from "react-router-dom";
import SubcriptionPage from "./SubcriptionPage";
import BoostedProperty from "./home/BoostedProperty";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
} from "react-share";

const Home = () => {
  const currentUrl = window.location.href;

  const gpsUrl =
    "https://play.google.com/store/apps/details?id=com.mygoldenhomes";

  const handleClick = async () => {
    try {
      const response = await fetch(gpsUrl, { method: "HEAD" });
      if (response.ok) {
        window.open(gpsUrl, "_blank");
      } else {
        triggerWhatsappShare();
      }
    } catch (error) {
      triggerWhatsappShare();
    }
  };

  const triggerWhatsappShare = () => {
    const shareButton = document.getElementById("whatsapp-share-button");
    if (shareButton) {
      shareButton.click();
    }
  };

  return (
    <>
      <HomeBanner />
      <LatestProperties />
      <ChooseProperties />
      {/* <RecentelySearched/> */}
      <BoostedProperty />
      {/* <BestCities /> */}
      {/* <OurAgents /> */}
      <RecommendedProperties />
      {/* <InsightsandTools /> */}
      {/* <PopularBuilders /> */}
      <OurClientSays />
      <Achivement />
      <ApartmentsVillas />
      <MoveInNow />
      <BHKchoiceInMind />
      {/* <CheckPropertyRates/> */}
      <ExploreOurServices />
      <ExploreRealEstate />

      {/* <CuratedPgCollections/> */}
      {/* <HomesByFurnishing/> */}
      {/* <Newslatter /> */}
      <div className="homewhatsapp desktopwhatsapp">
        <div className="homewhatsapp1">
          <WhatsappShareButton url={currentUrl} title="My Golden Homes">
            <img src="/assets/images/next.png" className="imgshare" />
          </WhatsappShareButton>
        </div>
      </div>
      <div className="homewhatsapp mobilewhatsapp">
        <div className="homewhatsapp1" onClick={handleClick}>
          <WhatsappShareButton
            id="whatsapp-share-button"
            url={gpsUrl}
            title="My Golden Homes"
          >
            <img src="/assets/images/next.png" className="imgshare" />
          </WhatsappShareButton>
        </div>
      </div>
    </>
  );
};

export default Home;
