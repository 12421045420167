import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { BiSolidStar } from 'react-icons/bi'
import { BsStar } from 'react-icons/bs'
import { TiTick } from 'react-icons/ti'
import { Link } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode } from 'swiper/modules';

const ProjectByAliens = () => {
    return (
        <>
            <div className="projects-by-aliens">
                <div className='projects-by-aliens-head'>
                    <h1>Projects by Aliens Developers Pvt Ltd</h1>
                </div>
                <div className='projects-by-aliens-pills'>
                    <div className='active-pill'><h3>Upcoming</h3></div>
                    <div><h3>Delivered</h3></div>
                </div>
                <div className="projectsbyaliens-swiper">
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={130}
                        freeMode={true}
                        pagination={{
                            clickable: false,
                        }}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            480: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            640: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            840: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            1000: {
                                slidesPerView: 4,
                                spaceBetween: 130,
                            },
                        }}
                        modules={[FreeMode]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="projectsbyaliens-swiper-card">
                                    <div className="projectsbyaliens-swiper-card-text-top">
                                        <div className='projectsbyaliens-swiper-card-text-top-in'>
                                            <div className='card-text-top-in-img'>
                                                <img src="/assets/images/sliderImg/img077.jpg" alt="" />
                                                <div className='card-img-tags'>
                                                    <div className='card-img-tags-holder'>
                                                        <div className='img-tick-icon'>
                                                            <div className='img-tick-icon1'></div>
                                                            <div className='img-tick-icon-text'>RERA</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card-img-text'>
                                                    <span>Possession from Dec 2024</span>
                                                    <span></span>
                                                </div>
                                                <i><BsStar/></i>
                                            </div>
                                        </div>
                                        <div className='projectsbyaliens-swiper-card-text-top-in1'>
                                            <div>
                                                <a>Aliens HUB</a>
                                                <p>Residential Land in Srisailam Highway, Hyderabad</p>
                                                <div>₹ 36 Lac to 1.44 Crore</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default ProjectByAliens