import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsFillSearchHeartFill, BsMicFill } from "react-icons/bs";
import { BiCurrentLocation } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import { Badge } from "react-bootstrap";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { searchPost } from "../../../redux/search/SearchSlice";
import ReactSlider from "react-slider";

const CoworkingSearchComponent = (props) => {
  const { isAuth, loginData } = useSelector((store) => store.Athentication);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();

  const [showResults, setShowResults] = useState(false);

  const [toggle, setToggle] = useState(false);

  const [selectedSeatType, setselectedSeatType] = useState([]);
  const [selectedNoSeat, setselectedNoSeat] = useState([]);
  const [selectedPlan, setselectedPlan] = useState([]);
  const [propertyPrice, setPropertyPrice] = useState([]);
  const [Plans, setPlans] = useState("");

  useEffect(() => {
    props.getSeatTypeDetails(selectedSeatType);
    props.getBudgetDetails(propertyPrice);
    props.getNoSeatDetails(selectedNoSeat);
    props.getPlansDetails(selectedPlan);
  }, [selectedSeatType, propertyPrice, selectedNoSeat, selectedPlan]);

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  const handleSeatTypePress = (selectvalue) => {
    if (selectedSeatType.includes(selectvalue)) {
      setselectedSeatType(
        selectedSeatType.filter((value) => value !== selectvalue)
      );
    } else {
      setselectedSeatType([...selectedSeatType, selectvalue]);
    }
  };
  const handleNoSeatPress = (selectvalue) => {
    if (selectedNoSeat.includes(selectvalue)) {
      setselectedNoSeat(
        selectedNoSeat.filter((value) => value !== selectvalue)
      );
    } else {
      setselectedNoSeat([...selectedNoSeat, selectvalue]);
    }
  };
  const handlePlanPress = (selectvalue) => {
    if (selectedPlan.includes(selectvalue)) {
      setselectedPlan(selectedPlan.filter((value) => value !== selectvalue));
    } else {
      setselectedPlan([...selectedPlan, selectvalue]);
    }
  };

  const seatNumber = [
    { id: "1", name: "1" },
    { id: "2", name: "2-5" },
    { id: "3", name: "6-10" },
    { id: "4", name: "11-20" },
    { id: "5", name: "21-50" },
    { id: "6", name: "51-100" },
    { id: "7", name: "101-500" },
    { id: "8", name: "500+" },
  ];
  const seatType = [
    { id: "1", name: " Hot Desk " },
    { id: "2", name: "Meeting Rooms" },
    { id: "3", name: "Dedicated Desk" },
    { id: "4", name: "Private Cabin" },
    { id: "5", name: "Private Office" },
    { id: "6", name: "Multi-Utility spaces" },
  ];

  const budgetPlans = [
    { id: "1", name: "Hourly" },
    { id: "2", name: "Daily" },
    { id: "3", name: "Weekly" },
    { id: "4", name: "Monthly" },
  ];

  return (
    <>
      <div className="dropdownbox " style={{paddingTop:"0"}}>
        <div className="dropdown-lists">
          <div className="parent-box">
            <div
              className={
                toggle === "No. of seats"
                  ? "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                  : "main-boxes radioInput_textOnly__1r7GL "
              }
              onClick={() => setToggle("No. of seats")}
            >
              <label className="">No. of seats</label>
              {toggle === "No. of seats" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>
            {toggle === "No. of seats" ? (
              <>
                <div className="bedroombox">
                  <div className="posted-head">
                    <h6 className="">Number of seats</h6>
                  </div>
                  <div className="posted-main" style={{ marginTop: "-2px" }}>
                    {seatNumber.map((data, index) => (
                      <div
                        key={index}
                        className={
                          selectedNoSeat.includes(data.name)
                            ? "  tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0 "
                            : "  tags-and-chips_textOnly__2k10B pageComponent "
                        }
                        onClick={() => {
                          handleNoSeatPress(data.name);
                        }}
                        style={{ marginRight: "5px" }}
                      >
                        <label className="">{data.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className="parent-box">
            <div
              className={
                toggle === "Seat Types"
                  ? "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                  : "main-boxes radioInput_textOnly__1r7GL "
              }
              onClick={() => setToggle("Seat Types")}
            >
              <label className="">Seat Types </label>
              {toggle === "Seat Types" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>
            {toggle === "Seat Types" ? (
              <>
                <div className="bedroombox mediabedroom">
                  <div className="bedroom-head">
                    <h6 className="">Seat Type</h6>
                  </div>
                  <div className="posted-main" style={{ marginTop: "-2px" }}>
                    {seatType.map((data, index) => (
                      <div
                        key={index}
                        className={
                          selectedSeatType.includes(data.name)
                            ? "  tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0 "
                            : "  tags-and-chips_textOnly__2k10B pageComponent "
                        }
                        onClick={() => {
                          handleSeatTypePress(data.name);
                        }}
                        style={{ marginRight: "5px" }}
                      >
                        <label className="">{data.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className="parent-box">
            <div
              className={
                toggle === "Budget"
                  ? "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                  : "main-boxes radioInput_textOnly__1r7GL "
              }
              onClick={() => setToggle("Budget")}
            >
              <label className="">Plans / Budget</label>
              {toggle === "Budget" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>

            {toggle === "Budget" ? (
              <>
                <div className="bedroombox">
                  <div className="construction-head">
                    <h6 className="">Choose your plan to adjust pricing</h6>
                  </div>
                  <div className="construction-boxes">
                    {budgetPlans.map((data, index) => (
                      <div
                        key={index}
                        className={
                          selectedPlan.includes(data.name)
                            ? "  tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0 "
                            : "  tags-and-chips_textOnly__2k10B pageComponent "
                        }
                        onClick={() => {
                          handlePlanPress(data.name);
                          setPlans("Hourly");
                        }}
                      >
                        <label className="">{data.name}</label>
                      </div>
                    ))}
                  </div>

                  {Plans !== "" ? (
                    <>
                      <div className="range-box">
                        <div className="range-head">
                          <h6 className="">Select Price Range</h6>
                        </div>
                        <div className="range-bar">
                          <label htmlFor="customRange2" className="">
                            0 - 99+ Lakhs
                          </label>
                          <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            trackClassName="example-track"
                            defaultValue={[0, 1000000]}
                            max={1000000}
                            min={0}
                            renderThumb={(props, state) => (
                              <div {...props}>{state.valueNow}</div>
                            )}
                            onChange={(value, index) => setPropertyPrice(value)}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div></div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className=""></div>
        </div>
      </div>
    </>
  );
};

export default CoworkingSearchComponent;
