import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode } from 'swiper/modules';

const LocationAdventages = () => {
    return (
        <>
            <div className="locationadventage">
                <div className='location-head'>
                    <div className='location-head-inner'>
                        <h2>Location Advantages</h2>
                        <a>View All (13)</a>
                    </div>
                </div>
                <p className='location-subhead'>
                     Aliens Space Station Hyderabad presents an exclusive opportunity to own a stunning home that offers all kinds of amenities and facilities. 
                </p>
                <div className='location-swiper'>
                    <Swiper
                        slidesPerView={3}
                        // grid={{
                        //     rows: 2,
                        // }}
                        spaceBetween={5}
                        pagination={{
                            clickable: false,
                        }}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            480: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            640: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            840: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            1000: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                        }}
                        modules={[FreeMode]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="location-swiper-card">
                                    <img src="/assets/images/sliderImg/AboutMyProperty2.png" alt="" />
                                    <div className="location-swiper-card-text">
                                        <h2>Sadhana Infinity International</h2>
                                        <p>4 km</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="location-swiper-card">
                                    <img src="/assets/images/sliderImg/AboutMyProperty2.png" alt="" />
                                    <div className="location-swiper-card-text">
                                        <h2>Telapur Railway Station</h2>
                                        <p>4.1 km</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="location-swiper-card">
                                    <img src="/assets/images/sliderImg/AboutMyProperty2.png" alt="" />
                                    <div className="location-swiper-card-text">
                                        <h2>Meil Leaf Golf Course</h2>
                                        <p>6.1 km</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="location-swiper-card">
                                    <img src="/assets/images/sliderImg/AboutMyProperty2.png" alt="" />
                                    <div className="location-swiper-card-text">
                                        <h2>Nehru Outer Ring Road</h2>
                                        <p>6.9 km</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="location-swiper-card">
                                    <img src="/assets/images/sliderImg/AboutMyProperty2.png" alt="" />
                                    <div className="location-swiper-card-text">
                                        <h2>University of Hyderabad</h2>
                                        <p>8 km</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="location-swiper-card">
                                    <img src="/assets/images/sliderImg/AboutMyProperty2.png" alt="" />
                                    <div className="location-swiper-card-text">
                                        <h2>Sheraton Hyderabad Hotel</h2>
                                        <p>8.6 km</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="location-swiper-card">
                                    <img src="/assets/images/sliderImg/AboutMyProperty2.png" alt="" />
                                    <div className="location-swiper-card-text">
                                        <h2>GSM Mall & Multiplex</h2>
                                        <p>9 km</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="location-swiper-card">
                                    <img src="/assets/images/sliderImg/AboutMyProperty2.png" alt="" />
                                    <div className="location-swiper-card-text">
                                        <h2>Healix Hospital</h2>
                                        <p>9.4 km</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="location-swiper-card">
                                    <img src="/assets/images/sliderImg/AboutMyProperty2.png" alt="" />
                                    <div className="location-swiper-card-text">
                                        <h2>Gachibowli Stadium</h2>
                                        <p>11.5 km</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="location-swiper-card">
                                    <img src="/assets/images/sliderImg/AboutMyProperty2.png" alt="" />
                                    <div className="location-swiper-card-text">
                                        <h2>Ascendas IT Park</h2>
                                        <p>11.9 km</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="location-swiper-card">
                                    <img src="/assets/images/sliderImg/AboutMyProperty2.png" alt="" />
                                    <div className="location-swiper-card-text">
                                        <h2>Ocean Park</h2>
                                        <p>12.6 km</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="location-swiper-card">
                                    <img src="/assets/images/sliderImg/AboutMyProperty2.png" alt="" />
                                    <div className="location-swiper-card-text">
                                        <h2>Miyapur Metro Station</h2>
                                        <p>13 km</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="location-swiper-card">
                                    <img src="/assets/images/sliderImg/AboutMyProperty2.png" alt="" />
                                    <div className="location-swiper-card-text">
                                        <h2>Begumpet Airport</h2>
                                        <p>25.3 km</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default LocationAdventages