import React from "react";
import SwiperSimilarProperty from "./components/SwiperSimilarProperty";

const SimilarProperty = () => {
  return (
    <>
      <div className="listing-carousel-wrapper carousel-wrap fl-wrap">
        <div className="list-single-main-item-title">
          <h3>Similar Properties</h3>
        </div>
        <div className=" ">
          <SwiperSimilarProperty />
        </div>
   
      </div>
    </>
  );
};

export default SimilarProperty;
