import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { BsArrowRightShort } from 'react-icons/bs'
import { Link } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode } from 'swiper/modules';

const RelatedToYourSearch = () => {
    return (
        <>
            <div className="related-to-search">
                <div className='related-search-head'>
                    <h1>Related to your search</h1>
                </div>
                <div className="related-search-swiper">
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        freeMode={true}
                        pagination={{
                            clickable: false,
                        }}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            480: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            640: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            840: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            1000: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                        modules={[FreeMode]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="related-search-swiper-card">
                                    <div className="related-search-swiper-card-text-top">
                                        <div className='related-search-swiper-card-text-top-in-text'>
                                            <h3>Popular Projects</h3>
                                            <div className='related-search-textin'>
                                                <p>Tripura Green Alpha in Tellapur</p>
                                                <p>Fortune Greenhomes Sapphire in Tellapur</p>
                                                <p>Aakriti Cyan in Tellapur</p>
                                            </div>
                                            <a>View 7 More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="related-search-swiper-card">
                                    <div className="related-search-swiper-card-text-top">
                                        <div className='related-search-swiper-card-text-top-in-text'>
                                            <h3>Similar New Launched Projects</h3>
                                            <div className='related-search-textin'>
                                                <p>Ramky One Orbit in Nallagandla</p>
                                                <p>Manepally Green Grandeur in Suchitra</p>
                                                <p>Anvita Sky Garden in Kollur</p>
                                            </div>
                                            <a>View 6 More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="related-search-swiper-card">
                                    <div className="related-search-swiper-card-text-top">
                                        <div className='related-search-swiper-card-text-top-in-text'>
                                            <h3>Similar Under Construction Projects</h3>
                                            <div className='related-search-textin'>
                                                <p>Visions Arsha in Tellapur</p>
                                                <p>Honer Aquantis in Gopanpally</p>
                                                <p>Haimi Nirvana in Osman Nagar</p>
                                            </div>
                                            <a>View 4 More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="related-search-swiper-card">
                                    <div className="related-search-swiper-card-text-top">
                                        <div className='related-search-swiper-card-text-top-in-text'>
                                            <h3>Popular Projects</h3>
                                            <div className='related-search-textin'>
                                                <p>Tripura Green Alpha in Tellapur</p>
                                                <p>Fortune Greenhomes Sapphire in Tellapur</p>
                                                <p>Aakriti Cyan in Tellapur</p>
                                            </div>
                                            <a>View 7 More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default RelatedToYourSearch