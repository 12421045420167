import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";

import "swiper/css";
import "swiper/css/pagination";

const recomPropertySwiper = () => {
  return (
    <>
      <div className="recom-ppt">
        <div></div>
        <div className="recom-head">
          <div>
            <h1>
              ₹ 1.12 - 4.98 Cr <span>+ Govt. Charges</span>
            </h1>
            <p>PRICE RANGE</p>
          </div>
          <div>
            <button>
              <FaDownload /> Download Brocher
            </button>
          </div>
        </div>
        <div className="recom-subhead">
          <h3>3, 4, 5 BHK Apartment</h3>
        </div>
        <div className="recom-swiper">
          <Swiper
            slidesPerView={3}
            spaceBetween={0}
            pagination={{
              clickable: false,
            }}
            breakpoints={{
              360: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              720: {
                slidesPerView: 2,
                spaceBetween: 10,
              },

              1080: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
            }}
            modules={[]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <div className="recom-item">
                    <div className="recom-item-head">
                      <h1>
                        <span>3 BHK </span>Apartment
                      </h1>
                    </div>
                    <hr></hr>
                    <div className="recom-body">
                      <p>Super Built-up Area</p>
                      <h3>
                        1344 - 2191 sq.ft.
                        <span>
                          (124.86 - 203.55 sq.m.) <BsChevronDown />
                        </span>
                      </h3>
                      <h2>
                        ₹ 1.12 - 1.71 Cr<span>+ Govt. Charges</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <div className="recom-item">
                    <div className="recom-item-head">
                      <h1>
                        <span>3 BHK </span>Apartment
                      </h1>
                    </div>
                    <hr></hr>
                    <div className="recom-body">
                      <p>Super Built-up Area</p>
                      <h3>
                        1344 - 2191 sq.ft.
                        <span>
                          (124.86 - 203.55 sq.m.) <BsChevronDown />
                        </span>
                      </h3>
                      <h2>
                        ₹ 1.12 - 1.71 Cr<span>+ Govt. Charges</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <div className="recom-item">
                    <div className="recom-item-head">
                      <h1>
                        <span>3 BHK </span>Apartment
                      </h1>
                    </div>
                    <hr></hr>
                    <div className="recom-body">
                      <p>Super Built-up Area</p>
                      <h3>
                        1344 - 2191 sq.ft.
                        <span>
                          (124.86 - 203.55 sq.m.) <BsChevronDown />
                        </span>
                      </h3>
                      <h2>
                        ₹ 1.12 - 1.71 Cr<span>+ Govt. Charges</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <div className="recom-item">
                    <div className="recom-item-head">
                      <h1>
                        <span>3 BHK </span>Apartment
                      </h1>
                    </div>
                    <hr></hr>
                    <div className="recom-body">
                      <p>Super Built-up Area</p>
                      <h3>
                        1344 - 2191 sq.ft.
                        <span>
                          (124.86 - 203.55 sq.m.) <BsChevronDown />
                        </span>
                      </h3>
                      <h2>
                        ₹ 1.12 - 1.71 Cr<span>+ Govt. Charges</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default recomPropertySwiper;
