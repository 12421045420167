
import React from "react";

const RERAApproved = () => {
  const reraapproved = [
    { id: "1", name: "RERA approved properties" },
    { id: "2", name: "RERA registered dealers" },
  ];
  return (
    <div className="mainbarfiltercomponents">
      {reraapproved.map((rera, index) => (
        <div className="propertyboxes" key={index}>
          <input type="checkbox" />
          <p className="propertytextpara">{rera.name}</p>
        </div>
      ))}

      <div>
        <p className="clearalltext">Select All</p>
      </div>
    </div>
  );
};

export default RERAApproved;