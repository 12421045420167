import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FcPhoneAndroid, FcInfo, FcIdea } from "react-icons/fc";
import { GrMail } from "react-icons/gr";
import { MdDoNotDisturbAlt } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { CiShare1 } from "react-icons/ci";
import { HiPencil } from "react-icons/hi2";
import { MdDelete } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CircularProgress from "../../postpropertypage/componenets/CircularProgress";
import RecallListingsModal from "./propertyReCalmodel/RecallListingsModal";
import BoostPropertyModel from "./propertyReCalmodel/BoostPropertyModel";
import {
  pageStatusChange,
  propertyUpdate,
  setSinglePropertyResponse,
} from "../../../redux/property/PropertySlice";
import BoostListing from "../../postpropertypage/skipPost/BoostListing";
import MainBoostingPage from "./MainBoostingPage";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { IoStar } from "react-icons/io5";
import { IoStarOutline } from "react-icons/io5";
import ResponsesNewModal from "./propertyReCalmodel/ResponsesNewModal";

const ResponseBySingleProperty = () => {
  const { singlePropertyResponse } = useSelector((store) => store.Property);
  const { loginData } = useSelector((store) => store.Athentication);

  const [modal, setModal] = useState(false);
  const [boostmodal, setBoostModal] = useState(false);
  const [singlePropertyId, setsinglePropertyId] = useState("");

  const [progress, setProgress] = useState(0);
  const [bostingIndex, setBostingIndex] = useState(0);
  const [selectBoost, setselectBoost] = useState("");

  const [responseProp, setResponseProp] = useState([]);
  const [downloadModal, setDownloadModal] = useState(false);
  const downloadModaltoggle = () => setDownloadModal(!downloadModal);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      // Simulating progress update
      setProgress((prevProgress) =>
        prevProgress < 100 ? prevProgress + 10 : 0
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const toggle = () => setModal(!modal);
  const boostToggle = () => setBoostModal(!boostmodal);

  const boostClick = (property) => {
    const propId = property._id;
    setsinglePropertyId(propId);
  };
  const deleteUpdateClick = (property) => {
    const formData = {
      propertyId: property._id,
      propertyStatus: "Deleted",
    };

    dispatch(propertyUpdate(formData));
  };
  const editPropertyClick = (property) => {
    const formData = {
      propertyId: property._id,
      propertyStatus: "Deleted",
    };
    navigate(`/edit-property/${property._id}`);
  };

  const setChangeIndexValue = (value) => {
    setBostingIndex(value);
  };
  const setselectBoostValue = (value) => {
    setselectBoost(value);
  };

  useEffect(() => {
    try {
      const fetchdata = async () => {
        const url = `${Baseurl}/api/v1/viewed/userviewed/${singlePropertyResponse._id}`;
        const fetchResponse = await axios(url);
        if (fetchResponse.data.success === true) {
          let singleResponse = fetchResponse.data.userViewedProperty;
          setResponseProp(singleResponse);
        }
      };
      fetchdata();
    } catch (error) {}
  }, []);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className="modalmainnew" style={{}}>
        <div
          className="model-wrappernew"
          style={{ height: "auto", width: "auto" }}
        >
          {/* <ModalHeader toggle={toggle}></ModalHeader> */}
          <ModalBody>
            <RecallListingsModal toggle={toggle} />
          </ModalBody>
        </div>
      </Modal>

      <Modal
        isOpen={boostmodal}
        toggle={boostToggle}
        className="modalmainPopUp"
      >
        <div
          className="model-wrapperPopUp"
          style={{ height: "auto", width: "auto" }}
        >
          <ModalBody>
            <MainBoostingPage
              bostingIndex={bostingIndex}
              boostToggle={boostToggle}
              setChangeIndexValue={setChangeIndexValue}
              singlePropertyId={singlePropertyId}
              setselectBoostValue={setselectBoostValue}
              selectBoost={selectBoost}
            />
          </ModalBody>
        </div>
      </Modal>

      {singlePropertyResponse !== "" ? (
        <>
          <div className="component__card">
            <div className="component__card_container1">
              <div className="card_in01" style={{ display: "flex" }}>
                <label>
                  <input type="checkbox" />
                  {singlePropertyResponse.availabilityStatus +
                    " " +
                    singlePropertyResponse.noofBedrooms +
                    " BHK " +
                    singlePropertyResponse.subCategory +
                    " for sale in " +
                    singlePropertyResponse.locality +
                    " , " +
                    singlePropertyResponse.city +
                    " , " +
                    singlePropertyResponse.state}
                </label>
              </div>
              <div
                className="card_in02 cardpremium"
                style={{ alignItems: "center" }}
              >
                <span
                  className="me-1 premuiumtext"
                  onClick={() => setModal(!modal)}
                >
                  {/* PREMIUM */}
                  {loginData.packageType}
                </span>
                <i>
                  <CiShare1 />
                </i>
              </div>
              <div className="card_in03 subuserbox">
                <div className="card-assign-align">
                  <span className="span_getleadssubuser">
                    Assign to Subuser
                  </span>
                </div>

                <div className="iconsflexbox">
                  <i
                    className="iconspremium"
                    onClick={() => editPropertyClick(singlePropertyResponse)}
                    style={{ cursor: "pointer" }}
                  >
                    <HiPencil />
                  </i>
                  <i
                    className="iconspremium"
                    onClick={() => deleteUpdateClick(singlePropertyResponse)}
                    style={{ cursor: "pointer" }}
                  >
                    <MdDelete />
                  </i>
                  <i className="iconspremium">
                    <MdDoNotDisturbAlt />
                  </i>
                </div>
              </div>
            </div>
            <div className="component__card_container2">
              <p>
                price: <span>Rs {singlePropertyResponse.visiblePrice},</span>|
                Super built-up Area :
                <span> {singlePropertyResponse.carpetArea} sq.ft.</span>
              </p>
            </div>
            <div className="component__card_container3">
              <p>
                <span className="span_number">
                  {singlePropertyResponse.propertyID} :{" "}
                </span>
                <span className="span_expired coloringRoot">
                  <font color="green">
                    {singlePropertyResponse.propertyStatus}
                  </font>
                </span>
                | Posted On :
                <span className="span_date">
                  {moment(singlePropertyResponse.createdAt).format(
                    "DD MMMM YYYY"
                  )}
                </span>
              </p>
            </div>
            <div
              className="component__card_container3"
              style={{ display: "flex" }}
            >
              <p>
                Expiry On:
                <span>
                  {moment(singlePropertyResponse.ExpiryDate).format(
                    "DD MMMM YYYY"
                  )}
                </span>
              </p>
              <p className="ms-3">
                <u>Extend Duration</u>
              </p>
            </div>
            <div className="card_container4-1" style={{ marginLeft: "10px" }}>
              <div className="row d-flex" style={{ display: "flex" }}>
                <div className="col-4 col-md-4 col-sm-4 col-xs-4 cardboxone">
                  <div className="subcardboxone">
                    <div>
                      <img
                        src="../assets/images/caution.png"
                        alt="caution"
                        className="cautionimage"
                      />
                    </div>
                    <div>
                      <h6 className="propertytext">Property score is good</h6>
                      <p className="the-add-text">The add might get ignored</p>
                    </div>
                  </div>
                  <div className="d-flex subcardboxone">
                    <p className="navblue-text">Add photos</p>
                    <p className="navblue-text">Get Verified</p>
                  </div>
                </div>
                <div className="col-4 col-md-4 col-sm-4 col-xs-4 cardboxtwo">
                  {/* <div
                    style={{
                      border: "3px solid green",
                      borderRadius: "50px",
                      width: "40px",
                      height: "40px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        color: "#000",
                        fontSize: "12px",
                        fontWeight: "500",
                        margin: 0,
                        textAlign: "center",
                      }}
                    >
                      100%
                    </p>
                  </div> */}
                  <div className="qualityScoreWidget_propertyScore__28KaJ mainbarcircular">
                    <div className="qualityScoreWidget_scoreGraph__3nVN7 circularbarsizing">
                      <div
                        className="circular_progress"
                        style={{ zIndex: "9" }}
                      >
                        <CircularProgress percentage={95} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-md-4 col-sm-4 col-xs-4 cardboxthree">
                  <div className="cardtext">
                    Locality Percentile:{" "}
                    <span className="me-1">
                      <font color="red" className="cardtext">
                        33.33%
                      </font>
                    </span>
                    <FaInfoCircle />
                  </div>
                </div>
              </div>
            </div>
            <div className="row ms-3 summary-text-box">
              <div className="col-4 col-md-4 col-sm-12 col-xs-12 deatilsboxone">
                <div className="m-1">
                  Summary view {singlePropertyResponse.Contactview} Details view{" "}
                  {singlePropertyResponse.Detailsview}
                </div>
              </div>
              <div className="col-4 col-md-4 col-sm-12 col-xs-12 detailsboxtwo">
                <div className="m-1">Listing Price: 1 Credits</div>
                <div>
                  <u>View Past Performance</u>
                </div>
              </div>
              <div
                className="col-4 col-md-4 col-sm-12 col-xs-12 detailsboxthree"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setBoostModal(!boostmodal);
                  boostClick(singlePropertyResponse);
                }}
              >
                <u>Boost Listing</u>
              </div>
            </div>
            <div className="component__card_container5 bottomcard-design">
              <div className="">
                <h4>
                  <span className="card_icon_idea">
                    <FcIdea />
                  </span>
                  Need more buyers/tenants for your property, simply
                  <span className="span_getleads"> Get Leads</span>
                </h4>
              </div>
              <div className="">
                <span className="span_getleads-assign">Assign to Subuser</span>
              </div>
            </div>
          </div>

          <div
            className="d-flex justify-content-end align-items-end mb-2"
            style={{ position: "relative", textAlign: "right" }}
          >
            <button
              className="btn btn-secondary"
              onClick={() => setDownloadModal(!downloadModal)}
              style={{ background: "#3270fc", border: "1px solid #3270fc" }}
            >
              Download Responses
            </button>
          </div>
          {downloadModal === true ? (
            <>
              <div className="modalopen">
                <ResponsesNewModal
                  response={responseProp}
                  toggle={downloadModaltoggle}
                />
              </div>
            </>
          ) : (
            <></>
          )}

          {responseProp.length > 0 ? (
            <>
              {responseProp.map((view, index) => (
                <div className="card-blue-main" key={index}>
                  <div className="row">
                    <div className="col-4 col-md-4 col-sm-12 col-xs-12 firstboxres">
                      <p className="responsesone-text">
                        <span style={{ fontWeight: "500" }}>
                          {view.userName} ({view.propertyName})
                        </span>{" "}
                      </p>

                      <p
                        className="responsesone-text"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <span>+91-{view.userMobile} |</span>{" "}
                        <img
                          src="../assets/images/correct.png"
                          alt="caution"
                          style={{
                            width: "15px",
                            height: "15px",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        />
                        <span
                          style={{
                            color: "#207faf",
                            fontWeight: "500",
                          }}
                        >
                          {view.userEmail}
                        </span>
                      </p>
                      {/* </div> */}
                    </div>
                    <div className="col-4 col-md-4 col-sm-12 col-xs-12 secondboxres">
                      <p
                        className="responsesone-text align-items-center"
                        style={{ display: "flex" }}
                      >
                        <div>
                          <span style={{ fontWeight: "500" }}>3.0</span>
                        </div>
                        <div className="ms-2 me-2">
                          <IoStar color="#f6af39" size={18} />
                          <IoStar color="#f6af39" size={18} />
                          <IoStar color="#f6af39" size={18} />
                          <IoStarOutline color="#f6af39" size={18} />
                          <IoStarOutline color="#f6af39" size={18} />
                        </div>
                        <div>
                          <span style={{ fontWeight: "500" }}>
                            View Behaviour
                          </span>
                        </div>
                      </p>
                      <p className="responsesone-text">
                        <span style={{ fontWeight: "500" }}>
                          Viewed Contact
                        </span>{" "}
                        on {moment(view.createdAt).format("DD MMM YYYY")}
                      </p>
                    </div>
                    <div className="col-4 col-md-4 col-sm-12 col-xs-12 thirdboxres">
                      <p className="responsesone-text">
                        <span
                          style={{
                            fontWeight: "500",
                          }}
                        >
                          {view.propertyId.slice(0, 8)}:{" "}
                        </span>
                        <span
                          style={{
                            color: "#207faf",
                            fontWeight: "500",
                          }}
                        >
                          {view.propertyStatus}
                        </span>
                      </p>
                      {view.noofBedrooms > 1 ? (
                        <>
                          <p className="responsesone-text">
                            Rs.
                            {view.price +
                              ", " +
                              view.noofBedrooms +
                              " Beds for " +
                              view.category +
                              " in " +
                              view.propertyStatus}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="responsesone-text">
                            Rs.
                            {view.price +
                              ", " +
                              view.noofBedrooms +
                              " Bed for " +
                              view.category +
                              " in " +
                              view.propertyStatus}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div className="component__responceWrapper">
                <div className="pageComponent"></div>
                <div className="fslBooking__noResult">
                  <h1>Sorry, No Results !</h1>
                  <p>We cannot find the item you are searching for.</p>
                  <div className="fslBooking__noResults"></div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="component__responceWrapper">
            <div className="pageComponent"></div>
            <div className="fslBooking__noResult">
              <h1>Sorry, No Results !</h1>
              <p>We cannot find the item you are searching for.</p>
              <div className="fslBooking__noResults"></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ResponseBySingleProperty;
