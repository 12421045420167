import React, { useState, useRef } from 'react';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';

const Grievance = () => {

  return (
    <>
      <div>
        <Header />
        <div className='grivance'>
          <div style={{ display: "block" }}>
            <div className='csp20'></div>
            <div id='container_griv'>
              <div id='content_newgriv'>
                <div className='sp20'></div>
                <div className='rf'>
                  <font color="#ff7100">*</font>
                  <b style={{ fontSize: "12px" }}> Compulsory Fields</b>
                </div>
              </div>
              <div className='sp20'></div>
              <div style={{ width: '90%', marginTop: "20px", textAlign: "left" }}>
                <div className='hd3'>
                  Grievance Form - for Complaints relating to content and Consumer Complaints.
                </div>
              </div>
              <div className='sp20'></div>
              <div className='secbx1 compaintForm' style={{ float: "left" }}>
                <div className='lf bxl'>
                  <div className='row4'>
                    <label htmlFor="">
                      <span id='span_Name'><font style={{ color: "#ff7100" }}>*</font> Your Name: </span>
                    </label>
                    <p style={{ marginTop: "10px" }}>
                      <input type="text" name='strName' size="25" maxLength="35" style={{ height: "20px" }} />
                    </p>
                  </div>
                  <div className='row4'>
                    <label htmlFor="">
                      <span id='span_Email'><font style={{ color: "#ff7100" }}>*</font> Your E-Mail ID:  </span>
                    </label>
                    <p style={{ marginTop: "10px" }}>
                      <input type="email" name='strName' size="25" style={{ height: "20px" }} />
                    </p>
                  </div>
                  <div className='row4'>
                    <label htmlFor="">
                      <span id='span_Email'><font style={{ color: "#ff7100" }}>*</font> Your Contact Number: </span>
                    </label>
                    <p style={{ marginTop: "10px" }}>
                      <input type="text" name='strNumber' size="25" style={{ height: "20px" }} />
                    </p>
                  </div>
                  <div className='row4'>
                    <label htmlFor="">
                      <span id='span_Email'><font style={{ color: "#ff7100" }}>*</font>   Please mark the web portal which hosts the issue(s) encountered by you :  </span>
                    </label>
                    <select style={{ marginTop: "10px", marginLeft: "120px", padding: "2px 2px", justifyContent: "left", alignItems: "center", textAlign: "left", display: "flex" }}>
                      <option value="1">Select</option>
                      <option value="2">naukri.com</option>
                      <option value="3">Mygoldenhomes.com</option>
                      <option value="4">Jeevansathi.com</option>
                      <option value="5">Firstnaukri.com</option>
                      <option value="6">Naukrigulf.com</option>
                      <option value="7">Firstnaukri.com</option>
                      <option value="8">iimjobs.com</option>
                      <option value="9">Jobhai.com</option>
                      <option value="10">Shiksha.com</option>
                      <option value="11">Infoedge.in</option>
                      <option value="12">Ambitionbox.com</option>
                      <option value="13">Hirist.com</option>
                      <option value="14">Updazz.com</option>
                      <option value="15">Allthingstalent.org</option>
                    </select>
                  </div>
                  <div className='row4'>
                    <label htmlFor="">
                      <span id='span_Email'><font style={{ color: "#ff7100" }}>*</font>  Please share the link (URL) of the Page which you are reporting against:  </span>
                    </label>
                    <p style={{ marginTop: "10px" }}>
                      <input type="text" name='strNumber' size="25" style={{ height: "20px" }} />
                    </p>
                  </div>
                  <div className='row4'>
                    <label htmlFor="">
                      <span id='span_Email'><font style={{ color: "#ff7100" }}>*</font>
                        Please tell us the reason for your complaint/concern.
                        Choose an option which most closely matches with your concern. If you are unsure
                        which option to choose, please select the last option. Thanks
                      </span>
                    </label>
                  </div>
                  <div className='row4'>
                    <div style={{ textAlign: "left", display: "flex" }}>
                      <input type="checkbox" style={{ verticalAlign: "middle" }} />
                      <span>belongs to another person and to which the user does not have any right</span>
                    </div>
                  </div>
                  <div className='row4'>
                    <div style={{ textAlign: "left", display: "flex" }}>
                      <div><input type="checkbox" style={{ verticalAlign: "middle" }} /></div>
                      <div>is defamatory, obscene, pornographic, paedophilic, invasive of another's privacy, including bodily privacy, insulting or harassing on the basis of gender, libellous,racially or ethnically objectionable,
                        relating or encouraging money laundering orgambling, or otherwise inconsistent with or contrary to the laws in force</div>
                    </div>
                  </div>
                  <div className='row4'>
                    <div style={{ textAlign: "left", display: "flex" }}>
                      <div><input type="checkbox" style={{ verticalAlign: "middle" }} /></div>
                      <div>is harmful to child</div>
                    </div>
                  </div>
                  <div className='row4'>
                    <div style={{ textAlign: "left", display: "flex" }}>
                      <div><input type="checkbox" style={{ verticalAlign: "middle" }} /></div>
                      <div>infringes any patent, trademark, copyright or other proprietary rights</div>
                    </div>
                  </div>
                  <div className='row4'>
                    <div style={{ textAlign: "left", display: "flex" }}>
                      <div><input type="checkbox" style={{ verticalAlign: "middle" }} /></div>
                      <div>violates any law for the time being in force</div>
                    </div>
                  </div>
                  <div className='row4'>
                    <div style={{ textAlign: "left", display: "flex" }}>
                      <div><input type="checkbox" style={{ verticalAlign: "middle" }} /></div>
                      <div>deceives or misleads the addressee about the origin of the message or knowingly andintentionally
                        communicates any information which is patently false or misleading innature but may reasonably be perceived as a fact</div>
                    </div>
                  </div>
                  <div className='row4'>
                    <div style={{ textAlign: "left", display: "flex" }}>
                      <div><input type="checkbox" style={{ verticalAlign: "middle" }} /></div>
                      <div>impersonates another person</div>
                    </div>
                  </div>
                  <div className='row4'>
                    <div style={{ textAlign: "left", display: "flex" }}>
                      <div><input type="checkbox" style={{ verticalAlign: "middle" }} /></div>
                      <div>threatens the unity, integrity, defence, security or sovereignty of India, friendlyrelations with foreign States, or public order,
                        or causes incitement to the commissionof any cognisable offence or prevents investigation of any offence or is insultingother nation</div>
                    </div>
                  </div>
                  <div className='row4'>
                    <div style={{ textAlign: "left", display: "flex" }}>
                      <div><input type="checkbox" style={{ verticalAlign: "middle" }} /></div>
                      <div>contains software virus or any other computer code, file or program designed tointerrupt,
                        destroy or limit the functionality of any computer resource</div>
                    </div>
                  </div>
                  <div className='row4'>
                    <div style={{ textAlign: "left", display: "flex" }}>
                      <div><input type="checkbox" style={{ verticalAlign: "middle" }} /></div>
                      <div>is patently false and untrue, and is written or published in any form,
                        with the intent tomislead or harass a person, entity or agency for financial gain or to cause any injuryto any person</div>
                    </div>
                  </div>
                  <div className='row4' style={{ textAlign: "left" }}>
                    <div style={{ textAlign: "left", display: "flex" }}>
                      <div><input type="checkbox" style={{ verticalAlign: "middle" }} /></div>
                      <div>Others (including Consumer Complaints)</div>
                    </div>
                  </div>
                  <div className='row4' style={{ textAlign: "left" }}>
                    <div style={{ textAlign: "left" }}>
                      <div style={{ marginBottom: "10px" }}><span id='span_Name'><font style={{ color: "#ff7100" }}>*</font> Please describe your complaint/concern in detail: </span></div>
                      <div><textarea name="" id="" cols="40" rows="8"></textarea></div>
                    </div>
                  </div>
                  <div className='row4' style={{ textAlign: "left" }}>
                    <div style={{ textAlign: "left" }}>
                      <div style={{ marginBottom: "10px" }}><span id='span_Name'><font style={{ color: "#ff7100" }}>*</font> Please upload any supporting document(s) pertaining to the issue you are reporting: </span></div>
                      <div><input type="file" /></div>
                      <div htmlFor="" style={{ marginTop: "10px" }}>(Please upload a GIF, PNG, JPG, PDF or JPEG file only and ensure that the file is currently not in use. [Maximum File Size Limit 2 MB])</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
        <Footer />
      </div>
    </>
  )
}

export default Grievance