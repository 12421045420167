import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { GrCircleQuestion } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";


const ItisPreleasedTemp = (props) => {
  const { PropertyProfile, PriceotherDetails,singleProperty } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [preleased, setPreleased] = useState(Boolean(singleProperty.preleasedCommercial));
  const [rentpermonth, seRrentpermonth] = useState(singleProperty.rentpermonthCommercial);
  const [leaseyears, setLeaseyears] = useState(singleProperty.leaseyearsCommercial);

  const [addAnnulRent, setAddAnnulRent] = useState(false);
  const [leaseincreasedPercentage, setLeaseincreasedPercentage] = useState(singleProperty.leasePercentageCommercial);
  const [businessType, setBusinessType] = useState(singleProperty.businessTypeCommercial);

  //errors
  const [preleasedError, setPreleasedError] = useState("");

  useEffect(() => {
    if (PriceotherDetails !== "") {
      setPreleased(PriceotherDetails.preleased);
      seRrentpermonth(PriceotherDetails.rentpermonth);
      setLeaseyears(PriceotherDetails.leaseyears);

      setAddAnnulRent(PriceotherDetails.addAnnulRent);
      setLeaseincreasedPercentage(PriceotherDetails.leaseincreasedPercentage);
      setBusinessType(PriceotherDetails.businessType);
    }
  }, [PriceotherDetails]);

  return (
    <>
      <div className="PreLeased-widgett" style={{ marginTop: "10px" }}>
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div
            className="widgetbox_header_text__2BXc_"
            style={{ marginBottom: "-20px" }}
          >
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Is it Pre-leased / Pre-Rented?{" "}
                    <i>
                      <GrCircleQuestion />
                    </i>
                  </span>
                </div>
                <div className="caption_strong_small">
                  <em className="xidHeading_subLabel__1Fc9h">
                    for properties that are already rented out
                  </em>
                </div>
              </h4>
            </div>
            <p style={{ color: "red" }}>{props.preleasedError}</p>
          </div>
        </div>
        <div className="false">
          <div
            className="pagecomponent"
            id="ownership-input"
            style={{ textAlign: "left" }}
          >
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  preleased === true
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setPreleased(true);
                  props.getPreReleased(true);
                }}
                id="1"
              >
                <span>Yes</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  preleased === false
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setPreleased(false);
                  props.getPreReleased(false);
                }}
                id="2"
              >
                <span>No</span>
              </div>
            </div>
          </div>
          {preleased === true ? (
            <>
              <div>
                <div className="widgetbox_white_box__1v7xz undefined false false">
                  <div
                    className="widgetbox_header_text__2BXc_"
                    style={{ marginBottom: "-20px" }}
                  >
                    <div className="xidHeading_PageHeadingwrap__3z0kL">
                      <h4>
                        <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                          <span
                            className="xidHeading_headingText__Ulc2a"
                            style={{ display: "inline" }}
                          >
                            Is it Pre-leased / Pre-Rented?{" "}
                            <i>
                              <GrCircleQuestion />
                            </i>
                          </span>
                        </div>
                        <div className="caption_strong_small">
                          <em className="xidHeading_subLabel__1Fc9h">
                            Lease / Rent related details of your property
                          </em>
                        </div>
                      </h4>
                    </div>
                  </div>
                </div>
                <div id="DepositValue-input">
                  <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                    <div className="false">
                      <input
                        type="text"
                        value={rentpermonth}
                        onChange={(e) => {
                          seRrentpermonth(e.target.value.replace(/\D/g, ""));
                          props.getRentPerMonth(e.target.value.replace(/\D/g, ""))

                        }}
                        placeholder="₹ Current rent per month"
                      />
                      <div></div>
                      <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT"></div>
                      <div className="numberInput_iconsWrapper__3Kpm5">
                        <i></i>
                        <div className="screeningActions_iconWrapper__dB1NM"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="bookingAmount-input">
                  <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                    <div className="false">
                      <input
                        type="text"
                        value={leaseyears}
                        onChange={(e) => {
                          setLeaseyears(e.target.value.replace(/\D/g, ""));
                          props.getLeaseYears(
                            e.target.value.replace(/\D/g, "")
                          );
                        }}
                        placeholder="Lease tenure in years"
                      />
                      <div></div>
                      <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT"></div>
                      <div className="numberInput_iconsWrapper__3Kpm5">
                        <i></i>
                        <div className="screeningActions_iconWrapper__dB1NM"></div>
                      </div>
                    </div>
                  </div>
                </div>
                {addAnnulRent === true ? (
                  <>
                    <div className="hyperlinks_medium AddOther_addOtherLink__3jZ8s">
                      <i className="iconS_PPF_20 icon_plusIcon">
                        <BiPlus />
                      </i>
                      <span className="AddOther_toggleLabel__YwU_k">
                        Add Annual Rent Increament & Leased to
                      </span>
                    </div>
                    <div id="DepositValue-input">
                      <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                        <div className="false">
                          <input
                            type="text"
                            value={leaseincreasedPercentage}
                            onChange={(e) => {
                              setLeaseincreasedPercentage(
                                e.target.value.replace(/\D/g, "")
                              );
                              props.getLeaseIncrease(
                                e.target.value.replace(/\D/g, "")
                              );
                            }}
                            placeholder="Percentage (%) of increase in rent"
                          />
                          <div></div>
                          <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT"></div>
                          <div className="numberInput_iconsWrapper__3Kpm5">
                            <i></i>
                            <div className="screeningActions_iconWrapper__dB1NM"></div>
                          </div>
                        </div>
                      </div>
                      <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                        <div className="false">
                          <input
                            type="text"
                            value={businessType}
                            onChange={(e) => {
                              setBusinessType(
                                e.target.value.replace(/\D/g, "")
                              );
                              props.getBusinessType(
                                e.target.value.replace(/\D/g, "")
                              );
                            }}
                            placeholder="Leased to - Business Type (Optional)"
                          />
                          <div></div>
                          <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT"></div>
                          <div className="numberInput_iconsWrapper__3Kpm5">
                            <i></i>
                            <div className="screeningActions_iconWrapper__dB1NM"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <span className="hyperlinks_medium d-inline-block">
                      <span
                        className="pageComponent"
                        onClick={() => {
                          setAddAnnulRent(!addAnnulRent);
                          props.getAnnualRent(!addAnnulRent);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className="iconS_PPFDesk_16 icon_bluePlusIcon expandWidget_addMoreIcon__1_5h-"
                          style={{
                            verticalAlign: "middle",
                            fontSize: "18px",
                          }}
                        >
                          <BiPlus />
                        </i>
                        <span
                          className="expandWidget_toggleLabel__3sbvc"
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Add Annual Rent Increament & Leased to
                        </span>
                      </span>
                    </span>
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ItisPreleasedTemp;
