import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { FiArrowUpRight } from 'react-icons/fi'
import { MdOutlineDesignServices } from 'react-icons/md'
import { IoMdCall } from 'react-icons/io'
import { Link } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode } from 'swiper/modules';

const FloorPlansSwiper = () => {
    return (
        <>
            <div className="floorplans">
                <div className='floorplans-head'>
                    <h1>Floor Plans & Pricing</h1>
                </div>
                <div className='floorplans-head-pills'>
                    <div className='active-pill'><h3>3 BHK Apartment</h3></div>
                    <div><h3>4 BHK Apartment</h3></div>
                    <div><h3>5 BHK Apartment</h3></div>
                </div>
                <div className="floorplans-swiper">
                    <p className='floorplans-swiper-subtext'>10 Floor Plans Available</p>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={180}
                        freeMode={true}
                        pagination={{
                            clickable: false,
                        }}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            480: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            640: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            840: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            1000: {
                                slidesPerView: 3,
                                spaceBetween: 180,
                            },
                        }}
                        modules={[FreeMode]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="floorplans-swiper-card">
                                    <div className="floorplans-swiper-card-text-top">
                                        <div className='floorplans-swiper-card-text-top-in'>
                                            <h2>₹ 1.12 Cr</h2>
                                        </div>
                                        <div>
                                            <div className='floorplans-swiper-card-text-top-in1'>
                                                <i><MdOutlineDesignServices /></i>
                                                <div>
                                                    <div>
                                                        <h2>₹ 1344 sq.ft. (124.86 sq.m)</h2>
                                                    </div>
                                                    <div>
                                                        <p>Super Built-up Area | 3 BHK</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-img'>
                                            <img src='/assets/images/sliderImg/H1.jpg' alt='' />
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-card'>
                                            <div className='floorplans-swiper-card-text-top-in-card-text'>
                                                <h2>Under Construction</h2>
                                                <h4>Units available with completion in Sep ‘24</h4>
                                            </div>
                                        </div>
                                        <a href="/" className='floorplans-swiper-card-text-top-in-card-end'>
                                            <div>
                                                <h2>Request callback</h2>
                                            </div>
                                            <div>
                                                <h2><IoMdCall/></h2>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="floorplans-swiper-card">
                                    <div className="floorplans-swiper-card-text-top">
                                        <div className='floorplans-swiper-card-text-top-in'>
                                            <h2>₹ 1.19 Cr</h2>
                                        </div>
                                        <div>
                                            <div className='floorplans-swiper-card-text-top-in1'>
                                                <i><MdOutlineDesignServices /></i>
                                                <div>
                                                    <div>
                                                        <h2>₹ 1432 sq.ft. (133.04 sq.m)</h2>
                                                    </div>
                                                    <div>
                                                        <p>Super Built-up Area | 3 BHK</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-img'>
                                            <img src='/assets/images/sliderImg/H1.jpg' alt='' />
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-card'>
                                            <div className='floorplans-swiper-card-text-top-in-card-text'>
                                                <h2>Under Construction</h2>
                                                <h4>Units available with completion in Sep ‘24</h4>
                                            </div>
                                        </div>
                                        <a href="/" className='floorplans-swiper-card-text-top-in-card-end'>
                                            <div>
                                                <h2>Request callback</h2>
                                            </div>
                                            <div>
                                                <h2><IoMdCall/></h2>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="floorplans-swiper-card">
                                    <div className="floorplans-swiper-card-text-top">
                                        <div className='floorplans-swiper-card-text-top-in'>
                                            <h2>₹ 1.23 Cr</h2>
                                        </div>
                                        <div>
                                            <div className='floorplans-swiper-card-text-top-in1'>
                                                <i><MdOutlineDesignServices /></i>
                                                <div>
                                                    <div>
                                                        <h2>₹ 1597 sq.ft. (148.37 sq.m)</h2>
                                                    </div>
                                                    <div>
                                                        <p>Super Built-up Area | 3 BHK</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-img'>
                                            <img src='/assets/images/sliderImg/H1.jpg' alt='' />
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-card'>
                                            <div className='floorplans-swiper-card-text-top-in-card-text'>
                                                <h2>Under Construction</h2>
                                                <h4>Units available with completion in Sep ‘24</h4>
                                            </div>
                                        </div>
                                        <a href="/" className='floorplans-swiper-card-text-top-in-card-end'>
                                            <div>
                                                <h2>Request callback</h2>
                                            </div>
                                            <div>
                                                <h2><IoMdCall/></h2>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="floorplans-swiper-card">
                                    <div className="floorplans-swiper-card-text-top">
                                        <div className='floorplans-swiper-card-text-top-in'>
                                            <h2>₹ 1.29 Cr</h2>
                                        </div>
                                        <div>
                                            <div className='floorplans-swiper-card-text-top-in1'>
                                                <i><MdOutlineDesignServices /></i>
                                                <div>
                                                    <div>
                                                        <h2>₹ 1673 sq.ft. (155.43 sq.m)</h2>
                                                    </div>
                                                    <div>
                                                        <p>Super Built-up Area | 3 BHK</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-img'>
                                            <img src='/assets/images/sliderImg/H1.jpg' alt='' />
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-card'>
                                            <div className='floorplans-swiper-card-text-top-in-card-text'>
                                                <h2>Under Construction</h2>
                                                <h4>Units available with completion in Sep ‘24</h4>
                                            </div>
                                        </div>
                                        <a href="/" className='floorplans-swiper-card-text-top-in-card-end'>
                                            <div>
                                                <h2>Request callback</h2>
                                            </div>
                                            <div>
                                                <h2><IoMdCall/></h2>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="floorplans-swiper-card">
                                    <div className="floorplans-swiper-card-text-top">
                                        <div className='floorplans-swiper-card-text-top-in'>
                                            <h2>₹ 1.3 Cr</h2>
                                        </div>
                                        <div>
                                            <div className='floorplans-swiper-card-text-top-in1'>
                                                <i><MdOutlineDesignServices /></i>
                                                <div>
                                                    <div>
                                                        <h2>₹ 1687 sq.ft. (156.73 sq.m)</h2>
                                                    </div>
                                                    <div>
                                                        <p>Super Built-up Area | 3 BHK</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-img'>
                                            <img src='/assets/images/sliderImg/H1.jpg' alt='' />
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-card'>
                                            <div className='floorplans-swiper-card-text-top-in-card-text'>
                                                <h2>Under Construction</h2>
                                                <h4>Units available with completion in Sep ‘24</h4>
                                            </div>
                                        </div>
                                        <a href="/" className='floorplans-swiper-card-text-top-in-card-end'>
                                            <div>
                                                <h2>Request callback</h2>
                                            </div>
                                            <div>
                                                <h2><IoMdCall/></h2>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="floorplans-swiper-card">
                                    <div className="floorplans-swiper-card-text-top">
                                        <div className='floorplans-swiper-card-text-top-in'>
                                            <h2>₹ 1.38 Cr</h2>
                                        </div>
                                        <div>
                                            <div className='floorplans-swiper-card-text-top-in1'>
                                                <i><MdOutlineDesignServices /></i>
                                                <div>
                                                    <div>
                                                        <h2>₹ 1792 sq.ft. (166.48 sq.m)</h2>
                                                    </div>
                                                    <div>
                                                        <p>Super Built-up Area | 3 BHK</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-img'>
                                            <img src='/assets/images/sliderImg/H1.jpg' alt='' />
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-card'>
                                            <div className='floorplans-swiper-card-text-top-in-card-text'>
                                                <h2>Under Construction</h2>
                                                <h4>Units available with completion in Sep ‘24</h4>
                                            </div>
                                        </div>
                                        <a href="/" className='floorplans-swiper-card-text-top-in-card-end'>
                                            <div>
                                                <h2>Request callback</h2>
                                            </div>
                                            <div>
                                                <h2><IoMdCall/></h2>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="floorplans-swiper-card">
                                    <div className="floorplans-swiper-card-text-top">
                                        <div className='floorplans-swiper-card-text-top-in'>
                                            <h2>₹ 1.44 Cr</h2>
                                        </div>
                                        <div>
                                            <div className='floorplans-swiper-card-text-top-in1'>
                                                <i><MdOutlineDesignServices /></i>
                                                <div>
                                                    <div>
                                                        <h2>₹ 1874 sq.ft. (174.1 sq.m)</h2>
                                                    </div>
                                                    <div>
                                                        <p>Super Built-up Area | 3 BHK</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-img'>
                                            <img src='/assets/images/sliderImg/H1.jpg' alt='' />
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-card'>
                                            <div className='floorplans-swiper-card-text-top-in-card-text'>
                                                <h2>Under Construction</h2>
                                                <h4>Units available with completion in Sep ‘24</h4>
                                            </div>
                                        </div>
                                        <a href="/" className='floorplans-swiper-card-text-top-in-card-end'>
                                            <div>
                                                <h2>Request callback</h2>
                                            </div>
                                            <div>
                                                <h2><IoMdCall/></h2>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="floorplans-swiper-card">
                                    <div className="floorplans-swiper-card-text-top">
                                        <div className='floorplans-swiper-card-text-top-in'>
                                            <h2>₹ 1.66 Cr</h2>
                                        </div>
                                        <div>
                                            <div className='floorplans-swiper-card-text-top-in1'>
                                                <i><MdOutlineDesignServices /></i>
                                                <div>
                                                    <div>
                                                        <h2>₹ 2132 sq.ft. (198.07 sq.m)</h2>
                                                    </div>
                                                    <div>
                                                        <p>Super Built-up Area | 3 BHK</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-img'>
                                            <img src='/assets/images/sliderImg/H1.jpg' alt='' />
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-card'>
                                            <div className='floorplans-swiper-card-text-top-in-card-text'>
                                                <h2>Under Construction</h2>
                                                <h4>Units available with completion in Sep ‘24</h4>
                                            </div>
                                        </div>
                                        <a href="/" className='floorplans-swiper-card-text-top-in-card-end'>
                                            <div>
                                                <h2>Request callback</h2>
                                            </div>
                                            <div>
                                                <h2><IoMdCall/></h2>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="floorplans-swiper-card">
                                    <div className="floorplans-swiper-card-text-top">
                                        <div className='floorplans-swiper-card-text-top-in'>
                                            <h2>₹ 1.67 Cr</h2>
                                        </div>
                                        <div>
                                            <div className='floorplans-swiper-card-text-top-in1'>
                                                <i><MdOutlineDesignServices /></i>
                                                <div>
                                                    <div>
                                                        <h2>₹ 2150 sq.ft. (199.74 sq.m)</h2>
                                                    </div>
                                                    <div>
                                                        <p>Super Built-up Area | 3 BHK</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-img'>
                                            <img src='/assets/images/sliderImg/H1.jpg' alt='' />
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-card'>
                                            <div className='floorplans-swiper-card-text-top-in-card-text'>
                                                <h2>Under Construction</h2>
                                                <h4>Units available with completion in Sep ‘24</h4>
                                            </div>
                                        </div>
                                        <a href="/" className='floorplans-swiper-card-text-top-in-card-end'>
                                            <div>
                                                <h2>Request callback</h2>
                                            </div>
                                            <div>
                                                <h2><IoMdCall/></h2>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="floorplans-swiper-card">
                                    <div className="floorplans-swiper-card-text-top">
                                        <div className='floorplans-swiper-card-text-top-in'>
                                            <h2>₹ 1.71 Cr</h2>
                                        </div>
                                        <div>
                                            <div className='floorplans-swiper-card-text-top-in1'>
                                                <i><MdOutlineDesignServices /></i>
                                                <div>
                                                    <div>
                                                        <h2>₹ 2191 sq.ft. (203.55 sq.m)</h2>
                                                    </div>
                                                    <div>
                                                        <p>Super Built-up Area | 3 BHK</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-img'>
                                            <img src='/assets/images/sliderImg/H1.jpg' alt='' />
                                        </div>
                                        <div className='floorplans-swiper-card-text-top-in-card'>
                                            <div className='floorplans-swiper-card-text-top-in-card-text'>
                                                <h2>Under Construction</h2>
                                                <h4>Units available with completion in Sep ‘24</h4>
                                            </div>
                                        </div>
                                        <a href="/" className='floorplans-swiper-card-text-top-in-card-end'>
                                            <div>
                                                <h2>Request callback</h2>
                                            </div>
                                            <div>
                                                <h2><IoMdCall/></h2>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default FloorPlansSwiper