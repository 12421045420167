import { Switch } from "@mui/material";
import React from "react";

const Videos = () => {
  return (
    <div className="mainbarfiltercomponents">
      <div className="propertyboxes">
        <Switch defaultChecked />
        <p>Videos</p>
      </div>
    </div>
  );
};

export default Videos;
