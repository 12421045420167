import React from 'react'
import Header from '../../components/layouts/Header'
import Footer from '../../components/layouts/Footer'

const ContactUs = () => {
  return (
    <>
      <div>
        <Header />
        <div style={{ paddingLeft: "23%", marginTop: "100px", marginBottom: "24px", marginLeft: "-65%" }}>
          <a href="" style={{ fontsize: "12px", color: "#42526E" }}>Home > </a>
          <a href="" style={{ fontsize: "12px", color: "#42526E" }}>Contact Us</a>
        </div>
        <div className='contact_uscontent css-1dbjc4n'>
          <div className='css-1dbjc4n r-xovx4i r-1xfd6ze r-bijgke'>
            <div className='css-1dbjc4n r-1awozwy r-6koalj r-eqz5dr'>
              <div className='css-1dbjc4n r-1uopjct r-145lgeb r-64el8z r-1kz6sp'>
                <div className='css-1dbjc4n r-1mlwlqe r-1uopjct r-145lgeb r-64el8z r-1udh08x r-1kz6sp r-417010 safety_guide'>
                  <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                  >
                    <img src="./assets/images/all/contactUs.png" alt="" />
                  </div>
                </div>
              </div>
              <div className='css-1dbjc4n r-7sv4c2'></div>
              <h1 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-1x35g6 r-13uqrnb r-b88u0q r-37tt59 r-1v456y7' style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Contact Us
              </h1>
            </div>
          </div>
        </div>
        <div className='pageComponent contact_ustable'>
          <table className='styles__table'>
            <tr className=''>
              <th className='styles__tableHeading'>City</th>
              <th className='styles__tableHeading'>Address</th>
              <th className='styles__tableHeading'>Toll Free</th>
              <th className='styles__tableHeading'>Email</th>
            </tr>

            <tr>
              <td>Ahmedabad</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>106, Iscon Elegance, S.G. Highway,  Prahladnagar,   Ahmedabad. Pin: 380015.</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Bangalore</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India) Ltd. N – 503, North Block, Manipal Centre, Dickenson Road, Bangalore - 560042</td>
              <td>1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Chandigarh</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>106, Iscon Elegance, S.G. Highway,  Prahladnagar,   Chandigarh. Pin: 380015.</td>
              <td>91-41-99099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Chennai</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India) Ltd. SCO 224-225 ,1st Floor Sector 40D Chandigarh -160036</td>
              <td>1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Hyderabad</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge India Ltd., 6-3-1192/1/1, Office No:113 To 115, 3rd Block, 1st Floor, White House, Kundan bagh, Beside Life Style, Begumpet, Hyderabad-500016</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Indore</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>518, 5th floor Shekhar Central Palasia Square A.B. Road – Indore Madhya Pradesh – 452001</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Jaipur</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India) Ltd. 605, Crystal Palm, 22 Godam Circle, Sardar Patel Marg, Jaipur - 302001</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Coimbatore</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India ) Ltd, 1st Floor, Elysium Central, Puliakulam Road, Coimbatore - 641045</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Kolkata</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Space Ergo Brilliant, Office No: 505, 5th Floor Plot No: A1-4, Block EP & GP Sector V, Salt Lake, Kolkata – 700 091</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Mumbai (Andheri)</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India) Ltd. 127-128, B-wing, 1st Floor, Chintamani Plaza, Andheri-Kurla Road, Chakala, Opp. CineMagic Theatre. Andheri-E, Mumbai- 400099</td>
              <td>1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Nagpur</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India) Ltd., G9, 10, 11, Achraj Tower II, Chindwara Road, Sadar, Nagpur – 440013</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Gurgaon</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India) Ltd. Plot No. B-15, Upper 1st Floor, Sector 32, Gurgaon</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Lucknow</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India) Ltd. 602, 6th Floor, Eldeco Corporate Chamber-2 ,Vibhuti Khand,Gomti Nagar,Behind WAVE Cinemas, Lucknow - 226010</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>International</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Mygoldenhomes.com Info Edge(I) Limited.</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Mumbai (Navi)</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India ) Ltd. 101-102, 1st Floor, B- Wing Shelton Sapphire Plot no. 18-19, Sec 15, CBD Belapur Navi Mumbai- Maharasthra 400614</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Bhubaneswar</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India) Ltd,OFFICE SPACE, Esplanade One Unit- 302, 3rd Floor, inside ESPLANDE MALL, Rasulgarh, Bhubaneswar, Odisha 751010.</td>
              <td>1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Surat</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge India Ltd, 409, 4th Floor, Proton Plus, Above Croma, Opp. Star Bazaar, L P Savani Road, Adajan, Surat – 395007</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Vadodara</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge India Ltd. 506, Samanvay Silver, B/s Royal Orchid Central Hotel, Near Mujmahuda Circle, Akota, Vadodara – 390020</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Cochin</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>106, Iscon Elegance, S.G. Highway,  Prahladnagar,   Ahmedabad. Pin: 380015.</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Ahmedabad</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge India Limited 6th Floor, Palakat Building, Thykoodam, Vyttila Bypass Cochin – 682019</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Nashik</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India) Ltd, B-8, Kusum Pushpa Apartment, Opp. Dairy Don, College Road, Nashik-422005</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Ranchi</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India) Ltd, Shop No 108, Sumati Vihar, Opp. Adivasi Hostel, Jail Road, Ranchi – 834 001</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Trivandrum</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India ) Ltd, Retnam, TC 4/2429 (2), Vikrampuram Hills, Kuravankonam, Trivnadrum - 695003.</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Rajkot</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge India Ltd. 215, 2nd Floor, Alpha Plus, Raiya Telephone Exchange Circle, 150 Feet Ring Road, Rajkot - 360005</td>
              <td>1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Aurangabad</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge India Limited Office No. 18, Space Olympia, Altius-B Wing Nr. Sports Complex, Garkheda, Shahanoormiya Darga Road, Aurangabad (MH)-431009</td>
              <td>1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Raipur</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India) Ltd. 2nd floor Taank Tower, Beside Hotel Sharda, Jail Road, Near Fafadih Chowk, Raipur - 492001</td>
              <td>1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Bhopal</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>	Info Edge (India) Ltd. Ground Floor 2, Harrison House, 6 Malviya Nagar, Bhopal - 462003</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Pune</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Info Edge (India) Ltd. Anand Square,2nd floor , Off No - 201 & 202, New Airport Road, Opposite Symbiosis International School, Viman Nagar, PUNE - 411014</td>
              <td>91-1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>

            <tr>
              <td>Patna</td>
              <td style={{ color: "rgb(66, 82, 110)" }}>Kaushalya Estate, Block A | Unit no. 404, 4th Floor | Bandar Bagicha Zone-5 | P.S. Kotwali Mauza - Ahrra,| Patna (Bihar) 800001</td>
              <td>1800-4199099</td>
              <td><a href="" className='styles__hyperText'>services@Mygoldenhomes.com</a></td>
            </tr>
          </table>
          <div className='css-1dbjc4n r-eu3ka'></div>
          <div className='css-1dbjc4n contact_usbottom'>
            <div className='css-1dbjc4n r-11c0sde contact_usimg'>
              <img src="./assets/images/all/contactUs.png" alt="" />
              <h4 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-b88u0q r-vrz42v contact_usimgtext'>Get in touch?</h4>
            </div>
            <div className='styles__getInTouchText'>
              Connect with us through email, phone or fax, or at following branch office office address:
            </div>
          </div>
          <div className='css-1dbjc4n r-eu3ka'></div>
          <div className='styles__contactBox'>
            <div className='styles_boxin styles_boxincontact'>
              <img src="./assets/images/all/callIcon.png" alt="" />
              <div style={{ width: "90%" }}>
                <p className='styles__hyperText'><a>1800 41 99099</a></p>
                <p className='styles__Text'>(Toll Free)</p>
              </div>
            </div>
            <div className='boxin_group'>
              <div className='styles_boxin boxnin2_styles'>
                <img src="./assets/images/all/mailIcon.png" alt="" />
                <div style={{ width: "90%" }}>
                  <p className='styles__hyperText'><a>services@mygoldenhomes.com</a></p>
                  <p className='styles__Text'>(Sales & General Enquire)</p>
                </div>
              </div>
              <div className='styles_boxin boxnin3_styles'>
                <img src="./assets/images/all/sendQuery.png" alt="" />
                <div style={{ width: "90%" }}>
                  <p className='styles__hyperText'><a>Send us your Query</a></p>
                  <p className='styles__Text'>(Get information about Mygoldenhomes)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default ContactUs