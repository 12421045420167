import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Unique = (props) => {
  const { PropertyProfile, PriceotherDetails, BasicDetails } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [expectedPrice, setExpectedPrice] = useState(props.expectedPrice);
  const [pricePerSqft, setPricePerSqft] = useState(0);
  const [priceNegotiable, setPriceNegotiable] = useState(false);

  const [uniquePropertyText, setUniquePropertyText] = useState("");
  const [uniquePropertyTextUse, setUniquePropertyTextUse] = useState("");
  //errors
  const [propertyUniqueError, setPropertyUniqueError] = useState("");

  useEffect(() => {
    if (Number(expectedPrice) > 0) {
      let value = Number(expectedPrice) / Number(PropertyProfile.carpetArea);

      value = Math.round(value);
      setPricePerSqft(value);
    }
  }, [PropertyProfile, expectedPrice]);

  useEffect(() => {
    if (PriceotherDetails !== "") {
      setExpectedPrice(PriceotherDetails.expectedPrice);
      setPricePerSqft(PriceotherDetails.pricePerSqft);

      setUniquePropertyText(PriceotherDetails.uniquePropertyText);
      setUniquePropertyTextUse(PriceotherDetails.uniquePropertyTextUse);
    }
  }, [PriceotherDetails]);

  useEffect(() => {
    if (uniquePropertyText.length >= 30) {
      setPropertyUniqueError("");
    }
  }, [uniquePropertyText]);

  const reviewChange = (e) => {
    const value = e.target.value.trim();
    setUniquePropertyText(value);
    props.handlesetUniqueTextArea(value);
  };

  const setUnicClick = (e) => {
    e.preventDefault();
   
    let unicText = "";
    if (props.expectedRent !== "") {
      unicText = `Exquisite property featuring contemporary design, luxurious amenities, and breathtaking views. Sale price is INR ${props.expectedRent}/-.`;
    }
    if (props.expectedRent !== "" && props.expectedRent === true) {
      unicText = `Exquisite property featuring contemporary design, luxurious amenities, and breathtaking views. Sale price is INR ${props.expectedRent}/- and is negotiable.`;
    }
    unicText = unicText.trim();
    setUniquePropertyText(unicText);
    props.handlesetUniqueTextArea(unicText);
  };

  return (
    <>
      <div className="Description-widget Pricing-widget">
        <div
          className="widgetbox_white_box__1v7xz undefined undefined undefined"
          style={{ textAlign: "left" }}
        >
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span
                      className="xidHeading_headingText__Ulc2a"
                      style={{ display: "inline" }}
                    >
                      What makes your property unique
                    </span>
                    <div className="screeningActions_iconWrapper__dB1NM"></div>
                  </div>
                </div>
              </h4>
            </div>
            <p style={{ color: "red" }}>{props.propertyUniqueError}</p>
          </div>
          <div className="propertyDescription_left_icon_box__10lcL">
            <p className="caption_subdued_medium">
              Adding description will increase your listing visibility
              <span
                className="propertyDescription_review__wfOOm hyperlinks_small pageComponet"
                onClick={(e) => {
                  setUnicClick(e);
                }}
                style={{ cursor: "pointer", marginTop: "5px" }}
              >
                Review
              </span>
            </p>
          </div>
          <div className="propertyDescription_description_box__2ID5j  pageComponent">
            <textarea
              name=""
              id="description-input"
              cols="30"
              rows="10"
              minLength={30}
              maxLength={5000}
              requiredminLength="30"
              requiredmaxLength="5000"
              value={uniquePropertyText}
              onChange={(e) => reviewChange(e)}
              style={{ textTransform: "capitalize" }}
              placeholder="Share some details about your property like spacious rooms, well maintained facilities.."
            ></textarea>
            <div className="propertyDescription_counterText__2A8CV badges_large_secondary">
              {/* <div>input_placeholder_inactive</div> */}
              <span className="undefined">
                {uniquePropertyText.length}/5000 ( Min. 30 characters )
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Unique;
