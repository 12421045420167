
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const MinimumAgreementDurationTemp = (props) => {
  const { PropertyProfile, PriceotherDetails,singleProperty } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [maintenanceMonthlyduration, setMaintenanceMonthlyDuration] =
    useState(singleProperty.durationAgreementPg);
  const [maintenanceMonthlyDurationShow, setMaintenanceMonthlyDurationShow] =
    useState("");


  const monthdurationarray = [
    { name: "0" },
    { name: "1" },
    { name: "2" },
    { name: "3" },
    { name: "4" },
    { name: "5" },
    { name: "6" },
    { name: "7" },
    { name: "8" },
    { name: "9" },
    { name: "10" },
    { name: "11" },
    { name: "12" },
    { name: "13" },
    { name: "14" },
    { name: "15" },
    { name: "16" },
    { name: "17" },
    { name: "18" },
    { name: "19" },
    { name: "20" },
    { name: "21" },
    { name: "22" },
    { name: "23" },
    { name: "24" },
    { name: "25" },
  ];

  return (
    <>
      <div className="LockIn-widget" style={{ marginTop: "10px" }}>
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div
            className="widgetbox_header_text__2BXc_"
            style={{ marginBottom: "-20px" }}
          >
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Minimum Agreement Duration
                  </span>
                </div>
              </h4>
            </div>
          </div>
        </div>
        <div className="false">
          <div id="floorNum-input">
            <div>
              <div id="maintenanceCriteria-input" className="pageComponent">
                <div>
                  <div
                    className="selectInput_custom_input_wrapper__MqLE9 selectInput_multiple_input__1oijd  pageComponent"
                    onClick={() => {
                      setMaintenanceMonthlyDurationShow(
                        !maintenanceMonthlyDurationShow
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                    <div
                      className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf"
                      style={{ border: "none" }}
                    >
                      {maintenanceMonthlyduration}
                    </div>
                    <div className="selectInput_iconsWrapper__1L2kR">
                      <span className="icon_downArrow">
                        <img src="/assets/images/all/arrow_down.png" alt="" />
                      </span>
                      <div className="screeningActions_iconWrapper__dB1NM"></div>
                    </div>
                  </div>
                  {maintenanceMonthlyDurationShow === true ? (
                    <>
                      <div className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown">
                        {monthdurationarray.map((mentaina, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              props.getDurationAgreement(mentaina.name);
                              setMaintenanceMonthlyDuration(mentaina.name);
                              setMaintenanceMonthlyDurationShow(false);
                            }}
                          >
                            <span>{mentaina.name}</span>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MinimumAgreementDurationTemp;
