import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { userViewPropertyPost } from "../../../redux/userViewdProperty/UserViewdPropertySlice";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropertyAdvertiserModel from "./PropertyAdvertiserModel";
import { toast } from "react-toastify";
import { userUpdateById } from "../../../redux/athentication/Athentication";
import { propertyUpdate } from "../../../redux/property/PropertySlice";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { FcApproval, FcSms } from "react-icons/fc";

const PropertyDetailsModel = ({ propertyprop, closePropdet, changeView }) => {
  const { loginData } = useSelector((store) => store.Athentication);

  const dispatch = useDispatch();

  const [advertiserModel, setAdvertiserModel] = useState(false);
  const toggle = () => setAdvertiserModel(!advertiserModel);

  const [invest, setInvest] = useState(false);
  const [business, setBusiness] = useState(false);
  const [reasontobuy, setReasontobuy] = useState("");

  const [dealer, setDealer] = useState(false);

  const [threeMonth, setThreeMonth] = useState(false);
  const [sixmonth, setSixmonth] = useState(false);
  const [morethanSixmonth, setMorethanSixmonth] = useState(false);
  const [planingtobuy, setPlaningtobuy] = useState("");

  const [homeLoan, sethomeLoan] = useState(false);
  const [siteVisit, setSiteVisit] = useState(false);
  const [agreeToMgh, setAgreeToMgh] = useState(false);
  const [alredyViewed, setAlredyViewed] = useState(true);
  const [tempLoading, setTempLoading] = useState(true);
  const [singleProperty, setSingleProperty] = useState(propertyprop);

  useEffect(() => {
    const viewResponseClick = async () => {
      const formVerifyData = {
        propertyId: propertyprop._id,
        userId: loginData._id,
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const verifyurl = `${Baseurl}/api/v1/viewed/viewedpropertyverifybyuser`;
      const propertyVerify = await axios.post(
        verifyurl,
        formVerifyData,
        config
      );
      if (propertyVerify.data.success === true) {
        if (propertyVerify.data.userViewedProperty.length > 0) {
          setAlredyViewed(false);
          setTempLoading(false);
        } else {
          setTempLoading(false);
        }
      } else {
        setTempLoading(false);
      }
    };
    viewResponseClick();
  }, [propertyprop]);

  const viewAdvertiserClick = () => {
    const formdata = {
      userId: loginData._id,
      userName: loginData.name,
      userMobile: loginData.mobile,
      userOwnerShip: loginData.role,
      userEmail: loginData.email,
      postUserPackage: loginData.packageType,
      propertyId: singleProperty._id,
      visiblePropertyId: singleProperty.propertyID,
      propertyName: singleProperty.apartment,
      noofBedrooms: singleProperty.noofBedrooms,
      price: singleProperty.visiblePrice,
      locality: singleProperty.locality,
      city: singleProperty.city,
      state: singleProperty.state,
      propertyStatus: singleProperty.propertyStatus,
      noofviews: 0,
      category: singleProperty.category,
      subcategory: singleProperty.subCategory,
      postUserId: singleProperty.userId,
      postUserName: singleProperty.postUserName,
      postUserEmail: singleProperty.postUserEmail,
      postUserMobile: singleProperty.postUserMobile,
      yourReason: reasontobuy,
      areYouaDealer: dealer,
      planingforBuy: planingtobuy,
      homeloan: homeLoan,
      sitevisit: siteVisit,
      agreeToMgh: agreeToMgh,
    };
    const advertiserData = dispatch(userViewPropertyPost(formdata));
    changeView();
    toast.success("Advertiser Details Posted Successfully", {
      position: "top-center",
      autoClose: 1000,
    });
    closePropdet();

    const propertyFormdata = {
      propertyId: singleProperty._id,
      noofPropertyViewed: singleProperty.noofPropertyViewed + 1,
    };
    dispatch(propertyUpdate(propertyFormdata));

    const userUpdate = {
      userId: loginData._id,
      creadits: loginData.creadits - 1,
    };
    dispatch(userUpdateById(userUpdate));
    setAdvertiserModel(true);
  };

  return (
    <>
      {tempLoading ? (
        <></>
      ) : (
        <>
          <div>
            <div className="head-one">
              <h4>
                Please fill in your details to be shared with this advertiser
                only.
              </h4>
            </div>

            <div className="model-container-main main-top col-10">
              <div className="model-container-inner col-5">
                <h3 className="main-head-posted">POSTED BY OWNER :</h3>
                <div className="model-container-inner1">
                  {alredyViewed === false ? (
                    <>
                      <h4>
                        +91 {singleProperty.postUserMobile} |
                        {singleProperty.postUserEmail}
                      </h4>
                    </>
                  ) : (
                    <>
                      <h4>
                        +91 xxxxxxx{singleProperty.postUserMobile.slice(7)} |{" "}
                        xxxxxxxxxx{singleProperty.postUserEmail.slice(7)}
                      </h4>
                    </>
                  )}

                  <p>{singleProperty.postUserName}</p>
                </div>
              </div>
              <div className="model-container-inner modelinner-sub col-5">
                <h3 className="main-head-posted">
                  POSTED ON{" "}
                  {moment(singleProperty.createdAt).format("DD MMMM YYYY")} :
                </h3>

                <div className="model-container-inner1">
                  <h4>
                    ₹ {singleProperty.visiblePrice} | {singleProperty.apartment}
                  </h4>
                  <p>
                    {singleProperty.carpetArea} SQ.FT. |{" "}
                    {singleProperty.availabilityStatus}
                  </p>
                </div>
              </div>
            </div>

            <div className="head-two">
              <h4>
                Please fill in your details to be shared with this advertiser
                only.
              </h4>
            </div>

            <div className="model-container-main main-bottom col-10">
              <div className="model-container-inner-one col-5">
                <h3 className="main-head-posted">BASIC INFORMATION :</h3>
                <div className="model-container-inner2">
                  <div className="inner2-1">
                    <label htmlFor="">Your reason to buy is</label>
                    <span>
                      <input
                        type="radio"
                        name="Invest"
                        id="FirstInvest"
                        value={invest}
                        onChange={() => {
                          setInvest(!invest);
                          if (invest === false) {
                            setReasontobuy("Investment");
                            setBusiness(false);
                          }
                        }}
                      />
                      Investment
                    </span>
                    <span>
                      <input
                        type="radio"
                        name="Invest"
                        id="SecInvest"
                        value={business}
                        onChange={() => {
                          setBusiness(!business);
                          if (business === false) {
                            setReasontobuy("Own Business Use");
                            setInvest(false);
                          }
                        }}
                      />
                      Own Business Use
                    </span>
                  </div>

                  <div className="inner2-2">
                    <label htmlFor="">Are you a property dealer</label>
                    <span>
                      <input
                        type="radio"
                        name="dealer"
                        id="FirstDealer"
                        value={dealer}
                        onChange={() => {
                          setDealer(!dealer);
                        }}
                      />
                      Yes
                    </span>
                    <span>
                      <input
                        type="radio"
                        name="dealer"
                        id="SecDealer"
                        value={dealer}
                        onChange={() => {
                          setDealer(!dealer);
                        }}
                      />
                      No
                    </span>
                  </div>
                  <div className="inner2-3">
                    <div className="form-inputs">
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-contr"
                        value={loginData.name}
                        readOnly
                      />
                    </div>
                    <div className="form-inputs">
                      <a>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option className="main-option" selected>
                            +91
                          </option>
                        </select>
                      </a>
                      <input
                        type="text"
                        placeholder="Mobile number"
                        className="form-control"
                        id="quantity"
                        min={1}
                        max={10}
                        readOnly
                        value={loginData.mobile}
                      />
                    </div>
                    <div className="form-inputs">
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        readOnly
                        value={loginData.email}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="model-container-inner-one col-5">
                <h3 className="main-head-posted">OPTIONAL INFORMATION :</h3>
                <div className="model-container-inner2">
                  <div className="model-container-inner21">
                    <label>
                      {" "}
                      By when you are planning to buy the property?
                    </label>
                    <div className="form-radios">
                      <span>
                        <input
                          type="radio"
                          name="buyproperty"
                          id="firstbuy"
                          value={threeMonth}
                          onChange={() => {
                            setThreeMonth(!threeMonth);
                            if (threeMonth === false) {
                              setPlaningtobuy("3 months");
                              setSixmonth(false);
                              setMorethanSixmonth(false);
                            }
                          }}
                        />
                        3 months
                      </span>
                      <span>
                        <input
                          type="radio"
                          name="buyproperty"
                          id="secbuy"
                          value={sixmonth}
                          onChange={() => {
                            setSixmonth(!sixmonth);
                            if (sixmonth === false) {
                              setPlaningtobuy("6 months");
                              setThreeMonth(false);
                              setMorethanSixmonth(false);
                            }
                          }}
                        />
                        6 months
                      </span>
                      <span>
                        <input
                          type="radio"
                          name="buyproperty"
                          id="thirdbuy"
                          value={morethanSixmonth}
                          onChange={() => {
                            setMorethanSixmonth(!morethanSixmonth);
                            if (morethanSixmonth === false) {
                              setPlaningtobuy("More than 6 months");
                              setThreeMonth(false);
                              setSixmonth(false);
                            }
                          }}
                        />
                        More than 6 months
                      </span>
                    </div>
                  </div>
                  <div className="model-container-inner22">
                    <div>
                      <input
                        type="checkbox"
                        value={homeLoan}
                        onChange={() => {
                          sethomeLoan(!homeLoan);
                        }}
                      />
                      I am interested in home loan
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        value={siteVisit}
                        onChange={() => {
                          setSiteVisit(!siteVisit);
                        }}
                      />
                      I am interested in site visits.
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        value={agreeToMgh}
                        onChange={() => {
                          setAgreeToMgh(!agreeToMgh);
                        }}
                      />
                      I agree to be contacted by My Golden Homes for<br></br>
                      similar properties or related services via WhatsApp,
                      <br></br>
                      phone(overriding NDNC registration), sms, e-mail etc.
                    </div>
                  </div>
                  {alredyViewed === false ? (
                    <></>
                  ) : (
                    <>
                      <div className="model-container-inner25">
                        <button
                          className="inner25-button"
                          onClick={(e) => viewAdvertiserClick(e)}
                        >
                          View Advertiser Details
                        </button>
                        <p>
                          By submitting I accept My Golden Homes Terms and
                          Conditions
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Modal
              isOpen={advertiserModel}
              toggle={() => setAdvertiserModel(true)}
              className="modalmain"
              style={{}}
            >
              <div className="model-wrapper">
                <ModalHeader
                  toggle={() => setAdvertiserModel(false)}
                ></ModalHeader>
                <ModalBody>
                  <PropertyAdvertiserModel />
                </ModalBody>
              </div>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default PropertyDetailsModel;
