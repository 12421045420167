import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

const AddroomHospital = (props) => {
  const { PropertyProfile, BasicDetails } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();

  const [noofrooms, setNoofrooms] = useState("");
  const [addmoreBedroomsvalue, setAddmoreBedroomsValue] = useState("");
  const [addmoreBedroomsShow, setAddmoreBedroomsShow] = useState(false);

  const [noofBathrooms, setNoofBathrooms] = useState("");
  const [addmoreBathroomsvalue, setAddmoreBathroomsValue] = useState("");
  const [addmoreBathroomsShow, setAddmoreBathroomsShow] = useState(false);

  useEffect(() => {
    if (PropertyProfile !== "") {
      setNoofrooms(PropertyProfile.noofrooms);
      setAddmoreBedroomsValue(PropertyProfile.addmoreBedroomsvalue);
      setAddmoreBedroomsShow(PropertyProfile.addmoreBedroomsShow);

      setNoofBathrooms(PropertyProfile.noofBathrooms);
      setAddmoreBathroomsValue(PropertyProfile.addmoreBathroomsvalue);
      setAddmoreBathroomsShow(PropertyProfile.addmoreBathroomsShow);
    }
  }, [PropertyProfile]);

  return (
    <>
      <div className="widgetbox_white_box__1v7xz undefined false false">
        <div className="widgetbox_header_text__2BXc_">
          <div className="xidHeading_PageHeadingwrap__3z0kL">
            <h4>
              <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                <div>
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Add Room Details
                  </span>
                </div>
              </div>
            </h4>
          </div>
          <p style={{ color: "red", fontSize: "12px" }}>
            {props.roomDetailsError}
          </p>
        </div>
        <div className="false">
          <div
            id="bedroomNum-input"
            className="pageComponent"
            style={{ textAlign: "left" }}
          >
            <span className="labels_semiBold radioInput_bubbleLable__1g8PH">
              No. of Rooms
              <div className="screeningActions_iconWrapper__dB1NM"></div>
            </span>
            <p style={{ color: "red", fontSize: "12px" }}>
              {props.noofRoomsError}
            </p>
            <div
              className="tagWrapper_tags_wrapper__KIRJJ  multiple_input"
              id="bedroomNum"
              style={{ marginTop: "8px" }}
            >
              <div
                className={
                  noofrooms === "1"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                id="1"
                onClick={() => {
                  setNoofrooms("1");
                  props.numberOfRooms("1");
                  setAddmoreBedroomsValue("");
                  props.addMoreBedroomValue("");
                }}
              >
                <span>1</span>
              </div>
              <div
                className={
                  noofrooms === "2"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                id="2"
                onClick={() => {
                  setNoofrooms("2");
                  props.numberOfRooms("2");
                  setAddmoreBedroomsValue("");
                  props.addMoreBedroomValue("");
                }}
              >
                <span>2</span>
              </div>
              <div
                className={
                  noofrooms === "3"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                id="3"
                onClick={() => {
                  setNoofrooms("3");
                  props.numberOfRooms("3");
                  setAddmoreBedroomsValue("");
                  props.addMoreBedroomValue("");
                }}
              >
                <span>3</span>
              </div>
              <div
                className={
                  noofrooms === "4"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                id="4"
                onClick={() => {
                  setNoofrooms("4");
                  props.numberOfRooms("4");
                  setAddmoreBedroomsValue("");
                  props.addMoreBedroomValue("");
                }}
              >
                <span>4</span>
              </div>
              {noofrooms > 4 ? (
                <>
                  <div
                    className={
                      noofrooms > 4
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL "
                    }
                    id="5"
                    onClick={() => {
                      setAddmoreBedroomsShow(true);
                      props.addMoreBedroomShow(true);
                    }}
                  >
                    <span>{noofrooms}</span>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div
                className="hyperlinks_medium AddOther_addOtherLink__3jZ8s"
                style={{ display: "block" }}
              >
                {addmoreBedroomsShow === false &&
                addmoreBedroomsvalue === "" ? (
                  <>
                    <i
                      className="icon_bluePlusIcon"
                      style={{ verticalAlign: "middle" }}
                    >
                      <BiPlus />
                    </i>
                    <span
                      className="AddOther_toggleLabel__YwU_k"
                      onClick={() => {
                        setAddmoreBedroomsShow(true);
                        props.addMoreBedroomShow(true);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Add other
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {addmoreBedroomsShow === true ? (
                <>
                  {" "}
                  <div className="AddOther_inputWrap__3J562">
                    <div className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined">
                      <div>
                        <input
                          type="text"
                          className="pageComponenet"
                          style={{
                            width: "60%",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                          value={addmoreBedroomsvalue}
                          onChange={(e) => {
                            setAddmoreBedroomsValue(
                              e.target.value.replace(/\D/g, "")
                            );
                            props.addMoreBedroomValue(
                              e.target.value.replace(/\D/g, "")
                            );
                          }}
                        />
                        <div></div>
                        <div className="textInput_placeholder__1KRsF caption_strong_large textInput_placeholderWithoutError__3FjSB">
                          <span className="undefined"></span>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="buttons_primaryRegular__2swW5 buttons_semi__32O7H undefined"
                        onClick={() => {
                          setAddmoreBedroomsShow(false);
                          props.addMoreBedroomShow(false);
                          setNoofrooms(addmoreBedroomsvalue);
                          props.numberOfRooms(addmoreBedroomsvalue);
                        }}
                      >
                        <span>
                          <span>Done</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            id="bathroomNum-input"
            className="pageComponent"
            style={{ textAlign: "left" }}
          >
            <span className="labels_semiBold radioInput_bubbleLable__1g8PH">
              No. of Washrooms
              <div className="screeningActions_iconWrapper__dB1NM"></div>
            </span>
            <p style={{ color: "red", fontSize: "12px" }}>
              {props.noofWashroomsError}
            </p>
            <div
              className="tagWrapper_tags_wrapper__KIRJJ  multiple_input"
              id="bedroomNum"
              style={{ marginTop: "8px" }}
            >
              <div
                id="1"
                className={
                  noofBathrooms === "1"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofBathrooms("1");
                  props.numberOfBathroom("1");
                  setAddmoreBathroomsValue("");
                  props.addMoreBathroomValue("");
                }}
              >
                <span>1</span>
              </div>
              <div
                className={
                  noofBathrooms === "2"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofBathrooms("2");
                  props.numberOfBathroom("2");
                  setAddmoreBathroomsValue("");
                  props.addMoreBathroomValue("");
                }}
                id="2"
              >
                <span>2</span>
              </div>
              <div
                className={
                  noofBathrooms === "3"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofBathrooms("3");
                  props.numberOfBathroom("3");
                  setAddmoreBathroomsValue("");
                  props.addMoreBathroomValue("");
                }}
                id="3"
              >
                <span>3</span>
              </div>
              <div
                className={
                  noofBathrooms === "4"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofBathrooms("4");
                  props.numberOfBathroom("4");
                  setAddmoreBathroomsValue("");
                  props.addMoreBathroomValue("");
                }}
                id="4"
              >
                <span>4</span>
              </div>
              {noofBathrooms > 4 ? (
                <>
                  <div
                    className={
                      noofBathrooms > 4
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL "
                    }
                    id="5"
                    onClick={() => {
                      setAddmoreBathroomsShow(true);
                      props.addMoreBathroomShow(true);
                    }}
                  >
                    <span>{noofBathrooms}</span>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div
                className="hyperlinks_medium AddOther_addOtherLink__3jZ8s"
                style={{ display: "block" }}
              >
                {addmoreBathroomsShow === false &&
                addmoreBathroomsvalue === "" ? (
                  <>
                    <i
                      className="icon_bluePlusIcon"
                      style={{ verticalAlign: "middle" }}
                    >
                      <BiPlus />
                    </i>
                    <span
                      className="AddOther_toggleLabel__YwU_k"
                      onClick={() => {
                        setAddmoreBathroomsShow(true);
                        props.addMoreBathroomShow(true);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Add other
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {addmoreBathroomsShow === true ? (
                <>
                  {" "}
                  <div className="AddOther_inputWrap__3J562">
                    <div className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined">
                      <div>
                        <input
                          type="text"
                          className="pageComponenet"
                          style={{
                            width: "60%",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                          value={addmoreBathroomsvalue}
                          onChange={(e) => {
                            setAddmoreBathroomsValue(
                              e.target.value.replace(/\D/g, "")
                            );
                            props.addMoreBathroomValue(
                              e.target.value.replace(/\D/g, "")
                            );
                          }}
                        />
                        <div></div>
                        <div className="textInput_placeholder__1KRsF caption_strong_large textInput_placeholderWithoutError__3FjSB">
                          <span className="undefined"></span>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="buttons_primaryRegular__2swW5 buttons_semi__32O7H undefined"
                        onClick={() => {
                          setAddmoreBathroomsShow(false);
                          props.addMoreBathroomShow(false);
                          setNoofBathrooms(addmoreBathroomsvalue);
                          props.numberOfBathroom(addmoreBathroomsvalue);
                        }}
                      >
                        <span>
                          <span>Done</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddroomHospital;
