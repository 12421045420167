import React from "react";
import { Table } from "react-bootstrap";
const Subregistryrecord = () => {
  // const products = [
  //   {
  //     RegisterDate: "10 FEB 2023",
  //     AgrementPrices: "Rs. 1.5 Cr",
  //     Area: "3646 (super b..",
  //     Prices: "Rs. 4113",
  //     FloorNo: "-",
  //     Tower: "-",
  //     Purchase: "-",
  //   },
  //   {
  //     RegisterDate: "11 MAY 2022",
  //     AgrementPrices: "Rs. 1.75 Cr",
  //     Area: "3446 (super b..",
  //     Prices: "Rs. 5432",
  //     FloorNo: "-",
  //     Tower: "-",
  //     Purchase: "-",
  //   },
  //   {
  //     RegisterDate: "12 MAY 2022",
  //     AgrementPrices: "Rs. 1.65 Cr",
  //     Area: "3376 (super b..",
  //     Prices: "Rs. 7543",
  //     FloorNo: "-",
  //     Tower: "-",
  //     Purchase: "-",
  //   },
  // ];
  return (
    <div>
      <div className="container">
        <div className="row" style={{ paddingTop: "15px" }}>
          <div className="col-lg-9">
            <h1 style={{ fontSize: "25px", fontWeight: "bold" }}>
              Transation Details
            </h1>
          </div>
          <div className="col-lg-3">
            <p>Data Source: State Government</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="main-table">
          {/* <table className="table table-bordered">
            <thead>
              <tr>
                <th>RegisterDate</th>
                <th>AgrementPrices</th>
                <th>Area(sq.ft)</th>
                <th>Prices sq.ft</th>
                <th>Floor No</th>
                <th>Tower/Wing</th>
                <th>Purchase type</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={index}>
                  <td>{product.RegisterDate}</td>
                  <td>{product.AgrementPrices}</td>
                  <td>{product.Area}</td>
                  <td>{product.Prices}</td>
                  <td>{product.FloorNo}</td>
                  <td>{product.Tower}</td>
                  <td>{product.Purchase}</td>
                </tr>
              ))}
            </tbody>
          </table> */}
          {/* <Table bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </Table> */}
          <table>
            <tr>
              <th>Registry Date</th>
              <th>Agreement Price</th>
              <th>Area(sq.ft)</th>
              <th>Price/sq.ft</th>
              <th>Floor No.</th>
              <th>Tower /Wing</th>
              <th>Purchase Type</th>
            </tr>
            <tr>
              <td>10 FEB 2024</td>
              <td>Rs. 1.5 Cr</td>
              <td>3646 (super b..)</td>
              <td>Rs. 4123</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>10 FEB 2024</td>
              <td>Rs. 1.5 Cr</td>
              <td>3646 (super b..)</td>
              <td>Rs. 4123</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>10 FEB 2024</td>
              <td>Rs. 1.5 Cr</td>
              <td>3646 (super b..)</td>
              <td>Rs. 4123</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Subregistryrecord;
