import React, { useEffect, useState } from "react";
import { FcAreaChart } from "react-icons/fc";
import { FcDataConfiguration } from "react-icons/fc";
import { ImPriceTags } from "react-icons/im";
import { FcAddressBook } from "react-icons/fc";
import { SiVirustotal } from "react-icons/si";
import { BsBorderTop, BsBrowserSafari } from "react-icons/bs";
import { BsInfoSquareFill } from "react-icons/bs";
import { AiFillPropertySafety } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import { RiGraduationCapFill } from "react-icons/ri";
import { RiBankCardFill } from "react-icons/ri";
import { FaFirstAid } from "react-icons/fa";
import { RiFirstAidKitFill } from "react-icons/ri";
import { CiBank } from "react-icons/ci";
import { FaTrain } from "react-icons/fa";
import { AiOutlineTrademarkCircle } from "react-icons/ai";
import { IoIosCheckmark } from "react-icons/io";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { GiWaterSplash } from "react-icons/gi";
import { FcCollect } from "react-icons/fc";
import { TbAirConditioning } from "react-icons/tb";
import { GiCelebrationFire } from "react-icons/gi";
import { FcEngineering } from "react-icons/fc";
import { FcCloseUpMode } from "react-icons/fc";
import { FcShop } from "react-icons/fc";
import { FcSupport } from "react-icons/fc";
import { FcAndroidOs } from "react-icons/fc";
import { FcLibrary } from "react-icons/fc";
import { FcReuse } from "react-icons/fc";
import { FcNfcSign } from "react-icons/fc";
import { FcInTransit } from "react-icons/fc";
import { FcLandscape } from "react-icons/fc";
import { FcFilm } from "react-icons/fc";
import { FcElectricalThreshold } from "react-icons/fc";
import { FcManager } from "react-icons/fc";
import { FcMindMap } from "react-icons/fc";
import moment from "moment/moment";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import { Navigation, Autoplay } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { PiImagesSquare } from "react-icons/pi";

const OfficeOverview = (props) => {
  const navigate = useNavigate();

  const [property, setProperty] = useState("");
  const [propertyRule, setPropertyRule] = useState("");
  const [propertyIncludePrice, setPropertyIncludePrice] = useState("");
  const [postMobile, setPostMobile] = useState("");
  const [propertyImagesWithName, setPropertyImagesWithName] = useState([]);

  useEffect(() => {
    const singleProperty = props.singlProperty;
    setProperty(singleProperty);
    setPropertyRule(props.propertyRule);
    setPropertyIncludePrice(props.propertyIncludePrice);
    setPostMobile(singleProperty.postUserMobile);
    setPropertyImagesWithName(singleProperty.titleImg);
  }, [props.singlProperty, props.propertyRule]);

  const propertyImageClick = () => {
    navigate("/property-image", {
      state: { propertyImages: props.propertyImages },
    });
  };

  return (
    <div style={{ backgroundColor: "#f5f7fa" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              navigation={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Autoplay]}
              className="mySwiper rounded"
            >
              {props.propertyImages.length > 0 ? (
                <>
                  {props.propertyImages.map((img, index) => (
                    <SwiperSlide key={index}>
                      <img src={img} class="d-block w-100 img-round" alt="" />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "30px",
                        }}
                        onClick={(e) => propertyImageClick(e)}
                      >
                        <PiImagesSquare
                          style={{ fontSize: "35px", color: "#fff" }}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                <>
                  <SwiperSlide>
                    <img src="/assets/images/noimage1.png" alt="" />
                  </SwiperSlide>
                </>
              )}
            </Swiper>
          </div>

          <div className="col-lg-6 row overview-banner2">
            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <FcAreaChart
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Area</p>
                </div>
                <strong>
                  Carpet Area :{" "}
                  {property.carpetArea + " " + property.carpetAreaMesurmentType}
                </strong>
              </div>
            </div>
            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <ImPriceTags
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Price</p>
                </div>
                {property.pricePerSqft === 0 ? (
                  <>
                    <strong>₹ {property.visiblePrice}</strong>
                  </>
                ) : (
                  <>
                    <strong>
                      ₹ {property.visiblePrice}
                      {" " + property.pricePerSqft}per{" "}
                      {property.carpetAreaMesurmentType}
                    </strong>
                  </>
                )}
              </div>
            </div>
            {property.category === "Lease" ? (
              <>
                <div className="col-lg-6 banner2-div">
                  <div style={{ textAlign: "left" }}>
                    <div className="col banner2">
                      <span>
                        <SiVirustotal
                          style={{ fontSize: "30px", paddingRight: "5px" }}
                        />
                      </span>
                      <p>Floor Number</p>
                    </div>
                    <strong>
                      {property.propertyOnFloor + " of " + property.noofFloor}{" "}
                      Floors
                    </strong>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <FcAddressBook
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Address</p>
                </div>
                <strong>
                  {property.apartment +
                    ", " +
                    property.locality +
                    ", " +
                    property.city +
                    ", " +
                    property.state}
                </strong>
              </div>
            </div>
            {property.availabilityStatus === "Under Construction" ? (
              <>
                <div className="col-lg-6 banner2-div">
                  <div style={{ textAlign: "left" }}>
                    <div className="col banner2">
                      <span>
                        <BsInfoSquareFill
                          style={{ fontSize: "30px", paddingRight: "5px" }}
                        />
                      </span>
                      <p>Possession in</p>
                    </div>
                    <strong>{property.propertyPossessionBy}</strong>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-6 banner2-div">
                  <div style={{ textAlign: "left" }}>
                    <div className="col banner2">
                      <span>
                        <BsInfoSquareFill
                          style={{ fontSize: "30px", paddingRight: "5px" }}
                        />
                      </span>
                      <p>Ready to Move</p>
                    </div>
                    <strong>{property.propertyAge}</strong>
                  </div>
                </div>
              </>
            )}

            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <BsInfoSquareFill
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Posted By</p>
                </div>
                <strong>{property.postUserPosition}</strong>
              </div>
            </div>
            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <BsBrowserSafari
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Posted On</p>
                </div>
                <strong>
                  {moment(property.createdAt).format("DD MMMM YYYY")}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{
          backgroundColor: "#f5f7fa",
          marginTop: "40px",
          padding: "20px 0px",
          borderTop: "1px solid #ddd",
          paddingTop: "20px",
        }}
      >
        <div className="row">
          <div className="col-lg-3 textalign">
            <div className="">
              <div style={{ paddingBottom: "7px" }}>
                Minimum Seats : <b>{property.minNoOfSeats}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Maximum Seats : <b>{property.maxNoofSeats}</b>
              </div>
              <div style={{ paddingBottom: "5px" }}>
                Number of Cabin : <b>{property.noofCabins}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Meeting Rooms : <b>{property.noofMeatingRooms}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Modern Lifts : <b>{property.modernLift ? "Yes" : "No"}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                NOC Certified : <b>{property.nocCommercial ? "Yes" : "No"}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 textalign">
            <div className="">
              <div style={{ paddingBottom: "7px" }}>
                WashRoom Avalible :{" "}
                <b>{property.washRoomAvalible === true ? "Yes" : "No"}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Private Washroom : <b>{property.privateWashroom}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Shared Washroom : <b>{property.sharedWashroom}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Property Ownership : <b>{property.ownerShip}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Passenger Lifts : <b>{property.passengerLifts}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Occupancy Certificate :{" "}
                <b>{property.ocCertificateCommercial ? "Yes" : "No"}</b>
              </div>
            </div>
          </div>

          <div className="col-lg-3 textalign">
            <div className="">
              <div style={{ paddingBottom: "7px" }}>
                Property Code : <b>{property.propertyID}</b>
              </div>
              {property.pantrySize === " " ? (
                <></>
              ) : (
                <>
                  <div style={{ paddingBottom: "7px" }}>
                    Pantry Size :{" "}
                    <b>
                      {property.pantrySize + " " + property.typeofpantrySize}
                    </b>
                  </div>
                </>
              )}

              <div style={{ paddingBottom: "7px" }}>
                Fire Extinguisher :{" "}
                <b>{property.fireExtinguisher ? "Yes" : "No"}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Fire Sensors : <b>{property.fireSensors ? "Yes" : "No"}</b>
              </div>
              {property.servicelifts !== " " ? (
                <>
                  <div style={{ paddingBottom: "7px" }}>
                    Service Lifts : <b>{property.servicelifts}</b>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div style={{ paddingBottom: "7px" }}>
                Sprinklers : <b>{property.sprinklers ? "Yes" : "No"}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 textalign">
            <div className="">
              <div style={{ paddingBottom: "7px" }}>
                FireHose : <b>{property.fireHose ? "Yes" : "No"}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Public Parking : <b>{property.publicParking ? "Yes" : "No"}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Private Parking :{" "}
                <b>{property.privateParkingOutSide ? "Yes" : "No"}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Available From : <b>{property.availableDate}</b>
              </div>
              {property.category === "Lease" ? (
                <>
                  <div style={{ paddingBottom: "7px" }}>
                    Lock - in Period : <b>{property.lockInPeriodCommercial}</b>
                  </div>

                  <div style={{ paddingBottom: "7px" }}>
                    Security Deposit: <b>{property.securityDeposit}</b>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{
          borderTop: "1px solid #ddd",
          paddingTop: "20px",
          paddingBottom: "30px",
        }}
      >
        <div className="textalign">
          <div className="" style={{ paddingBottom: "7px" }}>
            <b>About Property</b>
          </div>
          <div className="" style={{ paddingBottom: "7px" }}>
            <b> Address</b>:{" "}
            {property.apartment +
              ", " +
              property.locality +
              ", " +
              property.city +
              ", " +
              property.state}
          </div>
          <div className="" style={{ paddingBottom: "7px" }}>
            About: {property.unicProperty}
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{
          marginTop: "30px",
          borderTop: "1px solid #ddd",
          borderBottom: "1px solid #ddd",
          padding: "30px 0px",
        }}
      >
        <h2>
          <b>Features</b>
        </h2>
        <div className="row">
          {propertyIncludePrice.dth === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">DTH</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {propertyIncludePrice.wifi === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcNfcSign />
                  </i>
                  <p className="colourAdd">Wi Fi</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {propertyIncludePrice.electricity === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcLandscape />
                  </i>
                  <p className="colourAdd">Electricity</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {propertyIncludePrice.laundry === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcCollect />
                  </i>
                  <p className="colourAdd">Laundry Room</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {propertyIncludePrice.water === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Water</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {propertyIncludePrice.housekeeping === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcFilm />
                  </i>
                  <p className="colourAdd">Housekeeping</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.visitorParking === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p className="colourAdd">Visitor Parking</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.park === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Park</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {/* new start */}
          {property.maintenanceStaff === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Maintenance Staff</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.waterStorage === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Water Storage</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.wasteDisposal === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcNfcSign />
                  </i>
                  <p className="colourAdd">Waste Disposal</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.rainWaterHarvesting === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcLandscape />
                  </i>
                  <p className="colourAdd">Rain Water Harvesting</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.gasPipes === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcCollect />
                  </i>
                  <p className="colourAdd">Gas Pipes</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.waterpurifier === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Water Purifier</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.fengShuiVaastuCompliant === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcFilm />
                  </i>
                  <p className="colourAdd">Feng Shui / Vaastu Compliant</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.highCeilingHeight === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p className="colourAdd">High Ceiling Height</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.falseCeilingLighting === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">False Ceiling Lighting</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.internetwificonnectivity === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Internet / wi-fi Connectivity</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.centrallyAirConditioned === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Centrally Air-Conditioned</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.securityFireAlarm === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcNfcSign />
                  </i>
                  <p className="colourAdd">Security / Fire Alarm</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.recentlyRenovated === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcLandscape />
                  </i>
                  <p className="colourAdd">Recently Renovated</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.privateGardenTerrace === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcCollect />
                  </i>
                  <p className="colourAdd">Private Garden Terrace</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.naturalLight === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Natural Light</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.airyRooms === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcFilm />
                  </i>
                  <p className="colourAdd">Airy Rooms</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.intercomFacility === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p className="colourAdd">Intercom Facility</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.spaciousInteriors === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Spacious Interiors</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.fitnessCentreGYM === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p className="colourAdd">GYM</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.swimmingPool === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Swimming Pool</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.clubhouseCommunityCenter === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Club House / Community Center</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.securityPersonnel === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Personnel Security</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.lifts === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcNfcSign />
                  </i>
                  <p className="colourAdd">Lifts</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.separateentryforservantroom === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcLandscape />
                  </i>
                  <p className="colourAdd">Separate Entry for Servant Room</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.noopendrainagearound === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcCollect />
                  </i>
                  <p className="colourAdd">No Open Drainage Around</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.bankAttachedProperty === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Bank Attached Property</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.lowDensitySociety === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcFilm />
                  </i>
                  <p className="colourAdd">Low Density Society</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.municipalcorporation === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p className="colourAdd">Municipal Corporation</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.borewellTank === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Borewell / Tank</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.twentyfourbysevenWater === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p className="colourAdd">24*7 Water</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfficeOverview;
