import React, { useState, useEffect, useRef } from "react";
import { BiSearch } from "react-icons/bi";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import Footer from "../../components/layouts/Footer";
import HeaderNew from "../../components/layouts/HeaderNew";
import { useDispatch, useSelector } from "react-redux";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";
import { setleadesSearchObjects } from "../../redux/search/SearchSlice";

const LeadToSearch = () => {
  const { subCatTotal, subcatLoading } = useSelector(
    (state) => state.SubCategory
  );
  // const [minselected, setMinSelected] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isactivecom, setIsActiveCom] = useState(false);
  const [isactivebed, setIsActiveBed] = useState(false);
  const [isactiveminvalue, setIsActiveMinvalue] = useState(false);
  const [isactivemaxvalue, setIsActiveMaxvalue] = useState(false);

  const [propertySearch, setPropertySearch] = useState([]);
  const [propertySearchProject, setPropertySearchProject] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const [selectSupcat, setselectSupcat] = useState("Residential");
  const [selectbuyrent, setselectbuyrent] = useState("Sell");
  const [showSubcat, setshowSubcat] = useState([]);
  const [selectedSubcat, setSelectedSubcat] = useState([]);
  const [selectedBedrooms, setSelectedBedrooms] = useState([]);
  const [selectedMaxvalue, setSelectedMaxValue] = useState("");
  const [selectedMinValue, setSelectedMinValue] = useState("");
  const [search, setSearch] = useState("");
  const [searchArray, setSearchArray] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let menuRef = useRef();
  let bedmenuRef = useRef();
  let minvalRef = useRef();
  let maxvalRef = useRef();
  const ref = useRef();

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  const leadesRedirectclick = () => {
    const leadesForm = {
      selectSupcat: selectSupcat,
      selectbuyrent: selectbuyrent,
      selectedSubcat: selectedSubcat,
      selectedBedrooms: selectedBedrooms,
      selectedMaxvalue: selectedMaxvalue,
      selectedMinValue: selectedMinValue,
      searchArray: searchArray,
    };

    dispatch(setleadesSearchObjects(leadesForm));
    navigate("/leadtosearch-reult");
  };

  const searchChange = (e) => {
    const result = e.target.value;
    setSearch(result);
  };

  const removesearch = (data) => {
    const filteredArr = searchArray.filter((item) => item !== data);
    setSearchArray(filteredArr);
  };

  const storeCityInarray = (e, data) => {
    let localirtarray = [];
    if (searchArray.length > 0) {
      const findserchname = searchArray.filter(
        (seitem) => seitem === data.locality
      );
      if (findserchname.length === 0) {
        localirtarray = [data.locality, ...searchArray];
        setSearchArray(localirtarray);
      }
    } else {
      localirtarray = [data.locality];
      setSearchArray(localirtarray);
    }

    setSearch("");
    setShowResults(false);
  };

  useEffect(() => {
    const filteSubcat = subCatTotal.filter(
      (data) =>
        data.superCategory === selectSupcat && data.category === selectbuyrent
    );
    let allsubcat = [];
    for (let index = 0; index < filteSubcat.length; index++) {
      let element = filteSubcat[index].name;
      allsubcat = [...allsubcat, element];
    }
    setshowSubcat(filteSubcat);
    setSelectedSubcat(allsubcat);
  }, [subCatTotal, selectSupcat]);

  const handleSubcatPress = (evt) => {
    if (selectedSubcat.includes(evt.target.value)) {
      setSelectedSubcat(
        selectedSubcat.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setSelectedSubcat((selectedSubcat) => [
        ...selectedSubcat,
        evt.target.value,
      ]);
    }
  };
  const handleBedroomPress = (evt) => {
    if (selectedBedrooms.includes(evt.target.value)) {
      setSelectedBedrooms(
        selectedBedrooms.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setSelectedBedrooms((selectedBedrooms) => [
        ...selectedBedrooms,
        evt.target.value,
      ]);
    }
  };

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setIsActiveCom(false);
      }
      if (!bedmenuRef.current.contains(e.target)) {
        setIsActiveBed(false);
      }
      if (!minvalRef.current.contains(e.target)) {
        setIsActiveMinvalue(false);
      }
      if (!maxvalRef.current.contains(e.target)) {
        setIsActiveMaxvalue(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  //for search bar strt
  useEffect(() => {
    try {
      if (search.length >= 2) {
        const fetchdata = async () => {
          const url = `${Baseurl}/api/v1/property/allPropertylocalitycity/${search}`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.properties.slice(0, 5);
              setPropertySearch(searchresult);
            }
          } catch (error) {}
        };

        fetchdata();
      }
    } catch (error) {}

    try {
      if (search.length >= 2) {
        const fetchPropertyByProjects = async () => {
          const url = `${Baseurl}/api/v1/property/allPropertyproject/${search}`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.properties;
              setPropertySearchProject(searchresult);
            }
          } catch (error) {}
        };

        fetchPropertyByProjects();
      }
    } catch (error) {}

    setShowResults(true);
  }, [search]);
  //for search bar end

  const handleMinValueSelect = (value) => {
    setSelectedMinValue(value);
    setIsActiveMinvalue(false);
  };

  const handleMaxValueSelect = (value) => {
    setSelectedMaxValue(value);
    setIsActiveMinvalue(false);
  };

  const budget = [
    { id: "1", name: "5 Lacs", budget: 500000 },
    { id: "2", name: "10 Lacs", budget: 1000000 },
    { id: "3", name: "15 Lacs", budget: 1500000 },
    { id: "4", name: "20 Lacs", budget: 2000000 },
    { id: "5", name: "25 Lacs", budget: 2500000 },
    { id: "6", name: "35 Lacs", budget: 3500000 },
    { id: "7", name: "40 Lacs", budget: 4000000 },
    { id: "8", name: "45 Lacs", budget: 4500000 },
    { id: "9", name: "50 Lacs", budget: 5000000 },
    { id: "10", name: "55 Lacs", budget: 5500000 },
    { id: "11", name: "60 Lacs", budget: 6000000 },
    { id: "12", name: "65 Lacs", budget: 6500000 },
    { id: "13", name: "70 Lacs", budget: 7000000 },
    { id: "14", name: "75 Lacs", budget: 7500000 },
    { id: "15", name: "80 Lacs", budget: 8000000 },
    { id: "16", name: "85 Lacs", budget: 8500000 },
    { id: "17", name: "90 Lacs", budget: 9000000 },
    { id: "18", name: "95 Lacs", budget: 9500000 },
  ];

  const bhkapartments = [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "6", name: "6" },
    { id: "7", name: "7" },
    { id: "8", name: "8" },
    { id: "9", name: "9" },
    { id: "10", name: "10" },
  ];

  useEffect(() => {
    let allbhk = [];
    for (let index = 0; index < bhkapartments.length; index++) {
      let element = bhkapartments[index].name;
      allbhk = [...allbhk, element];
    }
    setSelectedBedrooms(allbhk);
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "2px",
  };

  return (
    <>
      <HeaderNew />
      <div className="lmsSearch__c lmsSearch__content lmsSearch__mTopFix ">
        <div>
          <div className="lmsSearch__clr" style={{ height: "5px" }}></div>
          <div className="lmsSearch__rf lmsSearch__creditBalance">
            <div
              className="lmsSearch__b lmsSearch__p5 lmsSearch__mt15 lmsSearch__lf"
              style={{ fontSize: "22px", color: "#666" }}
            >
              Lead Search
            </div>
            <div className="lmsSearch__rf lmsSearch__p5 lmsSearch__tooltip">
              <div className="lmsSearch__f15" style={{ color: "#666" }}>
                Credits Balance
                <span className="lmsSearch__tooltiptext">
                  <div style={{ textAlign: "left", padding: "0 10px" }}></div>
                </span>
              </div>
              <div className="lmsSearch__creditBalance lmsSearch__b lmsSearch__credits lmsSearch__inGreen">
                0/0
              </div>
            </div>
            <div className="lmsSearch__clr lmsSearch__bdr1"></div>
            <div
              className="lmsSearch__rf lmsSearch__f11 lmsSearch__p5"
              style={{ textAlign: "right" }}
            >
              Go back to Mygoldenhomes
            </div>
            <div className="lmsSearch__buyerSrchTabs">
              <input type="hidden" id="srchtabidentifier" value="10" />
              <div
                onClick={() => setselectSupcat("Residential")}
                className="undefined lmsSearch__tabIsOn lmsSearch__grey2 lmsSearch__b undefined"
                id="res"
                style={
                  selectSupcat === "Residential"
                    ? {
                        background: "#fff",
                        color: "gray",
                      }
                    : {
                        cursor: "pointer",
                      }
                }
              >
                Residential
              </div>
              <div
                className="undefined lmsSearch__tabIsOn lmsSearch__grey2 lmsSearch__b undefined"
                id="com"
                style={
                  selectSupcat === "Commertial"
                    ? {
                        background: "#fff",
                        color: "gray",
                        cursor: "pointer",
                      }
                    : {
                        cursor: "pointer",
                      }
                }
                onClick={() => setselectSupcat("Commertial")}
              >
                Commercial
              </div>
              {/* <div
                className="undefined lmsSearch__tabIsOn lmsSearch__grey2 lmsSearch__b undefined"
                id="al"
                onClick={() => setselectSupcat("")}
              >
                Auto Alerts
              </div> */}
              {/* <div
                className="undefined lmsSearch__tabIsOn lmsSearch__grey2 lmsSearch__b undefined"
                id="pl"
                onClick={() => setselectSupcat("")}
              >
                Purchased Leads
              </div> */}
              {/* <div
                className="undefined lmsSearch__tabIsOn lmsSearch__grey2 lmsSearch__b undefined"
                id="cu"
                onClick={() => setselectSupcat("")}
              >
                Credit Usage
              </div> */}
              <div className="lmsSearch__clr lmsSearch__bdr1"></div>
            </div>
            <div>
              <div className="lmsSearch__lmsSrchParent lmsSearch__normalState">
                <div
                  className="lmsSearch__buyerSrch lmsSearch__sSrch undefined lmsSearch__mb10 lmsSearch__bSrch lmsSearch__box"
                  style={{ display: "contents" }}
                >
                  <div className="lmsSearch__searchPad">
                    <div
                      className="lmsSearch__lmsSearchPrefer"
                      style={{ marginTop: "40px" }}
                    >
                      <span className="lmsSearch__f18 undefined">
                        Residential
                      </span>
                      <div
                        className="lmsSearch__nnHtmlRadio lmsSearch__twoCtrl"
                        style={{ zIndex: "1" }}
                      >
                        <div className="lmsSearch__f13 lmsSearch__simpleToggler">
                          <form action="">
                            <div className="radio_buttons">
                              <input
                                className="buy_radio"
                                type="radio"
                                value="Sell"
                                checked={selectbuyrent === "Sell"}
                                name="select"
                                onChange={() => setselectbuyrent("Sell")}
                              />{" "}
                              Buy
                              <input
                                className="rent_radio"
                                type="radio"
                                value="Rent"
                                checked={selectbuyrent === "Rent"}
                                onChange={() => setselectbuyrent("Rent")}
                                name="select"
                              />{" "}
                              Rent
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div
                      className="lmsSearch__clr"
                      style={{ marginTop: "25px" }}
                    ></div>
                    <div className="lmsSearch__LMSSearchCont">
                      <form
                        action=""
                        className=" component__searchWrapper "
                        style={{
                          height: "100%",
                          width: "59%",
                          marginLeft: "33px",
                        }}
                      >
                        <input
                          type="hidden"
                          name="preference"
                          id="preference"
                          value="S"
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="refSection"
                          id="referrer_section"
                          value="GNB"
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="budget_min"
                          id="budget_min"
                          value
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="budget_max"
                          id="budget_max"
                          value
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="searchform"
                          id="searchform"
                          value="1"
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="class"
                          id="class"
                          value
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="bedroom_num"
                          id="bedroom_num"
                          value
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="availability"
                          id="availability"
                          value
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="res_com"
                          id="res_com"
                          value="R"
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="property_type"
                          id="property_type"
                          value
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="situatedIn"
                          id="situatedIn"
                          value
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="businessType"
                          id="businessType"
                          value
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="seatTypes"
                          id="seatTypes"
                          value
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="planType"
                          id="planType"
                          value
                          autoComplete="off"
                        />
                        <input
                          type="hidden"
                          name="seatCountRng"
                          id="seatCountRng"
                          value
                          autoComplete="off"
                        />
                        <div></div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="component__showMenu">
                            <div
                              className="component__searchTag component__searchTag2"
                              style={{
                                whiteSpace: "initial",
                                zIndex: "10",
                                marginLeft: "0px",
                              }}
                            >
                              <div className="pageComponent d-flex component__searchInputWrapper component__noCityFilter">
                                {searchArray.length > 0 ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div
                                        className=""
                                        style={{
                                          background: "#f0f9ff",
                                          border: "1px solid #a3daff",
                                          borderRadius: "50px",
                                          padding: "5px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginRight: "10px",
                                          paddingRight: "10px",
                                          paddingLeft: "10px",
                                          flexDirection: "row",
                                          display: "flex",
                                        }}
                                      >
                                        <p
                                          className=""
                                          style={{ color: "#000", margin: 0 }}
                                        >
                                          {searchArray[0]}
                                        </p>
                                        <RxCross2
                                          onClick={() =>
                                            removesearch(searchArray[0])
                                          }
                                          color="#0078db"
                                          size={20}
                                        />
                                      </div>
                                      {searchArray.length > 1 ? (
                                        <>
                                          <div
                                            className=""
                                            style={{
                                              background: "#f0f9ff",
                                              border: "1px solid #a3daff",
                                              borderRadius: "50px",
                                              padding: "5px",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              paddingRight: "10px",
                                              paddingLeft: "10px",
                                            }}
                                          >
                                            <p
                                              className=""
                                              style={{
                                                color: "#000",
                                                margin: 0,
                                              }}
                                            >
                                              + {searchArray.length - 1} More
                                            </p>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <input
                                  type="text"
                                  name="keyword"
                                  className="list_header_semiBold"
                                  placeholder="Enter Locality / Project / Society / Landmark"
                                  value={search}
                                  ref={ref}
                                  onChange={(e) => searchChange(e)}
                                />
                              </div>
                              {showResults && (
                                <>
                                  {propertySearch.length > 0 ? (
                                    <>
                                      <div
                                        className="serchsugist"
                                        style={{
                                          background: "transparent",
                                        }}
                                      >
                                        <ul
                                          style={{
                                            position: "absolute",
                                            left: 0,
                                            top: 20,
                                            width: "100%",
                                            background: "#fff",
                                          }}
                                        >
                                          {propertySearch.map((data, index) => (
                                            <li
                                              className="d-flex py-1 liclas"
                                              onClick={(e) =>
                                                // searchRedirect(e, data)
                                                storeCityInarray(e, data)
                                              }
                                              key={index}
                                            >
                                              <div
                                                className="mx-1"
                                                style={{
                                                  textTransform: "capitalize",
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <h6>
                                                  {" "}
                                                  {data.locality +
                                                    " " +
                                                    data.city}
                                                </h6>
                                                <h6>Locality</h6>
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {propertySearchProject.length > 0 ? (
                                    <>
                                      <div
                                        className="serchsugist"
                                        style={{
                                          background: "transparent",
                                        }}
                                      >
                                        <ul
                                          style={{
                                            position: "absolute",
                                            left: 0,
                                            top: 20,
                                            width: "100%",
                                            background: "#fff",
                                          }}
                                        >
                                          {propertySearchProject.map(
                                            (data, index) => (
                                              <li
                                                className="d-flex py-1 liclas"
                                                onClick={(e) =>
                                                  // searchRedirect(e, data)
                                                  storeCityInarray(e, data)
                                                }
                                                key={index}
                                              >
                                                <div
                                                  className="mx-1"
                                                  style={{
                                                    textTransform: "capitalize",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <h6> {data.apartment}</h6>
                                                  <h6>Locality</h6>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                              <div className="component__searchRight">
                                <span className="component__upside">|</span>
                                <i
                                  className="component__iconSearch iconS_Common_24 icon_search"
                                  style={{ cursor: "pointer" }}
                                >
                                  <BiSearch />
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="component__heightCollapse ">
                          <div>
                            <ul className="component__autoSuggestion component__autoSuggSlideRemove"></ul>
                          </div>
                        </div>
                      </form>
                      <div
                        className="DropDownCheckBox__rel DropDownCheckBox__lf undefined undefined undefined undefined DropDownCheckBox__mr10 DropDownCheckBox__propertyTYpe"
                        ref={menuRef}
                      >
                        <a
                          style={{ width: "230px" }}
                          id="DropDownCheckBox__property_type_wrap_a"
                          className="DropDownCheckBox__f13 DropDownCheckBox__dropDown DropDownCheckBox__rel DropDownCheckBox__selClick undefined"
                          onClick={(e) => setIsActiveCom(!isactivecom)}
                        >
                          <span className="DropDownCheckBox__apType">
                            All Residential
                          </span>
                          <span>|</span>
                          {isactivecom ? (
                            <i>
                              <AiOutlineCaretUp />
                            </i>
                          ) : (
                            <i>
                              <AiOutlineCaretDown />
                            </i>
                          )}
                        </a>
                        {isactivecom && (
                          <div
                            style={{ width: "230px", display: "block" }}
                            className="DropDownCheckBox__abs DropDownCheckBox__z2 DropDownCheckBox__r05 DropDownCheckBox__ulCont undefined undefined"
                          >
                            <div
                              style={{ height: "210px", clear: "both" }}
                              className="DropDownCheckBox__scrollbar1 DropDownCheckBox__scrollbar96 DropDownCheckBox__mti5"
                            >
                              <div
                                className="DropDownCheckBox__viewport"
                                style={{ overflow: "initial", height: "200px" }}
                              >
                                <div
                                  className="DropDownCheckBox__overview"
                                  style={{ top: "0px" }}
                                >
                                  <ul>
                                    <ul>
                                      {showSubcat.map((data, index) => (
                                        <li key={index}>
                                          <input
                                            type="checkbox"
                                            id="1"
                                            value={data.name}
                                            checked={selectedSubcat.includes(
                                              data.name
                                            )}
                                            onChange={(e) =>
                                              handleSubcatPress(e, data.name)
                                            }
                                          />
                                          <label
                                            htmlFor=""
                                            className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                            for="1"
                                          >
                                            <span className="DropDownCheckBox__selected">
                                              {data.name}
                                            </span>
                                          </label>
                                        </li>
                                      ))}
                                    </ul>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="lmsSearch__inFix pageComponent"
                      style={{
                        marginLeft: "-5px",
                        height: "60px",
                        marginTop: "20px",
                      }}
                    >
                      <div style={{ display: "inline-flex", float: "left" }}>
                        <div
                          style={{
                            display: "inline-flex",
                            position: "relative",
                          }}
                        >
                          <div
                            className="DropDownCheckBox__rel DropDownCheckBox__lf  undefined undefined undefined undefined DropDownCheckBox__mr10 DropDownCheckBox__propertyTYpe"
                            ref={minvalRef}
                          >
                            <a
                              style={{ width: "115px" }}
                              id="DropDownCheckBox__property_type_wrap_a"
                              className="DropDownCheckBox__f13 DropDownCheckBox__dropDown DropDownCheckBox__rel DropDownCheckBox__selClick undefined"
                              onClick={(e) =>
                                setIsActiveMinvalue(!isactiveminvalue)
                              }
                            >
                              <span className="DropDownCheckBox__apType">
                                {selectedMinValue === "" ? (
                                  <>Min Price</>
                                ) : (
                                  <>{selectedMinValue.name}</>
                                )}
                              </span>
                              {isactiveminvalue ? (
                                <i>
                                  <AiOutlineCaretUp />
                                </i>
                              ) : (
                                <i>
                                  <AiOutlineCaretDown />
                                </i>
                              )}
                            </a>
                            {isactiveminvalue && (
                              <div
                                style={{ width: "115px", display: "block" }}
                                className="DropDownCheckBox__abs DropDownCheckBox__z2 DropDownCheckBox__r05 DropDownCheckBox__ulCont undefined undefined"
                              >
                                <div
                                  style={{ height: "150px", clear: "both" }}
                                  className="DropDownCheckBox__scrollbar2 DropDownCheckBox__scrollbar96 DropDownCheckBox__mti5"
                                >
                                  <div
                                    className="DropDownCheckBox__viewport dropdown_new_viewport"
                                    style={{
                                      overflow: "initial",
                                      height: "150px",
                                      overflowX: "hidden",
                                    }}
                                  >
                                    <div
                                      className="DropDownCheckBox__overview dropdown_new_overview"
                                      style={{ top: "0px" }}
                                    >
                                      <ul>
                                        <ul>
                                          {budget.map((data, index) => (
                                            <li
                                              key={index}
                                              onClick={() =>
                                                handleMinValueSelect(data)
                                              }
                                            >
                                              <span className="DropDownCheckBox__selected">
                                                {data.name}
                                              </span>
                                            </li>
                                          ))}
                                        </ul>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "18px",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                color: "#666",
                              }}
                            >
                              To
                            </span>
                          </div>
                          <div
                            className="DropDownCheckBox__rel DropDownCheckBox__lf  undefined undefined undefined undefined DropDownCheckBox__mr10 DropDownCheckBox__propertyTYpe"
                            ref={maxvalRef}
                          >
                            <a
                              style={{ width: "115px" }}
                              id="DropDownCheckBox__property_type_wrap_a"
                              className="DropDownCheckBox__f13 DropDownCheckBox__dropDown DropDownCheckBox__rel DropDownCheckBox__selClick undefined"
                              onClick={(e) =>
                                setIsActiveMaxvalue(!isactivemaxvalue)
                              }
                            >
                              <span className="DropDownCheckBox__apType">
                                {selectedMaxvalue === "" ? (
                                  <>Max Price</>
                                ) : (
                                  <>{selectedMaxvalue.name}</>
                                )}
                              </span>
                              {isactivemaxvalue ? (
                                <i>
                                  <AiOutlineCaretUp />
                                </i>
                              ) : (
                                <i>
                                  <AiOutlineCaretDown />
                                </i>
                              )}
                            </a>
                            {isactivemaxvalue && (
                              <div
                                style={{ width: "115px", display: "block" }}
                                className="DropDownCheckBox__abs DropDownCheckBox__z2 DropDownCheckBox__r05 DropDownCheckBox__ulCont undefined undefined"
                              >
                                <div
                                  style={{ height: "150px", clear: "both" }}
                                  className="DropDownCheckBox__scrollbar2 DropDownCheckBox__scrollbar96 DropDownCheckBox__mti5"
                                >
                                  <div
                                    className="DropDownCheckBox__viewport dropdown_new_viewport"
                                    style={{
                                      overflow: "initial",
                                      height: "150px",
                                      overflowX: "hidden",
                                    }}
                                  >
                                    <div
                                      className="DropDownCheckBox__overview dropdown_new_overview"
                                      style={{ top: "0px" }}
                                    >
                                      <ul>
                                        <ul>
                                          {budget.map((data, index) => (
                                            <li
                                              key={index}
                                              onClick={() => {
                                                handleMaxValueSelect(data);
                                                setIsActiveMaxvalue(
                                                  !isactivemaxvalue
                                                );
                                              }}
                                            >
                                              <span className="DropDownCheckBox__selected">
                                                {data.name}
                                              </span>
                                            </li>
                                          ))}
                                        </ul>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          style={{ display: "inline-flex", marginLeft: "15px" }}
                        >
                          <div
                            className="DropDownCheckBox__rel DropDownCheckBox__lf  undefined undefined undefined undefined DropDownCheckBox__mr10 DropDownCheckBox__propertyTYpe"
                            ref={bedmenuRef}
                          >
                            <a
                              style={{ width: "143px" }}
                              id="DropDownCheckBox__property_type_wrap_a"
                              className="DropDownCheckBox__f13 DropDownCheckBox__dropDown DropDownCheckBox__rel DropDownCheckBox__selClick undefined"
                              onClick={(e) => setIsActiveBed(!isactivebed)}
                            >
                              <span className="DropDownCheckBox__apType">
                                Bedroom
                              </span>
                              <span>|</span>
                              {isactivebed ? (
                                <i>
                                  <AiOutlineCaretUp />
                                </i>
                              ) : (
                                <i>
                                  <AiOutlineCaretDown />
                                </i>
                              )}
                            </a>
                            {isactivebed && (
                              <div
                                style={{ width: "143px", display: "block" }}
                                className="DropDownCheckBox__abs DropDownCheckBox__z2 DropDownCheckBox__r05 DropDownCheckBox__ulCont undefined undefined"
                              >
                                <div
                                  style={{ height: "150px", clear: "both" }}
                                  className="DropDownCheckBox__scrollbar2 DropDownCheckBox__scrollbar96 DropDownCheckBox__mti5"
                                >
                                  <div
                                    className="DropDownCheckBox__viewport"
                                    style={{
                                      overflow: "initial",
                                      height: "150px",
                                      overflowX: "hidden",
                                    }}
                                  >
                                    <div
                                      className="DropDownCheckBox__overview"
                                      style={{ top: "0px" }}
                                    >
                                      <ul>
                                        {bhkapartments.map((data, index) => (
                                          <li key={index}>
                                            <input
                                              type="checkbox"
                                              id="R"
                                              value={data.name}
                                              checked={selectedBedrooms.includes(
                                                data.name
                                              )}
                                              onChange={(e) => {
                                                handleBedroomPress(
                                                  e,
                                                  data.name
                                                );
                                                setIsActiveBed(!isactivebed);
                                              }}
                                            />
                                            <label
                                              htmlFor=""
                                              className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                              for="R"
                                            >
                                              <span className="DropDownCheckBox__selected">
                                                {data.name} Bedroom
                                              </span>
                                            </label>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* onClick={() => navigate("/leadtosearch-reult")} */}
                          {searchArray.length > 0 ? (
                            <>
                              <div onClick={() => leadesRedirectclick()}>
                                <input
                                  type="button"
                                  name="Search"
                                  value="Search"
                                  style={{
                                    width: "170px",
                                    cursor: "pointer",
                                    height: "43px",
                                    marginLeft: "20px",
                                    padding: "0",
                                    background: "#0078db",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "4px",
                                  }}
                                  className="lmsSearch_inline lmsSearchrf lmsSearchbtn lmsSearchblue lmsSearchresSrchBtn undefined lmsSearchb lmsSearch_mr8 pageComponent"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <Link onClick={handleOpen}>
                                <input
                                  type="button"
                                  name="Search"
                                  value="Search"
                                  style={{
                                    width: "170px",
                                    cursor: "pointer",
                                    height: "43px",
                                    marginLeft: "20px",
                                    padding: "0",
                                    background: "#0078db",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "4px",
                                  }}
                                  className="lmsSearch_inline lmsSearchrf lmsSearchbtn lmsSearchblue lmsSearchresSrchBtn undefined lmsSearchb lmsSearch_mr8 pageComponent"
                                />
                              </Link>
                            </>
                          )}

                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Message
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                              >
                                Please enter a city/locality/project to Proceed
                              </Typography>
                              <button
                                style={{
                                  width: "auto",
                                  cursor: "pointer",
                                  height: "auto",
                                  textAlign: "center",
                                  marginTop: "10px",
                                  padding: "10px 20px",
                                  background: "#0078db",
                                  color: "#fff",
                                  borderRadius: "4px",
                                  border: "none",
                                }}
                                onClick={handleClose}
                              >
                                Cancel
                              </button>
                            </Box>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="component__resultHeader">
                <ul>
                  <li>
                    Get Leads serviced by us who have matching or similar
                    interest to your property listing.
                  </li>
                </ul>
                <div className="component__restHeader">
                  <a href="">
                    <b>Go to DashBoard</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LeadToSearch;
