
import React, { useEffect, useState } from "react";
import {
  BiArrowBack,
  BiInfoCircle,
  BiChevronDown,
  BiChevronUp,
  BiPlus,
} from "react-icons/bi";
import { GrCircleQuestion } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";

const AuthorityTypeTemp = (props) => {
  const { PropertyProfile, PriceotherDetails,singleProperty } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [authorityType, setAuthorityType] = useState("");
  const [dTCP, setDTCP] = useState(singleProperty.dTCP);
  const [gHMC, setGHMC] = useState(singleProperty.gHMC);
  const [hMDA, setHMDA] = useState(singleProperty.hMDA);


  useEffect(() => {
    if (PriceotherDetails !== "") {
      setAuthorityType(PriceotherDetails.authorityType);
      setDTCP(PriceotherDetails.dTCP);
      setGHMC(PriceotherDetails.gHMC);
      setHMDA(PriceotherDetails.hMDA);
    }
  }, [PriceotherDetails]);
  return (
    <>
      <div className="Ownership-widget" style={{ marginTop: "10px" }}>
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div
            className="widgetbox_header_text__2BXc_"
            style={{ marginBottom: "-20px" }}
          >
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Which authority the property is approved by ?{" "}
                    <i>
                      <GrCircleQuestion />
                    </i>
                  </span>
                  <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                    (Optional)
                  </em>
                </div>
              </h4>
            </div>
          </div>
        </div>
        <div className="false">
          <div
            className="pagecomponent amenties-widget_inner"
            id="ownership-input"
            style={{ textAlign: "left" }}
          >
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  gHMC === true
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setGHMC(!gHMC);
                  props.setGHMC(!gHMC);
                }}
                id="1"
              >
                {gHMC === true ? (
                  <>
                    <i className="iconS_Common_20 icon_blueRightTick">
                      <img src="./assets/images/all/tick.png" alt="" />
                    </i>
                  </>
                ) : (
                  <>
                    <i className="iconS_PPF_20 icon_plusIcon">
                      <BiPlus />
                    </i>
                  </>
                )}
                <span>GHMC</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  hMDA === true
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setHMDA(!hMDA);
                  props.setHMDA(!hMDA);
                }}
                id="2"
              >
                {hMDA === true ? (
                  <>
                    <i className="iconS_Common_20 icon_blueRightTick">
                      <img src="./assets/images/all/tick.png" alt="" />
                    </i>
                  </>
                ) : (
                  <>
                    <i className="iconS_PPF_20 icon_plusIcon">
                      <BiPlus />
                    </i>
                  </>
                )}
                <span>HMDA</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  dTCP === true
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setDTCP(!dTCP);
                  props.setDTCP(!dTCP);
                }}
                id="3"
              >
                {dTCP === true ? (
                  <>
                    <i className="iconS_Common_20 icon_blueRightTick">
                      <img src="./assets/images/all/tick.png" alt="" />
                    </i>
                  </>
                ) : (
                  <>
                    <i className="iconS_PPF_20 icon_plusIcon">
                      <BiPlus />
                    </i>
                  </>
                )}
                <span>DTCP</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="ApprovedforIndustryType-widget"
        style={{ marginTop: "10px" }}
      >
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div
            className="widgetbox_header_text__2BXc_"
            style={{ marginBottom: "-20px" }}
          >
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Approved for Industry Type{" "}
                    <i>
                      <GrCircleQuestion />
                    </i>
                  </span>
                  <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                    (Optional)
                  </em>
                </div>
              </h4>
            </div>
          </div>
        </div>
        <div className="false">
          <div id="DepositValue-input">
            <div className="splitWidget_inputFiledWrap__1vX9m located_inside undefined">
              <div className="pageComponent" id="carpet-input">
                <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                  <div className="false">
                    <input type="text" placeholder="Select Industry Type" />
                  </div>
                  <div className="numberInput_iconsWrapper__3Kpm5">
                    <i></i>
                    <div className="screeningActions_iconWrapper__dB1NM"></div>
                  </div>
                </div>
              </div>
              <div className="pageComponent" id="carpet-input">
                <div style={{}}>
                  <div className="selectInput_custom_input_wrapper__MqLE9 false  pageComponent">
                    <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                    <div
                      className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf "
                      style={{ border: "none" }}
                    ></div>
                    <div className="selectInput_iconsWrapper__1L2kR">

                      <span className="icon_downArrow">
                        <img src="/assets/images/all/arrow_down.png" alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AuthorityTypeTemp;
