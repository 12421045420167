import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  mob: "",
  otp: "",
  pageStatus: 0,
  loginData: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData"))
    : "",
  email: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).email
    : "",
  name: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).name
    : "",
  mobile: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).mobile
    : "",
  isAuth: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData")).isAuth
    : false,
  modelShow: false,
  isUserLoading: false,
  show_model: false,
  loginTab: "Login",
  currentorderDetails: localStorage.getItem("currentorderDetails")
    ? JSON.parse(localStorage.getItem("currentorderDetails"))
    : "",
  selectBossPlan: localStorage.getItem("selectBossPlan")
    ? JSON.parse(localStorage.getItem("selectBossPlan"))
    : "",
};

export const userRegister = createAsyncThunk(
  "user/userRegister",
  async (formData, thunkAPI) => {
    let resp = {
      success: false,
      message: "user not registered",
    };
    try {
      const config = {
        Headers: {
          "Content-Type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/user/register`;
      resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const userNumberLogin = createAsyncThunk(
  "user/userNumberLogin",
  async (formData, thunkAPI) => {
    let resp = {
      success: false,
      message: "user not registered",
    };
    try {
      const config = {
        Headers: {
          "Content-Type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/user/numberlogin`;
      console.log(url);
      resp = await axios.post(url, formData, config);
      console.log(resp);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getuserbyId = createAsyncThunk(
  "user/getuserbyId",
  async (id, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/user/userById/${id}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("user Not Found");
    }
  }
);

// export const loginAuthentication = createAsyncThunk(
//   "Athentication/loginAuthentication",
//   async (formdetails, thunkAPI) => {
//     let resp = { success: false, message: "new Mobile" };
//     try {
//       const mob = formdetails.mobile;
//       const otp = formdetails.OTP;

//       // const url = `https://www.smsgatewayhub.com/api/mt/SendSMS?APIKey=sHlFlyxFy0GpOWKmWHnT4A&senderid=BMAXMS&channel=2&DCS=0&flashsms=0&number=${mob}&text=Your%20OTP%20for%20logging%20in%20to%20Salesbees%20account%20is%20${otp}.%20Do%20not%20share%20with%20anyone.%20%E2%80%93%20BMAX%20Marketing%20Solutions.&route=1&EntityId=1201166728788735437&dlttemplateid=1207166791590878810`;
//       const url = `https://mobicomm.dove-sms.com/submitsms.jsp?user=shantire&key=2ad16bc0f9XX&mobile=+91${mob}&message=Dear Customer, Your OTP for logging in to KiranaWorld account is ${otp}. Do not share with anyone. Regards Team Kirana World, a Unit of Shanti Retails.&senderid=KRNWLD&accusage=1&entityid=1201159194292972286&tempid=1407168898514000516`;
//       try {
//         const res = await fetch(url);
//         res
//           .json()
//           .then((res) => res)
//           .catch((err) => console.log(err));
//       } catch (error) {}
//       resp = await axios.get(url);

//       return resp;
//     } catch (error) {
//       return error;
//     }
//   }
// );

export const loginAuthentication = createAsyncThunk(
  "Athentication/loginAuthentication",
  async (formdetails, thunkAPI) => {
    let resp = { success: false, message: "new Mobile" };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/admin/sendsms`;
      resp = await axios.post(url, formdetails, config);

      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const validatePhoneNumber = createAsyncThunk(
  "user/validatePhoneNumber",
  async (mobile, thunkAPI) => {
    let resp = {
      success: false,
      message: "new Mobile",
    };
    try {
      const url = `${Baseurl}/api/v1/user/mobile/${mobile}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const validateEmail = createAsyncThunk(
  "user/validateEmail",
  async (email, thunkAPI) => {
    let resp = {
      success: false,
      message: "new Mobile",
    };
    try {
      const url = `${Baseurl}/api/v1/user/email/${email}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const userUpdateById = createAsyncThunk(
  "Athentication/userUpdateById",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const url = `${Baseurl}/api/v1/user/updateuser/${formData.userId}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("User Not Updated");
    }
  }
);

const AthenticationSlice = createSlice({
  name: "Athentication",
  initialState,
  reducers: {
    setShow_model(state, action) {
      state.show_model = action.payload;
    },
    LoginOtp(state, action) {
      state.mob = action.payload.mobile;
      state.otp = action.payload.OTP;
      state.pageStatus = 1;
    },
    signIn(state, action) {
      state.loginData = action.payload;
      state.isAuth = action.payload.isAuth;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.mobile = action.payload.mobile;
      localStorage.setItem("loginData", JSON.stringify(action.payload));
    },
    signout(state) {
      localStorage.removeItem("loginData");
      state.isAuth = false;
    },
    setLoginRegister(state, action) {
      state.loginTab = action.payload;
    },
    setModelShow(state, action) {
      state.modelShow = action.payload;
    },
    setcurrentorderDetails(state, action) {
      state.currentorderDetails = action.payload;
      localStorage.setItem(
        "currentorderDetails",
        JSON.stringify(state.currentorderDetails)
      );
    },
    setselectBossPlan(state, action) {
      state.selectBossPlan = action.payload;
      localStorage.setItem(
        "selectBossPlan",
        JSON.stringify(state.selectBossPlan)
      );
    },
  },
  extraReducers: {},
});

export const {
  LoginOtp,
  signIn,
  signout,
  setLoginRegister,
  setModelShow,
  setcurrentorderDetails,
  setselectBossPlan,
  setShow_model
} = AthenticationSlice.actions;
export const authActions = AthenticationSlice.actions;
export default AthenticationSlice.reducer;
