
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BiArrowBack,
  BiInfoCircle,
  BiChevronDown,
  BiChevronUp,
  BiPlus,
} from "react-icons/bi";
import { BsQuestionCircle } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";
import { GoAlert } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { GrCircleQuestion } from "react-icons/gr";

import { render } from "react-dom";

// Import react-circular-progressbar module and styles
import { CircularProgressbar } from "react-circular-progressbar";
import { setLocalityScooring, setPostPropertyStepsStatus, setPriceotherDetails, setaminitiesScooring, setbasicdetailScooring, setmainpropertyScooring, setphotoScooring, setpricingScooring, setpropertyScooring } from "../../../../redux/property/PropertySlice";
import UploadPhotos from "../../../postpropertypage/finalPriceComponets/UploadPhotos";
import OwnershipTemp from "./commonComp/OwnershipTemp";
import PriceDetailsTemp from "./commonComp/PriceDetailsTemp";
import UniqueTemp from "./commonComp/UniqueTemp";


const percentage = 66;

const PricingandOthersTemp = () => {
  const {
    singleProperty,
    PropertyProfile,
    PriceotherDetails,
    basicdetailScooring,
    mainpropertyScooring,
    photoScooring,
    pricingScooring,
    aminitiesScooring,
    LocalityScooring,
  } = useSelector((state) => state.Property);
  const dispatch = useDispatch();


  const [typeOfOwnerShip, setTypeOfOwnerShip] = useState(singleProperty.ownerShip);

  const [expectedPrice, setExpectedPrice] = useState(singleProperty.expectedPrice);
  const [pricePerSqft, setPricePerSqft] = useState(singleProperty.pricePerSqft);
  const [wordValue, setWordValue] = useState("");

  const [morePriceDetailsShow, setMorePriceDetailsShow] = useState(false);
  const [allinclusiveprice, setAllinclusiveprice] = useState(singleProperty.inclusivePrice);
  const [taxandgovtchargesexcluded, setTaxandGovtChargesExcluded] =
    useState(singleProperty.govtCharges);
  const [priceNegotiable, setPriceNegotiable] = useState(singleProperty.priceNegotiable);

  const [maintenance, setMaintenance] = useState(singleProperty.maintenanceAmount);
  const [maintenanceMonthly, setMaintenanceMonthly] = useState(singleProperty.maintenanceTerm);
  const [maintenanceMonthlyShow, setMaintenanceMonthlyShow] = useState("");
  const [bookingAmount, setBookingAmount] = useState(singleProperty.bookingAmount);
  const [annualDuesPayable, setAnnualDuesPayable] = useState("");
  const [membershipCharges, setMembershipCharges] = useState("");

  const [uniquePropertyText, setUniquePropertyText] = useState(singleProperty.uniquePropertyCommercial);
  const [uniquePropertyTextUse, setUniquePropertyTextUse] = useState("");

  const [authorityType, setAuthorityType] = useState("");
  const [dTCP, setDTCP] = useState("");
  const [gHMC, setGHMC] = useState("");
  const [hMDA, setHMDA] = useState("");

  const [pricepersqft, setPricepersqft] = useState("");
  const [inclusivePrice, setInclusivePrice] = useState(false);
  const [taxgovCharges, setTaxgovCharges] = useState(false);
  const [uniqueTextArea, setUniqueTextArea] = useState("");
  const [expectedRental, setExpectedRental] = useState("");
  const [bookingRental, setBookingRental] = useState("");
  const [annualDues, setAnnualDues] = useState("");
  const [electricity, setElectricity] = useState("");
  const [expectedRent, setExpectedRent] = useState("");

  const [oneTime, setOneTime] = useState("");

  //errors
  const [ownerSelectionError, setOwnerSelectionError] = useState("");
  const [priceDetailsError, setPriceDetailsError] = useState("");
  const [uniquePropertyError, setUniquePropertyError] = useState("");

  const getOwnership = (e) => {
    const result = e;
    setTypeOfOwnerShip(result);
  };

  const handleExpectedPrice = (e) => {
    const result = e;
    setExpectedRent(result);
  };
  const pricepersqftPress = (e) => {
    const result = e;
    setPricepersqft(result);
  };

  const handleElectricity = (result) => {
    setElectricity(result);
  };

  const handleInclusivePricePress = (result) => {
    setAllinclusiveprice(result);
  };
  const handlePricePress = (result) => {
    setPriceNegotiable(result);
  };
  const handletaxGovtPress = (result) => {
    setTaxandGovtChargesExcluded(result);
  };
  const handlesetUniqueTextArea = (result) => {
    setUniquePropertyText(result);
  };
  const morePricingDetails = (result) => {
    setOneTime(result.oneTime);
    setMaintenanceMonthly(result.maintenanceCharges);
    setExpectedRental(result.expectedRental);
    setBookingRental(result.bookingRental);
    setAnnualDues(result.annualDues);
    setMembershipCharges(result.membershipCharges);
  };

  useEffect(() => {
    if (Number(expectedPrice) > 0) {
      let value = Number(expectedPrice) / Number(PropertyProfile.carpetArea);

      value = Math.round(value);
      setPricePerSqft(value);
    }
  }, [PropertyProfile, expectedPrice]);

  useEffect(() => {
    if (PriceotherDetails !== "") {
      setTypeOfOwnerShip(PriceotherDetails.typeOfOwnerShip);

      setExpectedPrice(PriceotherDetails.expectedPrice);
      setPricePerSqft(PriceotherDetails.pricePerSqft);

      setMorePriceDetailsShow(PriceotherDetails.morePriceDetailsShow);
      setAllinclusiveprice(PriceotherDetails.allinclusiveprice);
      setTaxandGovtChargesExcluded(PriceotherDetails.taxandgovtchargesexcluded);
      setPriceNegotiable(PriceotherDetails.priceNegotiable);

      setMaintenance(PriceotherDetails.maintenance);
      setMaintenanceMonthly(PriceotherDetails.maintenanceMonthly);
      setMaintenanceMonthlyShow(PriceotherDetails.maintenanceMonthlyShow);
      setBookingAmount(PriceotherDetails.bookingAmount);
      setAnnualDuesPayable(PriceotherDetails.annualDuesPayable);
      setMembershipCharges(PriceotherDetails.membershipCharges);

      setUniquePropertyText(PriceotherDetails.uniquePropertyText);
      setUniquePropertyTextUse(PriceotherDetails.uniquePropertyTextUse);
    }
  }, [PriceotherDetails]);

  useEffect(() => {
    let totalcount = 0;
    let typeOfOwnerShipchnage = 0;
    if (typeOfOwnerShip !== "") {
      typeOfOwnerShipchnage = 2;
    } else {
      typeOfOwnerShipchnage = 0;
    }
    let expectedPricecount = 0;
    if (expectedPrice !== "") {
      expectedPricecount = 5;
    } else {
      expectedPricecount = 0;
    }
    let uniquePropertyTextCount = 0;
    if (uniquePropertyText !== "") {
      uniquePropertyTextCount = 5;
    } else {
      uniquePropertyTextCount = 0;
    }

    totalcount =
      typeOfOwnerShipchnage + expectedPricecount + uniquePropertyTextCount;
    dispatch(setLocalityScooring(LocalityScooring));
    dispatch(setbasicdetailScooring(basicdetailScooring));
    dispatch(setphotoScooring(photoScooring));
    dispatch(setpricingScooring(totalcount));
    dispatch(setaminitiesScooring(aminitiesScooring));
    dispatch(setmainpropertyScooring(mainpropertyScooring));

    dispatch(setpropertyScooring());
  }, [typeOfOwnerShip, expectedPrice, uniquePropertyText]);

  useEffect(() => {
    if (typeOfOwnerShip !== "") {
      setOwnerSelectionError("");
    }

    if (expectedRent !== "") {
      setPriceDetailsError("");
    }
    if (uniquePropertyText !== "") {
      setUniquePropertyError("");
    }
  }, [typeOfOwnerShip, expectedRent, uniquePropertyText]);

  const convertNumberToWord = (num) => {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (num === 0) {
      return "Zero";
    } else {
      return convertRecursive(num);
    }
  };

  const convertToWord = (e) => {
    let inputValue = e.target.value.replace(/\D/g, "");
    setExpectedPrice(inputValue);
    const num = Number(inputValue);
    if (isNaN(num)) {
      setWordValue("Invalid input");
      return;
    }
    const convertedWord = convertNumberToWord(num);
    setWordValue(convertedWord);
  };

  const convertRecursive = (num) => {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (num < 10) {
      return units[num];
    } else if (num < 20) {
      return teens[num - 10];
    } else if (num < 100) {
      const tensDigit = Math.floor(num / 10);
      const onesDigit = num % 10;
      return tens[tensDigit] + (onesDigit > 0 ? " " + units[onesDigit] : "");
    } else if (num < 1000) {
      const hundredsDigit = Math.floor(num / 100);
      const remainder = num % 100;
      return (
        units[hundredsDigit] +
        " Hundred" +
        (remainder > 0 ? " and " + convertRecursive(remainder) : "")
      );
    } else if (num < 100000) {
      const thousands = Math.floor(num / 1000);
      const remainder = num % 1000;
      return (
        convertRecursive(thousands) +
        " Thousand" +
        (remainder > 0 ? " " + convertRecursive(remainder) : "")
      );
    } else if (num < 10000000) {
      const millions = Math.floor(num / 100000);
      const remainder = num % 100000;
      return (
        convertRecursive(millions) +
        " Lakh" +
        (remainder > 0 ? " " + convertRecursive(remainder) : "")
      );
    } else if (num < 1000000000) {
      const billions = Math.floor(num / 10000000);
      const remainder = num % 10000000;
      return (
        convertRecursive(billions) +
        " Crore" +
        (remainder > 0 ? " " + convertRecursive(remainder) : "")
      );
    } else {
      return "Number too large to convert";
    }
  };

  const pricingOthersClick = (e) => {
    e.preventDefault();
    setOwnerSelectionError("");
    setPriceDetailsError("");
    setUniquePropertyError("");

    if (typeOfOwnerShip !== "") {
      setOwnerSelectionError("");
    } else {
      setOwnerSelectionError("Please Select the ownership details");
    }

    if (expectedRent !== "") {
      setPriceDetailsError("");
    } else {
      setPriceDetailsError("Please specify the price");
    }
    if (uniquePropertyText !== "") {
      setUniquePropertyError("");
    } else {
      setUniquePropertyError("Please Enter Unique Property Details");
    }

    if (
      typeOfOwnerShip !== "" &&
      expectedRent !== "" &&
      uniquePropertyText !== ""
    ) {
      const priceOtherDetails = {
        typeOfOwnerShip: typeOfOwnerShip,
        expectedPrice: expectedRent,
        pricePerSqft: pricePerSqft,
        morePriceDetailsShow: morePriceDetailsShow,
        allinclusiveprice: allinclusiveprice,
        taxandgovtchargesexcluded: taxandgovtchargesexcluded,
        priceNegotiable: priceNegotiable,
        maintenance: maintenance,
        maintenanceMonthly: maintenanceMonthly,
        maintenanceMonthlyShow: maintenanceMonthlyShow,
        bookingAmount: bookingAmount,
        annualDuesPayable: annualDuesPayable,
        membershipCharges: membershipCharges,
        uniquePropertyText: uniquePropertyText,
        authorityType: authorityType,
        dTCP: dTCP,
        gHMC: gHMC,
        hMDA: hMDA,

        laundary: false,
        electricity: false,
        water: false,
        wifi: false,
        houseKeeping: false,
        dth: false,
        noneofTheabove: false,
        FoodDetails: false,
        mealtype: false,
        lunch: false,
        breakfast: false,
        dinner: false,
        lunchweekends: false,
        breakfastweekends: false,
        dinnerweekends: false,
        dTCP: false,
        gHMC: false,
        hMDA: false,
      };
      dispatch(setPriceotherDetails(priceOtherDetails));
      dispatch(setPostPropertyStepsStatus(5));
    }
  };

  const reviewChange = (e) => {
    const value = e.target.value.trim();
    setUniquePropertyText(value);
  };
  const setUnicClick = (e) => {
    e.preventDefault();
    let unicText = "";
    if (expectedPrice !== "") {
      unicText = `Exquisite property featuring contemporary design, luxurious amenities, and breathtaking views. Sale price is INR ${expectedPrice}/-.`;
    }
    if (expectedPrice !== "" && priceNegotiable === true) {
      unicText = `Exquisite property featuring contemporary design, luxurious amenities, and breathtaking views. Sale price is INR ${expectedPrice}/- and is negotiable.`;
    }

    unicText = unicText.trim();
    setUniquePropertyText(unicText);
  };

  return (
    <>
      {/* <HeaderNew /> */}
      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA">
          <div className="page_ppf_step1__1icHe  pageComponent pricing_othersinner">
            <UploadPhotos />

            <OwnershipTemp
              getOwnership={getOwnership}
              ownerSelectionError={ownerSelectionError}
            />

            <PriceDetailsTemp
              handleExpectedPrice={handleExpectedPrice}
              pricepersqftPress={pricepersqftPress}
              handleInclusivePricePress={handleInclusivePricePress}
              handleElectricity={handleElectricity}
              handlePricePress={handlePricePress}
              handletaxGovtPress={handletaxGovtPress}
              priceDetailsError={priceDetailsError}
              morePricingDetails={setMaintenance}
              setMaintenanceMonthly={setMaintenanceMonthly}
              setBookingAmount={setBookingAmount}
            />

            <UniqueTemp
              expectedRent={expectedRent}
              priceNegotiable={priceNegotiable}
              propertyUniqueError={uniquePropertyError}
              handlesetUniqueTextArea={handlesetUniqueTextArea}
            />

            <div className="Description-widget Pricing-widget">
              <div
                className="PhotoDetailsButtonsWrapper-widget"
                style={{ textAlign: "left", marginTop: "20px" }}
              >
                <div id="PhotoDetailsButton-button" className="pageComponent">
                  <div>
                    <div className="buttonInput_continue_btn__1gb5M ">
                      <button
                        type="button"
                        id="PhotoDetailsButton"
                        className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                        onClick={(e) => pricingOthersClick(e)}
                      >
                        <span>
                          <span>Continue</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="rightSection"
          className="rightSection_rightSection__36zjv"
          style={{ minHeight: "calc(100vh - 54px)" }}
        >
          <div className="needHelp_helpBox__1b_kG" id="helpBox">
            <div className="needHelp_needHelpWrap__1eIlT">
              <div className="needHelp_helpHead__2mtNV">
                <i className="iconS_PPFDesk_16 icon_callIcon">
                  <IoMdCall />
                </i>
                <div className="caption_subdued_large">Need help ? </div>
              </div>
              <div className="caption_subdued_medium">
                You can email us at
                <a className="hyperlinks_small"> service@mygoldenhomes.com </a>
                or call us at
                <a className="hyperlinks_small"> 1800 41 99099</a>
                (IND Toll-Free).
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingandOthersTemp;
