import React, { useEffect, useState } from "react";
import {
  BiArrowBack,
  BiInfoCircle,
  BiChevronDown,
  BiChevronUp,
  BiPlus,
} from "react-icons/bi";
import { BsQuestionCircle } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";
import { GoAlert } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  setPostPropertyStepsStatus,
  setPriceotherDetails,
} from "../../../../../redux/property/PropertySlice";
import UploadPhotos from "../../../finalPriceComponets/UploadPhotos";
import PriceDetails from "../../../finalPriceComponets/PriceDetails";
import SecurityDeposit from "../../../finalPriceComponets/SecurityDeposit";
import MinimumAgreementDuration from "../../../finalPriceComponets/MinimumAgreementDuration";
import NoticePeriodMonths from "../../../finalPriceComponets/NoticePeriodMonths";
import Unique from "../../../finalPriceComponets/Unique";
import PreferedAgreement from "../../../finalPriceComponets/PreferedAgreement";

const RentPricePage = () => {
  const { PropertyProfile, PriceotherDetails } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [preferedAgreement, setPreferedAgreement] = useState(false);

  const [expectedRent, setExpectedRent] = useState("");
  const [morePriceDetailsShow, setMorePriceDetailsShow] = useState(false);
  const [maintenance, setMaintenance] = useState("");
  const [maintenanceMonthly, setMaintenanceMonthly] = useState("Monthly");
  const [maintenanceMonthlyShow, setMaintenanceMonthlyShow] = useState("");
  const [bookingAmount, setBookingAmount] = useState("");
  const [membershipCharges, setMembershipCharges] = useState("");

  const [expectedPrice, setExpectedPrice] = useState("");
  const [pricepersqft, setPricepersqft] = useState("");
  const [inclusivePrice, setInclusivePrice] = useState(false);
  const [priceNegotiable, setPriceNegotiable] = useState(false);
  const [taxgovCharges, setTaxgovCharges] = useState(false);
  const [uniqueTextArea, setUniqueTextArea] = useState("");
  const [expectedRental, setExpectedRental] = useState("");
  const [bookingRental, setBookingRental] = useState("");
  const [annualDues, setAnnualDues] = useState("");
  const [electricity, setElectricity] = useState("");

  const [oneTime, setOneTime] = useState("");

  const [securityDeposit, setSecurityDeposit] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [noofMonths, setNoofMonths] = useState("");
  const [durationAgreement, setDurationAgreement] = useState("");

  // console.log(securityDeposit,"1");
  // console.log(depositAmount,"2");
  // console.log(noofMonths,"3");

  const [maintenanceMonthlyduration, setMaintenanceMonthlyDuration] =
    useState("Months");
  const [maintenanceMonthlyDurationShow, setMaintenanceMonthlyDurationShow] =
    useState("");

  const [notiesMonths, setNoticeMonths] = useState("");

  const [uniquePropertyText, setUniquePropertyText] = useState("");
  const [uniquePropertyTextUse, setUniquePropertyTextUse] = useState("");

  // ==================errors========
  const [preferedAgreementError, setPreferedAgreementError] = useState("");
  const [priceDetailsError, setPriceDetailsError] = useState("");
  const [uniquePropertyError, setUniquePropertyError] = useState("");

  const handleExpectedPrice = (e) => {
    const result = e;
    setExpectedRent(result);
  };
  const pricepersqftPress = (e) => {
    const result = e;
    setPricepersqft(result);
  };

  const handleElectricity = (result) => {
    setElectricity(result);
  };

  const handleInclusivePricePress = (result) => {
    setInclusivePrice(result);
  };
  const handlePricePress = (result) => {
    setPriceNegotiable(result);
  };
  const handletaxGovtPress = (result) => {
    setTaxgovCharges(result);
  };
  const handlesetUniqueTextArea = (result) => {
    setUniqueTextArea(result);
  };

  const getSecurityDeposite = (result) => {
    setNoofMonths(result);
    setDepositAmount(result);
    setSecurityDeposit(result);
  };
  const getDurationAgreement = (result) => {
    setNoofMonths(result);
    setDepositAmount(result);
    setSecurityDeposit(result);
  };

  const getPreferedAgreement = (result) => {
    setPreferedAgreement(result);
  };

  const getNoticePeriodMonths = (result) => {
    setNoticeMonths(result);
  };

  useEffect(() => {
    if (PriceotherDetails !== "") {
      setPreferedAgreement(PriceotherDetails.preferedAgreement);

      setExpectedRent(PriceotherDetails.expectedRent);
      setMorePriceDetailsShow(PriceotherDetails.morePriceDetailsShow);
      setMaintenance(PriceotherDetails.maintenance);
      setMaintenanceMonthly(PriceotherDetails.maintenanceMonthly);
      setMaintenanceMonthlyShow(PriceotherDetails.maintenanceMonthlyShow);
      setBookingAmount(PriceotherDetails.bookingAmount);
      setMembershipCharges(PriceotherDetails.membershipCharges);

      setSecurityDeposit(PriceotherDetails.securityDeposit);
      setDepositAmount(PriceotherDetails.depositAmount);
      setNoofMonths(PriceotherDetails.noofMonths);

      setMaintenanceMonthlyDuration(
        PriceotherDetails.maintenanceMonthlyduration
      );
      setMaintenanceMonthlyDurationShow(
        PriceotherDetails.maintenanceMonthlyDurationShow
      );
      setNoticeMonths(PriceotherDetails.notiesMonths);
      setUniquePropertyText(PriceotherDetails.uniquePropertyText);
    }
  }, [PriceotherDetails]);

  useEffect(() => {
    if (preferedAgreement !== null) {
      setPreferedAgreementError("");
    }
    if (expectedRent !== "") {
      setPriceDetailsError("");
    }
    if (uniqueTextArea !== "") {
      setUniquePropertyError("");
    }
  }, [preferedAgreement, expectedRent, uniqueTextArea]);

  const pricingOthersClick = async (e) => {
    e.preventDefault();
    setPreferedAgreementError("");
    setPriceDetailsError("");
    setUniquePropertyError("");

    if (preferedAgreement !== null) {
      setPreferedAgreementError("");
    } else {
      setPreferedAgreementError("Please Select Agreement Type");
    }
    if (expectedRent !== "") {
      setPriceDetailsError("");
    } else {
      setPriceDetailsError("Please Enter Price Details");
    }
    if (uniqueTextArea !== "") {
      setUniquePropertyError("");
    } else {
      setUniquePropertyError("Please Enter Unique Property Details");
    }

    if (
      preferedAgreement !== null &&
      expectedRent !== "" &&
      uniqueTextArea !== ""
    ) {
      const priceOtherDetails = {
        preferedAgreement: preferedAgreement,
        expectedPrice: expectedRent,
        pricePerSqft: pricepersqft,
        morePriceDetailsShow: morePriceDetailsShow,
        allinclusiveprice: inclusivePrice,
        taxandgovtchargesexcluded: taxgovCharges,
        priceNegotiable: priceNegotiable,
        maintenance: maintenance,
        maintenanceMonthly: maintenanceMonthly,
        maintenanceMonthlyShow: maintenanceMonthlyShow,
        bookingAmount: bookingAmount,
        membershipCharges: membershipCharges,

        securityDeposit: securityDeposit,
        depositAmount: depositAmount,
        noofMonths: noofMonths,
        durationAgreement: durationAgreement,

        maintenanceMonthlyduration: maintenanceMonthlyduration,
        maintenanceMonthlyDurationShow: maintenanceMonthlyDurationShow,
        notiesMonths: notiesMonths,
        uniquePropertyText: uniquePropertyText,
      };
      // console.log(priceOtherDetails, "price123");
      dispatch(setPriceotherDetails(priceOtherDetails));
      dispatch(setPostPropertyStepsStatus(5));
    }
  };

  return (
    <>
      {/* <HeaderNew /> */}

      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA">
          <div className="page_ppf_step1__1icHe  pageComponent pricing_othersinner">
            <UploadPhotos />

            <PreferedAgreement
              getPreferedAgreement={getPreferedAgreement}
              preferedAgreementError={preferedAgreementError}
            />

            <PriceDetails
              handleExpectedPrice={handleExpectedPrice}
              pricepersqftPress={setPricepersqft}
              handleInclusivePricePress={handleInclusivePricePress}
              handleElectricity={handleElectricity}
              handlePricePress={handlePricePress}
              handletaxGovtPress={handletaxGovtPress}
              priceDetailsError={priceDetailsError}
              morePricingDetails={setMaintenance}
              setMaintenanceMonthly={setMaintenanceMonthly}
              setBookingAmount={setBookingAmount}
            />

            <SecurityDeposit
              getSecurityDeposite={setSecurityDeposit}
              setDepositAmount={setDepositAmount}
              setNoofMonths={setNoofMonths}
            />

            <MinimumAgreementDuration
              getDurationAgreement={setDurationAgreement}
            />

            <NoticePeriodMonths setNoticeMonths={setNoticeMonths} />

            <div className="Description-widget Pricing-widget">
              <div className="Description-widget Pricing-widget">
                <div
                  className="widgetbox_white_box__1v7xz undefined undefined undefined"
                  style={{ textAlign: "left" }}
                >
                  <Unique
                    handlesetUniqueTextArea={handlesetUniqueTextArea}
                    expectedRent={expectedRent}
                    priceNegotiable={priceNegotiable}
                    propertyUniqueError={uniquePropertyError}
                  />

                  <div
                    className="PhotoDetailsButtonsWrapper-widget"
                    style={{ textAlign: "left", marginTop: "20px" }}
                  >
                    <div
                      id="PhotoDetailsButton-button"
                      className="pageComponent"
                    >
                      <div>
                        <div className="buttonInput_continue_btn__1gb5M ">
                          <button
                            type="button"
                            onClick={(e) => pricingOthersClick(e)}
                            id="PhotoDetailsButton"
                            className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                          >
                            <span>
                              <span>Continue</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="rightSection"
          className="rightSection_rightSection__36zjv"
          style={{ minHeight: "calc(100vh - 54px)" }}
        >
          <div className="needHelp_helpBox__1b_kG" id="helpBox">
            <div className="needHelp_needHelpWrap__1eIlT">
              <div className="needHelp_helpHead__2mtNV">
                <i className="iconS_PPFDesk_16 icon_callIcon">
                  <IoMdCall />
                </i>
                <div className="caption_subdued_large">Need help ? </div>
              </div>
              <div className="caption_subdued_medium">
                You can email us at
                <a className="hyperlinks_small"> service@mygoldenhomes.com </a>
                or call us at
                <a className="hyperlinks_small"> 1800 41 99099</a>
                (IND Toll-Free).
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RentPricePage;
