import React, { useEffect, useState } from "react";
import { FcAreaChart } from "react-icons/fc";
import { FcDataConfiguration } from "react-icons/fc";
import { ImPriceTags } from "react-icons/im";
import { FcAddressBook } from "react-icons/fc";
import { SiVirustotal } from "react-icons/si";
import { BsBorderTop, BsBrowserSafari } from "react-icons/bs";
import { BsInfoSquareFill } from "react-icons/bs";
import { AiFillPropertySafety } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import { RiGraduationCapFill } from "react-icons/ri";
import { RiBankCardFill } from "react-icons/ri";
import { FaFirstAid } from "react-icons/fa";
import { RiFirstAidKitFill } from "react-icons/ri";
import { CiBank } from "react-icons/ci";
import { FaTrain } from "react-icons/fa";
import { AiOutlineTrademarkCircle } from "react-icons/ai";
import { IoIosCheckmark } from "react-icons/io";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { GiWaterSplash } from "react-icons/gi";
import { FcCollect } from "react-icons/fc";
import { TbAirConditioning } from "react-icons/tb";
import { GiCelebrationFire } from "react-icons/gi";
import { FcEngineering } from "react-icons/fc";
import { FcCloseUpMode } from "react-icons/fc";
import { FcShop } from "react-icons/fc";
import { FcSupport } from "react-icons/fc";
import { FcAndroidOs } from "react-icons/fc";
import { FcLibrary } from "react-icons/fc";
import { FcReuse } from "react-icons/fc";
import { FcNfcSign } from "react-icons/fc";
import { FcInTransit } from "react-icons/fc";
import { FcLandscape } from "react-icons/fc";
import { FcFilm } from "react-icons/fc";
import { FcElectricalThreshold } from "react-icons/fc";
import { FcManager } from "react-icons/fc";
import { FcMindMap } from "react-icons/fc";
import { MdElectricBolt } from "react-icons/md";
import { PiTelevisionBold } from "react-icons/pi";
import { FaHouseUser } from "react-icons/fa";
import { MdLocalLaundryService } from "react-icons/md";
import { FaHandHoldingWater } from "react-icons/fa";
import { FaWifi } from "react-icons/fa";

import moment from "moment/moment";
import "swiper/css";
import "swiper/css/navigation";

import { Navigation, Autoplay } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { PiImagesSquare } from "react-icons/pi";

const PgOverView = (props) => {
  const navigate = useNavigate();

  const [property, setProperty] = useState("");
  const [propertyRule, setPropertyRule] = useState("");
  const [propertyIncludePrice, setPropertyIncludePrice] = useState("");
  const [postMobile, setPostMobile] = useState("");
  const [propertyImagesWithName, setPropertyImagesWithName] = useState([]);
  useEffect(() => {
    const singleProperty = props.singlProperty;
    setProperty(singleProperty);
    setPropertyRule(props.propertyRule);
    setPropertyIncludePrice(props.propertyIncludePrice);
    setPostMobile(singleProperty.postUserMobile);
    setPropertyImagesWithName(singleProperty.titleImg);
  }, [props.singlProperty, props.propertyRule]);

  const propertyImageClick = () => {
    navigate("/property-image", {
      state: { propertyImages: props.propertyImages },
    });
  };

  return (
    <div style={{ backgroundColor: "#f5f7fa" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              navigation={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Autoplay]}
              className="mySwiper rounded"
            >
              {props.propertyImages.length > 0 ? (
                <>
                  {props.propertyImages.map((img, index) => (
                    <SwiperSlide key={index}>
                      <img src={img} class="d-block w-100 img-round" alt="" />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "30px",
                        }}
                        onClick={(e) => propertyImageClick(e)}
                      >
                        <PiImagesSquare
                          style={{ fontSize: "35px", color: "#fff" }}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                <>
                  <SwiperSlide>
                    <img src="/assets/images/noimage1.png" alt="" />
                  </SwiperSlide>
                </>
              )}
            </Swiper>
          </div>

          <div className="col-lg-6 row overview-banner2">
            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <FcAreaChart
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Rent</p>
                </div>
                <strong>₹ {property.visiblePrice}</strong>
                <p> Per Month</p>
              </div>
            </div>
            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <FcAddressBook
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Address</p>
                </div>
                <strong>
                  {property.apartment +
                    ", " +
                    property.locality +
                    ", " +
                    property.city +
                    ", " +
                    property.state}
                </strong>
              </div>
            </div>

            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <FcDataConfiguration
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>furnishing</p>
                </div>
                <strong>{property.furnishType}</strong>
              </div>
            </div>

            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <ImPriceTags
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Available for</p>
                </div>
                <strong>{property.availableFor}</strong>
              </div>
            </div>

            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <SiVirustotal
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Configuration</p>
                </div>
                <strong>
                  {property.noofBedrooms} Bedrooms , {property.noofBathrooms}
                  Bathrooms,{property.balconies} Balconies
                </strong>
              </div>
            </div>

            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <BsInfoSquareFill
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Posted By</p>
                </div>
                <strong>{property.postUserPosition}</strong>
              </div>
            </div>
            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <BsBrowserSafari
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Posted On</p>
                </div>
                <strong>
                  {moment(property.createdAt).format("DD MMMM YYYY")}
                </strong>
              </div>
            </div>

            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <AiFillPropertySafety
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Available From</p>
                </div>
                <strong>{property.availableDate}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{
          backgroundColor: "#f5f7fa",
          marginTop: "40px",
          padding: "20px 0px",
          borderTop: "1px solid #ddd",
          paddingTop: "20px",
        }}
      >
        <div className="row">
          <div className="col-lg-3 textalign">
            <div className="">
              <div style={{ paddingBottom: "7px" }}>
                Property Type : <b>{property.subCategory}</b>
              </div>
              <div style={{ paddingBottom: "5px" }}>
                Width of facing road :{" "}
                <b>{property.roadWidth + " " + property.roadWidthType}</b>
              </div>
              {property.AttachedBathrooms === true ? (
                <>
                  <div style={{ paddingBottom: "5px" }}>
                    Attached Bathroom : <b> Yes</b>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ paddingBottom: "5px" }}>
                    Attached Bathroom : <b> No</b>
                  </div>
                </>
              )}
              {property.Attachedbalcony === true ? (
                <>
                  <div style={{ paddingBottom: "5px" }}>
                    Attached Balcony : <b> Yes</b>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ paddingBottom: "5px" }}>
                    Attached Balcony : <b> No</b>
                  </div>
                </>
              )}
              <div>
                Property Code : <b>{property.propertyID}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 textalign">
            <div className="">
              <div style={{ paddingBottom: "7px" }}>
                Balcony : <b>{property.balconies}</b>
              </div>

              <div style={{ paddingBottom: "7px" }}>
                Parking :
                <b>
                  {" "}
                  {property.numberofCoveredParking + " Cover "}
                  {property.numberofOpenParking + " Open "}
                </b>
              </div>

              {property.typeofRoom !== "" ? (
                <>
                  <div style={{ paddingBottom: "5px" }}>
                    Room Type : <b> {property.typeofRoom}</b>
                  </div>
                </>
              ) : (
                <></>
              )}
              {property.noOfpeoplesharing !== "" &&
              property.typeofRoom === "Sharing" ? (
                <>
                  <div style={{ paddingBottom: "5px" }}>
                    People Sharing : <b> {property.noOfpeoplesharing}</b>
                  </div>
                </>
              ) : (
                <></>
              )}
              {property.balconies !== "" ? (
                <>
                  <div style={{ paddingBottom: "5px" }}>
                    Attached Balcony : <b> Yes</b>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ paddingBottom: "5px" }}>
                    Attached Balcony : <b> No</b>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-3 textalign">
            <div className="">
              <div style={{ paddingBottom: "7px" }}>
                Flooring Number :{" "}
                <b>
                  {property.propertyOnFloor +
                    " of " +
                    property.noofFloor +
                    " Floors "}
                </b>
              </div>
              <div>
                Minimum Contract Duration :{" "}
                <b>{property.durationAgreementPg + " Month "}</b>
              </div>
              {property.noOfBeds !== "" ? (
                <>
                  <div style={{ paddingBottom: "5px" }}>
                    Total Beds : <b> {property.noOfBeds}</b>
                  </div>
                </>
              ) : (
                <></>
              )}
              {property.noOfAvailbleBeds !== "" ? (
                <>
                  <div style={{ paddingBottom: "5px" }}>
                    Beds Available : <b> {property.noOfAvailbleBeds}</b>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-lg-3 textalign">
            <div className="">
              <div style={{ paddingBottom: "7px" }}>
                Flooring : <b>{property.typeofFlooring}</b>
              </div>
              {property.earlyLeavingCharges === "Fixed" ? (
                <>
                  <div style={{ paddingBottom: "7px" }}>
                    Early Leaving Charges: <b>₹ {property.earlyFixedCharges}</b>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ paddingBottom: "7px" }}>
                    Early Leaving Charges : <b>None</b>
                  </div>
                </>
              )}
            </div>
            {property.propertyAge !== "" ? (
              <>
                <div style={{ paddingBottom: "7px" }}>
                  Property Age : <b>{property.propertyAge}</b>
                </div>
              </>
            ) : (
              <></>
            )}
            <div>
              Power Backup : <b>{property.powerBackup}</b>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{ borderTop: "1px solid #ddd", paddingTop: "20px" }}
      >
        <div className="textalign">
          <div className="" style={{ paddingBottom: "7px" }}>
            <b>About Property</b>
          </div>
          <div className="" style={{ paddingBottom: "7px" }}>
            <b> Address</b>:{" "}
            {property.apartment +
              ", " +
              property.locality +
              ", " +
              property.city +
              ", " +
              property.state}
          </div>
          <div className="" style={{ paddingBottom: "7px" }}>
            About: {property.unicProperty}
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{ borderTop: "1px solid #ddd", paddingTop: "20px" }}
      >
        <div className="textalign">
          <div className="" style={{ paddingBottom: "7px" }}>
            <b>Included in the price</b>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "20px",
            }}
          >
            <div style={{ paddingBottom: "7px" }}>
              {propertyIncludePrice.dth ? (
                <div>
                  <PiTelevisionBold /> <b>Dth </b>{" "}
                </div>
              ) : null}
            </div>
            <div style={{ paddingBottom: "7px" }}>
              {propertyIncludePrice.electricity ? (
                <div>
                  <MdElectricBolt /> <b>Electricity </b>{" "}
                </div>
              ) : null}
            </div>
            <div style={{ paddingBottom: "7px" }}>
              {propertyIncludePrice.housekeeping ? (
                <div>
                  <FaHouseUser /> <b>House Keeping </b>{" "}
                </div>
              ) : null}
            </div>
            <div style={{ paddingBottom: "7px" }}>
              {propertyIncludePrice.laundry ? (
                <div>
                  <MdLocalLaundryService /> <b>Laundry </b>{" "}
                </div>
              ) : null}
            </div>
            <div style={{ paddingBottom: "7px" }}>
              {propertyIncludePrice.water ? (
                <div>
                  <FaHandHoldingWater /> <b>Water </b>{" "}
                </div>
              ) : null}
            </div>
            <div style={{ paddingBottom: "7px" }}>
              {propertyIncludePrice.wifi ? (
                <div>
                  <FaWifi /> <b>Wifi </b>{" "}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {property.furnishType === "Un Furnished" ? (
        <></>
      ) : (
        <>
          <div
            className="container"
            style={{
              marginTop: "30px",
              borderTop: "1px solid #ddd",
              borderBottom: "1px solid #ddd",
              padding: "30px 0px",
            }}
          >
            <h2>
              <b>Furnished</b>
            </h2>
            <div className="row">
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p
                    className={
                      property.numberofbeds === "undefined" ||
                      property.numberofbeds === 0
                        ? "colour"
                        : "colourAdd"
                    }
                  >
                    {property.numberofbeds !== "undefined" ||
                    property.numberofbeds === 0
                      ? property.numberofbeds + " Bed"
                      : "Bed"}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcNfcSign />
                  </i>
                  <p
                    className={
                      property.numberoffans === "undefined" ||
                      property.numberoffans === 0
                        ? "colour"
                        : "colourAdd"
                    }
                  >
                    {property.numberoffans !== "undefined" ||
                    property.numberoffans === 0
                      ? property.numberoffans + " Fan"
                      : "Fan"}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcLandscape />
                  </i>
                  <p
                    className={
                      property.numberoflight === "undefined" ||
                      property.numberoflight === 0
                        ? "colour"
                        : "colourAdd"
                    }
                  >
                    {property.numberoflight !== "undefined" ||
                    property.numberoflight === 0
                      ? property.numberoflight + " Light"
                      : "Light"}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcCollect />
                  </i>
                  <p
                    className={
                      property.numberofwardrobe === "undefined" ||
                      property.numberofwardrobe === 0
                        ? "colour"
                        : "colourAdd"
                    }
                  >
                    {property.numberofwardrobe !== "undefined" ||
                    property.numberofwardrobe === 0
                      ? property.numberofwardrobe + " Wardrobe"
                      : "Wardrobe"}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p
                    className={
                      property.numberofgeyser === "undefined" ||
                      property.numberofgeyser === 0
                        ? "colour"
                        : "colourAdd"
                    }
                  >
                    {property.numberofgeyser !== "undefined" ||
                    property.numberofgeyser === 0
                      ? property.numberofgeyser + " Geyser"
                      : "Geyser"}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcFilm />
                  </i>
                  <p
                    className={
                      property.numberoftv === "undefined" ||
                      property.numberoftv === 0
                        ? "colour"
                        : "colourAdd"
                    }
                  >
                    {property.numberoftv !== "undefined" ||
                    property.numberoftv === 0
                      ? property.numberoftv + " Tv"
                      : "Tv"}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p
                    className={
                      property.numberofac === "undefined" ||
                      property.numberofac === 0
                        ? "colour"
                        : "colourAdd"
                    }
                  >
                    {property.numberofac !== "undefined" ||
                    property.numberofac === 0
                      ? property.numberofac + " AC"
                      : "AC"}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p
                    className={property.sofa === false ? "colour" : "colourAdd"}
                  >
                    Sofa
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcSupport />
                  </i>
                  <p
                    className={
                      property.fridge === false ? "colour" : "colourAdd"
                    }
                  >
                    Fridge
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcInTransit />
                  </i>
                  <p
                    className={
                      property.stove === false ? "colour" : "colourAdd"
                    }
                  >
                    Stove
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcLibrary />
                  </i>
                  <p
                    className={
                      property.washingMachine === false ? "colour" : "colourAdd"
                    }
                  >
                    Washing Machine
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcEngineering />
                  </i>
                  <p
                    className={
                      property.waterpurifier === false ? "colour" : "colourAdd"
                    }
                  >
                    Water Purifier
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcCloseUpMode />
                  </i>
                  <p
                    className={
                      property.chimney === false ? "colour" : "colourAdd"
                    }
                  >
                    Chimney
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcSupport />
                  </i>
                  <p
                    className={
                      property.dinningTable === false ? "colour" : "colourAdd"
                    }
                  >
                    Dining Table
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAndroidOs />
                  </i>
                  <p
                    className={
                      property.exhaustFan === false ? "colour" : "colourAdd"
                    }
                  >
                    Exhaust Fan
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcFilm />
                  </i>
                  <p
                    className={
                      property.modularKitchen === false ? "colour" : "colourAdd"
                    }
                  >
                    Modular Kitchen
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p
                    className={
                      property.microwave === false ? "colour" : "colourAdd"
                    }
                  >
                    Microwave
                  </p>
                </div>
              </div>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcCollect />
                  </i>
                  <p
                    className={
                      property.curtains === false ? "colour" : "colourAdd"
                    }
                  >
                    Curtains
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div
        className="container"
        style={{ borderTop: "1px solid #ddd", paddingTop: "20px" }}
      >
        <div className="textalign">
          <div className="" style={{ paddingBottom: "7px" }}>
            <b> House Rules</b>:{" "}
          </div>

          <div className="" style={{ paddingBottom: "7px" }}>
            <ul>
              {propertyRule.Visitors === true ? (
                <li>Visitor allowed</li>
              ) : (
                <li></li>
              )}
              <li>Last entry time is: {property.showLastEntryTimePg}</li>
              <li>
                No restrictions on keeping{" "}
                {propertyRule.pet === true ? " Pet," : ""}
                {propertyRule.Smoking === true ? " Smoking," : ""}
                {propertyRule.Alcohol === true ? " Alcohol consumption," : ""}
                {propertyRule.Party === true ? " Hosting Parties," : ""}
                {propertyRule.Visitors === true ? " Visitors " : ""}
                provided your fellow residents are comfortable with it.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {property.FoodDetails === true ? (
        <>
          <div
            className="container"
            style={{ borderTop: "1px solid #ddd", paddingTop: "20px" }}
          >
            <div className="textalign">
              <div className="" style={{ paddingBottom: "7px" }}>
                <b> Food Type</b>:{" "}
              </div>

              <div className="" style={{ paddingBottom: "7px" }}>
                <ul>
                  {property.mealtype === true ? (
                    <li>Only Veg</li>
                  ) : (
                    <li>Veg and Non-Veg</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div
        className="container"
        style={{ borderTop: "1px solid #ddd", paddingTop: "20px" }}
      >
        <div className="textalign">
          <div className="" style={{ paddingBottom: "7px" }}>
            <b> Food Details</b>:{" "}
          </div>

          <div className="" style={{ paddingBottom: "7px" }}>
            <ul>
              {property.FoodDetails === true ? (
                <li>Food price is available on this property.</li>
              ) : (
                <li>Food Details is not available on this property.</li>
              )}
            </ul>
          </div>
        </div>
      </div>
      {property.FoodDetails === true ? (
        <>
          {property.breakfast === false ||
          property.lunch === true ||
          property.dinner === false ? (
            <>
              <div
                className="container"
                style={{ borderTop: "1px solid #ddd", paddingTop: "20px" }}
              >
                <div className="textalign">
                  <div className="" style={{ paddingBottom: "7px" }}>
                    <b>Week Days</b>:{" "}
                  </div>
                  <div className="row">
                    {property.breakfast === true ? (
                      <>
                        <div
                          className="col-lg-2 col-md-4 col-xs-6"
                          style={{ height: "70px" }}
                        >
                          <div className="icon">
                            <i>
                              <FcShop />
                            </i>
                            <p className="colourAdd">Breakfast</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {property.lunch === true ? (
                      <>
                        <div
                          className="col-lg-2 col-md-4 col-xs-6"
                          style={{ height: "70px" }}
                        >
                          <div className="icon">
                            <i>
                              <FcShop />
                            </i>
                            <p className="colourAdd">Lunch</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {property.dinner === true ? (
                      <>
                        <div
                          className="col-lg-2 col-md-4 col-xs-6"
                          style={{ height: "70px" }}
                        >
                          <div className="icon">
                            <i>
                              <FcShop />
                            </i>
                            <p className="colourAdd">Dinner</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.breakfastweekends === true ||
          property.lunchweekends === true ||
          property.dinnerweekends === true ? (
            <>
              <div
                className="container"
                style={{ borderTop: "1px solid #ddd", paddingTop: "20px" }}
              >
                <div className="textalign">
                  <div className="" style={{ paddingBottom: "7px" }}>
                    <b>Week Ends</b>:{" "}
                  </div>

                  <div className="row">
                    {property.breakfastweekends === true ? (
                      <>
                        <div
                          className="col-lg-2 col-md-4 col-xs-6"
                          style={{ height: "70px" }}
                        >
                          <div className="icon">
                            <i>
                              <FcShop />
                            </i>
                            <p className="colourAdd">Breakfast</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {property.lunchweekends === true ? (
                      <>
                        <div
                          className="col-lg-2 col-md-4 col-xs-6"
                          style={{ height: "70px" }}
                        >
                          <div className="icon">
                            <i>
                              <FcShop />
                            </i>
                            <p className="colourAdd">Lunch</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {property.dinnerweekends === true ? (
                      <>
                        <div
                          className="col-lg-2 col-md-4 col-xs-6"
                          style={{ height: "70px" }}
                        >
                          <div className="icon">
                            <i>
                              <FcShop />
                            </i>
                            <p className="colourAdd">Dinner</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      <div
        className="container"
        style={{
          marginTop: "30px",
          borderTop: "1px solid #ddd",
          borderBottom: "1px solid #ddd",
          padding: "30px 0px",
        }}
      >
        <h2>
          <b>Features</b>
        </h2>
        <div className="row">
          {propertyIncludePrice.dth === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">DTH</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {propertyIncludePrice.wifi === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcNfcSign />
                  </i>
                  <p className="colourAdd">Wi Fi</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {propertyIncludePrice.electricity === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcLandscape />
                  </i>
                  <p className="colourAdd">Electricity</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {propertyIncludePrice.laundry === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcCollect />
                  </i>
                  <p className="colourAdd">Laundry Room</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {propertyIncludePrice.water === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Water</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {propertyIncludePrice.housekeeping === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcFilm />
                  </i>
                  <p className="colourAdd">Housekeeping</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.visitorParking === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p className="colourAdd">Visitor Parking</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.park === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Park</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {/* new start */}
          {property.maintenanceStaff === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Maintenance Staff</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.waterStorage === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Water Storage</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.wasteDisposal === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcNfcSign />
                  </i>
                  <p className="colourAdd">Waste Disposal</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.rainWaterHarvesting === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcLandscape />
                  </i>
                  <p className="colourAdd">Rain Water Harvesting</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.gasPipes === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcCollect />
                  </i>
                  <p className="colourAdd">Gas Pipes</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.waterpurifier === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Water Purifier</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.fengShuiVaastuCompliant === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcFilm />
                  </i>
                  <p className="colourAdd">Feng Shui / Vaastu Compliant</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.highCeilingHeight === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p className="colourAdd">High Ceiling Height</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.falseCeilingLighting === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">False Ceiling Lighting</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.internetwificonnectivity === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Internet / wi-fi Connectivity</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.centrallyAirConditioned === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Centrally Air-Conditioned</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.securityFireAlarm === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcNfcSign />
                  </i>
                  <p className="colourAdd">Security / Fire Alarm</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.recentlyRenovated === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcLandscape />
                  </i>
                  <p className="colourAdd">Recently Renovated</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.privateGardenTerrace === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcCollect />
                  </i>
                  <p className="colourAdd">Private Garden Terrace</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.naturalLight === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Natural Light</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.airyRooms === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcFilm />
                  </i>
                  <p className="colourAdd">Airy Rooms</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.intercomFacility === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p className="colourAdd">Intercom Facility</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.spaciousInteriors === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Spacious Interiors</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.fitnessCentreGYM === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p className="colourAdd">GYM</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.swimmingPool === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Swimming Pool</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.clubhouseCommunityCenter === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Club House / Community Center</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.securityPersonnel === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Personnel Security</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.lifts === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcNfcSign />
                  </i>
                  <p className="colourAdd">Lifts</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.separateentryforservantroom === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcLandscape />
                  </i>
                  <p className="colourAdd">Separate Entry for Servant Room</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.noopendrainagearound === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcCollect />
                  </i>
                  <p className="colourAdd">No Open Drainage Around</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.bankAttachedProperty === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcAreaChart />
                  </i>
                  <p className="colourAdd">Bank Attached Property</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.lowDensitySociety === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcFilm />
                  </i>
                  <p className="colourAdd">Low Density Society</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.municipalcorporation === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p className="colourAdd">Municipal Corporation</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.borewellTank === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcShop />
                  </i>
                  <p className="colourAdd">Borewell / Tank</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {property.twentyfourbysevenWater === true ? (
            <>
              <div
                className="col-lg-2 col-md-4 col-xs-6"
                style={{ height: "70px" }}
              >
                <div className="icon">
                  <i>
                    <FcMindMap />
                  </i>
                  <p className="colourAdd">24*7 Water</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default PgOverView;
