import React from 'react'

const PropertyType = () => {
  return (
    <div className="mainbarfiltercomponents">
      <div className="propertyboxes">
        <input type="checkbox" />
        <p className='propertytextpara'>Independent House/Villa</p>
      </div>
      <div className="propertyboxes">
        <input type="checkbox" />
        <p className='propertytextpara'>Residential Apartment</p>
      </div>
      <div className="propertyboxes">
        <input type="checkbox" />
        <p className='propertytextpara'>Residential Land</p>
      </div>
      <div className="propertyboxes">
        <input type="checkbox" />
        <p className='propertytextpara'>Independent/Builder Floor</p>
      </div>
      <div className="propertyboxes">
        <input type="checkbox" />
        <p className='propertytextpara'>Farm House</p>
      </div>
      <div className="propertyboxes">
        <input type="checkbox" />
        <p className='propertytextpara'>Serviced Apartments</p>
      </div>
      <div>
        <p className="clearalltext">Select All</p>
      </div>
    </div>
  );
}

export default PropertyType;