import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { FiArrowUpRight } from 'react-icons/fi'
import { MdOutlineDesignServices } from 'react-icons/md'
import { IoMdCall } from 'react-icons/io'
import { BiSolidStar } from 'react-icons/bi'
import { BsArrowRightShort } from 'react-icons/bs'
import { Link } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode } from 'swiper/modules';

const ReviewsByResidents = () => {
    return (
        <>
            <div className="reviewsby-residents">
                <div className='reviewsby-residents-head'>
                    <h1>Reviews by residents</h1>
                </div>
                <div className='reviewsby-residents-pills'>
                    <div className='active-pill'><h3>Recommended</h3></div>
                    <div><h3>Negative First</h3></div>
                    <div><h3>Recent First</h3></div>
                </div>
                <div className="reviewsby-residents-swiper">
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={130}
                        freeMode={true}
                        pagination={{
                            clickable: false,
                        }}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            480: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            640: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            840: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            1000: {
                                slidesPerView: 4,
                                spaceBetween: 130,
                            },
                        }}
                        modules={[FreeMode]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="reviewsby-residents-swiper-card">
                                    <div className="reviewsby-residents-swiper-card-text-top">
                                        <div className='reviewsby-residents-swiper-card-text-top-in'>
                                            <h2>4.0 <BiSolidStar /></h2>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in1'>
                                            <h2>POSITIVES</h2>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in-text'>
                                            <p>
                                                Gachibowli is surrounded with all the essential that one need in real life from walkable distance market to life changing lifestyle.
                                                The cabs are easily availab...<span>Show More</span>
                                            </p>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in1'>
                                            <h2>NEGATIVES</h2>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in-text'>
                                            <p>
                                            The one thing i dont like about Gachibowli is that the frequent jams. 
                                            Its a busy locality amd so many IT sector office are located in it and cause traffic jam....<span>Show More</span>
                                            </p>
                                        </div>
                                        <div className='reviewsby-residents-footer'>
                                            <div className='reviewsby-residents-foot-img'>BS</div>
                                            <div className='reviewsby-residents-foot-name'>
                                                <div className='foot-name-bold'>Mifran</div>
                                                <div className='foot-name-semibold'>Tenant | Posted 13d ago</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="reviewsby-residents-swiper-card">
                                    <div className="reviewsby-residents-swiper-card-text-top">
                                        <div className='reviewsby-residents-swiper-card-text-top-in'>
                                            <h2>4.7 <BiSolidStar /></h2>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in1'>
                                            <h2>POSITIVES</h2>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in-text'>
                                            <p>
                                            Markets ar at a walking distance I.e. 200-600meter. market are big and provide fresh vegetables and fruits.
                                            power is provided 24/7 . it is safe to visit at nigh...<span>Show More</span>
                                            </p>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in1'>
                                            <h2>NEGATIVES</h2>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in-text'>
                                            <p>
                                            traffic jam are frequent not all the time. parking space are little bit congestible.
                                            pollution are moderate not so much high. there is missing of street light a...<span>Show More</span>
                                            </p>
                                        </div>
                                        <div className='reviewsby-residents-footer'>
                                            <div className='reviewsby-residents-foot-img'>VK</div>
                                            <div className='reviewsby-residents-foot-name'>
                                                <div className='foot-name-bold'>vishal kumar</div>
                                                <div className='foot-name-semibold'>Tenant (living since 3+y) | Posted 1m ago</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="reviewsby-residents-swiper-card">
                                    <div className="reviewsby-residents-swiper-card-text-top">
                                        <div className='reviewsby-residents-swiper-card-text-top-in'>
                                            <h2>5.0 <BiSolidStar /></h2>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in1'>
                                            <h2>POSITIVES</h2>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in-text'>
                                            <p>
                                            I am currently living in this area and i am pretty sure about this locality for its better facilities of everything.
                                            Almost everything is perfect in this locali...<span>Show More</span>
                                            </p>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in1'>
                                            <h2>NEGATIVES</h2>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in-text'>
                                            <p>
                                            Frequent jams problems are faced there often which causes pollutions like air pollution and noise pollution.
                                            Parking issues are present there often. Somewhere u....<span>Show More</span>
                                            </p>
                                        </div>
                                        <div className='reviewsby-residents-footer'>
                                            <div className='reviewsby-residents-foot-img'>ZA</div>
                                            <div className='reviewsby-residents-foot-name'>
                                                <div className='foot-name-bold'>Zaved ali</div>
                                                <div className='foot-name-semibold'>Tenant (living since 3+y) | Posted 1m ago</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="reviewsby-residents-swiper-card">
                                    <div className="reviewsby-residents-swiper-card-text-top">
                                        <div className='reviewsby-residents-swiper-card-text-top-in'>
                                            <h2>4.0 <BiSolidStar /></h2>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in1'>
                                            <h2>POSITIVES</h2>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in-text'>
                                            <p>
                                                Gachibowli is surrounded with all the essential that one need in real life from walkable distance market to life changing lifestyle.
                                                The cabs are easily availab...<span>Show More</span>
                                            </p>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in1'>
                                            <h2>NEGATIVES</h2>
                                        </div>
                                        <div className='reviewsby-residents-swiper-card-text-top-in-text'>
                                            <p>
                                            The one thing i dont like about Gachibowli is that the frequent jams. 
                                            Its a busy locality amd so many IT sector office are located in it and cause traffic jam....<span>Show More</span>
                                            </p>
                                        </div>
                                        <div className='reviewsby-residents-footer'>
                                            <div className='reviewsby-residents-foot-img'>BS</div>
                                            <div className='reviewsby-residents-foot-name'>
                                                <div className='foot-name-bold'>Mifran</div>
                                                <div className='foot-name-semibold'>Tenant | Posted 13d ago</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="reviewsby-residents-swiper-card4">
                                    <div className="reviewsby-residents-swiper-card-text-top4">
                                        <div className='reviewsby-residents-swiper-card-text-top-in4'>
                                            <i><BsArrowRightShort/></i>
                                            <h2>Read All</h2>
                                            <p>3843 Reviews</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default ReviewsByResidents