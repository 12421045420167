
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const QualityOfRatingTemp = (props) => {
  const { PropertyProfile, BasicDetails,singleProperty } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();

  const [noofRating, setNoofRating] = useState(singleProperty.noofRating);

  //errors
  const [qualityRatingError, setQualityRatingError] = useState("");

  useEffect(() => {
    if (PropertyProfile !== "") {
      setNoofRating(PropertyProfile.noofRating);
    }
  }, [PropertyProfile]);

  return (
    <>
      <div className="Rating-widget">
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span className="xidHeading_headingText__Ulc2a">
                      Quality Rating
                    </span>
                  </div>
                </div>
              </h4>
            </div>
            <p style={{ color: "red" }}>{props.qualityRatingError}</p>
          </div>
        </div>
        <div className="false">
          <div id="age-input" style={{ textAlign: "left" }}>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  noofRating === "No Rating"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofRating("No Rating");
                  props.noOfRating("No Rating");
                }}
                id="1"
              >
                <span>No Rating</span>
              </div>
              <div
                className={
                  noofRating === "1"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofRating("1");
                  props.noOfRating("1");
                }}
                id="2"
              >
                <span>1 Star</span>
              </div>
              <div
                className={
                  noofRating === "2"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofRating("2");
                  props.noOfRating("2");
                }}
                id="3"
              >
                <span>2 Star</span>
              </div>
              <div
                className={
                  noofRating === "3"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofRating("3");
                  props.noOfRating("3");
                }}
                id="4"
              >
                <span>3 Star</span>
              </div>
              <div
                className={
                  noofRating === "4"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofRating("4");
                  props.noOfRating("4");
                }}
                id="5"
              >
                <span>4 Star</span>
              </div>
              <div
                className={
                  noofRating === "5"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofRating("5");
                  props.noOfRating("5");
                }}
                id="6"
              >
                <span>5 Star</span>
              </div>
              <div
                className={
                  noofRating === "6"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofRating("6");
                  props.noOfRating("6");
                }}
                id="7"
              >
                <span>6 Star</span>
              </div>
              <div
                className={
                  noofRating === "7"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofRating("7");
                  props.noOfRating("7");
                }}
                id="8"
              >
                <span>7 Star</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QualityOfRatingTemp;
