import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPostPropertyStepsStatus,
  setPropertyProfile,
} from "../../../redux/property/PropertySlice";

const AvaliabilityStatus = (props) => {
  const { PropertyProfile, BasicDetails } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();

  const [readyToMove, setReadyToMove] = useState(false);
  const [underConstruction, setUnderConstruction] = useState(false);
  const [abilityStatus, setAbilityStatus] = useState(false);

  const [ageofProperty, setAgeofProperty] = useState("");

  const [expectedby, setExpectedby] = useState("");
  const [expectedbyShow, setExpectedbyShow] = useState(false);
  const [yearofExpectedBy, setYearofExpectedBy] = useState("");

  //errors

  const [abilityError, setAbilityError] = useState("");

  const [abilityStatusError, setAbilityStatusError] = useState("");

  useEffect(() => {
    if (PropertyProfile !== "") {
      setReadyToMove(PropertyProfile.readyToMove);
      setUnderConstruction(PropertyProfile.underConstruction);
      setAbilityStatus(PropertyProfile.abilityStatus);

      setAgeofProperty(PropertyProfile.ageofProperty);

      setExpectedby(PropertyProfile.expectedby);
      setYearofExpectedBy(PropertyProfile.yearofExpectedBy);
    }
  }, [PropertyProfile]);

  const underConstructions = [
    { id: "1", name: "With in 3 Months" },
    { id: "2", name: "With in 6 Months" },
    { id: "3", name: "By 2024" },
    { id: "4", name: "By 2025" },
    { id: "5", name: "By 2026" },
    { id: "6", name: "By 2027" },
  ];

  useEffect(() => {
    if (readyToMove !== true || underConstruction !== true) {
      setAbilityError("");
    }
    if (ageofProperty !== "" || expectedby !== "") {
      setAbilityStatusError("");
    }
  }, [readyToMove, underConstruction, ageofProperty, expectedby]);

  return (
    <>
      <div className="PropertyAvailability-widget">
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span
                      className="xidHeading_headingText__Ulc2a"
                      style={{ display: "inline" }}
                    >
                      Availability Status
                    </span>
                  </div>
                </div>
              </h4>
            </div>
            <p style={{ color: "red" }}>{props.abilityStatusError}</p>
          </div>
        </div>
        <div className="false">
          <div
            className="propertyAvailability-input"
            style={{ textAlign: "left" }}
          >
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div className="checkBox_tags_wrapper__2gksy  undefined multiple_input">
                <div
                  className={
                    readyToMove === true
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL "
                  }
                  id="I"
                  onClick={() => {
                    setReadyToMove(true);
                    props.getPropertyAvailability(true);
                    setUnderConstruction(false);
                    props.constructionOnProperty(false);
                    setAbilityStatus(true);
                    setExpectedby("");
                    props.getPropertyExpectedMonth("");
                  }}
                >
                  <span>Ready to move</span>
                </div>
                <div
                  className={
                    underConstruction === true
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL "
                  }
                  id="5"
                  onClick={() => {
                    setReadyToMove(false);
                    props.getPropertyAvailability(false);
                    setUnderConstruction(true);
                    props.constructionOnProperty(true);
                    setAbilityStatus(true);
                    setAgeofProperty("");
                    props.getPropertyAge("");
                  }}
                >
                  <span>Under construction</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Age-widget">
        {readyToMove === true ? (
          <>
            <div className="false">
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <div>
                          <span className="xidHeading_headingText__Ulc2a">
                            Age of property
                          </span>
                        </div>
                      </div>
                    </h4>
                  </div>
                </div>
                <p style={{ color: "red" }}>{props.selectAgeError}</p>
              </div>
              <div id="age-input" style={{ textAlign: "left" }}>
                <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                  <div
                    className={
                      ageofProperty === "0-1 years"
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL "
                    }
                    onClick={() => {
                      setAgeofProperty("0-1 years");
                      props.getPropertyAge("0-1 years");
                    }}
                    id="1"
                  >
                    <span>0-1 years</span>
                  </div>
                  <div
                    className={
                      ageofProperty === "1-5 years"
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL "
                    }
                    onClick={() => {
                      setAgeofProperty("1-5 years");
                      props.getPropertyAge("1-5 years");
                    }}
                    id="2"
                  >
                    <span>1-5 years</span>
                  </div>
                  <div
                    className={
                      ageofProperty === "5-10 years"
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL "
                    }
                    onClick={() => {
                      setAgeofProperty("5-10 years");
                      props.getPropertyAge("5-10 years");
                    }}
                    id="3"
                  >
                    <span>5-10 years</span>
                  </div>
                  <div
                    className={
                      ageofProperty === "10+ years"
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL "
                    }
                    onClick={() => {
                      setAgeofProperty("10+ years");
                      props.getPropertyAge("10+ years");
                    }}
                    id="4"
                  >
                    <span>10+ years</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="Posession-widget">
        <div className="widgetbox_white_box__1v7xz undefined false false">
          {underConstruction === true ? (
            <>
              <div className="formWidget_inlineContainer__3oR34 false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <div>
                          <span
                            className="xidHeading_headingText__Ulc2a"
                            style={{ display: "inline" }}
                          >
                            Possession By
                          </span>
                        </div>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
                <p style={{ color: "red" }}>{props.timePossessionError}</p>

              <div id="availability-input">
                <div>
                  <div
                    className="selectInput_custom_input_wrapper__MqLE9 selectInput_multiple_input__1oijd  pageComponent"
                    onClick={() => {
                      setExpectedbyShow(!expectedbyShow);
                      props.getPropertyExpectedMonth(!expectedbyShow);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {expectedby !== "" ? (
                      <>
                        <div className="selectInput_label__Sr6HY caption_subdued_large4">
                          {expectedby}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="selectInput_label__Sr6HY caption_subdued_large4">
                          Expected by
                        </div>
                      </>
                    )}

                    <div className="selectInput_custom_input__DdGZD input_placeholder_completed false"></div>
                    <div className="selectInput_iconsWrapper__1L2kR">
                      <span className="icon_downArrow">
                        <img src="/assets/images/all/arrow_down.png" alt="" />
                      </span>
                      <div className="screeningActions_iconWrapper__dB1NM"></div>
                    </div>
                  </div>
                  {expectedbyShow === true ? (
                    <>
                      <div
                        className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown"
                        // style={{ top: "auto", bottom: "100%" }}
                      >
                        {underConstructions.map((underCon, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              setExpectedbyShow(false);
                              setExpectedby(underCon.name);
                              props.getPropertyExpectedMonth(underCon.name);
                            }}
                          >
                            <span>{underCon.name}</span>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default AvaliabilityStatus;
