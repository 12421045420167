
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdCall } from "react-icons/io";
import { setPostPropertyStepsStatus, setPriceotherDetails } from "../../../../redux/property/PropertySlice";
import PriceDetailsTemp from "./commonComp/PriceDetailsTemp";
import UniqueTemp from "./commonComp/UniqueTemp";
import UploadPhotos from "../../../postpropertypage/finalPriceComponets/UploadPhotos";



const StoragePricingDetailsTemp = () => {
  const { PropertyProfile, PriceotherDetails,singleProperty } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();
  const [expectedPrice, setExpectedPrice] = useState(singleProperty.expectedPrice);
  const [pricePerSqft, setPricePerSqft] = useState(singleProperty.pricePerSqft);
  const [wordValue, setWordValue] = useState("");

  const [morePriceDetailsShow, setMorePriceDetailsShow] = useState(false);
  const [allinclusiveprice, setAllinclusiveprice] = useState(singleProperty.inclusivePrice);
  const [taxandgovtchargesexcluded, setTaxandGovtChargesExcluded] =
    useState(singleProperty.govtCharges);
  const [priceNegotiable, setPriceNegotiable] = useState(singleProperty.priceNegotiable);

  const [maintenance, setMaintenance] = useState(singleProperty.maintenanceAmount);
  const [maintenanceMonthly, setMaintenanceMonthly] = useState(singleProperty.maintenanceTerm);
  const [maintenanceMonthlyShow, setMaintenanceMonthlyShow] = useState("");
  const [bookingAmount, setBookingAmount] = useState(singleProperty.bookingAmount);
  const [annualDuesPayable, setAnnualDuesPayable] = useState("");
  const [membershipCharges, setMembershipCharges] = useState("");

  const [uniquePropertyText, setUniquePropertyText] = useState(singleProperty.uniquePropertyCommercial);
  const [uniquePropertyTextUse, setUniquePropertyTextUse] = useState("");

  const [pricepersqft, setPricepersqft] = useState("");
  const [inclusivePrice, setInclusivePrice] = useState(false);
  const [taxgovCharges, setTaxgovCharges] = useState(false);
  const [uniqueTextArea, setUniqueTextArea] = useState("");
  const [expectedRental, setExpectedRental] = useState("");
  const [bookingRental, setBookingRental] = useState("");
  const [annualDues, setAnnualDues] = useState("");
  const [electricity, setElectricity] = useState("");
  const [expectedRent, setExpectedRent] = useState("");

  const [oneTime, setOneTime] = useState("");

  //errors
  const [priceDetailsError, setPriceDetailsError] = useState("");
  const [uniquePropertyError, setUniquePropertyError] = useState("");

  const handleExpectedPrice = (e) => {
    const result = e;
    setExpectedPrice(result);
  };
  const pricepersqftPress = (e) => {
    const result = e;
    setPricePerSqft(result);
  };

  const handleElectricity = (result) => {
    setElectricity(result);
  };

  const handleInclusivePricePress = (result) => {
    setAllinclusiveprice(result);
  };
  const handlePricePress = (result) => {
    setPriceNegotiable(result);
  };
  const handletaxGovtPress = (result) => {
    setTaxandGovtChargesExcluded(result);
  };
  const handlesetUniqueTextArea = (result) => {
    setUniquePropertyText(result);
  };

  useEffect(() => {
    if (PriceotherDetails !== "") {
      setExpectedPrice(PriceotherDetails.expectedPrice);
      setPricePerSqft(PriceotherDetails.pricePerSqft);

      setMorePriceDetailsShow(PriceotherDetails.morePriceDetailsShow);
      setAllinclusiveprice(PriceotherDetails.allinclusiveprice);

      setTaxandGovtChargesExcluded(PriceotherDetails.taxandgovtchargesexcluded);
      setPriceNegotiable(PriceotherDetails.priceNegotiable);

      setMaintenance(PriceotherDetails.maintenance);
      setMaintenanceMonthly(PriceotherDetails.maintenanceMonthly);
      setMaintenanceMonthlyShow(PriceotherDetails.maintenanceMonthlyShow);
      setBookingAmount(PriceotherDetails.bookingAmount);
      setAnnualDuesPayable(PriceotherDetails.annualDuesPayable);
      setMembershipCharges(PriceotherDetails.membershipCharges);

      setUniquePropertyText(PriceotherDetails.uniquePropertyText);
    }
  }, [PriceotherDetails]);

  const convertNumberToWord = (num) => {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (num === 0) {
      return "Zero";
    } else {
      return convertRecursive(num);
    }
  };

  const convertToWord = (e) => {
    let inputValue = e.target.value.replace(/\D/g, "");
    setExpectedPrice(inputValue);
    const num = Number(inputValue);
    if (isNaN(num)) {
      setWordValue("Invalid input");
      return;
    }
    const convertedWord = convertNumberToWord(num);
    setWordValue(convertedWord);
  };

  const convertRecursive = (num) => {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (num < 10) {
      return units[num];
    } else if (num < 20) {
      return teens[num - 10];
    } else if (num < 100) {
      const tensDigit = Math.floor(num / 10);
      const onesDigit = num % 10;
      return tens[tensDigit] + (onesDigit > 0 ? " " + units[onesDigit] : "");
    } else if (num < 1000) {
      const hundredsDigit = Math.floor(num / 100);
      const remainder = num % 100;
      return (
        units[hundredsDigit] +
        " Hundred" +
        (remainder > 0 ? " and " + convertRecursive(remainder) : "")
      );
    } else if (num < 1000000) {
      const thousands = Math.floor(num / 1000);
      const remainder = num % 1000;
      return (
        convertRecursive(thousands) +
        " Thousand" +
        (remainder > 0 ? " " + convertRecursive(remainder) : "")
      );
    } else if (num < 1000000000) {
      const millions = Math.floor(num / 1000000);
      const remainder = num % 1000000;
      return (
        convertRecursive(millions) +
        " Million" +
        (remainder > 0 ? " " + convertRecursive(remainder) : "")
      );
    } else {
      return "Number too large to convert";
    }
  };

  useEffect(() => {
    if (expectedPrice !== "") {
      setPriceDetailsError("");
    }
    if (uniquePropertyText !== "") {
      setUniquePropertyError("");
    }
  }, [expectedPrice, uniquePropertyText]);

  const pricingOthersClick = async (e) => {
    e.preventDefault();
    setPriceDetailsError("");
    setUniquePropertyError("");

    if (expectedPrice !== "") {
      setPriceDetailsError("");
    } else {
      setPriceDetailsError("Please specify the price");
    }
    if (uniquePropertyText !== "") {
      setUniquePropertyError("");
    } else {
      setUniquePropertyError("Please Enter Unique Property Details");
    }

    if (expectedPrice !== "" && uniquePropertyText !== "") {
      const priceOtherDetails = {
        expectedPrice: expectedPrice,
        pricePerSqft: pricePerSqft,

        morePriceDetailsShow: morePriceDetailsShow,
        allinclusiveprice: allinclusiveprice,

        taxandgovtchargesexcluded: taxandgovtchargesexcluded,
        priceNegotiable: priceNegotiable,

        maintenance: maintenance,
        maintenanceMonthly: maintenanceMonthly,
        maintenanceMonthlyShow: maintenanceMonthlyShow,
        bookingAmount: bookingAmount,
        annualDuesPayable: annualDuesPayable,
        membershipCharges: membershipCharges,

        uniquePropertyText: uniquePropertyText,
      };
      dispatch(setPriceotherDetails(priceOtherDetails));
      dispatch(setPostPropertyStepsStatus(5));
    }
  };

  const reviewChange = (e) => {
    const value = e.target.value.trim();
    setUniquePropertyText(value);
  };
  const setUnicClick = (e) => {
    e.preventDefault();
    let unicText = "";
    if (expectedPrice !== "") {
      unicText = `Exquisite property featuring contemporary design, luxurious amenities, and breathtaking views. Sale price is INR ${expectedPrice}/-.`;
    }
    if (expectedPrice !== "" && priceNegotiable === true) {
      unicText = `Exquisite property featuring contemporary design, luxurious amenities, and breathtaking views. Sale price is INR ${expectedPrice}/- and is negotiable.`;
    }

    unicText = unicText.trim();
    setUniquePropertyText(unicText);
  };
  return (
    <>
      {/* <HeaderNew /> */}
      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA">
          <div className="page_ppf_step1__1icHe  pageComponent pricing_othersinner">
            <UploadPhotos />

            <PriceDetailsTemp
              handleExpectedPrice={handleExpectedPrice}
              pricepersqftPress={pricepersqftPress}
              handleInclusivePricePress={handleInclusivePricePress}
              handleElectricity={handleElectricity}
              handlePricePress={handlePricePress}
              handletaxGovtPress={handletaxGovtPress}
              priceDetailsError={priceDetailsError}
              morePricingDetails={setMaintenance}
              setMaintenanceMonthly={setMaintenanceMonthly}
              setBookingAmount={setBookingAmount}
            />

            <div className="Description-widget Pricing-widget">
              <div
                className="widgetbox_white_box__1v7xz undefined undefined undefined"
                style={{ textAlign: "left" }}
              >
                <UniqueTemp
                  handlesetUniqueTextArea={handlesetUniqueTextArea}
                  expectedRent={expectedPrice}
                  priceNegotiable={priceNegotiable}
                  propertyUniqueError={uniquePropertyError}
                />
                <div
                  className="PhotoDetailsButtonsWrapper-widget"
                  style={{ textAlign: "left", marginTop: "20px" }}
                >
                  <div id="PhotoDetailsButton-button" className="pageComponent">
                    <div>
                      <div className="buttonInput_continue_btn__1gb5M ">
                        <button
                          type="button"
                          id="PhotoDetailsButton"
                          className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                          onClick={(e) => pricingOthersClick(e)}
                        >
                          <span>
                            <span>Continue</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="rightSection"
          className="rightSection_rightSection__36zjv"
          style={{ minHeight: "calc(100vh - 54px)" }}
        >
          <div className="needHelp_helpBox__1b_kG" id="helpBox">
            <div className="needHelp_needHelpWrap__1eIlT">
              <div className="needHelp_helpHead__2mtNV">
                <i className="iconS_PPFDesk_16 icon_callIcon">
                  <IoMdCall />
                </i>
                <div className="caption_subdued_large">Need help ? </div>
              </div>
              <div className="caption_subdued_medium">
                You can email us at
                <a className="hyperlinks_small"> service@mygoldenhomes.com </a>
                or call us at
                <a className="hyperlinks_small"> 1800 41 99099</a>
                (IND Toll-Free).
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoragePricingDetailsTemp;
