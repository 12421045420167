import React from "react";
import ReactSlider from "react-slider";

const Budget = () => {
  const budgetOne = [
    { id: "1", name: "0", budget: 500000 },
    { id: "1", name: "5 L", budget: 500000 },
    { id: "2", name: "10 L", budget: 1000000 },
    { id: "3", name: "15 L", budget: 1500000 },
    { id: "4", name: "20 L", budget: 2000000 },
    { id: "5", name: "25 L", budget: 2500000 },
    { id: "19", name: "30 L", budget: 3000000 },
    { id: "6", name: "35 L", budget: 3500000 },
    { id: "7", name: "40 L", budget: 4000000 },
    { id: "8", name: "45 L", budget: 4500000 },
    { id: "9", name: "50 L", budget: 5000000 },
    { id: "10", name: "55 L", budget: 5500000 },
    { id: "11", name: "60 L", budget: 6000000 },
    { id: "12", name: "65 L", budget: 6500000 },
    { id: "13", name: "70 L", budget: 7000000 },
    { id: "14", name: "75 L", budget: 7500000 },
    { id: "15", name: "80 L", budget: 8000000 },
    { id: "16", name: "85 L", budget: 8500000 },
    { id: "17", name: "90 L", budget: 9000000 },
    { id: "18", name: "95 L", budget: 9500000 },
    { id: "20", name: "1 Cr", budget: 10000000 },
    { id: "121", name: "1.05 Cr", budget: 10500000 },
    { id: "21", name: "1.1 Cr", budget: 11000000 },
    { id: "122", name: "1.15 Cr", budget: 11500000 },
    { id: "22", name: "1.2 Cr", budget: 12000000 },
    { id: "123", name: "1.25 Cr", budget: 12500000 },
    { id: "23", name: "1.3 Cr", budget: 13000000 },
    { id: "124", name: "1.35 Cr", budget: 13500000 },
    { id: "24", name: "1.4 Cr", budget: 14000000 },
    { id: "125", name: "1.45 Cr", budget: 14500000 },
    { id: "25", name: "1.5 Cr", budget: 15000000 },
    { id: "126", name: "1.55 Cr", budget: 15500000 },
    { id: "26", name: "1.6 Cr", budget: 16000000 },
    { id: "127", name: "1.65 Cr", budget: 16500000 },
    { id: "27", name: "1.7 Cr", budget: 17000000 },
    { id: "128", name: "1.75 Cr", budget: 17500000 },
    { id: "28", name: "1.8 Cr", budget: 18000000 },
    { id: "129", name: "1.85 Cr", budget: 18500000 },
    { id: "29", name: "1.9 Cr", budget: 19000000 },
    { id: "130", name: "1.95 Cr", budget: 19500000 },
    { id: "30", name: "2 Cr", budget: 20000000 },
    { id: "31", name: "2.1 Cr", budget: 21000000 },
    { id: "32", name: "2.2 Cr", budget: 22000000 },
    { id: "33", name: "2.3 Cr", budget: 23000000 },
    { id: "34", name: "2.4 Cr", budget: 24000000 },
    { id: "35", name: "2.5 Cr", budget: 25000000 },
    { id: "36", name: "2.6 Cr", budget: 26000000 },
    { id: "37", name: "2.7 Cr", budget: 27000000 },
    { id: "38", name: "2.8 Cr", budget: 28000000 },
    { id: "39", name: "2.9 Cr", budget: 29000000 },
    { id: "40", name: "3 Cr", budget: 30000000 },
    { id: "41", name: "3.1 Cr", budget: 31000000 },
    { id: "42", name: "3.2 Cr", budget: 32000000 },
    { id: "43", name: "3.3 Cr", budget: 33000000 },
    { id: "44", name: "3.4 Cr", budget: 34000000 },
    { id: "45", name: "3.5 Cr", budget: 35000000 },
    { id: "46", name: "3.6 Cr", budget: 36000000 },
    { id: "47", name: "3.7 Cr", budget: 37000000 },
    { id: "48", name: "3.8 Cr", budget: 38000000 },
    { id: "49", name: "3.9 Cr", budget: 39000000 },
    { id: "50", name: "4 Cr", budget: 40000000 },
    { id: "51", name: "4.1 Cr", budget: 41000000 },
    { id: "52", name: "4.2 Cr", budget: 42000000 },
    { id: "53", name: "4.3 Cr", budget: 43000000 },
    { id: "54", name: "4.4 Cr", budget: 44000000 },
    { id: "55", name: "4.5 Cr", budget: 45000000 },
    { id: "56", name: "4.6 Cr", budget: 46000000 },
    { id: "57", name: "4.7 Cr", budget: 47000000 },
    { id: "58", name: "4.8 Cr", budget: 48000000 },
    { id: "59", name: "4.9 Cr", budget: 49000000 },
    { id: "60", name: "5 Cr", budget: 50000000 },
    { id: "61", name: "5.1 Cr", budget: 51000000 },
    { id: "62", name: "5.2 Cr", budget: 52000000 },
    { id: "63", name: "5.3 Cr", budget: 53000000 },
    { id: "64", name: "5.4 Cr", budget: 54000000 },
    { id: "65", name: "5.5 Cr", budget: 55000000 },
    { id: "66", name: "5.6 Cr", budget: 56000000 },
    { id: "67", name: "5.7 Cr", budget: 57000000 },
    { id: "68", name: "5.8 Cr", budget: 58000000 },
    { id: "69", name: "5.9 Cr", budget: 59000000 },
    { id: "70", name: "6 Cr", budget: 60000000 },
    { id: "71", name: "6.1 Cr", budget: 61000000 },
    { id: "72", name: "6.2 Cr", budget: 62000000 },
    { id: "73", name: "6.3 Cr", budget: 63000000 },
    { id: "74", name: "6.4 Cr", budget: 64000000 },
    { id: "75", name: "6.5 Cr", budget: 65000000 },
    { id: "76", name: "6.6 Cr", budget: 66000000 },
    { id: "77", name: "6.7 Cr", budget: 67000000 },
    { id: "78", name: "6.8 Cr", budget: 68000000 },
    { id: "79", name: "6.9 Cr", budget: 69000000 },
    { id: "80", name: "7 Cr", budget: 70000000 },
    { id: "81", name: "7.1 Cr", budget: 71000000 },
    { id: "82", name: "7.2 Cr", budget: 72000000 },
    { id: "83", name: "7.3 Cr", budget: 73000000 },
    { id: "84", name: "7.4 Cr", budget: 74000000 },
    { id: "85", name: "7.5 Cr", budget: 75000000 },
    { id: "86", name: "7.6 Cr", budget: 76000000 },
    { id: "87", name: "7.7 Cr", budget: 77000000 },
    { id: "88", name: "7.8 Cr", budget: 78000000 },
    { id: "89", name: "7.9 Cr", budget: 79000000 },
    { id: "90", name: "8 Cr", budget: 80000000 },
    { id: "91", name: "8.1 Cr", budget: 81000000 },
    { id: "92", name: "8.2 Cr", budget: 82000000 },
    { id: "93", name: "8.3 Cr", budget: 83000000 },
    { id: "94", name: "8.4 Cr", budget: 84000000 },
    { id: "95", name: "8.5 Cr", budget: 85000000 },
    { id: "96", name: "8.6 Cr", budget: 86000000 },
    { id: "97", name: "8.7 Cr", budget: 87000000 },
    { id: "98", name: "8.8 Cr", budget: 88000000 },
    { id: "99", name: "8.9 Cr", budget: 89000000 },
    { id: "100", name: "9 Cr", budget: 90000000 },
    { id: "101", name: "9.1 Cr", budget: 81000000 },
    { id: "102", name: "9.2 Cr", budget: 82000000 },
    { id: "103", name: "9.3 Cr", budget: 83000000 },
    { id: "104", name: "9.4 Cr", budget: 84000000 },
    { id: "105", name: "9.5 Cr", budget: 85000000 },
    { id: "106", name: "9.6 Cr", budget: 86000000 },
    { id: "107", name: "9.7 Cr", budget: 87000000 },
    { id: "108", name: "9.8 Cr", budget: 88000000 },
    { id: "109", name: "9.9 Cr", budget: 89000000 },
    { id: "110", name: "10 Cr", budget: 100000000 },
    { id: "111", name: "20 Cr", budget: 200000000 },
    { id: "112", name: "30 Cr", budget: 300000000 },
    { id: "113", name: "40 Cr", budget: 400000000 },
    { id: "114", name: "50 Cr", budget: 500000000 },
    { id: "115", name: "60 Cr", budget: 600000000 },
    { id: "116", name: "70 Cr", budget: 700000000 },
    { id: "117", name: "80 Cr", budget: 800000000 },
    { id: "118", name: "90 Cr", budget: 900000000 },
    { id: "119", name: "100 Cr", budget: 1000000000 },
    { id: "120", name: "100 + Cr", budget: 1000000001 },
  ];

  return (
    <div className="mainbarfiltercomponents">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p className="budgettext">Budget Range</p>
        <p className="inrtext">INR - ₹</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #eee",
          paddingBottom: "10px",
        }}
      >
        <div className="circularborder">
          <p className="inrtext zerotext">0</p>
        </div>
        <div>
          <p className="inrtext">to</p>
        </div>
        <div className="circularborder">
          <p className="inrtext hundredtext">100+Cr</p>
        </div>
      </div>
      <div className="sliderpartmain">
        <div className="slidernumberingone">
          {budgetOne.map((budgetone, index) => (
            <div key={index}>
              <p>{budgetone.name}</p>
            </div>
          ))}
        </div>
        <div className="slidernumberingtwo">
          {budgetOne.map((budgettwo, index) => (
            <div key={index}>
              <p>{budgettwo.name}</p>
            </div>
          ))}
        </div>
      </div>

      {/* <div
        style={{
          height: "50vh",
          width: "100%",
          // background: "red",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactSlider
          className="vertical-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          defaultValue={[0, 1000000000]}
          max={1000000000}
          min={0}
          step={500000}
          orientation="vertical"
          invert
          pearling
        />
      </div> */}

      {/* <div className="flex-between" style={{ margin: "15px 0px 0px 12px" }}>
        <ReactSlider
          className="vertical-slider"
          //vertical-slider
          thumbClassName="example-thumb"
          trackClassName="example-track"
          defaultValue={[0, 1000000000]}
          max={1000000000}
          min={0}
          step={500000}
          // renderThumb={(props, state) => (
          //   <div {...props}>{state.valueNow}</div>
          // )}
          // onChange={(value, index) => propertyPriceChange(value)}
        />
      </div> */}
    </div>
  );
};

export default Budget;
