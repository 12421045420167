import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";

export function ChoosingSubcription({
  toggleClick,
  propertyPostClick,
  setSkipPageChange,
}) {
  const { loginData } = useSelector((store) => store.Athentication);
  const [selectOption, setselectOption] = useState(false);
  return (
    <>
      <div className="choosing-subscription">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <button
            style={{
              backgroundColor: "unset",
              border: "none",
              outline: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              toggleClick();
              setSkipPageChange(0);
            }}
          >
            <RxCross2 />
          </button>
        </div>
        <div className="choosing-subscription-body">
          <div className="">
            <div className="choosing-subscription-body-title">
              Choose a Subscription
            </div>
            <p className="choosing-subscription-body-subtitle">
              You have the follwing existing plans. Pick the plan you would like
              to post this property{" "}
            </p>
          </div>
          <div className="filter-options">
            Sort By:
            <div className="filter">Expiring Soon</div>
            <div className="filter">Most Credits</div>
            <div className="filter">Least Credits</div>
            <div className="filter">recently Bought</div>
            <div className="filter-unique">None</div>
          </div>
          <div>
            <div
              className="subscription-plans"
              onClick={() => setselectOption(!selectOption)}
              style={{ cursor: "pointer" }}
            >
              <input
                type="radio"
                name=""
                id=""
                checked={selectOption === true}
              />
              <div>
                <div style={{ fontWeight: "600" }}>
                  {loginData.PostCreadits} {loginData.packageType} Listings
                </div>
                {/* <div>
                  Applicable <span style={{ fontWeight: "600" }}>Hyderbad</span>
                </div> */}
                <div>
                  Expires on{" "}
                  {moment(loginData.PostCreaditsExpire).format("Do MMM YY")}
                </div>
              </div>
              {/* <div style={{ fontSize: "12px" }}>
                30360 <br />
                credits
              </div> */}
            </div>
          </div>
        </div>
        <div className="choosing-subscription-footer">
          <p>
            By clicking on submit, i confirm tha the prperty details including
            price, photos are correct. And thi spropertyis avilable for sell /
            rent. In case of any discreperncies 99acres can take strict action
            including deleting the listing. I also terms And conditions.
          </p>
          {selectOption === true ? (
            <>
              <button
                style={{
                  backgroundColor: "#0077df",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => setSkipPageChange(2)}
              >
                Submit
              </button>
            </>
          ) : (
            <>
              <button>Submit</button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
