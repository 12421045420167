import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import {
  authActions,
  getuserbyId,
  validatePhoneNumber,
} from "../redux/athentication/Athentication";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";

const PaymentSuccessPage = () => {
  const { loginData, currentorderDetails } = useSelector(
    (store) => store.Athentication
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paymentgatewayStatus, setPaymentgatewayStatus] = useState("");
  const [paymentgatewayData, setPaymentgatewayStatusData] = useState("");
  const [tempLoading, setTempLoading] = useState(true);

  console.log(paymentgatewayData, "paymentgatewayData");

  useEffect(() => {
    const getOrderStatus = async () => {
      const orderformdata = {
        order_id: currentorderDetails.order_id,
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const orderurl = `${Baseurl}/api/v1/credithistory/getpaymentstatus`;
      let fetchOrderData = await axios.post(orderurl, orderformdata, config);
   
      try {
        const getdata = fetchOrderData.data.status_id;
        const data = fetchOrderData.data;
        setPaymentgatewayStatusData(data);
        if (String(getdata) === String(21)) {
          if (currentorderDetails.amount === data.effective_amount) {
            setPaymentgatewayStatus("SUCCESS");
          } else {
            setPaymentgatewayStatus("AmountMissmatch");
          }
        } else if (String(getdata) === String(23)) {
          setPaymentgatewayStatus("PENDING_VBV");
        } else if (String(getdata) === String(10)) {
          setPaymentgatewayStatus("NEW");
        } else if (String(getdata) === String(26)) {
          setPaymentgatewayStatus("AUTHENTICATION_FAILED");
        } else if (String(getdata) === String(27)) {
          setPaymentgatewayStatus("AUTHORIZATION_FAILED");
        } else if (String(getdata) === String(22)) {
          setPaymentgatewayStatus("JUSPAY_DECLINED");
        } else if (String(getdata) === String(28)) {
          setPaymentgatewayStatus("AUTHORIZING");
        } else if (String(getdata) === String(29)) {
          setPaymentgatewayStatus("COD_INITIATED");
        } else if (String(getdata) === String(20)) {
          setPaymentgatewayStatus("STARTED");
        } else if (String(getdata) === String(36)) {
          setPaymentgatewayStatus("AUTO_REFUNDED");
        } else if (String(getdata) === String(37)) {
          setPaymentgatewayStatus("PARTIAL_CHARGED");
        } else {
          setPaymentgatewayStatus("failed");
        }

        const validateNumber = await dispatch(getuserbyId(loginData._id));
        if (validateNumber.payload.success === true) {
          const userData = validateNumber.payload.user;
          const authenticForm = {
            ...userData,
            isAuth: true,
          };
          const userLog = await dispatch(authActions.signIn(authenticForm));
        }
        setTempLoading(false);
      } catch (error) {
        setPaymentgatewayStatus("failed");
      }
      setTempLoading(false);
    };
    if (currentorderDetails !== "") {
      getOrderStatus();
    }
  }, [currentorderDetails]);

  return (
    <>
      {tempLoading ? (
        <>
          <>
            <div
              style={{
                width: "100%",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
            </div>
          </>
        </>
      ) : (
        <>
          {paymentgatewayStatus === "SUCCESS" ? (
            <>
              <div>
                <div className="payment-success">
                  <img
                    className="paymentsuccess-img"
                    src="https://img.freepik.com/free-vector/payment-information-concept-illustration_114360-2886.jpg?w=2000&t=st=1704880841~exp=1704881441~hmac=f6b97c1e2c3625df91be8f0d6201fa54e0a835911652d58230cbc8f482e4a6ac"
                    alt=""
                  />
                  <h3>
                    Your amount {paymentgatewayData.effective_amount} Payment is
                    Successfull with Order Id {paymentgatewayData.order_id}
                  </h3>
                  <p>
                    Thank you for your payment.An automated payment receipt will
                    be sent to your registred email.{" "}
                  </p>

                  <div className="back-btn" onClick={() => navigate("/")}>
                    Back to Home
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {paymentgatewayStatus === "PENDING_VBV" ? (
                <>
                  <div className="payment-fail-box">
                    <div className="payment-fail-img-box">
                      <img
                        src="https://img.freepik.com/free-vector/removing-goods-from-basket-refusing-purchase-changing-decision-item-deletion-emptying-trash-online-shopping-app-laptop-user-cartoon-character_335657-2566.jpg?t=st=1707376212~exp=1707376812~hmac=5e14b201ce59a9a03d92106adf897626c6de4bda5bee2acead64d0a1c2ebcd7f"
                        alt=""
                      />
                    </div>
                    <div className="payment-fail-text-box">
                      <p>
                        Your amount {paymentgatewayData.effective_amount}{" "}
                        payment has Authentication is in progress with Order Id{" "}
                        {paymentgatewayData.order_id}. Please review your
                        payment information and try again.
                      </p>
                      <button onClick={() => navigate("/")}>
                        Back to home
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {paymentgatewayStatus === "NEW" ? (
                    <>
                      <div className="payment-fail-box">
                        <div className="payment-fail-img-box">
                          <img
                            src="https://img.freepik.com/free-vector/removing-goods-from-basket-refusing-purchase-changing-decision-item-deletion-emptying-trash-online-shopping-app-laptop-user-cartoon-character_335657-2566.jpg?t=st=1707376212~exp=1707376812~hmac=5e14b201ce59a9a03d92106adf897626c6de4bda5bee2acead64d0a1c2ebcd7f"
                            alt=""
                          />
                        </div>
                        <div className="payment-fail-text-box">
                          <p>
                            Your amount {paymentgatewayData.effective_amount}{" "}
                            payment has Newly created order. This is the status
                            if transaction is not triggered for an order with
                            Order Id {paymentgatewayData.order_id}. Please
                            review your payment information and try again.
                          </p>
                          <button onClick={() => navigate("/")}>
                            Back to home
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {paymentgatewayStatus === "AUTHENTICATION_FAILED" ? (
                        <>
                          <div className="payment-fail-box">
                            <div className="payment-fail-img-box">
                              <img
                                src="https://img.freepik.com/free-vector/removing-goods-from-basket-refusing-purchase-changing-decision-item-deletion-emptying-trash-online-shopping-app-laptop-user-cartoon-character_335657-2566.jpg?t=st=1707376212~exp=1707376812~hmac=5e14b201ce59a9a03d92106adf897626c6de4bda5bee2acead64d0a1c2ebcd7f"
                                alt=""
                              />
                            </div>
                            <div className="payment-fail-text-box">
                              <p>
                                Your amount{" "}
                                {paymentgatewayData.effective_amount} payment
                                has User did not complete authentication with
                                Order Id {paymentgatewayData.order_id}. Please
                                review your payment information and try again.
                              </p>
                              <button onClick={() => navigate("/")}>
                                Back to home
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {paymentgatewayStatus === "AUTHORIZATION_FAILED" ? (
                            <>
                              <div>
                                <div className="payment-success">
                                  <img
                                    className="paymentsuccess-img"
                                    src="https://img.freepik.com/free-vector/payment-information-concept-illustration_114360-2886.jpg?w=2000&t=st=1704880841~exp=1704881441~hmac=f6b97c1e2c3625df91be8f0d6201fa54e0a835911652d58230cbc8f482e4a6ac"
                                    alt=""
                                  />
                                  <h3>
                                    Your amount{" "}
                                    {paymentgatewayData.effective_amount}{" "}
                                    Payment is User completed authentication,
                                    but the bank refused the transaction. with
                                    Order Id {paymentgatewayData.order_id}
                                  </h3>
                                  <p>
                                    Thank you for your payment.An automated
                                    payment receipt will be sent to your
                                    registred email.{" "}
                                  </p>

                                  <div
                                    className="back-btn"
                                    onClick={() => navigate("/")}
                                  >
                                    Back to Home
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {paymentgatewayStatus === "JUSPAY_DECLINED" ? (
                                <>
                                  <div>
                                    <div className="payment-success">
                                      <img
                                        className="paymentsuccess-img"
                                        src="https://img.freepik.com/free-vector/payment-information-concept-illustration_114360-2886.jpg?w=2000&t=st=1704880841~exp=1704881441~hmac=f6b97c1e2c3625df91be8f0d6201fa54e0a835911652d58230cbc8f482e4a6ac"
                                        alt=""
                                      />
                                      <h3>
                                        Your amount{" "}
                                        {paymentgatewayData.effective_amount}{" "}
                                        Payment is User input is not accepted by
                                        the underlying PG with Order Id{" "}
                                        {paymentgatewayData.order_id}
                                      </h3>
                                      <p>
                                        Thank you for your payment.An automated
                                        payment receipt will be sent to your
                                        registred email.{" "}
                                      </p>

                                      <div
                                        className="back-btn"
                                        onClick={() => navigate("/")}
                                      >
                                        Back to Home
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {paymentgatewayStatus === "COD_INITIATED" ? (
                                    <>
                                      <div>
                                        <div className="payment-success">
                                          <img
                                            className="paymentsuccess-img"
                                            src="https://img.freepik.com/free-vector/payment-information-concept-illustration_114360-2886.jpg?w=2000&t=st=1704880841~exp=1704881441~hmac=f6b97c1e2c3625df91be8f0d6201fa54e0a835911652d58230cbc8f482e4a6ac"
                                            alt=""
                                          />
                                          <h3>
                                            Your amount{" "}
                                            {
                                              paymentgatewayData.effective_amount
                                            }{" "}
                                            Payment is COD Initiated
                                            Successfully with Order Id{" "}
                                            {paymentgatewayData.order_id}
                                          </h3>
                                          <p>
                                            Thank you for your payment.An
                                            automated payment receipt will be
                                            sent to your registred email.{" "}
                                          </p>

                                          <div
                                            className="back-btn"
                                            onClick={() => navigate("/")}
                                          >
                                            Back to Home
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {paymentgatewayStatus === "STARTED" ? (
                                        <>
                                          <div>
                                            <div className="payment-success">
                                              <img
                                                className="paymentsuccess-img"
                                                src="https://img.freepik.com/free-vector/payment-information-concept-illustration_114360-2886.jpg?w=2000&t=st=1704880841~exp=1704881441~hmac=f6b97c1e2c3625df91be8f0d6201fa54e0a835911652d58230cbc8f482e4a6ac"
                                                alt=""
                                              />
                                              <h3>
                                                Your amount{" "}
                                                {
                                                  paymentgatewayData.effective_amount
                                                }{" "}
                                                Payment is Transaction is
                                                pending. SmarteGateway system
                                                isn't able to find a gateway to
                                                process a transaction with Order
                                                Id {paymentgatewayData.order_id}
                                              </h3>
                                              <p>
                                                Thank you for your payment.An
                                                automated payment receipt will
                                                be sent to your registred email.{" "}
                                              </p>

                                              <div
                                                className="back-btn"
                                                onClick={() => navigate("/")}
                                              >
                                                Back to Home
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {paymentgatewayStatus ===
                                          "AUTO_REFUNDED" ? (
                                            <>
                                              <div>
                                                <div className="payment-success">
                                                  <img
                                                    className="paymentsuccess-img"
                                                    src="https://img.freepik.com/free-vector/payment-information-concept-illustration_114360-2886.jpg?w=2000&t=st=1704880841~exp=1704881441~hmac=f6b97c1e2c3625df91be8f0d6201fa54e0a835911652d58230cbc8f482e4a6ac"
                                                    alt=""
                                                  />
                                                  <h3>
                                                    Your amount{" "}
                                                    {
                                                      paymentgatewayData.effective_amount
                                                    }{" "}
                                                    Payment is Transaction is
                                                    automatically refunded with
                                                    Order Id{" "}
                                                    {
                                                      paymentgatewayData.order_id
                                                    }
                                                  </h3>
                                                  <p>
                                                    Thank you for your
                                                    payment.An automated payment
                                                    receipt will be sent to your
                                                    registred email.{" "}
                                                  </p>

                                                  <div
                                                    className="back-btn"
                                                    onClick={() =>
                                                      navigate("/")
                                                    }
                                                  >
                                                    Back to Home
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              {paymentgatewayStatus ===
                                              "PARTIAL_CHARGED" ? (
                                                <>
                                                  <div>
                                                    <div className="payment-success">
                                                      <img
                                                        className="paymentsuccess-img"
                                                        src="https://img.freepik.com/free-vector/payment-information-concept-illustration_114360-2886.jpg?w=2000&t=st=1704880841~exp=1704881441~hmac=f6b97c1e2c3625df91be8f0d6201fa54e0a835911652d58230cbc8f482e4a6ac"
                                                        alt=""
                                                      />
                                                      <h3>
                                                        Your amount{" "}
                                                        {
                                                          paymentgatewayData.effective_amount
                                                        }{" "}
                                                        Payment is This status
                                                        occurs when the final
                                                        charged or debited
                                                        amount is less than the
                                                        effective order amount.
                                                        It typically happens in
                                                        scenarios involving
                                                        either the use of
                                                        products like a
                                                        merchant's in-house
                                                        wallet, where users can
                                                        claim a certain balance
                                                        from the Merchant's
                                                        wallet, or in Auth and
                                                        Capture flow where the
                                                        total amount captured in
                                                        the end is lower than
                                                        the effective order
                                                        amount with Order Id{" "}
                                                        {
                                                          paymentgatewayData.order_id
                                                        }
                                                      </h3>
                                                      <p>
                                                        Thank you for your
                                                        payment.An automated
                                                        payment receipt will be
                                                        sent to your registred
                                                        email.{" "}
                                                      </p>

                                                      <div
                                                        className="back-btn"
                                                        onClick={() =>
                                                          navigate("/")
                                                        }
                                                      >
                                                        Back to Home
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  {paymentgatewayStatus ===
                                                  "AmountMissmatch" ? (
                                                    <>
                                                      <div className="payment-fail-box">
                                                        <div className="payment-fail-img-box">
                                                          <img
                                                            src="https://img.freepik.com/free-vector/removing-goods-from-basket-refusing-purchase-changing-decision-item-deletion-emptying-trash-online-shopping-app-laptop-user-cartoon-character_335657-2566.jpg?t=st=1707376212~exp=1707376812~hmac=5e14b201ce59a9a03d92106adf897626c6de4bda5bee2acead64d0a1c2ebcd7f"
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div className="payment-fail-text-box">
                                                          <p>
                                                            Your amount{" "}
                                                            {
                                                              paymentgatewayData.effective_amount
                                                            }{" "}
                                                            payment is miss
                                                            matched so we are
                                                            droping the order
                                                            with Order Id{" "}
                                                            {
                                                              paymentgatewayData.order_id
                                                            }
                                                            . Please review your
                                                            payment information
                                                            and try again.
                                                          </p>
                                                          <button
                                                            onClick={() =>
                                                              navigate("/")
                                                            }
                                                          >
                                                            Back to home
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PaymentSuccessPage;
