import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import {
  propertyImages,
  propertyPost,
  updateDesktopImages,
} from "../../redux/property/PropertySlice";
import { MdDelete } from "react-icons/md";
import { BiChevronUp } from "react-icons/bi";
import { toast } from "react-toastify";
import Header from "../../components/layouts/Header";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { authActions } from "../../redux/athentication/Athentication";

const AddProperty = () => {
  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { desktopimage, ispropertydeskimageLoading } = useSelector(
    (store) => store.Property
  );
  const { catTotal } = useSelector((store) => store.Category);
  const { subCatTotal } = useSelector((store) => store.SubCategory);
  const { loginData } = useSelector((store) => store.Athentication);

  const dispatch = useDispatch();

  const [superCategoryId, setSuperCategoryId] = useState("");
  const [supercategory, setSupercategory] = useState("");

  const [catbysuper, setCatbysuper] = useState([]);
  const [pgsectionbycat, setPgsectionbycat] = useState(false);
  const [categoryid, setCategoryid] = useState("");
  const [category, setCategory] = useState("");
  const [subcatbycat, setSubcatbycat] = useState([]);
  const [subCategoryid, setSubCategoryid] = useState("");
  const [subCategory, setSubCategory] = useState("");

  const [cityname, setCityname] = useState("");
  const [apartment, setApartment] = useState("");
  const [locality, setLocality] = useState("");
  const [houseno, setHouseno] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [yourstate, setYourstate] = useState("");

  //Error msg start
  const [subCaterrorMsg, setSubCaterrorMsg] = useState("");
  const [cityerrorMsg, setCityerrorMsg] = useState("");
  const [apartmenterrorMsg, setApartmenterrorMsg] = useState("");
  const [localityerrorMsg, setLocalityerrorMsg] = useState("");
  const [yourstateerrorMsg, setYourstateerrorMsg] = useState("");
  //Error msg end

  const [bedroom, setBedroom] = useState("");
  const [bathroom, setBathroom] = useState("");
  const [balcony, setBalcony] = useState("");
  const [carpetArea, setCarpetArea] = useState("");
  const [yardpercarpetid, setYardpercarpetid] = useState("");
  const [yardpercarpet, setYardpercarpet] = useState("");

  //Error Msg Start
  const [bedroomerrorMsg, setBedroomerrorMsg] = useState("");
  const [bathroomerrorMsg, setBathroomerrorMsg] = useState("");
  const [balconyerrorMsg, setBalconyerrorMsg] = useState("");
  const [carpetAreaErrorMsg, setCarpetAreaErrorMsg] = useState("");

  //Error Msg End

  const [roomtype, setRoomtype] = useState("");
  const [sharingTwo, setSharingTwo] = useState(Boolean(0));
  const [sharingThree, setSharingThree] = useState(Boolean(0));
  const [sharingFour, setSharingFour] = useState(Boolean(0));
  const [sharingFourPlus, setSharingFourPlus] = useState(Boolean(0));
  const [sharingcountRoomStatus, setSharingcountRoomStatus] = useState("");
  const [totalnoBed, setTotalnoBed] = useState("");
  const [noofBedsavailable, setNoofBedsavailable] = useState("");
  const [attachbathroom, setAttachbathroom] = useState(Boolean(0));
  const [attachbalcony, setAttachbalcony] = useState(Boolean(0));
  const [totalnoRoom, setTotalnoRoom] = useState("");
  const [noofRoomsavailable, setNoofRoomsavailable] = useState("");

  const [poojaroom, setPoojaroom] = useState(Boolean(0));
  const [studyroom, setStudyroom] = useState(Boolean(0));
  const [servantroom, setServantroom] = useState(Boolean(0));
  const [storeroom, setStoreroom] = useState(Boolean(0));

  const [furnish, setFurnish] = useState(Boolean(0));
  const [semifurnish, setSemifurnish] = useState(Boolean(0));
  const [unfurnish, setUnfurnish] = useState(Boolean(1));
  const [furnishedStatus, setFurnishedStatus] = useState("Un-furnished");

  const [coverparking, setCoverparking] = useState(0);
  const [openparking, setOpenparking] = useState(0);

  const [floorCount, setFloorCount] = useState("");
  const [totalfloorid, setTotalfloorid] = useState("");
  const [totalfloor, setTotalfloor] = useState("");

  //Error msg start
  const [floorCountErrorMsg, setFloorCountErrorMsg] = useState("");
  const [totalfloorErrorMsg, setTotalfloorErrorMsg] = useState("");
  //Error msg end

  const [unConstructid, setUnConstructid] = useState("");
  const [unConstruct, setUnConstruct] = useState("");
  const [ucMonthid, setUcMonthid] = useState("");
  const [ucMonth, setUcMonth] = useState("");

  const [readyto, setReadyto] = useState(Boolean(0));
  const [undercons, setUndercons] = useState(Boolean(0));
  const [availbilitystatus, setAvailbilitystatus] = useState("");

  //Error msg start
  const [possessionErrorMsg, setPossessionErrorMsg] = useState("");
  const [statusErrorMsg, setStatusErrorMsg] = useState("");
  //Error msg end

  const [oneyear, setOneyear] = useState(Boolean(0));
  const [fiveyear, setFiveyear] = useState(Boolean(0));
  const [tenyear, setTenyear] = useState(Boolean(0));
  const [plustenyear, setPlustenyear] = useState(Boolean(0));
  const [propertyAge, setPropertyAge] = useState("");

  //Error msg start
  const [proprtyageErrorMsg, setProprtyageErrorMsg] = useState("");
  //Error msg end

  const [forboys, setForboys] = useState(Boolean(0));
  const [forgils, setForgils] = useState(Boolean(0));
  const [forany, setForany] = useState(Boolean(0));
  const [availableforGender, setAvailableforGender] = useState("");

  const [suitableforStudents, setSuitableforStudents] = useState(Boolean(0));
  const [suitableforWorking, setSuitableforWorking] = useState(Boolean(0));

  const [textforproperty, setTextforproperty] = useState("");

  //Error msg start
  const [textforPropertyErrorMsg, setTextforPropertyErrorMsg] = useState("");
  //Error msg end

  const [free, setFree] = useState(Boolean(0));
  const [lease, setLease] = useState(Boolean(0));
  const [coOparative, setCoOparative] = useState(Boolean(0));
  const [power, setPower] = useState(Boolean(0));
  const [ownerShip, setOwnerShip] = useState("");
  //Error msg start
  const [ownerShipErrorMsg, setOwnerShipErrorMsg] = useState("");
  //Error msg end

  const [expectpri, setExpectpri] = useState("");
  const [pricepersq, setPricepersq] = useState("");

  //Error msg start
  const [expectpriErrorMsg, setExpectpriErrorMsg] = useState("");
  const [pricepersqErrorMsg, setPricepersqErrorMsg] = useState("");
  //Error msg end

  const [inclusive, setInclusive] = useState(Boolean(0));
  const [govtCharges, setGovtCharges] = useState(Boolean(0));
  const [priNegotiable, setPriNegotiable] = useState(Boolean(0));
  //for PG
  const [includePressForPg, setIncludePressForPg] = useState(false);

  const [expectedRentforPg, setExpectedRentforPg] = useState("");
  const [fixed, setFixed] = useState(Boolean(0));
  const [multiple, setMultiple] = useState(Boolean(0));
  const [noneRent, setNoneRent] = useState(Boolean(0));
  const [securitydepo, setSecuritydepo] = useState("");

  const [laundry, setLaundry] = useState(Boolean(0));
  const [electricity, setElectricity] = useState(Boolean(0));
  const [water, setWater] = useState(Boolean(0));
  const [wifi, setWifi] = useState(Boolean(0));
  const [housekepping, setHousekepping] = useState(Boolean(0));
  const [dth, setDth] = useState(Boolean(0));
  const [noneofabove, setNoneofabove] = useState(Boolean(0));

  const [laundryNotAvail, setLaundryNotAvail] = useState(Boolean(0));
  const [laundryAvail, setLaundryAvail] = useState(Boolean(0));
  const [laundryExcluding, setLaundryExcluding] = useState("");
  const [waterNotAvail, setWaterNotAvail] = useState(Boolean(0));
  const [waterAvail, setWaterAvail] = useState(Boolean(0));
  const [waterExcluding, setWaterExcluding] = useState("");
  const [wifiNotAvail, setWifiNotAvail] = useState(Boolean(0));
  const [wifiAvail, setWifiAvail] = useState(Boolean(0));
  const [wifiExcluding, setWifiExcluding] = useState("");
  const [houseNotAvail, setHouseNotAvail] = useState(Boolean(0));
  const [houseAvail, setHouseAvail] = useState(Boolean(0));
  const [houseExcluding, setHouseExcluding] = useState("");
  const [dthNotAvail, setDthNotAvail] = useState(Boolean(0));
  const [dthAvail, setDthAvail] = useState(Boolean(0));
  const [dthExcluding, setDthExcluding] = useState("");
  const [electricNotAvail, setElectricNotAvail] = useState(Boolean(0));
  const [electricAvail, setElectricAvail] = useState(Boolean(0));
  const [electricExcluding, setElectricExcluding] = useState("");
  const [foodAvail, setFoodAvail] = useState(Boolean(0));
  const [foodNotAvail, setFoodNotAvail] = useState(Boolean(0));
  const [foodDetails, setFoodDetails] = useState("");

  const [monthDurationid, setMonthDurationid] = useState("");
  const [monthDuration, setMonthDuration] = useState("");
  const [lastentrytimeid, setLastentrytimeid] = useState("");
  const [lastentrytime, setLastentrytime] = useState("");

  const [zeromonth, setZeromonth] = useState(Boolean(0));
  const [onemonth, setOnemonth] = useState(Boolean(0));
  const [twomonth, setTwomonth] = useState(Boolean(0));
  const [threemonth, setThreemonth] = useState(Boolean(0));
  const [fourmonth, setFourmonth] = useState(Boolean(0));
  const [fifthmonth, setFifthmonth] = useState(Boolean(0));
  const [sixmonth, setSixmonth] = useState(Boolean(0));
  const [monthofNotice, setMonthofNotice] = useState("");

  const [zeroleaving, setZeroleaving] = useState(Boolean(0));
  const [oneleaving, setOneleaving] = useState(Boolean(0));
  const [multileaving, setMultileaving] = useState(Boolean(0));
  const [yearlycharges, setYearlycharges] = useState("");

  const [petallow, setPetallow] = useState(Boolean(0));
  const [petNotallow, setPetNotallow] = useState(Boolean(0));
  const [petallowed, setPetallowed] = useState("");
  const [visitorsallow, setVisitorsallow] = useState(Boolean(0));
  const [visitorsNotallow, setVisitorsNotallow] = useState(Boolean(0));
  const [visitorsallowed, setVisitorsallowed] = useState("");
  const [smokingallow, setSmokingallow] = useState(Boolean(0));
  const [smokingNotallow, setSmokingNotallow] = useState(Boolean(0));
  const [smokingallowed, setSmokingallowed] = useState("");
  const [alcoholallow, setAlcoholallow] = useState(Boolean(0));
  const [alcoholNotallow, setAlcoholNotallow] = useState(Boolean(0));
  const [alcoholallowed, setAlcoholallowed] = useState("");
  const [eventallow, setEventallow] = useState(Boolean(0));
  const [eventNotallow, setEventNotallow] = useState(Boolean(0));
  const [eventallowed, setEventallowed] = useState("");

  const [anyotherRule, setAnyotherRule] = useState("");

  const yardperCarpetArea = [
    { id: "1", name: "sq.ft." },
    { id: "2", name: "sq.yards." },
    { id: "3", name: "sq.m." },
    { id: "4", name: "acres" },
    { id: "5", name: "marla" },
    { id: "6", name: "cents" },
    { id: "7", name: "bigha" },
    { id: "8", name: "kottah" },
    { id: "9", name: "kanal" },
    { id: "10", name: "grounds" },
    { id: "11", name: "ares" },
    { id: "12", name: "biswa" },
    { id: "13", name: "guntha" },
    { id: "14", name: "aankadam" },
    { id: "15", name: "hectares" },
    { id: "16", name: "rood" },
    { id: "17", name: "chataks" },
    { id: "18", name: "perch" },
  ];

  const floorDetails = [
    { id: "1", name: "Basement" },
    { id: "2", name: "Lower Ground" },
    { id: "3", name: "Ground" },
    { id: "4", name: "1" },
    { id: "5", name: "2" },
    { id: "6", name: "3" },
    { id: "7", name: "4" },
    { id: "8", name: "5" },
    { id: "9", name: "6" },
  ];

  const underConstruction = [
    { id: "1", name: "Within 3 Months" },
    { id: "2", name: "Within 6 Months" },
    { id: "3", name: "By 2024" },
    { id: "4", name: "By 2025" },
    { id: "5", name: "By 2026" },
    { id: "6", name: "By 2027" },
  ];
  const months = [
    { id: "1", name: "January" },
    { id: "2", name: "February" },
    { id: "3", name: "March" },
    { id: "4", name: "April" },
    { id: "5", name: "May" },
    { id: "6", name: "June" },
    { id: "7", name: "July" },
    { id: "8", name: "August" },
    { id: "9", name: "September" },
    { id: "10", name: "October" },
    { id: "11", name: "November" },
    { id: "12", name: "December" },
  ];

  const pgDuration = [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
    { id: "6", name: "6" },
  ];

  const lastEntryTime = [
    { id: "1", name: "7 PM" },
    { id: "2", name: "7.30 PM" },
    { id: "3", name: "8 PM" },
    { id: "4", name: "8.30 PM" },
    { id: "5", name: "9 PM" },
  ];

  const superCatChange = (e) => {
    const { value } = e.target;

    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setSuperCategoryId(value);
    setSupercategory(text);
  };

  useEffect(() => {
    const catbysupercat = catTotal.filter(
      (cat) => cat.superCategoryId === superCategoryId
    );

    setCatbysuper(catbysupercat);
  }, [catTotal, superCategoryId]);

  const categoryChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    let text = e.nativeEvent.target[index].text;
    setCategoryid(value);
    if (text === "Sell") {
      text = "Buy";
    }
    setCategory(text);
  };

  useEffect(() => {
    const subcatbycat = subCatTotal.filter(
      (cat) => cat.categoryId === categoryid
    );

    setSubcatbycat(subcatbycat);
  }, [subCatTotal, categoryid]);

  const suCatChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setSubCategoryid(value);
    setSubCategory(text);
  };

  const citynameChange = (e) => {
    const value = e.target.value;
    setCityname(value);
  };

  const apartmentChange = (e) => {
    const value = e.target.value;
    setApartment(value);
  };

  const localityChange = (e) => {
    const value = e.target.value;
    setLocality(value);
  };

  const housenoChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setHouseno(value);
  };

  const mobilenoChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMobileno(value);
  };

  const yourstateChange = (e) => {
    const value = e.target.value;
    setYourstate(value);
  };

  const bedroomChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setBedroom(value);
  };

  const bathroomChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setBathroom(value);
  };

  const balconyChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setBalcony(value);
  };

  const carpetAreaChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setCarpetArea(value);
  };

  const yardsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setYardpercarpetid(value);
    setYardpercarpet(text);
  };

  const totalBedChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setTotalnoBed(value);
  };
  const bedsavailableChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNoofBedsavailable(value);
  };

  const totalRoomChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setTotalnoRoom(value);
  };

  const roomAvailableChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNoofRoomsavailable(value);
  };

  const floorCountChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setFloorCount(value);
  };

  const floorDatailsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTotalfloorid(value);
    setTotalfloor(text);

  };

  const underConstructChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setUnConstructid(value);
    setUnConstruct(text);
  };

  const ucMonthChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setUcMonthid(value);
    setUcMonth(text);
  };

  const textforpropertyChange = (e) => {
    const value = e.target.value;
    setTextforproperty(value);
  };

  const expectpriChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setExpectpri(value);
    let persqvalue = Math.round(Number(value) / Number(carpetArea));
    setPricepersq(persqvalue);
  };

  const pricepersqChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPricepersq(value);
  };

  const decreseCoverParking = (e) => {
    if (coverparking > 0) {
      let value = coverparking;
      value = coverparking - 1;
      setCoverparking(value);
    }
  };

  const increseCoverParking = (e) => {
    let value = coverparking;
    value = coverparking + 1;
    setCoverparking(value);
  };

  const decreseOpenParking = (e) => {
    if (openparking > 0) {
      let value = openparking;
      value = openparking - 1;
      setOpenparking(value);
    }
  };

  const increseOpenParking = (e) => {
    let value = openparking;
    value = openparking + 1;
    setOpenparking(value);
  };

  const imageChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(propertyImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const imageRemoveClick = (e, imagename, indexnumber) => {
    let imagearray = desktopimage;
    imagearray = imagearray.filter((image) => image !== imagename);
    dispatch(updateDesktopImages(imagearray));
  };

  const pgDurationChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setMonthDurationid(value);
    setMonthDuration(text);
  };

  const lastEntryTimeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setLastentrytimeid(value);
    setLastentrytime(text);
  };

  const expextedRentChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setExpectedRentforPg(value);
  };

  const rulesCahnge = (e) => {
    const value = e.target.value;
    setAnyotherRule(value);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    var date = new Date();
    setSubCaterrorMsg("");
    setCityerrorMsg("");
    setApartmenterrorMsg("");
    setLocalityerrorMsg("");
    setBedroomerrorMsg("");
    setBathroomerrorMsg("");
    setBalconyerrorMsg("");
    setCarpetAreaErrorMsg("");
    setFloorCountErrorMsg("");
    setTotalfloorErrorMsg("");
    setStatusErrorMsg("");
    setProprtyageErrorMsg("");
    setPossessionErrorMsg("");
    setTextforPropertyErrorMsg("");
    setOwnerShipErrorMsg("");
    setExpectpriErrorMsg("");
    setPricepersqErrorMsg("");

    // await dispatch(propertyPost(formdata));
    try {
      if (subCategory === "") {
        setSubCaterrorMsg("Please Select at least one place");
        toast.error("Please Select at least one place", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (cityname === "") {
        setCityerrorMsg("Please enter the city name");
        toast.error("Please enter the city name", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (apartment === "") {
        setApartmenterrorMsg("Please enter the apartment/ society name");
        toast.error("Please enter the apartment/ society name", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (locality === "") {
        setLocalityerrorMsg("Please enter locality name");
        toast.error("Please enter locality name", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (yourstate === "") {
        setYourstateerrorMsg("Please enter state");
        toast.error("Please enter state", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (bedroom === "") {
        setBedroomerrorMsg("Please enter bedroom");
        toast.error("Please enter bedroom", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (bathroom === "") {
        setBathroomerrorMsg("Please enter bathroom count");
        toast.error("Please enter bathroom count", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (balcony === "") {
        setBalconyerrorMsg("Please enter balcony count");
        toast.error("Please enter balcony count", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (carpetArea === "") {
        setCarpetAreaErrorMsg("Please fill the carpet-area");
        toast.error("Please fill the carpet-area", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (floorCount === "") {
        setFloorCountErrorMsg("Please fill floor details");
        toast.error("Please fill floor details", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (totalfloor === "") {
        setTotalfloorErrorMsg("Please fill floor details");
        toast.error("Please fill floor details", {
          position: "top-center",
          autoClose: 1000,
        });
      }else if (readyto === true && propertyAge === "") {
        setProprtyageErrorMsg("Please select age of property");
        toast.error("Please select age of property", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (undercons === true && unConstruct === "") {
        setPossessionErrorMsg("Please select the possession date");
        toast.error("Please select the possession date", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (textforproperty === "") {
        setTextforPropertyErrorMsg("Please fill in the description");
        toast.error("Please fill in the description", {
          position: "top-center",
          autoClose: 1000,
        });
      }
      else if (loginData.role === "User" || loginData.role === "Owner") {
        const formdataSpent = {
          mobile: loginData.mobile,
          creadittospent: 1,
        };
        const url = `${Baseurl}/api/v1/user/shopUserByMobile`;

        let fetchData = await axios.put(url, formdataSpent);

        try {
          const authenticForm = {
            ...fetchData.data.user,
            isAuth: true,
          };
          dispatch(authActions.signIn(authenticForm));
        } catch (error) {}
        if (fetchData.data.success) {
          const formdata = {
            userId: loginData._id,
            superCategoryId: superCategoryId,
            superCategory: supercategory,
            categoryId: categoryid,
            category: category,
            subCategoryId: subCategoryid,
            subCategory: subCategory,
            // propertyID: ,
            // propertyUrl: ,
            // recentLocation: ,
            state: yourstate,
            city: cityname,
            apartment: apartment,
            locality: locality,
            searchArea:
              apartment + " " + locality + " " + cityname + " " + yourstate,
            houseNo: houseno,
            // apartmentType: ,
            noofBedrooms: bedroom,
            noofBathrooms: bathroom,
            balconies: balcony,
            carpetArea: carpetArea,
            selectYards: yardpercarpet,

            poojaRoom: poojaroom,
            studyRoom: studyroom,
            servantRoom: servantroom,
            storeRoom: storeroom,

            roomType: {
              sharingcountRoomStatus: sharingcountRoomStatus,
              totalnoBed: totalnoBed,
              noofBedsavailable: noofBedsavailable,
              totalnoRoom: totalnoRoom,
              noofRoomsavailable: noofRoomsavailable,
            },
            attachbathroom: attachbathroom,
            attachbalcony: attachbalcony,

            furnishing: furnishedStatus,

            reservedParking: {
              openparking: openparking,
              coverparking: coverparking,
            },
            noofFloor: floorCount,
            propertyOnFloor: totalfloor,
            availabilityStatus: availbilitystatus,
            propertyAge: propertyAge,
            availableforGender: availableforGender,
            suitableFor: {
              Students: suitableforStudents,
              WorkingProfessionals: suitableforWorking,
            },
            possessionby: {
              year: unConstruct,
              month: ucMonth,
            },
            // titleImg: ,
            DeskImg: desktopimage,
            ownerShip: ownerShip,
            //pg model start
            pg: {
              expextedrent: expectedRentforPg,
              securitydeposite: securitydepo,
              foodDetails: foodDetails,
              contractDuration: monthDuration,
              monthofNotice: monthofNotice,
              yearlyLeavingCharges: yearlycharges,
              lastentrytime: lastentrytime,
              anyotherRule: anyotherRule,
            },
            includesprice: {
              laundry: laundry,
              electricity: electricity,
              water: water,
              wifi: wifi,
              housekeeping: housekepping,
              dth: dth,
              noneoftheabove: noneofabove,
            },
            serviceExcluding: {
              laundryExtracharge: laundryExcluding,
              waterExtracharge: waterExcluding,
              wifiExtracharge: wifiExcluding,
              housekeepingExtracharge: houseExcluding,
              dthExtracharge: dthExcluding,
              electricityExtracharge: electricExcluding,
            },
            houseRules: {
              pet: petallowed,
              Visitors: visitorsallowed,
              Smoking: smokingallowed,
              Alcohol: alcoholallowed,
              Party: eventallowed,
            },

            //pg model end
            expectedPrice: expectpri,
            pricePerSqft: pricepersq,
            inclusivePrice: inclusive,
            govtCharges: govtCharges,
            priceNegotiable: priNegotiable,
            // boosted: ,
            // maintenanceAmount: ,
            // maintenanceTerm: ,
            // expectedRental: ,
            // bookingAmount: ,
            // annualPayable: ,
            // membershipCharge: ,
            unicProperty: textforproperty,
            // percentageCompletion: ,
          };

          const data = await dispatch(propertyPost(formdata));

          if (data.payload.success === true) {
            toast.success("Property Added Successfully", {
              position: "top-center",
              autoClose: 1000,
            });

            setSupercategory("");
            setCategory("");
            setSubCategory("");
            setCityname("");
            setApartment("");
            setLocality("");
            setHouseno("");
            setMobileno("");
            setYourstate("");

            setBedroom("");
            setBathroom("");
            setBalcony("");
            setCarpetArea("");
            setYardpercarpet("");

            setRoomtype("");
            setSharingcountRoomStatus("");
            setTotalnoBed("");
            setNoofBedsavailable("");
            setTotalnoRoom("");
            setNoofRoomsavailable("");

            setFloorCount("");
            setTotalfloor("");
            setUnConstruct("");
            setUcMonth("");
            setAvailbilitystatus("");
            setPropertyAge("");
            setAvailableforGender("");
            setTextforproperty("");
            setOwnerShip("");
            setExpectpri("");
            setPricepersq("");
            setExpectedRentforPg("");
            setSecuritydepo("");

            setLaundryExcluding("");
            setWaterExcluding("");
            setWifiExcluding("");
            setHouseExcluding("");
            setDthExcluding("");
            setElectricExcluding("");
            setFoodDetails("");
            setMonthDuration("");
            setLastentrytime("");
            setMonthofNotice("");
            setYearlycharges("");

            setPetallowed("");
            setVisitorsallowed("");
            setSmokingallowed("");
            setAlcoholallowed("");
            setEventallowed("");
            setAnyotherRule("");
          }
        } else {
          //not sufficient balance
          toast.error("Please Recharge on Wallet", {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else if (loginData.role === "Dealer" || loginData.role === "Builder") {
        // if(loginData.userExpire > date)
        // {
        const formdata = {
          userId: loginData._id,
          superCategoryId: superCategoryId,
          superCategory: supercategory,
          categoryId: categoryid,
          category: category,
          subCategoryId: subCategoryid,
          subCategory: subCategory,
          // propertyID: ,
          // propertyUrl: ,
          // recentLocation: ,
          state: yourstate,
          city: cityname,
          apartment: apartment,
          locality: locality,
          searchArea:
            apartment + " " + locality + " " + cityname + " " + yourstate,
          houseNo: houseno,
          // apartmentType: ,
          noofBedrooms: bedroom,
          noofBathrooms: bathroom,
          balconies: balcony,
          carpetArea: carpetArea,
          selectYards: yardpercarpet,

          poojaRoom: poojaroom,
          studyRoom: studyroom,
          servantRoom: servantroom,
          storeRoom: storeroom,

          roomType: {
            sharingcountRoomStatus: sharingcountRoomStatus,
            totalnoBed: totalnoBed,
            noofBedsavailable: noofBedsavailable,
            totalnoRoom: totalnoRoom,
            noofRoomsavailable: noofRoomsavailable,
          },
          attachbathroom: attachbathroom,
          attachbalcony: attachbalcony,

          furnishing: furnishedStatus,

          reservedParking: {
            openparking: openparking,
            coverparking: coverparking,
          },
          noofFloor: floorCount,
          propertyOnFloor: totalfloor,
          availabilityStatus: availbilitystatus,
          propertyAge: propertyAge,
          availableforGender: availableforGender,
          suitableFor: {
            Students: suitableforStudents,
            WorkingProfessionals: suitableforWorking,
          },
          possessionby: {
            year: unConstruct,
            month: ucMonth,
          },
          // titleImg: ,
          DeskImg: desktopimage,
          ownerShip: ownerShip,
          //pg model start
          pg: {
            expextedrent: expectedRentforPg,
            securitydeposite: securitydepo,
            foodDetails: foodDetails,
            contractDuration: monthDuration,
            monthofNotice: monthofNotice,
            yearlyLeavingCharges: yearlycharges,
            lastentrytime: lastentrytime,
            anyotherRule: anyotherRule,
          },
          includesprice: {
            laundry: laundry,
            electricity: electricity,
            water: water,
            wifi: wifi,
            housekeeping: housekepping,
            dth: dth,
            noneoftheabove: noneofabove,
          },
          serviceExcluding: {
            laundryExtracharge: laundryExcluding,
            waterExtracharge: waterExcluding,
            wifiExtracharge: wifiExcluding,
            housekeepingExtracharge: houseExcluding,
            dthExtracharge: dthExcluding,
            electricityExtracharge: electricExcluding,
          },
          houseRules: {
            pet: petallowed,
            Visitors: visitorsallowed,
            Smoking: smokingallowed,
            Alcohol: alcoholallowed,
            Party: eventallowed,
          },

          //pg model end
          expectedPrice: expectpri,
          pricePerSqft: pricepersq,
          inclusivePrice: inclusive,
          govtCharges: govtCharges,
          priceNegotiable: priNegotiable,
          // boosted: ,
          // maintenanceAmount: ,
          // maintenanceTerm: ,
          // expectedRental: ,
          // bookingAmount: ,
          // annualPayable: ,
          // membershipCharge: ,
          unicProperty: textforproperty,
          // percentageCompletion: ,
        };
        const data = await dispatch(propertyPost(formdata));

        if (data.payload.success === true) {
          toast.success("Property Added Successfully", {
            position: "top-center",
            autoClose: 1000,
          });

          setSupercategory("");
          setCategory("");
          setSubCategory("");
          setCityname("");
          setApartment("");
          setLocality("");
          setHouseno("");
          setMobileno("");
          setYourstate("");

          setBedroom("");
          setBathroom("");
          setBalcony("");
          setCarpetArea("");
          setYardpercarpet("");

          setRoomtype("");
          setSharingcountRoomStatus("");
          setTotalnoBed("");
          setNoofBedsavailable("");
          setTotalnoRoom("");
          setNoofRoomsavailable("");

          setFloorCount("");
          setTotalfloor("");
          setUnConstruct("");
          setUcMonth("");
          setAvailbilitystatus("");
          setPropertyAge("");
          setAvailableforGender("");
          setTextforproperty("");
          setOwnerShip("");
          setExpectpri("");
          setPricepersq("");
          setExpectedRentforPg("");
          setSecuritydepo("");

          setLaundryExcluding("");
          setWaterExcluding("");
          setWifiExcluding("");
          setHouseExcluding("");
          setDthExcluding("");
          setElectricExcluding("");
          setFoodDetails("");
          setMonthDuration("");
          setLastentrytime("");
          setMonthofNotice("");
          setYearlycharges("");

          setPetallowed("");
          setVisitorsallowed("");
          setSmokingallowed("");
          setAlcoholallowed("");
          setEventallowed("");
          setAnyotherRule("");
        }
        // }
        // else
        // {
        //   toast.error("Please Renew your subscription", {
        //     position: "top-center",
        //     autoClose: 1000,
        //   });
        // }
      }
    } catch (error) {}
  };

  return (
    <>
      <Header />
      <div className="dasboard-wrapper fl-wrap no-pag">
        <div className="dasboard-scrollnav-wrap scroll-to-fixed-fixed scroll-init2 fl-wrap">
          <ul>
            <li>
              <a href="#sec1" className="act-scrlink">
                Basic Details
              </a>
            </li>
            <li>
              <a href="#sec2">Location</a>
            </li>
            <li>
              <a href="#sec4">Property Profile</a>
            </li>
            {/* <li>
              <a href="#sec5">Rooms</a>
            </li> */}
            <li>
              <a href="#sec6">Photos</a>
            </li>
            <li>
              <a href="#sec7">Pricing & Others</a>
            </li>
          </ul>
          <div className="progress-indicator">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
              <circle
                cx={16}
                cy={16}
                r="15.9155"
                className="progress-bar__background"
              />
              <circle
                cx={16}
                cy={16}
                r="15.9155"
                className="progress-bar__progress js-progress-bar"
              />
            </svg>
          </div>
        </div>

        {/* Basic Information Start */}
        <div className="dasboard-widget-title fl-wrap" id="sec1">
          <h5>
            <i className="fas fa-info" />
            Basic Informations
          </h5>
        </div>

        <div className="dasboard-widget-box fl-wrap">
          <div className="custom-form">
            <div className="row">
              <div className="col-sm-4">
                <label>Property Type</label>
                <div className="listsearch-input-item">
                  <Form.Select
                    data-placeholder="All Types"
                    className="nice-select myclassoption chosen-select no-search-select large-select"
                    onChange={(e) => superCatChange(e)}
                    value={superCatTotal._id}
                    name={superCatTotal.name}
                  >
                    <option value="0">{"Select"}</option>
                    {superCatTotal.map((supcat, index) => (
                      <option
                        key={index}
                        value={supcat._id}
                        name={supcat.name}
                        required
                      >
                        {supcat.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
              <div className="col-sm-4">
                <label>I'm Looking to</label>
                <div className="listsearch-input-item">
                  <Form.Select
                    data-placeholder="Apartments"
                    className="nice-select chosen-select no-search-select large-select"
                    onChange={(e) => categoryChange(e)}
                    value={catbysuper._id}
                    name={catbysuper.name}
                  >
                    <option value="0">{"Select"}</option>
                    {catbysuper.map((cat, index) => (
                      <option
                        key={index}
                        value={cat._id}
                        name={cat.name}
                        required
                      >
                        {cat.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
              <div className="col-sm-4">
                <label> Places</label>
                <div className="listsearch-input-item">
                  <Form.Select
                    data-placeholder="Apartments"
                    className="nice-select chosen-select no-search-select large-select"
                    onChange={(e) => suCatChange(e)}
                    value={subcatbycat._id}
                    name={subcatbycat.name}
                  >
                    <option value="0">{"Select"}</option>
                    {subcatbycat.map((subcat, index) => (
                      <option
                        key={index}
                        value={subcat._id}
                        name={subcat.name}
                        required
                      >
                        {subcat.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div style={{ color: "red" }}>{subCaterrorMsg}</div>
              </div>
            </div>
          </div>
        </div>
        {/* Basic Information End */}

        {/* Location Start */}
        <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec2">
          <h5>
            <i className="fas fa-street-view" />
            Location / Contacts
          </h5>
        </div>

        <div className="dasboard-widget-box fl-wrap">
          <div className="custom-form">
            <div className="clearfix" />
            <div className="map-container">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    className="gmap_iframe"
                    width="100%"
                    frameBorder={0}
                    scrolling="no"
                    marginHeight={0}
                    marginWidth={0}
                    src="https://maps.google.com/maps?width=600&height=400&hl=en&q=Sai Ram Buildtech Systems&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                  />
                  <a href="https://pdflist.com/" alt="pdflist.com">
                    Pdflist.com
                  </a>
                </div>
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      ".mapouter{position:relative;text-align:right;width:100%;height:300px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:300px;}.gmap_iframe {height:300px!important;}",
                  }}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="row">
              <div className="col-sm-6">
                <label>
                  City
                  <span className="dec-icon">
                    <i className="far fa-envelope" />
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Enter City Name"
                  required
                  value={cityname}
                  onChange={(e) => citynameChange(e)}
                />
                <div style={{ color: "red" }}>{cityerrorMsg}</div>
              </div>

              <div className="col-sm-6">
                <label>
                  Apartment / Society Name
                  <span className="dec-icon">
                    <i className="far fa-envelope" />
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Apartment / Society Name"
                  required
                  value={apartment}
                  onChange={(e) => apartmentChange(e)}
                />
                <div style={{ color: "red" }}>{apartmenterrorMsg}</div>
              </div>
              <div className="col-sm-6">
                <label>
                  Locality
                  <span className="dec-icon">
                    <i className="far fa-envelope" />
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Locality"
                  required
                  value={locality}
                  onChange={(e) => localityChange(e)}
                />
                <div style={{ color: "red" }}>{localityerrorMsg}</div>
              </div>
              <div className="col-sm-6">
                <label>
                  House Number (optional)
                  <span className="dec-icon">
                    <i className="far fa-envelope" />
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Enter House Number"
                  value={houseno}
                  onChange={(e) => housenoChange(e)}
                />
              </div>
              <div className="col-sm-6">
                <label>
                  Mobile
                  <span className="dec-icon">
                    <i className="far fa-phone" />
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Mobile Number"
                  required
                  minLength={10}
                  maxLength={10}
                  pattern="[6-9]{1}[0-9]{9}"
                  value={mobileno}
                  onChange={(e) => mobilenoChange(e)}
                />
              </div>
              <div className="col-sm-6">
                <label>
                  State
                  <span className="dec-icon">
                    <i className="far fa-envelope" />
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Enter State"
                  value={yourstate}
                  onChange={(e) => yourstateChange(e)}
                />
                <div style={{ color: "red" }}>{yourstateerrorMsg}</div>
              </div>
            </div>
          </div>
        </div>
        {/* Location End */}

        {/* Details Start */}
        <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec4">
          <h5>
            <i className="fas fa-list" />
            Listing Details
          </h5>
        </div>

        <div className="dasboard-widget-box fl-wrap">
          <div className="custom-form">
            <div className="row">
              <div className="col-md-12">
                <div className="col-sm-4">
                  <label>
                    No.of Bedrooms:
                    <span className="dec-icon">
                      <i className="far fa-users" />
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="Bedrooms"
                    required
                    // minLength={1}
                    // maxLength={3}
                    value={bedroom}
                    onChange={(e) => bedroomChange(e)}
                  />
                  <div style={{ color: "red" }}>{bedroomerrorMsg}</div>
                </div>
                <div className="col-sm-4">
                  <label>
                    No.of Bathrooms:
                    <span className="dec-icon">
                      <i className="far fa-bed" />
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="Bathrooms"
                    value={bathroom}
                    onChange={(e) => bathroomChange(e)}
                  />
                  <div style={{ color: "red" }}>{bathroomerrorMsg}</div>
                </div>
                <div className="col-sm-4">
                  <label>
                    No.of Balconies:
                    <span className="dec-icon">
                      <i className="far fa-trees" />
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="Balconies"
                    value={balcony}
                    onChange={(e) => balconyChange(e)}
                  />
                  <div style={{ color: "red" }}>{balconyerrorMsg}</div>
                </div>
              </div>

              {/* Room Type Section Start */}
              {categoryid === "64d1e054698ff3a5f48b0d51" ? (
                <div className="col-md-12">
                  <div>
                    <label style={{ paddingLeft: "15px" }}>Room Type:</label>
                  </div>
                  <div
                    className="col-md-4 col-sm-4 col-xs-6"
                    onClick={() => setRoomtype("Sharing")}
                  >
                    <label htmlFor="Sharing">
                      <input
                        className="labelInput"
                        type="radio"
                        name="Sharing"
                        id="Sharing"
                      />
                      <span>Sharing</span>
                    </label>
                  </div>

                  <div
                    className="col-md-4 col-sm-4 col-xs-6"
                    onClick={() => setRoomtype("Private")}
                  >
                    <label htmlFor="Private">
                      <input
                        className="labelInput"
                        type="radio"
                        name="Sharing"
                        id="Private"
                      />
                      <span>Private</span>
                    </label>
                  </div>

                  {roomtype === "Sharing" ? (
                    <>
                      <div className="col-md-12">
                        <div>
                          <label>How many people can share this room?</label>
                        </div>
                        <div className="col-md-2 col-sm-3 col-xs-6">
                          <label htmlFor="Two">
                            <input
                              className="labelInput"
                              type="radio"
                              name="people"
                              id="Two"
                              value={sharingTwo}
                              onChange={() => {
                                setSharingTwo(!sharingTwo);
                                if (sharingTwo === false) {
                                  setSharingcountRoomStatus("2");
                                  setSharingThree(false);
                                  setSharingFour(false);
                                  setSharingFourPlus(false);
                                }
                              }}
                            />
                            <span>2</span>
                          </label>
                        </div>
                        <div className="col-md-2 col-sm-3 col-xs-6">
                          <label htmlFor="Three">
                            <input
                              className="labelInput"
                              type="radio"
                              name="people"
                              id="Three"
                              value={sharingThree}
                              onChange={() => {
                                setSharingThree(!sharingThree);
                                if (!sharingThree) {
                                  setSharingcountRoomStatus("3");
                                  setSharingTwo(false);
                                  setSharingFour(false);
                                  setSharingFourPlus(false);
                                }
                              }}
                            />
                            <span>3</span>
                          </label>
                        </div>
                        <div className="col-md-2 col-sm-3 col-xs-6">
                          <label htmlFor="Four">
                            <input
                              className="labelInput"
                              type="radio"
                              name="people"
                              id="Four"
                              value={sharingFour}
                              onChange={() => {
                                setSharingFour(!sharingFour);
                                if (!sharingFour) {
                                  setSharingcountRoomStatus("4");
                                  setSharingTwo(false);
                                  setSharingThree(false);
                                  setSharingFourPlus(false);
                                }
                              }}
                            />
                            <span>4</span>
                          </label>
                        </div>
                        <div className="col-md-2 col-sm-3 col-xs-6">
                          <label htmlFor="Five">
                            <input
                              className="labelInput"
                              type="radio"
                              name="people"
                              id="Five"
                              value={sharingFourPlus}
                              onChange={() => {
                                setSharingFourPlus(!sharingFourPlus);
                                if (!sharingFourPlus) {
                                  setSharingcountRoomStatus("4+");
                                  setSharingTwo(false);
                                  setSharingThree(false);
                                  setSharingFour(false);
                                }
                              }}
                            />
                            <span>4+</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div>
                          <label>
                            Capacity and Availability (optional):
                            <span className="dec-icon" style={{ left: "15px" }}>
                              <i className="fal fa-layer-group" />
                            </span>
                          </label>
                          <div className="col-sm-12">
                            <input
                              style={{ paddingLeft: "70px" }}
                              type="text"
                              placeholder="Total no. of beds in PG"
                              value={totalnoBed}
                              onChange={(e) => totalBedChange(e)}
                            />
                          </div>
                        </div>
                        <div>
                          <label>
                            <span className="dec-icon" style={{ left: "15px" }}>
                              <i className="fal fa-layer-group" />
                            </span>
                          </label>
                          <div className="col-sm-12">
                            <input
                              style={{ paddingLeft: "70px" }}
                              type="text"
                              placeholder="No. of beds available in PG"
                              value={noofBedsavailable}
                              onChange={(e) => bedsavailableChange(e)}
                            />
                          </div>
                        </div>
                        <div
                          className="col-sm-6"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ marginTop: "10px", marginRight: "10px" }}
                            value={attachbathroom}
                            onChange={() => setAttachbathroom(!attachbathroom)}
                          />
                          <label htmlFor="pric">Attached Bathroom</label>
                        </div>
                        <div
                          className="col-sm-6"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ marginTop: "10px", marginRight: "10px" }}
                            value={attachbalcony}
                            onChange={() => setAttachbalcony(!attachbalcony)}
                          />
                          <label htmlFor="charg">Attached Balcony</label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {roomtype === "Private" ? (
                    <>
                      <div className="col-sm-6">
                        <div>
                          <label>
                            Capacity and Availability (optional):
                            <span className="dec-icon" style={{ left: "15px" }}>
                              <i className="fal fa-layer-group" />
                            </span>
                          </label>
                          <div className="col-sm-12">
                            <input
                              style={{ paddingLeft: "70px" }}
                              type="text"
                              placeholder="Total no. of rooms in PG"
                              value={totalnoRoom}
                              onChange={(e) => totalRoomChange(e)}
                            />
                          </div>
                        </div>
                        <div>
                          <label>
                            <span className="dec-icon" style={{ left: "15px" }}>
                              <i className="fal fa-layer-group" />
                            </span>
                          </label>
                          <div className="col-sm-12">
                            <input
                              style={{ paddingLeft: "70px" }}
                              type="text"
                              placeholder="No. of rooms available in PG"
                              value={noofRoomsavailable}
                              onChange={(e) => roomAvailableChange(e)}
                            />
                          </div>
                        </div>
                        <div
                          className="col-sm-6"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ marginTop: "10px", marginRight: "10px" }}
                            value={attachbathroom}
                            onChange={() => setAttachbathroom(!attachbathroom)}
                          />
                          <label htmlFor="pric">Attached Bathroom</label>
                        </div>
                        <div
                          className="col-sm-6"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ marginTop: "10px", marginRight: "10px" }}
                            value={attachbalcony}
                            onChange={() => setAttachbalcony(!attachbalcony)}
                          />
                          <label htmlFor="charge">Attached Balcony</label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div></div>
              )}
              {/* Room Type Section End */}

              <div className="col-sm-10 d-flex">
                <label style={{ paddingLeft: "15px" }}>
                  Add Area Details:
                  <span className="dec-icon" style={{ left: "15px" }}>
                    <i className="fal fa-layer-group" />
                  </span>
                </label>
                <div className="col-sm-5">
                  <input
                    style={{ paddingLeft: "70px" }}
                    type="text"
                    placeholder="Carpet Area"
                    value={carpetArea}
                    onChange={(e) => carpetAreaChange(e)}
                  />
                  <div style={{ color: "red" }}>{carpetAreaErrorMsg}</div>
                </div>
                <div className="col-sm-5">
                  <Form.Select
                    data-placeholder="Apartments"
                    className="nice-select chosen-select no-search-select large-select"
                    onChange={(e) => yardsChange(e)}
                    value={yardperCarpetArea.id}
                    name={yardperCarpetArea.name}
                  >
                    <option value="0">{"Select"}</option>
                    {yardperCarpetArea.map((yards, index) => (
                      <option
                        key={index}
                        value={yards.id}
                        name={yards.name}
                        required
                      >
                        {yards.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>

              {/* <div className="col-sm-4">
                <label>
                  Add Area Details:
                  <span className="dec-icon">
                    <i className="far fa-bed" />
                  </span>
                </label>
                <input type="text" placeholder="Carpet Area" defaultValue="" />
              </div>
              <div className="col-sm-4">
                <label>
                  Build-up Area:
                  <span className="dec-icon">
                    <i className="far fa-bath" />
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Build-up Area"
                  defaultValue=""
                />
              </div>
              <div className="col-sm-4">
                <label>
                  Super Build-up Area:
                  <span className="dec-icon">
                    <i className="far fa-warehouse" />
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Super Build-up Area"
                  defaultValue=""
                />
              </div> */}

              {/* Other Rooms Start */}
              <div className="col-md-12">
                <div>
                  <label style={{ paddingLeft: "15px" }}>
                    Other rooms (optional):
                  </label>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-6">
                  <label htmlFor="Pooja">
                    <input
                      className="labelInput"
                      type="checkbox"
                      name="Pooja"
                      id="Pooja"
                      value={poojaroom}
                      onChange={() => {
                        setPoojaroom(!poojaroom);
                      }}
                    />
                    <span>+ Pooja Room</span>
                  </label>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-6 pl-0 pr-0">
                  <label htmlFor="Study">
                    <input
                      className="labelInput"
                      type="checkbox"
                      name="Pooja"
                      id="Study"
                      value={studyroom}
                      onChange={() => {
                        setStudyroom(!studyroom);
                      }}
                    />
                    <span>+ Study Room</span>
                  </label>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-xs-6"
                  style={{ paddingRight: "0" }}
                >
                  <label htmlFor="Servant">
                    <input
                      className="labelInput"
                      type="checkbox"
                      name="Pooja"
                      id="Servant"
                      value={servantroom}
                      onChange={() => {
                        setServantroom(!servantroom);
                      }}
                    />
                    <span>+ Servant Room</span>
                  </label>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-xs-6"
                  style={{ paddingRight: "0" }}
                >
                  <label htmlFor="Store">
                    <input
                      className="labelInput"
                      type="checkbox"
                      name="Pooja"
                      id="Store"
                      value={storeroom}
                      onChange={() => {
                        setStoreroom(!storeroom);
                      }}
                    />
                    <span>+ Store Room</span>
                  </label>
                </div>
              </div>
              {/* Other Rooms End */}
              {/* Furnishing Start */}
              <div className="col-md-12">
                <div>
                  <label style={{ paddingLeft: "15px" }}>
                    Furnishing (optional):
                  </label>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-6">
                  <label htmlFor="Furnish">
                    <input
                      className="labelInput"
                      type="radio"
                      name="Furnishing"
                      id="Furnish"
                      value={furnish}
                      onChange={() => {
                        setFurnish(!furnish);
                        if (furnish === false) {
                          setFurnishedStatus("Furnishied");
                          setUnfurnish(false);
                          setSemifurnish(false);
                        }
                      }}
                    />
                    <span>Furnished</span>
                  </label>
                </div>

                <div className="col-md-4 col-sm-6 col-xs-6">
                  <label htmlFor="Semi">
                    <input
                      className="labelInput"
                      type="radio"
                      name="Furnishing"
                      id="Semi"
                      value={semifurnish}
                      onChange={() => {
                        setSemifurnish(!semifurnish);
                        if (semifurnish === false) {
                          setFurnishedStatus("Semi-furnished");
                          setFurnish(false);
                          setUnfurnish(false);
                        }
                      }}
                    />
                    <span>Semi-furnished</span>
                  </label>
                </div>

                <div className="col-md-4 ">
                  <label htmlFor="unfurnish">
                    <input
                      className="labelInput"
                      type="radio"
                      name="Furnishing"
                      id="unfurnish"
                      defaultChecked
                      value={unfurnish}
                      onChange={() => {
                        setUnfurnish(!unfurnish);
                        if (unfurnish === false) {
                          setFurnishedStatus("Un-furnished");
                          setFurnish(false);
                          setSemifurnish(false);
                        }
                      }}
                    />
                    <span>Un-furnished</span>
                  </label>
                </div>
              </div>
              {/* Furnishing End */}
              {/* Parking Start */}
              <div className="col-md-12" style={{ marginLeft: "15px" }}>
                <div>
                  <label>Reserved Parking (optional):</label>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <label className="ReservedParkingLabel">
                    <span>Cover Parking</span>
                    <div className="CoverParkingCounter">
                      <div
                        className="coverminus"
                        onClick={(e) => decreseCoverParking(e)}
                      >
                        -
                      </div>
                      <div className="coverminus1">{coverparking}</div>
                      <div
                        className="coverminus"
                        onClick={(e) => increseCoverParking(e)}
                      >
                        +
                      </div>
                    </div>
                  </label>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <label className="ReservedParkingLabel">
                    <span>Open Parking</span>
                    <div className="CoverParkingCounter">
                      <div
                        className="coverminus"
                        onClick={(e) => decreseOpenParking(e)}
                      >
                        -
                      </div>
                      <div className="coverminus1">{openparking}</div>
                      <div
                        className="coverminus"
                        onClick={(e) => increseOpenParking(e)}
                      >
                        +
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              {/* Parking End */}
              {/* Floor details Start */}
              <div className="col-sm-12 d-flex">
                <label style={{ paddingLeft: "15px" }}>
                  Floor Details:
                  <span className="dec-icon" style={{ left: "15px" }}>
                    <i className="fal fa-layer-group" />
                  </span>
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    placeholder="Total Floors"
                    value={floorCount}
                    onChange={(e) => floorCountChange(e)}
                  />
                  <div style={{ color: "red" }}>{floorCountErrorMsg}</div>
                </div>
                <div className="col-sm-6">
                  <Form.Select
                    data-placeholder="Apartments"
                    className="nice-select chosen-select no-search-select large-select"
                    onChange={(e) => floorDatailsChange(e)}
                    value={floorDetails.id}
                    name={floorDetails.name}
                  >
                    <option value="0">Select</option>
                    {floorDetails.map((floor, index) => (
                      <option
                        key={index}
                        value={floor.id}
                        name={floor.name}
                        required
                      >
                        {floor.name}
                      </option>
                    ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>{totalfloorErrorMsg}</div>
                </div>
              </div>
              {/* Floor details End */}

              {categoryid === "64d1e054698ff3a5f48b0d51" ? (
                <>
                  <div className="col-md-12">
                    <div>
                      <label style={{ paddingLeft: "15px" }}>
                        Age of property:
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="Firstyear">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Age"
                          id="Firstyear"
                          value={oneyear}
                          onChange={() => {
                            setOneyear(!oneyear);
                            if (oneyear === false) {
                              setPropertyAge("0-1 years");
                              setFiveyear(false);
                              setTenyear(false);
                              setPlustenyear(false);
                            }
                          }}
                        />
                        <span>0-1 years</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="secyear">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Age"
                          id="secyear"
                          value={fiveyear}
                          onChange={() => {
                            setFiveyear(!fiveyear);
                            if (fiveyear === false) {
                              setPropertyAge("1-5 years");
                              setOneyear(false);
                              setTenyear(false);
                              setPlustenyear(false);
                            }
                          }}
                        />
                        <span>1-5 years</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="thyear">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Age"
                          id="thyear"
                          value={tenyear}
                          onChange={() => {
                            setTenyear(!tenyear);
                            if (tenyear === false) {
                              setPropertyAge("5-10 years");
                              setOneyear(false);
                              setFiveyear(false);
                              setPlustenyear(false);
                            }
                          }}
                        />
                        <span>5-10 years</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="fryear">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Age"
                          id="fryear"
                          value={plustenyear}
                          onChange={() => {
                            setPlustenyear(!plustenyear);
                            if (plustenyear === false) {
                              setPropertyAge("10+ years");
                              setOneyear(false);
                              setFiveyear(false);
                              setTenyear(false);
                            }
                          }}
                        />
                        <span>10+ years</span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 d-flex custom-form">
                    <label style={{ paddingLeft: "15px" }}>
                      Available from:
                      <span className="dec-icon" style={{ left: "15px" }}>
                        <i className="fal fa-layer-group" />
                      </span>
                    </label>
                    <div className="col-sm-6">
                      {/* <input type="text" /> */}
                      <input type="date" className="beautiful-date-input" />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div>
                      <label style={{ paddingLeft: "15px" }}>
                        Available for:
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-4 col-xs-4">
                      <label htmlFor="boys">
                        <input
                          className="labelInput"
                          type="radio"
                          name="gender"
                          id="boys"
                          value={forboys}
                          onChange={() => {
                            setForboys(!forboys);
                            if (forboys === false) {
                              setAvailableforGender("Boys");
                              setForgils(false);
                              setForany(false);
                            }
                          }}
                        />
                        <span>Boys</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-4 col-xs-4">
                      <label htmlFor="girls">
                        <input
                          className="labelInput"
                          type="radio"
                          name="gender"
                          id="girls"
                          value={forgils}
                          onChange={() => {
                            setForgils(!forgils);
                            if (forgils === false) {
                              setAvailableforGender("Girls");
                              setForboys(false);
                              setForany(false);
                            }
                          }}
                        />
                        <span>Girls</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-4 col-xs-4">
                      <label htmlFor="any">
                        <input
                          className="labelInput"
                          type="radio"
                          name="gender"
                          id="any"
                          value={forany}
                          onChange={() => {
                            setForany(!forany);
                            if (forany === false) {
                              setAvailableforGender("Any");
                              setForboys(false);
                              setForgils(false);
                            }
                          }}
                        />
                        <span>Any</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6" style={{ marginLeft: "15px" }}>
                    <div>
                      <label>Suitable for:</label>
                    </div>
                    <div
                      className="col-sm-6"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{ marginTop: "10px", marginRight: "10px" }}
                        value={suitableforStudents}
                        onChange={() =>
                          setSuitableforStudents(!suitableforStudents)
                        }
                      />
                      <label htmlFor="pric">Students</label>
                    </div>
                    <div
                      className="col-sm-6"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{ marginTop: "10px", marginRight: "10px" }}
                        value={suitableforWorking}
                        onChange={() =>
                          setSuitableforWorking(!suitableforWorking)
                        }
                      />
                      <label htmlFor="charg">Working Professionals</label>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* Availbility Start */}
                  <div className="col-md-12">
                    <div>
                      <label style={{ paddingLeft: "15px" }}>
                        Availbility Status:
                      </label>
                    </div>
                    <div
                      className="col-md-3 col-sm-6 col-xs-6"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <label htmlFor="Ready">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Availbility"
                          id="Ready"
                          value={readyto}
                          onChange={() => {
                            setReadyto(!readyto);
                            if (readyto === false) {
                              setAvailbilitystatus("Ready to move");
                              setUndercons(false);
                            }
                          }}
                        />
                        <span>Ready to move</span>
                      </label>
                    </div>
                    <div
                      className="col-md-3 col-sm-6 col-xs-6"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <label htmlFor="Construction">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Availbility"
                          id="Construction"
                          value={undercons}
                          onChange={() => {
                            setUndercons(!undercons);
                            if (undercons === false) {
                              setAvailbilitystatus("Under Construction");
                              setReadyto(false);
                            }
                          }}
                        />
                        <span>Under Construction</span>
                      </label>
                    </div>

                    <div style={{ color: "red" }}>{statusErrorMsg}</div>
                  </div>

                  {readyto === true ? (
                    <>
                      {/* Age OF Property Start */}
                      <div className="col-md-12">
                        <div>
                          <label style={{ paddingLeft: "15px" }}>
                            Age of property:
                          </label>
                          <div style={{ color: "red" }}>
                            {proprtyageErrorMsg}
                          </div>
                        </div>

                        <div className="col-md-3 col-sm-6 col-xs-6">
                          <label htmlFor="Firstyear">
                            <input
                              className="labelInput"
                              type="radio"
                              name="Age"
                              id="Firstyear"
                              value={oneyear}
                              onChange={() => {
                                setOneyear(!oneyear);
                                if (oneyear === false) {
                                  setPropertyAge("0-1 years");
                                  setFiveyear(false);
                                  setTenyear(false);
                                  setPlustenyear(false);
                                }
                              }}
                            />
                            <span>0-1 years</span>
                          </label>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-6">
                          <label htmlFor="secyear">
                            <input
                              className="labelInput"
                              type="radio"
                              name="Age"
                              id="secyear"
                              value={fiveyear}
                              onChange={() => {
                                setFiveyear(!fiveyear);
                                if (fiveyear === false) {
                                  setPropertyAge("1-5 years");
                                  setOneyear(false);
                                  setTenyear(false);
                                  setPlustenyear(false);
                                }
                              }}
                            />
                            <span>1-5 years</span>
                          </label>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-6">
                          <label htmlFor="thyear">
                            <input
                              className="labelInput"
                              type="radio"
                              name="Age"
                              id="thyear"
                              value={tenyear}
                              onChange={() => {
                                setTenyear(!tenyear);
                                if (tenyear === false) {
                                  setPropertyAge("5-10 years");
                                  setOneyear(false);
                                  setFiveyear(false);
                                  setPlustenyear(false);
                                }
                              }}
                            />
                            <span>5-10 years</span>
                          </label>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-6">
                          <label htmlFor="fryear">
                            <input
                              className="labelInput"
                              type="radio"
                              name="Age"
                              id="fryear"
                              value={plustenyear}
                              onChange={() => {
                                setPlustenyear(!plustenyear);
                                if (plustenyear === false) {
                                  setPropertyAge("10+ years");
                                  setOneyear(false);
                                  setFiveyear(false);
                                  setTenyear(false);
                                }
                              }}
                            />
                            <span>10+ years</span>
                          </label>
                        </div>
                      </div>
                      {/* Age OF Property End */}
                    </>
                  ) : (
                    <></>
                  )}

                  {undercons === true ? (
                    <>
                      <div className="col-sm-6" style={{ marginBottom: "6px" }}>
                        <div style={{ color: "red" }}>{possessionErrorMsg}</div>

                        <Form.Select
                          data-placeholder="Apartments"
                          className="nice-select chosen-select no-search-select large-select"
                          onChange={(e) => underConstructChange(e)}
                          value={underConstruction.id}
                          name={underConstruction.name}
                        >
                          <option value="0">Expexted By</option>
                          {underConstruction.map((floor, index) => (
                            <option
                              key={index}
                              value={floor.id}
                              name={floor.name}
                            >
                              {floor.name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                      {unConstruct ? (
                        <>
                          <div
                            className="col-sm-6"
                            style={{ marginBottom: "6px" }}
                          >
                            <Form.Select
                              data-placeholder="Apartments"
                              className="nice-select chosen-select no-search-select large-select"
                              onChange={(e) => ucMonthChange(e)}
                              value={months.id}
                              name={months.name}
                            >
                              <option value="0">Month</option>
                              {months.map((month, index) => (
                                <option
                                  key={index}
                                  value={month.id}
                                  name={month.name}
                                >
                                  {month.name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {/* Availbility End */}
                </>
              )}

              <div className="col-sm-12">
                <label>What makes your property unique: </label>

                <div className="listsearch-input-item">
                  <textarea
                    cols={40}
                    rows={3}
                    style={{ height: 135 }}
                    placeholder="Share some details about your property like spacious rooms, well maintained facilities..."
                    spellCheck="false"
                    required
                    value={textforproperty}
                    onChange={(e) => textforpropertyChange(e)}
                  />
                  <div style={{ color: "red" }}>{textforPropertyErrorMsg}</div>
                </div>
              </div>
            </div>
            <div className="clearfix" />
            {/* <label>Amenities: </label> */}
            {/* Checkboxes */}
            {/* <div className=" add-list-tags fl-wrap">
              <ul className="fl-wrap filter-tags no-list-style ds-tg">
                <li>
                  <input
                    id="check-aaa5"
                    type="checkbox"
                    name="check"
                    defaultChecked
                  />
                  <label htmlFor="check-aaa5"> Wi Fi</label>
                </li>
                <li>
                  <input
                    id="check-bb5"
                    type="checkbox"
                    name="check"
                    defaultChecked
                  />
                  <label htmlFor="check-bb5">Pool</label>
                </li>
                <li>
                  <input id="check-dd5" type="checkbox" name="check" />
                  <label htmlFor="check-dd5"> Security</label>
                </li>
                <li>
                  <input id="check-cc5" type="checkbox" name="check" />
                  <label htmlFor="check-cc5"> Laundry Room</label>
                </li>
                <li>
                  <input
                    id="check-ff5"
                    type="checkbox"
                    name="check"
                    defaultChecked
                  />
                  <label htmlFor="check-ff5"> Equipped Kitchen</label>
                </li>
                <li>
                  <input id="check-c4" type="checkbox" name="check" />
                  <label htmlFor="check-c4">Air Conditioning</label>
                </li>
                <li>
                  <input id="check-c18" type="checkbox" name="check" />
                  <label htmlFor="check-c18">Parking</label>
                </li>
              </ul>
            </div> */}
            {/* Checkboxes end */}
          </div>
        </div>

        {/* Details End */}

        {/* photos start */}
        <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec6">
          <h5>
            <i className="fas fa-ruler-combined" />
            Photos
          </h5>
        </div>

        <div className="dasboard-widget-box fl-wrap">
          <div className="custom-form add_room-item-wrap">
            <div className="add_room-container fl-wrap">
              <div className="add_room-item fl-wrap">
                <span
                  className="remove-rp tolt"
                  data-microtip-position="left"
                  data-tooltip="Remove"
                >
                  <i className="fal fa-times-circle" />
                </span>
                <div className="row">
                  <div className="col-sm-6">
                    <label>Upload Images</label>
                    <div className="listsearch-input-item fl-wrap">
                      <div className="fuzone">
                        <div className="fu-text">
                          <span>
                            <i className="far fa-cloud-upload-alt" /> Click here
                            or drop files to upload
                          </span>
                          <div className="photoUpload-files fl-wrap" />
                        </div>

                        <input
                          type="file"
                          className="upload"
                          onChange={imageChange}
                          multiple
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    {ispropertydeskimageLoading ? (
                      <div></div>
                    ) : (
                      <>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {desktopimage.map((image, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                height: "100px",
                                width: "150px",
                                margin: "5px",
                              }}
                            >
                              <img
                                src={image}
                                height="100%"
                                style={{
                                  height: "100px",
                                  width: "150px",
                                  margin: "5px",
                                }}
                                alt="img"
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 3,
                                }}
                                onClick={(e) =>
                                  imageRemoveClick(e, image, index)
                                }
                              >
                                <h6
                                  style={{
                                    fontWeight: "bold",
                                    color: "red",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                >
                                  X
                                </h6>
                              </span>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* photos end*/}
        {/* Pricing and others start */}
        {categoryid === "64d1e054698ff3a5f48b0d51" ? (
          <>
            <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec7">
              <h5>
                <i className="fas fa-sliders-h" />
                Pricing and Others
              </h5>
            </div>
            <div className="dasboard-widget-box fl-wrap">
              <div className="custom-form">
                <div className="row">
                  <div className="col-sm-12 d-flex">
                    <label style={{ paddingLeft: "15px" }}>Rent Details:</label>
                    <div className="col-sm-6">
                      <input
                        style={{ paddingLeft: 10 }}
                        type="text"
                        placeholder="₹ Expected Rent"
                        required
                        value={expectedRentforPg}
                        onChange={(e) => expextedRentChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div>
                      <label style={{ paddingLeft: "15px" }}>
                        Security deposit: (optional)
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-4 col-xs-4">
                      <label htmlFor="fixedDeposit">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Security"
                          id="fixedDeposit"
                          value={fixed}
                          onChange={() => {
                            setFixed(!fixed);
                            if (fixed === false) {
                              setSecuritydepo("Fixed");
                              setMultiple(false);
                              setNoneRent(false);
                            }
                          }}
                        />
                        <span>Fixed</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-4 col-xs-8">
                      <label htmlFor="multiDeposit">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Security"
                          id="multiDeposit"
                          value={multiple}
                          onChange={() => {
                            setMultiple(!multiple);
                            if (multiple === false) {
                              setSecuritydepo("Multiple of Rent");
                              setFixed(false);
                              setNoneRent(false);
                            }
                          }}
                        />
                        <span>Multiple of Rent</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-4 col-xs-12">
                      <label htmlFor="noneDeposit">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Security"
                          id="noneDeposit"
                          value={noneRent}
                          onChange={() => {
                            setNoneRent(!noneRent);
                            if (noneRent === false) {
                              setSecuritydepo("None");
                              setFixed(false);
                              setMultiple(false);
                            }
                          }}
                        />
                        <span>None</span>
                      </label>
                    </div>
                  </div>

                  {/* Total Price Include Start */}
                  <div className="col-md-12">
                    <div>
                      <label style={{ paddingLeft: "15px" }}>
                        Total Price Includes :
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="Laundry">
                        <input
                          className="labelInput"
                          type="checkbox"
                          name="Laundry"
                          id="Laundry"
                          value={laundry}
                          onChange={() => setLaundry(!laundry)}
                        />
                        <span>+ Laundry</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="Electricity">
                        <input
                          className="labelInput"
                          type="checkbox"
                          name="Laundry"
                          id="Electricity"
                          value={electricity}
                          onChange={() => setElectricity(!electricity)}
                        />
                        <span>+ Electricity</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="Water">
                        <input
                          className="labelInput"
                          type="checkbox"
                          name="Laundry"
                          id="Water"
                          value={water}
                          onChange={() => setWater(!water)}
                        />
                        <span>+ Water</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="Wifi">
                        <input
                          className="labelInput"
                          type="checkbox"
                          name="Laundry"
                          id="Wifi"
                          value={wifi}
                          onChange={() => setWifi(!wifi)}
                        />
                        <span>+ Wifi</span>
                      </label>
                    </div>
                    <div
                      className="col-md-3 col-sm-6 col-xs-6"
                      style={{ paddingRight: "0" }}
                    >
                      <label htmlFor="Housekepping">
                        <input
                          className="labelInput"
                          type="checkbox"
                          name="Laundry"
                          id="Housekepping"
                          value={housekepping}
                          onChange={() => setHousekepping(!housekepping)}
                        />
                        <span>+ Housekepping</span>
                      </label>
                    </div>
                    <div
                      className="col-md-3 col-sm-6 col-xs-6"
                      style={{ paddingRight: "0" }}
                    >
                      <label htmlFor="DTH">
                        <input
                          className="labelInput"
                          type="checkbox"
                          name="Laundry"
                          id="DTH"
                          value={dth}
                          onChange={() => setDth(!dth)}
                        />
                        <span>+ DTH</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                      <label htmlFor="Noneof">
                        <input
                          className="labelInput"
                          type="checkbox"
                          name="Laundry"
                          id="Noneof"
                          value={noneofabove}
                          onChange={() => setNoneofabove(!noneofabove)}
                        />
                        <span>+ None of the above</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {includePressForPg === true ? (
                      <>
                        <label
                          htmlFor="servicesnot"
                          style={{ paddingLeft: "15px" }}
                        >
                          <span>Services Excluding Price:</span>
                        </label>
                        <div className="col-sm-12">
                          <div>
                            <label>Laundry (optional):</label>
                          </div>
                          <div
                            className="col-sm-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              className=""
                              type="radio"
                              name="Laundry"
                              id="Laundry"
                              style={{
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                              value={laundryNotAvail}
                              onChange={() => {
                                setLaundryNotAvail(!laundryNotAvail);
                                if (laundryNotAvail === false) {
                                  setLaundryExcluding("Not available");
                                  setLaundryAvail(false);
                                }
                              }}
                            />
                            <label htmlFor="Laundry">Not available</label>
                          </div>
                          <div
                            className="col-sm-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              className=""
                              type="radio"
                              name="Laundry"
                              id="Laundry"
                              style={{
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                              value={laundryAvail}
                              onChange={() => {
                                setLaundryAvail(!laundryAvail);
                                if (laundryAvail === false) {
                                  setLaundryExcluding(
                                    "Available for extra charge"
                                  );
                                  setLaundryNotAvail(false);
                                }
                              }}
                            />
                            <label htmlFor="Laundry">
                              Available for extra charge
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div>
                            <label>Water (optional):</label>
                          </div>
                          <div
                            className="col-sm-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="radio"
                              name="Water"
                              id="Water"
                              style={{
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                              value={waterNotAvail}
                              onChange={() => {
                                setWaterNotAvail(!waterNotAvail);
                                if (waterNotAvail === false) {
                                  setWaterExcluding("Not available");
                                  setWaterAvail(false);
                                }
                              }}
                            />
                            <label htmlFor="Water">Not available</label>
                          </div>
                          <div
                            className="col-sm-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="radio"
                              name="Water"
                              id="Water"
                              style={{
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                              value={waterAvail}
                              onChange={() => {
                                setWaterAvail(!waterAvail);
                                if (waterAvail === false) {
                                  setWaterExcluding(
                                    "Available for extra charge"
                                  );
                                  setWaterNotAvail(false);
                                }
                              }}
                            />
                            <label htmlFor="Water">
                              Available for extra charge
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div>
                            <label>Wi-fi (optional):</label>
                          </div>
                          <div
                            className="col-sm-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="radio"
                              name="Wi-fi"
                              id="Wi-fi"
                              style={{
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                              value={wifiNotAvail}
                              onChange={() => {
                                setWifiNotAvail(!wifiNotAvail);
                                if (wifiNotAvail === false) {
                                  setWifiExcluding("Not available");
                                  setWifiAvail(false);
                                }
                              }}
                            />
                            <label htmlFor="Wi-fi">Not available</label>
                          </div>
                          <div
                            className="col-sm-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="radio"
                              name="Wi-fi"
                              id="Wi-fi"
                              style={{
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                              value={wifiAvail}
                              onChange={() => {
                                setWifiAvail(!wifiAvail);
                                if (wifiAvail === false) {
                                  setWifiExcluding(
                                    "Available for extra charge"
                                  );
                                  setWifiNotAvail(false);
                                }
                              }}
                            />
                            <label htmlFor="Wi-fi">
                              Available for extra charge
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div>
                            <label>Housekeeping (optional):</label>
                          </div>
                          <div
                            className="col-sm-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="radio"
                              name="House"
                              id="House"
                              style={{
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                              value={houseNotAvail}
                              onChange={() => {
                                setHouseNotAvail(!houseNotAvail);
                                if (houseNotAvail === false) {
                                  setHouseExcluding("Not available");
                                  setHouseAvail(false);
                                }
                              }}
                            />
                            <label htmlFor="House">Not available</label>
                          </div>
                          <div
                            className="col-sm-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="radio"
                              name="House"
                              id="House"
                              style={{
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                              value={houseAvail}
                              onChange={() => {
                                setHouseAvail(!houseAvail);
                                if (houseAvail === false) {
                                  setHouseExcluding(
                                    "Available for extra charge"
                                  );
                                  setHouseNotAvail(false);
                                }
                              }}
                            />
                            <label htmlFor="House">
                              Available for extra charge
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div>
                            <label>DTH (optional):</label>
                          </div>
                          <div
                            className="col-sm-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="radio"
                              name="DTH"
                              id="DTH"
                              style={{
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                              value={dthNotAvail}
                              onChange={() => {
                                setDthNotAvail(!dthNotAvail);
                                if (dthNotAvail === false) {
                                  setDthExcluding("Not available");
                                  setDthAvail(false);
                                }
                              }}
                            />
                            <label htmlFor="DTH">Not available</label>
                          </div>
                          <div
                            className="col-sm-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="radio"
                              name="DTH"
                              id="DTH"
                              style={{
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                              value={dthAvail}
                              onChange={() => {
                                setDthAvail(!dthAvail);
                                if (dthAvail === false) {
                                  setDthExcluding("Available for extra charge");
                                  setDthNotAvail(false);
                                }
                              }}
                            />
                            <label htmlFor="DTH">
                              Available for extra charge
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div>
                            <label>Electricity (optional):</label>
                          </div>
                          <div
                            className="col-sm-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="radio"
                              name="Electric"
                              id="Electric"
                              style={{
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                              value={electricNotAvail}
                              onChange={() => {
                                setElectricNotAvail(!electricNotAvail);
                                if (electricNotAvail === false) {
                                  setElectricExcluding("Not available");
                                  setElectricAvail(false);
                                }
                              }}
                            />
                            <label htmlFor="Electric">Not available</label>
                          </div>
                          <div
                            className="col-sm-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="radio"
                              name="Electric"
                              id="Electric"
                              style={{
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                              value={electricAvail}
                              onChange={() => {
                                setElectricAvail(!electricAvail);
                                if (electricAvail === false) {
                                  setElectricExcluding(
                                    "Available for extra charge"
                                  );
                                  setElectricNotAvail(false);
                                }
                              }}
                            />
                            <label htmlFor="Electric">
                              Available for extra charge
                            </label>
                          </div>
                        </div>
                        <label
                          htmlFor="servicesnot"
                          onClick={() =>
                            setIncludePressForPg(!includePressForPg)
                          }
                        >
                          <span
                            style={{
                              color: "#4285f4",
                              fontSize: "13px",
                              cursor: "pointer",
                            }}
                          >
                            <b>
                              <BiChevronUp /> Add Details about services not
                              included in Price
                            </b>
                          </span>
                        </label>
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="servicesnot"
                          onClick={() =>
                            setIncludePressForPg(!includePressForPg)
                          }
                        >
                          <span
                            style={{
                              color: "#4285f4",
                              fontSize: "13px",
                              cursor: "pointer",
                            }}
                          >
                            <b>
                              + Add Details about services not included in Price
                            </b>
                          </span>
                        </label>
                      </>
                    )}
                  </div>
                  {/* Total Price Include End */}
                  <div className="col-md-12">
                    <div>
                      <label style={{ paddingLeft: "15px" }}>
                        Food Details:{" "}
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-4 col-xs-6">
                      <label htmlFor="Availablefood">
                        <input
                          className="labelInput"
                          type="radio"
                          name="AvailableFood"
                          id="Availablefood"
                          value={foodAvail}
                          onChange={() => {
                            setFoodAvail(!foodAvail);
                            if (foodAvail === false) {
                              setFoodDetails("Available");
                              setFoodNotAvail(false);
                            }
                          }}
                        />
                        <span>Available</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-4 col-xs-6">
                      <label htmlFor="notvailablefood">
                        <input
                          className="labelInput"
                          type="radio"
                          name="AvailableFood"
                          id="notvailablefood"
                          value={foodNotAvail}
                          onChange={() => {
                            setFoodNotAvail(!foodNotAvail);
                            if (foodNotAvail === false) {
                              setFoodDetails("Not Available");
                              setFoodAvail(false);
                            }
                          }}
                        />
                        <span>Not Available</span>
                      </label>
                    </div>
                  </div>

                  <div
                    className="col-sm-12 d-flex"
                    style={{ marginBottom: "10px" }}
                  >
                    <label style={{ paddingLeft: "15px" }}>
                      Minimum Contract Duration (optional):
                    </label>
                    <div className="col-sm-6">
                      <Form.Select
                        data-placeholder="Apartments"
                        className="nice-select chosen-select no-search-select large-select"
                        onChange={(e) => pgDurationChange(e)}
                        value={pgDuration.id}
                        name={pgDuration.name}
                      >
                        <option value="0">Months</option>
                        {pgDuration.map((months, index) => (
                          <option
                            key={index}
                            value={months.id}
                            name={months.name}
                            required
                          >
                            {months.name}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div>
                      <label style={{ paddingLeft: "15px" }}>
                        Months of Notice:{" "}
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="0Month">
                        <input
                          className="labelInput"
                          type="radio"
                          name="NoticeMonth"
                          id="0Month"
                          value={zeromonth}
                          onChange={() => {
                            setZeromonth(!zeromonth);
                            if (zeromonth === false) {
                              setMonthofNotice("None");
                              setOnemonth(false);
                              setTwomonth(false);
                              setThreemonth(false);
                              setFourmonth(false);
                              setFifthmonth(false);
                              setSixmonth(false);
                            }
                          }}
                        />
                        <span>None</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="1Month">
                        <input
                          className="labelInput"
                          type="radio"
                          name="NoticeMonth"
                          id="1Month"
                          value={onemonth}
                          onChange={() => {
                            setOnemonth(!onemonth);
                            if (onemonth === false) {
                              setMonthofNotice("1 month");
                              setZeromonth(false);
                              setTwomonth(false);
                              setThreemonth(false);
                              setFourmonth(false);
                              setFifthmonth(false);
                              setSixmonth(false);
                            }
                          }}
                        />
                        <span>1 month</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="2Month">
                        <input
                          className="labelInput"
                          type="radio"
                          name="NoticeMonth"
                          id="2Month"
                          value={twomonth}
                          onChange={() => {
                            setTwomonth(!twomonth);
                            if (twomonth === false) {
                              setMonthofNotice("2 months");
                              setZeromonth(false);
                              setOnemonth(false);
                              setThreemonth(false);
                              setFourmonth(false);
                              setFifthmonth(false);
                              setSixmonth(false);
                            }
                          }}
                        />
                        <span>2 months</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="3Month">
                        <input
                          className="labelInput"
                          type="radio"
                          name="NoticeMonth"
                          id="3Month"
                          value={threemonth}
                          onChange={() => {
                            setThreemonth(!threemonth);
                            if (threemonth === false) {
                              setMonthofNotice("3 months");
                              setZeromonth(false);
                              setOnemonth(false);
                              setTwomonth(false);
                              setFourmonth(false);
                              setFifthmonth(false);
                              setSixmonth(false);
                            }
                          }}
                        />
                        <span>3 months</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="4Month">
                        <input
                          className="labelInput"
                          type="radio"
                          name="NoticeMonth"
                          id="4Month"
                          value={fourmonth}
                          onChange={() => {
                            setFourmonth(!fourmonth);
                            if (fourmonth === false) {
                              setMonthofNotice("4 months");
                              setZeromonth(false);
                              setOnemonth(false);
                              setTwomonth(false);
                              setThreemonth(false);
                              setFifthmonth(false);
                              setSixmonth(false);
                            }
                          }}
                        />
                        <span>4 months</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="5Month">
                        <input
                          className="labelInput"
                          type="radio"
                          name="NoticeMonth"
                          id="5Month"
                          value={fifthmonth}
                          onChange={() => {
                            setFifthmonth(!fifthmonth);
                            if (fifthmonth === false) {
                              setMonthofNotice("5 months");
                              setZeromonth(false);
                              setOnemonth(false);
                              setTwomonth(false);
                              setThreemonth(false);
                              setFourmonth(false);
                              setSixmonth(false);
                            }
                          }}
                        />
                        <span>5 months</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="6Month">
                        <input
                          className="labelInput"
                          type="radio"
                          name="NoticeMonth"
                          id="6Month"
                          value={sixmonth}
                          onChange={() => {
                            setSixmonth(!sixmonth);
                            if (sixmonth === false) {
                              setMonthofNotice("6 months");
                              setZeromonth(false);
                              setOnemonth(false);
                              setTwomonth(false);
                              setThreemonth(false);
                              setFourmonth(false);
                              setFifthmonth(false);
                            }
                          }}
                        />
                        <span>6 months</span>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div>
                      <label style={{ paddingLeft: "15px" }}>
                        Early leaving charges (optional):{" "}
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="0leaving">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Earlyleaving"
                          id="0leaving"
                          value={zeroleaving}
                          onChange={() => {
                            setZeroleaving(!zeroleaving);
                            if (zeroleaving === false) {
                              setYearlycharges("None");
                              setOneleaving(false);
                              setMultileaving(false);
                            }
                          }}
                        />
                        <span>None</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <label htmlFor="1leaving">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Earlyleaving"
                          id="1leaving"
                          value={oneleaving}
                          onChange={() => {
                            setOneleaving(!oneleaving);
                            if (oneleaving === false) {
                              setYearlycharges("Fixed");
                              setZeroleaving(false);
                              setMultileaving(false);
                            }
                          }}
                        />
                        <span>Fixed</span>
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                      <label htmlFor="2leaving">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Earlyleaving"
                          id="2leaving"
                          value={multileaving}
                          onChange={() => {
                            setMultileaving(!multileaving);
                            if (multileaving === false) {
                              setYearlycharges("Multiple of Rent");
                              setZeroleaving(false);
                              setOneleaving(false);
                            }
                          }}
                        />
                        <span>Multiple of Rent</span>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div>
                      <label style={{ paddingLeft: "15px" }}>
                        Some house rules (optional):{" "}
                      </label>
                    </div>
                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-md-8">
                        <label>Pets allowed: </label>
                      </div>
                      <div className="col-md-4" style={{ display: "flex" }}>
                        <label htmlFor="Pets">
                          <input
                            className=""
                            type="radio"
                            name="Pets"
                            id="Pets"
                            value={petallow}
                            onChange={() => {
                              setPetallow(!petallow);
                              if (petallow === false) {
                                setPetallowed("Yes");
                                setPetNotallow(false);
                              }
                            }}
                          />
                          <span>Yes</span>
                        </label>
                        <label htmlFor="Pets">
                          <input
                            className=""
                            type="radio"
                            name="Pets"
                            id="Pets"
                            value={petNotallow}
                            onChange={() => {
                              setPetNotallow(!petNotallow);
                              if (petNotallow === false) {
                                setPetallowed("No");
                                setPetallow(false);
                              }
                            }}
                          />
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-md-8">
                        <label>Visitors allowed: </label>
                      </div>
                      <div className="col-md-4" style={{ display: "flex" }}>
                        <label htmlFor="Visitors">
                          <input
                            className=""
                            type="radio"
                            name="Visitors"
                            id="Visitors"
                            value={visitorsallow}
                            onChange={() => {
                              setVisitorsallow(!visitorsallow);
                              if (visitorsallow === false) {
                                setVisitorsallowed("Yes");
                                setVisitorsNotallow(false);
                              }
                            }}
                          />
                          <span>Yes</span>
                        </label>
                        <label htmlFor="Visitors">
                          <input
                            className=""
                            type="radio"
                            name="Visitors"
                            id="Visitors"
                            value={visitorsNotallow}
                            onChange={() => {
                              setVisitorsNotallow(!visitorsNotallow);
                              if (visitorsNotallow === false) {
                                setVisitorsallowed("No");
                                setVisitorsallow(false);
                              }
                            }}
                          />
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-md-8">
                        <label>Smoking allowed: </label>
                      </div>
                      <div className="col-md-4" style={{ display: "flex" }}>
                        <label htmlFor="Smoking">
                          <input
                            className=""
                            type="radio"
                            name="Smoking"
                            id="Smoking"
                            value={smokingallow}
                            onChange={() => {
                              setSmokingallow(!smokingallow);
                              if (smokingallow === false) {
                                setSmokingallowed("Yes");
                                setSmokingNotallow(false);
                              }
                            }}
                          />
                          <span>Yes</span>
                        </label>
                        <label htmlFor="Smoking">
                          <input
                            className=""
                            type="radio"
                            name="Smoking"
                            id="Smoking"
                            value={smokingNotallow}
                            onChange={() => {
                              setSmokingNotallow(!smokingNotallow);
                              if (smokingNotallow === false) {
                                setSmokingallowed("No");
                                setSmokingallow(false);
                              }
                            }}
                          />
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-md-8">
                        <label>Alcohol allowed: </label>
                      </div>
                      <div className="col-md-4" style={{ display: "flex" }}>
                        <label htmlFor="Alcohol">
                          <input
                            className=""
                            type="radio"
                            name="Alcohol"
                            id="Alcohol"
                            value={alcoholallow}
                            onChange={() => {
                              setAlcoholallow(!alcoholallow);
                              if (alcoholallow === false) {
                                setAlcoholallowed("Yes");
                                setAlcoholNotallow(false);
                              }
                            }}
                          />
                          <span>Yes</span>
                        </label>
                        <label htmlFor="Alcohol">
                          <input
                            className=""
                            type="radio"
                            name="Alcohol"
                            id="Alcohol"
                            value={alcoholNotallow}
                            onChange={() => {
                              setAlcoholNotallow(!alcoholNotallow);
                              if (alcoholNotallow === false) {
                                setAlcoholallowed("No");
                                setAlcoholallow(false);
                              }
                            }}
                          />
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-md-8">
                        <label>Party/Event allowed: </label>
                      </div>
                      <div className="col-md-4" style={{ display: "flex" }}>
                        <label htmlFor="Event">
                          <input
                            className=""
                            type="radio"
                            name="Event"
                            id="Event"
                            value={eventallow}
                            onChange={() => {
                              setEventallow(!eventallow);
                              if (eventallow === false) {
                                setEventallowed("Yes");
                                setEventNotallow(false);
                              }
                            }}
                          />
                          <span>Yes</span>
                        </label>
                        <label htmlFor="Event">
                          <input
                            className=""
                            type="radio"
                            name="Event"
                            id="Event"
                            value={eventNotallow}
                            onChange={() => {
                              setEventNotallow(!eventNotallow);
                              if (eventNotallow === false) {
                                setEventallowed("Yes");
                                setEventallow(false);
                              }
                            }}
                          />
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-sm-12 d-flex"
                    style={{ marginBottom: "10px" }}
                  >
                    <div className="col-sm-6">
                      <Form.Select
                        data-placeholder="Apartments"
                        className="nice-select chosen-select no-search-select large-select"
                        onChange={(e) => lastEntryTimeChange(e)}
                        value={lastEntryTime.id}
                        name={lastEntryTime.name}
                      >
                        <option value="0">Last entry time</option>
                        {lastEntryTime.map((time, index) => (
                          <option
                            key={index}
                            value={time.id}
                            name={time.name}
                            required
                          >
                            {time.name}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <label>Have any other rule? </label>
                    <div className="listsearch-input-item">
                      <textarea
                        cols={30}
                        rows={3}
                        style={{ height: 85 }}
                        placeholder="Type any other rules that guest should follow..."
                        spellCheck="false"
                        required
                        value={anyotherRule}
                        onChange={(e) => rulesCahnge(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="dasboard-widget-title dwb-mar fl-wrap" id="sec7">
              <h5>
                <i className="fas fa-sliders-h" />
                Pricing and Others
              </h5>
            </div>
            <div className="dasboard-widget-box fl-wrap">
              <div className="custom-form">
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      <label style={{ paddingLeft: "15px" }}>Ownership:</label>
                      <label style={{ color: "red", paddingLeft: "15px" }}>
                        {ownerShipErrorMsg}
                      </label>
                    </div>

                    <div
                      className="col-md-3 col-sm-6 col-xs-6"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <label htmlFor="freehold">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Owner"
                          id="freehold"
                          value={free}
                          onChange={() => {
                            setFree(!free);
                            if (free === false) {
                              setOwnerShip("Freehold");
                              setLease(false);
                              setCoOparative(false);
                              setPower(false);
                            }
                          }}
                        />
                        <span>Freehold</span>
                      </label>
                    </div>
                    <div
                      className="col-md-3 col-sm-6 col-xs-6"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <label htmlFor="freelease">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Owner"
                          id="freelease"
                          value={lease}
                          onChange={() => {
                            setLease(!lease);
                            if (lease === false) {
                              setOwnerShip("Leasehold");
                              setFree(false);
                              setCoOparative(false);
                              setPower(false);
                            }
                          }}
                        />
                        <span>Leasehold</span>
                      </label>
                    </div>
                    <div
                      className="col-md-3 col-sm-6 col-xs-6"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <label htmlFor="society">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Owner"
                          id="society"
                          value={coOparative}
                          onChange={() => {
                            setCoOparative(!coOparative);
                            if (coOparative === false) {
                              setOwnerShip("Co-Oparative Society");
                              setFree(false);
                              setLease(false);
                              setPower(false);
                            }
                          }}
                        />
                        <span>Co-Oparative Society</span>
                      </label>
                    </div>
                    <div
                      className="col-md-3 col-sm-6 col-xs-6"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <label htmlFor="attorney">
                        <input
                          className="labelInput"
                          type="radio"
                          name="Owner"
                          id="attorney"
                          value={power}
                          onChange={() => {
                            setPower(!power);
                            if (power === false) {
                              setOwnerShip("Power of Attorney");
                              setFree(false);
                              setLease(false);
                              setCoOparative(false);
                            }
                          }}
                        />
                        <span>Power of Attorney</span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-10 d-flex">
                    <label style={{ paddingLeft: "15px" }}>
                      Price Details:
                      <span className="dec-icon" style={{ left: "15px" }}>
                        <i className="fal fa-layer-group" />
                      </span>
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        placeholder="₹ Expected Price"
                        required
                        value={expectpri}
                        onChange={(e) => expectpriChange(e)}
                      />
                      <div style={{ color: "red" }}>{expectpriErrorMsg}</div>
                    </div>
                    <div className="col-sm-4">
                      <div style={{ color: "red" }}>{pricepersqErrorMsg}</div>
                      <input
                        style={{ paddingLeft: 10 }}
                        type="text"
                        placeholder="₹ Price per sq. ft."
                        required
                        value={pricepersq}
                        onChange={(e) => pricepersqChange(e)}
                      />
                    </div>
                    {/* checkbox start  */}
                    <div
                      className="col-sm-6"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="price"
                        style={{ marginTop: "10px", marginRight: "10px" }}
                        value={inclusive}
                        onChange={() => setInclusive(!inclusive)}
                      />
                      <label htmlFor="price">All Inclusive price</label>
                    </div>
                    <div
                      className="col-sm-6"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="charges"
                        style={{ marginTop: "10px", marginRight: "10px" }}
                        value={govtCharges}
                        onChange={() => setGovtCharges(!govtCharges)}
                      />
                      <label htmlFor="charges">
                        Tax and Govt. charges excluded
                      </label>
                    </div>
                    <div
                      className="col-sm-6"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <input
                        id="Negotiable"
                        type="checkbox"
                        style={{ marginTop: "10px", marginRight: "10px" }}
                        value={priNegotiable}
                        onChange={() => setPriNegotiable(!priNegotiable)}
                      />
                      <label htmlFor="Negotiable">Price Negotiable</label>
                    </div>
                    {/* checkbox end */}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Pricing and others end */}

        <a
          className="btn color-bg float-btn"
          style={{ cursor: "pointer" }}
          onClick={handlesubmit}
        >
          Submit
        </a>
      </div>
    </>
  );
};

export default AddProperty;
