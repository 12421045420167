import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { GrCircleQuestion } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";

const PLotComponentsTemp = (props) => {
  const { PropertyProfile, BasicDetails,singleProperty } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();

  const [carpetArea, setCarpetArea] = useState("");
  const [typeofCarpetArea, setTypeofCarpetArea] = useState("sq.ft.");
  const [typeofCarpetAreaShow, setTypeofCarpetAreaShow] = useState(false);

  const [lengthofPlot, setLengthofPlot] = useState(singleProperty.lengthofPlot);
  const [widthofPlot, setWidthofPlot] = useState(singleProperty.widthofPlot);

  const [allowedNoofFloors, setAllowedNoofFloors] = useState(singleProperty.allowedNoofFloors);

  const [wallboundryAvaliable, setWallboundryAvaliable] = useState(singleProperty.wallboundryAvaliable);

  const [noofOpenSides, setNoofOpenSides] = useState(singleProperty.noofOpenSides);

  const [OnthisProperty, setOnthisProperty] = useState(singleProperty.ConustructionOnthisProperty);

  const [shed, setShed] = useState(false);
  const [room, setRoom] = useState(false);
  const [washRoom, setWashRoom] = useState(false);
  const [others, setOthers] = useState(false);

  const [expectedby, setExpectedby] = useState(singleProperty.propertyPossessionBy);
  const [expectedbyShow, setExpectedbyShow] = useState(false);

  const [facingRoad, setFacingRoad] = useState(singleProperty.facingRoad);
  const [roadWidth, setRoadWidth] = useState(singleProperty.plotRoadwidth);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    if (PropertyProfile !== "") {
      setCarpetArea(PropertyProfile.carpetArea);
      setTypeofCarpetArea(PropertyProfile.typeofCarpetArea);
      setTypeofCarpetAreaShow(PropertyProfile.typeofCarpetAreaShow);

      setLengthofPlot(PropertyProfile.lengthofPlot);
      setWidthofPlot(PropertyProfile.widthofPlot);

      setAllowedNoofFloors(PropertyProfile.allowedNoofFloors);

      setWallboundryAvaliable(PropertyProfile.wallboundryAvaliable);

      setNoofOpenSides(PropertyProfile.noofOpenSides);

      setOnthisProperty(PropertyProfile.OnthisProperty);

      setShed(PropertyProfile.shed);
      setRoom(PropertyProfile.room);
      setWashRoom(PropertyProfile.washRoom);
      setOthers(PropertyProfile.others);

      setFacingRoad(PropertyProfile.facingRoad);
      setRoadWidth(PropertyProfile.roadWidth);

      setExpectedby(PropertyProfile.expectedby);
      setExpectedbyShow(PropertyProfile.expectedbyShow);
    }
  }, [PropertyProfile]);

  const areaMesurments = [
    { name: "sq.ft." },
    { name: "sq.yards" },
    { name: "sq.m." },
    { name: "acres" },
    { name: "marla" },
    { name: "cents" },
    { name: "bigha" },
    { name: "kottah" },
    { name: "kanal" },
    { name: "grounds" },
    { name: "ares" },
    { name: "biswa" },
    { name: "guntha" },
    { name: "aankadam" },
    { name: "hectares" },
    { name: "rood" },
    { name: "chataks" },
    { name: "perch" },
  ];

  const underConstructions = [
    { id: "1", name: "Within 3 Months" },
    { id: "2", name: "Within 6 Months" },
    { id: "3", name: "By 2024" },
    { id: "4", name: "By 2025" },
    { id: "5", name: "By 2026" },
    { id: "6", name: "By 2027" },
  ];

  return (
    <>
      <div className="Area-widget">
        <div className="widgetbox_white_box__1v7xz undefined undefined undefined">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span
                      className="xidHeading_headingText__Ulc2a"
                      style={{ display: "inline" }}
                    >
                      Property Dimensions <em>(Optional)</em>
                    </span>
                  </div>
                </div>
              </h4>
            </div>
          </div>
          <div className="" style={{ width: "70%" }}>
            <div className="carpetArea-widget">
              <div className="  undefined">
                <div className="pageComponent" id="carpet-input">
                  <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                    <div className="false">
                      <input
                        type="text"
                        class="false false "
                        value={lengthofPlot}
                        onChange={(e) => {
                          setLengthofPlot(e.target.value.replace(/\D/g, ""));
                          props.lengthOfPlot(e.target.value.replace(/\D/g, ""));
                        }}
                        placeholder="Length of plot (Lin sq.ft)"
                      />
                      <div className="caption_strong_small">
                        <em
                          className="xidHeading_subLabel__1Fc9h"
                          style={{ color: "red" }}
                        >
                          {props.lengthOfPlotError}
                        </em>
                      </div>
                    </div>
                    <div className="false">
                      <input
                        type="text"
                        class="false false "
                        value={widthofPlot}
                        onChange={(e) => {
                          setWidthofPlot(e.target.value.replace(/\D/g, ""));
                          props.widthOfPlot(e.target.value.replace(/\D/g, ""));
                        }}
                        placeholder="width of plot (in sq.ft)"
                      />
                      <div className="caption_strong_small">
                        <em
                          className="xidHeading_subLabel__1Fc9h"
                          style={{ color: "red" }}
                        >
                          {props.widthofPlotError}
                        </em>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="FloorDetails-widget">
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span
                      className="xidHeading_headingText__Ulc2a"
                      style={{ display: "inline" }}
                    >
                      Floor Allowed for construction
                    </span>
                  </div>
                </div>
                <div className="caption_strong_small">
                  <em
                    className="xidHeading_subLabel__1Fc9h"
                    style={{ color: "red" }}
                  >
                    {props.allowedFloorsError}
                  </em>
                </div>
              </h4>
            </div>
          </div>
          <div className="formWidget_inlineContainer__3oR34 false">
            <div id="totalFloor-input">
              <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                <div className="false">
                  <input
                    type="text"
                    inputMode="decimal"
                    value={allowedNoofFloors}
                    onChange={(e) => {
                      setAllowedNoofFloors(e.target.value.replace(/\D/g, ""));
                      props.allowedFloors(e.target.value.replace(/\D/g, ""));
                    }}
                    placeholder="No.of Floors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="PropertyAvailability-widget">
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span
                      className="xidHeading_headingText__Ulc2a"
                      style={{ display: "inline" }}
                    >
                      Is there a boundary wall around the property ?{" "}
                    </span>
                  </div>
                </div>
              </h4>
              <p style={{ color: "red", fontSize: "12px" }}>
                {props.wallboundryAvaliableError}
              </p>
            </div>
          </div>
        </div>
        <div className="false">
          <div
            className="propertyAvailability-input"
            style={{ textAlign: "left" }}
          >
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div className="checkBox_tags_wrapper__2gksy  undefined multiple_input">
                <div
                  className={
                    wallboundryAvaliable === true
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setWallboundryAvaliable(true);
                    props.avialableWallBoundry(true);
                  }}
                  id="I"
                >
                  <span>Yes</span>
                </div>
                <div
                  className={
                    wallboundryAvaliable === false
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setWallboundryAvaliable(false);
                    props.avialableWallBoundry(false);
                  }}
                  id="5"
                >
                  <span>No</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Configuration-widget">
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span
                      className="xidHeading_headingText__Ulc2a"
                      style={{ display: "inline" }}
                    >
                      No. of Open Sides
                      <i
                        className="tooltip_icon"
                        id="DisabledAutoHideExample"
                        style={{ fontSize: "18px" }}
                      >
                        <GrCircleQuestion />
                      </i>
                    </span>
                  </div>
                </div>
              </h4>
            </div>
            <div className="caption_strong_small">
              <em
                className="xidHeading_subLabel__1Fc9h"
                style={{ color: "red" }}
              >
                {props.openSidesError}
              </em>
            </div>
          </div>
          <div className="false">
            <div
              id="bedroomNum-input"
              className="pageComponent"
              style={{ textAlign: "left" }}
            >
              <div
                className="tagWrapper_tags_wrapper__KIRJJ  multiple_input"
                id="bedroomNum"
                style={{ marginTop: "8px" }}
              >
                <div
                  className={
                    noofOpenSides === "1"
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setNoofOpenSides("1");
                    props.openSides("1");
                  }}
                  id="1"
                >
                  <span>1</span>
                </div>
                <div
                  className={
                    noofOpenSides === "2"
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setNoofOpenSides("2");
                    props.openSides("2");
                  }}
                  id="2"
                >
                  <span>2</span>
                </div>
                <div
                  className={
                    noofOpenSides === "3"
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setNoofOpenSides("3");
                    props.openSides("3");
                  }}
                  id="3"
                >
                  <span>3</span>
                </div>
                <div
                  className={
                    noofOpenSides === "3+"
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setNoofOpenSides("3+");
                    props.openSides("3+");
                  }}
                  id="more"
                >
                  <span>3 +</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="PropertyAvailability-widget">
          <div className="widgetbox_white_box__1v7xz undefined false false">
            <div className="widgetbox_header_text__2BXc_">
              <div className="xidHeading_PageHeadingwrap__3z0kL">
                <h4>
                  <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                    <div>
                      <span
                        className="xidHeading_headingText__Ulc2a"
                        style={{ display: "inline" }}
                      >
                        Any Construction done On this Property
                        <i
                          className="tooltip_icon"
                          id="DisabledAutoHideExample"
                          style={{ fontSize: "18px" }}
                        >
                          <GrCircleQuestion />
                        </i>
                      </span>
                    </div>
                  </div>
                </h4>
                <div className="caption_strong_small">
                  <em
                    className="xidHeading_subLabel__1Fc9h"
                    style={{ color: "red" }}
                  >
                    {props.anyConstructionError}
                  </em>
                </div>
              </div>
            </div>
          </div>
          <div className="false">
            <div
              className="propertyAvailability-input"
              style={{ textAlign: "left" }}
            >
              <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                <div className="checkBox_tags_wrapper__2gksy  undefined multiple_input">
                  <div
                    className={
                      OnthisProperty === true
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL"
                    }
                    onClick={() => {
                      setOnthisProperty(true);
                      props.constructionOnProperty(true);
                    }}
                    id="I"
                  >
                    <span>Yes</span>
                  </div>
                  <div
                    className={
                      OnthisProperty === false
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL"
                    }
                    onClick={() => {
                      setOnthisProperty(false);
                      props.constructionOnProperty(false);
                    }}
                    id="5"
                  >
                    <span>No</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {OnthisProperty === true ? (
          <>
            <div className="Furnishing-widget">
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <div>
                          <span className="xidHeading_headingText__Ulc2a">
                            What Type of construction has been done
                            <i
                              className="tooltip_icon"
                              id="DisabledAutoHideExample"
                              style={{ fontSize: "18px" }}
                            >
                              <GrCircleQuestion />
                            </i>
                          </span>
                        </div>
                      </div>
                    </h4>
                  </div>
                </div>
                <div className="false">
                  <div
                    id="additionalRooms-input"
                    className="pageComponent"
                    style={{ textAlign: "left" }}
                  >
                    <div className="checkBox_tags_wrapper__2gksy undefined multiple_input">
                      <div
                        className={
                          shed === true
                            ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                            : "tags-and-chips_textOnly__2k10B pageComponent "
                        }
                        onClick={() => {
                          setShed(!shed);
                          props.shed(!shed);
                        }}
                        id="1"
                      >
                        {shed === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Shed</span>
                      </div>

                      <div
                        className={
                          room === true
                            ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                            : "tags-and-chips_textOnly__2k10B pageComponent "
                        }
                        onClick={() => {
                          setRoom(!room);
                          props.room(!room);
                        }}
                        id="2"
                      >
                        {room === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span> Rooms</span>
                      </div>
                      <div
                        className={
                          washRoom === true
                            ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                            : "tags-and-chips_textOnly__2k10B pageComponent "
                        }
                        onClick={() => {
                          setWashRoom(!washRoom);
                          props.washroom(!washRoom);
                        }}
                        id="3"
                      >
                        {washRoom === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Wash Room</span>
                      </div>
                      <div
                        className={
                          others === true
                            ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                            : "tags-and-chips_textOnly__2k10B pageComponent "
                        }
                        onClick={() => {
                          setOthers(!others);
                          props.others(!others);
                        }}
                        id="4"
                      >
                        {others === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Others</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="Posession-widget">
          <div className="widgetbox_white_box__1v7xz undefined false false">
            <div className="formWidget_inlineContainer__3oR34 false">
              <div className="widgetbox_header_text__2BXc_">
                <div className="xidHeading_PageHeadingwrap__3z0kL">
                  <h4>
                    <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                      <div>
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Possession By
                        </span>
                      </div>
                    </div>
                  </h4>
                </div>
                <div className="caption_strong_small">
                  <em
                    className="xidHeading_subLabel__1Fc9h"
                    style={{ color: "red" }}
                  >
                    {props.possessionError}
                  </em>
                </div>
              </div>
            </div>
            <div id="availability-input">
              <div>
                <div
                  className="selectInput_custom_input_wrapper__MqLE9 selectInput_multiple_input__1oijd  pageComponent"
                  onClick={() => {
                    setExpectedbyShow(!expectedbyShow);
                    props.expectedbyShow(!expectedbyShow);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {expectedby !== "" ? (
                    <>
                      <div className="selectInput_label__Sr6HY caption_subdued_large4">
                        {expectedby}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="selectInput_label__Sr6HY caption_subdued_large4">
                        Expected by
                      </div>
                    </>
                  )}

                  <div className="selectInput_custom_input__DdGZD input_placeholder_completed false"></div>
                  <div className="selectInput_iconsWrapper__1L2kR">
                    <span className="icon_downArrow">
                      <img src="/assets/images/all/arrow_down.png" alt="" />
                    </span>
                    <div className="screeningActions_iconWrapper__dB1NM"></div>
                  </div>
                </div>
                {expectedbyShow === true ? (
                  <>
                    <div
                      className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown"
                      // style={{ top: "auto", bottom: "100%" }}
                    >
                      {underConstructions.map((underCon, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setExpectedbyShow(false);
                            setExpectedby(underCon.name);
                            props.expectedbyShow(false);
                            props.possesion(underCon.name);
                          }}
                        >
                          <span>{underCon.name}</span>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* commercial plot/land */}

      <div className="Widthoffacingroad-widget">
        <div className="widgetbox_header_text__2BXc_">
          <div className="xidHeading_PageHeadingwrap__3z0kL">
            <h4>
              <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                <div>
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Width of facing road
                  </span>
                </div>
              </div>
            </h4>
          </div>
          <div className="caption_strong_small">
            <em className="xidHeading_subLabel__1Fc9h" style={{ color: "red" }}>
              {props.widthOfFacingError}
            </em>
          </div>
        </div>
        <div className="splitWidget_inputFiledWrap__1vX9m  undefined">
          <div className="pageComponent" id="carpet-input">
            <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
              <div className="false">
                <input
                  type="text"
                  value={roadWidth}
                  onChange={(e) => {
                    setRoadWidth(e.target.value.replace(/\D/g, ""));
                    props.widthOfFacingRoad(e.target.value.replace(/\D/g, ""));
                  }}
                  placeholder="Enter the width"
                />
                <div></div>
                <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT">
                  {/* <span className='undefined'>Carpet Area</span> */}
                </div>
              </div>
              <div className="numberInput_iconsWrapper__3Kpm5">
                <i></i>
                <div className="screeningActions_iconWrapper__dB1NM"></div>
              </div>
            </div>
          </div>

          <div className="pageComponent" id="carpet-input">
            <div style={{}}>
              <div className="selectInput_custom_input_wrapper__MqLE9 false  pageComponent">
                <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                <div
                  className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf "
                  style={{ border: "none", cursor: "pointer" }}
                  onClick={(e) => {
                    setTypeofCarpetAreaShow(!typeofCarpetAreaShow);
                  }}
                >
                  {typeofCarpetArea}
                </div>
                <div
                  className="selectInput_iconsWrapper__1L2kR"
                  onClick={(e) => {
                    setTypeofCarpetAreaShow(!typeofCarpetAreaShow);
                  }}
                >
                  {typeofCarpetAreaShow ? (
                    <span className="icon_downArrow">
                      <img src="/assets/images/all/arrow_up.png" alt="" />
                    </span>
                  ) : (
                    <span className="icon_downArrow">
                      <img src="/assets/images/all/arrow_down.png" alt="" />
                    </span>
                  )}
                </div>
              </div>
              {typeofCarpetAreaShow && (
                <>
                  <div className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown">
                    {areaMesurments.map((mesurment, index) => (
                      <div key={index}>
                        <span
                          onClick={() => {
                            setTypeofCarpetArea(mesurment.name);
                            props.carpetAreaMesurment(mesurment.name);
                            setTypeofCarpetAreaShow(false);
                          }}
                        >
                          {mesurment.name}
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        id="Facing-widget"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div class="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Property facing
                  </span>
                </div>
              </h4>
            </div>
            <div className="caption_strong_small">
              <em
                className="xidHeading_subLabel__1Fc9h"
                style={{ color: "red" }}
              >
                {props.FacingError}
              </em>
            </div>
          </div>
          <div className="false" style={{ textAlign: "left" }}>
            <div className="pagecomponent amenties-widget_inner">
              <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                <div
                  className={
                    facingRoad === "North"
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setFacingRoad("North");
                    props.propertyFacing("North");
                  }}
                  id="1"
                >
                  <span>North</span>
                </div>
              </div>
              <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                <div
                  className={
                    facingRoad === "South"
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setFacingRoad("South");
                    props.propertyFacing("South");
                  }}
                  id="2"
                >
                  <span>South</span>
                </div>
              </div>
              <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                <div
                  className={
                    facingRoad === "East"
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setFacingRoad("East");
                    props.propertyFacing("East");
                  }}
                  id="3"
                >
                  <span>East</span>
                </div>
              </div>
              <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                <div
                  className={
                    facingRoad === "West"
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setFacingRoad("West");
                    props.propertyFacing("West");
                  }}
                  id="4"
                >
                  <span>West</span>
                </div>
              </div>
              <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                <div
                  className={
                    facingRoad === "North-East"
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setFacingRoad("North-East");
                    props.propertyFacing("North-East");
                  }}
                  id="5"
                >
                  <span>North-East</span>
                </div>
              </div>
              <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                <div
                  className={
                    facingRoad === "North-West"
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setFacingRoad("North-West");
                    props.propertyFacing("North-West");
                  }}
                  id="6"
                >
                  <span>North-West</span>
                </div>
              </div>
              <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                <div
                  className={
                    facingRoad === "South-East"
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setFacingRoad("South-East");
                    props.propertyFacing("South-East");
                  }}
                  id="7"
                >
                  <span>South-East</span>
                </div>
              </div>
              <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                <div
                  className={
                    facingRoad === "South-West"
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setFacingRoad("South-West");
                    props.propertyFacing("South-West");
                  }}
                  id="8"
                >
                  <span>South-West </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PLotComponentsTemp;
