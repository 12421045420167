import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

import "swiper/css";
import "swiper/css/pagination";

const InterestingReads1 = () => {
    return (
        <>
            <div className="interesting-reads">
                <div className='interesting-reads-head'>
                    <h1>Interesting Reads</h1>
                    <a>Read realty news, guides & articles</a>
                </div>
                <div className="interesting-reads-body">
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={0}
                        pagination={{
                            clickable: false,
                        }}
                        breakpoints={{
                            360: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            720: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            1080: {
                                slidesPerView: 4,
                                spaceBetween: 0,
                            },
                        }}
                        modules={[]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/IR_01.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>15+ Facade Lighting Ideas</h3>
                                            <a>Sep 19, 2023</a>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/IR_02.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>45+ Outdoor Wall Lights Ideas</h3>
                                            <a>Sep 19, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/IR_03.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>45+ Ceiling Lights Ideas for Home & Office</h3>
                                            <a>Sep 18, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/IR_04.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>10+ Bay Window Styles, Designs and Ideas</h3>
                                            <a>Sep 18, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/IR_05.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>60+ Kitechen Lighting Ideas</h3>
                                            <a> Sep 18, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/IR_06.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>All about owning a self-acquired property</h3>
                                            <a>Sep 15, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/IR_07.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>All about putty paint</h3>
                                            <a>Sep 15, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/IR_08.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>Every thing you need to know about Hakku patra</h3>
                                            <a>Sep 15, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/IR_09.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>Convenant meaning in property law and types</h3>
                                            <a>Sep 15, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/IR_10.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>A comprehensive guide on wall fungus</h3>
                                            <a>Sep 15, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default InterestingReads1