import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import RegisterOtp from "./RegisterOtp";
import {
  LoginOtp,
  loginAuthentication,
  setModelShow,
} from "../../redux/athentication/Athentication";

const Registration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [formData, setformData] = useState("");
  const [otpShow, setOtpShow] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const nameChange = (e) => {
    let value = e.target.value;
    setName(value);
  };

  const emailChange = (e) => {
    let value = e.target.value;
    setEmail(value);
  };

  const setMobileChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    setMobile(value);
  };

  const handleRegdSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (name !== "" && mobile !== "" && email !== "") {
      try {
        if (mobile.length === 10) {
          var digits = "0123456789";
          let OTP = "";
          for (let i = 0; i < 6; i++) {
            OTP += digits[Math.floor(Math.random() * 10)];
          }
          const formData = {
            name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
            email: email,
            mobile: mobile,
            OTP: OTP,
          };
          await dispatch(loginAuthentication(formData));
          setformData(formData);
          dispatch(LoginOtp({ mobile, OTP }));
          setBtnLoading(false);
          setOtpShow(true);
        }
      } catch (error) {}
    } else {
    }
  };

  const privacyClick = () => {
    dispatch(setModelShow(false));
    navigate("/privacypolicy");
  };
  const termClick = () => {
    dispatch(setModelShow(false));
    navigate("/termsandconditions");
  };

  return (
    <>
      <div className="custom-form">
        {otpShow === false ? (
          <>
            <form
              name="registerform"
              className="main-register-form"
              onSubmit={(e) => handleRegdSubmit(e)}
            >
              <label>
                Full Name *
                <span className="dec-icon">
                  <i className="fal fa-user" />
                </span>
              </label>
              <input
                name="name"
                type="text"
                placeholder="Enter Your Name"
                required
                value={name}
                onChange={(e) => nameChange(e)}
              />
              <label>
                Email Address *
                <span className="dec-icon">
                  <i className="fal fa-envelope" />
                </span>
              </label>
              <input
                name="email"
                type="email"
                placeholder="Enter Your Email"
                required
                value={email}
                onChange={(e) => emailChange(e)}
              />
              <label>
                Mobile Number *
                <span className="dec-icon">
                  <i className="fal fa-user" />
                </span>
              </label>
              <input
                name="name2"
                type="text"
                placeholder="Enter Your Mobile Number"
                required
                minLength={10}
                maxLength={10}
                value={mobile}
                pattern="[6-9]{1}[0-9]{9}"
                onChange={(e) => setMobileChange(e)}
              />

              <label style={{ marginTop: "15px" }}>
                Are you a Real Estate Agent ?
              </label>
              <div className="filter-tags ft-list ">
                <div className="filter-tags ft-list">
                  <input id="check-owner" type="radio" name="check" />
                  <label>Yes</label>
                </div>
                <div className="filter-tags ft-list">
                  <input
                    id="check-owner"
                    type="radio"
                    name="check"
                    defaultChecked
                  />
                  <label>No</label>
                </div>
              </div>
              <div className="filter-tags ft-list">
                <input id="check-a2" type="checkbox" name="check" />
                <label htmlFor="check-a2">
                  I agree to the{" "}
                  <span
                    style={{ color: "#3270fc" }}
                    onClick={() => privacyClick()}
                  >
                    {" "}
                    Privacy Policy
                  </span>{" "}
                  and{" "}
                  <span
                    style={{ color: "#3270fc" }}
                    onClick={() => termClick()}
                  >
                    Terms and Conditions
                  </span>{" "}
                </label>
              </div>
              <div className="clearfix" />
              {btnLoading === true ? (
                <>
                  <button className="log_btn color-bg">Verifing...</button>
                </>
              ) : (
                <div>
                  <button className="log_btn color-bg">Register</button>
                </div>
              )}
            </form>
          </>
        ) : (
          <>
            <RegisterOtp formData={formData} />
          </>
        )}
      </div>
    </>
  );
};

export default Registration;
