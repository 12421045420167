import { Switch } from "@mui/material";
import React from "react";

const HideAlreadySeen = () => {
  return (
    <div className="mainbarfiltercomponents">
      <div className="propertyboxes">
        <Switch defaultChecked /> 
        <p>Hide already seen</p>
      </div>
    </div>
  );
};

export default HideAlreadySeen;
