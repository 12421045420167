import React from "react";
import { useSelector } from "react-redux";
import PropertyProfile from "./PropertyProfile";
import PlotPropertyProfile from "./residential/sell/plotandland/PlotPropertyProfile";
import PgFlatPrpertyp from "./residential/Pg/PgFlatPrpertyp";
import OfficePropertyDetails from "./commericial/rentlease/office/OfficePropertyDetails";
import RetailPropertyDetails from "./commericial/rentlease/retail/RetailPropertyDetails";
import PlotLandPropertyDetails from "./commericial/rentlease/plotandland/PlotLandPropertyDetails";
import HospitalityPropertyDetails from "./commericial/rentlease/hospitality/HospitalityPropertyDetails";
import IndustryPropertyProfile from "./commericial/rentlease/industry/IndustryPropertyProfile";
import HospitalityPropertyDetailsSell from "./commericial/rentlease/hospitality/HospitalityPropertyDetailsSell";

const MainPropertyProfile = () => {
  const { BasicDetails } = useSelector((state) => state.Property);

  return (
    <>
      {BasicDetails.supercatName === "Residential" ? (
        <>
          {BasicDetails.catName === "Pg" ? (
            <>
              <PgFlatPrpertyp />
            </>
          ) : (
            <>
              {BasicDetails.subcatName === "Plot" ||
              BasicDetails.subcatName === "Land" ? (
                <>
                  <PlotPropertyProfile />
                </>
              ) : (
                <>
                  <PropertyProfile />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {BasicDetails.catName === "Sell" &&
          BasicDetails.subcatName === "Hospitality" ? (
            <>
      
              <HospitalityPropertyDetailsSell />
            </>
          ) : (
            <>
              {BasicDetails.subcatName === "Office" ? (
                <>
                  <OfficePropertyDetails />
                </>
              ) : (
                <>
                  {BasicDetails.subcatName === "Retail" ? (
                    <>
                      <RetailPropertyDetails />
                    </>
                  ) : (
                    <>
                      {BasicDetails.subcatName === "Plot" ||
                      BasicDetails.subcatName === "Land" ? (
                        <>
                          <PlotLandPropertyDetails /> 
                        </>
                      ) : (
                        <>
                      
                          {BasicDetails.subcatName === "Storage" ||
                          BasicDetails.subcatName === "Industry" ? (
                            <>
                              <IndustryPropertyProfile />
                            </>
                          ) : (
                            <>
                              {BasicDetails.subcatName === "Hospitality" ? (
                                <>
                                  <HospitalityPropertyDetails />
                                </>
                              ) : (
                                <>
                                  <IndustryPropertyProfile />
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default MainPropertyProfile;
