import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/pagination";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { useSelector } from "react-redux";

// import { Pagination } from 'swiper/modules';

function ApartmentsVillas() {
  const { subCatTotal } = useSelector((store) => store.SubCategory);
  const navigate = useNavigate();

  const [supcat, setSupcat] = useState("Residential");
  const [cat, setCat] = useState("Rent");
  const [subcat, setSubcat] = useState("Apartment");
  const [subcat1, setSubcat1] = useState("Land");
  const [subcat2, setSubcat2] = useState("Villa");
  const [subcat3, setSubcat3] = useState("Independent House");
  const [subcat4, setSubcat4] = useState("Farmhouse");
  const [subcat5, setSubcat5] = useState("1 RK/Studio Apartment");
  const [subcat6, setSubcat6] = useState("Service Apartment");

  const [city, setCity] = useState("Hyderabad");

  const [apartmentCount, setApartmentCount] = useState([]);
  const [landCount, setLandCount] = useState([]);
  const [VillaCount, setVillaCount] = useState([]);
  const [IndependentHouseCount, setIndependentHouseCount] = useState([]);
  const [FarmhouseCount, setFarmhouseCount] = useState([]);
  const [rkstudioCount, setRkStudioCount] = useState([]);
  const [serviceApartmentCount, setServiceApartmentCount] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/property/propertybycityandsubcat/${city}/${supcat}/${subcat}`;
        const fetchProperty = await axios.get(url);
        try {
          if (fetchProperty.data.success === true) {
            const property = fetchProperty.data.propertybycity;
            setApartmentCount(property);
          }
        } catch (error) {}
      };
      fetchData();
    } catch (error) {}
  }, []);
  useEffect(() => {
    try {
      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/property/propertybycityandsubcat/${city}/${supcat}/${subcat1}`;
        const fetchProperty = await axios.get(url);
        try {
          if (fetchProperty.data.success === true) {
            const property = fetchProperty.data.propertybycity;
            setLandCount(property);
          }
        } catch (error) {}
      };
      fetchData();
    } catch (error) {}
  }, []);
  useEffect(() => {
    try {
      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/property/propertybycityandsubcat/${city}/${supcat}/${subcat2}`;
        const fetchProperty = await axios.get(url);
        try {
          if (fetchProperty.data.success === true) {
            const property = fetchProperty.data.propertybycity;
            setVillaCount(property);
          }
        } catch (error) {}
      };
      fetchData();
    } catch (error) {}
  }, []);
  useEffect(() => {
    try {
      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/property/propertybycityandsubcat/${city}/${supcat}/${subcat3}`;
        const fetchProperty = await axios.get(url);
        try {
          if (fetchProperty.data.success === true) {
            const property = fetchProperty.data.propertybycity;
            setIndependentHouseCount(property);
          }
        } catch (error) {}
      };
      fetchData();
    } catch (error) {}
  }, []);
  useEffect(() => {
    try {
      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/property/propertybycityandsubcat/${city}/${supcat}/${subcat4}`;
        const fetchProperty = await axios.get(url);
        try {
          if (fetchProperty.data.success === true) {
            const property = fetchProperty.data.propertybycity;
            setFarmhouseCount(property);
          }
        } catch (error) {}
      };
      fetchData();
    } catch (error) {}
  }, []);
  useEffect(() => {
    try {
      const fetchData = async () => {
        let mysubcat = subcat5;
        mysubcat = mysubcat.replaceAll(",", "-");
        mysubcat = mysubcat.replaceAll("/", "-");
        mysubcat = mysubcat.replaceAll(" ", "-");
        const url = `${Baseurl}/api/v1/property/propertybycityandsubcat/${city}/${supcat}/${mysubcat}`;
        const fetchProperty = await axios.get(url);
        try {
          if (fetchProperty.data.success === true) {
            const property = fetchProperty.data.propertybycity;
            setRkStudioCount(property);
          }
        } catch (error) {}
      };
      fetchData();
    } catch (error) {}
  }, []);
  useEffect(() => {
    try {
      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/property/propertybycityandsubcat/${city}/${supcat}/${subcat6}`;
        const fetchProperty = await axios.get(url);
        try {
          if (fetchProperty.data.success === true) {
            const property = fetchProperty.data.propertybycity;
            setServiceApartmentCount(property);
          }
        } catch (error) {}
      };
      fetchData();
    } catch (error) {}
  }, []);

  const apartmentClick = () => {
    navigate(`/property-search/${supcat}/${cat}/${subcat}/${city}`);
  };
  const landClick = () => {
    navigate(`/property-search/${supcat}/${cat}/${subcat1}/${city}`);
  };
  const villaClick = () => {
    navigate(`/property-search/${supcat}/${cat}/${subcat2}/${city}`);
  };
  const independentClick = () => {
    navigate(`/property-search/${supcat}/${cat}/${subcat3}/${city}`);
  };
  const farmhouseClick = () => {
    navigate(`/property-search/${supcat}/${cat}/${subcat4}/${city}`);
  };
  const rkstudioClick = () => {
    navigate(`/property-search/${supcat}/${cat}/${subcat5}/${city}`);
  };
  const servedClick = () => {
    navigate(`/property-search/${supcat}/${cat}/${subcat6}/${city}`);
  };

  return (
    <>
      <div className="apartment-villa">
        <div className="apartment-head">
          <h1>Apartments, Villas and more</h1>
          <p>in {city}</p>
        </div>
        <div className="apartment-villa-swiper">
          <Swiper
            slidesPerView={3}
            spaceBetween={10}
            pagination={{
              clickable: false,
            }}
            breakpoints={{
              360: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              720: {
                slidesPerView: 2,
                spaceBetween: 10,
              },

              1080: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            modules={[]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <div
                    className="apartment-villas-item fl-wrap"
                    onClick={(e) => apartmentClick(e)}
                  >
                    <div className="apartment-villa-img1">
                      <img
                        className="bg"
                        src="/assets/images/sliderImg/d_hp_property_type_1.webp"
                        alt=""
                      />
                    </div>
                    <div className="apartment-villas-content">
                      <h3>
                        <a>Residential Apartment</a>
                      </h3>
                      <p>{apartmentCount.length}+ Properties</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <article className="geodir-category-listing fl-wrap">
                    <div
                      className="apartment-villas-item fl-wrap"
                      onClick={(e) => landClick(e)}
                    >
                      <div className="apartment-villa-img2">
                        <img
                          className="bg"
                          src="/assets/images/sliderImg/d_hp_property_type_3.webp"
                          alt=""
                        />
                      </div>
                      <div className="apartment-villas-content">
                        <h3>
                          <a>Residential Land</a>
                        </h3>
                        <p>{landCount.length}+ Properties</p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <article className="geodir-category-listing fl-wrap">
                    <div
                      className="apartment-villas-item fl-wrap"
                      onClick={(e) => villaClick(e)}
                    >
                      <div className="apartment-villa-img3">
                        <img
                          className="bg"
                          src="/assets/images/sliderImg/d_hp_property_type_2.webp"
                          alt=""
                        />
                      </div>
                      <div className="apartment-villas-content">
                        <h3>
                          <a>Independent Villa</a>
                        </h3>
                        <p>{VillaCount.length}+ Properties</p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <article className="geodir-category-listing fl-wrap">
                    <div
                      className="apartment-villas-item fl-wrap"
                      onClick={(e) => independentClick(e)}
                    >
                      <div className="apartment-villa-img4">
                        <img
                          className="bg"
                          src="/assets/images/sliderImg/d_hp_property_type_4_1.png"
                          alt=""
                        />
                      </div>
                      <div className="apartment-villas-content">
                        <h3>
                          <a>Independent/Builder Floor</a>
                        </h3>
                        <p>{IndependentHouseCount.length}+ Properties</p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <article className="geodir-category-listing fl-wrap">
                    <div
                      className="apartment-villas-item fl-wrap"
                      onClick={(e) => farmhouseClick(e)}
                    >
                      <div className="apartment-villa-img5">
                        <img
                          className="bg"
                          src="/assets/images/sliderImg/d_hp_property_type_5.webp"
                          alt=""
                        />
                      </div>
                      <div className="apartment-villas-content">
                        <h3>
                          <a>Farm House</a>
                        </h3>
                        <p>{FarmhouseCount.length}+ Properties</p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <article className="geodir-category-listing fl-wrap">
                    <div
                      className="apartment-villas-item fl-wrap"
                      onClick={(e) => rkstudioClick(e)}
                    >
                      <div className="apartment-villa-img6">
                        <img
                          className="bg"
                          src="/assets/images/sliderImg/d_hp_property_type_90.webp"
                          alt=""
                        />
                      </div>
                      <div className="apartment-villas-content">
                        <h3>
                          <a>1 RK/Studio Apartments</a>
                        </h3>
                        <p>{rkstudioCount.length}+ Properties</p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <article className="geodir-category-listing fl-wrap">
                    <div
                      className="apartment-villas-item fl-wrap"
                      onClick={(e) => servedClick(e)}
                    >
                      <div className="apartment-villa-img7">
                        <img
                          className="bg"
                          src="/assets/images/sliderImg/d_hp_property_type_22.webp"
                          alt=""
                        />
                      </div>
                      <div className="apartment-villas-content">
                        <h3>
                          <a>Served Apartments</a>
                        </h3>
                        <p>{serviceApartmentCount.length}+ Properties</p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default ApartmentsVillas;
