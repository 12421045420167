import React from "react";
import { useSelector } from "react-redux";
import EditBasicTemp from "./components/EditBasicTemp";
import EditMainPropertTemp from "./components/EditMainPropertTemp";
import EditPhotoDetailsTemp from "./components/EditPhotoDetailsTemp";
import EditLocationTemp from "./components/EditLocationTepm";
import EditPriceOthersTemp from "./components/EditPriceOthersTemp";
import EditAmentiesSectionTemp from "./components/EditAmentiesSectionTemp";


const EditHome = () => {
  const { postPropertyStepsStatus } = useSelector((state) => state.Property);

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return <EditBasicTemp />;
      case 1:
        return <EditLocationTemp />;
      case 2:
        return <EditMainPropertTemp />;
      case 3:
        return <EditPhotoDetailsTemp />;
      case 4:
        return <EditPriceOthersTemp />;
      case 5:
        return <EditAmentiesSectionTemp />;
      default:
        return <EditBasicTemp />;
        break;
    }
  };

  return <>{renderSwitch(postPropertyStepsStatus)}</>;
};

export default EditHome;
