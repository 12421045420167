import React from 'react'

const ShortListed = () => {
  return (
    <>
      <div className='shortlisted-outer'>
        <div className='shortlisted-inner'>
          <div className='shortlisted-main'>
            <div className='shortlisted-spacer'></div>
            <div className='shortlisted-image'>
              <img src="/assets/images/sliderImg/recentActivityHouse.png" alt="" />
            </div>
            <div className='shortlisted-text'>You haven’t shortlisted anything yet!</div>
            <div className='shortlisted-subtext'>In case you have shortlisted something on another device/account, Login / Register to view them here</div>
            <div className='shortlisted-spacer'></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShortListed