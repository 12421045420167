import React, { useEffect, useState } from "react";
import { GrCircleQuestion } from "react-icons/gr";
import { Tooltip } from "reactstrap";
import {
  BiArrowBack,
  BiPlus,
  BiMinus,
  BiChevronDown,
  BiInfoCircle,
} from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  setPostPropertyStepsStatus,
  setPropertyProfile,
} from "../../../../../../redux/property/PropertySlice";
import AddAreaDetails from "../../../../finalcomponents/AddAreaDetails";
import FloorDetails from "../../../../finalcomponents/FloorDetails";
import AvaliabilityStatus from "../../../../finalcomponents/AvaliabilityStatus";
import AvaliableFrom from "../../../../finalcomponents/AvaliableFrom";
import ParkingType from "../../../../finalcomponents/ParkingType";

const RetailPropertyDetails = () => {
  const { PropertyProfile, BasicDetails } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();

  const [carpetArea, setCarpetArea] = useState("");
  const [typeofCarpetArea, setTypeofCarpetArea] = useState("sq.ft.");
  const [typeofCarpetAreaShow, setTypeofCarpetAreaShow] = useState(false);

  const [builtupArea, setBuiltupArea] = useState("");
  const [typeofBuiltupArea, setTypeofBuiltupArea] = useState("sq.ft.");
  const [typeofBuiltupAreaShow, setTypeofBuiltupAreaShow] = useState(false);
  const [typeofBuiltupAreaBox, setTypeofBuiltupAreaBox] = useState(false);

  const [superBuiltupArea, setSuperBuiltupArea] = useState("");
  const [typeofSuperBuiltupArea, setTypeofSuperBuiltupArea] =
    useState("sq.ft.");
  const [typeofSuperBuiltupAreaShow, setTypeofSuperBuiltupAreaShow] =
    useState(false);
  const [typeofSuperBuiltupAreaBox, setTypeofSuperBuiltupAreaBox] =
    useState(false);

  const [publicWashRooms, setPublicWashRooms] = useState(false);
  const [privateWashRooms, setPrivateWashRooms] = useState(false);
  const [washrooms, setWashrooms] = useState(true);

  const [entranceWidth, setEntranceWidth] = useState("");
  const [typeofentranceWidth, setTypeofentranceWidth] = useState("sq.ft.");
  const [typeofentranceWidthShow, setTypeofentranceWidthShow] = useState(false);

  const [cellingHeight, setCellingHeight] = useState("");
  const [typeofcellingHeight, setTypeofcellingHeight] = useState("sq.ft.");
  const [typeofcellingHeightShow, setTypeofcellingHeightShow] = useState(false);

  const [entrance, setEntrance] = useState(false);
  const [elevator, setElevator] = useState(false);
  const [stairs, setStairs] = useState(false);

  const [parkingType, setParkingType] = useState(false);
  const [publicParking, setPublicParking] = useState(false);
  const [privateParking, setPrivateParking] = useState(false);

  const [totalFloors, setTotalFloors] = useState(0);
  const [propertyonFloors, setPropertyonFloors] = useState("");
  const [propertyonFloorsShow, setPropertyonFloorsShow] = useState(false);
  const [typeofPropertyonFloors, setTypeofPropertyonFloors] = useState("");

  const [readyToMove, setReadyToMove] = useState(false);
  const [underConstruction, setUnderConstruction] = useState(false);
  const [abilityStatus, setAbilityStatus] = useState(false);

  const [ageofProperty, setAgeofProperty] = useState("");

  const [expectedby, setExpectedby] = useState("");
  const [expectedbyShow, setExpectedbyShow] = useState(false);
  const [yearofExpectedBy, setYearofExpectedBy] = useState("");
  const [availableDate, setavailableDate] = useState("");

  const [availabilityError, setavailabilityError] = useState("");
  const [selectAgeError, setSelectedAgeError] = useState("");
  const [timePossessionError, settimePossessionError] = useState("");

  // errors
  const [areaDetailsError, setAreaDetailsError] = useState("");

  const [washroomsError, setWashroomsError] = useState("");
  const [abilityError, setAbilityError] = useState("");
  const [abilityStatusError, setAbilityStatusError] = useState("");

  const [floorDetailsError, setFloorDetailsError] = useState("");
  const [parkingError, setParkingError] = useState("");

  const [availabilityDateError, setavailabilityDateError] = useState("");
  const [availableDateError, setAvailableDateError] = useState(false);

  const [iscontent, setIsContent] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const carpetAreaChange = (e) => {
    const result = e;
    setCarpetArea(result);
  };

  const builtupChange = (e) => {
    const result = e;
    setBuiltupArea(result);
  };

  const superbuiltupChange = (e) => {
    const result = e;
    setSuperBuiltupArea(result);
  };

  const carpetAreaMesurment = (e) => {
    const result = e;
    setTypeofCarpetArea(result);
  };

  const builtupMesurment = (e) => {
    const result = e;
    setTypeofBuiltupArea(result);
  };
  const superbuiltupMesurment = (e) => {
    const result = e;
    setTypeofSuperBuiltupArea(result);
  };
  const getNumberOfFloors = (value) => {
    console.log();
    setTotalFloors(value);
  };
  const getPropertyOnFloors = (value) => {
    setPropertyonFloors(value);
  };

  const parkingDefaultType = (value) => {
    setParkingType(value);
  };
  const parkingPrivateType = (value) => {
    setPrivateParking(value);
  };

  const parkingPublicType = (value) => {
    setPublicParking(value);
  };
  const getPropertyAvailability = (value) => {
    setAbilityStatus(value);
  };
  const getPropertyAge = (value) => {
    setAgeofProperty(value);
  };
  const getPropertyExpectedYear = (value) => {
    setYearofExpectedBy(value);
  };
  const getPropertyExpectedMonth = (value) => {
    setExpectedby(value);
  };
  const constructionOnProperty = (e) => {
    const result = e;
    setUnderConstruction(result);
  };
  const availableFromDate = (e) => {
    const result = e;
    setavailableDate(result);
  };

  useEffect(() => {
    if (PropertyProfile !== "") {
      setCarpetArea(PropertyProfile.carpetArea);
      setTypeofCarpetArea(PropertyProfile.typeofCarpetArea);
      setTypeofCarpetAreaShow(PropertyProfile.typeofCarpetAreaShow);

      setBuiltupArea(PropertyProfile.builtupArea);
      setTypeofBuiltupArea(PropertyProfile.typeofBuiltupArea);
      setTypeofBuiltupAreaShow(PropertyProfile.typeofBuiltupAreaShow);
      setTypeofBuiltupAreaBox(PropertyProfile.typeofBuiltupAreaBox);

      setWashrooms(PropertyProfile.washrooms);

      setPublicWashRooms(PropertyProfile.publicWashRooms);
      setPrivateWashRooms(PropertyProfile.privateWashRooms);

      setEntranceWidth(PropertyProfile.entranceWidth);
      setTypeofentranceWidth(PropertyProfile.typeofentranceWidth);
      setTypeofentranceWidthShow(PropertyProfile.typeofentranceWidthShow);

      setCellingHeight(PropertyProfile.cellingHeight);
      setTypeofcellingHeight(PropertyProfile.typeofcellingHeight);
      setTypeofcellingHeightShow(PropertyProfile.typeofcellingHeightShow);

      setEntrance(PropertyProfile.entrance);
      setElevator(PropertyProfile.elevator);
      setStairs(PropertyProfile.stairs);

      setParkingType(PropertyProfile.parkingType);
      setPublicParking(PropertyProfile.publicParking);
      setPrivateParking(PropertyProfile.privateParking);

      setTotalFloors(PropertyProfile.totalFloors);
      setPropertyonFloors(PropertyProfile.propertyonFloors);
      setPropertyonFloorsShow(PropertyProfile.propertyonFloorsShow);

      setReadyToMove(PropertyProfile.readyToMove);
      setUnderConstruction(PropertyProfile.underConstruction);

      setAgeofProperty(PropertyProfile.ageofProperty);

      setExpectedby(PropertyProfile.expectedby);
      setExpectedbyShow(PropertyProfile.expectedbyShow);

      setavailableDate(PropertyProfile.availableDate);
    }
  }, [PropertyProfile]);

  const areaMesurments = [
    { name: "sq.ft." },
    { name: "sq.yards" },
    { name: "sq.m." },
    { name: "acres" },
    { name: "marla" },
    { name: "cents" },
    { name: "bigha" },
    { name: "kottah" },
    { name: "kanal" },
    { name: "grounds" },
    { name: "ares" },
    { name: "biswa" },
    { name: "guntha" },
    { name: "aankadam" },
    { name: "hectares" },
    { name: "rood" },
    { name: "chataks" },
    { name: "perch" },
  ];

  useEffect(() => {
    if (carpetArea !== "") {
      setAreaDetailsError("");
    }

    if (
      washrooms === true ||
      privateWashRooms === true ||
      publicWashRooms === true
    ) {
      setWashroomsError("");
    }
    if (totalFloors !== "") {
      setFloorDetailsError("");
    }
    if (
      parkingType === true ||
      publicParking === true ||
      privateParking === true
    ) {
      setParkingError("");
    }
    if (ageofProperty !== "" || expectedby !== "") {
      setavailabilityError("");
      setSelectedAgeError("");
      settimePossessionError("");
    }
    if (availableDate !== "") {
      setAvailableDateError("");
    }
  }, [
    carpetArea,
    washrooms,
    privateWashRooms,
    publicWashRooms,
    totalFloors,
    parkingType,
    publicParking,
    privateParking,
    ageofProperty,
    expectedby,
    availableDate,
  ]);

  const propertyProfileClick = async (e) => {
    e.preventDefault();
    setAreaDetailsError("");
    setWashroomsError("");
    setFloorDetailsError("");
    setParkingError("");
    setavailabilityError("");
    setSelectedAgeError("");
    settimePossessionError("");
    setAvailableDateError("");

    if (carpetArea !== "") {
      setAreaDetailsError("");
    } else {
      setAreaDetailsError("Please Enter Area Details");
    }
    if (
      washrooms === true ||
      privateWashRooms === true ||
      publicWashRooms === true
    ) {
      setWashroomsError("");
    } else {
      setWashroomsError("Please Select Washroom Type");
    }
    if (totalFloors !== "") {
      setFloorDetailsError("");
    } else {
      setFloorDetailsError("Please Enter Floor Details");
    }
    if (
      parkingType === true ||
      publicParking === true ||
      privateParking === true
    ) {
      setParkingError("");
    } else {
      setParkingError("Please Select Parking Type");
    }
    if (ageofProperty !== "" || expectedby !== "") {
      setavailabilityError("");
      setSelectedAgeError("");
      settimePossessionError("");
    } else {
      setavailabilityError("Selact Availibility Status ");
      setSelectedAgeError("Select Property Age");
      settimePossessionError("Select Expected Date");
    }
    if (availableDate !== "") {
      setAvailableDateError("");
    } else {
      setAvailableDateError("Select Available Date");
    }

    if (
      carpetArea !== "" &&
      totalFloors !== "" &&
      abilityStatus !== "" &&
      availableDate !== ""
    ) {
      if (
        washrooms === true ||
        privateWashRooms === true ||
        (publicWashRooms === true && parkingType === true) ||
        publicParking === true ||
        privateParking === true
      ) {
        const propertyProfile = {
          carpetArea: carpetArea,
          typeofCarpetArea: typeofCarpetArea,
          typeofCarpetAreaShow: typeofCarpetAreaShow,

          builtupArea: builtupArea,
          typeofBuiltupArea: typeofBuiltupArea,
          typeofBuiltupAreaShow: typeofBuiltupAreaShow,
          typeofBuiltupAreaBox: typeofBuiltupAreaBox,

          entranceWidth: entranceWidth,
          typeofentranceWidth: typeofentranceWidth,
          typeofentranceWidthShow: typeofentranceWidthShow,

          cellingHeight: cellingHeight,
          typeofcellingHeight: typeofcellingHeight,
          typeofcellingHeightShow: typeofcellingHeightShow,

          washRoomAvalible: washrooms,

          privateWashroom: privateWashRooms,
          sharedWashroom: publicWashRooms,

          totalFloors: totalFloors,
          propertyonFloors: propertyonFloors,
          propertyonFloorsShow: propertyonFloorsShow,

          entrance: entrance,
          elevator: elevator,
          stairs: stairs,

          publicParking: publicParking,
          privateParking: privateParking,

          readyToMove: abilityStatus,
          underConstruction: underConstruction,

          ageofProperty: ageofProperty,

          expectedby: expectedby,
          expectedbyShow: expectedbyShow,

          availableDate: availableDate,
        };
        dispatch(setPropertyProfile(propertyProfile));
        dispatch(setPostPropertyStepsStatus(3));
      }
    }
  };

  return (
    <>
      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA">
          <div className="Area-widget">
            <AddAreaDetails
              carpetAreaChange={carpetAreaChange}
              builtupChange={builtupChange}
              superbuiltupChange={superbuiltupChange}
              carpetAreaMesurment={carpetAreaMesurment}
              builtupMesurment={builtupMesurment}
              superbuiltupMesurment={superbuiltupMesurment}
              areaDetailsError={areaDetailsError}
            />

            <div className="OfficeSetup-widget">
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <div>
                          <span
                            className="xidHeading_headingText__Ulc2a"
                            style={{ display: "inline" }}
                          >
                            Shop facade size
                          </span>
                          <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                            (Optional)
                          </em>
                        </div>
                      </div>
                      <div className="caption_strong_small">
                        <em className="xidHeading_subLabel__1Fc9h">
                          Shop - front related details
                        </em>
                      </div>
                    </h4>
                  </div>
                </div>
                <div className="carpetArea-widget">
                  <div className="splitWidget_inputFiledWrap__1vX9m  undefined">
                    <div className="pageComponent" id="carpet-input">
                      <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                        <div className="false">
                          <input
                            type="text"
                            class="false false"
                            value={entranceWidth}
                            onChange={(e) =>
                              setEntranceWidth(
                                e.target.value.replace(/\D/g, "")
                              )
                            }
                            placeholder="Entrance width"
                          />
                          <div></div>
                          <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT">
                            {/* <span className='undefined'>Carpet Area</span> */}
                          </div>
                        </div>
                        <div className="numberInput_iconsWrapper__3Kpm5">
                          <i></i>
                          <div className="screeningActions_iconWrapper__dB1NM"></div>
                        </div>
                      </div>
                    </div>
                    <div className="pageComponent" id="carpet-input">
                      <div style={{}}>
                        <div className="selectInput_custom_input_wrapper__MqLE9 false  pageComponent">
                          <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                          <div
                            className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf "
                            style={{ border: "none", cursor: "pointer" }}
                            onClick={(e) =>
                              setTypeofentranceWidthShow(
                                !typeofentranceWidthShow
                              )
                            }
                          >
                            {typeofentranceWidth}
                          </div>
                          <div
                            className="selectInput_iconsWrapper__1L2kR"
                            onClick={(e) => {
                              setTypeofentranceWidthShow(
                                !typeofentranceWidthShow
                              );
                            }}
                          >
                            {typeofentranceWidthShow ? (
                              <span className="icon_downArrow">
                                <img
                                  src="/assets/images/all/arrow_up.png"
                                  alt=""
                                />
                              </span>
                            ) : (
                              <span className="icon_downArrow">
                                <img
                                  src="/assets/images/all/arrow_down.png"
                                  alt=""
                                />
                              </span>
                            )}
                          </div>
                        </div>
                        {typeofentranceWidthShow && (
                          <>
                            <div className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown">
                              {areaMesurments.map((mesurment, index) => (
                                <div key={index}>
                                  <span
                                    onClick={() => {
                                      setTypeofentranceWidth(mesurment.name);
                                      setTypeofentranceWidthShow(false);
                                    }}
                                  >
                                    {mesurment.name}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carpetArea-widget">
                  <div className="splitWidget_inputFiledWrap__1vX9m  undefined">
                    <div className="pageComponent" id="carpet-input">
                      <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                        <div className="false">
                          <input
                            type="text"
                            class="false false"
                            value={cellingHeight}
                            onChange={(e) =>
                              setCellingHeight(
                                e.target.value.replace(/\D/g, "")
                              )
                            }
                            placeholder="Ceiling Height"
                          />
                          <div></div>
                          <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT">
                            {/* <span className='undefined'>Carpet Area</span> */}
                          </div>
                        </div>
                        <div className="numberInput_iconsWrapper__3Kpm5">
                          <i></i>
                          <div className="screeningActions_iconWrapper__dB1NM"></div>
                        </div>
                      </div>
                    </div>
                    <div className="pageComponent" id="carpet-input">
                      <div style={{}}>
                        <div className="selectInput_custom_input_wrapper__MqLE9 false  pageComponent">
                          <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                          <div
                            className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf "
                            style={{ border: "none", cursor: "pointer" }}
                            onClick={(e) =>
                              setTypeofcellingHeightShow(
                                !typeofcellingHeightShow
                              )
                            }
                          >
                            {typeofcellingHeight}
                          </div>
                          <div
                            className="selectInput_iconsWrapper__1L2kR"
                            onClick={(e) => {
                              setTypeofcellingHeightShow(
                                !typeofcellingHeightShow
                              );
                            }}
                          >
                            {typeofcellingHeightShow ? (
                              <span className="icon_downArrow">
                                <img
                                  src="/assets/images/all/arrow_up.png"
                                  alt=""
                                />
                              </span>
                            ) : (
                              <span className="icon_downArrow">
                                <img
                                  src="/assets/images/all/arrow_down.png"
                                  alt=""
                                />
                              </span>
                            )}
                          </div>
                        </div>
                        {typeofcellingHeightShow && (
                          <>
                            <div className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown">
                              {areaMesurments.map((mesurment, index) => (
                                <div key={index}>
                                  <span
                                    onClick={() => {
                                      setTypeofcellingHeight(mesurment.name);
                                      setTypeofcellingHeightShow(false);
                                    }}
                                  >
                                    {mesurment.name}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="Washrooms-widget Preference_widget_11">
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <div>
                          <span
                            className="xidHeading_headingText__Ulc2a"
                            style={{ display: "inline" }}
                          >
                            Washrooms
                          </span>
                        </div>
                      </div>
                    </h4>
                  </div>
                  <p style={{ color: "red" }}>{washroomsError} </p>
                  <div className="false">
                    <div
                      className="pagecomponent amenties-widget_inner"
                      id="ownership-input"
                      style={{ textAlign: "left" }}
                    >
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            privateWashRooms === true
                              ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                              : "tags-and-chips_textOnly__2k10B pageComponent "
                          }
                          onClick={() => {
                            setPrivateWashRooms(!privateWashRooms);
                          }}
                          id="1"
                        >
                          {privateWashRooms === true ? (
                            <>
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Private washrooms</span>
                        </div>
                      </div>
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            publicWashRooms === true
                              ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                              : "tags-and-chips_textOnly__2k10B pageComponent "
                          }
                          onClick={() => {
                            setPublicWashRooms(!publicWashRooms);
                          }}
                          id="2"
                        >
                          {publicWashRooms === true ? (
                            <>
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Public washrooms</span>
                        </div>
                      </div>
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            washrooms === true
                              ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                              : "tags-and-chips_textOnly__2k10B pageComponent "
                          }
                          onClick={() => {
                            setWashrooms(!washrooms);
                          }}
                          id="3"
                        >
                          {washrooms === true ? (
                            <>
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Not Available</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <FloorDetails
              getNumberOfFloors={getNumberOfFloors}
              getPropertyOnFloors={getPropertyOnFloors}
              floorDetailsError={floorDetailsError}
            />

            <div id="LocatedNear-widget Preference_widget_11">
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <div>
                          <span
                            className="xidHeading_headingText__Ulc2a"
                            style={{ display: "inline" }}
                          >
                            Located Near
                          </span>

                          <em className="xidHeading_subLabel__1Fc9h">
                            (Optional)
                          </em>
                        </div>
                      </div>
                    </h4>
                  </div>
                  <div className="false">
                    <div
                      className="pagecomponent amenties-widget_inner"
                      id="ownership-input"
                      style={{ textAlign: "left" }}
                    >
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            entrance == true
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() => setEntrance(!entrance)}
                          id="1"
                        >
                          {entrance === true ? (
                            <>
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Entrance</span>
                        </div>
                      </div>
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            elevator == true
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() => setElevator(!elevator)}
                          id="2"
                        >
                          {elevator === true ? (
                            <>
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Elevator</span>
                        </div>
                      </div>
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            stairs == true
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() => setStairs(!stairs)}
                          id="3"
                        >
                          {stairs === true ? (
                            <>
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Stairs</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ParkingType
              parkingType={parkingDefaultType}
              parkingPrivateType={parkingPrivateType}
              parkingPublicType={parkingPublicType}
              parkingError={parkingError}
            />

            <AvaliabilityStatus
              getPropertyAvailability={getPropertyAvailability}
              getPropertyAge={getPropertyAge}
              abilityStatusError={availabilityError}
              selectAgeError={selectAgeError}
              timePossessionError={timePossessionError}
              getPropertyExpectedYear={getPropertyExpectedYear}
              getPropertyExpectedMonth={getPropertyExpectedMonth}
              constructionOnProperty={constructionOnProperty}
            />

            <AvaliableFrom
              availableFromDate={availableFromDate}
              availabilityDateError={availableDateError}
            />

            {/* <div className="Posession-widget">
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="formWidget_inlineContainer__3oR34 false">
                  <div className="widgetbox_header_text__2BXc_">
                    <div className="xidHeading_PageHeadingwrap__3z0kL">
                      <h4>
                        <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                          <div>
                            <span
                              className="xidHeading_headingText__Ulc2a"
                              style={{ display: "inline" }}
                            >
                              Suitable for business types
                            </span>
                          </div>
                        </div>
                      </h4>
                    </div>
                  </div>
                </div>
                <div id="availability-input">
                  <div>
                    <div className="selectInput_custom_input_wrapper__MqLE9 selectInput_multiple_input__1oijd  pageComponent">
                      <div className="selectInput_label__Sr6HY caption_subdued_large4">
                        Select business type
                      </div>
                      <div className="selectInput_custom_input__DdGZD input_placeholder_completed false"></div>
                      <div className="selectInput_iconsWrapper__1L2kR">
                        <span className="icon_downArrow">
                          <img src="/assets/images/all/arrow_down.png" alt="" />
                        </span>
                        <div className="screeningActions_iconWrapper__dB1NM"></div>
                      </div>
                    </div>
                    <div
                      className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown"
                      style={{ width: "500px", marginLeft: "0px" }}
                    >
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Select business type
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          ATM
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Bakery
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Boutique
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Clinic
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Clothes
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Cloud Kitchen
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Coffee
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Dental Clinic
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Fast Food
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Footwear
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Grocery
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Gym
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Jewellery
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Juice
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Meat
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Medical
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Mobile
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Pub/Bar
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Restaurants
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Salon/Spa
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Stationery
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Sweet
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Tea Stall
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                          />
                          Other business type
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div id="Location-widget">
            <div id="PrimaryDetailsButtonsWrapper-widget">
              <div className="dummyFormWidget_flexContainer__2vVMv">
                <div id="PrimaryDetailsButton-button" className="pageComponent">
                  <div>
                    <div className="buttonInput_continue_btn__1gb5M ">
                      <button
                        type="button"
                        className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                        onClick={(e) => propertyProfileClick(e)}
                      >
                        <span>
                          <span>Continue</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RetailPropertyDetails;
