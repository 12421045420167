import React from "react";
import HomeBanner from "./home/HomeBanner";
import LatestProperties from "./home/LatestProperties";
import ChooseProperties from "./home/ChooseProperties";
import BestCities from "./home/BestCities";
import OurAgents from "./home/OurAgents";
import Achivement from "./home/Achivement";
import OurClientSays from "./home/OurClientSays";
import Newslatter from "./home/Newslatter";
import BHKchoiceInMind from "./home/BHKchoiceInMind";
import InsightsandTools from "./home/InsightsandTools";
import PopularBuilders from "./home/PopularBuilders";
import RecommendedProperties from "./home/RecommendedProperties";
import ApartmentsVillas from "./home/ApartmentsVillas";
import MoveInNow from "./home/MoveInNow";
import CheckPropertyRates from "./home/CheckPropertyRates";
import ExploreOurServices from "./home/ExploreOurServices";
import ExploreRealEstate from "./home/ExploreRealEstate";
import CuratedPgCollections from "./home/CuratedPgCollections";
import HomesByFurnishing from "./home/HomesByFurnishing";
import RecentelySearched from "./PropertyInfo/RecentelySearched";
import PostProperty from "./PropertyInfo/PostProperty";
import { Link } from "react-router-dom";
import SubcriptionPage from "./SubcriptionPage";
import BoostedProperty from "./home/BoostedProperty";
import { PopupComponent } from "./home/PopupComponent";
import { useSelector } from "react-redux";

const Facebook = () => {
  const { isAuth } = useSelector((store) => store.Athentication);

  return (
    <>
      <PopupComponent />
      <HomeBanner />
      <LatestProperties />
      <ChooseProperties />
      {/* <RecentelySearched/> */}
      <BoostedProperty />
      {/* <BestCities /> */}
      {/* <OurAgents /> */}
      <RecommendedProperties />
      {/* <InsightsandTools /> */}
      {/* <PopularBuilders /> */}
      <OurClientSays />
      <Achivement />
      <ApartmentsVillas />
      <MoveInNow />
      <BHKchoiceInMind />
      {/* <CheckPropertyRates/> */}
      <ExploreOurServices />
      <ExploreRealEstate />

      {/* <CuratedPgCollections/> */}
      {/* <HomesByFurnishing/> */}
      {/* <Newslatter /> */}
    </>
  );
};

export default Facebook;
