import React from 'react'
import { Link } from 'react-router-dom'

const GetLeades = () => {
    return (
        <>
            <div className='pageComponent component__leadsView' style={{height:"60vh"}}>
                <div className='component__resultHeader' style={{marginTop:"30px", textAlign:"left"}}>
                    <ul>
                        <li>Get Leads serviced by us who have matching or similar interest to your property listing.</li>
                    </ul>
                    <div className='component__restHeader'>
                        <Link to="/">Go to DashBoard</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GetLeades