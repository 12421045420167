import React from "react";
import { useSelector } from "react-redux";
import EditLocationDetails from "./editLocation/EditLocationDetails";
import EditOfficeLocationDetails from "./editLocation/EditOfficeLocationDetails";
import EditPlotLandLocationDetails from "./editLocation/EditPlotLandLocationDetails";

const EditLocationTemp = () => {
  const { BasicDetails } = useSelector((state) => state.Property);
  return (
    <>
      {BasicDetails.supercatName === "Residential" ? (
        <>
          <EditLocationDetails />
        </>
      ) : (
        <>
          {BasicDetails.subcatName === "Office" ? (
            <>
              <EditOfficeLocationDetails />
            </>
          ) : (
            <>
              <EditPlotLandLocationDetails />
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditLocationTemp;