import React, { useId, useState } from "react";
import Login from "../Logins/Login";
import Registration from "../Logins/Registration";
import { Link } from "react-router-dom";
import { setModelShow } from "../../redux/athentication/Athentication";
import { useDispatch, useSelector } from "react-redux";

const HeaderNew = () => {
  const { modelShow } = useSelector((store) => store.Athentication);
  const { loginData, isAuth } = useSelector((store) => store.Athentication);

  const dispatch = useDispatch();

  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { catTotal } = useSelector((store) => store.Category);

  const [search, setSearch] = useState("");
  const [showresult, setShowresult] = useState(false);

  const [buychange, setBuychange] = useState(true);
  const [rentchange, setRentchange] = useState(false);

  const [supercat, setSupercat] = useState("Residential");
  const [cat, setCat] = useState("Buy");

  const residential = (e, value) => {
    setBuychange(false);
    setRentchange(false);

    setSupercat("Residential");
    // setSubcat("All");
    if (value === "Buy") {
      setCat("Buy");
      setBuychange(true);
    } else if (value === "Rent") {
      setCat("Rent");
      setRentchange(true);
    }
  };

  return (
    <>
      <header className="main-header">
        <div className="logo-holder">
          <Link to="/">
            <img src="/assets/images/logo2.png" alt="" />
          </Link>
        </div>

        <div className="nav-button-wrap color-bg nvminit">
          <div className="nav-button">
            <span />
            <span />
            <span />
          </div>
        </div>

        {isAuth === true ? (
          <>
            <div className="add-list_wrap">
              <Link to="/PostPropertyHome" className="add-list color-bg">
                <i className="fal fa-plus" />
                <span>
                  Post Property
                  <span
                    style={{
                      background: "#219653",
                      color: "#fff",
                      padding: "2px 5px",
                      borderRadius: "3px",
                      fontWeight: "700",
                      lineHeight: "9px",
                      letterSpacing: ".02em",
                      textTransform: "uppercase",
                      fontSize: "10px",
                    }}
                  >
                    Free
                  </span>
                </span>
              </Link>
            </div>
          </>
        ) : (
          <>
            <div
              className="add-list_wrap modal-open"
              onClick={() => dispatch(setModelShow(true))}
            >
              <Link to="" className="add-list color-bg">
                <i className="fal fa-plus" />
                <span>
                  Post Property
                  <span
                    style={{
                      background: "#219653",
                      color: "#fff",
                      padding: "2px 5px",
                      borderRadius: "3px",
                      fontWeight: "700",
                      lineHeight: "9px",
                      letterSpacing: ".02em",
                      textTransform: "uppercase",
                      fontSize: "10px",
                    }}
                  >
                    Free
                  </span>
                </span>
              </Link>
            </div>
          </>
        )}

        {isAuth === true ? (
          <>
            <Link to="/dashboard">
              <div className="show-reg-form">
                <i className="fas fa-user" />
                <span>{loginData.name}</span>
              </div>
            </Link>
          </>
        ) : (
          <>
            <div
              className="show-reg-form modal-open"
              onClick={() => dispatch(setModelShow(true))}
            >
              <i className="fas fa-user" />
              <span>Sign In</span>
            </div>
          </>
        )}
      </header>

      {/* for model */}
      <div
        className="main-register-wrap modal"
        style={modelShow === true ? { display: "block" } : { display: "none" }}
      >
        {/* <div className="reg-overlay" /> */}
        <div className="main-register-holder tabs-act">
          <div
            className={
              modelShow === true
                ? "main-register-wrapper modal_main fl-wrap vis_mr"
                : "main-register-wrapper modal_main fl-wrap"
            }
          >
            <div className="main-register-header color-bg">
              <div className="main-register-logo fl-wrap">
                <img src="/assets/images/white-logo1.png" alt="" />
              </div>
              <div className="main-register-bg">
                <div className="mrb_pin" />
                <div className="mrb_pin mrb_pin2" />
              </div>
              <div className="mrb_dec" />
              <div className="mrb_dec mrb_dec2" />
            </div>

            <div className="main-register">
              <div className="close-reg">
                <i className="fal fa-times" />
              </div>
              <ul className="tabs-menu fl-wrap no-list-style">
                <li className="current">
                  <a href="#tab-1">
                    <i className="fal fa-sign-in-alt" /> Login
                  </a>
                </li>
                <li>
                  <a href="#tab-2">
                    <i className="fal fa-user-plus" /> Register
                  </a>
                </li>
              </ul>

              <div className="tabs-container">
                <div className="tab">
                  <div id="tab-1" className="tab-content first-tab">
                    <Login />
                  </div>

                  <div className="tab">
                    <div id="tab-2" className="tab-content">
                      <Registration />
                    </div>
                  </div>
                </div>

                <div className="log-separator fl-wrap">
                  <span>or</span>
                </div>
                <div className="soc-log fl-wrap">
                  <p>For faster login or register use your social account.</p>
                  <Link to="#" className="facebook-log">
                    Facebook
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderNew;
