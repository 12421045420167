import React from "react";

const Localities = () => {
  const toplocalities = [
    { id: "1", name: "Kondapur" },
    { id: "2", name: "Bachupally" },
    { id: "3", name: "Kokapet" },
    { id: "4", name: "Kollur" },
    { id: "5", name: "Tellapur" },
  ];
  const otherlocalities = [
    { id: "1", name: "Kondapur" },
    { id: "2", name: "Bachupally" },
    { id: "3", name: "Kokapet" },
    { id: "4", name: "Kollur" },
    { id: "5", name: "Tellapur" },
    { id: "6", name: "Shad nagar" },
    { id: "7", name: "Gachibowli" },
    { id: "8", name: "Miyapur" },
    { id: "9", name: "Nallagandla" },
    { id: "10", name: "Narsingi" },
    { id: "11", name: "Patancheru Mandal" },
    { id: "12", name: "Adibatla" },
    { id: "13", name: "Maheswaram" },
    { id: "14", name: "Ghatkesar" },
    { id: "15", name: "Shankarpally" },
    { id: "16", name: "Pragati Nagar" },
    { id: "17", name: "Manikonda" },
    { id: "18", name: "Beeramguda" },
    { id: "19", name: "Sangareddy" },
    { id: "20", name: "Sadashivpet" },
    { id: "21", name: "Puppalaguda" },
    { id: "22", name: "Kothur" },
    { id: "23", name: "ibrahimpatnam" },
    { id: "24", name: "Hi Tech City" },
    { id: "25", name: "Nizampet" },
    { id: "26", name: "Mokila" },
    { id: "27", name: "Chandanagar" },
    { id: "28", name: "Bandlaguda" },
    { id: "29", name: "Bibi Nagar" },
    { id: "30", name: "Aminpur" },
    { id: "31", name: "Shamshabad" },
    { id: "32", name: "Uppal" },
    { id: "33", name: "Kukatpally" },
    { id: "34", name: "Kandi" },
    { id: "35", name: "Nagaram" },
    { id: "36", name: "Tukkuguda" },
    { id: "37", name: "Choutuppal" },
    { id: "38", name: "Mallampet" },
    { id: "39", name: "Isnapur" },
    { id: "40", name: "Kandukur" },
    { id: "41", name: "Rudraram" },
    { id: "42", name: "Kadtal" },
    { id: "43", name: "Nadigama" },
    { id: "44", name: "Financial District" },
    { id: "45", name: "Gajulramram" },
    { id: "46", name: "Hafeezpet" },
    { id: "47", name: "Kapra" },
    { id: "48", name: "Mucherla" },
    { id: "49", name: "TurkaYamjal" },
    { id: "50", name: "Rajendra Nagar" },
    { id: "51", name: "Jubille Hills" },
    { id: "52", name: "Indresham" },
    { id: "53", name: "Gopanpally" },
    { id: "54", name: "Nanramguda" },
    { id: "55", name: "Boduppal" },
    { id: "56", name: "Bowrampet" },
    { id: "57", name: "Moinabad" },
    { id: "58", name: "Banjara hills" },
    { id: "59", name: "LB Nagar" },
    { id: "60", name: "Attapur" },
  ];
  return (
    <div className="mainbarfiltercomponents">
      <div style={{ marginLeft: "5px", marginBottom: "5px" }}>
        <input type="text" placeholder="Search Localities" />
      </div>
      <div>
        <p style={{ color: "#000" }}>Top Localities</p>
      </div>
      {toplocalities.map((top, index) => (
        <div className="propertyboxes" key={index}>
          <input type="checkbox" />
          <p className="propertytextpara">{top.name}</p>
        </div>
      ))}
      <div>
        <p style={{ color: "#000" }}>Other Localities</p>
      </div>
      {otherlocalities.map((other, index) => (
        <div className="propertyboxes" key={index}>
          <input type="checkbox" />
          <p className="propertytextpara">{other.name}</p>
        </div>
      ))}
    </div>
  );
};

export default Localities;
