import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsFillSearchHeartFill, BsMicFill } from "react-icons/bs";
import { BiCurrentLocation } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import { Badge } from "react-bootstrap";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { searchPost } from "../../../redux/search/SearchSlice";
import ReactSlider from "react-slider";

const ProjectsSearchComponent = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();

  const [showResults, setShowResults] = useState(false);
  const [toggle, setToggle] = useState(false);

  const [selectProjectStatus, setSelectProjectStatus] = useState([]);
  const [selectedBedrooms, setSelectedBedrooms] = useState([]);
  const [propertyPrice, setPropertyPrice] = useState([]);
  console.log(selectProjectStatus, "selectProjectStatus");

  useEffect(() => {
    props.getBudgetDetails(propertyPrice);
    props.getBedRoomDetails(selectedBedrooms);
  }, [propertyPrice, selectedBedrooms]);

  const projectStatus = [
    { id: "1", name: " New Launch" },
    { id: "2", name: "Under Construction" },
    { id: "3", name: " Ready to move" },
  ];

  useEffect(() => {
    let statusArray = [];
    for (let index = 0; index < projectStatus.length; index++) {
      statusArray.push(projectStatus[index].name);
    }
    setSelectProjectStatus([...statusArray]);
    props.getProjectStatusDetails([...statusArray]);
  }, []);

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  const handleProjectPress = (evt) => {
    if (selectProjectStatus.includes(evt.target.value)) {
      setSelectProjectStatus(
        selectProjectStatus.filter((e) => {
          return e !== evt.target.value;
        })
      );
      props.getProjectStatusDetails(
        selectProjectStatus.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setSelectProjectStatus((selectProjectStatus) => [
        ...selectProjectStatus,
        evt.target.value,
      ]);
      props.getProjectStatusDetails((selectProjectStatus) => [
        ...selectProjectStatus,
        evt.target.value,
      ]);
    }
  };

  const handleBedroomPress = (selectvalue) => {
    if (selectedBedrooms.includes(selectvalue)) {
      setSelectedBedrooms(
        selectedBedrooms.filter((value) => value !== selectvalue)
      );
    } else {
      setSelectedBedrooms([...selectedBedrooms, selectvalue]);
    }
  };

  const bhkapartments = [
    { id: "1", name: "+ 1 RK / 1 BHK" },
    { id: "2", name: "+ 2 BHK" },
    { id: "3", name: "+ 3 BHK" },
    { id: "4", name: "+ 4 BHK" },
    { id: "5", name: "+ 5 BHK" },
  ];

  return (
    <>
      <div className="dropdownbox " style={{paddingTop:"0"}}>
        <div className="mb-1">
          <p className="text-dark">Residential Project</p>
        </div>
        <div className="showsubcatbanner">
          {projectStatus.map((project, index) => (
            <div className="subcatBoxbann">
              <div className="childlabel" key={index}>
                <input
                  type="checkbox"
                  id="flat"
                  name="flat"
                  // defaultChecked={true}
                  value={project.name}
                  checked={selectProjectStatus.includes(project.name)}
                  onChange={(e) => handleProjectPress(e, project.name)}
                />
                <label className="" htmlFor="flat">
                  {project.name}
                </label>
              </div>
            </div>
          ))}
        </div>
        <div className="label-caption">
          <p>
            Looking for commercial properties ?<a href="#">Click here</a>
          </p>
          <hr />
        </div>
        <div className="dropdown-lists">
          <div className="parent-box">
            <div
              className={
                toggle === "Budget"
                  ? "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                  : "main-boxes radioInput_textOnly__1r7GL "
              }
              onClick={() => setToggle("Budget")}
            >
              <label className="">Budget</label>
              {toggle === "Budget" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>
            {toggle === "Budget" ? (
              <>
                <div className="range-box">
                  <div className="range-head">
                    <h6 className="">Select Price Range</h6>
                  </div>
                  <div className="range-bar">
                    <label htmlFor="customRange2" className="">
                      0 - 100 Crores+
                    </label>
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="example-thumb"
                      trackClassName="example-track"
                      defaultValue={[0, 1000000000]}
                      max={1000000000}
                      min={0}
                      renderThumb={(props, state) => (
                        <div {...props}>{state.valueNow}</div>
                      )}
                      onChange={(value, index) => setPropertyPrice(value)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className="parent-box">
            <div
              className={
                toggle === "Bedroom"
                  ? "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                  : "main-boxes radioInput_textOnly__1r7GL "
              }
              onClick={() => setToggle("Bedroom")}
            >
              <label className="">Bedroom </label>
              {toggle === "Bedroom" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>

            {toggle === "Bedroom" ? (
              <>
                <div className="bedroombox mediabedroom">
                  <div className="bedroom-head">
                    <h6 className="">Number of Bedrooms</h6>
                  </div>
                  <div className="boxes-parent" style={{ marginTop: "-2px" }}>
                    {bhkapartments.map((bhk, index) => (
                      <div
                        key={index}
                        className={
                          selectedBedrooms.includes(bhk.id)
                            ? "  tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0 "
                            : "  tags-and-chips_textOnly__2k10B pageComponent "
                        }
                        onClick={() => {
                          handleBedroomPress(bhk.id);
                        }}
                      >
                        <label className=""> {bhk.name} </label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className=""></div>
        </div>
      </div>
    </>
  );
};

export default ProjectsSearchComponent;
