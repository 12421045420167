import React, { useEffect, useState } from "react";
import { BiArrowBack, BiPlus, BiMinus } from "react-icons/bi";
import { GrCircleQuestion } from "react-icons/gr";
import { Tooltip } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { BiInfoCircle } from "react-icons/bi";
import {
  setPostPropertyStepsStatus,
  setPropertyProfile,
} from "../../../../../../redux/property/PropertySlice";
import AddAreaDetails from "../../../../finalcomponents/AddAreaDetails";

import PLotComponents from "../../../../finalcomponents/PLotComponents";

const PlotLandPropertyDetails = () => {
  const { PropertyProfile, BasicDetails } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();

  const [carpetArea, setCarpetArea] = useState("");
  const [typeofCarpetArea, setTypeofCarpetArea] = useState("sq.ft.");
  const [typeofCarpetAreaShow, setTypeofCarpetAreaShow] = useState(false);

  const [builtupArea, setBuiltupArea] = useState("");
  const [typeofBuiltupArea, setTypeofBuiltupArea] = useState("sq.ft.");
  const [typeofBuiltupAreaShow, setTypeofBuiltupAreaShow] = useState(false);
  const [typeofBuiltupAreaBox, setTypeofBuiltupAreaBox] = useState(false);

  const [superBuiltupArea, setSuperBuiltupArea] = useState("");
  const [typeofSuperBuiltupArea, setTypeofSuperBuiltupArea] =
    useState("sq.ft.");
  const [typeofSuperBuiltupAreaShow, setTypeofSuperBuiltupAreaShow] =
    useState(false);
  const [typeofSuperBuiltupAreaBox, setTypeofSuperBuiltupAreaBox] =
    useState(false);

  const [lengthofPlot, setLengthofPlot] = useState("");
  const [widthofPlot, setWidthofPlot] = useState("");

  const [allowedNoofFloors, setAllowedNoofFloors] = useState("");

  const [wallboundryAvaliable, setWallboundryAvaliable] = useState(false);

  const [noofOpenSides, setNoofOpenSides] = useState("");

  const [OnthisProperty, setOnthisProperty] = useState(false);

  const [shed, setShed] = useState(false);
  const [room, setRoom] = useState(false);
  const [washRoom, setWashRoom] = useState(false);
  const [others, setOthers] = useState(false);

  const [expectedby, setExpectedby] = useState("");
  const [expectedbyShow, setExpectedbyShow] = useState(false);
  const [yearofExpectedBy, setYearofExpectedBy] = useState("");


  const [roadWidth, setRoadWidth] = useState("");

  const [facingRoad, setFacingRoad] = useState("");
 
  //errors
  const [areaDetailsError, setAreaDetailsError] = useState("");

  const [lengtthOfPlotError, setLengtthOfPlotError] = useState("");
  const [widthofPlotError, setWidthofPlotError] = useState("");

  const [allowedFloorsError, setAllowedFloorsError] = useState("");

  const [wallboundryAvaliableError, setWallboundryAvaliableError] =
    useState("");
  const [openSidesError, setOpenSidesError] = useState("");
  const [anyConstructionError, setAnyConstructionError] = useState("");
  const [possessionError, setPossessionError] = useState("");
  const [widthOfFacingError, setWidthOfFacingError] = useState("");
  const [FacingError, setFacingError] = useState("");

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const carpetAreaChange = (e) => {
    const result = e;
    setCarpetArea(result);
  };

  const builtupChange = (e) => {
    const result = e;
    setBuiltupArea(result);
  };

  const superbuiltupChange = (e) => {
    const result = e;
    setSuperBuiltupArea(result);
  };

  const carpetAreaMesurment = (e) => {
    const result = e;
    setTypeofCarpetArea(result);
  };

  const builtupMesurment = (e) => {
    const result = e;
    setTypeofBuiltupArea(result);
  };
  const superbuiltupMesurment = (e) => {
    const result = e;
    setTypeofSuperBuiltupArea(result);
  };

  const lengthOfPlot = (e) => {
    const result = e;
    setLengthofPlot(result);
  };
  const widthOfPlot = (e) => {
    const result = e;
    setWidthofPlot(result);
  };
  const allowedFloors = (e) => {
    const result = e;
    setAllowedNoofFloors(result);
  };
  const avialableWallBoundry = (e) => {
    const result = e;
    setWallboundryAvaliable(result);
  };
  const openSides = (e) => {
    const result = e;
    setNoofOpenSides(result);
  };

  const constructionOnProperty = (e) => {
    const result = e;
    setOnthisProperty(result);
  };
  const roomSet = (e) => {
    const result = e;
    setRoom(result);
  };
  const shedSet = (e) => {
    const result = e;
    setShed(result);
  };

  const washroomSet = (e) => {
    const result = e;
    setWashRoom(result);
  };

  const expectedbyShowSet = (e) => {
    const result = e;
    setExpectedbyShow(result);
  };

  const othersSet = (e) => {
    const result = e;
    setOthers(result);
  };

  const possesion = (e) => {
    const result = e;
    setExpectedby(result);
  };

  const propertyFacing = (e) => {
    const result = e;
    setFacingRoad(result);
  };

  const widthOfFacingRoad = (e) => {
    const result = e;
    setRoadWidth(result);
  };

  useEffect(() => {
    if (PropertyProfile !== "") {
      setCarpetArea(PropertyProfile.carpetArea);
      setTypeofCarpetArea(PropertyProfile.typeofCarpetArea);
      setTypeofCarpetAreaShow(PropertyProfile.typeofCarpetAreaShow);

      setBuiltupArea(PropertyProfile.builtupArea);
      setTypeofBuiltupArea(PropertyProfile.typeofBuiltupArea);
      setTypeofBuiltupAreaShow(PropertyProfile.typeofBuiltupAreaShow);
      setTypeofBuiltupAreaBox(PropertyProfile.typeofBuiltupAreaBox);

      setSuperBuiltupArea(PropertyProfile.superBuiltupArea);
      setTypeofSuperBuiltupArea(PropertyProfile.typeofSuperBuiltupArea);
      setTypeofSuperBuiltupAreaShow(PropertyProfile.typeofSuperBuiltupAreaShow);
      setTypeofSuperBuiltupAreaBox(PropertyProfile.typeofSuperBuiltupAreaBox);

      setLengthofPlot(PropertyProfile.lengthofPlot);
      setWidthofPlot(PropertyProfile.widthofPlot);

      setAllowedNoofFloors(PropertyProfile.allowedNoofFloors);

      setWallboundryAvaliable(PropertyProfile.wallboundryAvaliable);

      setNoofOpenSides(PropertyProfile.noofOpenSides);

      setOnthisProperty(PropertyProfile.OnthisProperty);

      setShed(PropertyProfile.shed);
      setRoom(PropertyProfile.room);
      setWashRoom(PropertyProfile.washRoom);
      setOthers(PropertyProfile.others);

      setExpectedby(PropertyProfile.expectedby);
      setExpectedbyShow(PropertyProfile.expectedbyShow);
    }
  }, [PropertyProfile]);


  useEffect(() => {
    if (carpetArea !== "" && typeofCarpetArea !== "") {
      setAreaDetailsError("");
    }
    if (lengthofPlot !== "") {
      setLengtthOfPlotError("");
    }
    if (widthofPlot !== "") {
      setWidthofPlotError("");
    }
    if (allowedNoofFloors !== "") {
      setAllowedFloorsError("");
    }
    if (wallboundryAvaliable !== "") {
      setWallboundryAvaliableError("");
    }
    if (noofOpenSides !== "") {
      setOpenSidesError("");
    }
    if (OnthisProperty !== "") {
      setAnyConstructionError("");
    }
    if (expectedby !== "") {
      setPossessionError("");
    }
    if (roadWidth !== "") {
      setWidthOfFacingError("");
    }
    if (facingRoad !== "") {
      setFacingError("");
    }
  }, [
    carpetArea,
    typeofCarpetArea,
    lengthofPlot,
    widthofPlot,
    allowedNoofFloors,
    wallboundryAvaliable,
    noofOpenSides,
    OnthisProperty,
    expectedby,
    roadWidth,
    facingRoad,
  ]);

  const propertyProfileClick = async (e) => {
    e.preventDefault();
    setAreaDetailsError("");
    setLengtthOfPlotError("");
    setWidthofPlotError("");
    setAllowedFloorsError("");
    setWallboundryAvaliableError("");
    setOpenSidesError("");
    setAnyConstructionError("");
    setPossessionError("");
    setWidthOfFacingError("");
    setFacingError("");

    if (carpetArea !== "" && typeofCarpetArea !== "") {
      setAreaDetailsError("");
    } else {
      setAreaDetailsError("Atleast one area type mandatory");
    }

    if (lengthofPlot !== "") {
      setLengtthOfPlotError("");
    } else {
      setLengtthOfPlotError("Enter The Lenght of plot");
    }

    if (widthofPlot !== "") {
      setWidthofPlotError("");
    } else {
      setWidthofPlotError("Enter the Width of plot");
    }

    if (allowedNoofFloors !== "") {
      setAllowedFloorsError("");
    } else {
      setAllowedFloorsError("Enter the Allowed No.of Floors");
    }

    if (wallboundryAvaliable !== "") {
      setWallboundryAvaliableError("");
    } else {
      setWallboundryAvaliableError("Please Select the BoundryWall ");
    }

    if (noofOpenSides !== "") {
      setOpenSidesError("");
    } else {
      setOpenSidesError("Please Select your Sides ");
    }

    if (OnthisProperty !== "") {
      setAnyConstructionError("");
    } else {
      setAnyConstructionError("Please Select Construction In this Property ");
    }

    if (expectedby !== "") {
      setPossessionError("");
    } else {
      setPossessionError("Please Select Expected Date ");
    }

    if (roadWidth !== "") {
      setWidthOfFacingError("");
    } else {
      setWidthOfFacingError("Please Enter the Road Width ");
    }

    if (facingRoad !== "") {
      setFacingError("");
    } else {
      setFacingError("Please Select Road Face");
    }

    if (
      carpetArea !== "" &&
      typeofCarpetArea !== "" &&
      lengthofPlot !== "" &&
      widthofPlot !== "" &&
      allowedNoofFloors !== "" &&
      wallboundryAvaliable !== "" &&
      noofOpenSides !== "" &&
      OnthisProperty !== "" &&
      expectedby !== "" &&
      roadWidth !== "" &&
      facingRoad !== ""
    ) {
      const propertyform = {
        carpetArea: carpetArea,
        typeofCarpetArea: typeofCarpetArea,
        typeofCarpetAreaShow: typeofCarpetAreaShow,

        builtupArea: builtupArea,
        typeofBuiltupArea: typeofBuiltupArea,
        typeofBuiltupAreaShow: typeofBuiltupAreaShow,
        typeofBuiltupAreaBox: typeofBuiltupAreaBox,

        superBuiltupArea: superBuiltupArea,
        typeofSuperBuiltupArea: typeofSuperBuiltupArea,
        typeofSuperBuiltupAreaShow: typeofSuperBuiltupAreaShow,
        typeofSuperBuiltupAreaBox: typeofSuperBuiltupAreaBox,

        lengthofPlot: lengthofPlot,
        widthofPlot: widthofPlot,

        allowedNoofFloors: allowedNoofFloors,

        wallboundryAvaliable: wallboundryAvaliable,

        noofOpenSides: noofOpenSides,

        OnthisProperty: OnthisProperty,

        shed: shed,
        room: room,
        washRoom: washRoom,
        others: others,

        expectedby: expectedby,
        expectedbyShow: expectedbyShow,
        roadWidth: roadWidth,
        facingRoad: facingRoad,

      };

      dispatch(setPropertyProfile(propertyform));
      dispatch(setPostPropertyStepsStatus(3));
    }
  };

  return (
    <>
      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA">
          <div className="page_ppf_step1__1icHe  pageComponent">
            <div className="PrimaryDetailsHeader-desktopHeader">
              <div className="desktopHeader_iconInlineHead__W8_RL">
                <div className="caption_subdued_medium">
                  <span
                    className="desktopHeader_backArrow__1lVxQ iconS_PPFDesk_24 icon_backGrey"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  >
                    <BiArrowBack />
                  </span>
                  <div style={{ fontSize: "14px" }}>Back</div>
                </div>
                <div className="desktopHeader_text__3iyVt">
                  <div className="undefined title_l_semiBold">
                    Tell us about your property
                  </div>
                </div>
              </div>

              <AddAreaDetails
                carpetAreaChange={carpetAreaChange}
                builtupChange={builtupChange}
                superbuiltupChange={superbuiltupChange}
                carpetAreaMesurment={carpetAreaMesurment}
                builtupMesurment={builtupMesurment}
                superbuiltupMesurment={superbuiltupMesurment}
                areaDetailsError={areaDetailsError}
              />

              <PLotComponents
                lengthOfPlot={lengthOfPlot}
                widthOfPlot={widthOfPlot}
                allowedFloors={allowedFloors}
                avialableWallBoundry={avialableWallBoundry}
                openSides={openSides}
                constructionOnProperty={constructionOnProperty}
                shed={shedSet}
                room={roomSet}
                washroom={washroomSet}
                others={othersSet}
                possesion={possesion}
                expectedbyShow={expectedbyShowSet}
                widthOfFacingRoad={widthOfFacingRoad}
                propertyFacing={propertyFacing}
                carpetAreaMesurment={carpetAreaMesurment}
                lengthOfPlotError={lengtthOfPlotError}
                widthofPlotError={widthofPlotError}
                allowedFloorsError={allowedFloorsError}
                wallboundryAvaliableError={wallboundryAvaliableError}
                openSidesError={openSidesError}
                anyConstructionError={anyConstructionError}
                possessionError={possessionError}
                widthOfFacingError={widthOfFacingError}
                FacingError={FacingError}
              />
            </div>

            <div id="Location-widget">
              <div id="PrimaryDetailsButtonsWrapper-widget">
                <div className="dummyFormWidget_flexContainer__2vVMv">
                  <div
                    id="PrimaryDetailsButton-button"
                    className="pageComponent"
                  >
                    <div>
                      <div className="buttonInput_continue_btn__1gb5M ">
                        <button
                          type="button"
                          className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                          onClick={(e) => propertyProfileClick(e)}
                        >
                          <span>
                            <span>Continue</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlotLandPropertyDetails;
