import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/pagination";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setReadytomoveStatus } from "../../redux/property/PropertySlice";

const MoveInNow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [supercat, setSupercat] = useState("Residential");
  const [cat, setCat] = useState("Buy");

  const [city, setCity] = useState("Hyderabad");
  const [readttomove, setReadytomove] = useState("Ready to move");
  const [undercons, setUndercons] = useState("Under Construction");

  const [readttomoveCount, setReadttomoveCount] = useState(0);
  const [underconsCount, setUnderconsCount] = useState(0);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/property/propertybyavailibility/${city}/${readttomove}`;

        const fetchProperty = await axios.get(url);
        try {
          if (fetchProperty.data.success === true) {
            const propertybyCity = fetchProperty.data.propertybyAvailability;
            setReadttomoveCount(propertybyCity);
          }
        } catch (error) {}
      };
      fetchData();
    } catch (error) {}
  }, []);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/property/propertybyavailibility/${city}/${undercons}`;

        const fetchProperty = await axios.get(url);
        try {
          if (fetchProperty.data.success === true) {
            const propertybyCity = fetchProperty.data.propertybyAvailability;
            setUnderconsCount(propertybyCity);
          }
        } catch (error) {}
      };
      fetchData();
    } catch (error) {}
  }, []);

  const readytomoveClick = () => {
    // navigate(`/property-search/xyz/xyz/xyz/${city}`);
    // dispatch(setReadytomoveStatus(readttomove));
  };

  return (
    <>
      <div className="movein-now">
        <div className="movein-head">
          <h1>Move in now, next year or later</h1>
          <p>Projects based on your preferred possession date</p>
        </div>
        <div className="movein-now-swiper">
          <Swiper
            slidesPerView={3}
            spaceBetween={20}
            pagination={{
              clickable: false,
            }}
            breakpoints={{
              360: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              720: {
                slidesPerView: 2,
                spaceBetween: 10,
              },

              1080: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            modules={[]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <div
                    className="movein-now-item fl-wrap"
                    onClick={() => readytomoveClick()}
                  >
                    <div className="movein-now-img1">
                      <img
                        className="bg"
                        src="/assets/images/sliderImg/d_hp_availability_2.webp"
                        alt=""
                      />
                    </div>
                    <div className="movein-now-content">
                      <h3>
                        <a>Ready to move</a>
                      </h3>
                      <p>{readttomoveCount}+ Properties</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <div className="movein-now-item fl-wrap">
                    <div className="movein-now-img2">
                      <img
                        className="bg"
                        src="/assets/images/sliderImg/hp_this_year.webp"
                        alt=""
                      />
                    </div>
                    <div className="movein-now-content">
                      <h3>
                        <a>Possession after 2023</a>
                      </h3>
                      <p>{underconsCount}+ Properties</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <div className="movein-now-item fl-wrap">
                    <div className="movein-now-img3">
                      <img
                        className="bg"
                        src="/assets/images/sliderImg/hp_beyond_this_year.webp"
                        alt=""
                      />
                    </div>
                    <div className="movein-now-content">
                      <h3>
                        <a>Possession after 2024</a>
                      </h3>
                      <p>{underconsCount}+ Properties</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <div className="movein-now-item fl-wrap">
                    <div className="movein-now-img4">
                      <img
                        className="bg"
                        src="/assets/images/sliderImg/hp_beyond_this_year.webp"
                        alt=""
                      />
                    </div>
                    <div className="movein-now-content">
                      <h3>
                        <a>Possession after 2025</a>
                      </h3>
                      <p>{underconsCount}+ Properties</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <div className="movein-now-item fl-wrap">
                    <div className="movein-now-img5">
                      <img
                        className="bg"
                        src="/assets/images/sliderImg/hp_beyond_this_year.webp"
                        alt=""
                      />
                    </div>
                    <div className="movein-now-content">
                      <h3>
                        <a>Possession after 2026</a>
                      </h3>
                      <p>{underconsCount}+ Properties</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <div className="movein-now-item fl-wrap">
                    <div className="movein-now-img6">
                      <img
                        className="bg"
                        src="/assets/images/sliderImg/hp_beyond_this_year.webp"
                        alt=""
                      />
                    </div>
                    <div className="movein-now-content">
                      <h3>
                        <a>Possession after 2027</a>
                      </h3>
                      <p>{underconsCount}+ Properties</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slick-slide-item">
                <div className="">
                  <div className="movein-now-item fl-wrap">
                    <div className="movein-now-img7">
                      <img
                        className="bg"
                        src="/assets/images/sliderImg/hp_beyond_this_year.webp"
                        alt=""
                      />
                    </div>
                    <div className="movein-now-content">
                      <h3>
                        <a>Possession after 2027</a>
                      </h3>
                      <p>{underconsCount}+ Properties</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default MoveInNow;
