import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RegisterOtp from "./RegisterOtp";
import {
  authActions,
  setModelShow,
  userRegister,
} from "../../redux/athentication/Athentication";
import { setuserId } from "../../redux/property/PropertySlice";

const OtpRegister = () => {
    const { mob } = useSelector((state) => state.Athentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const nameChange = (e) => {
    let value = e.target.value;
    setName(value);
  };

  const emailChange = (e) => {
    let value = e.target.value;
    setEmail(value);
  };

  const handleRegdSubmit = async (e) => {
    e.preventDefault();
    if (name !== "" && email !== "") {
      try {
        const formData = {
          name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
          email: email,
          mobile: mob,
        };
        const userDetails = await dispatch(userRegister(formData));
      
        if (userDetails.payload.success === true) {
          const userData = userDetails.payload.user;
          const authenticForm = {
            ...userData,
            isAuth: true,
          };
          dispatch(authActions.signIn(authenticForm));
          dispatch(setuserId(userData._id));
          dispatch(setModelShow(false));
        }
      } catch (error) {}
    } else {
    }
  };
  return (
    <div>
      <form
        name="registerform"
        className="main-register-form"
        onSubmit={(e) => handleRegdSubmit(e)}
      >
        <label>
          Full Name *
          <span className="dec-icon">
            <i className="fal fa-user" />
          </span>
        </label>
        <input
          name="name"
          type="text"
          placeholder="Enter Your Name"
          required
          value={name}
          onChange={(e) => nameChange(e)}
        />
        <label>
          Email Address *
          <span className="dec-icon">
            <i className="fal fa-envelope" />
          </span>
        </label>
        <input
          name="email"
          type="email"
          placeholder="Enter Your Email"
          required
          value={email}
          onChange={(e) => emailChange(e)}
        />
        <label>
          Mobile Number *
          <span className="dec-icon">
            <i className="fal fa-user" />
          </span>
        </label>
        <input
          name="name2"
          type="text"
          placeholder="Enter Your Mobile Number"
          required
          minLength={10}
          maxLength={10}
          value={mob}
          readOnly
        />

        <div className="filter-tags ft-list">
          <input id="check-a2" type="checkbox" name="check" />
          <label htmlFor="check-a2">
            I agree to the <a to="#">Privacy Policy</a> and
            <a to="#">Terms and Conditions</a>
          </label>
        </div>
        <div className="clearfix" />
        <button className="log_btn color-bg">Register</button>
      </form>
    </div>
  );
};

export default OtpRegister;
