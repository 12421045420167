import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FcPhoneAndroid, FcInfo, FcIdea } from "react-icons/fc";
import { GrMail } from "react-icons/gr";
import { MdDoNotDisturbAlt } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import moment from "moment/moment";
import {
  propertyUpdate,
  setAmentiesDetails,
  setBasicDetails,
  setLocationDetails,
  setPhotoDetails,
  setPriceotherDetails,
  setPropertyProfile,
} from "../../../redux/property/PropertySlice";
import { authActions } from "../../../redux/athentication/Athentication";
import { toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import BoostModel from "./BoostModel";

const Active = () => {
  const { loginData } = useSelector((store) => store.Athentication);
  const { searchProperties } = useSelector((store) => store.Search);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [editSingleProperty, setEditSingleProperty] = useState("");
  const toggle = () => setModal(!modal);

  const [activeList, setActiveList] = useState([]);
  const [searchProperty, setSearchProperty] = useState([]);
  const [propertyloading, setPropertyloading] = useState(true);


  useEffect(() => {
    const userId = loginData._id;
    try {
      const fetchdata = async () => {
        const url = `${Baseurl}/api/v1/property/active/${userId}`;
        const fetchProperty = await axios(url);
        if (fetchProperty.data.success === true) {
          let activeProperty = fetchProperty.data.property;
          setActiveList(activeProperty);
          setPropertyloading(false);
        }
      };
      fetchdata();
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (propertyloading === false) {
      let search = searchProperties;
      let searchList = [];
      if (search.length > 1) {
        let singleProperty = activeList.filter((property) =>
          property.locality.toLowerCase().match(search.toLowerCase())
        );
        searchList = [...searchList, ...singleProperty];
        let singleProperty1 = activeList.filter((property) =>
          property.propertyID.toLowerCase().match(search.toLowerCase())
        );
        searchList = [...searchList, ...singleProperty1];

        setSearchProperty(searchList);
      } else {
        setSearchProperty(activeList);
      }
    }
  }, [propertyloading, searchProperties, activeList]);

  const editPropertyClick = (e, property) => {
    const basicDetailForm = {
      supercatName: property.superCategory,
      supercatId: property.superCategoryId,
      catName: property.category,
      catId: property.categoryId,
      subcatName: property.subCategory,
      subcatId: property.subCategoryId,
    };
    dispatch(setBasicDetails(basicDetailForm));

    const locartionForm = {
      city: property.city.charAt(0).toUpperCase() + property.city.slice(1),
      apartment:
        property.apartment.charAt(0).toUpperCase() +
        property.apartment.slice(1),
      locality:
        property.locality.charAt(0).toUpperCase() + property.locality.slice(1),
      housenumber: property.housenumber,
    };
    dispatch(setLocationDetails(locartionForm));

    const propertyDetailsForm = {
      noofBedrooms: property.noofBedrooms,

      noofBathrooms: property.noofBathrooms,

      balconies: property.balconies,
      carpetArea: Number(property.carpetArea),
      typeofCarpetArea: property.typeofCarpetArea,

      builtupArea: Number(property.builtupArea),
      typeofBuiltupArea: property.typeofBuiltupArea,

      superBuiltupArea: Number(property.superBuiltupArea),
      typeofSuperBuiltupArea: property.typeofSuperBuiltupArea,

      poojaroom: property.poojaroom,
      studyroom: property.studyroom,
      servantroom: property.servantroom,
      storeroom: property.storeroom,
    };
    dispatch(setPropertyProfile(propertyDetailsForm));
    const imageForm = {
      DeskImg: property.DeskImg,
    };
    dispatch(setPhotoDetails(imageForm));
    const priceForm = {};
    dispatch(setPriceotherDetails(priceForm));
    const ammenitiesForm = {
      light: property.light,
      fans: property.fans,
      ac: property.ac,
      tv: property.tv,
      beds: property.beds,
      wardrobe: property.wardrobe,
      geyser: property.geyser,
      sofa: property.sofa,
      washingMachine: property.washingMachine,
      stove: property.stove,
      fridge: property.fridge,
      waterPurifier: property.waterPurifier,
      microwave: property.microwave,
      modularKitchen: property.modularKitchen,
      chimney: property.chimney,
      dinningTable: property.dinningTable,
      curtains: property.curtains,
      exhaustFan: property.exhaustFan,
    };
    dispatch(setAmentiesDetails(ammenitiesForm));
    navigate("/postPropertyHome");
  };

  const BoostClick = async (e, propertyId) => {
    const formdata = {
      mobile: loginData.mobile,
      creadittospent: 10,
    };
    const url = `${Baseurl}/api/v1/user/shopUserByMobile`;
    let fetchData = await axios.put(url, formdata);
    try {
      const authenticForm = {
        ...fetchData.data.user,
        isAuth: true,
      };
      dispatch(authActions.signIn(authenticForm));
    } catch (error) {}
    if (fetchData.data.success) {
      var date = new Date();
      date.setDate(date.getDate() + 5);
      var pdate = new Date();
      pdate.setDate(pdate.getDate() + 180);

      const propertyformdata = {
        propertyId: propertyId,
        boosted: true,
        boostedExpire: date,
        ExpiryDate: pdate,
      };
      dispatch(propertyUpdate(propertyformdata));
      toast.success("Property Boosted Successfully", {
        position: "top-center",
        autoClose: 1500,
      });
    } else {
      //not sufficient balance
      toast.error("Please Recharge on Wallet", {
        position: "top-center",
        autoClose: 1000,
      });
      // navigate("/subcription");
    }
  };

  return (
    <>
      <div className="component__allListingInfo">
        <div className="component__listingCount">
          {searchProperty.length > 1 ? (
            <>
              <span>{searchProperty.length}</span> Properties
            </>
          ) : (
            <>
              <span>{searchProperty.length}</span> Property
            </>
          )}
        </div>
      </div>

      <div className="false component__premiumFilters">
        <span className="component__mg_ryt_20">Showing In:</span>
        <div className="tags-and-chips__textOnly undefined  tags-and-chips__active pageComponent tags-and-chips__mr2 pageComponent">
          <span className="tags-and-chips__badgeParent "></span>
          <span>Active</span>
          <i className="iconS_Common_20 icon_closeBlue ">
            <AiOutlineClose />
          </i>
        </div>
        <label className="component__clearAll">Clear All Filters</label>
      </div>
      {searchProperty.length > 0 ? (
        <>
          {searchProperty.map((property, index) => (
            <div className="component__card" key={index}>
              <div className="component__card_container1">
                <div className="card_in01">
                  {property.apartment === "" ? (
                    <>
                      <label>
                        {property.subCategory +
                          " for sale in " +
                          property.locality +
                          " , " +
                          property.city +
                          " , " +
                          property.state}
                      </label>
                    </>
                  ) : (
                    <>
                      <label>
                        {property.noofBedrooms +
                          " BHK " +
                          property.subCategory +
                          " for sale in " +
                          property.locality +
                          " , " +
                          property.city +
                          " , " +
                          property.state}
                      </label>
                    </>
                  )}
                </div>
                {/* <div className="card_in02">
                  <span>Plain</span>
                </div> */}
                <div className="card_in03">
                  <i>
                    <MdDoNotDisturbAlt />
                  </i>
                </div>
              </div>
              <div className="component__card_container2">
                <p>
                  price: <span>Rs {property.visiblePrice},</span>| Super
                  built-up Area :<span> {property.carpetArea} sq.ft.</span>
                </p>
              </div>
              <div className="component__card_container3">
                <p>
                  <span className="span_number">{property.propertyID} : </span>
                  <span className="span_expired coloringRoot">
                    {property.propertyStatus}
                  </span>
                  | Posted On :
                  <span className="span_date">
                    {" "}
                    {moment(property.createdAt).format("DD MMMM YYYY")}
                  </span>
                </p>
              </div>
              <div className="component__card_container4">
                <div className="card_container4-1">
                  <p>
                    Expiry On:{" "}
                    <span>
                      {moment(property.ExpiryDate).format("DD MMMM YYYY")}
                    </span>{" "}
                    {/* <a>Recall</a> ExpiryDate */}
                  </p>
                </div>
                <div className="card_container4-2">
                  <p> Property Viewed: {property.noofPropertyViewed}</p>
                </div>
              </div>
              <div className="component__card_container5">
                <h4>
                  <span className="card_icon_idea">
                    <FcIdea />
                  </span>
                  Need more buyers/tenants for your property, simply
                  <span className="span_getleads"> Get Leads</span>
                </h4>
                <div className="button_container_5">
                  <button
                    className="buttons__primaryRegular_btn btn_edit"
                    onClick={(e) => editPropertyClick(e, property)}
                  >
                    <Link style={{ color: "#fff" }}>Edit</Link>
                  </button>

                  <button className="buttons__primaryRegular_btn btn_boost">
                    <Link
                      style={{ color: "#fff" }}
                      onClick={(e) => BoostClick(e, property._id)}
                      // onClick={(e) => setModal(!modal)}
                    >
                      Boost
                    </Link>
                  </button>
                </div>
                <Modal
                  isOpen={modal}
                  toggle={toggle}
                  className="boostmodalmain"
                  style={{}}
                >
                  <div className="boostmodel-wrapper">
                    <ModalHeader toggle={toggle}></ModalHeader>
                    <ModalBody>
                      <BoostModel />
                    </ModalBody>
                  </div>
                </Modal>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <div className="component__responceWrapper">
            <div className="pageComponent"></div>
            <div className="fslBooking__noResult">
              <h1>Sorry, No Results !</h1>
              <p>We cannot find the item you are searching for.</p>
              <div className="fslBooking__noResults"></div>
            </div>
          </div>
        </>
      )}

      <div className="component__card_bottomresult">
        <p>Displaying 1 - 1 of 1 results</p>
      </div>
    </>
  );
};

export default Active;
