import React from "react";
import { RxCross2 } from "react-icons/rx";

function PropertyFeatures({
  toggleClick,
  propertyPostClick,
  setSkipPageChange,
}) {
  return (
    <>
      <div className="property-features">
        <div
          className="cancel-btn-grp"
          onClick={() => {
            toggleClick();
            setSkipPageChange(0);
          }}
        >
          <button className="cancel-btn" style={{ cursor: "pointer" }}>
            <RxCross2 />
          </button>
        </div>
        <div className="property-features-box">
          <div>
            <div className="describe-features-box">
              <img
                src="https://www.99acres.com/universalapp/img/AboutMyProperty2.png"
                height="60px"
                width="60px"
                alt=""
              />
              <div className="describe-features">
                Tell us the TOP 4 unique property features*
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <img src="" alt="" />
              </div>
              <div className="col-lg-6 property-changes">
                <p>
                  We have shown the best 4 here. you can either change or
                  confirm to post the property{" "}
                </p>
                <div className="feature-box">
                  <div>Fetures1</div>
                  <select>
                    <option value="">East Facing</option>
                    <option value="">East Facing</option>
                    <option value="">East Facing</option>
                  </select>
                </div>
                <div className="feature-box">
                  <div>Fetures2</div>
                  <select>
                    <option value="">Private Garden</option>
                    <option value="">East Facing</option>
                    <option value="">East Facing</option>
                  </select>
                </div>
                <div className="feature-box">
                  <div>Fetures3</div>
                  <select>
                    <option value="">hitech city within 2km</option>
                    <option value="">Eeast Facing</option>
                    <option value="">Eeast Facing</option>
                  </select>
                </div>
                <div className="feature-box">
                  <div>Feture4</div>
                  <select>
                    <option value="">international school within 1.5km</option>
                    <option value="">Eeast Facing</option>
                    <option value="">Eeast Facing</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="property-features-footer">
              <p>
                Unique features :These are the features that you entered on the{" "}
                <br /> previous page.You can always{" "}
                <span
                  style={{ color: "#0077df", cursor: "pointer" }}
                  onClick={() => {
                    toggleClick();
                    setSkipPageChange(0);
                  }}
                >
                  {" "}
                  Go Back{" "}
                </span>{" "}
                and edit if you want to
              </p>
              <div>
                <button
                  style={{
                    border: "1px solid #0077df",
                    color: "#0077df",
                    backgroundColor: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={(e) => propertyPostClick(e,0,2)}
                >
                  Skip to post
                </button>
                <button
                  style={{
                    backgroundColor: "#0077df",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => setSkipPageChange(1)}
                >
                  Confirm & post property
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyFeatures;
