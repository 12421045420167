import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FcHome } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode } from "swiper/modules";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { useSelector } from "react-redux";

const BHKchoiceInMind = () => {
  const navigate = useNavigate()
  const [allbhks, setAllbhks] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/property/allbhk`;
        const fetchBhk = await axios.get(url);
        try {
          if (fetchBhk.data.success === true) {
            const Bhks = fetchBhk.data.bhk;
            setAllbhks(Bhks);
          }
        } catch (error) {}
      };
      fetchData();
    } catch (error) {}
  }, []);

  const OnebhkClick = () => {
    navigate(`/property-search/xyz/xyz/xyz/hyderabad`);
  };

  return (
    <>
      <div className="bhk-choice">
        <div className="bhk-head">
          <h1>
            <FcHome /> BHK choice in mind?
          </h1>
          <p>Browse by no. of bedrooms in the house</p>
        </div>
        <div className="bhk-swiper">
          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            freeMode={true}
            pagination={{
              clickable: false,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },

              480: {
                slidesPerView: 1,
                spaceBetween: 10,
              },

              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },

              840: {
                slidesPerView: 2,
                spaceBetween: 10,
              },

              1000: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            modules={[FreeMode]}
            className="mySwiper"
          >
            {allbhks &&
              allbhks.map((bhk, index) => (
                <SwiperSlide key={index}>
                  <div classname="slick-slide-item">
                    <div className="bhk-swiper-card">
                      <div className="bhk-swiper-card-icon">
                        <FcHome />
                      </div>
                      <div
                        className="bhk-swiper-card-text"
                        onClick={() => OnebhkClick(bhk)}
                      >
                        <h2>{bhk._id} BHK</h2>
                        <p>{bhk.count}+ Properties</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default BHKchoiceInMind;
