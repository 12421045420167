import React from 'react'

const SubscriptionStatus = () => {
    return (
        <>
            <div className='pageComponent dashboard__mainContent'>
                <div id='subscriptionStatus__rightSection'>
                    <div className='subscriptionStatus__f11 subscriptionStatus__lm10 subscriptionStatus__mt5'>
                        <div className='undefined subscriptionStatus__mt2'>
                            <h2 className=''>Total Subscriptions <span className='subscriptionStatus__details'>(2)</span> </h2>
                            <table className='subscriptionStatus__subscriptionStatusTable'>
                                <thead className=''>
                                    <th style={{ width: "20%" }}>Transactions Details</th>
                                    <th style={{ width: "60%" }}>Product Description</th>
                                    <th style={{ width: "10%" }}>Expiry Date</th>
                                    <th style={{ width: "10%" }}>Status</th>
                                </thead>
                                <tbody className='subscriptionStatus__tbody'>
                                    <tr>
                                        <td colSpan={4}>
                                            <table style={{ width: "100%" }}>
                                                <tr>
                                                    <td style={{ width: "20%" }}>
                                                        <b>ID: 2324-T2411598</b>
                                                        <br />
                                                        <span className='subscriptionStatus__f11'>Date: 2023.07.24</span>
                                                    </td>
                                                    <td style={{ width: "80%" }} >
                                                        <table style={{ width: "100%", height: "100%" }} cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='' style={{ width: "74%" }}>
                                                                    <b>1.</b>
                                                                    Plain - 1 Listing
                                                                    1 Free listing
                                                                    <br />
                                                                    <br />
                                                                </td>
                                                                <td style={{ width: "13%" }}>
                                                                    2038-01-01
                                                                    <br />
                                                                    <br />
                                                                </td>
                                                                <td style={{ width: "13%" }}>
                                                                    <span className='subscriptionStatus__actcolor'><b>Active</b></span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody className='subscriptionStatus__tbody'>
                                    <tr>
                                        <td colSpan={6}>
                                            <table style={{ width: "100%" }}>
                                                <tr>
                                                    <td style={{ width: "20%" }}>
                                                        <b>ID: 2324-T2411598</b>
                                                        <br />
                                                        <span className='subscriptionStatus__f11'>Date: 2023.07.24</span>
                                                    </td>
                                                    <td style={{ width: "80%" }} >
                                                        <table style={{ width: "100%", height: "100%" }} cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='' style={{ width: "74%" }}>
                                                                    <b>1.</b>
                                                                    Plain - 1 Listing
                                                                    1 Free listing
                                                                    <br />
                                                                    <br />
                                                                </td>
                                                                <td style={{ width: "13%" }}>
                                                                    2038-01-01
                                                                    <br />
                                                                    <br />
                                                                </td>
                                                                <td style={{ width: "13%" }}>
                                                                    <span className='subscriptionStatus__actcolor'><b>Active</b></span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div style={{ margin: "2%" }}>
                    <div className='subscriptionStatus__f11 subscriptionStatus__hm10 subscriptionStatus__notes'>
                        <br />
                        <b>Note :</b>
                        You can make payments only in the currency in which transaction has been registered.
                    </div>
                    <div className='subscriptionStatus__f11 subscriptionStatus__hm10'>
                        <span className='red'>* Inactive </span>
                        A Product that you have subscribed to may be inactive due to one of the following reasons. 
                        <br />
                        <ul className='subscriptionStatus__list'>
                            <li> Payment has not been recieved yet </li>
                            <li>You have requested to deactivate a particular product </li>
                            <li>You already have a live and active subscription for the same product.
                                In this case the subscription will be automatically activated when the previous subscription ends.</li>
                            <li>The product you have subscribed to has been deactivated by 99acres.com </li>
                            <li>The payment recieved by 99acres.com was cancelled/cheque bounced. </li>
                        </ul>
                        <p><br />To speak to our <b> Service Center</b> please contact us between 9:00am to 11:00pm IST </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriptionStatus