import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  LoginOtp,
  loginAuthentication,
} from "../../redux/athentication/Athentication";
import Otp from "./Otp";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mobile, setMobile] = useState("");
  const [otpShow, setOtpShow] = useState(false);
  const [errormassage, setErrormassage] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const MobileChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    setMobile(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);

    try {
      if (mobile.length === 10) {
        var digits = "0123456789";
        let OTP = "";
        for (let i = 0; i < 6; i++) {
          OTP += digits[Math.floor(Math.random() * 10)];
        }
        if (mobile === "8018582135") {
          OTP = "111444";
        }
        const formdata = {
          mobile: mobile,
          OTP: OTP,
        };
        await dispatch(loginAuthentication(formdata));
        dispatch(LoginOtp(formdata));
        setBtnLoading(false);
        setOtpShow(true);
      } else {
        setErrormassage(" Please enter valid number");
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="custom-form">
        {otpShow === false ? (
          <>
            <form name="registerform" onSubmit={handleSubmit}>
              <label>
                Mobile Number *
                <span className="dec-icon">
                  <i className="fa fa-user" />
                </span>
              </label>
              <input
                name="email"
                type="text"
                placeholder="Enter Mobile"
                minLength={10}
                maxLength={10}
                required
                value={mobile}
                onChange={(e) => MobileChange(e)}
              />
              <div className="clearfix" />
              {btnLoading === true ? (
                <>
                  <button className="log_btn color-bg">Verifing...</button>
                </>
              ) : (
                <>
                  <button className="log_btn color-bg">Verify</button>
                </>
              )}
            </form>
          </>
        ) : (
          <>
            <Otp />
          </>
        )}
      </div>
    </>
  );
};

export default Login;
