import React from 'react'
import { useSelector } from 'react-redux';
import PgFlatPrpertypTemp from './editMainProperty/PgFlatPrpertypTemp';
import PlotPropertyProfileTemp from './editMainProperty/PlotPropertyProfileTemp';
import PropertyProfileTemp from './editMainProperty/PropertyProfileTemp';
import HospitalityPropertyDetailsSellTemp from './editMainProperty/HospitalityPropertyDetailsSellTemp';
import OfficePropertyDetailsTemp from './editMainProperty/OfficePropertyDetailsTemp';
import RetailPropertyDetailsTemp from './editMainProperty/RetailPropertyDetailsTemp';
import PlotLandPropertyDetailsTemp from './editMainProperty/PlotLandPropertyDetailsTemp';
import IndustryPropertyProfileTemp from './editMainProperty/IndustryPropertyProfileTemp';
import HospitalityPropertyDetailsTemp from './editMainProperty/HospitalityPropertyDetailsTemp';

const EditMainPropertTemp = () => {
    const { BasicDetails } = useSelector((state) => state.Property);
  return (
    <>
      {BasicDetails.supercatName === "Residential" ? (
        <>
          {BasicDetails.catName === "Pg" ? (
            <>
              <PgFlatPrpertypTemp />
            </>
          ) : (
            <>
              {BasicDetails.subcatName === "Plot" ||
              BasicDetails.subcatName === "Land" ? (
                <>
                  <PlotPropertyProfileTemp />
                </>
              ) : (
                <>
                  <PropertyProfileTemp />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {BasicDetails.catName === "Sell" &&
          BasicDetails.subcatName === "Hospitality" ? (
            <>
            {/* done */}
              <HospitalityPropertyDetailsSellTemp />
            </>
          ) : (
            <>
              {BasicDetails.subcatName === "Office" ? (
                <>
                      {/* done */}
                  <OfficePropertyDetailsTemp />
                </>
              ) : (
                <>
                  {BasicDetails.subcatName === "Retail" ? (
                    <>
                      {/* done */}
                      <RetailPropertyDetailsTemp />
                    </>
                  ) : (
                    <>
                    
                      {BasicDetails.subcatName === "Plot" ||
                      BasicDetails.subcatName === "Land" ? (
                        <>
                         {/* done */}
                          <PlotLandPropertyDetailsTemp /> 
                        </>
                      ) : (
                        <>
                      
                          {BasicDetails.subcatName === "Storage" ||
                          BasicDetails.subcatName === "Industry" ? (
                            <>
                            {/* done */}
                              <IndustryPropertyProfileTemp />
                            </>
                          ) : (
                            <>
                              {BasicDetails.subcatName === "Hospitality" ? (
                                <>
                                  <HospitalityPropertyDetailsTemp />
                                </>
                              ) : (
                                <>
                                  <IndustryPropertyProfileTemp />
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default EditMainPropertTemp