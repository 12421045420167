import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const AvaliableFrom = (props) => {
  const { PropertyProfile, BasicDetails } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [availableDate, setavailableDate] = useState("");

  return (
    <>
      <div id="Location-widget">
        <div className="widgetbox_white_box__1v7xz undefined undefined undefined">
          <div className="xidHeading_PageHeadingwrap__3z0kL">
            <h4>
              <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                <div>
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Available From
                  </span>
                </div>
              </div>
            </h4>
          </div>
          <p style={{ color: "red", fontSize: "12px" }}>
              {props.availabilityDateError}
            </p>
          <div className="location_fieldsWrapper__3sary">
            <div className="city_citySuggestor__21uXN">
              <div className="suggestor_suggestor__pe1Hh">
                <div className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined">
                  <div className="">
                    <input
                      type="date"
                      placeholder=" Total no. of beds in PG"
                      id="project"
                      value={availableDate}
                      onChange={(e) => {
                        setavailableDate(e.target.value);
                        props.availableFromDate(e.target.value);
                      }}
                    />
                    <div></div>
                    <div className="textInput_placeholder__1KRsF caption_strong_large textInput_placeholderWithoutError__3FjSB">
                      <span className="undefined"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="priceRangeText-input">
            <div className="caption_strong_medium"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvaliableFrom;
