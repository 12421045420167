import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPostPropertyStepsStatus,
  setPropertyProfile,
} from "../../../redux/property/PropertySlice";

const FloorDetails = (props) => {
  const { PropertyProfile, BasicDetails } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();

  const [totalFloors, setTotalFloors] = useState(0);
  const [propertyonFloors, setPropertyonFloors] = useState("");
  const [propertyonFloorsShow, setPropertyonFloorsShow] = useState(false);
  const [typeofPropertyonFloors, setTypeofPropertyonFloors] = useState("");

  const [floorDetails, setFloorDetails] = useState([]);

  //errors
  const [floorDetailsError, setFloorDetailsError] = useState("");

  useEffect(() => {
    if (PropertyProfile !== "") {
      setTotalFloors(PropertyProfile.totalFloors);
      setPropertyonFloors(PropertyProfile.propertyonFloors);
      setTypeofPropertyonFloors(PropertyProfile.typeofPropertyonFloors);
    }
  }, [PropertyProfile]);

  useEffect(() => {
    if (totalFloors !== "" && propertyonFloors !== "") {
      setFloorDetailsError("");
    }
  }, [totalFloors, propertyonFloors]);

  const totalfloorClick = (e) => {
    if (e.target.value <= 90) {
      setTotalFloors(e.target.value.replace(/^[0]+$/, 0));
      props.getNumberOfFloors(e.target.value.replace(/^[0]+$/, 0));
      if (e.target.value > 0) {
        let floorDetails = [
          { id: 1, name: "Basement" },
          { id: 2, name: "Lower Ground" },
          { id: 3, name: "Ground" },
        ];
        let formdata = {};
        for (let index = 1; index <= e.target.value; index++) {
          formdata = {
            id: 3 + index,
            name: index,
          };
          floorDetails = [...floorDetails, formdata];
        }
        setFloorDetails(floorDetails);
      } else {
        let floorDetails = [
          { id: 1, name: "Basement" },
          { id: 2, name: "Lower Ground" },
          { id: 3, name: "Ground" },
        ];
        setFloorDetails(floorDetails);
      }
    }
  };
  useEffect(() => {
    if (totalFloors === 0 && totalFloors !== "") {
      let floorDetails = [
        { id: 1, name: "Basement" },
        { id: 2, name: "Lower Ground" },
        { id: 3, name: "Ground" },
      ];
      setFloorDetails(floorDetails);
    }
  }, [totalFloors]);

  return (
    <>
      <div className="FloorDetails-widget">
        <div className="widgetbox_white_box__1v7xz undefined false false FloorDetails-widget_inner">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span
                      className="xidHeading_headingText__Ulc2a"
                      style={{ display: "inline" }}
                    >
                      Floor Details
                    </span>
                  </div>
                </div>
                {props.floorDetailsError !== "" ? (
                  <>
                    <div className="caption_strong_small">
                      <em
                        className="xidHeading_subLabel__1Fc9h"
                        style={{ color: "red" }}
                      >
                        {props.floorDetailsError}
                      </em>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </h4>
            </div>
          </div>
          <div className="formWidget_inlineContainer__3oR34 false">
            <div id="totalFloor-input">
              <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                <div className="false">
                  <input
                    type="text"
                    inputMode="decimal"
                    placeholder="Total Floors"
                    value={totalFloors}
                    max={90}
                    min={0}
                    onChange={
                      (e) => totalfloorClick(e)
                      //   {
                      //   if (e.target.value <= 90) {
                      //     setTotalFloors(
                      //       e.target.value.replace(/^[0]+$/, 0)
                      //     );
                      //   }
                      // }
                    }
                  />
                </div>
              </div>
            </div>

            <div id="floorNum-input">
              {BasicDetails.subcatName === "Independent House" ||
              BasicDetails.subcatName === "Farmhouse" ||
              BasicDetails.subcatName === "Villa" ? (
                <></>
              ) : (
                <>
                  <div>
                    <div
                      className="selectInput_custom_input_wrapper__MqLE9 selectInput_multiple_input__1oijd  pageComponent"
                      onClick={() => {
                        setPropertyonFloorsShow(!propertyonFloorsShow);
                        props.getPropertyOnFloors(!propertyonFloorsShow);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {propertyonFloors !== "" ? (
                        <>
                          {" "}
                          <div className="selectInput_label__Sr6HY caption_subdued_large4">
                            {propertyonFloors}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="selectInput_label__Sr6HY caption_subdued_large4">
                            Property on Floor
                          </div>
                        </>
                      )}

                      <div className="selectInput_custom_input__DdGZD input_placeholder_completed false"></div>
                      <div className="selectInput_iconsWrapper__1L2kR">
                        <span className="icon_downArrow">
                          <img src="/assets/images/all/arrow_down.png" alt="" />
                        </span>
                        <div className="screeningActions_iconWrapper__dB1NM"></div>
                      </div>
                    </div>
                    {propertyonFloorsShow === true ? (
                      <>
                        <div className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown propertyonfloor_dpdwn">
                          {floorDetails.map((floor, index) => (
                            <div
                              key={index}
                              onClick={(e) => {
                                setPropertyonFloorsShow(false);
                                setPropertyonFloors(floor.name);
                                props.getPropertyOnFloors(floor.name);
                              }}
                            >
                              <span>{floor.name}</span>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FloorDetails;
