import { configureStore } from "@reduxjs/toolkit";
import AthenticationReducer from "../redux/athentication/Athentication";
import superCategoryReducer from "../redux/superCategory/SuperCategorySlice";
import CategoryReducer from "../redux/category/CategorySlice";
import PropertyReducer from "../redux/property/PropertySlice";
import SubCategoryReducer from "../redux/subCategory/SubCategorySlice";
import CreditHistoryReducer from "../redux/creditHistory/CreditHistorySlice";
import UserViewPropertyReducer from "../redux/userViewdProperty/UserViewdPropertySlice";
import SearchReducer from "../redux/search/SearchSlice";

export const store = configureStore({
  reducer: {
    Athentication: AthenticationReducer,
    superCategory: superCategoryReducer,
    Category: CategoryReducer,
    SubCategory: SubCategoryReducer,
    Property: PropertyReducer,
    CreditHistory: CreditHistoryReducer,
    UserViewProperty: UserViewPropertyReducer,
    Search: SearchReducer,
  },
});
