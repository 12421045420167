import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { FcPhoneAndroid, FcInfo, FcIdea } from "react-icons/fc";
import { GrMail } from "react-icons/gr";
import { MdDoNotDisturbAlt } from "react-icons/md";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ResponseModel from "./responseModel/ResponseModel";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { authActions } from "../../../redux/athentication/Athentication";
import { toast } from "react-toastify";

const AllListing = () => {
  const { userViewPropertyAll } = useSelector(
    (store) => store.UserViewProperty
  );
  const { loginData } = useSelector((store) => store.Athentication);

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [singleProperty, setSingleProperty] = useState("");
  const toggle = () => setModal(!modal);
  const [response, setResponse] = useState([]);

  const closePropDetailModal = () => {
    setModal(false);
  };

  const viewResponseClick = async (e, property) => {
    const propertyVerifyData = {
      propertyId: property.propertyId,
      postUserId: loginData._id,
    };
    const config = {
      Headers: { "Content-Type": "application/json" },
    };
    const verifyurl = `${Baseurl}/api/v1/viewed/viewedpropertyverifynew`;
    const propertyVerify = await axios.post(
      verifyurl,
      propertyVerifyData,
      config
    );
    try {
      if (propertyVerify.data.success === true) {
        try {
          if (propertyVerify.data.userViewedProperty.length > 0) {
            // setModal(!modal);
            // setSingleProperty(property);
          } else {
            const formdata = {
              mobile: loginData.mobile,
              creadittospent: 1,
            };
            const url = `${Baseurl}/api/v1/user/shopUserByMobile`;
            let fetchData = await axios.put(url, formdata);
            try {
              const authenticForm = {
                ...fetchData.data.user,
                isAuth: true,
              };
              dispatch(authActions.signIn(authenticForm));
            } catch (error) {}
            if (fetchData.data.success) {
              // setModal(!modal);
              // setSingleProperty(property);
            } else {
              toast.error("Please Recharge on Wallet", {
                position: "top-center",
                autoClose: 1000,
              });
            }
          }
        } catch (error) {}
      } else {
        const formdata = {
          mobile: loginData.mobile,
          creadittospent: 1,
        };
        const url = `${Baseurl}/api/v1/user/shopUserByMobile`;
        let fetchData = await axios.put(url, formdata);
        try {
          const authenticForm = {
            ...fetchData.data.user,
            isAuth: true,
          };
          dispatch(authActions.signIn(authenticForm));
        } catch (error) {}
        if (fetchData.data.success) {
          setModal(!modal);
          setSingleProperty(property);
        } else {
          toast.error("Please Recharge on Wallet", {
            position: "top-center",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="component__allListingInfo">
        <div className="component__listingCount">
          {userViewPropertyAll.length > 0 && userViewPropertyAll.length <= 1 ? (
            <>
              <span>{userViewPropertyAll.length}</span> Property
            </>
          ) : (
            <>
              <span>{userViewPropertyAll.length}</span> Properties
            </>
          )}
        </div>
      </div>
      <div className="false component__premiumFilters">
        <span className="component__mg_ryt_20">Showing In:</span>
        <div className="tags-and-chips__textOnly undefined  tags-and-chips__active pageComponent tags-and-chips__mr2 pageComponent">
          <span className="tags-and-chips__badgeParent "></span>
          <span>ALL</span>
          <i className="iconS_Common_20 icon_closeBlue ">
            <AiOutlineClose />
          </i>
        </div>
        <label className="component__clearAll">Clear All Filters</label>
      </div>
      {userViewPropertyAll.length > 0 ? (
        <>
          {userViewPropertyAll.map((view, index) => (
            <div className="component__card" key={index}>
              <div className="component__card_container1">
                <div className="card_in01">
                  <label>{view.propertyName}</label>
                </div>

                {/* <div className="card_in03">
                  <i>
                    <MdDoNotDisturbAlt />
                  </i>
                </div> */}
              </div>
              <div className="component__card_container2">
                <p>
                  price: <span>Rs {view.price},</span>
                </p>
              </div>
              <div className="component__card_container3">
                <p>
                  <span className="span_number">
                    {view.propertyId.slice(0, 8)} :{" "}
                  </span>
                  <span
                    className={
                      view.propertyStatus === "Active"
                        ? "span_expired coloringRoot"
                        : "span_expired"
                    }
                  >
                    {view.propertyStatus}
                  </span>
                  | Posted On :
                  <span className="span_date">
                    {moment(view.createdAt).format("DD MMMM YYYY")}
                  </span>
                </p>
              </div>
              <div className="component__card_container4">
                <div className="card_container4-1">
                  <p>
                    Expiry On:
                    <span>
                      {""}
                      {moment(view.ExpiryDate).format("DD MMMM YYYY")}
                    </span>
                  </p>
                </div>
                <div className="card_container4-2">
                  <p> Property Viewed: {view.noofviews}</p>
                </div>
              </div>
              <div className="component__card_container5 d-flex">
                <h4>
                  <span className="card_icon_idea">
                    <FcIdea />
                  </span>
                  Need more buyers/tenants for your property, simply
                  <span className="span_getleads"> Get Leads</span>
                </h4>
                <div className="button_container_5">
                  <button
                    className="buttons__primaryRegular_btn btn_edit"
                    style={{ width: "auto" }}
                  >
                    <Link
                      to=""
                      style={{ color: "#fff" }}
                      onClick={(e) => {
                        viewResponseClick(e, view);
                      }}
                    >
                      View Response
                    </Link>
                  </button>
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    className="modalmainnew"
                    style={{}}
                  >
                    <div
                      className="model-wrappernew"
                      style={{ height: "auto", width: "auto" }}
                    >
                      <ModalHeader toggle={toggle}></ModalHeader>
                      <ModalBody>
                        <ResponseModel
                          closePropdet={closePropDetailModal}
                          propertyprop={singleProperty}
                        />
                      </ModalBody>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <div className="component__responceWrapper">
            <div className="pageComponent"></div>
            <div className="fslBooking__noResult">
              <h1>Sorry, No Results !</h1>
              <p>We cannot find the item you are searching for.</p>
              <div className="fslBooking__noResults"></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AllListing;
