import React from "react";
import Header from "../components/layouts/Header";
import { useSelector } from "react-redux";
import { Link,useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Search = () => {
  const { propertyAll } = useSelector((store) => store.Property);

  const params = useParams();
  

  const slugUrl = params.keyword;


  return (
    <>
      <Header />

      <div id="wrapper">
        <div className="">
          <div className="top-search-content">
            <div className="top-search-dec color-bg">
              <i className="far fa-sliders-h" />
            </div>
            <div className="top-search-content-title">Search:</div>
            <div className="close_sb-filter">
              <i className="fal fa-times" />
            </div>
            <div className="custom-form fl-wrap">
              <div className="row">
                <div className="col-sm-3">
                  <div className="listsearch-input-item">
                    <input
                      type="text"
                      placeholder="Property type..."
                      defaultValu=""
                    />
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="listsearch-input-item">
                    <select
                      data-placeholder="Construction Status"
                      className="chosen-select nice-select on-radius no-search-select"
                    >
                      <option>Construction Status</option>
                      <option>Ready to move</option>
                      <option>Under Construction</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="listsearch-input-item">
                    <select
                      data-placeholder="Posted by"
                      className="chosen-select nice-select on-radius no-search-select"
                    >
                      <option>Posted by</option>
                      <option>Owner</option>
                      <option>Builder</option>
                      <option>Dealer</option>
                      <option>Feature Dealer</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="listsearch-input-item">
                    <div className="price-rage-item fl-wrap">
                      <span className="pr_title">Price:</span>
                      <input
                        type="text"
                        className="price-range-double"
                        data-min={100}
                        data-max={100000}
                        name="price-range2"
                        step={100}
                        defaultValue={1}
                        data-prefix="₹"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="more-opt_btn mor-opt-btn_act">
              Advanced search
              <span>
                <i className="fas fa-caret-down" />
              </span>
            </div>

            <div className="more-search-opt-wrap more-hidden_wrap">
              <div className="msotw_title fl-wrap">
                <i className="far fa-sliders-h" />
                Advanced Search
              </div>
              <div className="close_msotw">
                <i className="fal fa-times" />
              </div>
              {/* <div className="clearfix" /> */}
              <div className="row">
                <div className="col-sm-2">
                  <div className="listsearch-input-item">
                    <label>Bedrooms</label>
                    <select
                      data-placeholder="Bedrooms"
                      className="chosen-select nice-select on-radius no-search-select"
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="listsearch-input-item">
                    <label>Localities</label>
                    <div className="listsearch-input-item">
                      <input
                        type="text"
                        placeholder="Localities..."
                        defaultValu=""
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="listsearch-input-item">
                    <label>Purchase type</label>
                    <select
                      data-placeholder="Bathrooms"
                      className="chosen-select nice-select on-radius no-search-select"
                    >
                      <option>Resale</option>
                      <option>New Booking</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="listsearch-input-item">
                    <label>Area Sq/ft</label>
                    <div className="price-rage-item pr-it-nopad fl-wrap">
                      <input
                        type="text"
                        className="price-range-double"
                        data-min={1}
                        data-max={1000}
                        name="price-range2"
                        data-step={1}
                        defaultValue={0}
                        // data-prefix="₹"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <div className="listsearch-input-item">
                    <label>Furnishing status</label>
                    <select
                      data-placeholder="Furnishing status"
                      className="chosen-select nice-select on-radius no-search-select"
                    >
                      <option>Unfurnished</option>
                      <option>Semifurnished</option>
                      <option>Furnished</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="listsearch-input-item">
                    <label>RERA Approved</label>
                    <select
                      data-placeholder="Bathrooms"
                      className="chosen-select nice-select on-radius no-search-select"
                    >
                      <option>RERA approved properties</option>
                      <option>RERA registered dealers</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="clearfix" /> */}

              <div className="listsearch-input-item clact">
                <label>Amenities</label>
                <div className=" fl-wrap filter-tags">
                  <ul className="no-list-style">
                    <li>
                      <input id="check-b" type="checkbox" name="check" />
                      <label htmlFor="check-b">Parking</label>
                    </li>
                    <li>
                      <input
                        id="check-c"
                        type="checkbox"
                        name="check"
                        defaultChecked
                      />
                      <label htmlFor="check-c">Power Backup</label>
                    </li>
                    <li>
                      <input id="check-aa" type="checkbox" name="check" />
                      <label htmlFor="check-aa">Lift</label>
                    </li>
                    <li>
                      <input id="check-d" type="checkbox" name="check" />
                      <label htmlFor="check-d">Parking</label>
                    </li>
                    <li>
                      <input
                        id="check-d2"
                        type="checkbox"
                        name="check"
                        defaultChecked
                      />
                      <label htmlFor="check-d2">Gymnasium</label>
                    </li>
                    <li>
                      <input
                        id="check-d3"
                        type="checkbox"
                        name="check"
                        defaultChecked
                      />
                      <label htmlFor="check-d3">Swimming Pool</label>
                    </li>
                    <li>
                      <input id="check-d4" type="checkbox" name="check" />
                      <label htmlFor="check-d4">Club House</label>
                    </li>
                    <li>
                      <input id="check-d5" type="checkbox" name="check" />
                      <label htmlFor="check-d5">Security Personnel</label>
                    </li>
                    <li>
                      <input id="check-d6" type="checkbox" name="check" />
                      <label htmlFor="check-d6">Gas Pipeline</label>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="msotw_footer">
                <a href="#" className="btn small-btn float-btn color-bg">
                  Search
                </a>
                <div className="reset-form reset-btn">
                  <i className="far fa-sync-alt" /> Reset Filters
                </div>
              </div>
            </div>
          </div>

          <div className="col-list-wrap gray-bg">
            <div className="col-list-wrap_opt fl-wrap">
              <div className="show-hidden-filter col-list-wrap_opt_btn color-bg">
                Show Filters
              </div>
          
            </div>

            <div className="list-main-wrap-header fl-wrap fixed-listing-header">
              <div className="container">
                <div className="list-main-wrap-title">
                  <h2>
                    Results For : <span>Hyderabad </span>
                    <strong>8</strong>
                  </h2>
                </div>

                <div className="list-main-wrap-opt">
                  <div className="price-opt">
                    <span className="price-opt-title">Sort by:</span>
                    <div className="listsearch-input-item">
                      <select
                        data-placeholder="Popularity"
                        className="chosen-select nice-select no-search-select"
                      >
                        <option>Popularity</option>
                        <option>Average rating</option>
                        <option>Price: low to high</option>
                        <option>Price: high to low</option>
                      </select>
                    </div>
                  </div>

                  <div className="grid-opt">
                    <ul className="no-list-style">
                      <li className="grid-opt_act">
                        <span
                          className="two-col-grid act-grid-opt tolt"
                          data-microtip-position="bottom"
                          data-tooltip="Grid View"
                        >
                          <i className="far fa-th" />
                        </span>
                      </li>
                      <li className="grid-opt_act">
                        <span
                          className="one-col-grid tolt"
                          data-microtip-position="bottom"
                          data-tooltip="List View"
                        >
                          <i className="far fa-list" />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="listing-item-container fl-wrap">
              {propertyAll.slice(0, 12).map((property, index) => (
                <div className="gallery-item" key={index}>
                  <div className="">
                    <article className="geodir-category-listing fl-wrap">
                      <div className="geodir-category-img fl-wrap">
                        {property.DeskImg.length > 0 ? (
                          <>
                            <Link to={`/property-info/${property.apartment}/${property.propertyID}`} className="geodir-category-img_item">
                              <img src={property.DeskImg[0]} alt="" />
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link to={`/property-info/${property.apartment}/${property.propertyID}`} className="geodir-category-img_item">
                              <img src="assets/images/noimage1.png" alt="" />
                            </Link>
                          </>
                        )}

                        <div className="geodir-category-location">
                          <Link to={`/property-info/${property.apartment}/${property.propertyID}`} className="single-map-item tolt">
                            <i className="fas fa-map-marker-alt" />
                            <span>
                              {property.subCategory + " , " + property.state}
                            </span>
                          </Link>
                        </div>
                        <ul className="list-single-opt_header_cat">
                          <li>
                            <Link to="" className="cat-opt blue-bg">
                              {property.category}
                            </Link>
                          </li>
                          <li>
                            <Link to="" className="cat-opt color-bg">
                              {property.subCategory}
                            </Link>
                          </li>
                        </ul>
                        <Link
                          to=""
                          className="geodir_save-btn tolt"
                          data-microtip-position="left"
                          data-tooltip="Save"
                        >
                          <span>
                            <i className="fal fa-heart" />
                          </span>
                        </Link>
                        <Link
                          to=""
                          className="compare-btn tolt"
                          data-microtip-position="left"
                          data-tooltip="Compare"
                        >
                          <span>
                            <i className="fal fa-random" />
                          </span>
                        </Link>
                        <div className="geodir-category-listing_media-list">
                          <span>
                            <i className="fas fa-camera" />{" "}
                            {property.DeskImg.length}
                          </span>
                        </div>
                      </div>
                      <div className="geodir-category-content fl-wrap">
                        <h3 className="title-sin_item">
                          <Link to={`/property-info/${property.apartment}/${property.propertyID}`}>{property.apartment}</Link>
                        </h3>
                        <div className="geodir-category-content_price">
                          ₹ {property.visiblePrice}
                        </div>
                        <p>{property.unicProperty}</p>
                        <div className="geodir-category-content-details">
                          <ul>
                            <li>
                              <i className="fal fa-bed" />
                              <span>{property.noofBedrooms}</span>
                            </li>
                            <li>
                              <i className="fal fa-bath" />
                              <span>{property.noofBathrooms}</span>
                            </li>
                            <li>
                              <i className="fal fa-cube" />
                              <span>
                                {property.carpetArea +
                                  " " +
                                  property.selectYards}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="geodir-category-footer fl-wrap">
                          <a to="agent-single.html" className="gcf-company">
                            <img src="./assets/images/avatar/2.jpg" alt="" />
                            {/* <span>{loginData.name}</span> */}
                            <span>Jack Maiden</span>
                          </a>
                          <div
                            className="listing-rating card-popup-rainingvis tolt"
                            data-microtip-position="top"
                            data-tooltip="Good"
                            data-starrating2={4}
                          />
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              ))}
            </div>

            <div className="small-footer fl-wrap">
              <div className="copyright">
                © Homeradar 2020 . All rights reserved.
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
