import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GrCircleQuestion } from "react-icons/gr";

const AreYouOkBrokersTemp = (props) => {
  const { PropertyProfile, BasicDetails,singleProperty } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [areyouOk, setAreyouOk] = useState(singleProperty.areyouOk);

  return (
    <>
      <div className="PropertyAvailability-widget">
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span
                      className="xidHeading_headingText__Ulc2a"
                      style={{ display: "inline" }}
                    >
                      Are You ok with brokers connecting you
                      <i
                        className="tooltip_icon"
                        id="DisabledAutoHideExample"
                        style={{ fontSize: "18px" }}
                      >
                        <GrCircleQuestion />
                      </i>
                    </span>
                  </div>
                </div>
              </h4>
            </div>
          </div>
        </div>
        <div className="false">
          <div
            className="propertyAvailability-input"
            style={{ textAlign: "left" }}
          >
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div className="checkBox_tags_wrapper__2gksy  undefined multiple_input">
                <div
                  className={
                    areyouOk === true
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setAreyouOk(true);
                    props.areYouOk(true);
                  }}
                  id="I"
                >
                  <span>Yes</span>
                </div>
                <div
                  className={
                    areyouOk === false
                      ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                      : "pageComponent radioInput_textOnly__1r7GL"
                  }
                  onClick={() => {
                    setAreyouOk(false);
                    props.areYouOk(true);
                  }}
                  id="5"
                >
                  <span>No</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AreYouOkBrokersTemp;
