import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsFillSearchHeartFill, BsMicFill } from "react-icons/bs";
import { BiCurrentLocation } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import { Badge } from "react-bootstrap";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { searchPost } from "../../../redux/search/SearchSlice";
import ReactSlider from "react-slider";

const PlotLandSearchComponent = (props) => {
  const { isAuth, loginData } = useSelector((store) => store.Athentication);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();

  const [showResults, setShowResults] = useState(false);
  const [toggle, setToggle] = useState(false);

  const [selectLand, setSelectLand] = useState([]);
  const [selectedPostedBy, setSelectedPostedBy] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [propertyPrice, setPropertyPrice] = useState([]);

  const [selectLandType, setSelectLandType] = useState(false);

  useEffect(() => {
    props.getBudgetDetails(propertyPrice);
    props.getPostedDetailsDetails(selectedPostedBy);
    props.getAreaDetails(selectedArea);
    props.getLandDetails(selectLand);
  }, [
    propertyPrice,
    selectedPostedBy,
    selectedArea,
    selectLand,
  ]);

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  const commercialPlotStatus = [
    { id: "1", name: "Agricultural / Farm Land" },
    { id: "2", name: "Industrial Plots/Land" },
    { id: "3", name: "Commercial / Inst. Land" },
  ];
  const propertyPost = [
    { id: "1", name: "Owner" },
    { id: "2", name: "Builder" },
    { id: "3", name: "Dealer" },
  ];

  const handleCominPlandPress = (evt) => {
    if (selectLand.includes(evt.target.value)) {
      setSelectLand(
        selectLand.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setSelectLand((selectLand) => [...selectLand, evt.target.value]);
    }
  };

  const handlePostedByPress = (selectvalue) => {
    if (selectedPostedBy.includes(selectvalue)) {
      setSelectedPostedBy(
        selectedPostedBy.filter((value) => value !== selectvalue)
      );
    } else {
      setSelectedPostedBy([...selectedPostedBy, selectvalue]);
    }
  };
  return (
    <>
      <div className="dropdownbox "    style={{paddingTop:"0"}}>
        <div className="">
          <p className="">Plots/Land</p>
        </div>
        <div className="check-label">
          <div className="parentlabel">
            <div className="childlabel">
              <input
                type="radio"
                id="flat"
                name="flat"
                value={selectLandType}
                checked={selectLandType === false}
                onChange={(e) => setSelectLandType(false)}
              />
              <label className="" htmlFor="flat">
                Residential Plots/Land
              </label>
            </div>
          </div>
          <div className="parentlabel">
            <div className="childlabel">
              <input
                type="radio"
                id="independent"
                name="independent"
                value={selectLandType}
                checked={selectLandType === true}
                onChange={(e) => setSelectLandType(true)}
              />
              <label className="" htmlFor="independent">
                Commercial Plots/Land
              </label>
            </div>
          </div>
          <div className="parentlabel"></div>
        </div>
        {selectLandType === true ? (
          <>
            <div className="check-label">
              {commercialPlotStatus.map((data, index) => (
                <div className="parentlabel" key={index}>
                  <div className="childlabel">
                    <input
                      type="checkbox"
                      id="independent"
                      name="independent"
                      value={data.name}
                      checked={selectLand.includes(data.name)}
                      onChange={(e) => handleCominPlandPress(e, data.name)}
                    />
                    <label className="" htmlFor="independent">
                      {data.name}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="label-caption">
          <p>
            Looking for commercial properties ?<a href="#">Click here</a>
          </p>
          <hr />
        </div>
        <div className="dropdown-lists">
          <div className="parent-box">
            <div
              className={
                toggle === "Budget"
                  ? "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                  : "main-boxes radioInput_textOnly__1r7GL "
              }
              onClick={() => setToggle("Budget")}
            >
              <label className="">Budget</label>
              {toggle === "Budget" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>
            {toggle === "Budget" ? (
              <>
                <div className="range-box">
                  <div className="range-head">
                    <h6 className="">Select Price Range</h6>
                  </div>
                  <div className="range-bar">
                    <label htmlFor="customRange2" className="">
                      0 - 100 Crores+
                    </label>
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="example-thumb"
                      trackClassName="example-track"
                      defaultValue={[0, 1000000000]}
                      max={1000000000}
                      min={0}
                      renderThumb={(props, state) => (
                        <div {...props}>{state.valueNow}</div>
                      )}
                      onChange={(value, index) => setPropertyPrice(value)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className="parent-box">
            <div
              className={
                toggle === "Area"
                  ? "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                  : "main-boxes radioInput_textOnly__1r7GL "
              }
              onClick={() => setToggle("Area")}
            >
              <label className="">Area </label>
              {toggle === "Area" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>
            {toggle === "Area" ? (
              <>
                <div className="range-box">
                  <div className="range-head">
                    <h6 className="">Select Area Range</h6>
                  </div>
                  <div className="range-bar">
                    <label htmlFor="customRange2" className="">
                      0 Sq.Ft - 10000 Sq.Ft
                    </label>
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="example-thumb"
                      trackClassName="example-track"
                      defaultValue={[0, 10000]}
                      max={10000}
                      min={0}
                      renderThumb={(props, state) => (
                        <div {...props}>{state.valueNow}</div>
                      )}
                      onChange={(value, index) => setSelectedArea(value)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className="parent-box">
            <div
              className={
                toggle === "Posted"
                  ? "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                  : "main-boxes radioInput_textOnly__1r7GL "
              }
              onClick={() => setToggle("Posted")}
            >
              <label className="">Posted By</label>
              {toggle === "Posted" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>
            {toggle === "Posted" ? (
              <>
                <div className="bedroombox">
                  <div className="posted-head">
                    <h6 className="">Posted By</h6>
                  </div>
                  <div className="posted-main" style={{ marginTop: "-2px" }}>
                    {propertyPost.map((data, index) => (
                      <div
                        className={
                          selectedPostedBy.includes(data.name)
                            ? "  tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0 "
                            : "  tags-and-chips_textOnly__2k10B pageComponent "
                        }
                        onClick={() => {
                          handlePostedByPress(data.name);
                        }}
                      >
                        <label className="">{data.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className=""></div>
        </div>
      </div>
    </>
  );
};

export default PlotLandSearchComponent;
