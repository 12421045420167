import React from "react";
import SimplePricing from "./home/SimplePricing";

const SubcriptionPage = () => {
  return (
    <>
      <SimplePricing />
    </>
  );
};

export default SubcriptionPage;
