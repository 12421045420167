import React from 'react'

const ConstructionStatus = () => {
    const consustatus = [
      { id: "1", name: "New Launch" },
      { id: "2", name: "Under Construction" },
      { id: "3", name: "Ready To Move" },
    ];
  return (
    <div className="mainbarfiltercomponents">
      {consustatus.map((construct, index) => (
        <div className="propertyboxes" key={index}>
          <input type="checkbox" />
          <p className="propertytextpara">{construct.name}</p>
        </div>
      ))}

      <div>
        <p className="clearalltext">Select All</p>
      </div>
    </div>
  );
}

export default ConstructionStatus