import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { IoMdCall } from 'react-icons/io'
import { Link } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode } from 'swiper/modules';

const SellersMayContact = () => {
    return (
        <>
            <div className="sellermay">
                <div className='sellermay-head'>
                    <div><h1>Sellers you may contact for more details</h1></div>
                    <div><h4>View All Sellers</h4></div>
                </div>
                <div className='sellermay-head-pills'>
                    <div className='active-pill'><h3>3 BHK Apartment</h3></div>
                    <div><h3>4 BHK Apartment</h3></div>
                    <div><h3>5 BHK Apartment</h3></div>
                </div>
                <div className="sellermay-swiper">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        freeMode={true}
                        pagination={{
                            clickable: false,
                        }}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            480: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            640: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            840: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            1000: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                        }}
                        modules={[FreeMode]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="sellermay-swiper-card">
                                    <div className='sellermay-swiper-card-text-img'>
                                        <img src='/assets/images/sliderImg/H11.jpeg' alt=''/>
                                    </div>
                                    <div className='sellermay-swiper-card-text'>
                                        <div><h3>Aliens Developers Pvt Ltd</h3></div>
                                        <div><p>Builder</p></div>
                                    </div>
                                    <a className='sellermay-swiper-card-bottom-text'>
                                        <div>
                                            <h3><IoMdCall/> View Contact</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default SellersMayContact