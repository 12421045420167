import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  searchAll: [],
  searchProperties: [],
  searchComponentStatus: 0,
  commercialStatus: 0,
  isSearchLoading: true,
  searchObjects: localStorage.getItem("searchObjects")
    ? JSON.parse(localStorage.getItem("searchObjects"))
    : "",
  leadesSearchObjects: localStorage.getItem("leadesSearchObjects")
    ? JSON.parse(localStorage.getItem("leadesSearchObjects"))
    : "",
};

export const searchPost = createAsyncThunk(
  "search/searchPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/search/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("search Not create");
    }
  }
);

const SearchSlice = createSlice({
  name: "Search",
  initialState,
  reducers: {
    setSearchProperties(state, action) {
      state.searchProperties = action.payload;
    },
    setSearchComponentStatus(state, action) {
      state.searchComponentStatus = action.payload;
    },
    setcommercialStatus(state, action) {
      state.commercialStatus = action.payload;
    },
    setsearchObjects(state, action) {
      state.searchObjects = action.payload;
      localStorage.setItem("searchObjects", JSON.stringify(state.searchObjects));
    },
    setleadesSearchObjects(state, action) {
      state.leadesSearchObjects = action.payload;
      localStorage.setItem("leadesSearchObjects", JSON.stringify(state.leadesSearchObjects));
    },
  },
  extraReducers: {
    [searchPost.pending]: (state) => {
      state.isSearchLoading = true;
    },

    [searchPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.searchAll = [...state.searchAll, action.payload.search];
      }
      state.isSearchLoading = false;
    },

    [searchPost.rejected]: (state, action) => {
      state.isSearchLoading = true;
    },
  },
});
export const {
  setSearchProperties,
  setSearchComponentStatus,
  setcommercialStatus,
  setsearchObjects,
  setleadesSearchObjects } = SearchSlice.actions;
export default SearchSlice.reducer;
