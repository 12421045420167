import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Oc = (props) => {
  const { PropertyProfile, PriceotherDetails } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();
  const [ocCertificate, setOcCertificate] = useState(false);

  //errors
  const [OccupancyCertificateError, setOccupancyCertificateError] =
    useState("");

  useEffect(() => {
    if (PriceotherDetails !== "") {
      setOcCertificate(PriceotherDetails.ocCertificate);
      props.getOcCertificate(PriceotherDetails.ocCertificate);
    }
  }, [PriceotherDetails]);

  return (
    <>
      <div
        className="OccupancyCertificate-widget"
        style={{ marginTop: "10px" }}
      >
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div
            className="widgetbox_header_text__2BXc_"
            style={{ marginBottom: "-20px" }}
          >
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Occupancy Certificate
                  </span>
                </div>
              </h4>
            </div>
            <p style={{ color: "red" }}>{OccupancyCertificateError}</p>
          </div>
        </div>
        <div className="false">
          <div
            className="pagecomponent"
            id="ownership-input"
            style={{ textAlign: "left" }}
          >
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  ocCertificate === true
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setOcCertificate(true);
                  props.getOcCertificate(true);
                }}
                id="1"
              >
                <span>Yes</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  ocCertificate === false
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setOcCertificate(false);
                  props.getOcCertificate(false);
                }}
                id="2"
              >
                <span>No</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Oc;
