import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BiArrowBack,
  BiInfoCircle,
  BiChevronDown,
  BiPlus,
} from "react-icons/bi";
import { BsLightbulb } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";
import { HiOutlineLightBulb } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import HeaderNew from "../../../components/layouts/HeaderNew";
import CircularProgress from "./CircularProgress";
import { useNavigate } from "react-router-dom";
import {
  desktopimageremove,
  propertyPost,
  setAmentiesDetails,
  setBasicDetails,
  setLocalityScooring,
  setLocationDetails,
  setPhotoDetails,
  setPostPropertyStepsStatus,
  setPriceotherDetails,
  setPropertyProfile,
  setaminitiesScooring,
  setbasicdetailScooring,
  setcurrentPost,
  setmainpropertyScooring,
  setphotoScooring,
  setpostPropertyStepsData,
  setpricingScooring,
  setpropertyScooring,
} from "../../../redux/property/PropertySlice";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import {
  authActions,
  userUpdateById,
} from "../../../redux/athentication/Athentication";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { SkipToPost } from "../skipPost/SkipToPost";
import PropertyFeatures from "../skipPost/PropertyFeatures";
import { ChoosingSubcription } from "../skipPost/ChoosingSubcription";
import MainSkipPage from "../MainSkipPage";

const AmentiesSection = () => {
  const {
    AmentiesDetails,
    BasicDetails,
    LocationDetails,
    desktopimage,
    PriceotherDetails,
    PropertyProfile,
    PhotoDetails,
    basicdetailScooring,
    mainpropertyScooring,
    photoScooring,
    pricingScooring,
    aminitiesScooring,
    LocalityScooring,
    propertyScooring,
  } = useSelector((state) => state.Property);
  const { loginData } = useSelector((store) => store.Athentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [maintenanceStaff, setMaintenanceStaff] = useState(false);
  const [waterStorage, setWaterStorage] = useState(false);
  const [wasteDisposal, setWasteDisposal] = useState(false);
  const [rainWaterHarvesting, setRainWaterHarvesting] = useState(false);
  const [gasPipes, setGasPipes] = useState(false);
  const [waterpurifier, setWaterpurifier] = useState(false);
  const [visitorParking, setVisitorParking] = useState(false);
  const [fengShuiVaastuCompliant, setFengShuiVaastuCompliant] = useState(false);
  const [park, setPark] = useState(false);

  const [highCeilingHeight, setHighCeilingHeight] = useState(false);
  const [falseCeilingLighting, setFalseCeilingLighting] = useState(false);
  const [internetwificonnectivity, setInternetwificonnectivity] =
    useState(false);
  const [centrallyAirConditioned, setCentrallyAirConditioned] = useState(false);
  const [securityFireAlarm, setSecurityFireAlarm] = useState(false);
  const [recentlyRenovated, setRecentlyRenovated] = useState(false);
  const [privateGardenTerrace, setPrivateGardenTerrace] = useState(false);
  const [naturalLight, setNaturalLight] = useState(false);
  const [airyRooms, setAiryRooms] = useState(false);
  const [intercomFacility, setIntercomFacility] = useState(false);
  const [spaciousInteriors, setSpaciousInteriors] = useState(false);

  const [fitnessCentreGYM, setFitnessCentreGYM] = useState(false);
  const [swimmingPool, setSwimmingPool] = useState(false);
  const [clubhouseCommunityCenter, setClubhouseCommunityCenter] =
    useState(false);
  const [securityPersonnel, setSecurityPersonnel] = useState(false);
  const [lifts, setLifts] = useState(false);

  const [separateentryforservantroom, setSeparateentryforservantroom] =
    useState(false);
  const [noopendrainagearound, setNoopendrainagearound] = useState(false);
  const [bankAttachedProperty, setBankAttachedProperty] = useState(false);
  const [lowDensitySociety, setLowDensitySociety] = useState(false);

  const [municipalcorporation, setMunicipalcorporation] = useState(false);
  const [borewellTank, setBorewellTank] = useState(false);
  const [twentyfourbysevenWater, setTwentyfourbysevenWater] = useState(false);

  const [pool, setPool] = useState(false);
  const [parkGarden, setParkGarden] = useState(false);
  const [mainRoad, setMainRoad] = useState(false);
  const [others, setOthers] = useState(false);

  const [inagatedsociety, setInagatedsociety] = useState(false);
  const [inasemigatedsociety, setInasemigatedsociety] = useState(false);
  const [cornerProperty, setCornerProperty] = useState(false);
  const [petFriendly, setPetFriendly] = useState(false);
  const [wheelchairfriendly, setWheelchairfriendly] = useState(false);

  const [none, setNone] = useState(false);
  const [partial, setPartial] = useState(false);
  const [full, setFull] = useState(false);
  const [powerBackup, setpowerBackup] = useState("");

  const [propertyFacing, setPropertyFacing] = useState("");

  const [north, setNorth] = useState(false);
  const [south, setSouth] = useState(false);
  const [east, setEast] = useState(false);
  const [west, setWest] = useState(false);
  const [northEast, setNorthEast] = useState(false);
  const [northWest, setNorthWest] = useState(false);
  const [southEast, setSouthEast] = useState(false);
  const [southWest, setSouthWest] = useState(false);

  const [typeofFlooring, setTypeofFlooring] = useState("");
  const [typeofFlooringShow, setTypeofFlooringShow] = useState(false);

  const [roadWidth, setRoadWidth] = useState("");
  const [roadWidthType, setRoadWidthType] = useState("Feet");
  const [roadWidthTypeShow, setRoadWidthTypeShow] = useState(false);

  const [closetoMetroStation, setClosetoMetroStation] = useState(false);
  const [closetoSchool, setClosetoSchool] = useState(false);
  const [closetoHospital, setClosetoHospital] = useState(false);
  const [closetoMarket, setClosetoMarket] = useState(false);
  const [closetoRailwayStation, setClosetoRailwayStation] = useState(false);
  const [closetoAirport, setClosetoAirport] = useState(false);
  const [closetoMall, setClosetoMall] = useState(false);
  const [closetoHighway, setClosetoHighway] = useState(false);

  const [enterUSPs, setEnterUSPs] = useState("");
  const [uniquePropertyText, setUniquePropertyText] = useState("");

  const [progress, setProgress] = useState(0);

  const [buttonLoading, setbuttonLoading] = useState(false);
  const [postPropertyPopUp, setPostPropertyPopUp] = useState(false);

  const [skipPageIndex, setSkipPageIndex] = useState(0);
  const [numberOfBosting, setnumberOfBosting] = useState(0);
  const [ExperieMounth, setExperieMounth] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Simulating progress update
      setProgress((prevProgress) =>
        prevProgress < 100 ? prevProgress + 10 : 0
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let totalcount = 0;
    let aminitieschnage = 0;
    if (
      maintenanceStaff === true ||
      waterStorage === true ||
      wasteDisposal === true ||
      rainWaterHarvesting === true ||
      gasPipes === true ||
      waterpurifier === true ||
      visitorParking === true ||
      fengShuiVaastuCompliant === true ||
      park === true
    ) {
      aminitieschnage = 5;
    } else {
      aminitieschnage = 0;
    }
    let propertyFacingcount = 0;
    if (propertyFacing !== "") {
      propertyFacingcount = 5;
    } else {
      propertyFacingcount = 0;
    }

    totalcount = aminitieschnage + propertyFacingcount;
    dispatch(setLocalityScooring(LocalityScooring));
    dispatch(setbasicdetailScooring(basicdetailScooring));
    dispatch(setphotoScooring(photoScooring));
    dispatch(setpricingScooring(pricingScooring));
    dispatch(setaminitiesScooring(totalcount));
    dispatch(setmainpropertyScooring(mainpropertyScooring));
    dispatch(setpropertyScooring());
  }, [
    maintenanceStaff,
    waterStorage,
    wasteDisposal,
    rainWaterHarvesting,
    gasPipes,
    waterpurifier,
    visitorParking,
    fengShuiVaastuCompliant,
    park,
    propertyFacing,
  ]);

  useEffect(() => {
    if (AmentiesDetails !== "") {
      setMaintenanceStaff(AmentiesDetails.maintenanceStaff);
      setWaterStorage(AmentiesDetails.waterStorage);
      setWasteDisposal(AmentiesDetails.wasteDisposal);
      setRainWaterHarvesting(AmentiesDetails.rainWaterHarvesting);
      setGasPipes(AmentiesDetails.gasPipes);
      setWaterpurifier(AmentiesDetails.waterpurifier);
      setVisitorParking(AmentiesDetails.visitorParking);
      setFengShuiVaastuCompliant(AmentiesDetails.fengShuiVaastuCompliant);
      setPark(AmentiesDetails.park);

      setHighCeilingHeight(AmentiesDetails.highCeilingHeight);
      setFalseCeilingLighting(AmentiesDetails.falseCeilingLighting);
      setInternetwificonnectivity(AmentiesDetails.internetwificonnectivity);
      setCentrallyAirConditioned(AmentiesDetails.centrallyAirConditioned);
      setSecurityFireAlarm(AmentiesDetails.securityFireAlarm);
      setRecentlyRenovated(AmentiesDetails.recentlyRenovated);
      setPrivateGardenTerrace(AmentiesDetails.privateGardenTerrace);
      setNaturalLight(AmentiesDetails.naturalLight);
      setAiryRooms(AmentiesDetails.airyRooms);
      setIntercomFacility(AmentiesDetails.intercomFacility);
      setSpaciousInteriors(AmentiesDetails.spaciousInteriors);

      setFitnessCentreGYM(AmentiesDetails.fitnessCentreGYM);
      setSwimmingPool(AmentiesDetails.swimmingPool);
      setClubhouseCommunityCenter(AmentiesDetails.clubhouseCommunityCenter);
      setSecurityPersonnel(AmentiesDetails.securityPersonnel);
      setLifts(AmentiesDetails.lifts);

      setSeparateentryforservantroom(
        AmentiesDetails.separateentryforservantroom
      );
      setNoopendrainagearound(AmentiesDetails.noopendrainagearound);
      setBankAttachedProperty(AmentiesDetails.bankAttachedProperty);
      setLowDensitySociety(AmentiesDetails.lowDensitySociety);

      setMunicipalcorporation(AmentiesDetails.municipalcorporation);
      setBorewellTank(AmentiesDetails.borewellTank);
      setTwentyfourbysevenWater(AmentiesDetails.twentyfourbysevenWater);

      setPool(AmentiesDetails.pool);
      setParkGarden(AmentiesDetails.parkGarden);
      setMainRoad(AmentiesDetails.mainRoad);
      setOthers(AmentiesDetails.others);

      setInagatedsociety(AmentiesDetails.inagatedsociety);
      setInasemigatedsociety(AmentiesDetails.inasemigatedsociety);
      setCornerProperty(AmentiesDetails.cornerProperty);
      setPetFriendly(AmentiesDetails.petFriendly);
      setWheelchairfriendly(AmentiesDetails.wheelchairfriendly);

      setpowerBackup(AmentiesDetails.powerBackup);

      setPropertyFacing(AmentiesDetails.propertyFacing);

      setTypeofFlooring(AmentiesDetails.typeofFlooring);

      setRoadWidth(AmentiesDetails.roadWidth);
      setRoadWidthType(AmentiesDetails.roadWidthType);

      setClosetoMetroStation(AmentiesDetails.closetoMetroStation);
      setClosetoSchool(AmentiesDetails.closetoSchool);
      setClosetoHospital(AmentiesDetails.closetoHospital);
      setClosetoMarket(AmentiesDetails.closetoMarket);
      setClosetoRailwayStation(AmentiesDetails.closetoRailwayStation);
      setClosetoAirport(AmentiesDetails.closetoAirport);
      setClosetoMall(AmentiesDetails.closetoMall);
      setClosetoHighway(AmentiesDetails.closetoHighway);
    }
  }, [AmentiesDetails]);

  const amentiesdetailsClick = (e) => {
    const amentiesDetails = {
      maintenanceStaff: maintenanceStaff,
      waterStorage: waterStorage,
      wasteDisposal: wasteDisposal,
      rainWaterHarvesting: rainWaterHarvesting,
      gasPipes: gasPipes,
      waterpurifier: waterpurifier,
      visitorParking: visitorParking,
      fengShuiVaastuCompliant: fengShuiVaastuCompliant,
      park: park,
      highCeilingHeight: highCeilingHeight,
      falseCeilingLighting: falseCeilingLighting,
      internetwificonnectivity: internetwificonnectivity,
      centrallyAirConditioned: centrallyAirConditioned,
      securityFireAlarm: securityFireAlarm,
      recentlyRenovated: recentlyRenovated,
      privateGardenTerrace: privateGardenTerrace,
      naturalLight: naturalLight,
      airyRooms: airyRooms,
      intercomFacility: intercomFacility,
      spaciousInteriors: spaciousInteriors,
      fitnessCentreGYM: fitnessCentreGYM,
      swimmingPool: swimmingPool,
      maintenanceStaff: maintenanceStaff,
      clubhouseCommunityCenter: clubhouseCommunityCenter,
      securityPersonnel: securityPersonnel,
      lifts: lifts,
      separateentryforservantroom: separateentryforservantroom,
      noopendrainagearound: noopendrainagearound,
      bankAttachedProperty: bankAttachedProperty,
      lowDensitySociety: lowDensitySociety,
      municipalcorporation: municipalcorporation,
      borewellTank: borewellTank,
      twentyfourbysevenWater: twentyfourbysevenWater,
      pool: pool,
      parkGarden: parkGarden,
      mainRoad: mainRoad,
      others: others,
      inagatedsociety: inagatedsociety,
      inasemigatedsociety: inasemigatedsociety,
      cornerProperty: cornerProperty,
      petFriendly: petFriendly,
      wheelchairfriendly: wheelchairfriendly,
      powerBackup: powerBackup,
      propertyFacing: propertyFacing,
      typeofFlooring: typeofFlooring,
      roadWidth: roadWidth,
      roadWidthType: roadWidthType,
      closetoMetroStation: closetoMetroStation,
      closetoSchool: closetoSchool,
      closetoHospital: closetoHospital,
      closetoMarket: closetoMarket,
      closetoRailwayStation: closetoRailwayStation,
      closetoAirport: closetoAirport,
      closetoMall: closetoMall,
      closetoHighway: closetoHighway,
    };
    //setAmentiesDetails
    dispatch(setAmentiesDetails(amentiesDetails));
    // dispatch(setPostPropertyStepsStatus(5));
  };

  const propertyPostClick = async (e, boosting, experie) => {
    e.preventDefault();
    if (!buttonLoading) {
      setbuttonLoading(true);
      let expectedPrice = 0;
      if (PriceotherDetails.expectedPrice) {
        expectedPrice = PriceotherDetails.expectedPrice;
      } else {
        expectedPrice = expectedPrice;
      }

      let booststatus = false;
      if (boosting > 1) {
        booststatus = true;
      }

      let todayDate = new Date();
      todayDate.setMonth(todayDate.getMonth() + Number(experie));
      todayDate = new Date(todayDate);

      const creditabove = Number(boosting) + Number(experie * 0.5);

      // if (loginData.role === "User" || loginData.role === "Owner") {
      if (loginData.PostCreadits >= Number(creditabove)) {
        const formdataSpent = {
          PostCreadits: loginData.PostCreadits - Number(creditabove),
        };
        const url = `${Baseurl}/api/v1/user/updateuser/${loginData._id}`;

        let fetchData = await axios.put(url, formdataSpent);
        let furnishType = "";
        if (PropertyProfile.furnish === true) {
          furnishType = "Furnished";
        } else if (PropertyProfile.semifurnish) {
          furnishType = "Semi Furnished";
        } else if (PropertyProfile.unfurnish) {
          furnishType = "Un Furnished";
        }

        let propertyAvailableStatuss = "";

        if (
          PropertyProfile.readyToMove === true ||
          BasicDetails.catName === "Pg"
        ) {
          propertyAvailableStatuss = "Ready To Move";
        } else {
          propertyAvailableStatuss = "Under Construction";
        }

        try {
          const authenticForm = {
            ...fetchData.data.user,
            isAuth: true,
          };
          dispatch(authActions.signIn(authenticForm));
        } catch (error) {}

        if (fetchData.data.success) {
          let visblePrice = "";
          if (
            Number(PriceotherDetails.expectedPrice) >= 1000 &&
            Number(PriceotherDetails.expectedPrice) <= 99999
          ) {
            // visblePrice = (Number(PriceotherDetails.expectedPrice) / 1000).toFixed(1)
            visblePrice = String(PriceotherDetails.expectedPrice);
          } else if (
            Number(PriceotherDetails.expectedPrice) <= 9999999 &&
            Number(PriceotherDetails.expectedPrice) >= 100000
          ) {
            visblePrice = (
              Number(PriceotherDetails.expectedPrice) / 100000
            ).toFixed(1);
            visblePrice = `${visblePrice} lac`;
          } else if (Number(PriceotherDetails.expectedPrice) > 10000000) {
            visblePrice = (
              Number(PriceotherDetails.expectedPrice) / 10000000
            ).toFixed(1);
            visblePrice = `${visblePrice} cr`;
          }
          let postUserPosition = loginData.role;
          if (postUserPosition === "User") {
            postUserPosition = "Owner";
          }
          const formdata = {
            userId: loginData._id,
            postUserName: loginData.name,
            postUserEmail: loginData.email,
            postUserMobile: loginData.mobile,
            postUserPosition: postUserPosition,
            superCategoryId: BasicDetails.supercatId,
            superCategory: BasicDetails.supercatName,
            categoryId: BasicDetails.catId,
            category: BasicDetails.catName,
            subCategoryId: BasicDetails.subcatId,
            subCategory: BasicDetails.subcatName,
            typeOfland: BasicDetails.typeOfland,
            reatailSubshop: BasicDetails.reatailSubshop,
            percentageCompletion: propertyScooring,
            NoofBoosting: Number(boosting),
            boosted: booststatus,
            // state: yourstate,
            city:
              LocationDetails.city.charAt(0).toUpperCase() +
              LocationDetails.city.slice(1).trim().toLowerCase(),
            apartment:
              LocationDetails.apartment.charAt(0).toUpperCase() +
              LocationDetails.apartment.slice(1).trim().toLowerCase(),
            locality:
              LocationDetails.locality.charAt(0).toUpperCase() +
              LocationDetails.locality.slice(1).trim().toLowerCase(),
            SubLocality: LocationDetails.subLocality,
            locatedInside: LocationDetails.locatedInside,
            zoneType: LocationDetails.zoneType,
            searchArea:
              LocationDetails.apartment +
              " " +
              LocationDetails.locality +
              " " +
              LocationDetails.city +
              " " +
              "Telangana",
            houseNo: LocationDetails.housenumber,
            state: "Telangana",

            apartmentType: "",
            noofBedrooms: PropertyProfile.noofBedrooms,
            noofBathrooms: PropertyProfile.noofBathrooms,
            balconies: PropertyProfile.balconies,

            carpetArea: Number(PropertyProfile.carpetArea),
            carpetAreaMesurmentType: PropertyProfile.typeofCarpetArea,

            buildupArea: Number(PropertyProfile.builtupArea),
            buildupAreaMesurmentType: PropertyProfile.typeofBuiltupArea,

            superbuildupArea: Number(PropertyProfile.superBuiltupArea),
            superbuildupAreaMesurmentType:
              PropertyProfile.typeofSuperBuiltupArea,

            plotArea: Number(PropertyProfile.plotArea),
            plotAreaMesurmentType: PropertyProfile.plotAreaMesurmentType,

            lengthofPlot: PropertyProfile.lengthofPlot,
            widthofPlot: PropertyProfile.widthofPlot,
            allowedNoofFloors: PropertyProfile.allowedNoofFloors,
            wallboundryAvaliable: PropertyProfile.wallboundryAvaliable,
            noofOpenSides: PropertyProfile.noofOpenSides,
            ConustructionOnthisProperty: PropertyProfile.OnthisProperty,
            conustruction: {
              shed: PropertyProfile.shed,
              room: PropertyProfile.room,
              washRoom: PropertyProfile.washRoom,
              others: PropertyProfile.others,
            },

            typeofRoom: PropertyProfile.typeofRoom,
            noOfpeoplesharing: PropertyProfile.noOfpeoplesharing,
            noOfBeds: PropertyProfile.noOfBeds,
            noOfAvailbleBeds: PropertyProfile.noOfAvailbleBeds,
            AttachedBathrooms: PropertyProfile.AttachedBathrooms,
            Attachedbalcony: PropertyProfile.Attachedbalcony,
            //ofc
            minNoOfSeats: PropertyProfile.minNoOfSeats,
            maxNoofSeats: PropertyProfile.maxNoofSeats,
            noofCabins: PropertyProfile.noofCabins,
            noofMeatingRooms: PropertyProfile.noofMeatingRooms,

            washRoomAvalible: PropertyProfile.washRoomAvalible,
            privateWashroom: PropertyProfile.privateWashroom,
            sharedWashroom: PropertyProfile.sharedWashroom,

            ConferenceRoom: PropertyProfile.ConferenceRoom,
            receptionArea: PropertyProfile.receptionArea,
            pantryType: PropertyProfile.pantryType,
            pantrySize: PropertyProfile.pantrySize,
            typeofpantrySize: PropertyProfile.typeofpantrySize,

            officeFurnish: PropertyProfile.furnish,
            officeCentralAir: PropertyProfile.centralAir,
            officeOxygen: PropertyProfile.oxygen,
            officeUps: PropertyProfile.ups,

            fireExtinguisher: PropertyProfile.fireExtinguisher,
            fireSensors: PropertyProfile.fireSensors,
            sprinklers: PropertyProfile.sprinklers,
            fireHose: PropertyProfile.fireHose,

            pglift: PropertyProfile.lifts,
            servicelifts: PropertyProfile.servicelifts,
            passengerLifts: PropertyProfile.passengerLifts,
            modernLift: PropertyProfile.modernLift,

            publicParking: PropertyProfile.publicParking,
            privateParkingOutSide: PropertyProfile.privateParking,

            //retail

            entranceWidth: PropertyProfile.entranceWidth,
            typeofentranceWidth: PropertyProfile.typeofentranceWidth,
            cellingHeight: PropertyProfile.cellingHeight,
            typeofcellingHeight: PropertyProfile.typeofcellingHeight,

            entrance: PropertyProfile.entrance,
            elevator: PropertyProfile.elevator,
            stairs: PropertyProfile.stairs,

            poojaRoom: PropertyProfile.poojaroom,
            studyRoom: PropertyProfile.studyroom,
            servantRoom: PropertyProfile.servantroom,
            storeRoom: PropertyProfile.storeroom,

            furnishType: furnishType,
            availableFor: PropertyProfile.availableFor,
            availableDate: PropertyProfile.availableDate,
            family: PropertyProfile.family,
            man: PropertyProfile.man,
            women: PropertyProfile.women,
            areyouOk: PropertyProfile.areyouOk,

            numberoflight: String(PropertyProfile.light),
            numberoffans: String(PropertyProfile.fans),
            numberofac: String(PropertyProfile.ac),
            numberoftv: String(PropertyProfile.tv),
            numberofbeds: String(PropertyProfile.beds),
            numberofwardrobe: String(PropertyProfile.wardrobe),
            numberofgeyser: String(PropertyProfile.geyser),
            sofa: PropertyProfile.sofa,
            washingMachine: PropertyProfile.washingMachine,
            stove: PropertyProfile.stove,
            fridge: PropertyProfile.fridge,
            waterPurifier: PropertyProfile.waterPurifier,
            microwave: PropertyProfile.microwave,
            modularKitchen: PropertyProfile.modularKitchen,
            chimney: PropertyProfile.chimney,
            dinningTable: PropertyProfile.dinningTable,
            curtains: PropertyProfile.curtains,
            exhaustFan: PropertyProfile.exhaustFan,

            numberofCoveredParking: String(PropertyProfile.coveredParking),
            numberofOpenParking: String(PropertyProfile.openParking),

            noofFloor: PropertyProfile.totalFloors,
            propertyOnFloor: PropertyProfile.propertyonFloors,

            availabilityStatus: propertyAvailableStatuss,
            propertyAge: PropertyProfile.ageofProperty,

            propertyPossessionBy: PropertyProfile.expectedby,
            plotRoadwidth: PropertyProfile.roadWidth,
            facingRoad: PropertyProfile.facingRoad,
            noofRating: PropertyProfile.noofRating,

            DeskImg: desktopimage,

            ownerShip: PriceotherDetails.typeOfOwnerShip,
            preferedAgreement: PriceotherDetails.preferedAgreement,
            expectedPrice: PriceotherDetails.expectedPrice,
            visiblePrice: visblePrice,
            pricePerSqft: Number(PriceotherDetails.pricePerSqft),

            inclusivePrice: PriceotherDetails.allinclusiveprice,
            govtCharges: PriceotherDetails.taxandgovtchargesexcluded,
            priceNegotiable: PriceotherDetails.priceNegotiable,

            maintenanceAmount: PriceotherDetails.maintenance,
            maintenanceTerm: PriceotherDetails.maintenanceMonthly,
            expectedRental: PriceotherDetails.expectedPrice,
            bookingAmount: PriceotherDetails.bookingAmount,
            annualPayable: PriceotherDetails.annualDuesPayable,
            membershipCharge: PriceotherDetails.membershipCharges,
            securityDeposit: PriceotherDetails.securityDeposit,
            depositAmount: PriceotherDetails.depositAmount,
            noofMonths: PriceotherDetails.noofMonths,
            includesprice: {
              laundry: PriceotherDetails.laundary,
              electricity: PriceotherDetails.electricity,
              water: PriceotherDetails.water,
              wifi: PriceotherDetails.wifi,
              housekeeping: PriceotherDetails.houseKeeping,
              dth: PriceotherDetails.dth,
              noneoftheabove: PriceotherDetails.noneofTheabove,
            },
            houseKeepingExtraCharge: PriceotherDetails.houseKeepingExtraCharge,
            FoodDetails: PriceotherDetails.FoodDetails,
            mealtype: PriceotherDetails.mealtype,
            lunch: PriceotherDetails.lunch,
            breakfast: PriceotherDetails.breakfast,
            dinner: PriceotherDetails.dinner,
            lunchweekends: PriceotherDetails.lunchweekends,
            breakfastweekends: PriceotherDetails.breakfastweekends,
            dinnerweekends: PriceotherDetails.dinnerweekends,
            foodCharges: PriceotherDetails.foodCharges,
            lunchAmount: PriceotherDetails.lunchAmount,
            breakfastAmount: PriceotherDetails.breakfastAmount,
            dinnerAmount: PriceotherDetails.dinnerAmount,
            fixedAmountFood: PriceotherDetails.fixedAmountFood,

            durationAgreementPg: PriceotherDetails.durationAgreement,
            maintenanceMonthlyduration:
              PriceotherDetails.maintenanceMonthlyduration,
            notiesMonths: PriceotherDetails.notiesMonths,

            earlyLeavingCharges: PriceotherDetails.earlyLeavingCharges,
            earlyFixedCharges: PriceotherDetails.earlyFixedCharges,
            earlyMonths: PriceotherDetails.earlyMonths,

            houseRules: {
              pet: PriceotherDetails.pets,
              Visitors: PriceotherDetails.visitors,
              Smoking: PriceotherDetails.smoking,
              Alcohol: PriceotherDetails.alchol,
              Party: PriceotherDetails.party,
            },

            showLastEntryTimePg: PriceotherDetails.showLastEntryTime,
            otherRulePg: PriceotherDetails.otherRulePg,
            uniquePropertyCommercial: PriceotherDetails.uniquePropertyText,
            dTCP: PriceotherDetails.dTCP,
            gHMC: PriceotherDetails.gHMC,
            hMDA: PriceotherDetails.hMDA,
            preleasedCommercial: PriceotherDetails.preleased,
            rentpermonthCommercial: PriceotherDetails.rentpermonth,
            leaseyearsCommercial: PriceotherDetails.leaseyears,
            leasePercentageCommercial:
              PriceotherDetails.leaseincreasedPercentage,
            businessTypeCommercial: PriceotherDetails.businessType,
            lockInPeriodCommercial: PriceotherDetails.lockInPeriod,
            yearlyRentCommercial: PriceotherDetails.yearlyRent,
            nocCommercial: PriceotherDetails.noc,
            ocCertificateCommercial: PriceotherDetails.ocCertificate,
            officeUsedfor: PriceotherDetails.officeUsed,

            maintenanceStaff: maintenanceStaff,
            waterStorage: waterStorage,
            wasteDisposal: wasteDisposal,
            rainWaterHarvesting: rainWaterHarvesting,
            gasPipes: gasPipes,
            waterpurifier: waterpurifier,
            visitorParking: visitorParking,
            fengShuiVaastuCompliant: fengShuiVaastuCompliant,
            park: park,

            highCeilingHeight: highCeilingHeight,
            falseCeilingLighting: falseCeilingLighting,
            internetwificonnectivity: internetwificonnectivity,
            centrallyAirConditioned: centrallyAirConditioned,
            securityFireAlarm: securityFireAlarm,
            recentlyRenovated: recentlyRenovated,
            privateGardenTerrace: privateGardenTerrace,
            naturalLight: naturalLight,
            airyRooms: airyRooms,
            intercomFacility: intercomFacility,
            spaciousInteriors: spaciousInteriors,

            fitnessCentreGYM: fitnessCentreGYM,
            swimmingPool: swimmingPool,
            clubhouseCommunityCenter: clubhouseCommunityCenter,
            securityPersonnel: securityPersonnel,
            lifts: lifts,

            separateentryforservantroom: separateentryforservantroom,
            noopendrainagearound: noopendrainagearound,
            bankAttachedProperty: bankAttachedProperty,
            lowDensitySociety: lowDensitySociety,
            municipalcorporation: municipalcorporation,
            borewellTank: borewellTank,
            twentyfourbysevenWater: twentyfourbysevenWater,

            pool: pool,
            parkGarden: parkGarden,
            mainRoad: mainRoad,
            others: others,

            inagatedsociety: inagatedsociety,
            inasemigatedsociety: inasemigatedsociety,
            cornerProperty: cornerProperty,
            petFriendly: petFriendly,
            wheelchairfriendly: wheelchairfriendly,

            powerBackup: powerBackup,
            propertyFacing: propertyFacing,
            typeofFlooring: typeofFlooring,
            roadWidth: roadWidth,
            roadWidthType: roadWidthType,

            closetoMetroStation: closetoMetroStation,
            closetoSchool: closetoSchool,
            closetoHospital: closetoHospital,
            closetoMarket: closetoMarket,
            closetoRailwayStation: closetoRailwayStation,
            closetoAirport: closetoAirport,
            closetoMall: closetoMall,
            closetoHighway: closetoHighway,

            unicProperty: uniquePropertyText,
            ExpiryDate: todayDate,
          };
          console.log(formdata, "formdata123");
          const data = await dispatch(propertyPost(formdata));

          if (data.payload.success === true) {
            const currPost = data.payload.property;
            dispatch(setcurrentPost(currPost));
            navigate("/post-success");
            toast.success("Property Added Successfully", {
              position: "top-center",
              autoClose: 1000,
            });
            dispatch(setPostPropertyStepsStatus(0));
            dispatch(setpostPropertyStepsData(""));
            dispatch(setBasicDetails(""));
            dispatch(setLocationDetails(""));
            dispatch(setPropertyProfile(""));
            dispatch(setPhotoDetails(""));
            dispatch(setPriceotherDetails(""));
            dispatch(setAmentiesDetails(""));
            dispatch(desktopimageremove(""));

            dispatch(setLocalityScooring(0));
            dispatch(setbasicdetailScooring(0));
            dispatch(setphotoScooring(0));
            dispatch(setpricingScooring(0));
            dispatch(setaminitiesScooring(0));
            dispatch(setmainpropertyScooring(0));

            dispatch(setpropertyScooring());
          } else {
            setbuttonLoading(false);
          }
          if (postUserPosition !== loginData.role) {
            const userUpdate = {
              userId: loginData._id,
              role: postUserPosition,
            };

            dispatch(userUpdateById(userUpdate));
          }
        }
      } else {
        toast.error("Insufficient Balance", {
          position: "top-center",
          autoClose: 1500,
        });
        setbuttonLoading(false);
      }
    }
    // }
  };

  const propertyPostPopUpClick = async (e) => {
    e.preventDefault();
    setPostPropertyPopUp(true);
  };

  const setSkipPageChange = (value) => {
    setSkipPageIndex(value);
  };
  const setexperiMounthChange = (value) => {
    setExperieMounth(value);
  };
  const setNoOfBoostingChange = (value) => {
    setnumberOfBosting(value);
  };

  const reviewChange = (e) => {
    const value = e.target.value.trim();
    setUniquePropertyText(value);
  };

  console.log(BasicDetails, "BasicDetails");
  console.log(LocationDetails, "LocationDetails");
  console.log(PropertyProfile, "PropertyProfile");

  const setUnicClick = (e) => {
    e.preventDefault();

    let unicText = "";
    if (BasicDetails.supercatName === "Commercial") {
      unicText = `This ${BasicDetails.subcatName} is available for ${
        BasicDetails.catName
      } in ${
        LocationDetails.apartment
      }, one of the most prominent projects for ${BasicDetails.subcatName} in ${
        LocationDetails.apartment
      } is available for ${BasicDetails.catName} in ${
        LocationDetails.locality
      }, ${LocationDetails.city}. Constructed on a Carpet Area of ${
        PropertyProfile.carpetArea + " " + PropertyProfile.typeofCarpetArea
      }. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
    } else {
      if (BasicDetails.catName === "Pg") {
        unicText = `This ${BasicDetails.subcatName} is available for ${
          BasicDetails.catName
        } in ${
          LocationDetails.apartment
        }, one of the most prominent projects for ${
          BasicDetails.subcatName
        } in ${LocationDetails.apartment} is available for ${
          BasicDetails.catName
        } in ${LocationDetails.locality}, ${
          LocationDetails.city
        }. Constructed on a Carpet Area of ${
          PropertyProfile.carpetArea + " " + PropertyProfile.typeofCarpetArea
        }, the ${BasicDetails.subcatName} comprises ${
          PropertyProfile.noofBedrooms
        } bedroom(s), ${PropertyProfile.noofBathrooms} bathrooms and ${
          PropertyProfile.balconies
        } balcony. The ${BasicDetails.subcatName} has a total of ${
          PropertyProfile.totalFloors
        } floors and this property is situated on ${
          PropertyProfile.propertyonFloors
        } floor. This is a Ready To Move project and the property is ${
          PropertyProfile.ageofProperty
        } year old. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
      } else {
        if (
          BasicDetails.subcatName === "Office" ||
          BasicDetails.subcatName === "Retail"
        ) {
          if (PropertyProfile.readyToMove === true) {
            unicText = `This ${BasicDetails.subcatName} is available for ${
              BasicDetails.catName
            } in ${
              LocationDetails.apartment
            }, one of the most prominent projects for ${
              BasicDetails.subcatName
            } in ${LocationDetails.apartment} is available for ${
              BasicDetails.catName
            } in ${LocationDetails.locality}, ${
              LocationDetails.city
            }. Constructed on a Carpet Area of ${
              PropertyProfile.carpetArea +
              " " +
              PropertyProfile.typeofCarpetArea
            }, the ${BasicDetails.subcatName} comprises ${
              PropertyProfile.noofBedrooms
            } bedroom(s), ${PropertyProfile.noofBathrooms} bathrooms and ${
              PropertyProfile.balconies
            } balcony. The ${BasicDetails.subcatName} has a total of ${
              PropertyProfile.totalFloors
            } floors  ${
              BasicDetails.subcatName !== "Villa"
                ? PropertyProfile.propertyonFloors +
                  "and this property is situated on floor"
                : ""
            } 
         
          
         . This is a Ready To Move project and the property is ${
           PropertyProfile.ageofProperty
         } year old. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
          } else {
            unicText = `This ${BasicDetails.subcatName} is available for ${
              BasicDetails.catName
            } in ${
              LocationDetails.apartment
            }, one of the most prominent projects for ${
              BasicDetails.subcatName
            } in ${LocationDetails.apartment} is available for ${
              BasicDetails.catName
            } in ${LocationDetails.locality}, ${
              LocationDetails.city
            }. Constructed on a Carpet Area of ${
              PropertyProfile.carpetArea +
              " " +
              PropertyProfile.typeofCarpetArea
            }, the ${BasicDetails.subcatName} comprises ${
              PropertyProfile.noofBedrooms
            } bedroom(s), ${PropertyProfile.noofBathrooms} bathrooms and ${
              PropertyProfile.balconies
            } balcony. The ${BasicDetails.subcatName} has a total of ${
              PropertyProfile.totalFloors
            } floors ${
              BasicDetails.subcatName !== "Villa"
                ? PropertyProfile.propertyonFloors +
                  "and this property is situated on floor"
                : ""
            } . This is a Under Construction project and the property is ${
              PropertyProfile.ageofProperty
            } year old. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
          }
        } else if ( BasicDetails.subcatName === "Plot" ||
          BasicDetails.subcatName === "Land" ) {
            if (PropertyProfile.readyToMove === true) {
              unicText = `This ${BasicDetails.subcatName} is available for ${
                BasicDetails.catName
              } in ${
                LocationDetails.apartment
              }, one of the most prominent projects for ${
                BasicDetails.subcatName
              } in ${LocationDetails.apartment} is available for ${
                BasicDetails.catName
              } in ${LocationDetails.locality}, ${
                LocationDetails.city
              }. Constructed on a Carpet Area of ${
                PropertyProfile.carpetArea +
                " " +
                PropertyProfile.typeofCarpetArea
              }. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
            } else {
              unicText = `This ${BasicDetails.subcatName} is available for ${
                BasicDetails.catName
              } in ${
                LocationDetails.apartment
              }, one of the most prominent projects for ${
                BasicDetails.subcatName
              } in ${LocationDetails.apartment} is available for ${
                BasicDetails.catName
              } in ${LocationDetails.locality}, ${
                LocationDetails.city
              }. Constructed on a Carpet Area of ${
                PropertyProfile.carpetArea +
                " " +
                PropertyProfile.typeofCarpetArea
              },. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
            }
        } else{
          if (PropertyProfile.readyToMove === true) {
            unicText = `This ${PropertyProfile.noofBedrooms} BHK ${
              BasicDetails.subcatName
            } is available for ${BasicDetails.catName} in ${
              LocationDetails.apartment
            }, one of the most prominent projects for ${
              BasicDetails.subcatName
            } in ${LocationDetails.apartment} is available for ${
              BasicDetails.catName
            } in ${LocationDetails.locality}, ${
              LocationDetails.city
            }. Constructed on a Carpet Area of ${
              PropertyProfile.carpetArea +
              " " +
              PropertyProfile.typeofCarpetArea
            }, the ${BasicDetails.subcatName} comprises ${
              PropertyProfile.noofBedrooms
            } bedroom(s), ${PropertyProfile.noofBathrooms} bathrooms and ${
              PropertyProfile.balconies
            } balcony. The ${BasicDetails.subcatName} has a total of ${
              PropertyProfile.totalFloors
            } floors  ${
              BasicDetails.subcatName !== "Villa"
                ? PropertyProfile.propertyonFloors +
                  "and this property is situated on floor"
                : ""
            } . This is a Ready To Move project and the property is ${
              PropertyProfile.ageofProperty
            } year old. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
          } else {
            unicText = `This ${PropertyProfile.noofBedrooms} BHK ${
              BasicDetails.subcatName
            } is available for ${BasicDetails.catName} in ${
              LocationDetails.apartment
            }, one of the most prominent projects for ${
              BasicDetails.subcatName
            } in ${LocationDetails.apartment} is available for ${
              BasicDetails.catName
            } in ${LocationDetails.locality}, ${
              LocationDetails.city
            }. Constructed on a Carpet Area of ${
              PropertyProfile.carpetArea +
              " " +
              PropertyProfile.typeofCarpetArea
            }, the ${BasicDetails.subcatName} comprises ${
              PropertyProfile.noofBedrooms
            } bedroom(s), ${PropertyProfile.noofBathrooms} bathrooms and ${
              PropertyProfile.balconies
            } balcony. The ${BasicDetails.subcatName} has a total of ${
              PropertyProfile.totalFloors
            } floors  ${
              BasicDetails.subcatName !== "Villa"
                ? PropertyProfile.propertyonFloors +
                  "and this property is situated on floor"
                : ""
            } . This is a Under Construction project and the property is ${
              PropertyProfile.ageofProperty
            } year old. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
          }
        }
      }
    }

    unicText = unicText.trim();
    setUniquePropertyText(unicText);
  };

  const typeofFloorArray = [
    { name: "Marble" },
    { name: "Concrete" },
    { name: "Polished concrete" },
    { name: "Granite" },
    { name: "Ceramic" },
    { name: "Mosaic" },
    { name: "Cement" },
    { name: "Stone" },
    { name: "Vinyl" },
    { name: "Wood" },
    { name: "Vitrified" },
    { name: "IPSFinish" },
    { name: "Others" },
  ];
  const toggleClick = (e) => {
    setPostPropertyPopUp(!postPropertyPopUp);
  };

  return (
    <>
      {/* <HeaderNew /> */}

      <Modal
        isOpen={postPropertyPopUp}
        toggle={toggleClick}
        className="modalmainPopUp"
        style={{}}
      >
        <div className="model-wrapperPopUp " style={{}}>
          <ModalBody>
            <div>
              <MainSkipPage
                toggleClick={toggleClick}
                propertyPostClick={propertyPostClick}
                skipPageIndex={skipPageIndex}
                setSkipPageChange={setSkipPageChange}
                setexperiMounthChange={setexperiMounthChange}
                setNoOfBoostingChange={setNoOfBoostingChange}
              />
            </div>
          </ModalBody>
        </div>
      </Modal>

      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null amenties__sectionmain"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA amenties__sectionmainner">
          <div className="page_ppf_step1__1icHe  pageComponent">
            <div
              className="amenties-widget_inner"
              style={{ marginBottom: "20px" }}
            >
              <div className="alertBanner_alertBanner__1EZXm alertBanner_lightBlue__26maB amenties-widget_banner pageComponent">
                <i
                  className="iconS_PPF_20 icon_tip alertBanner_alertIcn__2C2MJ"
                  style={{ fontSize: "16px" }}
                >
                  <HiOutlineLightBulb />
                </i>
                <div className="caption_strong_large alertBanner_content__1r5zv">
                  Your property is ready, you can post now or add the following
                  details for better visibility.
                </div>
                <i className="iconS_Common_16 icon_close pageComponent alertBanner_closeIcon__1zCYE">
                  <AiOutlineClose />
                </i>
              </div>
            </div>

            <div className="PrimaryDetailsHeader-desktopHeader">
              <div className="desktopHeader_iconInlineHead__W8_RL">
                <div className="caption_subdued_medium">
                  <span
                    className="desktopHeader_backArrow__1lVxQ iconS_PPFDesk_24 icon_backGrey"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  >
                    <BiArrowBack />
                  </span>
                  <div style={{ fontSize: "14px" }}>Back</div>
                </div>
                <div className="desktopHeader_text__3iyVt">
                  <div className="undefined title_l_semiBold">
                    Add amenities/unique features
                  </div>
                  <div className="caption_subdued_large">
                    All fields on this page are optional
                  </div>
                </div>
              </div>
            </div>

            <div
              id="amenities-input"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div
                  className="widgetbox_header_text__2BXc_"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Amenities
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="false">
                <div
                  className="pagecomponent amenties-widget_inner"
                  id="ownership-input"
                  style={{ textAlign: "left" }}
                >
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        maintenanceStaff === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setMaintenanceStaff(!maintenanceStaff)}
                      id="1"
                    >
                      {maintenanceStaff === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}

                      <span>Maintenance Staff</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        waterStorage === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setWaterStorage(!waterStorage)}
                      id="2"
                    >
                      {waterStorage === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Water Storage</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        wasteDisposal === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setWasteDisposal(!wasteDisposal)}
                      id="3"
                    >
                      {wasteDisposal === true ? (
                        <>
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Waste Disposal</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        rainWaterHarvesting === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() =>
                        setRainWaterHarvesting(!rainWaterHarvesting)
                      }
                      id="4"
                    >
                      {rainWaterHarvesting === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Rain Water Harvesting</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        gasPipes === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setGasPipes(!gasPipes)}
                      id="4"
                    >
                      {gasPipes === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Piped-gas</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        waterpurifier === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setWaterpurifier(!waterpurifier)}
                      id="4"
                    >
                      {waterpurifier === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Water purifier</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        visitorParking === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setVisitorParking(!visitorParking)}
                      id="4"
                    >
                      {visitorParking === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Visitor Parking</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        fengShuiVaastuCompliant === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() =>
                        setFengShuiVaastuCompliant(!fengShuiVaastuCompliant)
                      }
                      id="4"
                    >
                      {fengShuiVaastuCompliant === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Feng Shui / Vaastu Compliant</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        park === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setPark(!park)}
                      id="4"
                    >
                      {park === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Park</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="PropertyFeatures-widget"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div
                  className="widgetbox_header_text__2BXc_"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Property Features
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="false">
                <div
                  className="pagecomponent amenties-widget_inner"
                  id="ownership-input"
                  style={{ textAlign: "left" }}
                >
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        highCeilingHeight === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setHighCeilingHeight(!highCeilingHeight)}
                      id="1"
                    >
                      {highCeilingHeight === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>High Ceiling Height</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        falseCeilingLighting === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() =>
                        setFalseCeilingLighting(!falseCeilingLighting)
                      }
                      id="2"
                    >
                      {falseCeilingLighting === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>False Ceiling Lighting</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        internetwificonnectivity === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() =>
                        setInternetwificonnectivity(!internetwificonnectivity)
                      }
                      id="3"
                    >
                      {internetwificonnectivity === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Internet/wi-fi connectivity</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        centrallyAirConditioned === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() =>
                        setCentrallyAirConditioned(!centrallyAirConditioned)
                      }
                      id="4"
                    >
                      {centrallyAirConditioned === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Centrally Air Conditioned</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        securityFireAlarm === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setSecurityFireAlarm(!securityFireAlarm)}
                      id="4"
                    >
                      {securityFireAlarm === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Security / Fire Alarm</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        recentlyRenovated === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setRecentlyRenovated(!recentlyRenovated)}
                      id="4"
                    >
                      {recentlyRenovated === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Recently Renovated</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        privateGardenTerrace === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() =>
                        setPrivateGardenTerrace(!privateGardenTerrace)
                      }
                      id="4"
                    >
                      {privateGardenTerrace === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Private Garden / Terrace</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        naturalLight === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setNaturalLight(!naturalLight)}
                      id="4"
                    >
                      {naturalLight === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Natural Light</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        airyRooms === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setAiryRooms(!airyRooms)}
                      id="4"
                    >
                      {airyRooms === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Airy Rooms</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        intercomFacility === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setIntercomFacility(!intercomFacility)}
                      id="4"
                    >
                      {intercomFacility === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Intercom Facility</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        spaciousInteriors === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setSpaciousInteriors(!spaciousInteriors)}
                      id="4"
                    >
                      {spaciousInteriors === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Spacious Interiors</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="SocietyFeatures-widget"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div
                  className="widgetbox_header_text__2BXc_"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Society/Building feature
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="false">
                <div
                  className="pagecomponent amenties-widget_inner"
                  id="ownership-input"
                  style={{ textAlign: "left" }}
                >
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        fitnessCentreGYM === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setFitnessCentreGYM(!fitnessCentreGYM)}
                      id="1"
                    >
                      {fitnessCentreGYM === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}

                      <span>Fitness Centre / GYM</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        swimmingPool === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setSwimmingPool(!swimmingPool)}
                      id="2"
                    >
                      {swimmingPool === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Swimming Pool</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        clubhouseCommunityCenter === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() =>
                        setClubhouseCommunityCenter(!clubhouseCommunityCenter)
                      }
                      id="3"
                    >
                      {clubhouseCommunityCenter === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Club house / Community Center</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        securityPersonnel === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setSecurityPersonnel(!securityPersonnel)}
                      id="4"
                    >
                      {securityPersonnel === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Security Personnel</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        lifts === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setLifts(!lifts)}
                      id="4"
                    >
                      {lifts === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Lift(s)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="OtherAmenities-widget"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div
                  className="widgetbox_header_text__2BXc_"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Additional Features
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="false">
                <div
                  className="pagecomponent amenties-widget_inner"
                  id="ownership-input"
                  style={{ textAlign: "left" }}
                >
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        separateentryforservantroom === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() =>
                        setSeparateentryforservantroom(
                          !separateentryforservantroom
                        )
                      }
                      id="1"
                    >
                      {separateentryforservantroom === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}

                      <span>Separate entry for servant room</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        noopendrainagearound === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() =>
                        setNoopendrainagearound(!noopendrainagearound)
                      }
                      id="2"
                    >
                      {noopendrainagearound === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>No open drainage around</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        bankAttachedProperty === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() =>
                        setBankAttachedProperty(!bankAttachedProperty)
                      }
                      id="3"
                    >
                      {bankAttachedProperty === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Bank Attached Property</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        lowDensitySociety === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setLowDensitySociety(!lowDensitySociety)}
                      id="4"
                    >
                      {lowDensitySociety === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Low Density Society</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="WaterSource-widget"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div class="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Water Source
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
                <div className="false">
                  <div
                    className="pagecomponent amenties-widget_inner"
                    id="ownership-input"
                    style={{ textAlign: "left" }}
                  >
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          municipalcorporation === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() =>
                          setMunicipalcorporation(!municipalcorporation)
                        }
                        id="1"
                      >
                        {lowDensitySociety === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}

                        <span>Municipal corporation</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          borewellTank === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() => setBorewellTank(!borewellTank)}
                        id="2"
                      >
                        {borewellTank === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Borewell/Tank</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          twentyfourbysevenWater === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() =>
                          setTwentyfourbysevenWater(!twentyfourbysevenWater)
                        }
                        id="3"
                      >
                        {twentyfourbysevenWater === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>24*7 Water</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="Overlooking-widget"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div class="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Overlooking
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
                <div className="false">
                  <div
                    className="pagecomponent amenties-widget_inner"
                    id="ownership-input"
                    style={{ textAlign: "left" }}
                  >
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          pool === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() => setPool(!pool)}
                        id="1"
                      >
                        {pool === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Pool</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          parkGarden === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() => setParkGarden(!parkGarden)}
                        id="2"
                      >
                        {parkGarden === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Park/Garden</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          mainRoad === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() => setMainRoad(!mainRoad)}
                        id="3"
                      >
                        {mainRoad === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Main Road</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          others === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() => setOthers(!others)}
                        id="3"
                      >
                        {others === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Others</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="OtherFeatures-widget"
              className="pageComponent"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div class="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Other Features
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
              <div
                className="false"
                style={{ textAlign: "left", marginTop: "-20px" }}
              >
                <div id="withinGatedCommunity-input">
                  <div className="toggleInput_checkbox_wrapper__3MxZY  false">
                    <div>
                      <input
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        checked={inagatedsociety === true}
                        onChange={() => setInagatedsociety(!inagatedsociety)}
                      />
                      <label>In a gated society</label>
                    </div>
                  </div>
                </div>

                <div id="withinsemiGatedCommunity-input">
                  <div className="toggleInput_checkbox_wrapper__3MxZY  false">
                    <div>
                      <input
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        checked={inasemigatedsociety === true}
                        onChange={() =>
                          setInasemigatedsociety(!inasemigatedsociety)
                        }
                      />
                      <label>In a Semi-gated society</label>
                    </div>
                  </div>
                </div>
                <div id="cornerProperty-input">
                  <div className="toggleInput_checkbox_wrapper__3MxZY  false">
                    <div>
                      <input
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        checked={cornerProperty === true}
                        onChange={() => setCornerProperty(!cornerProperty)}
                      />
                      <label>Corner Property</label>
                    </div>
                  </div>
                </div>
                <div id="petFriendly-input">
                  <div className="toggleInput_checkbox_wrapper__3MxZY  false">
                    <div>
                      <input
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        checked={petFriendly === true}
                        onChange={() => setPetFriendly(!petFriendly)}
                      />
                      <label>Pet Friendly</label>
                    </div>
                  </div>
                </div>
                <div id="wheelChairFriendly-input">
                  <div className="toggleInput_checkbox_wrapper__3MxZY  false">
                    <div>
                      <input
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        checked={wheelchairfriendly === true}
                        onChange={() =>
                          setWheelchairfriendly(!wheelchairfriendly)
                        }
                      />
                      <label>Wheelchair friendly</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="PowerBackup-widget"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div class="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          PowerBackup
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
                <div className="false" style={{ textAlign: "left" }}>
                  <div className="pagecomponent amenties-widget_inner">
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          powerBackup === "none"
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL "
                        }
                        onClick={() => setpowerBackup("none")}
                        id="1"
                      >
                        <span>none</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          powerBackup === "Partial"
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL "
                        }
                        onClick={() => setpowerBackup("Partial")}
                        id="2"
                      >
                        <span>Partial</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          powerBackup === "Full"
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL "
                        }
                        onClick={() => setpowerBackup("Full")}
                        id="3"
                      >
                        <span>Full</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="Facing-widget"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div class="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Property facing
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
                <div className="false" style={{ textAlign: "left" }}>
                  <div className="pagecomponent amenties-widget_inner">
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          propertyFacing === "North"
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL "
                        }
                        onClick={() => setPropertyFacing("North")}
                        id="1"
                      >
                        <span>North</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          propertyFacing === "South"
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL "
                        }
                        onClick={() => setPropertyFacing("South")}
                        id="2"
                      >
                        <span>South</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          propertyFacing === "East"
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL "
                        }
                        onClick={() => setPropertyFacing("East")}
                        id="3"
                      >
                        <span>East</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          propertyFacing === "West"
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL "
                        }
                        onClick={() => setPropertyFacing("West")}
                        id="3"
                      >
                        <span>West</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          propertyFacing === "North-East"
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL "
                        }
                        onClick={() => setPropertyFacing("North-East")}
                        id="3"
                      >
                        <span>North-East</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          propertyFacing === "North-West"
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL "
                        }
                        onClick={() => setPropertyFacing("North-West")}
                        id="3"
                      >
                        <span>North-West</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          propertyFacing === "South-East"
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL "
                        }
                        onClick={() => setPropertyFacing("South-East")}
                        id="3"
                      >
                        <span>South-East</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          propertyFacing === "South-West"
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL "
                        }
                        onClick={() => setPropertyFacing("South-West")}
                        id="3"
                      >
                        <span>South-West</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="Flooring-widget"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="expandWidget_widgetWrapper__119oa">
                <div className="expandWidget_entitiesWrapper__3b5T1">
                  <div className="widgetbox_header_text__2BXc_">
                    <div className="xidHeading_PageHeadingwrap__3z0kL">
                      <h4>
                        <div class="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                          <span
                            className="xidHeading_headingText__Ulc2a"
                            style={{ display: "inline" }}
                          >
                            Type of flooring
                          </span>
                        </div>
                      </h4>
                    </div>
                  </div>
                  <div className="false">
                    <div id="flooring-input" className="pageComponent">
                      <div className="" style={{ position: "relative" }}>
                        <div
                          className="selectInput_custom_input_wrapper__MqLE9 false  pageComponent"
                          onClick={() =>
                            setTypeofFlooringShow(!typeofFlooringShow)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div className="selectInput_label__Sr6HY caption_subdued_large  "></div>
                          {typeofFlooring !== "" ? (
                            <>
                              <div className="selectInput_custom_input__DdGZD input_placeholder_completed false">
                                {typeofFlooring}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="selectInput_custom_input__DdGZD input_placeholder_completed false">
                                Select
                              </div>
                            </>
                          )}

                          <div className="selectInput_iconsWrapper__1L2kR">
                            <span className="icon_downArrow">
                              <img
                                src="/assets/images/all/arrow_down.png"
                                alt=""
                              />
                            </span>
                            <div className="screeningActions_iconWrapper__dB1NM"></div>
                          </div>
                        </div>
                        {typeofFlooringShow === true ? (
                          <>
                            <div
                              className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown"
                              style={{ top: "100%", bottom: "auto" }}
                            >
                              {typeofFloorArray.map((floor, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    setTypeofFlooring(floor.name);
                                    setTypeofFlooringShow(false);
                                  }}
                                >
                                  <span>{floor.name}</span>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="WidthofRoad-widget"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="expandWidget_widgetWrapper__119oa">
                <div className="expandWidget_entitiesWrapper__3b5T1">
                  <div className="widgetbox_header_text__2BXc_">
                    <div className="xidHeading_PageHeadingwrap__3z0kL">
                      <h4>
                        <div class="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                          <span
                            className="xidHeading_headingText__Ulc2a"
                            style={{ display: "inline" }}
                          >
                            Width of facing road
                          </span>
                        </div>
                      </h4>
                    </div>
                  </div>
                  <div id="Maintenance-widget">
                    <div className="splitWidget_inputFiledWrap__1vX9m  undefined">
                      <div id="maintenance-input" className="pageComponent">
                        <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                          <div className="false">
                            <input
                              type="text"
                              placeholder="Enter the width"
                              value={roadWidth}
                              onChange={(e) =>
                                setRoadWidth(e.target.value.replace(/\D/g, ""))
                              }
                            />
                            <div></div>
                            {/* <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT">
                              <span>Enter the width</span>
                            </div> */}
                            <div className="numberInput_iconsWrapper__3Kpm5">
                              <i></i>
                              <div className="screeningActions_iconWrapper__dB1NM"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="maintenanceCriteria-input"
                        className="pageComponent"
                      >
                        <div>
                          <div
                            className="selectInput_custom_input_wrapper__MqLE9 selectInput_multiple_input__1oijd  pageComponent"
                            onClick={() =>
                              setRoadWidthTypeShow(!roadWidthTypeShow)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                            {roadWidthType !== "" ? (
                              <>
                                {" "}
                                <div
                                  className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf"
                                  style={{ border: "none" }}
                                >
                                  {roadWidthType}
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf"
                                  style={{ border: "none" }}
                                >
                                  Feet
                                </div>
                              </>
                            )}

                            <div className="selectInput_iconsWrapper__1L2kR">
                              <span className="icon_downArrow">
                                <img
                                  src="/assets/images/all/arrow_down.png"
                                  alt=""
                                />
                              </span>
                              <div className="screeningActions_iconWrapper__dB1NM"></div>
                            </div>
                          </div>
                          {roadWidthTypeShow === true ? (
                            <>
                              <div
                                className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown"
                                style={{ top: "100%", bottom: "auto" }}
                              >
                                <div
                                  onClick={() => {
                                    setRoadWidthType("Feet");
                                    setRoadWidthTypeShow(false);
                                  }}
                                >
                                  <span>Feet</span>
                                </div>
                                <div
                                  onClick={() => {
                                    setRoadWidthType("Meter");
                                    setRoadWidthTypeShow(false);
                                  }}
                                >
                                  <span>Meter</span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="LocationAdvantages-widget"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="pageComponent" id="locationAdvantages-input">
                <div className="widgetbox_white_box__1v7xz undefined false false">
                  <div
                    className="widgetbox_header_text__2BXc_"
                    style={{ marginBottom: "-20px" }}
                  >
                    <div className="xidHeading_PageHeadingwrap__3z0kL">
                      <h4>
                        <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                          <span
                            className="xidHeading_headingText__Ulc2a"
                            style={{ display: "inline" }}
                          >
                            Location Advantages
                          </span>
                        </div>
                        <div className="caption_strong_small">
                          <em className="xidHeading_subLabel__1Fc9h">
                            Highlight the nearby landmarks*
                          </em>
                        </div>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="false">
                  <div
                    className="pagecomponent amenties-widget_inner"
                    id="ownership-input"
                    style={{ textAlign: "left" }}
                  >
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          closetoMetroStation === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() =>
                          setClosetoMetroStation(!closetoMetroStation)
                        }
                        id="1"
                      >
                        {closetoMetroStation === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}

                        <span>Close to Metro Station</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          closetoSchool === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() => setClosetoSchool(!closetoSchool)}
                        id="2"
                      >
                        {closetoSchool === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Close to School</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          closetoHospital === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() => setClosetoHospital(!closetoHospital)}
                        id="3"
                      >
                        {closetoHospital === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Close to Hospital</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          closetoMarket === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() => setClosetoMarket(!closetoMarket)}
                        id="4"
                      >
                        {closetoMarket === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Close to Market</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          closetoRailwayStation === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() =>
                          setClosetoRailwayStation(!closetoRailwayStation)
                        }
                        id="4"
                      >
                        {closetoRailwayStation === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Close to Railway Station</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          closetoAirport === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() => setClosetoAirport(!closetoAirport)}
                        id="4"
                      >
                        {closetoAirport === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Close to Airport</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          closetoMall === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() => setClosetoMall(!closetoMall)}
                        id="4"
                      >
                        {closetoMall === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Close to Mall</span>
                      </div>
                    </div>
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className={
                          closetoHighway === true
                            ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                            : "pageComponent radioInput_textOnly__1r7GL"
                        }
                        onClick={() => setClosetoHighway(!closetoHighway)}
                        id="4"
                      >
                        {closetoHighway === true ? (
                          <>
                            {" "}
                            <i className="iconS_Common_20 icon_blueRightTick">
                              <img src="./assets/images/all/tick.png" alt="" />
                            </i>
                          </>
                        ) : (
                          <>
                            <i className="iconS_PPF_20 icon_plusIcon">
                              <BiPlus />
                            </i>
                          </>
                        )}
                        <span>Close to Highway</span>
                      </div>
                    </div>
                    <span
                      className="caption_subdued_small_semi"
                      style={{ marginTop: "16px" }}
                    >
                      *Please provide correct information, otherwise your
                      listing might get blocked
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              id="SuggestedUsp-widget"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div
                  className="widgetbox_header_text__2BXc_"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Suggest a Property Feature
                        </span>
                      </div>
                      <div className="caption_strong_small">
                        <em className="xidHeading_subLabel__1Fc9h">
                          That you want us to add in the form
                        </em>
                      </div>
                    </h4>
                  </div>
                </div>
                <div className="false">
                  <div className="amenties-widget_inner" id="suggestUsp-input">
                    <div className="" style={{ marginTop: "30px" }}>
                      <div className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined">
                        <div className="textInput_iconsWrapper__2cgSX">
                          <div className="screeningActions_iconWrapper__dB1NM"></div>
                        </div>
                        <div className="">
                          <input
                            type="text"
                            required
                            id="undefined-input"
                            className="pageComponent"
                            placeholder="Enter your USP’s here"
                          />
                          <div></div>
                    
                        </div>
                      </div>
                      <span
                        className="caption_subdued_medium"
                        style={{ width: "500px" }}
                      >
                        You can add as many Features seperated by comma
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div id="UpdatedDescriptions-widget">
              <div className="propertyDescription_reviewContainer__Nelwt">
                <div className="widgetbox_white_box__1v7xz propertyDescription_reviewWidget__3dNSY undefined undefined">
                  <div>
                    <div className="widgetbox_header_text__2BXc_">
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <div>
                              <i
                                className="icon_bulbIcon xidHeading_beforeIcn__27xao"
                                style={{ fontSize: "16px", color: "blue" }}
                              >
                                <BsLightbulb />
                              </i>
                              <span
                                className="xidHeading_headingText__Ulc2a"
                                style={{ display: "inline" }}
                              >
                                Updated Description
                              </span>
                            </div>
                          </div>
                        </h4>
                      </div>
                    </div>
                    <p
                      className="caption_strong_large"
                      style={{ marginBottom: "10px" }}
                    >
                      Based on the additional inputs made by you, we have
                      updated your property description
                    </p>
                    <span
                      className="propertyDescription_review__wfOOm hyperlinks_small pageComponet"
                      onClick={(e) => setUnicClick(e)}
                      style={{ cursor: "pointer" }}
                    >
                      Review
                    </span>
                    <div className="propertyDescription_description_box__2ID5j">
                      <textarea
                        className="body_med"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        maxLength="5000"
                        value={uniquePropertyText}
                        onChange={(e) => reviewChange(e)}
                        style={{ height: "200px" }}
                      ></textarea>
                      <div className="propertyDescription_counterText__2A8CV propertyDescription_aLeft__3Im0F badges_large_secondary">
                        {uniquePropertyText.length > 0 ? (
                          <>
                            <span className="undefined">
                              {uniquePropertyText.length}/5000 ( Min. 30
                              characters )
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="undefined">
                              0/5000 ( Min. 30 characters )
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="UspAmenitiesButtonsWrapper-widget"
              style={{ textAlign: "left" }}
            >
              <div id="UspamenitiesButton-button" className="pageComponent">
                <div>
                  <div className="buttonInput_continue_btn__1gb5M ">
                    {buttonLoading === false ? (
                      <>
                        <button
                          type="button"
                          id="PhotoDetailsButton"
                          className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                          onClick={(e) => {
                            // amentiesdetailsClick(e);
                            // propertyPostClick(e);
                            propertyPostPopUpClick(e);
                          }}
                        >
                          <span>
                            <span>Post Property</span>
                          </span>
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          id="PhotoDetailsButton"
                          className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                          // onClick={(e) => {
                          //   // amentiesdetailsClick(e);
                          //   propertyPostClick(e);
                          // }}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span className="mx-2">Post Property</span>
                          <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={[
                              "#e6b800",
                              "#e6b800",
                              "#e6b800",
                              "#e6b800",
                              "#e6b800",
                            ]}
                          />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="rightSection"
        className="rightSection_rightSection__36zjv"
        style={{ minHeight: "calc(100vh - 54px)" }}
      >
        <div className="sectionInfo_contentWrap__35b6q">
          <img
            src="./assets/images/all/Amenities.png"
            alt=""
            style={{ width: "160px" }}
          />
          <div
            className="sectionInfo_textSection__Zm4lg sectionInfo_textCenter__1-nk4"
            style={{ padding: "0px 50px 0px 50px" }}
          >
            <div className="caption_strong_large sectionInfo_subText__1alcp">
              These are the features that buyers look for. Highlighting them
              attracts more responses.
            </div>
          </div>
        </div>
        <div className="needHelp_helpBox__1b_kG" id="helpBox">
          <div className="needHelp_needHelpWrap__1eIlT">
            <div className="needHelp_helpHead__2mtNV">
              <i className="iconS_PPFDesk_16 icon_callIcon">
                <IoMdCall />
              </i>
              <div className="caption_subdued_large">Need help ? </div>
            </div>
            <div className="caption_subdued_medium">
              You can email us at
              <a className="hyperlinks_small"> service@mygoldenhomes.com </a>
              or call us at
              <a className="hyperlinks_small"> 1800 41 99099</a>
              (IND Toll-Free).
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AmentiesSection;
