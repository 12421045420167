import React from "react";

const ChooseProperties = () => {
  return (
    <>
      <section>
        <div className="container">
          {/*about-wrap */}
          <div className="about-wrap">
            <div className="row">
              <div className="col-md-5">
                <div className="about-title ab-hero fl-wrap">
                  <h2>Why Choose Our Properties </h2>
                  <h4>Check video presentation to find out more about us .</h4>
                </div>
                <div className="services-opions fl-wrap">
                  <ul>
                    <li>
                      <i className="fal fa-headset" />
                      <h4>24 Hours Support</h4>
                      <p>
                        Offering 24-hour support for a property website is an
                        indispensable asset that not only elevates user
                        experience but also establishes trust and reliability
                        within the real estate industry.
                      </p>
                    </li>
                    <li>
                      <i className="fal fa-users-cog" />
                      <h4>User Admin Panel</h4>
                      <p>
                        The user admin panel is the control center of our
                        property website, granting users an intuitive interface
                        to manage listings, preferences, and interactions.
                      </p>
                    </li>
                    <li>
                      <i className="fal fa-phone-laptop" />
                      <h4>Mobile Friendly</h4>
                      <p>
                        Our property website is meticulously crafted to be
                        mobile-friendly. Whether you're on a smartphone or
                        tablet, our responsive design ensures a seamless
                        browsing experience. Explore listings, contact sellers,
                        and access vital information effortlessly.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-1" />
              <div className="col-md-6">
                <div className="about-img fl-wrap">
                  <img
                    src="./assets/images/all/27.jpg"
                    className="respimg"
                    alt=""
                  />
                  <div className="about-img-hotifer color-bg">
                    <p>
                      Your website is fully responsive so visitors can view your
                      content from their choice of device.
                    </p>
                    <h4>Mr. Govind</h4>
                    <h5>MG Homes CEO</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* about-wrap end  */}
        </div>
      </section>
    </>
  );
};

export default ChooseProperties;
