import React, { useEffect, useState } from "react";
import { BiPlus, BiMinus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

const ReversedParking = (props) => {
  const { PropertyProfile, BasicDetails } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();
  const [coveredParking, setCoveredParking] = useState(0);
  const [openParking, setOpenParking] = useState(0);

  useEffect(() => {
    if (PropertyProfile !== "") {
      setCoveredParking(PropertyProfile.coveredParking);
      setOpenParking(PropertyProfile.openParking);
    }
  }, [PropertyProfile]);

  return (
    <>
      <div className="ReservedParking-widget">
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <span className="xidHeading_headingText__Ulc2a">
                    Reserved Parking
                  </span>
                  <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                    (Optional)
                  </em>
                </div>
              </h4>
            </div>
          </div>
          <div className="formWidget_counterWrap__1o-7W false">
            <div className="pageComponent" id="coveredParking-input">
              <div className="body_med counter_counterWrap__U0_kk">
                <span>Covered Parking</span>
                <div className="counter_counter_wrapper__amSb0">
                  {coveredParking > 0 ? (
                    <>
                      {" "}
                      <span
                        className="caption_subdued_large"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setCoveredParking(coveredParking - 1);
                          props.getCoverParking(coveredParking - 1);
                        }}
                      >
                        <i>
                          <BiMinus />
                        </i>
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className="caption_subdued_large"
                        style={{
                          cursor: "default",
                          opacity: "0.2",
                          verticalAlign: "middle",
                        }}
                      >
                        <i>
                          <BiMinus />
                        </i>
                      </span>
                    </>
                  )}

                  <span className="caption_strong_large">{coveredParking}</span>
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCoveredParking(coveredParking + 1);
                      props.getCoverParking(coveredParking + 1);
                    }}
                  >
                    <i>
                      <BiPlus />
                    </i>
                  </span>
                </div>
              </div>
            </div>
            <div className="pageComponent" id="openParking-input">
              <div className="body_med counter_counterWrap__U0_kk  ">
                <span>Open Parking</span>
                <div className="counter_counter_wrapper__amSb0">
                  {openParking > 0 ? (
                    <>
                      {" "}
                      <span
                        className="caption_subdued_large"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOpenParking(openParking - 1);
                          props.getOpenParking(openParking - 1);
                        }}
                      >
                        <i>
                          <BiMinus />
                        </i>
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className="caption_subdued_large"
                        style={{
                          cursor: "default",
                          opacity: "0.2",
                          verticalAlign: "middle",
                        }}
                      >
                        <i>
                          <BiMinus />
                        </i>
                      </span>
                    </>
                  )}
                  <span className="caption_strong_large">{openParking}</span>
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenParking(openParking + 1);

                      props.getOpenParking(openParking + 1);
                    }}
                  >
                    <i>
                      <BiPlus />
                    </i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReversedParking;
