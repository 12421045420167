
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const PreferedAgreementTemp = (props) => {
  const { PropertyProfile, PriceotherDetails,singleProperty } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [preferedAgreement, setPreferedAgreement] = useState(singleProperty.preferedAgreement);


  useEffect(() => {
    if (PriceotherDetails !== "") {
      setPreferedAgreement(PriceotherDetails.preferedAgreement);
    }
  }, [PriceotherDetails]);

  return (
    <>
      <div className="Ownership-widget" style={{ marginTop: "10px" }}>
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div
            className="widgetbox_header_text__2BXc_"
            style={{ marginBottom: "-20px" }}
          >
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Preferred Agreement Type{" "}
                  </span>
                </div>
              </h4>
            </div>
            <p style={{ color: "red" }}>{props.preferedAgreementError}</p>
          </div>
        </div>
        <div className="false">
          <div
            className="pagecomponent"
            id="ownership-input"
            style={{ textAlign: "left" }}
          >
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  preferedAgreement === true
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setPreferedAgreement(true);
                  props.getPreferedAgreement(true);
                }}
                id="1"
              >
                <span>Company lease agreement</span>
              </div>
            </div>
            <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
              <div
                className={
                  preferedAgreement === false
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL"
                }
                onClick={() => {
                  setPreferedAgreement(false);
                  props.getPreferedAgreement(false);
                }}
                id="2"
              >
                <span>Any</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreferedAgreementTemp;
