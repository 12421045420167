import React from "react";

const Bhk = () => {
  const bhkapartments = [
    { id: "1", name: "1 RK / 1 BHK" },
    { id: "2", name: "2 BHK" },
    { id: "3", name: "3 BHK" },
    { id: "4", name: "4 BHK" },
    { id: "5", name: "4+ BHK" },
  ];

  return (
    <div className="mainbarfiltercomponents">
      {bhkapartments.map((bhk, index) => (
        <div className="propertyboxes" key={index}>
          <input type="checkbox" />
          <p className="propertytextpara">{bhk.name}</p>
        </div>
      ))}

      <div>
        <p className="clearalltext">Select All</p>
      </div>
    </div>
  );
};

export default Bhk;
