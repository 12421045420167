import moment from "moment";
import React, { useState } from "react";
import { FcApproval, FcSms } from "react-icons/fc";

const ResponseModel = (propertyprop, closePropdet) => {
  const [singleProperty, setSingleProperty] = useState(
    propertyprop.propertyprop
  );
  return (
    <>
      <div>
        <div className="model-container-main main-top col-10">
          <div className="model-container-inner col-5">
            <h3 className="main-head-posted">SEEN BY :</h3>
            <div className="model-container-inner1">
              <h4>{singleProperty.userMobile}</h4>
              <p>{singleProperty.userEmail}</p>
            </div>
          </div>

          <div className="model-container-inner modelinner-sub col-5">
            <h3 className="main-head-posted">
              POSTED ON :{" "}
              {moment(singleProperty.createdAt).format("DD-MMMM-YYYY")}
            </h3>

            <div className="model-container-inner1">
              <h4>
                ₹ {singleProperty.price} | {singleProperty.propertyName}
              </h4>
              <p> {singleProperty.propertyStatus}</p>
            </div>
          </div>
        </div>

        <div className="model-container-main main-bottom col-10">
          <div className="model-container-inner-one col-5">
            <div className="model-container-inner2">
              {singleProperty.yourReason !== "" ? (
                <>
                  <div className="inner2-1">
                    <label htmlFor="">Reason for Buying</label>
                    <span>{singleProperty.yourReason}</span>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResponseModel;
