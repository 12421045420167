import React, { useState } from "react";
import { BsArrowUpShort } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { authActions } from "../../redux/athentication/Athentication";
import { toast } from "react-toastify";
import OwnerPlan from "./wallet/OwnerPlan";
import DealerPlan from "./wallet/DealerPlan";
import BuilderPlan from "./wallet/BuilderPlan";
import BossPlans from "./components/BossPlans";

const Wallet = () => {
  const { loginData, selectBossPlan } = useSelector(
    (store) => store.Athentication
  );

  const dispatch = useDispatch();

  const [amount, setAmount] = useState(0);

  const buyClick = async () => {
    const formdata = {
      mobile: loginData.mobile,
      creadittoAdd: Number(amount),
    };
    const url = `${Baseurl}/api/v1/user/creaditadd`;
    let fetchData = await axios.put(url, formdata);
    try {
      const authenticForm = {
        ...fetchData.data.user,
        isAuth: true,
      };
      dispatch(authActions.signIn(authenticForm));
    } catch (error) {}
  };

  const roleClick = async (e, role, amount, days) => {
    var pdate = new Date();
    pdate.setDate(pdate.getDate() + days);
    const formdata = {
      mobile: loginData.mobile,
      creadittospent: amount,
      role: role,
      userExpire: pdate,
    };
    const url = `${Baseurl}/api/v1/user/shopUserByMobile`;
    let fetchData = await axios.put(url, formdata);
    try {
      const authenticForm = {
        ...fetchData.data.user,
        isAuth: true,
      };
      dispatch(authActions.signIn(authenticForm));
    } catch (error) {}
    if (fetchData.data.success) {
      toast.success("Subscription Added", {
        position: "top-center",
        autoClose: 1500,
      });
    } else {
      //not sufficient balance
      toast.error("Please Recharge on Wallet", {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <div className="wallet_container1">
        <h1 className="wallet_mainhead">Wallet</h1>
        <div className=" walcon ">
          <div className="walsubcon ">
            <div className="walsubcon1">
              <div className="wallet_card_content">
                <h4>Wallet Balance</h4>
                <h3>{loginData.creadits}</h3>
                <p style={{ color: "grren" }}>
                  <span className="wallet_icon" style={{ color: "grren" }}>
                    <BsArrowUpShort />
                    {loginData.role}
                  </span>
                </p>
              </div>
              <div>
                <div>
                  <img
                    src="/assets/images/all/Wallet.png"
                    alt=""
                    height={60}
                    width={60}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="walsubcon ">
            <div className="walsubcon2">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value.replace(/\D/g, ""))}
                minLength={1}
              />
            </div>
            <div className=" wallet_buy_button " onClick={(e) => buyClick(e)}>
              <input type="button" value="Buy" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="wallet_container">
        <h1 className="wallet_mainhead">Wallet</h1>

        <div className="row wallet_cardholder">
          <div className="col-md-5 wallet_card">
            <div className="wallet_card_content">
              <h4>Wallet Balance</h4>
              <h3>{loginData.creadits}</h3>
              <p>
                <span className="wallet_icon">
                  <BsArrowUpShort />
                  {loginData.role}
                </span>
              </p>
            </div>
            <div className="wallet_card_img">
              <img src="/assets/images/all/Wallet.png" alt="" />
            </div>
          </div>
        </div>
        <div className="wallet_bottom row">
          <div className="col-lg-9 wallet_number_input">
            <input
              type="number"
              className="form-control"
              placeholder="Enter Your Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              minLength={1}
            />
          </div>
          <div
            className="col-md-3 wallet_buy_button"
            onClick={(e) => buyClick(e)}
          >
            <input type="button" value="Buy" />
          </div>
        </div>
      </div> */}

      {/* <section
        className="gray-bg small-padding "
        
      >
        <div className="container">
          <div className="pricing-switcher-header pricing-head">
            Subscription Types
          </div>

          <div className="clearfix" />
          <div className="row">
            {loginData.role === "User" ? (
              <div className="col-md-4">
                <div className="pricing-column fl-wrap">
                  <div className="pricing-header">
                    <h3>
                      <span></span>Owner
                    </h3>
                    <div className="price-num price-item fl-wrap">
                      <div className="price-num-item">
                        <span className="mouth-cont">
                          <span className="curen">₹</span>0
                        </span>
                        <span className="year-cont">
                          <span className="curen">₹</span>0
                        </span>
                      </div>
                      <div className="price-num-desc">
                        <span className="mouth-cont">Per Month</span>
                        <span className="year-cont">Per Year</span>
                      </div>
                    </div>
                    <p>Lorem ipsum dolor amet, consectetur adipiscing elit.</p>
                  </div>
                  <div className="pricing-content fl-wrap">
                    <ul className="no-list-style">
                      <li>One Listing</li>
                      <li className="not-included">90 Days Availability</li>
                      <li className="not-included">Non-Featured</li>
                      <li className="not-included">Limited Support</li>
                    </ul>
                    <a
                      className="price-link color-bg fl-wrap"
                      onClick={(e) => roleClick(e, "Owner", 0, 180)}
                    >
                      Choose Free
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {loginData.role === "User" || loginData.role === "Owner" ? (
              <div className="col-md-4">
                <div className="pricing-column fl-wrap">
                  <div className="pricing-header">
                    <h3>
                      <span></span>Dealer
                    </h3>
                    <div className="price-num price-item fl-wrap">
                      <div className="price-num-item">
                        <span className="mouth-cont">
                          <span className="curen">Creadits</span>500
                        </span>
                      </div>
                    </div>

                    <p style={{ color: "#000" }}>Lorem ipsum dolor amet, consectetur adipiscing elit.</p>
                  </div>
                  <div className="pricing-content fl-wrap">
                    <ul className="no-list-style">
                      <li>Unlimited Listings</li>
                      <li>180 Days Availability</li>
                      <li>Non-Featured</li>
                      <li>Limited Support</li>
                    </ul>
                    <a
                      className="price-link color-bg fl-wrap"
                      onClick={(e) => roleClick(e, "Dealer", 500, 180)}
                    >
                      Choose Extended
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {loginData.role === "User" ||
              loginData.role === "Owner" ||
              loginData.role === "Dealer" ? (
              <div className="col-md-4">
                <div className="pricing-column fl-wrap">
                  <div className="pricing-header">
                    <h3>
                      <span></span>Builder
                    </h3>
                    <div className="price-num price-item fl-wrap">
                      <div className="price-num-item">
                        <span className="mouth-cont">
                          <span className="curen">Creadits</span>1000
                        </span>
                      </div>
                    </div>
                    <p>Lorem ipsum dolor amet, consectetur adipiscing elit.</p>
                  </div>
                  <div className="pricing-content fl-wrap">
                    <ul className="no-list-style">
                      <li>Unlimited Listings</li>
                      <li>365 days Availability</li>
                      <li>Featured In Search Results</li>
                      <li>24/7 Support</li>
                    </ul>
                    <a
                      className="price-link color-bg fl-wrap"
                      onClick={(e) => roleClick(e, "Builder", 1000, 365)}
                    >
                      Choose Premium
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section> */}

      {/* <OwnerPlan /> */}
      <BossPlans />
      {/* <DealerPlan />
      <BuilderPlan /> */}
    </>
  );
};

export default Wallet;
