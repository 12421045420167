import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiInfoCircle, BiChevronUp, BiPlus } from "react-icons/bi";
import { BsQuestionCircle } from "react-icons/bs";
import {
  setPostPropertyStepsStatus,
  setPriceotherDetails,
} from "../../../redux/property/PropertySlice";

const percentage = 66;

const PriceDetails = (props) => {
  const { PropertyProfile, PriceotherDetails, BasicDetails } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();
  const [expectedPrice, setExpectedPrice] = useState("");
  const [pricePerSqft, setPricePerSqft] = useState(0);
  const [wordValue, setWordValue] = useState("");

  const [morePriceDetailsShow, setMorePriceDetailsShow] = useState(false);
  const [allinclusiveprice, setAllinclusiveprice] = useState(false);
  const [taxandgovtchargesexcluded, setTaxandGovtChargesExcluded] =
    useState(false);
  const [priceNegotiable, setPriceNegotiable] = useState(false);

  const [maintenance, setMaintenance] = useState("");
  const [maintenanceMonthly, setMaintenanceMonthly] = useState("Monthly");
  const [maintenanceMonthlyShow, setMaintenanceMonthlyShow] = useState("");
  const [bookingAmount, setBookingAmount] = useState("");
  const [annualDuesPayable, setAnnualDuesPayable] = useState("");
  const [membershipCharges, setMembershipCharges] = useState("");


  //errors
  const [priceDetailsError, setPriceDetailsError] = useState("");

  useEffect(() => {
    if (Number(expectedPrice) > 0) {
      if (PropertyProfile.superBuiltupArea > 0) {
        let value =
          Number(expectedPrice) / Number(PropertyProfile.superBuiltupArea);
        value = Math.round(value);
        setPricePerSqft(value);
        props.pricepersqftPress(value);
      } else {
        let value = Number(expectedPrice) / Number(PropertyProfile.carpetArea);
        value = Math.round(value);
        setPricePerSqft(value);
        props.pricepersqftPress(value);
      }
    }
  }, [PropertyProfile, expectedPrice]);

  useEffect(() => {
    if (PriceotherDetails !== "") {
      setExpectedPrice(PriceotherDetails.expectedPrice);
      props.handleExpectedPrice(PriceotherDetails.expectedPrice);
      setPricePerSqft(PriceotherDetails.pricePerSqft);
      props.pricepersqftPress(PriceotherDetails.pricePerSqft);

      setMorePriceDetailsShow(PriceotherDetails.morePriceDetailsShow);
      setAllinclusiveprice(PriceotherDetails.allinclusiveprice);
      setTaxandGovtChargesExcluded(PriceotherDetails.taxandgovtchargesexcluded);
      setPriceNegotiable(PriceotherDetails.priceNegotiable);

      setMaintenance(PriceotherDetails.maintenance);
      setMaintenanceMonthly(PriceotherDetails.maintenanceMonthly);
      setMaintenanceMonthlyShow(PriceotherDetails.maintenanceMonthlyShow);
      setBookingAmount(PriceotherDetails.bookingAmount);
      setAnnualDuesPayable(PriceotherDetails.annualDuesPayable);
      setMembershipCharges(PriceotherDetails.membershipCharges);
    }
  }, [PriceotherDetails]);

  const maintenancearray = [
    { name: "Monthly" },
    { name: "Annually" },
    { name: "One Time" },
    { name: "Per Unit / Monthly" },
  ];

  useEffect(() => {
    if (expectedPrice !== "") {
      setPriceDetailsError("");
    }
  }, [expectedPrice]);

  const convertNumberToWord = (num) => {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (num === 0) {
      return "Zero";
    } else {
      return convertRecursive(num);
    }
  };

  const convertToWord = (e) => {
    let inputValue = e.target.value.replace(/\D/g, "");
    setExpectedPrice(inputValue);
    props.handleExpectedPrice(inputValue);
    const num = Number(inputValue);
    if (isNaN(num)) {
      setWordValue("Invalid input");
      return;
    }
    const convertedWord = convertNumberToWord(num);
    setWordValue(convertedWord);
  };

  const convertRecursive = (num) => {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (num < 10) {
      return units[num];
    } else if (num < 20) {
      return teens[num - 10];
    } else if (num < 100) {
      const tensDigit = Math.floor(num / 10);
      const onesDigit = num % 10;
      return tens[tensDigit] + (onesDigit > 0 ? " " + units[onesDigit] : "");
    } else if (num < 1000) {
      const hundredsDigit = Math.floor(num / 100);
      const remainder = num % 100;
      return (
        units[hundredsDigit] +
        " Hundred" +
        (remainder > 0 ? " and " + convertRecursive(remainder) : "")
      );
    } else if (num < 100000) {
      const thousands = Math.floor(num / 1000);
      const remainder = num % 1000;
      return (
        convertRecursive(thousands) +
        " Thousand" +
        (remainder > 0 ? " " + convertRecursive(remainder) : "")
      );
    } else if (num < 10000000) {
      const millions = Math.floor(num / 100000);
      const remainder = num % 100000;
      return (
        convertRecursive(millions) +
        " Lakh" +
        (remainder > 0 ? " " + convertRecursive(remainder) : "")
      );
    } else if (num < 1000000000) {
      const billions = Math.floor(num / 10000000);
      const remainder = num % 10000000;
      return (
        convertRecursive(billions) +
        " Crore" +
        (remainder > 0 ? " " + convertRecursive(remainder) : "")
      );
    } else {
      return "Number too large to convert";
    }
  };

  return (
    <>
      <div className="Pricing-widget">
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div class="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Price Details
                  </span>
                </div>
              </h4>
            </div>
            <p style={{ color: "red" }}>{props.priceDetailsError}</p>
          </div>
          <div className="false">
            <div id="PriceFieldsWrapper-widget">
              <div className="dummyFormWidget_inlineContainer__12ide">
                <div id="PriceWrapper-widget">
                  <div>
                    <div className="pageComponent" id="price-input">
                      <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                        <div className="false">
                          <input
                            type="text"
                            value={expectedPrice}
                            onChange={(e) => {
                              convertToWord(e);
                            }}
                            inputMode="decimal"
                            placeholder="₹ Expected Price"
                          />
                          {/* <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT">
                                  <span>₹ Expected Price</span>
                                </div> */}
                        </div>
                        <div className="numberInput_iconsWrapper__3Kpm5">
                          <i className="iconS_PPFDesk_24 icon_redInfo">
                            <BiInfoCircle />
                          </i>
                          <i></i>
                          <div className="screeningActions_iconWrapper__dB1NM"></div>
                        </div>
                      </div>
                    </div>
                    <div id="priceInWords-input">
                      {wordValue !== "" && (
                        <div className="input_placeholder_inactive multiple_input">
                          ₹ Price in words {wordValue}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div id="PricePerUnitAreaWrapper-widget">
                  <div className="pageComponent" id="pricePerUnitArea-input">
                    <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                      <div className="false">
                        <input
                          type="text"
                          inputMode="decimal"
                          value={pricePerSqft}
                          onChange={(e) => {
                            setPricePerSqft(
                              Math.round(e.target.value.replace(/\D/g, ""))
                            );
                            props.pricepersqftPress(
                              Math.round(e.target.value.replace(/\D/g, ""))
                            );
                          }}
                          placeholder="₹ Price per sq.ft."
                        />
                      </div>
                      <div className="numberInput_iconsWrapper__3Kpm5">
                        <i className="iconS_PPFDesk_24 icon_redInfo">
                          <BiInfoCircle />
                        </i>
                        <i></i>
                      </div>
                    </div>
                    <div id="priceInWords-input">
                      <div className="input_placeholder_inactive multiple_input">
                        ₹ Price per sq.ft.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="priceRangeText-input">
              <div className="caption_strong_medium"></div>
            </div>
            <div className="PriceIncludeFieldsWrapper-widget">
              <div
                className="dummyFormWidget_inlineContainer__12ide"
                style={{ textAlign: "left", marginBottom: "20px" }}
              >
                <div className="pageComponent" id="inclusive-input">
                  <div className="toggleInput_checkbox_wrapper__3MxZY  false">
                    <div>
                      <input
                        type="checkbox"
                        checked={allinclusiveprice === true}
                        onChange={() => {
                          setAllinclusiveprice(!allinclusiveprice);
                          props.handleInclusivePricePress(!allinclusiveprice);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <label>All inclusive price</label>
                      <div className="iconTooltip_infoWrapper__3Rn4H">
                        <i
                          className="iconS_PPFDesk_16 icon_question undefined"
                          style={{ fontSize: "12px" }}
                        >
                          <BsQuestionCircle />
                        </i>
                        <div className="iconTooltip_toolTipWrap__124Co iconTooltip_tooltipHover__2FrOE iconTooltip_outerContainer__1akhw body_small">
                          <div>
                            <div className="PriceInclusiveInfo_pageHeading__2obr9">
                              <div className="xidHeading_PageHeadingwrap__3z0kL">
                                <h4>
                                  <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                                    <div>
                                      <span
                                        className="xidHeading_headingText__Ulc2a"
                                        style={{ display: "inline" }}
                                      >
                                        All Inclusive Price
                                      </span>
                                    </div>
                                  </div>
                                </h4>
                              </div>
                            </div>
                            <p className="body_med PriceInclusiveInfo_subHeading__3A8I4">
                              An all inclusive price includes the following:
                            </p>
                            <ul className="body_med PriceInclusiveInfo_inclusivePrices__1SkAT">
                              <li>Base Price</li>
                              <li>Floor and Location PLC</li>
                              <li>Car Parking charges</li>
                              <li>Developmental Charges like EDC, IDC</li>
                              <li>Lease Rent</li>
                              <li>Maintenance deposit</li>
                              <li>
                                Infrastructure charges like External
                                Electrification Fire fighting etc
                              </li>
                              <li>Amenities charges like intercom, water</li>
                              <li>Club Membership</li>
                              <li>Power Back-up</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pageComponent" id="taxGovtCharges-input">
                  <div className="toggleInput_checkbox_wrapper__3MxZY  false">
                    <div>
                      <input
                        type="checkbox"
                        checked={taxandgovtchargesexcluded === true}
                        onChange={() => {
                          setTaxandGovtChargesExcluded(
                            !taxandgovtchargesexcluded
                          );
                          props.handletaxGovtPress(!taxandgovtchargesexcluded);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <label>Tax and Govt. charges excluded</label>
                    </div>
                  </div>
                </div>
                <div className="pageComponent" id="negotiable-input">
                  <div className="toggleInput_checkbox_wrapper__3MxZY  false">
                    <div>
                      <input
                        type="checkbox"
                        checked={priceNegotiable === true}
                        onChange={() => {
                          setPriceNegotiable(!priceNegotiable);
                          props.handlePricePress(!priceNegotiable);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <label>Price Negotiable</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {morePriceDetailsShow === true ? (
              <>
                <div id="PricingDetails-widget" style={{ marginTop: "10px" }}>
                  <div className="expandWidget_widgetWrapper__119oa">
                    <div className="expandWidget_entitiesWrapper__3b5T1">
                      <p className="list_header_semiBold expandWidget_label__1fP-8">
                        Additional Pricing Details
                        <em
                          className="caption_subdued_large"
                          style={{ display: "inline-block" }}
                        >
                          (Optional)
                        </em>
                      </p>
                      <div id="Maintenance-widget">
                        <div className="splitWidget_inputFiledWrap__1vX9m  undefined">
                          <div id="maintenance-input" className="pageComponent">
                            <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                              <div className="false">
                                <input
                                  type="text"
                                  placeholder="Maintenance"
                                  value={maintenance}
                                  onChange={(e) => {
                                    setMaintenance(
                                      e.target.value.replace(/\D/g, "")
                                    );
                                    props.morePricingDetails(
                                      e.target.value.replace(/\D/g, "")
                                    );
                                  }}
                                />
                                <div></div>
                                {/* <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT">
                                        <span>Maintenance</span>
                                      </div> */}
                                <div className="numberInput_iconsWrapper__3Kpm5">
                                  <i></i>
                                  <div className="screeningActions_iconWrapper__dB1NM"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="maintenanceCriteria-input"
                            className="pageComponent"
                          >
                            <div>
                              <div
                                className="selectInput_custom_input_wrapper__MqLE9 selectInput_multiple_input__1oijd  pageComponent"
                                onClick={() => {
                                  setMaintenanceMonthlyShow(
                                    !maintenanceMonthlyShow
                                  );
                                  // props.setMaintenanceMonthly(
                                  //   !maintenanceMonthlyShow
                                  // );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                                <div
                                  className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf"
                                  style={{ border: "none" }}
                                >
                                  {maintenanceMonthly}
                                </div>
                                <div className="selectInput_iconsWrapper__1L2kR">
                                  <span className="icon_downArrow">
                                    <img
                                      src="/assets/images/all/arrow_down.png"
                                      alt=""
                                    />
                                  </span>
                                  <div className="screeningActions_iconWrapper__dB1NM"></div>
                                </div>
                              </div>
                              {maintenanceMonthlyShow === true ? (
                                <>
                                  <div
                                    className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown"
                                    // style={{ top: "auto", bottom: "100%" }}
                                  >
                                    {maintenancearray.map((mentaina, index) => (
                                      <div
                                        key={index}
                                        onClick={() => {
                                          setMaintenanceMonthlyShow(false);
                                          setMaintenanceMonthly(mentaina.name);
                                          props.setMaintenanceMonthly(
                                            mentaina.name
                                          );
                                        }}
                                      >
                                        <span>{mentaina.name}</span>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="bookingAmount-input">
                        <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                          <div className="false">
                            <input
                              type="text"
                              placeholder="Booking Amount"
                              value={bookingAmount}
                              onChange={(e) => {
                                setBookingAmount(
                                  e.target.value.replace(/\D/g, "")
                                );

                                props.setBookingAmount(
                                  e.target.value.replace(/\D/g, "")
                                );
                              }}
                            />
                            <div></div>
                            {/* <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT">
                                    <span>Booking Amount</span>
                                  </div> */}
                            <div className="numberInput_iconsWrapper__3Kpm5">
                              <i></i>
                              <div className="screeningActions_iconWrapper__dB1NM"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div id="annualDues-input">
                        <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                          <div className="false">
                            <input
                              type="text"
                              placeholder="Annual dues payable"
                              value={annualDuesPayable}
                              onChange={(e) => {
                                setAnnualDuesPayable(
                                  e.target.value.replace(/\D/g, "")
                                );
                                props.morePricingDetails(
                                  e.target.value.replace(/\D/g, "")
                                );
                              }}
                            />
                            <div></div>
                           
                            <div className="numberInput_iconsWrapper__3Kpm5">
                              <i></i>
                              <div className="screeningActions_iconWrapper__dB1NM"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="membership-input">
                        <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                          <div className="false">
                            <input
                              type="text"
                              placeholder="Membership charge"
                              value={membershipCharges}
                              onChange={(e) => {
                                setMembershipCharges(
                                  e.target.value.replace(/\D/g, "")
                                );
                                props.morePricingDetails(
                                  e.target.value.replace(/\D/g, "")
                                );
                              }}
                            />

                            <div className="numberInput_iconsWrapper__3Kpm5">
                              <i></i>
                              <div className="screeningActions_iconWrapper__dB1NM"></div>
                            </div>
                          </div>
                        </div>
                        <div id="layers">
                          <div className="Bottomsheet_pageTrayBottom__2yDEW my_node-enter-done">
                            <div
                              id="bottomSheet"
                              className="Bottomsheet_PageTray__gP3CR  undefined"
                            >
                              <div className="Bottomsheet_PageTrayhead__3pvka">
                                <i className="iconS_Common_24 icon_close">
                                  <img
                                    src="./assets/images/all/closebtn.png"
                                    alt=""
                                  />
                                </i>
                                <i className="iconS_Common_24 icon_close"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <span className="hyperlinks_medium d-inline-block">
                      <span
                        className="pageComponent"
                        onClick={() =>
                          setMorePriceDetailsShow(!morePriceDetailsShow)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className="iconS_PPFDesk_16 icon_bluePlusIcon expandWidget_addMoreIcon__1_5h-"
                          style={{
                            verticalAlign: "middle",
                            fontSize: "18px",
                          }}
                        >
                          <BiChevronUp />
                        </i>
                        <span
                          className="expandWidget_toggleLabel__3sbvc"
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Add more pricing details
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <span className="hyperlinks_medium d-inline-block">
                  <span
                    className="pageComponent"
                    onClick={() =>
                      setMorePriceDetailsShow(!morePriceDetailsShow)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className="iconS_PPFDesk_16 icon_bluePlusIcon expandWidget_addMoreIcon__1_5h-"
                      style={{
                        verticalAlign: "middle",
                        fontSize: "18px",
                      }}
                    >
                      <BiPlus />
                    </i>
                    <span
                      className="expandWidget_toggleLabel__3sbvc"
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      Add more pricing details
                    </span>
                  </span>
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceDetails;
