
import React, { useEffect, useState } from "react";
import { BiArrowBack, BiPlus, BiMinus, BiInfoCircle } from "react-icons/bi";
import { GrCircleQuestion } from "react-icons/gr";
import { Tooltip } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setPostPropertyStepsStatus, setPropertyProfile } from "../../../../redux/property/PropertySlice";
import AddroomHospitalTemp from "../commonComponent/AddroomHospitalTemp";
import AddAreaDetailsTemp from "../commonComponent/AddAreaDetailsTemp";
import OtherRoomsTemp from "../commonComponent/OtherRoomsTemp";
import FurnishingTemp from "../commonComponent/FurnishingTemp";
import AvaliableFromTemp from "../commonComponent/AvaliableFromTemp";
import AvaliabilityStatusTemp from "../commonComponent/AvaliabilityStatusTemp";
import QualityOfRatingTemp from "../commonComponent/QualityOfRatingTemp";

const HospitalityPropertyDetailsSellTemp = () => {
  const { PropertyProfile, BasicDetails,singleProperty } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();

  const [noofrooms, setNoofrooms] = useState(singleProperty.noofBedrooms);
  const [addmoreBedroomsvalue, setAddmoreBedroomsValue] = useState("");
  const [addmoreBedroomsShow, setAddmoreBedroomsShow] = useState(false);

  const [noofBathrooms, setNoofBathrooms] = useState(singleProperty.noofBathrooms);
  const [addmoreBathroomsvalue, setAddmoreBathroomsValue] = useState("");
  const [addmoreBathroomsShow, setAddmoreBathroomsShow] = useState(false);

  const [carpetArea, setCarpetArea] = useState(singleProperty.carpetArea);
  const [typeofCarpetArea, setTypeofCarpetArea] = useState(singleProperty.carpetAreaMesurmentType);
  const [typeofCarpetAreaShow, setTypeofCarpetAreaShow] = useState(false);

  const [builtupArea, setBuiltupArea] = useState(singleProperty.buildupArea);
  const [typeofBuiltupArea, setTypeofBuiltupArea] = useState(singleProperty.buildupAreaMesurmentType);
  const [typeofBuiltupAreaShow, setTypeofBuiltupAreaShow] = useState(false);
  const [typeofBuiltupAreaBox, setTypeofBuiltupAreaBox] = useState(false);

  const [superBuiltupArea, setSuperBuiltupArea] = useState(singleProperty.superbuildupArea);
  const [typeofSuperBuiltupArea, setTypeofSuperBuiltupArea] =
    useState(singleProperty.superbuildupAreaMesurmentType);
  const [typeofSuperBuiltupAreaShow, setTypeofSuperBuiltupAreaShow] =
    useState(false);
  const [typeofSuperBuiltupAreaBox, setTypeofSuperBuiltupAreaBox] =
    useState(false);

    const [poojaroom, setPoojaroom] = useState(singleProperty.poojaRoom);
    const [studyroom, setStudyroom] = useState(singleProperty.studyRoom);
    const [servantroom, setServantroom] = useState(singleProperty.servantRoom);
    const [storeroom, setStoreroom] = useState(singleProperty.storeRoom);  

  const [furnish, setFurnish] = useState(Boolean(0));
  const [semifurnish, setSemifurnish] = useState(Boolean(0));
  const [unfurnish, setUnfurnish] = useState(Boolean(1));
  const [furnishedStatus, setFurnishedStatus] = useState("Un-furnished");

  const [light, setLight] = useState(singleProperty.numberoflight);
  const [fans, setFans] = useState(singleProperty.numberoffans);
  const [ac, setAc] = useState(singleProperty.numberofac);
  const [tv, setTv] = useState(singleProperty.numberoftv);
  const [beds, setBeds] = useState(singleProperty.numberofbeds);
  const [wardrobe, setWardrobe] = useState(singleProperty.numberofwardrobe);
  const [geyser, setGeyser] = useState(singleProperty.numberofgeyser);
  const [sofa, setSofa] = useState(singleProperty.sofa);
  const [washingMachine, setWashingMachine] = useState(singleProperty.washingMachine);
  const [stove, setStove] = useState(singleProperty.stove);
  const [fridge, setFridge] = useState(singleProperty.fridge);
  const [waterPurifier, setWaterPurifier] = useState(singleProperty.waterPurifier);
  const [microwave, setMicrowave] = useState(singleProperty.microwave);
  const [modularKitchen, setModularKitchen] = useState(singleProperty.modularKitchen);
  const [chimney, setChimney] = useState(singleProperty.chimney);
  const [dinningTable, setDinningTable] = useState(singleProperty.dinningTable);
  const [curtains, setCurtains] = useState(singleProperty.curtains);
  const [exhaustFan, setExhaustFan] = useState(singleProperty.exhaustFan);

  const [readyToMove, setReadyToMove] = useState(false);
  const [underConstruction, setUnderConstruction] = useState(false);
  const [abilityStatus, setAbilityStatus] = useState(false);

  const [ageofProperty, setAgeofProperty] = useState(singleProperty.propertyAge);
  const [availableDate, setavailableDate] = useState(singleProperty.availableDate);

  const [expectedby, setExpectedby] = useState(singleProperty.propertyPossessionBy);
  const [expectedbyShow, setExpectedbyShow] = useState(false);
  const [yearofExpectedBy, setYearofExpectedBy] = useState("");

  const [noofRating, setNoofRating] = useState(singleProperty.noofRating);
  //errors

  const [roomDetailsError, setRoomDetailsError] = useState("");
  const [noofRoomsError, setNoofRoomsError] = useState("");
  const [noofWashroomsError, setNoofWashroomsError] = useState("");

  const [areaDetailsError, setAreaDetailsError] = useState("");
  const [floorDetailsError, setFloorDetailsError] = useState("");
  const [abilityError, setAbilityError] = useState("");

  const [availableFromError, setAvailableFromError] = useState("");

  const [qualityRatingError, setQualityRatingError] = useState("");
  const [furnishingError, SetFurnishingError] = useState("");

  const [iscontent, setIsContent] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [availabilityError, setavailabilityError] = useState("");

  const [selectAgeError, setSelectedAgeError] = useState("");
  const [timePossessionError, settimePossessionError] = useState("");

  const carpetAreaChange = (e) => {
    const result = e;
    setCarpetArea(result);
  };

  const builtupChange = (e) => {
    const result = e;
    setBuiltupArea(result);
  };

  const superbuiltupChange = (e) => {
    const result = e;
    setSuperBuiltupArea(result);
  };

  const carpetAreaMesurment = (e) => {
    const result = e;
    setTypeofCarpetArea(result);
  };

  const builtupMesurment = (e) => {
    const result = e;
    setTypeofBuiltupArea(result);
  };
  const superbuiltupMesurment = (e) => {
    const result = e;
    setTypeofSuperBuiltupArea(result);
  };

  const getpoojaroom = (value) => {
    setPoojaroom(value);
  };
  const getstudyroom = (value) => {
    setStudyroom(value);
  };
  const getservantroom = (value) => {
    setServantroom(value);
  };
  const getstoreroom = (value) => {
    setStoreroom(value);
  };

  const getFurnishingDetails = (value) => {
    setFurnishedStatus(value);
  };

  const getFurnishingType = (value) => {
    setFurnishedStatus(value);
    setWardrobe(value.wardrobe);
    setAc(value.ac);
    setChimney(value.chimney);
    setGeyser(value.geyser);
    setMicrowave(value.microwave);
    setLight(value.light);
    setFans(value.fan);
    setTv(value.tv);
    setBeds(value.beds);
    setCurtains(value.curtain);

    setExhaustFan(value.exhaustfan);
    // setStudyTable(value.studyTable);
    setModularKitchen(value.modularKitchen);
    setWashingMachine(value.washingMachine);
    setStove(value.stove);
    setFridge(value.fridge);
    setDinningTable(value.dinningtable);
    setWaterPurifier(value.waterpurifier);
    setSofa(value.sofa);
  };

  const getPropertyAvailability = (value) => {
    setAbilityStatus(value);
  };
  const getPropertyAge = (value) => {
    setAgeofProperty(value);
  };
  const getPropertyExpectedYear = (value) => {
    setYearofExpectedBy(value);
  };
  const getPropertyExpectedMonth = (value) => {
    setExpectedby(value);
  };
  const constructionOnProperty = (e) => {
    const result = e;
    setUnderConstruction(result);
  };
  const availableFromDate = (e) => {
    const result = e;
    setavailableDate(result);
  };

  const numberOfRooms = (e) => {
    const result = e;
    setNoofrooms(result);
  };
  const numberOfBathroom = (e) => {
    const result = e;
    setNoofBathrooms(result);
  };

  const addMoreBedroom = (e) => {
    const result = e;
    setAddmoreBedroomsValue(result);
  };
  const addMoreBedroomShow = (e) => {
    const result = e;
    setAddmoreBedroomsShow(result);
  };
  const addMoreBedroomValue = (e) => {
    const result = e;
    setAddmoreBedroomsValue(result);
  };
  const addmoreBathrooms = (e) => {
    const result = e;
    setNoofBathrooms(result);
  };
  const addMoreBathroomValue = (e) => {
    const result = e;
    setAddmoreBathroomsValue(result);
  };
  const addMoreBathroomShow = (e) => {
    const result = e;
    setAddmoreBathroomsShow(result);
  };

  const noOfRating = (e) => {
    const result = e;
    setNoofRating(result);
  };

  useEffect(() => {
    if (PropertyProfile !== "") {
      setNoofrooms(PropertyProfile.noofrooms);
      setAddmoreBedroomsValue(PropertyProfile.addmoreBedroomsvalue);
      setAddmoreBedroomsShow(PropertyProfile.addmoreBedroomsShow);

      setNoofBathrooms(PropertyProfile.noofBathrooms);
      setAddmoreBathroomsValue(PropertyProfile.addmoreBathroomsvalue);
      setAddmoreBathroomsShow(PropertyProfile.addmoreBathroomsShow);

      setCarpetArea(PropertyProfile.carpetArea);
      setTypeofCarpetArea(PropertyProfile.typeofCarpetArea);
      setTypeofCarpetAreaShow(PropertyProfile.typeofCarpetAreaShow);

      setBuiltupArea(PropertyProfile.builtupArea);
      setTypeofBuiltupArea(PropertyProfile.typeofBuiltupArea);
      setTypeofBuiltupAreaShow(PropertyProfile.typeofBuiltupAreaShow);
      setTypeofBuiltupAreaBox(PropertyProfile.typeofBuiltupAreaBox);

      setSuperBuiltupArea(PropertyProfile.superBuiltupArea);
      setTypeofSuperBuiltupArea(PropertyProfile.typeofSuperBuiltupArea);
      setTypeofSuperBuiltupAreaShow(PropertyProfile.typeofSuperBuiltupAreaShow);
      setTypeofSuperBuiltupAreaBox(PropertyProfile.typeofSuperBuiltupAreaBox);

      setPoojaroom(PropertyProfile.poojaroom);
      setStudyroom(PropertyProfile.studyroom);
      setServantroom(PropertyProfile.servantroom);
      setStoreroom(PropertyProfile.storeroom);

      setFurnish(PropertyProfile.furnish);
      setSemifurnish(PropertyProfile.semifurnish);
      setUnfurnish(PropertyProfile.unfurnish);

      setLight(PropertyProfile.light);
      setFans(PropertyProfile.fans);
      setAc(PropertyProfile.ac);
      setTv(PropertyProfile.tv);
      setBeds(PropertyProfile.beds);
      setWardrobe(PropertyProfile.wardrobe);
      setGeyser(PropertyProfile.geyser);
      setSofa(PropertyProfile.sofa);
      setWashingMachine(PropertyProfile.washingMachine);
      setStove(PropertyProfile.stove);
      setFridge(PropertyProfile.fridge);
      setWaterPurifier(PropertyProfile.waterPurifier);
      setMicrowave(PropertyProfile.microwave);
      setModularKitchen(PropertyProfile.modularKitchen);
      setChimney(PropertyProfile.chimney);
      setDinningTable(PropertyProfile.dinningTable);
      setCurtains(PropertyProfile.curtains);
      setExhaustFan(PropertyProfile.exhaustFan);

      setReadyToMove(PropertyProfile.readyToMove);
      setUnderConstruction(PropertyProfile.underConstruction);

      setAgeofProperty(PropertyProfile.ageofProperty);

      setExpectedby(PropertyProfile.expectedby);
      setExpectedbyShow(PropertyProfile.expectedbyShow);

      setNoofRating(PropertyProfile.noofRating);
    }
  }, [PropertyProfile]);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    if (noofrooms !== "" && noofBathrooms !== "") {
      setRoomDetailsError("");
    }
    if (noofrooms !== "") {
      setNoofRoomsError("");
    }
    if (noofBathrooms !== "") {
      setNoofWashroomsError("");
    }
    if (carpetArea !== "") {
      setAreaDetailsError("");
    }
    if (availableDate !== "") {
      setAvailableFromError("");
    }
    if (abilityStatus === true) {
      setavailabilityError("");
    }
    if (ageofProperty !== "" || expectedby !== "") {
      setSelectedAgeError("");
      settimePossessionError("");
    }
    if (noofRating !== "") {
      setQualityRatingError("");
    }
  }, [
    noofrooms,
    noofBathrooms,
    carpetArea,
    availableDate,
    abilityStatus,
    ageofProperty,
    expectedby,
    noofRating,
  ]);

  const propertyProfileClick = async (e) => {
    e.preventDefault();
    setRoomDetailsError("");
    setNoofRoomsError("");
    setNoofWashroomsError("");
    setAreaDetailsError("");
    setAvailableFromError("");
    setavailabilityError("");
    setQualityRatingError("");

    if (noofrooms !== "" || noofBathrooms !== "") {
      setRoomDetailsError("");
    } else {
      setRoomDetailsError("All fields are required");
    }

    if (noofrooms !== "") {
      setNoofRoomsError("");
    } else {
      setNoofRoomsError("Please Select No of Rooms ");
    }
    if (noofBathrooms !== "") {
      setNoofWashroomsError("");
    } else {
      setNoofWashroomsError("Please Select No of Washrooms");
    }
    if (carpetArea !== "") {
      setAreaDetailsError("");
    } else {
      setAreaDetailsError("Enter Area Details");
    }
    if (availableDate !== "") {
      setAvailableFromError("");
    } else {
      setAvailableFromError("Selact Available From ");
    }

    if (ageofProperty !== "" || expectedby !== "") {
      setavailabilityError("");
      setSelectedAgeError("");
      settimePossessionError("");
    } else {
      setavailabilityError("Selact Availibility Status ");
      setSelectedAgeError("Select Property Age");
      settimePossessionError("Select Expected Date");
    }
    if (noofRating !== "") {
      setQualityRatingError("");
    } else {
      setQualityRatingError("Selact Rating ");
    }

    if (
      noofrooms !== "" &&
      noofBathrooms !== "" &&
      carpetArea !== "" &&
      availableDate !== "" &&
      noofRating !== ""
    ) {
      const propertyform = {
        noofBedrooms: noofrooms,
        addmoreBedroomsvalue: addmoreBedroomsvalue,
        addmoreBedroomsShow: addmoreBedroomsShow,

        noofBathrooms: noofBathrooms,
        addmoreBathroomsvalue: addmoreBathroomsvalue,
        addmoreBathroomsShow: addmoreBathroomsShow,

        carpetArea: carpetArea,
        typeofCarpetArea: typeofCarpetArea,
        typeofCarpetAreaShow: typeofCarpetAreaShow,

        builtupArea: builtupArea,
        typeofBuiltupArea: typeofBuiltupArea,
        typeofBuiltupAreaShow: typeofBuiltupAreaShow,
        typeofBuiltupAreaBox: typeofBuiltupAreaBox,

        superBuiltupArea: superBuiltupArea,
        typeofSuperBuiltupArea: typeofSuperBuiltupArea,
        typeofSuperBuiltupAreaShow: typeofSuperBuiltupAreaShow,
        typeofSuperBuiltupAreaBox: typeofSuperBuiltupAreaBox,

        poojaroom: poojaroom,
        studyroom: studyroom,
        servantroom: servantroom,
        storeroom: storeroom,

        furnish: furnish,
        semifurnish: semifurnish,
        unfurnish: unfurnish,

        light: light,
        fans: fans,
        ac: ac,
        tv: tv,
        beds: beds,
        wardrobe: wardrobe,
        geyser: geyser,
        sofa: sofa,
        washingMachine: washingMachine,
        stove: stove,
        fridge: fridge,
        waterPurifier: waterPurifier,
        microwave: microwave,
        modularKitchen: modularKitchen,
        chimney: chimney,
        dinningTable: dinningTable,
        curtains: curtains,
        exhaustFan: exhaustFan,

        availableDate: availableDate,
        readyToMove: readyToMove,
        underConstruction: underConstruction,

        ageofProperty: ageofProperty,
        expectedby: expectedby,
        expectedbyShow: expectedbyShow,
        noofRating: noofRating,
      };

      dispatch(setPropertyProfile(propertyform));
      dispatch(setPostPropertyStepsStatus(3));
    }
  };

  return (
    <>
      {/* <HeaderNew /> */}
      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA">
          <div className="page_ppf_step1__1icHe  pageComponent">
            <div className="PrimaryDetailsHeader-desktopHeader">
              <div className="desktopHeader_iconInlineHead__W8_RL">
                <div className="caption_subdued_medium">
                  <span
                    className="desktopHeader_backArrow__1lVxQ iconS_PPFDesk_24 icon_backGrey"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  >
                    <BiArrowBack />
                  </span>
                  <div style={{ fontSize: "14px" }}>Back</div>
                </div>
                <div className="desktopHeader_text__3iyVt">
                  <div className="undefined title_l_semiBold">
                    Tell us about your property
                  </div>
                </div>
              </div>
              <div className="FloorPlans-widget"></div>

              <div className="Configuration-widget">
                <AddroomHospitalTemp
                  numberOfRooms={numberOfRooms}
                  addMoreBedroom={addMoreBedroom}
                  addMoreBedroomShow={addMoreBedroomShow}
                  addMoreBedroomValue={addMoreBedroomValue}
                  numberOfBathroom={numberOfBathroom}
                  addmoreBathrooms={addmoreBathrooms}
                  addMoreBathroomValue={addMoreBathroomValue}
                  addMoreBathroomShow={addMoreBathroomShow}
                  roomDetailsError={roomDetailsError}
                  noofRoomsError={noofRoomsError}
                  noofWashroomsError={noofWashroomsError}
                />

                <AddAreaDetailsTemp
                  carpetAreaChange={carpetAreaChange}
                  builtupChange={builtupChange}
                  superbuiltupChange={superbuiltupChange}
                  carpetAreaMesurment={carpetAreaMesurment}
                  builtupMesurment={builtupMesurment}
                  superbuiltupMesurment={superbuiltupMesurment}
                  areaDetailsError={areaDetailsError}
                />

                <OtherRoomsTemp
                  getpoojaroom={getpoojaroom}
                  getstudyroom={getstudyroom}
                  getservantroom={getservantroom}
                  getstoreroom={getstoreroom}
                />

                <FurnishingTemp
                  getFurnishingDetails={getFurnishingDetails}
                  getFurnishingType={getFurnishingType}
                  setLight={setLight}
                  setFans={setFans}
                  setAc={setAc}
                  setTv={setTv}
                  setBeds={setBeds}
                  setWardrobe={setWardrobe}
                  setGeyser={setGeyser}
                  setSofa={setSofa}
                  setWashingMachine={setWashingMachine}
                  setStove={setStove}
                  setFridge={setFridge}
                  setWaterPurifier={setWaterPurifier}
                  setMicrowave={setMicrowave}
                  setModularKitchen={setModularKitchen}
                  setChimney={setChimney}
                  setDinningTable={setDinningTable}
                  setCurtains={setCurtains}
                  setExhaustFan={setExhaustFan}
                />

                <AvaliableFromTemp
                  availableFromDate={availableFromDate}
                  availabilityDateError={availableFromError}
                />

                <AvaliabilityStatusTemp
                  getPropertyAvailability={getPropertyAvailability}
                  getPropertyAge={getPropertyAge}
                  abilityStatusError={availabilityError}
                  selectAgeError={selectAgeError}
                  timePossessionError={timePossessionError}
                  getPropertyExpectedYear={getPropertyExpectedYear}
                  getPropertyExpectedMonth={getPropertyExpectedMonth}
                  constructionOnProperty={constructionOnProperty}
                />

                <QualityOfRatingTemp
                  noOfRating={noOfRating}
                  qualityRatingError={qualityRatingError}
                />
              </div>
            </div>
            <div id="Location-widget">
              <div id="PrimaryDetailsButtonsWrapper-widget">
                <div className="dummyFormWidget_flexContainer__2vVMv">
                  <div
                    id="PrimaryDetailsButton-button"
                    className="pageComponent"
                  >
                    <div>
                      <div className="buttonInput_continue_btn__1gb5M ">
                        <button
                          type="button"
                          className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                          onClick={(e) => propertyProfileClick(e)}
                        >
                          <span>
                            <span>Continue</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HospitalityPropertyDetailsSellTemp;
