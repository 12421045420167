import React from "react";
import { FcPhoneAndroid, FcInfo } from "react-icons/fc";
import { GrMail } from "react-icons/gr";
import { useSelector } from "react-redux";

const DashBoard = () => {
  const { loginData } = useSelector((store) => store.Athentication);
  return (
    <>
      <div className="dashboard__mainContent">
        <div id="dashboard__placeholder" className="dashboard__fullWidth">
          <div>
            <div className="maximiseBanner fullWidth">
              <div className="">
                <div className="maximiseText dInlineBlock">
                  Maximize your business with Mygoldenhomes.com
                  <span className="title_bold dInlineBlock">
                    Utilize our offerings
                  </span>
                </div>
                <div className="infoWrap">
                  <div>
                    <div className="caption_subdued_medium">
                      Check responses anytime anywhere! Download our award
                      winning app.
                    </div>
                    <button className="buttons__primaryRegular undefined">
                      Responses on the go
                    </button>
                  </div>
                  <div>
                    <div className="caption_subdued_medium">
                      Having trouble in using any features. Click here for FAQ
                      section.
                    </div>
                    <button className="greenButton">FAQ Section</button>
                  </div>
                  <div>
                    <div className="caption_subdued_medium">
                      Your Credit Balance :-
                      <button
                        className="buttons__primaryRegular undefined"
                        style={{ width: "50%" }}
                      >
                        {loginData.creadits}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="dashboard__subscriptions">
          <div>
            <div>
              <h2>
                Subscriptions{" "}
                <span className="dashboard__details">
                  <b>0</b> Listings Live{" "}
                </span>
              </h2>
            </div>
          </div>
          <div className="dashboard__block pageComponent">
            <h3>
              Active Subscriptions{" "}
              <span className="dashboard__greenText">1</span>
            </h3>
            <table className="dashboard__table">
              <thead>
                <th style={{ width: "30%" }}>Product Name</th>
                <th style={{ width: "20%" }}>Quantity</th>
                <th style={{ width: "30%" }}>Quantity Left</th>
                <th style={{ width: "20%" }}></th>
              </thead>
              <tbody>
                <tr className="index__subsRow">
                  <td className="index__borderBottom">Plain Listings</td>
                  <td className="index__borderBottom">2.0</td>
                  <td className="index__borderBottom">2</td>
                  <td className="index__borderBottom">
                    2 expires in 5209 days
                  </td>
                </tr>
              </tbody>
              <tbody className="dashboard__disclaimerRenewal">
                <tr className="dashboard__disclaimerBox">
                  <td className="dashboard__padt2">
                    <span>
                      <img
                        className="dashboard__warningIcon"
                        src="/assets/images/all/warning_24px.png"
                        alt=""
                      />
                      <div className="dashboard__renewtext">
                        To renew your expiring and finishing-up products,
                      </div>
                    </span>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="dashboard__padt2 dashboard__ml20">
                    <a className="pageComponent dashboard__bluBtn">
                      REQUEST A CALL FOR RENEWAL
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}

        <div className="dashboard__faqContainer">
          <div className="dashboard__mr5">
            <div className="pageComponent">
              <div className="ad_Wrap  bannerImg1">
                <div className="title_bold bannerHead">
                  See responses <br></br> on the go
                </div>
                <div className="caption_subdued_small infoLineText">
                  On Mygoldenhomes mobile app
                </div>
                <button className="buttons__primaryRegular">
                  Get mobile app
                </button>
              </div>
            </div>
          </div>
          <div className="pageComponent">
            <div className="faq_Wrap  bannerImg1">
              <div className="title_bold bannerHead">
                Need help with <br></br> any feature?
              </div>
              <div className="caption_subdued_small infoLineText">
                Get help from FAQ section
              </div>
              <button className="buttons__primaryRegular">
                See FAQ section
              </button>
            </div>
          </div>
        </div>

        <div className="getInTouch__getInTouch">
          <h4>FOR QUERIES YOU CAN REACH US AT:</h4>
          <div className="getInTouch__block">
            <ul>
              <li>
                <i className="getInTouch__icon">
                  <FcPhoneAndroid />
                </i>
                <p>
                  <b>Call us at:</b>+91 9063483711
                </p>
                <p>
                  For international numbers click
                  <a>here</a>
                </p>
                <p>
                  <b>SMS:</b> +91 9063483711
                </p>
              </li>
              <li className="getInTouch__mail">
                <i className="getInTouch__icon">
                  <GrMail />
                </i>
                <p>
                  <b>Mail us for Sales/Service/General Enquiries:</b>
                  <a>services@mygoldenhomes.com</a>
                </p>
                <p>
                  For international numbers click
                  <a>here</a>
                </p>
              </li>
              <li className="getInTouch__request">
                <i className="getInTouch__icon">
                  <FcInfo />
                </i>
                <p>
                  <b>Request for Information:</b>
                  <br />
                  Ask us for information about our services.
                  <a>Request Info</a>
                </p>
                <p>
                  For international numbers click
                  <a>here</a>
                </p>
              </li>
            </ul>
          </div>
          <div className="getInTouch__greyblock">
            <b>Office & Locations :</b>
            <a>Click here</a>
            for Branch Address
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoard;
