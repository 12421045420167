import React from "react";
import { Link } from "react-router-dom";
import SwiperOurAgents from "./swiperComponents/SwiperOurAgents";

const OurAgents = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="section-title st-center fl-wrap">
            <h4>The Best Agents</h4>
            <h2>Meet Our Agents</h2>
          </div>

          <div className="clearfix" />
          <div className="listing-carousel-wrapper lc_hero carousel-wrap fl-wrap">
            <div className="">
              <SwiperOurAgents />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurAgents;
