
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const LockinPeriodTemp = (props) => {
  const { PropertyProfile, PriceotherDetails,singleProperty } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [lockInPeriod, setLockInPeriod] = useState(singleProperty.lockInPeriodCommercial);

  useEffect(() => {
    if (PriceotherDetails !== "") {
      setLockInPeriod(PriceotherDetails.lockInPeriod);

    }
  }, [PriceotherDetails]);

  return (
    <>
      <div className="LockIn-widget" style={{ marginTop: "10px" }}>
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div
            className="widgetbox_header_text__2BXc_"
            style={{ marginBottom: "-20px" }}
          >
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Lock - in Period
                  </span>
                </div>
              </h4>
            </div>
            <p style={{ color: "red" }}>{props.LockinPeriodError}</p>
          </div>
        </div>
        <div className="false">
          <div id="DepositValue-input">
            <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
              <div className="false">
                <input
                  type="text"
                  value={lockInPeriod}
                  onChange={(e) =>{
                    setLockInPeriod(e.target.value.replace(/\D/g, ""));
                    props.getLockInPeriod(e.target.value.replace(/\D/g, ""))
                  }}
                  placeholder="Enter Number of Months"
                />
                <div></div>
                <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT"></div>
                <div className="numberInput_iconsWrapper__3Kpm5">
                  <i></i>
                  <div className="screeningActions_iconWrapper__dB1NM"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default LockinPeriodTemp;
