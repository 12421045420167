import React from "react";

const NewProjectsSocieites = () => {
  const newprojects = [
    { id: "1", name: "Fort View Residency" },
    { id: "2", name: "Gayatri Apartment" },
    { id: "3", name: "Pramukha Paramount Apartment" },
    { id: "4", name: "SRR Anasuya Residency" },
  ];
  return (
    <div className="mainbarfiltercomponents">
      <div style={{ marginLeft:"10px",marginBottom:"5px" }}>
        <input type="text" placeholder="Search Society" />
      </div>
      <div>
        <p style={{ color: "#000" }}>Top Societies</p>
      </div>
      {newprojects.map((newpro, index) => (
        <div className="propertyboxes" key={index}>
          <input type="checkbox" />
          <p className="propertytextpara">{newpro.name}</p>
        </div>
      ))}
    </div>
  );
};

export default NewProjectsSocieites;
