import React from "react";
import SwiperOurClientSays from "./swiperComponents/SwiperOurClientSays";

const OurClientSays = () => {
  return (
    <>
      <section className="gray-bg ">
        <div className="container">
          <div className="section-title st-center fl-wrap">
            <h4>Testimonilas</h4>
            <h2>What Our Clients Say</h2>
          </div>
        </div>
        <div className="clearfix" />
        <div className="">
          <SwiperOurClientSays />
        </div>
      </section>
    </>
  );
};

export default OurClientSays;
