import React, { useEffect, useState } from "react";
import { GrCircleQuestion } from "react-icons/gr";
import { Tooltip } from "reactstrap";
import {
  BiArrowBack,
  BiPlus,
  BiMinus,
  BiChevronDown,
  BiInfoCircle,
} from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  setPostPropertyStepsStatus,
  setPropertyProfile,
} from "../../../../../../redux/property/PropertySlice";
import AddAreaDetails from "../../../../finalcomponents/AddAreaDetails";
import FloorDetails from "../../../../finalcomponents/FloorDetails";
import ParkingType from "../../../../finalcomponents/ParkingType";
import AvaliabilityStatus from "../../../../finalcomponents/AvaliabilityStatus";
import AvaliableFrom from "../../../../finalcomponents/AvaliableFrom";

const OfficePropertyDetails = () => {
  const { PropertyProfile, BasicDetails } = useSelector(
    (state) => state.Property
  );
  

  const dispatch = useDispatch();

  const [carpetArea, setCarpetArea] = useState("");
  const [typeofCarpetArea, setTypeofCarpetArea] = useState("sq.ft.");
  const [typeofCarpetAreaShow, setTypeofCarpetAreaShow] = useState(false);

  const [builtupArea, setBuiltupArea] = useState("");
  const [typeofBuiltupArea, setTypeofBuiltupArea] = useState("sq.ft.");
  const [typeofBuiltupAreaShow, setTypeofBuiltupAreaShow] = useState(false);

  const [superBuiltupArea, setSuperBuiltupArea] = useState("");
  const [typeofSuperBuiltupArea, setTypeofSuperBuiltupArea] =
    useState("sq.ft.");
  const [typeofSuperBuiltupAreaShow, setTypeofSuperBuiltupAreaShow] =
    useState(false);
  const [typeofSuperBuiltupAreaBox, setTypeofSuperBuiltupAreaBox] =
    useState(false);

  const [minNoOfSeats, setMinNoOfSeats] = useState(0);
  const [maxNoofSeats, setMaxNoofSeats] = useState(0);
  const [noofCabins, setNoofCabins] = useState(0);

  const [noofMeatingRooms, setNoofMeatingRooms] = useState(0);

  const [washRoomAvalible, setWashRoomAvalible] = useState(false);
  const [privateWashroom, setPrivateWashroom] = useState(0);
  const [sharedWashroom, setSharedWashroom] = useState(0);

  const [pantryType, setPantryType] = useState("");

  const [lifts, setLifts] = useState(false);
  const [servicelifts, setServiceLifts] = useState(0);
  const [passengerLifts, setPassengerLifts] = useState(0);
  const [modernLift, setModernLift] = useState(false);

  const [parkinggType, setParkinggType] = useState(false);
  const [publicParking, setPublicParking] = useState(false);
  const [privateParking, setPrivateParking] = useState(false);

  const [totalFloors, setTotalFloors] = useState(0);
  const [propertyonFloors, setPropertyonFloors] = useState("");
  const [propertyonFloorsShow, setPropertyonFloorsShow] = useState(false);

  const [ConferenceRoom, setConferenceRoom] = useState(false);

  const [receptionArea, setReceptionArea] = useState(false);

  const [fireExtinguisher, setFireExtinguisher] = useState(false);
  const [fireSensors, setFireSensors] = useState(false);
  const [sprinklers, setSprinklers] = useState(false);
  const [fireHose, setFireHose] = useState(false);

  const [pantrySize, setPantrySize] = useState("");
  const [typeofpantrySize, setTypeofpantrySize] = useState("sq.ft.");
  const [typeofpantrySizeShow, setTypeofpantrySizeShow] = useState(false);

  const [furnish, setFurnish] = useState(false);
  const [centralAir, setCentralAir] = useState(false);
  const [oxygen, setOxygen] = useState(false);
  const [ups, setUps] = useState(false);

  const [noofBedrooms, setNoofBedrooms] = useState("");
  const [addmoreBedroomsvalue, setAddmoreBedroomsValue] = useState("");
  const [addmoreBedroomsShow, setAddmoreBedroomsShow] = useState(false);

  const [readyToMove, setReadyToMove] = useState(false);
  const [underConstruction, setUnderConstruction] = useState(false);
  const [abilityStatus, setAbilityStatus] = useState(false);

  const [ageofProperty, setAgeofProperty] = useState("");

  const [expectedby, setExpectedby] = useState("");
  const [expectedbyShow, setExpectedbyShow] = useState(false);
  const [yearofExpectedBy, setYearofExpectedBy] = useState("");

  const [availableDate, setavailableDate] = useState("");

  //error
  const [areaDetailsError, setAreaDetailsError] = useState("");
  const [floorDetailsError, setFloorDetailsError] = useState("");

  const [minimumseatError, setMinimumseatError] = useState("");
  const [maxmumseatError, setMaxmumseatError] = useState("");
  const [cabinError, setCabinError] = useState("");

  const [meatingRoomError, setMeatingRoomError] = useState("");
  const [washRoomError, setWashRoomError] = useState("");
  const [conferenceRoomError, setConferenceRoomError] = useState("");
  const [receptionError, setReceptionError] = useState("");
  const [pantryTypeError, setPantryTypeError] = useState("");
  const [facilitiesError, setFacilitiesError] = useState("");
  const [furnishedError, setFurnishedError] = useState("");
  const [airConditioningError, setAirConditioningError] = useState("");
  const [oxygenError, setOxygenError] = useState("");
  const [upsError, setUpsError] = useState("");

  const [FireError, setFireError] = useState("");

  const [liftError, setLiftError] = useState("");

  const [parkingError, setParkingError] = useState("");

  const [availabilityError, setavailabilityError] = useState("");
  const [selectAgeError, setSelectedAgeError] = useState("");
  const [timePossessionError, settimePossessionError] = useState("");

  const [availableDateError, setAvailableDateError] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const carpetAreaChange = (e) => {
    const result = e;
    setCarpetArea(result);
  };

  const builtupChange = (e) => {
    const result = e;
    setBuiltupArea(result);
  };

  const superbuiltupChange = (e) => {
    const result = e;
    setSuperBuiltupArea(result);
  };

  const carpetAreaMesurment = (e) => {
    const result = e;
    setTypeofCarpetArea(result);
  };

  const builtupMesurment = (e) => {
    const result = e;
    setTypeofBuiltupArea(result);
  };
  const superbuiltupMesurment = (e) => {
    const result = e;
    setTypeofSuperBuiltupArea(result);
  };
  const getNumberOfFloors = (value) => {
    setTotalFloors(value);
  };
  const getPropertyOnFloors = (value) => {
    setPropertyonFloors(value);
  };

  const parkingType = (value) => {
    setParkinggType(value);
  };

  const parkingPrivateType = (value) => {
    setPrivateParking(value);
  };

  const parkingPublicType = (value) => {
    setPublicParking(value);
  };
  const getPropertyAvailability = (value) => {
    setAbilityStatus(value);
  };
  const getPropertyAge = (value) => {
    setAgeofProperty(value);
  };
  const getPropertyExpectedYear = (value) => {
    setYearofExpectedBy(value);
  };
  const getPropertyExpectedMonth = (value) => {
    setExpectedby(value);
  };
  const constructionOnProperty = (e) => {
    const result = e;
    setUnderConstruction(result);
  };
  const availableFromDate = (e) => {
    const result = e;
    setavailableDate(result);
  };

  useEffect(() => {
    if (carpetArea !== "") {
      setAreaDetailsError("");
    }
    if (minNoOfSeats !== "") {
      setMinimumseatError("");
    }
    if (maxNoofSeats !== "") {
      setMaxmumseatError("");
    }
    if (noofCabins !== "") {
      setCabinError("");
    }
    if (noofMeatingRooms !== "") {
      setMeatingRoomError("");
    }
    if (
      fireExtinguisher === true ||
      fireSensors === true ||
      sprinklers === true ||
      fireHose === true
    ) {
      setFireError("");
    }
    if (totalFloors !== "") {
      setFloorDetailsError("");
    }
    if (
      parkinggType === true ||
      publicParking === true ||
      privateParking === true
    ) {
      setParkingError("");
    }

    if (ageofProperty !== "" || expectedby !== "") {
      setavailabilityError("");
      setSelectedAgeError("");
      settimePossessionError("");
    }
    if (availableDate !== "") {
      setAvailableDateError("");
    }
  }, [
    carpetArea,
    minNoOfSeats,
    maxNoofSeats,
    noofCabins,
    noofMeatingRooms,
    fireExtinguisher,
    fireSensors,
    sprinklers,
    fireHose,
    totalFloors,
    parkinggType,
    publicParking,
    privateParking,
    abilityStatus,
    ageofProperty,
    expectedby,
    availableDate,
  ]);

  useEffect(() => {
    if (PropertyProfile !== "") {
      setCarpetArea(PropertyProfile.carpetArea);
      setTypeofCarpetArea(PropertyProfile.typeofCarpetArea);
      setTypeofCarpetAreaShow(PropertyProfile.typeofCarpetAreaShow);

      setSuperBuiltupArea(PropertyProfile.superBuiltupArea);
      setTypeofSuperBuiltupArea(PropertyProfile.typeofSuperBuiltupArea);
      setTypeofSuperBuiltupAreaShow(PropertyProfile.typeofSuperBuiltupAreaShow);
      setTypeofSuperBuiltupAreaBox(PropertyProfile.typeofSuperBuiltupAreaBox);

      setMinNoOfSeats(PropertyProfile.minNoOfSeats);
      setMaxNoofSeats(PropertyProfile.maxNoofSeats);
      setNoofCabins(PropertyProfile.noofCabins);

      setNoofMeatingRooms(PropertyProfile.noofMeatingRooms);

      setWashRoomAvalible(PropertyProfile.washRoomAvalible);
      setPrivateWashroom(PropertyProfile.privateWashroom);
      setSharedWashroom(PropertyProfile.sharedWashroom);

      setPantryType(PropertyProfile.pantryType);

      setLifts(PropertyProfile.lifts);
      setServiceLifts(PropertyProfile.servicelifts);
      setPassengerLifts(PropertyProfile.passengerLifts);
      setModernLift(PropertyProfile.modernLift);

      setParkinggType(PropertyProfile.parkinggType);
      setPublicParking(PropertyProfile.publicParking);
      setPrivateParking(PropertyProfile.privateParking);

      setTotalFloors(PropertyProfile.totalFloors);
      setPropertyonFloors(PropertyProfile.propertyonFloors);
      setPropertyonFloorsShow(PropertyProfile.propertyonFloorsShow);

      setConferenceRoom(PropertyProfile.ConferenceRoom);

      setReceptionArea(PropertyProfile.receptionArea);

      setFireExtinguisher(PropertyProfile.fireExtinguisher);
      setFireSensors(PropertyProfile.fireSensors);
      setSprinklers(PropertyProfile.sprinklers);
      setFireHose(PropertyProfile.fireHose);

      setPantrySize(PropertyProfile.pantrySize);
      setTypeofpantrySize(PropertyProfile.typeofpantrySize);
      setTypeofpantrySizeShow(PropertyProfile.typeofpantrySizeShow);

      setFurnish(PropertyProfile.furnish);
      setCentralAir(PropertyProfile.centralAir);
      setOxygen(PropertyProfile.oxygen);
      setUps(PropertyProfile.ups);

      setNoofBedrooms(PropertyProfile.noofBedrooms);
      setAddmoreBedroomsValue(PropertyProfile.addmoreBedroomsvalue);
      setAddmoreBedroomsShow(PropertyProfile.addmoreBedroomsShow);

      setReadyToMove(PropertyProfile.readyToMove);
      setUnderConstruction(PropertyProfile.underConstruction);
      setAbilityStatus(PropertyProfile.abilityStatus);

      setAgeofProperty(PropertyProfile.ageofProperty);

      setExpectedby(PropertyProfile.expectedby);
      setExpectedbyShow(PropertyProfile.expectedbyShow);

      setavailableDate(PropertyProfile.availableDate);
    }
  }, [PropertyProfile]);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const areaMesurments = [
    { name: "sq.ft." },
    { name: "sq.yards" },
    { name: "sq.m." },
    { name: "acres" },
    { name: "marla" },
    { name: "cents" },
    { name: "bigha" },
    { name: "kottah" },
    { name: "kanal" },
    { name: "grounds" },
    { name: "ares" },
    { name: "biswa" },
    { name: "guntha" },
    { name: "aankadam" },
    { name: "hectares" },
    { name: "rood" },
    { name: "chataks" },
    { name: "perch" },
  ];

  const floorDetails = [
    { id: "1", name: "Basement" },
    { id: "2", name: "Lower Ground" },
    { id: "3", name: "Ground" },
    { id: "4", name: "1" },
    { id: "5", name: "2" },
    { id: "6", name: "3" },
    { id: "7", name: "4" },
    { id: "8", name: "5" },
    { id: "9", name: "6" },
  ];

  const underConstructions = [
    { id: "1", name: "Within 3 Months" },
    { id: "2", name: "Within 6 Months" },
    { id: "3", name: "By 2024" },
    { id: "4", name: "By 2025" },
    { id: "5", name: "By 2026" },
    { id: "6", name: "By 2027" },
  ];

  const propertyProfileClick = async (e) => {
    e.preventDefault();
    setAreaDetailsError("");
    setMinimumseatError("");
    setMaxmumseatError("");
    setCabinError("");
    setMeatingRoomError("");
    setFireError("");
    setFloorDetailsError("");
    setParkingError("");
    setavailabilityError("");
    setSelectedAgeError("");
    settimePossessionError("");
    setAvailableDateError("");

    if (carpetArea !== "") {
      setAreaDetailsError("");
    } else {
      setAreaDetailsError("Enter Area Details");
    }
    if (minNoOfSeats !== "") {
      setMinimumseatError("");
    } else {
      setMinimumseatError("Enter Minimum Seats");
    }
    if (maxNoofSeats !== "") {
      setMaxmumseatError("");
    } else {
      setMaxmumseatError("Enter Maximum Seats");
    }
    if (noofCabins !== "") {
      setCabinError("");
    } else {
      setCabinError("Enter no. of cabin");
    }
    if (noofMeatingRooms !== "") {
      setMeatingRoomError("");
    } else {
      setMeatingRoomError("Enter no. of Meeting Rooms");
    }
    if (
      fireExtinguisher === true ||
      fireSensors === true ||
      sprinklers === true ||
      fireHose === true
    ) {
      setFireError("");
    } else {
      setFireError("Select atleast One");
    }
    if (totalFloors !== "") {
      setFloorDetailsError("");
    } else {
      setFloorDetailsError("Enter no. of Floors");
    }
    if (
      parkinggType === true ||
      publicParking === true ||
      privateParking === true
    ) {
      setParkingError("");
    } else {
      setParkingError("Select Parking");
    }
    if (ageofProperty !== "" || expectedby !== "") {
      setavailabilityError("");
      setSelectedAgeError("");
      settimePossessionError("");
    } else {
      setavailabilityError("Selact Availibility Status ");
      setSelectedAgeError("Select Property Age");
      settimePossessionError("Select Expected Date");
    }
    if (availableDate !== "") {
      setAvailableDateError("");
    } else {
      setAvailableDateError("Select Available Date");
    }

    if (
      carpetArea !== "" &&
      minNoOfSeats !== "" &&
      maxNoofSeats !== "" &&
      noofCabins !== "" &&
      noofMeatingRooms !== "" &&
      totalFloors !== "" &&
      abilityStatus !== "" &&
      availableDate !== ""
    ) {
      if (
        fireExtinguisher === true ||
        fireSensors === true ||
        sprinklers === true ||
        (fireHose === true && parkinggType === true) ||
        publicParking === true ||
        privateParking === true
      ) {
        const propertyProfile = {
          carpetArea: carpetArea,
          typeofCarpetArea: typeofCarpetArea,
          typeofCarpetAreaShow: typeofCarpetAreaShow,

          builtupArea: builtupArea,
          typeofBuiltupArea: typeofBuiltupArea,
          typeofBuiltupAreaShow: typeofBuiltupAreaShow,

          superBuiltupArea: superBuiltupArea,
          typeofSuperBuiltupArea: typeofSuperBuiltupArea,
          typeofSuperBuiltupAreaShow: typeofSuperBuiltupAreaShow,
          typeofSuperBuiltupAreaBox: typeofSuperBuiltupAreaBox,

          minNoOfSeats: minNoOfSeats,
          maxNoofSeats: maxNoofSeats,
          noofCabins: noofCabins,

          noofMeatingRooms: noofMeatingRooms,

          washRoomAvalible: washRoomAvalible,
          privateWashroom: privateWashroom,
          sharedWashroom: sharedWashroom,

          pantryType: pantryType,
          receptionArea: receptionArea,
          ConferenceRoom: ConferenceRoom,

          lifts: lifts,
          servicelifts: servicelifts,
          passengerLifts: passengerLifts,
          modernLift: modernLift,

          totalFloors: totalFloors,
          propertyonFloors: propertyonFloors,
          propertyonFloorsShow: propertyonFloorsShow,

          fireExtinguisher: fireExtinguisher,
          fireSensors: fireSensors,
          sprinklers: sprinklers,
          fireHose: fireHose,

          pantrySize: pantrySize,
          typeofpantrySize: typeofpantrySize,
          typeofpantrySizeShow: typeofpantrySizeShow,

          furnish: furnish,
          centralAir: centralAir,
          oxygen: oxygen,
          ups: ups,

          noofBedrooms: noofBedrooms,
          addmoreBedroomsvalue: addmoreBedroomsvalue,
          addmoreBedroomsShow: addmoreBedroomsShow,
          publicParking:publicParking,
          privateParking:privateParking,

          readyToMove: abilityStatus,
          underConstruction: underConstruction,
          abilityStatus: abilityStatus,

          ageofProperty: ageofProperty,

          expectedby: expectedby,
          expectedbyShow: expectedbyShow,

          availableDate: availableDate,
        };
        dispatch(setPropertyProfile(propertyProfile));
        dispatch(setPostPropertyStepsStatus(3));
      }
    }
  };

  return (
    <>
      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA">
          <div className="Area-widget">
            <div className="PrimaryDetailsHeader-desktopHeader">
              <div className="desktopHeader_iconInlineHead__W8_RL">
                <div className="caption_subdued_medium">
                  <span
                    className="desktopHeader_backArrow__1lVxQ iconS_PPFDesk_24 icon_backGrey"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  >
                    <BiArrowBack />
                  </span>
                  <div style={{ fontSize: "14px" }}>Back</div>
                </div>
                <div className="desktopHeader_text__3iyVt">
                  <div className="undefined title_l_semiBold">
                    Tell us about your property
                  </div>
                </div>
              </div>
            </div>

            <div className="widgetbox_white_box__1v7xz undefined undefined undefined">
              <AddAreaDetails
                carpetAreaChange={carpetAreaChange}
                builtupChange={builtupChange}
                superbuiltupChange={superbuiltupChange}
                carpetAreaMesurment={carpetAreaMesurment}
                builtupMesurment={builtupMesurment}
                superbuiltupMesurment={superbuiltupMesurment}
                areaDetailsError={areaDetailsError}
              />

              <div className="" style={{ width: "70%" }}>
                <div className="OfficeSetup-widget">
                  <div className="widgetbox_white_box__1v7xz undefined false false OfficeSetup_inner">
                    <div className="widgetbox_header_text__2BXc_">
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <div>
                              <span
                                className="xidHeading_headingText__Ulc2a"
                                style={{ display: "inline" }}
                              >
                                Describe your office setup
                              </span>
                            </div>
                          </div>
                        </h4>
                      </div>
                    </div>
                    <div className="formWidget_inlineContainer__3oR34 false">
                      <div id="totalFloor-input">
                        <p style={{ color: "red" }}>{minimumseatError}</p>
                        <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                          <div className="false">
                            <input
                              type="text"
                              inputMode="decimal"
                              placeholder="Mini. no. of Seats"
                              value={minNoOfSeats}
                              max={90}
                              min={0}
                              onChange={(e) => {
                                if (e.target.value <= 90) {
                                  setMinNoOfSeats(
                                    e.target.value.replace(/^[0]+$/, 0)
                                  );
                                }
                              }}
                            />

                            <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT"></div>
                          </div>
                        </div>
                      </div>
                      <div id="floorNum-input">
                        <p style={{ color: "red" }}>{maxmumseatError}</p>
                        <div>
                          <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                            <div className="false">
                              <input
                                type="text"
                                inputMode="decimal"
                                placeholder="Max. no. of Seats"
                                value={maxNoofSeats}
                                max={90}
                                min={0}
                                onChange={(e) => {
                                  if (e.target.value <= 90) {
                                    setMaxNoofSeats(
                                      e.target.value.replace(/^[0]+$/, 0)
                                    );
                                  }
                                }}
                              />
                              <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="totalFloor-input">
                        <p style={{ color: "red" }}>{cabinError}</p>
                        <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                          <div className="false">
                            <input
                              type="text"
                              inputMode="decimal"
                              placeholder="No.of Cabins"
                              value={noofCabins}
                              max={90}
                              min={0}
                              onChange={(e) => {
                                if (e.target.value <= 90) {
                                  setNoofCabins(
                                    e.target.value.replace(/^[0]+$/, 0)
                                  );
                                }
                              }}
                            />
                            <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT"></div>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>

                <div className="MeetingRooms-widget">
                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div className="widgetbox_header_text__2BXc_">
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <div>
                              <span
                                className="xidHeading_headingText__Ulc2a"
                                style={{ display: "inline" }}
                              >
                                No. of Meeting Rooms
                              </span>
                            </div>
                          </div>
                        </h4>
                      </div>
                      <p style={{ color: "red" }}>{meatingRoomError}</p>
                    </div>
                    <div className="formWidget_inlineContainer__3oR34 false">
                      <div id="floorNum-input">
                        <div>
                          <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                            <div className="false">
                              <input
                                type="text"
                                inputMode="decimal"
                                placeholder="No. of Meeting Rooms"
                                value={noofMeatingRooms}
                                max={90}
                                min={0}
                                onChange={(e) => {
                                  if (e.target.value <= 90) {
                                    setNoofMeatingRooms(
                                      e.target.value.replace(/^[0]+$/, 0)
                                    );
                                  }
                                }}
                              />
                              <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>

                <div id="Washrooms-widget Preference_widget_11">
                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div className="widgetbox_header_text__2BXc_">
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <div>
                              <span
                                className="xidHeading_headingText__Ulc2a"
                                style={{ display: "inline" }}
                              >
                                Washrooms
                              </span>
                            </div>
                          </div>
                        </h4>
                        <p style={{ color: "red" }}>{washRoomError}</p>
                      </div>
                    </div>
                    <div className="false">
                      <div
                        className="pageComponent"
                        style={{ textAlign: "left" }}
                      >
                        <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                          <div
                            className={
                              washRoomAvalible === true
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL"
                            }
                            onClick={() => {
                              setWashRoomAvalible(true);
                            }}
                            id="a"
                          >
                            <span>Available </span>
                          </div>
                          <div
                            className={
                              washRoomAvalible === false
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL"
                            }
                            onClick={() => {
                              setWashRoomAvalible(false);
                            }}
                            id="n"
                          >
                            <span>Not-Available</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {washRoomAvalible === true ? (
                      <>
                        <div id="Washroom-widget" style={{ textAlign: "left" }}>
                          <div className="" id="passengerLifts-input">
                            <div className="body_med counter_counterWrap__U0_kk">
                              <div className="body_med counter_counterWrap__U0_kk">
                                <span> Private Washrooms</span>
                              </div>
                              <div className="counter_counter_wrapper__amSb0  counter_smallSize__10lVe">
                                {privateWashroom > 0 ? (
                                  <>
                                    <span
                                      className="caption_subdued_large"
                                      style={{
                                        cursor: "default",
                                      }}
                                      onClick={() => {
                                        setPrivateWashroom(privateWashroom - 1);
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className="caption_subdued_large"
                                      style={{
                                        cursor: "pointer",
                                        opacity: "0.2",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                )}
                                <span className="caption_strong_large">
                                  {privateWashroom}
                                </span>
                                <span
                                  onClick={() => {
                                    setPrivateWashroom(privateWashroom + 1);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i>
                                    <BiPlus />
                                  </i>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div id="serviceLifts-input">
                            <div className="body_med counter_counterWrap__U0_kk">
                              <div className="body_med counter_counterWrap__U0_kk">
                                <span> Shared Washrooms</span>
                              </div>
                              <div className="counter_counter_wrapper__amSb0  counter_smallSize__10lVe">
                                {sharedWashroom > 0 ? (
                                  <>
                                    {" "}
                                    <span
                                      className="caption_subdued_large"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSharedWashroom(sharedWashroom - 1);
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className="caption_subdued_large"
                                      style={{
                                        cursor: "default",
                                        opacity: "0.2",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                )}

                                <span className="caption_strong_large">
                                  {sharedWashroom}
                                </span>
                                <span
                                  onClick={() => {
                                    setSharedWashroom(sharedWashroom + 1);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i>
                                    <BiPlus />
                                  </i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div id="ConferenceRooms-widget Preference_widget_11">
                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div className="widgetbox_header_text__2BXc_">
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <div>
                              <span
                                className="xidHeading_headingText__Ulc2a"
                                style={{ display: "inline" }}
                              >
                                Conference Room
                              </span>
                            </div>
                          </div>
                        </h4>
                        <p style={{ color: "red" }}>{conferenceRoomError}</p>
                      </div>
                    </div>
                    <div className="false">
                      <div
                        className="pageComponent"
                        style={{ textAlign: "left" }}
                      >
                        <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                          <div
                            className={
                              ConferenceRoom === true
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL"
                            }
                            onClick={() => {
                              setConferenceRoom(true);
                            }}
                            id="a"
                          >
                            <span>Available</span>
                          </div>
                          <div
                            className={
                              ConferenceRoom === false
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL"
                            }
                            onClick={() => {
                              setConferenceRoom(false);
                            }}
                            id="n"
                          >
                            <span>Not-Available</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="Reception-widget Preference_widget_11">
                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div className="widgetbox_header_text__2BXc_">
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <div>
                              <span
                                className="xidHeading_headingText__Ulc2a"
                                style={{ display: "inline" }}
                              >
                                Reception Area
                              </span>
                            </div>
                          </div>
                        </h4>
                        <p style={{ color: "red" }}>{receptionError}</p>
                      </div>
                    </div>
                    <div className="false">
                      <div
                        className="pageComponent"
                        style={{ textAlign: "left" }}
                      >
                        <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                          <div
                            className={
                              receptionArea === true
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL"
                            }
                            onClick={() => {
                              setReceptionArea(true);
                            }}
                            id="a"
                          >
                            <span>Available</span>
                          </div>
                          <div
                            className={
                              receptionArea === false
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL"
                            }
                            onClick={() => {
                              setReceptionArea(false);
                            }}
                            id="n"
                          >
                            <span>Not-Available</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="Pantry-widget Preference_widget_11">
                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div className="widgetbox_header_text__2BXc_">
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <div>
                              <span
                                className="xidHeading_headingText__Ulc2a"
                                style={{ display: "inline" }}
                              >
                                Pantry Type
                              </span>
                            </div>
                          </div>
                        </h4>
                        <p style={{ color: "red" }}>{pantryTypeError}</p>
                      </div>
                    </div>
                    <div className="false">
                      <div
                        className="pageComponent"
                        style={{ textAlign: "left" }}
                      >
                        <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                          <div
                            className={
                              pantryType === "Private"
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL"
                            }
                            onClick={() => {
                              setPantryType("Private");
                            }}
                            id="p"
                          >
                            <span>Private</span>
                          </div>
                          <div
                            className={
                              pantryType === "Shared"
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL"
                            }
                            onClick={() => {
                              setPantryType("Shared");
                            }}
                            id="s"
                          >
                            <span>Shared</span>
                          </div>
                          <div
                            className={
                              pantryType === "Not - Available"
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL"
                            }
                            onClick={() => {
                              setPantryType("Not - Available");
                            }}
                            id="n"
                          >
                            <span>Not - Available</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {pantryType === "Private" || pantryType === "Shared" ? (
                      <>
                        <div className="splitWidget_inputFiledWrap__1vX9m  undefined">
                          <div className="pageComponent" id="carpet-input">
                            <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                              <div className="false">
                                <input
                                  type="text"
                                  className="false false"
                                  value={pantrySize}
                                  onChange={(e) =>
                                    setPantrySize(
                                      e.target.value.replace(/\D/g, "")
                                    )
                                  }
                                  placeholder="Pantry Size (Optional)"
                                />
                              </div>
                              <div className="numberInput_iconsWrapper__3Kpm5">
                                <i></i>
                                <div className="screeningActions_iconWrapper__dB1NM"></div>
                              </div>
                            </div>
                          </div>
                          <div className="pageComponent" id="carpet-input">
                            <div style={{}}>
                              <div className="selectInput_custom_input_wrapper__MqLE9 false  pageComponent">
                                <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                                <div
                                  className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf "
                                  style={{ border: "none", cursor: "pointer" }}
                                  onClick={(e) =>
                                    setTypeofpantrySizeShow(
                                      !typeofpantrySizeShow
                                    )
                                  }
                                >
                                  {typeofpantrySize}
                                </div>
                                <div
                                  className="selectInput_iconsWrapper__1L2kR"
                                  onClick={(e) => {
                                    setTypeofpantrySizeShow(
                                      !typeofpantrySizeShow
                                    );
                                  }}
                                >
                                  {typeofpantrySizeShow ? (
                                    <span className="icon_downArrow">
                                      <img
                                        src="/assets/images/all/arrow_up.png"
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    <span className="icon_downArrow">
                                      <img
                                        src="/assets/images/all/arrow_down.png"
                                        alt=""
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                              {typeofpantrySizeShow && (
                                <>
                                  <div className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown">
                                    {areaMesurments.map((mesurment, index) => (
                                      <div key={index}>
                                        <span
                                          onClick={() => {
                                            setTypeofpantrySize(mesurment.name);
                                            setTypeofpantrySizeShow(false);
                                          }}
                                        >
                                          {mesurment.name}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div id="CommercialR2MFacilities-widget Preference-widget Preference_widget_11">
                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div className="widgetbox_header_text__2BXc_">
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <div>
                              <span
                                className="xidHeading_headingText__Ulc2a"
                                style={{ display: "inline" }}
                              >
                                Please select the facilities available
                              </span>
                            </div>
                          </div>
                        </h4>
                        <p style={{ color: "red" }}>{facilitiesError}</p>
                      </div>
                    </div>
                    <div className="false">
                      <div
                        className="pageComponent facilities__available"
                        style={{ textAlign: "left" }}
                      >
                        <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input facilities_inner">
                          <div
                            className="radioInput_inline_radio__qveVO radioInput_marginBtm__VxOrF "
                            id="p"
                          >
                            <div className="body_med margin_radio">
                              Furnishing
                            </div>
                            <p style={{ color: "red" }}>{furnishedError}</p>
                            <div style={{ display: "flex" }}>
                              <div className="pageComponent" data-label="VALUE">
                                <div>
                                  <input
                                    type="radio"
                                    checked={furnish === true}
                                    onChange={() => setFurnish(true)}
                                    aria-label="Available"
                                  />
                                  <label>Available</label>
                                </div>
                              </div>
                              <div className="pageComponent" data-label="VALUE">
                                <div>
                                  <input
                                    type="radio"
                                    checked={furnish === false}
                                    onChange={() => setFurnish(false)}
                                    aria-label="Not Available"
                                  />
                                  <label>Not Available</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="radioInput_inline_radio__qveVO radioInput_marginBtm__VxOrF "
                            id="q"
                          >
                            <div className="body_med margin_radio">
                              Central Air Conditioning
                            </div>
                            <p style={{ color: "red" }}>
                              {airConditioningError}
                            </p>
                            <div style={{ display: "flex" }}>
                              <div className="pageComponent" data-label="VALUE">
                                <div>
                                  <input
                                    type="radio"
                                    checked={centralAir === true}
                                    onChange={() => setCentralAir(true)}
                                    aria-label="Available"
                                  />
                                  <label>Available</label>
                                </div>
                              </div>
                              <div className="pageComponent" data-label="VALUE">
                                <div>
                                  <input
                                    type="radio"
                                    checked={centralAir === false}
                                    onChange={() => setCentralAir(false)}
                                    aria-label="Not Available"
                                  />
                                  <label>Not Available</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="radioInput_inline_radio__qveVO radioInput_marginBtm__VxOrF "
                            id="r"
                          >
                            <div className="body_med margin_radio">
                              Oxygen Duct
                            </div>
                            <p style={{ color: "red" }}>{oxygenError}</p>
                            <div style={{ display: "flex" }}>
                              <div className="pageComponent" data-label="VALUE">
                                <div>
                                  <input
                                    type="radio"
                                    checked={oxygen === true}
                                    onChange={() => setOxygen(true)}
                                    aria-label="Available"
                                  />
                                  <label>Available</label>
                                </div>
                              </div>
                              <div className="pageComponent" data-label="VALUE">
                                <div>
                                  <input
                                    type="radio"
                                    checked={oxygen === false}
                                    onChange={() => setOxygen(false)}
                                    aria-label="Not Available"
                                  />
                                  <label>Not Available</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="radioInput_inline_radio__qveVO radioInput_marginBtm__VxOrF "
                            id="s"
                          >
                            <div className="body_med margin_radio">UPS</div>
                            <p style={{ color: "red" }}>{upsError}</p>
                            <div style={{ display: "flex" }}>
                              <div className="pageComponent" data-label="VALUE">
                                <div>
                                  <input
                                    type="radio"
                                    checked={ups === true}
                                    onChange={() => setUps(true)}
                                    aria-label="Available"
                                  />
                                  <label>Available</label>
                                </div>
                              </div>
                              <div className="pageComponent" data-label="VALUE">
                                <div>
                                  <input
                                    type="radio"
                                    checked={ups === false}
                                    onChange={() => setUps(false)}
                                    aria-label="Not Available"
                                  />
                                  <label>Not Available</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="FireSafety-widget">
                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div className="widgetbox_header_text__2BXc_">
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <div>
                              <span className="xidHeading_headingText__Ulc2a">
                                Fire safety measures include
                              </span>
                            </div>
                          </div>
                        </h4>
                      </div>
                    </div>
                    <p style={{ color: "red" }}>{FireError}</p>
                    <div className="false">
                      <div
                        id="additionalRooms-input"
                        className="pageComponent"
                        style={{ textAlign: "left" }}
                      >
                        <div className="checkBox_tags_wrapper__2gksy undefined multiple_input">
                          <div
                            className={
                              fireExtinguisher === true
                                ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                                : "tags-and-chips_textOnly__2k10B pageComponent "
                            }
                            onClick={() =>
                              setFireExtinguisher(!fireExtinguisher)
                            }
                            id="1"
                          >
                            {fireExtinguisher === true ? (
                              <>
                                {" "}
                                <i className="iconS_Common_20 icon_blueRightTick">
                                  <img
                                    src="./assets/images/all/tick.png"
                                    alt=""
                                  />
                                </i>
                              </>
                            ) : (
                              <>
                                <i className="iconS_PPF_20 icon_plusIcon">
                                  <BiPlus />
                                </i>
                              </>
                            )}
                            <span>Fire Extinguisher</span>
                          </div>
                          <div
                            className={
                              fireSensors === true
                                ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                                : "tags-and-chips_textOnly__2k10B pageComponent "
                            }
                            onClick={() => setFireSensors(!fireSensors)}
                            id="2"
                          >
                            {fireSensors === true ? (
                              <>
                                {" "}
                                <i className="iconS_Common_20 icon_blueRightTick">
                                  <img
                                    src="./assets/images/all/tick.png"
                                    alt=""
                                  />
                                </i>
                              </>
                            ) : (
                              <>
                                <i className="iconS_PPF_20 icon_plusIcon">
                                  <BiPlus />
                                </i>
                              </>
                            )}
                            <span>Fire Sensors</span>
                          </div>
                          <div
                            className={
                              sprinklers === true
                                ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                                : "tags-and-chips_textOnly__2k10B pageComponent "
                            }
                            onClick={() => setSprinklers(!sprinklers)}
                            id="3"
                          >
                            {sprinklers === true ? (
                              <>
                                {" "}
                                <i className="iconS_Common_20 icon_blueRightTick">
                                  <img
                                    src="./assets/images/all/tick.png"
                                    alt=""
                                  />
                                </i>
                              </>
                            ) : (
                              <>
                                <i className="iconS_PPF_20 icon_plusIcon">
                                  <BiPlus />
                                </i>
                              </>
                            )}
                            <span>Sprinklers</span>
                          </div>
                          <div
                            className={
                              fireHose === true
                                ? "tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0"
                                : "tags-and-chips_textOnly__2k10B pageComponent "
                            }
                            onClick={() => setFireHose(!fireHose)}
                            id="4"
                          >
                            {fireHose === true ? (
                              <>
                                {" "}
                                <i className="iconS_Common_20 icon_blueRightTick">
                                  <img
                                    src="./assets/images/all/tick.png"
                                    alt=""
                                  />
                                </i>
                              </>
                            ) : (
                              <>
                                <i className="iconS_PPF_20 icon_plusIcon">
                                  <BiPlus />
                                </i>
                              </>
                            )}
                            <span>Fire Hose</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <FloorDetails
                  getNumberOfFloors={getNumberOfFloors}
                  getPropertyOnFloors={getPropertyOnFloors}
                  floorDetailsError={floorDetailsError}
                />

                <div id="Staircases-widget Preference_widget_11">
                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div className="widgetbox_header_text__2BXc_">
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <div>
                              <span
                                className="xidHeading_headingText__Ulc2a"
                                style={{ display: "inline" }}
                              >
                                No. of Staircases
                              </span>
                            </div>
                            <div className="xidHeading_headingText__Ulc2a">
                              <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                                (Optional)
                              </em>
                            </div>
                          </div>
                        </h4>
                      </div>
                    </div>
                    <div className="false">
                      <div
                        className="pageComponent"
                        style={{ textAlign: "left" }}
                      >
                        <div
                          className="tagWrapper_tags_wrapper__KIRJJ  multiple_input"
                          id="bedroomNum"
                          style={{ marginTop: "8px" }}
                        >
                          <div
                            className={
                              noofBedrooms === "1"
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL "
                            }
                            id="1"
                            onClick={() => {
                              setNoofBedrooms("1");
                              setAddmoreBedroomsValue("");
                            }}
                          >
                            <span>1</span>
                          </div>
                          <div
                            className={
                              noofBedrooms === "2"
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL "
                            }
                            id="2"
                            onClick={() => {
                              setNoofBedrooms("2");
                              setAddmoreBedroomsValue("");
                            }}
                          >
                            <span>2</span>
                          </div>
                          <div
                            className={
                              noofBedrooms === "3"
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL "
                            }
                            id="3"
                            onClick={() => {
                              setNoofBedrooms("3");
                              setAddmoreBedroomsValue("");
                            }}
                          >
                            <span>3</span>
                          </div>
                          <div
                            className={
                              noofBedrooms === "4"
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL "
                            }
                            id="4"
                            onClick={() => {
                              setNoofBedrooms("4");
                              setAddmoreBedroomsValue("");
                            }}
                          >
                            <span>4</span>
                          </div>
                          {noofBedrooms > 4 ? (
                            <>
                              <div
                                className={
                                  noofBedrooms > 4
                                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                    : "pageComponent radioInput_textOnly__1r7GL "
                                }
                                id="5"
                                onClick={() => {
                                  setAddmoreBedroomsShow(true);
                                }}
                              >
                                <span>{noofBedrooms}</span>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div
                            className="hyperlinks_medium AddOther_addOtherLink__3jZ8s"
                            style={{ display: "block" }}
                          >
                            {addmoreBedroomsShow === false &&
                            addmoreBedroomsvalue === "" ? (
                              <>
                                <i
                                  className="icon_bluePlusIcon"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <BiPlus />
                                </i>
                                <span
                                  className="AddOther_toggleLabel__YwU_k"
                                  onClick={() => setAddmoreBedroomsShow(true)}
                                  style={{ cursor: "pointer" }}
                                >
                                  Add other
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          {addmoreBedroomsShow === true ? (
                            <>
                              {" "}
                              <div className="AddOther_inputWrap__3J562">
                                <div className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined">
                                  <div>
                                    <input
                                      type="text"
                                      className="pageComponenet"
                                      style={{
                                        width: "60%",
                                        borderTop: "none",
                                        borderLeft: "none",
                                        borderRight: "none",
                                      }}
                                      value={addmoreBedroomsvalue}
                                      onChange={(e) =>
                                        setAddmoreBedroomsValue(
                                          e.target.value.replace(/\D/g, "")
                                        )
                                      }
                                    />
                                    <div></div>
                                    <div className="textInput_placeholder__1KRsF caption_strong_large textInput_placeholderWithoutError__3FjSB">
                                      <span className="undefined"></span>
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    className="buttons_primaryRegular__2swW5 buttons_semi__32O7H undefined"
                                    onClick={() => {
                                      setAddmoreBedroomsShow(false);
                                      setNoofBedrooms(addmoreBedroomsvalue);
                                    }}
                                  >
                                    <span>
                                      <span>Done</span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="Lifts-widget Preference_widget_11">
                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div className="widgetbox_header_text__2BXc_">
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <div>
                              <span
                                className="xidHeading_headingText__Ulc2a"
                                style={{ display: "inline" }}
                              >
                                Lifts
                              </span>
                            </div>
                          </div>
                        </h4>
                      </div>
                      <p style={{ color: "red" }}>{liftError}</p>
                    </div>
                    <div className="false">
                      <div
                        className="pageComponent"
                        style={{ textAlign: "left" }}
                      >
                        <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                          <div
                            className={
                              lifts === true
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL"
                            }
                            onClick={() => {
                              setLifts(true);
                            }}
                            id="a"
                          >
                            <span>Available</span>
                          </div>
                          <div
                            className={
                              lifts === false
                                ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                                : "pageComponent radioInput_textOnly__1r7GL"
                            }
                            onClick={() => {
                              setLifts(false);
                            }}
                            id="n"
                          >
                            <span>Not-Available</span>
                          </div>
                        </div>
                        {lifts === true ? (
                          <>
                            <div id="liftsCount-widget">
                              <div
                                className=""
                                id="passengerLifts-input"
                                style={{ display: "flex" }}
                              >
                                <div
                                  className="body_med counter_counterWrap__U0_kk"
                                  style={{ marginRight: "30px" }}
                                >
                                  <div className="body_med counter_counterWrap__U0_kk">
                                    <span>Passenger Lifts</span>
                                  </div>
                                  <div className="counter_counter_wrapper__amSb0  counter_smallSize__10lVe">
                                    {passengerLifts > 0 ? (
                                      <>
                                        <span
                                          className="caption_subdued_large"
                                          style={{
                                            cursor: "default",
                                          }}
                                          onClick={() => {
                                            setPassengerLifts(
                                              passengerLifts - 1
                                            );
                                          }}
                                        >
                                          <i>
                                            <BiMinus />
                                          </i>
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          className="caption_subdued_large"
                                          style={{
                                            cursor: "pointer",
                                            opacity: "0.2",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <i>
                                            <BiMinus />
                                          </i>
                                        </span>
                                      </>
                                    )}
                                    <span className="caption_strong_large">
                                      {passengerLifts}
                                    </span>
                                    <span
                                      onClick={() => {
                                        setPassengerLifts(passengerLifts + 1);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i>
                                        <BiPlus />
                                      </i>
                                    </span>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    verticalAlign: "middle",
                                    justifyContent: "center",
                                    fontSize: "14px",
                                  }}
                                >
                                  <span>
                                    <input
                                      type="checkbox"
                                      style={{
                                        marginRight: "5px",
                                        verticalAlign: "middle",
                                      }}
                                      checked={modernLift === true}
                                      onChange={() =>
                                        setModernLift(!modernLift)
                                      }
                                    />
                                    Modern lifts (Optional)
                                  </span>
                                </div>
                              </div>
                              <div id="serviceLifts-input">
                                <div className="body_med counter_counterWrap__U0_kk">
                                  <div className="body_med counter_counterWrap__U0_kk">
                                    <span>Service Lifts</span>
                                  </div>
                                  <div className="counter_counter_wrapper__amSb0  counter_smallSize__10lVe">
                                    {servicelifts > 0 ? (
                                      <>
                                        <span
                                          className="caption_subdued_large"
                                          style={{
                                            cursor: "default",
                                          }}
                                          onClick={() => {
                                            setServiceLifts(servicelifts - 1);
                                          }}
                                        >
                                          <i>
                                            <BiMinus />
                                          </i>
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          className="caption_subdued_large"
                                          style={{
                                            cursor: "pointer",
                                            opacity: "0.2",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <i>
                                            <BiMinus />
                                          </i>
                                        </span>
                                      </>
                                    )}
                                    <span className="caption_strong_large">
                                      {servicelifts}
                                    </span>
                                    <span
                                      onClick={() => {
                                        setServiceLifts(servicelifts + 1);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i>
                                        <BiPlus />
                                      </i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <ParkingType
                  parkingType={parkingType}
                  parkingPrivateType={parkingPrivateType}
                  parkingPublicType={parkingPublicType}
                  parkingError={parkingError}
                />

                <AvaliabilityStatus
                  getPropertyAvailability={getPropertyAvailability}
                  getPropertyAge={getPropertyAge}
                  abilityStatusError={availabilityError}
                  selectAgeError={selectAgeError}
                  timePossessionError={timePossessionError}
                  getPropertyExpectedYear={getPropertyExpectedYear}
                  getPropertyExpectedMonth={getPropertyExpectedMonth}
                  constructionOnProperty={constructionOnProperty}
                />

                <AvaliableFrom
                  availableFromDate={availableFromDate}
                  availabilityDateError={availableDateError}
                />
              </div>
            </div>

            <div id="Location-widget">
              <div id="PrimaryDetailsButtonsWrapper-widget">
                <div className="dummyFormWidget_flexContainer__2vVMv">
                  <div
                    id="PrimaryDetailsButton-button"
                    className="pageComponent"
                  >
                    <div>
                      <div className="buttonInput_continue_btn__1gb5M ">
                        <button
                          type="button"
                          className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                          onClick={(e) => propertyProfileClick(e)}
                        >
                          <span>
                            <span>Continue</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfficePropertyDetails;
