import React, { useEffect, useState } from "react";
import { FcAreaChart } from "react-icons/fc";
import { FcDataConfiguration } from "react-icons/fc";
import { ImPriceTags } from "react-icons/im";
import { FcAddressBook } from "react-icons/fc";
import { SiVirustotal } from "react-icons/si";
import { BsBorderTop, BsBrowserSafari } from "react-icons/bs";
import { BsInfoSquareFill } from "react-icons/bs";
import { AiFillPropertySafety } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import { RiGraduationCapFill } from "react-icons/ri";
import { RiBankCardFill } from "react-icons/ri";
import { FaFirstAid } from "react-icons/fa";
import { RiFirstAidKitFill } from "react-icons/ri";
import { CiBank } from "react-icons/ci";
import { FaTrain } from "react-icons/fa";
import { AiOutlineTrademarkCircle } from "react-icons/ai";
import { IoIosCheckmark } from "react-icons/io";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { GiWaterSplash } from "react-icons/gi";
import { FcCollect } from "react-icons/fc";
import { TbAirConditioning } from "react-icons/tb";
import { GiCelebrationFire } from "react-icons/gi";
import { FcEngineering } from "react-icons/fc";
import { FcCloseUpMode } from "react-icons/fc";
import { FcShop } from "react-icons/fc";
import { FcSupport } from "react-icons/fc";
import { FcAndroidOs } from "react-icons/fc";
import { FcLibrary } from "react-icons/fc";
import { FcReuse } from "react-icons/fc";
import { FcNfcSign } from "react-icons/fc";
import { FcInTransit } from "react-icons/fc";
import { FcLandscape } from "react-icons/fc";
import { FcFilm } from "react-icons/fc";
import { FcElectricalThreshold } from "react-icons/fc";
import { FcManager } from "react-icons/fc";
import { FcMindMap } from "react-icons/fc";
import moment from "moment/moment";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import { Navigation, Autoplay } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { PiImagesSquare } from "react-icons/pi";

const PlotLandOverview = (props) => {
  const [property, setProperty] = useState({});
  const [propertyRule, setPropertyRule] = useState({});
  const [postMobile, setPostMobile] = useState("");
  const [propertyImagesWithName, setPropertyImagesWithName] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const singleProperty = props.singlProperty;
    setProperty(singleProperty);
    setPropertyRule(props.propertyRule);
    setPostMobile(singleProperty.postUserMobile);
    setPropertyImagesWithName(singleProperty.titleImg);
  }, [props.singlProperty, props.propertyRule]);

  const propertyImageClick = () => {
    navigate("/property-image", {
      state: { propertyImages: props.propertyImages },
    });
  };

  return (
    <div style={{ backgroundColor: "#f5f7fa" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              navigation={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Autoplay]}
              className="mySwiper rounded"
            >
              {props.propertyImages.length > 0 ? (
                <>
                  {props.propertyImages.map((img, index) => (
                    <SwiperSlide key={index}>
                      <img src={img} class="d-block w-100 img-round" alt="" />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "30px",
                        }}
                        onClick={(e) => propertyImageClick(e)}
                      >
                        <PiImagesSquare
                          style={{ fontSize: "35px", color: "#fff" }}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                <>
                  <SwiperSlide>
                    <img src="/assets/images/noimage1.png" alt="" />
                  </SwiperSlide>
                </>
              )}
            </Swiper>
          </div>

          <div className="col-lg-6 row overview-banner2">
            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <FcAreaChart
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Area</p>
                </div>
                <strong>
                  Plot Area :{" "}
                  {property.carpetArea + " " + property.carpetAreaMesurmentType}
                </strong>
                {/* <p>{property.carpetAreaMesurmentType}</p> */}
              </div>
            </div>
            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <ImPriceTags
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Price</p>
                </div>
                {property.pricePerSqft === 0 ? (
                  <>
                    <strong>₹ {property.visiblePrice}</strong>
                  </>
                ) : (
                  <>
                    <strong>
                      ₹ {property.visiblePrice}
                      {property.pricePerSqft}per{" "}
                      {property.carpetAreaMesurmentType}
                    </strong>
                  </>
                )}
              </div>
            </div>

            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <FcAddressBook
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Address</p>
                </div>
                <strong>
                  {property.locality +
                    ", " +
                    property.city +
                    ", " +
                    property.state}
                </strong>
              </div>
            </div>
            {property.propertyFacing === "" ? (
              <></>
            ) : (
              <>
                <div className="col-lg-6 banner2-div">
                  <div style={{ textAlign: "left" }}>
                    <div className="col banner2">
                      <span>
                        <FcDataConfiguration
                          style={{ fontSize: "30px", paddingRight: "5px" }}
                        />
                      </span>
                      <p>Facing</p>
                    </div>
                    <strong>{property.propertyFacing}</strong>
                  </div>
                </div>
              </>
            )}

            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <BsInfoSquareFill
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Floor allowed</p>
                </div>
                <strong>{property.allowedNoofFloors}</strong>
              </div>
            </div>
            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <BsInfoSquareFill
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Posted By</p>
                </div>
                <strong>{property.postUserPosition}</strong>
              </div>
            </div>
            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <BsBrowserSafari
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Posted On</p>
                </div>
                <strong>
                  {moment(property.createdAt).format("DD MMMM YYYY")}
                </strong>
              </div>
            </div>

            {/* <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <SiVirustotal
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Floor Number</p>
                </div>
                <strong>
                  {property.propertyOnFloor + " of " + property.noofFloor}Floors
                </strong>
              </div>
            </div>
            <div className="col-lg-6 banner2-div">
              <div style={{ textAlign: "left" }}>
                <div className="col banner2">
                  <span>
                    <AiFillPropertySafety
                      style={{ fontSize: "30px", paddingRight: "5px" }}
                    />
                  </span>
                  <p>Property Age</p>
                </div>
                <strong>
                  {property.propertyAge}
                
                </strong>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{
          backgroundColor: "#f5f7fa",
          marginTop: "40px",
          padding: "20px 0px",
          borderTop: "1px solid #ddd",
          paddingTop: "20px",
        }}
      >
        <div className="row">
          <div className="col-lg-3 textalign">
            <div className="">
              <div style={{ paddingBottom: "7px" }}>
                Width of Plot : <b>{property.widthofPlot} Sq.Ft.</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Length of Plot : <b>{property.lengthofPlot} Sq.Ft.</b>
              </div>
              <div style={{ paddingBottom: "5px" }}>
                Width of facing road : <b>{property.roadWidth} Feet</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 textalign">
            <div className="">
              <div style={{ paddingBottom: "7px" }}>
                Property Ownership : <b>{property.ownerShip}</b>
              </div>

              <div>
                Property Code : <b>{property.propertyID}</b>
              </div>
              {property.superCategory === "Commercial" ? (
                <>
                  <div>
                    Approved By :
                    <b>
                      {property.dTCP === true ? " DTCP , " : "None"}
                      {property.gHMC === true ? " GHMC , " : "None"}
                      {property.hMDA === true ? " HMDA" : "None"}
                    </b>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-lg-3 textalign">
            <div className="">
              <div style={{ paddingBottom: "7px" }}>
                Floor Allowed : <b>{property.allowedNoofFloors}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                Road Facing : <b>{property.facingRoad}</b>
              </div>
              <div style={{ paddingBottom: "7px" }}>
                No of Open Sides : <b>{property.noofOpenSides}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 textalign">
            <div className="">
              {property.wallboundryAvaliable ? (
                <>
                  <div style={{ paddingBottom: "7px" }}>
                    Boundry Wall Avaliable : <b>Yes</b>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ paddingBottom: "7px" }}>
                    Boundry Wall Avaliable : <b>No</b>
                  </div>
                </>
              )}
              {property.ConustructionOnthisProperty ? (
                <>
                  <div style={{ paddingBottom: "7px" }}>
                    Conustruction on this Property : <b>Yes</b>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ paddingBottom: "7px" }}>
                    Conustruction on this Property : <b>No</b>
                  </div>
                </>
              )}
              {property.availabilityStatus === "Under Construction" ? (
                <>
                  <div style={{ paddingBottom: "7px" }}>
                    Available From : <b>{property.propertyPossessionBy}</b>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ paddingBottom: "7px" }}>
                    Property Age : <b>{property.propertyAge}</b>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{
          borderTop: "1px solid #ddd",
          paddingTop: "20px",
          paddingBottom: "30px",
        }}
      >
        <div className="textalign">
          <div className="" style={{ paddingBottom: "7px" }}>
            <b>About Property</b>
          </div>
          <div className="" style={{ paddingBottom: "7px" }}>
            <b> Address</b>:{" "}
            {property.apartment +
              ", " +
              property.locality +
              ", " +
              property.city +
              ", " +
              property.state}
          </div>
          <div className="" style={{ paddingBottom: "7px" }}>
            About: {property.unicProperty}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlotLandOverview;
