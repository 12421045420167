import React, { useEffect, useState } from "react";
import "./propertyinfonew/BossPlans.css";
import { useParams } from "react-router-dom";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import Subbanner from "./propertyinfonew/Subbanner";
import Overview from "./propertyinfonew/Overview";
import Dealerdetails from "./propertyinfonew/Dealerdetails";
import Registryrecord from "./propertyinfonew/Registryrecord";
import Explorelocality from "./propertyinfonew/Explorelocality";
import Recommendation from "./propertyinfonew/Recommendation";
import Articles from "./propertyinfonew/Articles";
import Society from "./propertyinfonew/Society";
import { ColorRing } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  setPropertyInfoSingleProperty,
  setPropertyInfoSinglePropertyCheck,
} from "../redux/property/PropertySlice";
import PgSubbanner from "./propertyinfonew/PgSubbanner";
import PlotLandSubbanner from "./propertyinfonew/PlotLandSubbanner";
import CommercialSubbanner from "./propertyinfonew/CommercialSubbanner";
import RentOverView from "./propertyinfonew/RentOverView";
import PgOverView from "./propertyinfonew/PgOverView";
import CommercialOverview from "./propertyinfonew/CommercialOverview";
import PlotLandOverview from "./propertyinfonew/PlotLandOverview";
import ResidentialOverview from "./propertyinfonew/ResidentialOverview";
import OfficeOverview from "./propertyinfonew/OfficeOverview";
import RetailSubBanner from "./propertyinfonew/RetailSubBanner";
import RetailOverview from "./propertyinfonew/RetailOverview";
import OtherSubBanner from "./propertyinfonew/OtherSubBanner";
import OtherOverview from "./propertyinfonew/OtherOverview";
import FarmhouseOverview from "./propertyinfonew/FarmhouseOverview";
import OfficeComSubbanner from "./propertyinfonew/OfficeComSubbanner";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

const PropertyInfoNew = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [property, setProperty] = useState({});
  const [propertyRule, setPropertyRule] = useState({});
  const [propertyIncludePrice, setPropertyIncludePrice] = useState({});
  const [propertyImages, setPropertyImages] = useState([]);
  const [propertyImagesWithName, setPropertyImagesWithName] = useState([]);
  const [propertySingleImage, setPropertySingleImage] = useState("");
  const [tempLoading, setTempLoading] = useState(true);

  useEffect(() => {
    if (params.apartname !== "") {
      try {
        const fetchdata = async () => {
          // const url = `${Baseurl}/api/v1/property/single-propertyby/${params.apartname}/${params.propertyid}`;
          const url = `${Baseurl}/api/v1/property/propertybyprojectid/${params.propertyid}`;
          const fetchProperty = await axios(url);
          if (fetchProperty.data.success === true) {
            let singleProperty = fetchProperty.data.property;
            setProperty(singleProperty);
            setPropertyRule(singleProperty.houseRules);
            setPropertyIncludePrice(singleProperty.includesprice);
            setPropertyImages(singleProperty.DeskImg);
            setPropertySingleImage(singleProperty.thumbnail);
            setPropertyImagesWithName(singleProperty.titleImg);
            setTempLoading(false);
          }
        };
        fetchdata();
      } catch (error) {}
    }
  }, [params.apartname]);

  const currentUrl = window.location.href;

  return (
    <>
      {tempLoading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh",
            }}
          >
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        </>
      ) : (
        <>
          {property.superCategory === "Residential" ? (
            <>
              {property.category === "Sell" || property.category === "Rent" ? (
                <>
                  {property.subCategory === "Plot" ||
                  property.subCategory === "Land" ? (
                    <>
                      <PlotLandSubbanner singlProperty={property} />
                      <PlotLandOverview
                        singlProperty={property}
                        propertyRule={propertyRule}
                        propertyImages={propertyImages}
                        propertyImagesWithName={propertyImagesWithName}
                        propertySingleImage={propertySingleImage}
                      />
                    </>
                  ) : (
                    <>
                      {property.subCategory === "Farmhouse" ? (
                        <>
                          <Subbanner singlProperty={property} />
                          <FarmhouseOverview
                            singlProperty={property}
                            propertyRule={propertyRule}
                            propertyIncludePrice={propertyIncludePrice}
                            propertyImages={propertyImages}
                            propertyImagesWithName={propertyImagesWithName}
                            propertySingleImage={propertySingleImage}
                          />
                        </>
                      ) : (
                        <>
                          <Subbanner singlProperty={property} />
                          <ResidentialOverview
                            singlProperty={property}
                            propertyRule={propertyRule}
                            propertyIncludePrice={propertyIncludePrice}
                            propertyImages={propertyImages}
                            propertyImagesWithName={propertyImagesWithName}
                            propertySingleImage={propertySingleImage}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <PgSubbanner singlProperty={property} />
                  <PgOverView
                    singlProperty={property}
                    propertyRule={propertyRule}
                    propertyIncludePrice={propertyIncludePrice}
                    propertyImages={propertyImages}
                    propertyImagesWithName={propertyImagesWithName}
                    propertySingleImage={propertySingleImage}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {property.subCategory === "Plot" ||
              property.subCategory === "Land" ? (
                <>
                  <PlotLandSubbanner singlProperty={property} />
                  <PlotLandOverview
                    singlProperty={property}
                    propertyRule={propertyRule}
                    propertyImages={propertyImages}
                    propertyImagesWithName={propertyImagesWithName}
                    propertySingleImage={propertySingleImage}
                  />
                </>
              ) : (
                <>
                  {property.subCategory === "Office" ? (
                    <>
                      <OfficeComSubbanner singlProperty={property} />
                      <OfficeOverview
                        singlProperty={property}
                        propertyRule={propertyRule}
                        propertyIncludePrice={propertyIncludePrice}
                        propertyImages={propertyImages}
                        propertyImagesWithName={propertyImagesWithName}
                        propertySingleImage={propertySingleImage}
                      />
                    </>
                  ) : (
                    <>
                      {property.subCategory === "Retail" ? (
                        <>
                          <RetailSubBanner singlProperty={property} />
                          <RetailOverview
                            singlProperty={property}
                            propertyRule={propertyRule}
                            propertyIncludePrice={propertyIncludePrice}
                            propertyImages={propertyImages}
                            propertyImagesWithName={propertyImagesWithName}
                            propertySingleImage={propertySingleImage}
                          />
                        </>
                      ) : (
                        <>
                          <OtherSubBanner singlProperty={property} />
                          <OtherOverview
                            singlProperty={property}
                            propertyRule={propertyRule}
                            propertyIncludePrice={propertyIncludePrice}
                            propertyImages={propertyImages}
                            propertyImagesWithName={propertyImagesWithName}
                            propertySingleImage={propertySingleImage}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}

          <Recommendation
            singlProperty={property}
            propertyImages={propertyImages}
          />
        </>
      )}
      <div className="whatsappcontainer1">
        <div className="whatsappcontainer">
          <WhatsappShareButton url={currentUrl} title="My Golden Homes">
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <div style={{ marginTop: "5px", marginBottom: "6px" }}>
            <FacebookShareButton url={currentUrl} title="My Golden Homes">
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
          </div>
          <div style={{ marginBottom: "16px" }}>
            <TwitterShareButton url={currentUrl} title="My Golden Homes">
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyInfoNew;
