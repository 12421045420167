import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Baseurl, Baseurl2 } from "../config/BaseUrl";
import { CiSearch } from "react-icons/ci";
import { FaChevronDown } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa6";
import Switch from "@mui/material/Switch";
import ReactSlider from "react-slider";
import { MdBlock } from "react-icons/md";
import { CiStar } from "react-icons/ci";
import { CgArrowTopRight } from "react-icons/cg";

import PropertyDetailsModel from "./home/latestProperty/PropertyDetailsModel";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { pageStatusChange } from "../redux/property/PropertySlice";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import moment from "moment/moment";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdOutlineSearchOff } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import { CiFilter } from "react-icons/ci";
import { FaSort } from "react-icons/fa6";
import { HiMiniUserCircle } from "react-icons/hi2";
import BottomSheet from "./BottomSheet";
import HideAlreadySeen from "./FilterComponents/HideAlreadySeen";
import Verified from "./FilterComponents/Verified";
import { useSpring, animated } from "react-spring";
import MainBottomSheetComponent from "./MainBottomSheetComponent";
import { toast } from "react-toastify";

const SearchPropertyy = () => {
  const { searchObjects } = useSelector((store) => store.Search);
  const { subCatTotal } = useSelector((store) => store.SubCategory);
  const { isAuth, loginData } = useSelector((store) => store.Athentication);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const minref = useRef();
  const maxref = useRef();

  const [currentPropertyIndex, setCurrentPropertyIndex] = useState("");

  const [minShow, setMinShow] = useState(false);
  const [maxShow, setMaxShow] = useState(false);

  const [fetchProperty, setFetchProperty] = useState([]);
  const [showProperty, setShowProperty] = useState([]);
  const [sublocalityBycity, setsublocalityBycity] = useState([]);
  const [projectsBycity, setProjectBycity] = useState([]);
  const [loadingdata, setLoadingdata] = useState(true);

  const [allselectedFilters, setAllselectedFilters] = useState([]);

  console.log(allselectedFilters, "allselectedFilters");

  const [modal, setModal] = useState(false);
  const [searchmodal, setsearchModal] = useState(false);
  const [maximumtriceShow, setmaximumtriceShow] = useState(false);
  const [maximumareaShow, setmaximumareaShow] = useState(false);

  const [minimumtriceShow, setminimumtriceShow] = useState(false);
  const [minimumareaShow, setminimumareaShow] = useState(false);

  const [bhxmoreShow, setBhxmoreShow] = useState(false);
  const [subcatmoreShow, setSubmoreShow] = useState(false);

  const [budgetShow, setBudgetShow] = useState(true);
  const [typeOfProperty, setTypeOfProperty] = useState(true);
  const [typeOfOffice, setTypeOfOffice] = useState(true);
  const [availableFor, setAvailableFor] = useState(true);
  const [sharing, setSharing] = useState(true);
  const [pgService, setPgService] = useState(true);
  const [noofBedrooms, setNoofBedrooms] = useState(true);
  const [constructionStatus, setConstructionStatus] = useState(false);
  const [postedBy, setPostedBy] = useState(false);
  const [area, setArea] = useState(false);
  const [totalCapacity, setTotalCapacity] = useState(false);
  const [pgBedRoom, setPgBedRoom] = useState(false);
  const [localities, setLocalities] = useState(false);
  const [aminities, setAminities] = useState(false);
  const [furnishingStatusShow, setFurnishingStatusShow] = useState(false);
  const [reraStatusShow, setReraStatusShow] = useState(false);

  const [singleProperty, setSingleProperty] = useState("");
  const [closepopup, setClosepopup] = useState(false);
  const [maximumshowPrice, setmaximumshowPrice] = useState("100+ Cr");
  const [minimumshowPrice, setminimumshowPrice] = useState("0");
  const [maximumshowArea, setmaximumshowarea] = useState("10000+ sq.ft.");
  const [minimumshowarea, setminimumshowarea] = useState("0");

  const [showSubcat, setShowSubcat] = useState([]);
  const [searchArray, setSearchArray] = useState([]);
  const [selectedSubcat, setSelectedSubcat] = useState([]);
  const [showOfficeList, setOfficeList] = useState(false);
  const [showSelcectedOfficeList, setSelcectedOfficeList] = useState([]);
  const [showLandList, setLandList] = useState(false);
  const [showSelcectedLandList, setSelcectedLandList] = useState([]);
  const [selectedSubLocality, setSelectedSubLocality] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedBedrooms, setSelectedBedrooms] = useState([]);
  const [selectedAvailableFor, setSelectedAvailableFor] = useState([]);
  const [selectedConustruction, setSelectedConustruction] = useState([]);
  const [selectedPostedBy, setSelectedPostedBy] = useState([]);
  const [propertyPrice, setPropertyPrice] = useState([0, 1000000000]);
  const [propertyPriceMax, setPropertyPriceMax] = useState(1000000000);

  const [propertyPriceMini, setPropertyPriceMini] = useState(0);
  const [selectedFurnished, setSelectedFurnished] = useState([]);
  const [selectedSharing, setSelectedSharing] = useState([]);
  const [selectedAvailables, setSelectedAvailables] = useState([]);
  const [selectedSeatType, setselectedSeatType] = useState([]);
  const [selectedNoSeat, setselectedNoSeat] = useState([]);
  const [selectedPlan, setselectedPlan] = useState([]);
  const [selectLand, setSelectLand] = useState([]);
  const [selectedArea, setSelectedArea] = useState([0, 10000]);
  const [selectProjectStatus, setSelectProjectStatus] = useState([]);
  const [selectReraapprovels, setSelectReraapprovels] = useState([]);
  const [selectCommersialOfficeType, setSelectCommersialOfficeType] = useState(
    []
  );

  const [parking, setparking] = useState(false);
  const [park, setpark] = useState(false);
  const [powerbackup, setpowerbackup] = useState(false);
  const [lifts, setLifts] = useState(false);
  const [pool, setPool] = useState(false);

  const [wifi, setWifi] = useState(false);
  const [foodService, setFoodService] = useState(false);
  const [acRoom, setAcRoom] = useState(false);
  const [laundry, setLaundry] = useState(false);
  const [wheelchair, setWheelchair] = useState(false);
  const [pet, setPet] = useState(false);

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [filterComponentIndex, setFilterComponentIndex] = useState(0);

  const [minBudget, setminBudget] = useState("Min Budget");
  const [maxBudget, setMaxBudget] = useState("Max Budget");

  const [minArea, setMinArea] = useState("Min Area");
  const [maxArea, setMaxArea] = useState("Max Area");

  const [isFixed, setIsFixed] = useState(false);

  const commercialTypeofOffice = [
    { name: "Ready to move office space" },
    { name: "Bare shell office space" },
    { name: "Co-working office space" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const desiredPosition = 1850;
      setIsFixed(scrollPosition > desiredPosition);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const changeFilterCompoIndex = (value) => {
    setFilterComponentIndex(value);
  };
  const handleOpen = () => {
    setIsBottomSheetOpen(true);
  };

  const handleOfficeListPress = (name) => {
    if (selectedSubcat.includes("Office")) {
      if (showSelcectedOfficeList.includes(name)) {
        setSelcectedOfficeList(
          showSelcectedOfficeList.filter((value) => value !== name)
        );
      } else {
        setSelcectedOfficeList([...showSelcectedOfficeList, name]);
        // Filter showProperty based on typeOfland equal to name
        const newProperties = showProperty.filter(
          (individualProperty) => individualProperty.typeOfland === name
        );
        // Update showProperty state with filtered properties
        setShowProperty(newProperties);
      }
    }
  };

  const handleLandListPress = (name) => {
    if (selectedSubcat.includes("Land")) {
      if (showSelcectedLandList.includes(name)) {
        setSelcectedLandList(
          showSelcectedLandList.filter((value) => value !== name)
        );
      } else {
        setSelcectedLandList([...showSelcectedLandList, name]);
        const newProperties = showProperty.filter(
          (individualProperty) => individualProperty.typeOfland === name
        );
        setShowProperty(newProperties);
      }
    }
  };

  const handleClose = () => {
    setIsBottomSheetOpen(false);
  };

  const openBottomSheet = () => {
    setIsBottomSheetOpen(!isBottomSheetOpen);
  };

  useEffect(() => {
    const clickoutside = (e) => {
      if (isBottomSheetOpen && ref.current && !ref.current.contains(e.target)) {
        setIsBottomSheetOpen(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [isBottomSheetOpen]);

  const toggle = () => setModal(!modal);

  const closePropDetailModal = () => {
    setModal(false);
  };

  useEffect(() => {
    const clickoutside = (e) => {
      if (closepopup && ref.current && !ref.current.contains(e.target)) {
        setClosepopup(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [ref]);

  useEffect(() => {
    let supCatkey = params.supercat;
    let catkey = params.cat;
    let formdata = searchObjects;
    let subCatkey = params.subcat;

    const filterSubcat = subCatTotal.filter(
      (data) => data.superCategory === supCatkey && data.category === catkey
    );
    setShowSubcat(filterSubcat);

    setSelectedBedrooms([...formdata.selectedBedrooms]);
    setSelectedConustruction([...formdata.selectedConustruction]);
    setPropertyPrice([...formdata.propertyPrice]);
    setPropertyPriceMax(formdata.propertyPrice[1]);

    setPropertyPriceMini(0);
    setSelectedPostedBy([...formdata.selectedPostedBy]);
    // setSelectedAvailableFor([...formdata.selectedAvailableFor]);
    // setSelectedSharing([...formdata.selectedSharing]);

    setSearchArray([...formdata.selectedCity]);
    let subcatlist = [];
    if (subCatkey.match("-")) {
      subcatlist = subCatkey.split("-");
    }
    subcatlist = [...subcatlist, ...formdata.selectedSubcat];

    setSelectedSubcat([...subcatlist]);

    let allfilter = [];
    allfilter = [
      ...allfilter,
      ...formdata.selectedCity,
      ...formdata.selectedBedrooms,
      ...formdata.selectedConustruction,
      ...formdata.propertyPrice,
      ...formdata.selectedPostedBy,
      ...formdata.selectedSubcat,
    ];

    setAllselectedFilters([...allfilter]);
  }, [
    params.key,
    params.supercat,
    params.cat,
    params.subcat,
    searchObjects,
    fetchProperty,
    subCatTotal,
  ]);

  useEffect(() => {
    let selectedFields = allselectedFilters;
    if (selectedSubcat.length > 0) {
      selectedFields = [...selectedFields, ...selectedSubcat];
    }
    if (minimumshowPrice !== "0" && maximumshowPrice !== "100+ Cr") {
      selectedFields = selectedFields.filter(
        (word) => !word.toLowerCase().includes("₹")
      );
      const priceText = `₹ ${minimumshowPrice} - ${maximumshowPrice}`;

      selectedFields = [...selectedFields, priceText];
    }
    if (selectedBedrooms.length > 0) {
      selectedFields = [
        ...selectedFields,
        ...selectedBedrooms.map((bedroom) => bedroom + "BHK"),
      ];
    }
    if (selectedConustruction.length > 0) {
      selectedFields = [...selectedFields, ...selectedConustruction];
    }
    if (selectedPostedBy.length > 0) {
      selectedFields = [...selectedFields, ...selectedPostedBy];
    }
    if (selectedFurnished.length > 0) {
      selectedFields = [...selectedFields, ...selectedFurnished];
    }
    if (selectedAvailableFor.length > 0) {
      selectedFields = [...selectedFields, ...selectedAvailableFor];
    }
    if (selectedSharing.length > 0) {
      selectedFields = [...selectedFields, ...selectedSharing];
    }

    const uniqvalue = [...new Set(selectedFields)];
    setAllselectedFilters(uniqvalue);
  }, [
    selectedSubcat,
    minimumshowPrice,
    maximumshowPrice,
    selectedBedrooms,
    selectedConustruction,
    selectedPostedBy,
    selectedFurnished,
    selectedAvailableFor,
    selectedSharing,
  ]);

  useEffect(() => {
    const minimumPrice = propertyPriceMini;
    const maximumPrice = propertyPriceMax;
    let minimumVisblePrice = "";
    if (Number(minimumPrice) >= 1000 && Number(minimumPrice) <= 99999) {
      minimumVisblePrice = String(minimumPrice);
    } else if (
      Number(minimumPrice) <= 9999999 &&
      Number(minimumPrice) >= 100000
    ) {
      minimumVisblePrice = (Number(minimumPrice) / 100000).toFixed(1);
      minimumVisblePrice = `${minimumVisblePrice} lac`;
    } else if (Number(minimumPrice) > 10000000) {
      minimumVisblePrice = (Number(minimumPrice) / 10000000).toFixed(1);
      minimumVisblePrice = `${minimumVisblePrice} cr`;
    } else {
      minimumVisblePrice = `0`;
    }
    setminimumshowPrice(minimumVisblePrice);

    let maximumVisblePrice = "";
    if (Number(maximumPrice) >= 1000 && Number(maximumPrice) <= 99999) {
      maximumVisblePrice = String(maximumPrice);
    } else if (
      Number(maximumPrice) <= 9999999 &&
      Number(maximumPrice) >= 100000
    ) {
      maximumVisblePrice = (Number(maximumPrice) / 100000).toFixed(1);
      maximumVisblePrice = `${maximumVisblePrice} lac`;
    } else if (Number(maximumPrice) > 10000000) {
      maximumVisblePrice = (Number(maximumPrice) / 10000000).toFixed(1);
      maximumVisblePrice = `${maximumVisblePrice} cr`;
    } else {
      maximumVisblePrice = "100+ Cr";
    }
    setmaximumshowPrice(maximumVisblePrice);
  }, [propertyPriceMini, propertyPriceMax]);

  const propertyPriceChange = (value) => {
    setPropertyPrice(value);
    setPropertyPriceMax(value[1]);
    setPropertyPriceMini(value[0]);
    const minimumPrice = value[0];
    const maximumPrice = value[1];
    setminBudget(minimumPrice);
    setMaxBudget(maximumPrice);

    let minimumVisblePrice = "";
    if (Number(minimumPrice) >= 1000 && Number(minimumPrice) <= 99999) {
      minimumVisblePrice = String(minimumPrice);
    } else if (
      Number(minimumPrice) <= 9999999 &&
      Number(minimumPrice) >= 100000
    ) {
      minimumVisblePrice = (Number(minimumPrice) / 100000).toFixed(1);
      minimumVisblePrice = `${minimumVisblePrice} lac`;
    } else if (Number(minimumPrice) > 10000000) {
      minimumVisblePrice = (Number(minimumPrice) / 10000000).toFixed(1);
      minimumVisblePrice = `${minimumVisblePrice} cr`;
    } else {
      minimumVisblePrice = `0`;
    }
    setminimumshowPrice(minimumVisblePrice);

    let maximumVisblePrice = "";
    if (Number(maximumPrice) >= 1000 && Number(maximumPrice) <= 99999) {
      maximumVisblePrice = String(maximumPrice);
    } else if (
      Number(maximumPrice) <= 9999999 &&
      Number(maximumPrice) >= 100000
    ) {
      maximumVisblePrice = (Number(maximumPrice) / 100000).toFixed(1);
      maximumVisblePrice = `${maximumVisblePrice} lac`;
    } else if (Number(maximumPrice) > 10000000) {
      maximumVisblePrice = (Number(maximumPrice) / 10000000).toFixed(1);
      maximumVisblePrice = `${maximumVisblePrice} cr`;
    } else {
      maximumVisblePrice = "100+ Cr";
    }
    setmaximumshowPrice(maximumVisblePrice);
  };

  useEffect(() => {
    const maximumArea = selectedArea[1];
    const minimumArea = selectedArea[0];

    let maximumVisblearea = "";

    maximumVisblearea = `${maximumArea} sq.ft.`;
    setmaximumshowarea(maximumVisblearea);

    let minimumVisblearea = "";
    minimumVisblearea = `${minimumArea} sq.ft.`;
    setminimumshowarea(minimumVisblearea);
  }, [selectedArea]);

  const selectAreaDetailsPress = (value) => {
    setSelectedArea(value);
    const maximumArea = value[1];
    const minimumArea = value[0];
    setMaxArea(maximumArea);
    setMinArea(minimumArea);

    let maximumVisblearea = "";
    maximumVisblearea = `${maximumArea} sq.ft.`;
    setmaximumshowarea(maximumVisblearea);

    let minimumVisblearea = "";
    minimumVisblearea = `${minimumArea} sq.ft.`;
    setminimumshowarea(minimumVisblearea);
  };

  //============property filtering Search Start by manoj============
  console.log(searchObjects, "searchObjects");

  useEffect(() => {
    let fetchPropertys = [];
    let citySearch = searchObjects.selectedCity;

    for (let index = 0; index < citySearch.length; index++) {
      const element = citySearch[index];
      try {
        const fetchPropertybycity = async () => {
          const url = `${Baseurl2}/api/v1/property/propertybycity/${element}`;
          const fetchProperties = await axios.get(url);
          if (fetchProperties.data.success) {
            let finalproperty = [];
            let property = fetchProperties.data.propertybycity;
            finalproperty = [...finalproperty, ...property];
            fetchPropertys = [...fetchPropertys, ...finalproperty];
            setFetchProperty(finalproperty);
          }
        };
        fetchPropertybycity();
      } catch (error) {}
    }
    for (let index = 0; index < citySearch.length; index++) {
      const element = citySearch[index];
      try {
        const fetchPropertybylocality = async () => {
          const url = `${Baseurl2}/api/v1/property/allPropertylocality/${element}`;
          const fetchProperties = await axios.get(url);
          if (fetchProperties.data.success) {
            let finalproperty = fetchPropertys;
            let property = fetchProperties.data.properties;
            finalproperty = [...finalproperty, ...property];
            fetchPropertys = [...fetchPropertys, ...finalproperty];
            setFetchProperty(fetchPropertys);
          }
        };
        fetchPropertybylocality();
      } catch (error) {}
    }
    for (let index = 0; index < citySearch.length; index++) {
      const element = citySearch[index];
      try {
        const fetchPropertybylocality = async () => {
          const url = `${Baseurl2}/api/v1/property/allPropertyapartment/${element}`;
          const fetchProperties = await axios.get(url);
          if (fetchProperties.data.success) {
            let finalproperty = fetchPropertys;
            let property = fetchProperties.data.properties;
            finalproperty = [...finalproperty, ...property];
            fetchPropertys = [...fetchPropertys, ...finalproperty];
            setFetchProperty(fetchPropertys);
          }
        };
        fetchPropertybylocality();
      } catch (error) {}
    }

    for (let index = 0; index < citySearch.length; index++) {
      const element = citySearch[index];
      try {
        const fetchdata = async () => {
          const url = `${Baseurl2}/api/v1/property/alllocalities/Hyderabad`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.localities;

              const filteredArr = searchresult.filter((item, index) => {
                return searchresult.indexOf(item === index);
              });
              let filterupdateData = [];
              for (let k = 0; k < filteredArr.length; k++) {
                let elementname = filteredArr[k];

                let elementText =
                  elementname.charAt(0).toUpperCase() +
                  elementname.slice(1).toLowerCase();
                filterupdateData = [...filterupdateData, elementText];
              }
              filterupdateData = [...filterupdateData];
              setsublocalityBycity([...filterupdateData]);
            }
          } catch (error) {}
        };

        fetchdata();
      } catch (error) {}
    }

    for (let index = 0; index < citySearch.length; index++) {
      const element = citySearch[index];
      try {
        const fetchProjectdata = async () => {
          const url = `${Baseurl2}/api/v1/property/allprojects/${element}`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.apartments;

              setProjectBycity(searchresult);
            }
          } catch (error) {}
        };

        fetchProjectdata();
      } catch (error) {}
    }
  }, [searchObjects]);

  useEffect(() => {
    let supCatkey = params.supercat;
    let catkey = params.cat;

    let formdata = searchObjects;

    if (fetchProperty.length > 0) {
      let fetchPropertys = fetchProperty;
      if (supCatkey !== "" && fetchPropertys.length > 0) {
        fetchPropertys = fetchPropertys.filter(
          (property) => property.superCategory === supCatkey
        );
        fetchPropertys = fetchPropertys;
      }

      if (catkey !== "" && fetchPropertys.length > 0) {
        fetchPropertys = fetchPropertys.filter(
          (property) => property.category === catkey
        );
        fetchPropertys = fetchPropertys;
        // if (catkey === "Coworking") {
        //   fetchPropertys = fetchPropertys.filter(
        //     (property) => property.typeOfland === "Co-working office space"
        //   );
        //   fetchPropertys = fetchPropertys;
        // }else{
        //   fetchPropertys = fetchPropertys.filter(
        //     (property) => property.category === catkey
        //   );
        //   fetchPropertys = fetchPropertys;
        // }
      }

      if (selectedSubcat.length === 0) {
        fetchPropertys = fetchPropertys;
      } else {
        let subcatproperty = [];
        for (let index = 0; index < selectedSubcat.length; index++) {
          const subcat = selectedSubcat[index];
          let filtersubcat = fetchPropertys.filter(
            (property) => property.subCategory === subcat
          );
          subcatproperty = [...subcatproperty, ...filtersubcat];
        }
        fetchPropertys = subcatproperty;
      }
      if (selectedBedrooms.length === 0) {
        fetchPropertys = fetchPropertys;
      } else {
        let bedroomproperty = [];
        for (let index = 0; index < selectedBedrooms.length; index++) {
          const bedroom = selectedBedrooms[index];
          let filterbedRoom = fetchPropertys.filter(
            (property) => property.noofBedrooms === bedroom
          );
          bedroomproperty = [...bedroomproperty, ...filterbedRoom];
        }
        fetchPropertys = bedroomproperty;
      }

      if (selectedConustruction.length === 0) {
        fetchPropertys = fetchPropertys;
      } else {
        let filrerproperty = [];
        for (let index = 0; index < selectedConustruction.length; index++) {
          const selectElement = selectedConustruction[index];
          let filterElementProperty = fetchPropertys.filter(
            (property) => property.availabilityStatus === selectElement
          );
          filrerproperty = [...filrerproperty, ...filterElementProperty];
        }
        fetchPropertys = filrerproperty;
      }
      if (selectedPostedBy.length === 0) {
        fetchPropertys = fetchPropertys;
      } else {
        let filrerproperty = [];
        for (let index = 0; index < selectedPostedBy.length; index++) {
          const selectElement = selectedPostedBy[index];
          let filterElementProperty = fetchPropertys.filter(
            (property) => property.postUserPosition === selectElement
          );
          filrerproperty = [...filrerproperty, ...filterElementProperty];
        }
        fetchPropertys = filrerproperty;
      }

      if (selectedSubLocality.length === 0) {
        fetchPropertys = fetchPropertys;
      } else {
        let filrerproperty = [];
        for (let index = 0; index < selectedSubLocality.length; index++) {
          const selectElement = selectedSubLocality[index];
          let filterElementProperty = fetchPropertys.filter(
            (property) => property.locality === selectElement
          );
          filrerproperty = [...filrerproperty, ...filterElementProperty];
        }
        fetchPropertys = filrerproperty;
      }

      if (selectedFurnished.length === 0) {
        fetchPropertys = fetchPropertys;
      } else {
        let filrerproperty = [];
        for (let index = 0; index < selectedFurnished.length; index++) {
          const selectElement = selectedFurnished[index];
          let filterElementProperty = fetchPropertys.filter(
            (property) => property.furnishType === selectElement
          );
          filrerproperty = [...filrerproperty, ...filterElementProperty];
        }
        fetchPropertys = filrerproperty;
      }

      if (selectedAvailableFor.length === 0) {
        fetchPropertys = fetchPropertys;
      } else {
        let filrerproperty = [];
        for (let index = 0; index < selectedAvailableFor.length; index++) {
          const selectElement = selectedAvailableFor[index];
          let filterElementProperty = fetchPropertys.filter(
            (property) => property.availableFor === selectElement
          );
          filrerproperty = [...filrerproperty, ...filterElementProperty];
        }
        fetchPropertys = filrerproperty;
      }
      if (selectCommersialOfficeType.length === 0) {
        fetchPropertys = fetchPropertys;
      } else {
        let filrerproperty = [];
        for (
          let index = 0;
          index < selectCommersialOfficeType.length;
          index++
        ) {
          const selectElement = selectCommersialOfficeType[index];
          let filterElementProperty = fetchPropertys.filter(
            (property) => property.typeOfland === selectElement
          );
          filrerproperty = [...filrerproperty, ...filterElementProperty];
        }
        fetchPropertys = filrerproperty;
      }

      if (selectedSharing.length === 0) {
        fetchPropertys = fetchPropertys;
      } else {
        let filrerproperty = [];
        for (let index = 0; index < selectedSharing.length; index++) {
          const selectElement = selectedSharing[index];
          let filterElementProperty = fetchPropertys.filter(
            (property) => property.typeofRoom === selectElement
          );
          filrerproperty = [...filrerproperty, ...filterElementProperty];
        }
        fetchPropertys = filrerproperty;
      }

      if (propertyPrice.length === 0) {
        fetchPropertys = fetchPropertys;
      } else {
        const PropertyPrice = fetchPropertys.filter(
          (item) =>
            item.expectedPrice >= propertyPrice[0] &&
            item.expectedPrice <= propertyPrice[1]
        );
        fetchPropertys = PropertyPrice;
      }
      if (selectedArea.length === 0) {
        fetchPropertys = fetchPropertys;
      } else {
        const PropertyPrice = fetchPropertys.filter(
          (item) =>
            item.carpetArea >= selectedArea[0] &&
            item.carpetArea <= selectedArea[1]
        );
        fetchPropertys = PropertyPrice;
      }

      if (lifts === false) {
        fetchPropertys = fetchPropertys;
      } else {
        const PropertyFilter = fetchPropertys.filter(
          (item) => item.lifts === true
        );
        fetchPropertys = PropertyFilter;
      }
      if (parking === false) {
        fetchPropertys = fetchPropertys;
      } else {
        const PropertyFilter = fetchPropertys.filter(
          (item) =>
            item.numberofCoveredParking !== "" ||
            item.numberofOpenParking !== ""
        );
        fetchPropertys = PropertyFilter;
      }
      if (powerbackup === false) {
        fetchPropertys = fetchPropertys;
      } else {
        const PropertyFilter = fetchPropertys.filter(
          (item) => item.powerBackup !== ""
        );
        fetchPropertys = PropertyFilter;
      }
      if (park === false) {
        fetchPropertys = fetchPropertys;
      } else {
        const PropertyFilter = fetchPropertys.filter(
          (item) => item.parkGarden === true
        );
        fetchPropertys = PropertyFilter;
      }
      if (pool === false) {
        fetchPropertys = fetchPropertys;
      } else {
        const PropertyFilter = fetchPropertys.filter(
          (item) => item.pool === true
        );
        fetchPropertys = PropertyFilter;
      }

      if (wifi === false) {
        fetchPropertys = fetchPropertys;
      } else {
        const PropertyFilter = fetchPropertys.filter(
          (item) => item.includesprice.wifi === true
        );
        fetchPropertys = PropertyFilter;
      }

      if (foodService === false) {
        fetchPropertys = fetchPropertys;
      } else {
        const PropertyFilter = fetchPropertys.filter(
          (item) => item.FoodDetails === true
        );
        fetchPropertys = PropertyFilter;
      }

      if (laundry === false) {
        fetchPropertys = fetchPropertys;
      } else {
        const PropertyFilter = fetchPropertys.filter(
          (item) => item.includesprice.laundry === true
        );
        fetchPropertys = PropertyFilter;
      }

      if (wheelchair === false) {
        fetchPropertys = fetchPropertys;
      } else {
        const PropertyFilter = fetchPropertys.filter(
          (item) => item.wheelchairfriendly === true
        );
        fetchPropertys = PropertyFilter;
      }
      if (pet === false) {
        fetchPropertys = fetchPropertys;
      } else {
        const PropertyFilter = fetchPropertys.filter(
          (item) => item.petFriendly === true
        );
        fetchPropertys = PropertyFilter;
      }

      setLoadingdata(false);
      setShowProperty(fetchPropertys);
    }
  }, [
    params.key,
    params.supercat,
    params.cat,
    searchObjects,
    fetchProperty,
    selectedSubcat,
    selectedBedrooms,
    selectedConustruction,
    selectedPostedBy,
    selectedSubLocality,
    selectedFurnished,
    selectedAvailableFor,
    selectedSharing,
    propertyPrice,
    selectedArea,
    lifts,
    parking,
    powerbackup,
    park,
    pool,
    wifi,
    foodService,
    laundry,
    wheelchair,
    pet,
    selectCommersialOfficeType,
  ]);
  useEffect(() => {
    const officeProperties = showProperty.filter((individualProperty) => {
      return individualProperty.propertyID === "MG16786";
    });
  }, [showProperty]);

  const toggleClick = (e, property) => {
    setModal(!modal);
    setSingleProperty(property);
  };

  const verifyLogin = () => {
    toast.success("Please log in to access this content.", {
      position: "top-center",
      autoClose: 1500,
      className: "custom_toast",
    });
  };
  const changeView = () => {};
  const selectRemoveClick = (data) => {
    const filterdata = allselectedFilters.filter((item) => item !== data);
    setAllselectedFilters(filterdata);

    if (selectedSubcat.length > 0) {
      const filterValue = selectedSubcat.filter((item) => item !== data);
      setSelectedSubcat(filterValue);
    }

    // if (selectedBedrooms.length > 0) {
    //   const filterValue = selectedBedrooms.filter((item) => item !== data);
    //   setSelectedSubcat(filterValue)
    // }
    // if (selectedConustruction.length > 0) {
    //   const filterValue = selectedConustruction.filter((item) => item !== data);
    //   setSelectedSubcat(filterValue)
    // }
    // if (selectedPostedBy.length > 0) {
    //   const filterValue = selectedPostedBy.filter((item) => item !== data);
    //   setSelectedSubcat(filterValue)
    // }
    // if (selectedFurnished.length > 0) {
    //   const filterValue = selectedFurnished.filter((item) => item !== data);
    //   setSelectedSubcat(filterValue)
    // }
  };

  const availforClick = (availvalue) => {
    if (selectedAvailableFor.includes(availvalue)) {
      setSelectedAvailableFor(
        selectedAvailableFor.filter((value) => value !== availvalue)
      );
      const removeFilter = allselectedFilters.filter(
        (data) => data !== availvalue
      );
      setAllselectedFilters([...removeFilter]);
    } else {
      setSelectedAvailableFor([...selectedAvailableFor, availvalue]);
    }
  };

  const roomShareClick = (availvalue) => {
    if (selectedSharing.includes(availvalue)) {
      setSelectedSharing(
        selectedSharing.filter((value) => value !== availvalue)
      );
      const removeFilter = allselectedFilters.filter(
        (data) => data !== availvalue
      );
      setAllselectedFilters([...removeFilter]);
    } else {
      setSelectedSharing([...selectedSharing, availvalue]);
    }
  };

  const addSelectField = (data) => {
    const filterdata = [...allselectedFilters, data];
    setAllselectedFilters(filterdata);
  };

  const handleBedroomPress = (selectvalue) => {
    if (selectedBedrooms.includes(selectvalue)) {
      setSelectedBedrooms(
        selectedBedrooms.filter((value) => value !== selectvalue)
      );
      const removeFilter = allselectedFilters.filter(
        (data) => data !== selectvalue + "BHK"
      );
      setAllselectedFilters([...removeFilter]);
    } else {
      setSelectedBedrooms([...selectedBedrooms, selectvalue]);
    }
  };

  const handleConustructionPress = (selectvalue) => {
    if (selectedConustruction.includes(selectvalue)) {
      setSelectedConustruction(
        selectedConustruction.filter((value) => value !== selectvalue)
      );
      const removeFilter = allselectedFilters.filter(
        (data) => data !== selectvalue
      );
      setAllselectedFilters([...removeFilter]);
    } else {
      setSelectedConustruction([...selectedConustruction, selectvalue]);
    }
  };
  const handlePostedByPress = (selectvalue) => {
    if (selectedPostedBy.includes(selectvalue)) {
      setSelectedPostedBy(
        selectedPostedBy.filter((value) => value !== selectvalue)
      );
      const removeFilter = allselectedFilters.filter(
        (data) => data !== selectvalue
      );
      setAllselectedFilters([...removeFilter]);
    } else {
      setSelectedPostedBy([...selectedPostedBy, selectvalue]);
    }
  };

  const handleSubcatPress = (selectvalue) => {
    if (selectedSubcat.includes(selectvalue)) {
      setSelectedSubcat(
        selectedSubcat.filter((value) => value !== selectvalue)
      );

      const removeFilter = allselectedFilters.filter(
        (data) => data !== selectvalue
      );
      setAllselectedFilters([...removeFilter]);
    } else {
      setSelectedSubcat([...selectedSubcat, selectvalue]);
    }
    if (selectvalue === "Office") {
      setOfficeList(!showOfficeList);
    }
    if (selectvalue === "Land") {
      setLandList(!showLandList);
    }
  };
  const handleOfficeTypePress = (selectvalue) => {
    if (selectCommersialOfficeType.includes(selectvalue)) {
      setSelectCommersialOfficeType(
        selectCommersialOfficeType.filter((value) => value !== selectvalue)
      );

      const removeFilter = allselectedFilters.filter(
        (data) => data !== selectvalue
      );
      setAllselectedFilters([...removeFilter]);
    } else {
      setSelectCommersialOfficeType([
        ...selectCommersialOfficeType,
        selectvalue,
      ]);
    }
  };

  const handleFurnishedPress = (selectvalue) => {
    if (selectedFurnished.includes(selectvalue)) {
      setSelectedFurnished(
        selectedFurnished.filter((value) => value !== selectvalue)
      );
      const removeFilter = allselectedFilters.filter(
        (data) => data !== selectvalue
      );
      setAllselectedFilters([...removeFilter]);
    } else {
      setSelectedFurnished([...selectedFurnished, selectvalue]);
    }
  };
  const handleReraPress = (selectvalue) => {
    if (selectReraapprovels.includes(selectvalue)) {
      setSelectReraapprovels(
        selectReraapprovels.filter((value) => value !== selectvalue)
      );
      const removeFilter = allselectedFilters.filter(
        (data) => data !== selectvalue
      );
      setAllselectedFilters([...removeFilter]);
    } else {
      setSelectReraapprovels([...selectReraapprovels, selectvalue]);
    }
  };

  const handleSubLocalityPress = (evt) => {
    if (selectedSubLocality.includes(evt.target.value)) {
      setSelectedSubLocality(
        selectedSubLocality.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setSelectedSubLocality((selectedSubLocality) => [
        ...selectedSubLocality,
        evt.target.value,
      ]);
    }
  };
  const handleProjectPress = (evt) => {
    if (selectedProjects.includes(evt.target.value)) {
      setSelectedProjects(
        selectedProjects.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setSelectedProjects((selectedProjects) => [
        ...selectedProjects,
        evt.target.value,
      ]);
    }
  };

  const bhkapartments = [
    { id: "1", name: "+ 1 RK / 1 BHK" },
    { id: "2", name: "+ 2 BHK" },
    { id: "3", name: "+ 3 BHK" },
    { id: "4", name: "+ 4 BHK" },
    { id: "5", name: "+ 5 BHK" },
    { id: "6", name: "+ 6 BHK" },
    { id: "7", name: "+ 7 BHK" },
    { id: "8", name: "+ 8 BHK" },
    { id: "9", name: "+ 9 BHK" },
    { id: "9 +", name: "+ 9 + BHK" },
  ];

  const consustatus = [
    { id: "2", name: "Ready To Move" },
    { id: "3", name: "New Launch" },
    { id: "1", name: "Under Construction" },
  ];
  const propertyPost = [
    { id: "1", name: "Owner" },
    { id: "2", name: "Builder" },
    { id: "3", name: "Dealer" },
  ];

  const furnishingStatus = [
    { id: "1", name: "Un Furnished" },
    { id: "2", name: "Semi Furnished" },
    { id: "3", name: "Furnished" },
  ];
  const rearaStatus = [
    { id: "1", name: "Rera approved properties" },
    { id: "2", name: "Rera registered dealers" },
  ];

  const budget = [
    { id: "1", name: "5 Lacs", budget: 500000 },
    { id: "2", name: "10 Lacs", budget: 1000000 },
    { id: "3", name: "15 Lacs", budget: 1500000 },
    { id: "4", name: "20 Lacs", budget: 2000000 },
    { id: "5", name: "25 Lacs", budget: 2500000 },
    { id: "19", name: "30 Lacs", budget: 3000000 },
    { id: "6", name: "35 Lacs", budget: 3500000 },
    { id: "7", name: "40 Lacs", budget: 4000000 },
    { id: "8", name: "45 Lacs", budget: 4500000 },
    { id: "9", name: "50 Lacs", budget: 5000000 },
    { id: "10", name: "55 Lacs", budget: 5500000 },
    { id: "11", name: "60 Lacs", budget: 6000000 },
    { id: "12", name: "65 Lacs", budget: 6500000 },
    { id: "13", name: "70 Lacs", budget: 7000000 },
    { id: "14", name: "75 Lacs", budget: 7500000 },
    { id: "15", name: "80 Lacs", budget: 8000000 },
    { id: "16", name: "85 Lacs", budget: 8500000 },
    { id: "17", name: "90 Lacs", budget: 9000000 },
    { id: "18", name: "95 Lacs", budget: 9500000 },
    { id: "20", name: "1 Cr", budget: 10000000 },
    { id: "121", name: "1.05 Cr", budget: 10500000 },
    { id: "21", name: "1.1 Cr", budget: 11000000 },
    { id: "122", name: "1.15 Cr", budget: 11500000 },
    { id: "22", name: "1.2 Cr", budget: 12000000 },
    { id: "123", name: "1.25 Cr", budget: 12500000 },
    { id: "23", name: "1.3 Cr", budget: 13000000 },
    { id: "124", name: "1.35 Cr", budget: 13500000 },
    { id: "24", name: "1.4 Cr", budget: 14000000 },
    { id: "125", name: "1.45 Cr", budget: 14500000 },
    { id: "25", name: "1.5 Cr", budget: 15000000 },
    { id: "126", name: "1.55 Cr", budget: 15500000 },
    { id: "26", name: "1.6 Cr", budget: 16000000 },
    { id: "127", name: "1.65 Cr", budget: 16500000 },
    { id: "27", name: "1.7 Cr", budget: 17000000 },
    { id: "128", name: "1.75 Cr", budget: 17500000 },
    { id: "28", name: "1.8 Cr", budget: 18000000 },
    { id: "129", name: "1.85 Cr", budget: 18500000 },
    { id: "29", name: "1.9 Cr", budget: 19000000 },
    { id: "130", name: "1.95 Cr", budget: 19500000 },
    { id: "30", name: "2 Cr", budget: 20000000 },
    { id: "31", name: "2.1 Cr", budget: 21000000 },
    { id: "32", name: "2.2 Cr", budget: 22000000 },
    { id: "33", name: "2.3 Cr", budget: 23000000 },
    { id: "34", name: "2.4 Cr", budget: 24000000 },
    { id: "35", name: "2.5 Cr", budget: 25000000 },
    { id: "36", name: "2.6 Cr", budget: 26000000 },
    { id: "37", name: "2.7 Cr", budget: 27000000 },
    { id: "38", name: "2.8 Cr", budget: 28000000 },
    { id: "39", name: "2.9 Cr", budget: 29000000 },
    { id: "40", name: "3 Cr", budget: 30000000 },
    { id: "41", name: "3.1 Cr", budget: 31000000 },
    { id: "42", name: "3.2 Cr", budget: 32000000 },
    { id: "43", name: "3.3 Cr", budget: 33000000 },
    { id: "44", name: "3.4 Cr", budget: 34000000 },
    { id: "45", name: "3.5 Cr", budget: 35000000 },
    { id: "46", name: "3.6 Cr", budget: 36000000 },
    { id: "47", name: "3.7 Cr", budget: 37000000 },
    { id: "48", name: "3.8 Cr", budget: 38000000 },
    { id: "49", name: "3.9 Cr", budget: 39000000 },
    { id: "50", name: "4 Cr", budget: 40000000 },
    { id: "51", name: "4.1 Cr", budget: 41000000 },
    { id: "52", name: "4.2 Cr", budget: 42000000 },
    { id: "53", name: "4.3 Cr", budget: 43000000 },
    { id: "54", name: "4.4 Cr", budget: 44000000 },
    { id: "55", name: "4.5 Cr", budget: 45000000 },
    { id: "56", name: "4.6 Cr", budget: 46000000 },
    { id: "57", name: "4.7 Cr", budget: 47000000 },
    { id: "58", name: "4.8 Cr", budget: 48000000 },
    { id: "59", name: "4.9 Cr", budget: 49000000 },
    { id: "60", name: "5 Cr", budget: 50000000 },
    { id: "61", name: "5.1 Cr", budget: 51000000 },
    { id: "62", name: "5.2 Cr", budget: 52000000 },
    { id: "63", name: "5.3 Cr", budget: 53000000 },
    { id: "64", name: "5.4 Cr", budget: 54000000 },
    { id: "65", name: "5.5 Cr", budget: 55000000 },
    { id: "66", name: "5.6 Cr", budget: 56000000 },
    { id: "67", name: "5.7 Cr", budget: 57000000 },
    { id: "68", name: "5.8 Cr", budget: 58000000 },
    { id: "69", name: "5.9 Cr", budget: 59000000 },
    { id: "70", name: "6 Cr", budget: 60000000 },
    { id: "71", name: "6.1 Cr", budget: 61000000 },
    { id: "72", name: "6.2 Cr", budget: 62000000 },
    { id: "73", name: "6.3 Cr", budget: 63000000 },
    { id: "74", name: "6.4 Cr", budget: 64000000 },
    { id: "75", name: "6.5 Cr", budget: 65000000 },
    { id: "76", name: "6.6 Cr", budget: 66000000 },
    { id: "77", name: "6.7 Cr", budget: 67000000 },
    { id: "78", name: "6.8 Cr", budget: 68000000 },
    { id: "79", name: "6.9 Cr", budget: 69000000 },
    { id: "80", name: "7 Cr", budget: 70000000 },
    { id: "81", name: "7.1 Cr", budget: 71000000 },
    { id: "82", name: "7.2 Cr", budget: 72000000 },
    { id: "83", name: "7.3 Cr", budget: 73000000 },
    { id: "84", name: "7.4 Cr", budget: 74000000 },
    { id: "85", name: "7.5 Cr", budget: 75000000 },
    { id: "86", name: "7.6 Cr", budget: 76000000 },
    { id: "87", name: "7.7 Cr", budget: 77000000 },
    { id: "88", name: "7.8 Cr", budget: 78000000 },
    { id: "89", name: "7.9 Cr", budget: 79000000 },
    { id: "90", name: "8 Cr", budget: 80000000 },
    { id: "91", name: "8.1 Cr", budget: 81000000 },
    { id: "92", name: "8.2 Cr", budget: 82000000 },
    { id: "93", name: "8.3 Cr", budget: 83000000 },
    { id: "94", name: "8.4 Cr", budget: 84000000 },
    { id: "95", name: "8.5 Cr", budget: 85000000 },
    { id: "96", name: "8.6 Cr", budget: 86000000 },
    { id: "97", name: "8.7 Cr", budget: 87000000 },
    { id: "98", name: "8.8 Cr", budget: 88000000 },
    { id: "99", name: "8.9 Cr", budget: 89000000 },
    { id: "100", name: "9 Cr", budget: 90000000 },
    { id: "101", name: "9.1 Cr", budget: 81000000 },
    { id: "102", name: "9.2 Cr", budget: 82000000 },
    { id: "103", name: "9.3 Cr", budget: 83000000 },
    { id: "104", name: "9.4 Cr", budget: 84000000 },
    { id: "105", name: "9.5 Cr", budget: 85000000 },
    { id: "106", name: "9.6 Cr", budget: 86000000 },
    { id: "107", name: "9.7 Cr", budget: 87000000 },
    { id: "108", name: "9.8 Cr", budget: 88000000 },
    { id: "109", name: "9.9 Cr", budget: 89000000 },
    { id: "110", name: "10 Cr", budget: 100000000 },
    { id: "111", name: "20 Cr", budget: 200000000 },
    { id: "112", name: "30 Cr", budget: 300000000 },
    { id: "113", name: "40 Cr", budget: 400000000 },
    { id: "114", name: "50 Cr", budget: 500000000 },
    { id: "115", name: "60 Cr", budget: 600000000 },
    { id: "116", name: "70 Cr", budget: 700000000 },
    { id: "117", name: "80 Cr", budget: 800000000 },
    { id: "118", name: "90 Cr", budget: 900000000 },
    { id: "119", name: "100 Cr", budget: 1000000000 },
    { id: "120", name: "100 + Cr", budget: 1000000001 },
  ];

  const areamesure = [
    { id: "1", name: "500 sq.ft.", mesurement: 500 },
    { id: "2", name: "1000 sq.ft.", mesurement: 1000 },
    { id: "3", name: "1500 sq.ft.", mesurement: 1500 },
    { id: "4", name: "2000 sq.ft.", mesurement: 2000 },
    { id: "5", name: "2500 sq.ft.", mesurement: 2500 },
    { id: "6", name: "3500 sq.ft.", mesurement: 3500 },
    { id: "7", name: "4000 sq.ft.", mesurement: 4000 },
    { id: "8", name: "4500 sq.ft.", mesurement: 4500 },
    { id: "9", name: "5000 sq.ft.", mesurement: 5000 },
    { id: "10", name: "5500 sq.ft.", mesurement: 5500 },
    { id: "11", name: "6000 sq.ft.", mesurement: 6000 },
    { id: "12", name: "6500 sq.ft.", mesurement: 6500 },
    { id: "13", name: "7000 sq.ft.", mesurement: 7000 },
    { id: "14", name: "7500 sq.ft.", mesurement: 7500 },
    { id: "15", name: "8000 sq.ft.", mesurement: 8000 },
    { id: "16", name: "8500 sq.ft.", mesurement: 8500 },
    { id: "17", name: "9000 sq.ft.", mesurement: 9000 },
    { id: "18", name: "9500 sq.ft.", mesurement: 9500 },
    { id: "19", name: "10000 sq.ft.", mesurement: 10000 },
  ];

  const officeList = [
    {
      id: 1,
      name: "Ready to move office space",
      value: "Ready to move office space",
    },
    {
      id: 2,
      name: "Bare shell office space",
      value: "Bare shell office space",
    },
    {
      id: 3,
      name: "Co working office space",
      value: "Co - working office space",
    },
  ];
  const landList = [
    {
      id: 1,
      name: "Commertial Land/Inst. Land",
      value: "Commertial Land/Inst. Land",
    },
    {
      id: 2,
      name: "Bare shell office space",
      value: "Bare shell office space",
    },
    {
      id: 3,
      name: "Co working office space",
      value: "Co - working office space",
    },
  ];

  const pgAvailablefor = [
    { id: "1", name: "Boys" },
    { id: "2", name: "Girls" },
    { id: "3", name: "Any" },
  ];
  const pgSharing = [
    { id: "1", name: "Sharing" },
    { id: "2", name: "Private" },
  ];

  useEffect(() => {
    const clickoutside = (e) => {
      if (
        minimumtriceShow &&
        minref.current &&
        !minref.current.contains(e.target)
      ) {
        setminimumtriceShow(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [minimumtriceShow]);

  useEffect(() => {
    const clickoutside = (e) => {
      if (
        maximumtriceShow &&
        maxref.current &&
        !maxref.current.contains(e.target)
      ) {
        setmaximumtriceShow(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [maximumtriceShow]);

  const clearAllClick = () => {
    setSelectedSubcat([]);
  };

  const PropertyinfoClick = (e, property) => {
    // navigate(`/property-info/${property.apartment}/${property.propertyID}`);
    e.preventDefault();
console.log("hi");

    if (property.apartment === "") {
      const url = `/property-info/commercial/${property.propertyID}`;
      window.open(url, "_blank");
    } else {
      const url = `/property-info/${property.apartment}/${property.propertyID}`;
      window.open(url, "_blank");
    }
  };

  return (
    <>
      <div className="mainsearchcont topmargins">
        <div className="searchcont_box">
          {params.cat === "Pg" ? (
            <>
              <div className={`searchcont_leftbox`}>
                <div className={`${isFixed ? "searchcont_leftbox_fixed" : ""}`}>
                  <div className="sticky">
                    <div className="searchcont_leftbox_cont1 pt-20">
                      <div className="flex-between m-10">
                        <div className="font-style">Applied Filters</div>
                        <div
                          className="font-style"
                          style={{ color: "#091e42" }}
                          onClick={() => clearAllClick()}
                        >
                          Clear All
                        </div>
                      </div>
                      <div className="filters m-10">
                        {allselectedFilters.map((data, index) => (
                          <div
                            key={index}
                            style={{
                              border: " 1px solid #a3daff",
                              color: "#091e42",
                              fontWeight: "600",
                              background: "#f0f9ff",
                              padding: "0.8vh 2vh",
                              borderRadius: "10px",
                              position: "relative",
                              margin: "0.5vh",
                            }}
                          >
                            {data}
                            <span
                              style={{
                                color: "#000",
                                cursor: "pointer",
                                position: "absolute",
                                top: -6,
                                right: -2,
                                background: "#fff",
                                height: "3vh",
                                width: "3vh",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                fontSize: "15px",
                                paddingBottom: "4px",
                              }}
                              onClick={() => selectRemoveClick(data)}
                            >
                              x
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="m-10">
                      <div
                        className="range-head heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setBudgetShow(!budgetShow)}
                      >
                        <h6 className="font-style">Budget</h6>
                        {!budgetShow ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>
                      {!budgetShow ? (
                        <></>
                      ) : (
                        <>
                          <div className="range-bar">
                            <label htmlFor="customRange2" className="">
                              0
                            </label>
                            <div
                              className="flex-between"
                              style={{ margin: "15px 0px 0px 12px" }}
                            >
                              <ReactSlider
                                className="horizontal-slider"
                                //vertical-slider
                                thumbClassName="example-thumb"
                                trackClassName="example-track"
                                defaultValue={[0, 1000000000]}
                                max={1000000000}
                                min={0}
                                step={500000}
                                value={propertyPrice}
                                onChange={(value, index) =>
                                  propertyPriceChange(value)
                                }
                              />
                            </div>
                            <div className="slider-box">
                              <div> {minimumshowPrice}</div>
                              <div> {maximumshowPrice}</div>
                            </div>
                          </div>
                          <div
                            className="flex-between"
                            style={{ marginTop: "50px" }}
                          >
                            <div
                              className="flex-between budget-filter"
                              style={{
                                position: "relative",
                                cursor: "pointer",
                              }}
                              ref={minref}
                            >
                              <div
                                style={{ marginRight: "5px" }}
                                onClick={() =>
                                  setminimumtriceShow(!minimumtriceShow)
                                }
                              >
                                {minBudget}
                              </div>
                              <div
                                onClick={() =>
                                  setminimumtriceShow(!minimumtriceShow)
                                }
                              >
                                <FaChevronDown />
                              </div>
                              {minimumtriceShow === true ? (
                                <>
                                  <div
                                    className=""
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      height: "25vh",

                                      top: 50,
                                      left: 0,
                                      overflow: "hidden",
                                      overflowY: "scroll",
                                      zIndex: 9999999,
                                    }}
                                  >
                                    {budget.map((data, index) => (
                                      <h5
                                        key={index}
                                        style={{
                                          width: "100%",
                                          height: "20%",
                                          background: "#efefef",
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          textAlign: "right",
                                          padding: "0 2vh",
                                          color: "#333",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          setPropertyPrice([
                                            data.budget,
                                            propertyPriceMax,
                                          ]);
                                          setPropertyPriceMini(data.budget);
                                          setminBudget(data.budget);
                                          setminimumtriceShow(false);
                                        }}
                                      >
                                        {data.name}
                                      </h5>
                                    ))}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div
                              className="flex-between budget-filter"
                              style={{
                                position: "relative",
                                cursor: "pointer",
                              }}
                              ref={maxref}
                            >
                              <div
                                style={{ marginRight: "5px" }}
                                onClick={() =>
                                  setmaximumtriceShow(!maximumtriceShow)
                                }
                              >
                                {maxBudget}
                              </div>
                              <div
                                onClick={() =>
                                  setmaximumtriceShow(!maximumtriceShow)
                                }
                              >
                                <FaChevronDown />
                              </div>

                              {maximumtriceShow === true ? (
                                <>
                                  <div
                                    className=""
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      height: "25vh",
                                      top: 50,
                                      left: 0,
                                      overflow: "hidden",
                                      overflowY: "scroll",
                                      zIndex: 9999999,
                                    }}
                                  >
                                    {budget
                                      .filter(
                                        (data) =>
                                          data.budget > propertyPriceMini
                                      )
                                      .map((data, index) => (
                                        <h5
                                          key={index}
                                          style={{
                                            width: "100%",
                                            height: "20%",
                                            background: "#efefef",
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            textAlign: "right",
                                            padding: "0 2vh",
                                            color: "#333",
                                            fontSize: "12px",
                                          }}
                                          onClick={() => {
                                            setPropertyPrice([
                                              propertyPriceMini,
                                              data.budget,
                                            ]);
                                            setPropertyPriceMax(data.budget);
                                            setMaxBudget(data.budget);
                                            setmaximumtriceShow(false);
                                          }}
                                        >
                                          {data.name}
                                        </h5>
                                      ))}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="input-box m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setAvailableFor(!availableFor)}
                      >
                        <div className="font-style">Available For</div>
                        <div>
                          {!availableFor ? (
                            <>
                              <FaChevronDown />
                            </>
                          ) : (
                            <>
                              <FaChevronUp />
                            </>
                          )}
                        </div>
                      </div>
                      {!availableFor ? (
                        <></>
                      ) : (
                        <>
                          <div className="input-box-cont">
                            {pgAvailablefor.map((avail, indexavail) => (
                              <div
                                className="childlabel side-panel"
                                key={indexavail}
                                onClick={() => {
                                  availforClick(avail.name);
                                }}
                              >
                                <label
                                  className="option-bg-color"
                                  htmlFor="flat"
                                  style={
                                    selectedAvailableFor.includes(avail.name)
                                      ? {
                                          border: " 1px solid #a3daff",
                                          color: "#091e42",
                                          fontWeight: "600",
                                          background: "#f0f9ff",
                                        }
                                      : {}
                                  }
                                >
                                  {avail.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="input-box m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setSharing(!sharing)}
                      >
                        <div className="font-style">Sharing</div>
                        <div>
                          {!sharing ? (
                            <>
                              <FaChevronDown />
                            </>
                          ) : (
                            <>
                              <FaChevronUp />
                            </>
                          )}
                        </div>
                      </div>
                      {!sharing ? (
                        <></>
                      ) : (
                        <>
                          <div className="input-box-cont">
                            {pgSharing.map((share, indexshare) => (
                              <div
                                className="childlabel side-panel"
                                key={indexshare}
                                onClick={() => {
                                  roomShareClick(share.name);
                                }}
                              >
                                <label
                                  className="option-bg-color"
                                  htmlFor="flat"
                                  style={
                                    selectedSharing.includes(share.name)
                                      ? {
                                          border: " 1px solid #a3daff",
                                          color: "#091e42",
                                          fontWeight: "600",
                                          background: "#f0f9ff",
                                        }
                                      : {}
                                  }
                                >
                                  {share.name} Rooms
                                </label>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="input-box m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setTypeOfProperty(!typeOfProperty)}
                      >
                        <div className="font-style">Type of property</div>
                        <div>
                          {!typeOfProperty ? (
                            <>
                              <FaChevronDown />
                            </>
                          ) : (
                            <>
                              <FaChevronUp />
                            </>
                          )}
                        </div>
                      </div>
                      {!typeOfProperty ? (
                        <></>
                      ) : (
                        <>
                          <div className="input-box-cont">
                            {showSubcat.slice(0, 5).map((subcat, index) => (
                              <div
                                className="childlabel side-panel"
                                key={index}
                                onClick={() => {
                                  handleSubcatPress(subcat.name);
                                }}
                              >
                                <label
                                  className="option-bg-color"
                                  style={
                                    selectedSubcat.includes(subcat.name)
                                      ? {
                                          border: " 1px solid #a3daff",
                                          color: "#091e42",
                                          fontWeight: "600",
                                          background: "#f0f9ff",
                                        }
                                      : {}
                                  }
                                  htmlFor="flat"
                                >
                                  {subcat.name}
                                </label>
                              </div>
                            ))}

                            {subcatmoreShow === true ? (
                              <>
                                {showSubcat.slice(5).map((subcat, index) => (
                                  <div
                                    className="childlabel side-panel"
                                    key={index}
                                    onClick={() => {
                                      handleSubcatPress(subcat.name);
                                    }}
                                  >
                                    <label
                                      className="option-bg-color"
                                      style={
                                        selectedSubcat.includes(subcat.name)
                                          ? {
                                              border: " 1px solid #a3daff",
                                              color: "#091e42",
                                              fontWeight: "600",
                                              background: "#f0f9ff",
                                            }
                                          : {}
                                      }
                                      htmlFor="flat"
                                    >
                                      {subcat.name}
                                    </label>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}

                            {subcatmoreShow === false &&
                            showSubcat.length - 5 > 0 ? (
                              <>
                                <div className="flex-start">
                                  <div
                                    className="font-style checkbox-text"
                                    style={{
                                      marginLeft: "5px",
                                      color: "#0078db",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setSubmoreShow(true)}
                                  >
                                    + {showSubcat.length - 5} More
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="flex-start">
                                  <div
                                    className="font-style checkbox-text"
                                    style={{
                                      marginLeft: "5px",
                                      color: "#0078db",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setSubmoreShow(false)}
                                  >
                                    Show less
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="input-box m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setPgService(!pgService)}
                      >
                        <div className="font-style">PG Services</div>
                        <div>
                          {!pgService ? (
                            <>
                              <FaChevronDown />
                            </>
                          ) : (
                            <>
                              <FaChevronUp />
                            </>
                          )}
                        </div>
                      </div>
                      {!pgService ? (
                        <></>
                      ) : (
                        <>
                          <div className="input-box-cont">
                            <div
                              className="childlabel side-panel"
                              onClick={() => setWifi(!wifi)}
                            >
                              <label
                                className="option-bg-color"
                                htmlFor="flat"
                                style={
                                  wifi === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                + Wifi
                              </label>
                            </div>
                            <div
                              className="childlabel side-panel"
                              onClick={() => setFoodService(!foodService)}
                            >
                              <label
                                className="option-bg-color"
                                htmlFor="flat"
                                style={
                                  foodService === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                Food Service
                              </label>
                            </div>

                            {/* <div className="childlabel side-panel"
                             onClick={() => setAcRoom(!acRoom)}
                            >
                              <label className="option-bg-color" htmlFor="flat" 
                                 style={
                                  acRoom === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                AC Room
                              </label>
                            </div> */}
                            <div
                              className="childlabel side-panel"
                              onClick={() => setLaundry(!laundry)}
                            >
                              <label
                                className="option-bg-color"
                                htmlFor="flat"
                                style={
                                  laundry === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                Laundry Available
                              </label>
                            </div>
                            <div
                              className="childlabel side-panel"
                              onClick={() => setWheelchair(!wheelchair)}
                            >
                              <label
                                className="option-bg-color"
                                htmlFor="flat"
                                style={
                                  wheelchair === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                Wheelchair Friendly
                              </label>
                            </div>
                            <div
                              className="childlabel side-panel"
                              onClick={() => setPet(!pet)}
                            >
                              <label
                                className="option-bg-color"
                                htmlFor="flat"
                                style={
                                  pet === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                Pet Friendly
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {(params.supercat !== "Commercial" &&
                      params.subcat !== "Plot-Land" &&
                      params.cat === "Sell") ||
                    params.cat === "Rent" ||
                    params.cat === "Pg" ? (
                      <>
                        <div className="m-10">
                          <div
                            className="heading"
                            style={{ cursor: "pointer" }}
                            onClick={() => setNoofBedrooms(!noofBedrooms)}
                          >
                            <h6 className="font-style">No. of Bedrooms</h6>
                            {!noofBedrooms ? (
                              <>
                                <FaChevronDown />
                              </>
                            ) : (
                              <>
                                <FaChevronUp />
                              </>
                            )}
                          </div>
                          {!noofBedrooms ? (
                            <></>
                          ) : (
                            <>
                              <div
                                className="flex-start"
                                style={{
                                  marginTop: "-2px",
                                  flexWrap: "wrap",
                                  padding: "0px",
                                }}
                              >
                                {bhkapartments.slice(0, 5).map((bhk, index) => (
                                  <div
                                    className="childlabel side-panel"
                                    onClick={() => {
                                      handleBedroomPress(bhk.id);
                                    }}
                                    key={index}
                                  >
                                    <label
                                      className="option-bg-color "
                                      style={
                                        selectedBedrooms.includes(bhk.id)
                                          ? {
                                              border: " 1px solid #a3daff",
                                              color: "#091e42",
                                              fontWeight: "600",
                                              background: "#f0f9ff",
                                            }
                                          : {}
                                      }
                                    >
                                      {bhk.name}
                                    </label>
                                  </div>
                                ))}

                                {bhxmoreShow === true ? (
                                  <>
                                    {bhkapartments
                                      .slice(5)
                                      .map((bhk, index) => (
                                        <div
                                          className="childlabel side-panel"
                                          onClick={() => {
                                            handleBedroomPress(bhk.id);
                                          }}
                                          key={index}
                                        >
                                          <label
                                            className="option-bg-color "
                                            style={
                                              selectedBedrooms.includes(bhk.id)
                                                ? {
                                                    border:
                                                      " 1px solid #a3daff",
                                                    color: "#091e42",
                                                    fontWeight: "600",
                                                    background: "#f0f9ff",
                                                  }
                                                : {}
                                            }
                                          >
                                            {bhk.name}
                                          </label>
                                        </div>
                                      ))}
                                  </>
                                ) : (
                                  <></>
                                )}

                                {bhxmoreShow === false &&
                                bhkapartments.length - 5 > 0 ? (
                                  <>
                                    <div className="flex-start">
                                      <div
                                        className="font-style checkbox-text"
                                        style={{
                                          marginLeft: "5px",
                                          color: "#0078db",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setBhxmoreShow(true)}
                                      >
                                        + {bhkapartments.length - 5} More
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="flex-start">
                                      <div
                                        className="font-style checkbox-text"
                                        style={{
                                          marginLeft: "5px",
                                          color: "#0078db",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setBhxmoreShow(false)}
                                      >
                                        Show less
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setAminities(!aminities)}
                      >
                        <h6 className="font-style">Aminities</h6>
                        {!aminities ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>
                      {!aminities ? (
                        <></>
                      ) : (
                        <>
                          <div
                            className="flex-start"
                            style={{
                              marginTop: "-2px",
                              flexWrap: "wrap",
                              padding: "0px",
                            }}
                          >
                            <div
                              className="childlabel side-panel"
                              onClick={() => setparking(!parking)}
                            >
                              <label
                                className="option-bg-color"
                                style={
                                  parking === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                + Parking
                              </label>
                            </div>
                            <div
                              className="childlabel side-panel"
                              onClick={() => setpark(!park)}
                            >
                              <label
                                className="option-bg-color"
                                style={
                                  park === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                + Park
                              </label>
                            </div>
                            <div
                              className="childlabel side-panel"
                              onClick={() => setpowerbackup(!powerbackup)}
                            >
                              <label
                                className="option-bg-color"
                                style={
                                  powerbackup === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                + Power Backup
                              </label>
                            </div>
                            <div
                              className="childlabel side-panel"
                              onClick={() => setLifts(!lifts)}
                            >
                              <label
                                className="option-bg-color"
                                style={
                                  lifts === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                + Lift
                              </label>
                            </div>

                            <div
                              className="childlabel side-panel"
                              onClick={() => setPool(!pool)}
                            >
                              <label
                                className="option-bg-color"
                                style={
                                  pool === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                + Swimming Pool
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="m-10" style={{}}>
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setLocalities(!localities)}
                      >
                        <h6 className="font-style">Localities</h6>
                        {!localities ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>

                      {!localities ? (
                        <></>
                      ) : (
                        <>
                          <div
                            style={{
                              marginTop: "-2px",
                              padding: "0px",
                              textAlign: "left",
                              position: "relative",
                            }}
                          >
                            {sublocalityBycity
                              .slice(0, 6)
                              .map((data, index) => (
                                <div className="flex-start">
                                  <input
                                    type="checkbox"
                                    value={data}
                                    checked={selectedSubLocality.includes(data)}
                                    onChange={(e) =>
                                      handleSubLocalityPress(e, data)
                                    }
                                    key={index}
                                    name=""
                                    id=""
                                  />
                                  <label
                                    style={
                                      selectedSubLocality.includes(data)
                                        ? {
                                            color: "#091e42",
                                            fontWeight: "600",
                                          }
                                        : {}
                                    }
                                    className="checkbox-text"
                                  >
                                    {data}
                                  </label>
                                  <img
                                    src="/assets/images/ratings.png"
                                    alt=""
                                    style={{ marginLeft: "5px" }}
                                  />
                                </div>
                              ))}

                            {sublocalityBycity.length - 6 > 0 ? (
                              <>
                                <div
                                  className="flex-start"
                                  onClick={() => setsearchModal(!searchmodal)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <CiSearch />
                                  <div
                                    className="font-style checkbox-text"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    More Localities
                                  </div>

                                  {searchmodal === true ? (
                                    <>
                                      <div
                                        style={{
                                          background: "#fff",
                                          padding: "15px",
                                          borderRadius: "3px",
                                          position: "absolute",
                                          width: "700px",
                                          backgroundColor: "#fff",
                                          zIndex: 3,
                                          boxShadow:
                                            "2px 2px 5px 2px rgba(0, 0, 0, 0.5);",
                                          top: 0,
                                        }}
                                      >
                                        <div
                                          className=""
                                          style={{
                                            display: "flex",
                                            marginTop: "15px",
                                          }}
                                        >
                                          <div
                                            className=""
                                            style={{ width: "50%" }}
                                          >
                                            <p
                                              style={{
                                                color: "#000",
                                                fontWeight: "500",
                                              }}
                                            >
                                              Top Localities
                                            </p>
                                            {sublocalityBycity
                                              .slice(0, 6)
                                              .map((data, index) => (
                                                <p
                                                  style={{ color: "#000" }}
                                                  key={index}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    value={data}
                                                    checked={selectedSubLocality.includes(
                                                      data
                                                    )}
                                                    onChange={(e) =>
                                                      handleSubLocalityPress(
                                                        e,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    style={
                                                      selectedSubLocality.includes(
                                                        data
                                                      )
                                                        ? {
                                                            color: "#091e42",
                                                            fontWeight: "600",
                                                          }
                                                        : {}
                                                    }
                                                    className="checkbox-text"
                                                  >
                                                    {data}
                                                  </label>
                                                </p>
                                              ))}
                                          </div>
                                          <div
                                            className=""
                                            style={{
                                              width: "50%",
                                              height: "280px",
                                              overflowY: "scroll",
                                            }}
                                          >
                                            <p
                                              style={{
                                                color: "#000",
                                                fontWeight: "500",
                                              }}
                                            >
                                              More Localities
                                            </p>

                                            {sublocalityBycity.map(
                                              (data, index) => (
                                                <p
                                                  style={{
                                                    color: "#000",
                                                    textTransform: "capitalize",
                                                  }}
                                                  key={index}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    value={data}
                                                    checked={selectedSubLocality.includes(
                                                      data
                                                    )}
                                                    onChange={(e) =>
                                                      handleSubLocalityPress(
                                                        e,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    style={
                                                      selectedSubLocality.includes(
                                                        data
                                                      )
                                                        ? {
                                                            color: "#091e42",
                                                            fontWeight: "600",
                                                          }
                                                        : {}
                                                    }
                                                    className="checkbox-text"
                                                  >
                                                    {data}
                                                  </label>
                                                </p>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setPostedBy(!postedBy)}
                      >
                        <h6 className="font-style">Posted By</h6>
                        {!postedBy ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>
                      {!postedBy ? (
                        <></>
                      ) : (
                        <>
                          <div
                            className="flex-start"
                            style={{
                              marginTop: "-2px",
                              flexWrap: "wrap",
                              padding: "0px",
                            }}
                          >
                            {propertyPost.map((data, index) => (
                              <div
                                className="childlabel side-panel"
                                key={index}
                                onClick={() => {
                                  handlePostedByPress(data.name);
                                }}
                              >
                                <label
                                  className="option-bg-color"
                                  htmlFor="flat"
                                  style={
                                    selectedPostedBy.includes(data.name)
                                      ? {
                                          border: " 1px solid #a3daff",
                                          color: "#091e42",
                                          fontWeight: "600",
                                          background: "#f0f9ff",
                                        }
                                      : {}
                                  }
                                >
                                  {data.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setFurnishingStatusShow(!furnishingStatusShow)
                        }
                      >
                        <h6 className="font-style">furnishing Status</h6>
                        {!furnishingStatusShow ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>
                      {!furnishingStatusShow ? (
                        <></>
                      ) : (
                        <>
                          <div
                            className="flex-start"
                            style={{
                              marginTop: "-2px",
                              flexWrap: "wrap",
                              padding: "0px",
                            }}
                          >
                            {furnishingStatus.map((data, index) => (
                              <div
                                className="childlabel side-panel"
                                key={index}
                                onClick={() => {
                                  handleFurnishedPress(data.name);
                                }}
                              >
                                <label
                                  className="option-bg-color "
                                  style={
                                    selectedFurnished.includes(data.name)
                                      ? {
                                          border: " 1px solid #a3daff",
                                          color: "#091e42",
                                          fontWeight: "600",
                                          background: "#f0f9ff",
                                        }
                                      : {}
                                  }
                                >
                                  {data.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>

                    {/* <div className="m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setTotalCapacity(!totalCapacity)}
                      >
                        <h6 className="font-style">Total Capacity</h6>
                        {!totalCapacity ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>
                      {!totalCapacity ? (
                        <></>
                      ) : (
                        <>
                          <div
                            className="flex-start"
                            style={{
                              marginTop: "-2px",
                              flexWrap: "wrap",
                              padding: "0px",
                            }}
                          >
                            <div className="childlabel side-panel">
                              <label className="option-bg-color ">
                                1-2 Guests
                              </label>
                            </div>
                          </div>
                          <div
                            className="flex-start"
                            style={{
                              marginTop: "-2px",
                              flexWrap: "wrap",
                              padding: "0px",
                            }}
                          >
                            <div className="childlabel side-panel">
                              <label className="option-bg-color ">
                                2-4 Guests
                              </label>
                            </div>
                          </div>
                          <div
                            className="flex-start"
                            style={{
                              marginTop: "-2px",
                              flexWrap: "wrap",
                              padding: "0px",
                            }}
                          >
                            <div className="childlabel side-panel">
                              <label className="option-bg-color ">
                                4-10 Guests
                              </label>
                            </div>
                          </div>
                          <div
                            className="flex-start"
                            style={{
                              marginTop: "-2px",
                              flexWrap: "wrap",
                              padding: "0px",
                            }}
                          >
                            <div className="childlabel side-panel">
                              <label className="option-bg-color ">
                                10+ Guests
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={`searchcont_leftbox`}>
                <div className={`${isFixed ? "searchcont_leftbox_fixed" : ""}`}>
                  <div className="sticky">
                    <div className="searchcont_leftbox_cont1 pt-20">
                      <div className="flex-between m-10">
                        <div className="font-style">Applied Filters</div>
                        <div
                          className="font-style"
                          style={{ color: "#091e42" }}
                          onClick={() => clearAllClick()}
                        >
                          Clear All
                        </div>
                      </div>
                      <div className="filters m-10">
                        {allselectedFilters.map((data, index) => (
                          <div
                            key={index}
                            style={{
                              border: " 1px solid #a3daff",
                              color: "#091e42",
                              fontWeight: "600",
                              background: "#f0f9ff",
                              padding: "0.8vh 2vh",
                              borderRadius: "10px",
                              position: "relative",
                              margin: "0.5vh",
                            }}
                          >
                            {data}
                            <span
                              style={{
                                color: "#000",
                                cursor: "pointer",
                                position: "absolute",
                                top: -6,
                                right: -2,
                                background: "#fff",
                                height: "3vh",
                                width: "3vh",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                fontSize: "15px",
                                paddingBottom: "4px",
                              }}
                              onClick={() => selectRemoveClick(data)}
                            >
                              x
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* <div
                className="flex-between m-10"
                style={{ borderBottom: "1px solid #ddd", padding: "10px" }}
              >
                <div> Hide already seen</div>
                <Switch defaultChecked />
              </div> */}
                    {/* <div
                className="flex-between m-10 verified-propertie"
                style={{ borderBottom: "1px solid #ddd", padding: "10px" }}
              >
                <div className="">
                  <div style={{ textAlign: "left" }}>Verified Properties</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/assets/images/verified.png"
                      className="verified-property"
                      alt=""
                    />
                    <div style={{ fontSize: "10px", marginLeft: "10px" }} class>
                      by Mygoldenhomes verification team
                    </div>
                  </div>
                </div>
                <Switch defaultChecked />
              </div> */}
                    <div className="m-10">
                      <div
                        className="range-head heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setBudgetShow(!budgetShow)}
                      >
                        <h6 className="font-style">Budget</h6>
                        {!budgetShow ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>
                      {!budgetShow ? (
                        <></>
                      ) : (
                        <>
                          <div className="range-bar">
                            <label htmlFor="customRange2" className="">
                              0
                            </label>
                            <div
                              className="flex-between"
                              style={{ margin: "15px 0px 0px 12px" }}
                            >
                              <ReactSlider
                                className="horizontal-slider"
                                //vertical-slider
                                thumbClassName="example-thumb"
                                trackClassName="example-track"
                                defaultValue={[0, 1000000000]}
                                max={1000000000}
                                min={0}
                                step={500000}
                                value={propertyPrice}
                                // renderThumb={(props, state) => (
                                //   <div {...props}>{state.valueNow}</div>
                                // )}
                                onChange={(value, index) =>
                                  propertyPriceChange(value)
                                }
                              />
                            </div>
                            <div className="slider-box">
                              <div> {minimumshowPrice}</div>
                              <div> {maximumshowPrice}</div>
                            </div>
                          </div>
                          <div
                            className="flex-between"
                            style={{ marginTop: "50px" }}
                          >
                            <div
                              className="flex-between budget-filter"
                              style={{
                                position: "relative",
                                cursor: "pointer",
                              }}
                              ref={minref}
                            >
                              <div
                                style={{ marginRight: "5px" }}
                                onClick={() =>
                                  setminimumtriceShow(!minimumtriceShow)
                                }
                              >
                                {minBudget}
                              </div>
                              <div
                                onClick={() =>
                                  setminimumtriceShow(!minimumtriceShow)
                                }
                              >
                                <FaChevronDown />
                              </div>
                              {minimumtriceShow === true ? (
                                <>
                                  <div
                                    className=""
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      height: "25vh",
                                      // background: "#fff",
                                      top: 50,
                                      left: 0,
                                      overflow: "hidden",
                                      overflowY: "scroll",
                                      zIndex: 9999999,
                                    }}
                                  >
                                    {budget.map((data, index) => (
                                      <h5
                                        key={index}
                                        style={{
                                          width: "100%",
                                          height: "20%",
                                          background: "#efefef",
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          textAlign: "right",
                                          padding: "0 2vh",
                                          color: "#333",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          setPropertyPrice([
                                            data.budget,
                                            propertyPriceMax,
                                          ]);
                                          setPropertyPriceMini(data.budget);
                                          setminBudget(data.budget);
                                          setminimumtriceShow(false);
                                        }}
                                      >
                                        {data.name}
                                      </h5>
                                    ))}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div
                              className="flex-between budget-filter"
                              style={{
                                position: "relative",
                                cursor: "pointer",
                              }}
                              ref={maxref}
                            >
                              <div
                                style={{ marginRight: "5px" }}
                                onClick={() =>
                                  setmaximumtriceShow(!maximumtriceShow)
                                }
                              >
                                {maxBudget}
                              </div>
                              <div
                                onClick={() =>
                                  setmaximumtriceShow(!maximumtriceShow)
                                }
                              >
                                <FaChevronDown />
                              </div>

                              {maximumtriceShow === true ? (
                                <>
                                  <div
                                    className=""
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      height: "25vh",
                                      // background: "#fff",
                                      top: 50,
                                      left: 0,
                                      overflow: "hidden",
                                      overflowY: "scroll",
                                      zIndex: 9999999,
                                    }}
                                  >
                                    {budget
                                      .filter(
                                        (data) =>
                                          data.budget > propertyPriceMini
                                      )
                                      .map((data, index) => (
                                        <h5
                                          key={index}
                                          style={{
                                            width: "100%",
                                            height: "20%",
                                            background: "#efefef",
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            textAlign: "right",
                                            padding: "0 2vh",
                                            color: "#333",
                                            fontSize: "12px",
                                          }}
                                          onClick={() => {
                                            setPropertyPrice([
                                              propertyPriceMini,
                                              data.budget,
                                            ]);
                                            setPropertyPriceMax(data.budget);
                                            setMaxBudget(data.budget);
                                            setmaximumtriceShow(false);
                                          }}
                                        >
                                          {data.name}
                                        </h5>
                                      ))}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="input-box m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setTypeOfProperty(!typeOfProperty)}
                      >
                        <div className="font-style">Type of property</div>
                        <div>
                          {!typeOfProperty ? (
                            <>
                              <FaChevronDown />
                            </>
                          ) : (
                            <>
                              <FaChevronUp />
                            </>
                          )}
                        </div>
                      </div>
                      {!typeOfProperty ? (
                        <></>
                      ) : (
                        <>
                          <div className="input-box-cont">
                            {showSubcat.slice(0, 5).map((subcat, index) => (
                              <div
                                className="childlabel side-panel"
                                key={index}
                                onClick={() => {
                                  handleSubcatPress(subcat.name);
                                }}
                              >
                                <label
                                  className="option-bg-color"
                                  style={
                                    selectedSubcat.includes(subcat.name)
                                      ? {
                                          border: " 1px solid #a3daff",
                                          color: "#091e42",
                                          fontWeight: "600",
                                          background: "#f0f9ff",
                                        }
                                      : {}
                                  }
                                  htmlFor="flat"
                                >
                                  {subcat.name}
                                </label>
                              </div>
                            ))}

                            {subcatmoreShow === true ? (
                              <>
                                {showSubcat.slice(5).map((subcat, index) => (
                                  <div
                                    className="childlabel side-panel"
                                    key={index}
                                    onClick={() => {
                                      handleSubcatPress(subcat.name);
                                    }}
                                  >
                                    <label
                                      className="option-bg-color"
                                      style={
                                        selectedSubcat.includes(subcat.name)
                                          ? {
                                              border: " 1px solid #a3daff",
                                              color: "#091e42",
                                              fontWeight: "600",
                                              background: "#f0f9ff",
                                            }
                                          : {}
                                      }
                                      htmlFor="flat"
                                    >
                                      {subcat.name}
                                    </label>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}

                            {subcatmoreShow === false &&
                            showSubcat.length - 5 > 0 ? (
                              <>
                                <div className="flex-start">
                                  <div
                                    className="font-style checkbox-text"
                                    style={{
                                      marginLeft: "5px",
                                      color: "#0078db",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setSubmoreShow(true)}
                                  >
                                    + {showSubcat.length - 5} More
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="flex-start">
                                  <div
                                    className="font-style checkbox-text"
                                    style={{
                                      marginLeft: "5px",
                                      color: "#0078db",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setSubmoreShow(false)}
                                  >
                                    Show less
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    {params.supercat === "Commercial" ? (
                      <>
                        <div className="input-box m-10">
                          <div
                            className="heading"
                            style={{ cursor: "pointer" }}
                            onClick={() => setTypeOfOffice(!typeOfOffice)}
                          >
                            <div className="font-style">Office Type</div>
                            <div>
                              {!typeOfOffice ? (
                                <>
                                  <FaChevronDown />
                                </>
                              ) : (
                                <>
                                  <FaChevronUp />
                                </>
                              )}
                            </div>
                          </div>

                          {typeOfOffice === true && (
                            <div className="input-box-cont">
                              {commercialTypeofOffice.map((subcat, index) => (
                                <div
                                  className="childlabel side-panel"
                                  key={index}
                                  onClick={() => {
                                    handleOfficeTypePress(subcat.name);
                                  }}
                                >
                                  <label
                                    className="option-bg-color"
                                    style={
                                      selectCommersialOfficeType.includes(
                                        subcat.name
                                      )
                                        ? {
                                            border: " 1px solid #a3daff",
                                            color: "#091e42",
                                            fontWeight: "600",
                                            background: "#f0f9ff",
                                          }
                                        : {}
                                    }
                                    htmlFor="flat"
                                  >
                                    {subcat.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* {
                      showOfficeList ?
                        <div className="m-10">
                          <div
                            className="range-head heading"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="input-box-cont"
                            >
                              {
                                officeList.map((individualOption, index) =>
                                  <div
                                    className="childlabel side-panel"
                                    onClick={() => {
                                      handleOfficeListPress(individualOption.name);
                                    }}
                                    key={index}
                                  >
                                    <label
                                      className="option-bg-color "
                                      style={
                                        showSelcectedOfficeList.includes(individualOption.name)
                                          ? {
                                            border: " 1px solid #a3daff",
                                            color: "#091e42",
                                            fontWeight: "600",
                                            background: "#f0f9ff",
                                          }
                                          : {}
                                      }
                                    >
                                      {individualOption.name}
                                    </label>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        </div> : <></>
                    }
                    {
                      showLandList ?
                        <div className="m-10">
                          <div
                            className="range-head heading"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="input-box-cont"
                            >
                              {
                                landList.map((individualOption, index) =>
                                  <div
                                    className="childlabel side-panel"
                                    onClick={() => {
                                      handleLandListPress(individualOption.name);
                                    }}
                                    key={index}
                                  >
                                    <label
                                      className="option-bg-color "
                                      style={
                                        showSelcectedLandList.includes(individualOption.name)
                                          ? {
                                            border: " 1px solid #a3daff",
                                            color: "#091e42",
                                            fontWeight: "600",
                                            background: "#f0f9ff",
                                          }
                                          : {}
                                      }
                                    >
                                      {individualOption.name}
                                    </label>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        </div> : <></>
                    } */}
                    {(params.supercat !== "Commercial" &&
                      params.subcat !== "Plot-Land" &&
                      params.cat === "Sell") ||
                    params.cat === "Rent" ? (
                      <>
                        <div className="m-10">
                          <div
                            className="heading"
                            style={{ cursor: "pointer" }}
                            onClick={() => setNoofBedrooms(!noofBedrooms)}
                          >
                            <h6 className="font-style">No. of Bedrooms</h6>
                            {!noofBedrooms ? (
                              <>
                                <FaChevronDown />
                              </>
                            ) : (
                              <>
                                <FaChevronUp />
                              </>
                            )}
                          </div>
                          {!noofBedrooms ? (
                            <></>
                          ) : (
                            <>
                              <div
                                className="flex-start"
                                style={{
                                  marginTop: "-2px",
                                  flexWrap: "wrap",
                                  padding: "0px",
                                }}
                              >
                                {bhkapartments.slice(0, 5).map((bhk, index) => (
                                  <div
                                    className="childlabel side-panel"
                                    onClick={() => {
                                      handleBedroomPress(bhk.id);
                                    }}
                                    key={index}
                                  >
                                    <label
                                      className="option-bg-color "
                                      style={
                                        selectedBedrooms.includes(bhk.id)
                                          ? {
                                              border: " 1px solid #a3daff",
                                              color: "#091e42",
                                              fontWeight: "600",
                                              background: "#f0f9ff",
                                            }
                                          : {}
                                      }
                                    >
                                      {bhk.name}
                                    </label>
                                  </div>
                                ))}

                                {bhxmoreShow === true ? (
                                  <>
                                    {bhkapartments
                                      .slice(5)
                                      .map((bhk, index) => (
                                        <div
                                          className="childlabel side-panel"
                                          onClick={() => {
                                            handleBedroomPress(bhk.id);
                                          }}
                                          key={index}
                                        >
                                          <label
                                            className="option-bg-color "
                                            style={
                                              selectedBedrooms.includes(bhk.id)
                                                ? {
                                                    border:
                                                      " 1px solid #a3daff",
                                                    color: "#091e42",
                                                    fontWeight: "600",
                                                    background: "#f0f9ff",
                                                  }
                                                : {}
                                            }
                                          >
                                            {bhk.name}
                                          </label>
                                        </div>
                                      ))}
                                  </>
                                ) : (
                                  <></>
                                )}

                                {bhxmoreShow === false &&
                                bhkapartments.length - 5 > 0 ? (
                                  <>
                                    <div className="flex-start">
                                      <div
                                        className="font-style checkbox-text"
                                        style={{
                                          marginLeft: "5px",
                                          color: "#0078db",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setBhxmoreShow(true)}
                                      >
                                        + {bhkapartments.length - 5} More
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="flex-start">
                                      <div
                                        className="font-style checkbox-text"
                                        style={{
                                          marginLeft: "5px",
                                          color: "#0078db",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setBhxmoreShow(false)}
                                      >
                                        Show less
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setConstructionStatus(!constructionStatus)
                        }
                      >
                        <h6 className="font-style">Construction Status</h6>
                        {!constructionStatus ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>
                      {!constructionStatus ? (
                        <></>
                      ) : (
                        <>
                          <div
                            className="flex-start"
                            style={{
                              marginTop: "-2px",
                              flexWrap: "wrap",
                              padding: "0px",
                            }}
                          >
                            {consustatus.map((data, index) => (
                              <div
                                className="childlabel side-panel"
                                key={index}
                                onClick={() => {
                                  handleConustructionPress(data.name);
                                }}
                              >
                                <label
                                  className="option-bg-color"
                                  htmlFor="flat"
                                  style={
                                    selectedConustruction.includes(data.name)
                                      ? {
                                          border: " 1px solid #a3daff",
                                          color: "#091e42",
                                          fontWeight: "600",
                                          background: "#f0f9ff",
                                        }
                                      : {}
                                  }
                                >
                                  {data.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setPostedBy(!postedBy)}
                      >
                        <h6 className="font-style">Posted By</h6>
                        {!postedBy ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>
                      {!postedBy ? (
                        <></>
                      ) : (
                        <>
                          <div
                            className="flex-start"
                            style={{
                              marginTop: "-2px",
                              flexWrap: "wrap",
                              padding: "0px",
                            }}
                          >
                            {propertyPost.map((data, index) => (
                              <div
                                className="childlabel side-panel"
                                key={index}
                                onClick={() => {
                                  handlePostedByPress(data.name);
                                }}
                              >
                                {/* <input type="checkbox" /> */}
                                <label
                                  className="option-bg-color"
                                  htmlFor="flat"
                                  style={
                                    selectedPostedBy.includes(data.name)
                                      ? {
                                          border: " 1px solid #a3daff",
                                          color: "#091e42",
                                          fontWeight: "600",
                                          background: "#f0f9ff",
                                        }
                                      : {}
                                  }
                                >
                                  {data.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setArea(!area)}
                      >
                        <h6 className="font-style">Area</h6>
                        {!area ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>
                      {!area ? (
                        <></>
                      ) : (
                        <>
                          <div className="range-bar">
                            <label htmlFor="customRange2" className="">
                              0
                            </label>
                            <div
                              className="flex-between"
                              style={{ margin: "15px 0px 0px 12px" }}
                            >
                              <ReactSlider
                                className="horizontal-slider"
                                thumbClassName="example-thumb"
                                trackClassName="example-track"
                                defaultValue={[0, 10000]}
                                max={10000}
                                min={0}
                                step={500}
                                // renderThumb={(props, state) => (
                                //   <div {...props}>{state.valueNow}</div>
                                // )}
                                onChange={(value, index) =>
                                  selectAreaDetailsPress(value)
                                }
                              />
                            </div>
                            <div className="slider-box">
                              <div>{minimumshowarea}</div>
                              <div>{maximumshowArea}</div>
                            </div>
                          </div>
                          <div
                            className="flex-between"
                            style={{ marginTop: "50px" }}
                          >
                            <div
                              className="flex-between budget-filter"
                              style={{
                                position: "relative",
                                cursor: "pointer",
                                width: "45%",
                              }}
                              ref={minref}
                            >
                              <div
                                style={{ marginRight: "5px" }}
                                onClick={() =>
                                  setminimumareaShow(!minimumareaShow)
                                }
                              >
                                {minArea}
                              </div>
                              <div
                                onClick={() =>
                                  setminimumareaShow(!minimumareaShow)
                                }
                              >
                                <FaChevronDown />
                              </div>
                              {minimumareaShow === true ? (
                                <>
                                  <div
                                    className=""
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      height: "25vh",
                                      // background: "#fff",
                                      top: 50,
                                      left: 0,
                                      overflow: "hidden",
                                      overflowY: "scroll",
                                      zIndex: 9999999,
                                    }}
                                  >
                                    {areamesure.map((data, index) => (
                                      <h5
                                        key={index}
                                        style={{
                                          width: "100%",
                                          height: "20%",
                                          background: "#efefef",
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          textAlign: "right",
                                          padding: "0 2vh",
                                          color: "#333",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          setSelectedArea([
                                            data.mesurement,
                                            10000,
                                          ]);
                                          setMinArea(data.mesurement);
                                          setminimumareaShow(!minimumareaShow);
                                        }}
                                      >
                                        {data.name}
                                      </h5>
                                    ))}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div
                              className="flex-between budget-filter"
                              style={{
                                position: "relative",
                                cursor: "pointer",
                                width: "45%",
                              }}
                              ref={maxref}
                            >
                              <div
                                style={{ marginRight: "5px" }}
                                onClick={() =>
                                  setmaximumareaShow(!maximumareaShow)
                                }
                              >
                                {maxArea}
                              </div>
                              <div
                                onClick={() =>
                                  setmaximumareaShow(!maximumareaShow)
                                }
                              >
                                <FaChevronDown />
                              </div>
                              {maximumareaShow === true ? (
                                <>
                                  <div
                                    className=""
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      height: "25vh",
                                      // background: "#fff",
                                      top: 50,
                                      left: 0,
                                      overflow: "hidden",
                                      overflowY: "scroll",
                                      zIndex: 9999999,
                                    }}
                                  >
                                    {areamesure.map((data, index) => (
                                      <h5
                                        key={index}
                                        style={{
                                          width: "100%",
                                          height: "20%",
                                          background: "#efefef",
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          textAlign: "right",
                                          padding: "0 2vh",
                                          color: "#333",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          setSelectedArea([
                                            500,
                                            data.mesurement,
                                          ]);
                                          setMaxArea(data.mesurement);
                                          setmaximumareaShow(!maximumareaShow);
                                        }}
                                      >
                                        {data.name}
                                      </h5>
                                    ))}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="m-10" style={{}}>
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setLocalities(!localities)}
                      >
                        <h6 className="font-style">Localities</h6>
                        {!localities ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>

                      {!localities ? (
                        <></>
                      ) : (
                        <>
                          <div
                            style={{
                              marginTop: "-2px",
                              padding: "0px",
                              textAlign: "left",
                              position: "relative",
                            }}
                          >
                            {sublocalityBycity
                              .slice(0, 6)
                              .map((data, index) => (
                                <div className="flex-start">
                                  <input
                                    type="checkbox"
                                    value={data}
                                    checked={selectedSubLocality.includes(data)}
                                    onChange={(e) =>
                                      handleSubLocalityPress(e, data)
                                    }
                                    key={index}
                                    name=""
                                    id=""
                                  />
                                  <label
                                    style={
                                      selectedSubLocality.includes(data)
                                        ? {
                                            color: "#091e42",
                                            fontWeight: "600",
                                          }
                                        : {}
                                    }
                                    className="checkbox-text"
                                  >
                                    {data}
                                  </label>
                                  <img
                                    src="/assets/images/ratings.png"
                                    alt=""
                                    style={{ marginLeft: "5px" }}
                                  />
                                </div>
                              ))}

                            {sublocalityBycity.length - 6 > 0 ? (
                              <>
                                <div
                                  className="flex-start"
                                  onClick={() => setsearchModal(!searchmodal)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <CiSearch />
                                  <div
                                    className="font-style checkbox-text"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    More Localities
                                  </div>

                                  {searchmodal === true ? (
                                    <>
                                      <div
                                        style={{
                                          background: "#fff",
                                          padding: "15px 0px",
                                          borderRadius: "3px",
                                          position: "absolute",
                                          width: "330px",
                                          backgroundColor: "#fff",
                                          zIndex: 3,
                                          boxShadow:
                                            "2px 2px 5px 2px rgba(0, 0, 0, 0.5);",
                                          top: 0,
                                          height: "300px",
                                          overflow: "scroll",
                                        }}
                                      >
                                        <div
                                          className=""
                                          style={{
                                            display: "flex",
                                            marginTop: "15px",
                                          }}
                                        >
                                          <div
                                            className=""
                                            style={{ width: "50%" }}
                                          >
                                            <p
                                              style={{
                                                color: "#000",
                                                fontWeight: "500",
                                              }}
                                            >
                                              Top Localities
                                            </p>
                                            {sublocalityBycity.map(
                                              (data, index) => (
                                                <p
                                                  style={{ color: "#000" }}
                                                  key={index}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    value={data}
                                                    checked={selectedSubLocality.includes(
                                                      data
                                                    )}
                                                    onChange={(e) =>
                                                      handleSubLocalityPress(
                                                        e,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    style={
                                                      selectedSubLocality.includes(
                                                        data
                                                      )
                                                        ? {
                                                            color: "#091e42",
                                                            fontWeight: "600",
                                                          }
                                                        : {}
                                                    }
                                                    className="checkbox-text"
                                                  >
                                                    {data}
                                                  </label>
                                                </p>
                                              )
                                            )}
                                          </div>
                                          {/* <div
                                            className=""
                                            style={{
                                              width: "50%",
                                              height: "280px",
                                              overflowY: "scroll",
                                              zIndex:9999
                                            }}
                                          >
                                            <p
                                              style={{
                                                color: "#000",
                                                fontWeight: "500",
                                                border:"1px solid red",
                                                zIndex:9999
                                              }}
                                            >
                                              More Localities
                                            </p>

                                            {sublocalityBycity.map(
                                              (data, index) => (
                                                <p
                                                  style={{
                                                    color: "#000",
                                                    textTransform: "capitalize",
                                                  }}
                                                  key={index}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    value={data}
                                                    checked={selectedSubLocality.includes(
                                                      data
                                                    )}
                                                    onChange={(e) =>
                                                      handleSubLocalityPress(
                                                        e,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    style={
                                                      selectedSubLocality.includes(
                                                        data
                                                      )
                                                        ? {
                                                            color: "#091e42",
                                                            fontWeight: "600",
                                                          }
                                                        : {}
                                                    }
                                                    className="checkbox-text"
                                                  >
                                                    {data}
                                                  </label>
                                                </p>
                                              )
                                            )}
                                          </div> */}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setAminities(!aminities)}
                      >
                        <h6 className="font-style">Aminities</h6>
                        {!aminities ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>
                      {!aminities ? (
                        <></>
                      ) : (
                        <>
                          <div
                            className="flex-start"
                            style={{
                              marginTop: "-2px",
                              flexWrap: "wrap",
                              padding: "0px",
                            }}
                          >
                            <div
                              className="childlabel side-panel"
                              onClick={() => setparking(!parking)}
                            >
                              <label
                                className="option-bg-color"
                                style={
                                  parking === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                + Parking
                              </label>
                            </div>
                            <div
                              className="childlabel side-panel"
                              onClick={() => setpark(!park)}
                            >
                              <label
                                className="option-bg-color"
                                style={
                                  park === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                + Park
                              </label>
                            </div>
                            <div
                              className="childlabel side-panel"
                              onClick={() => setpowerbackup(!powerbackup)}
                            >
                              <label
                                className="option-bg-color"
                                style={
                                  powerbackup === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                + Power Backup
                              </label>
                            </div>
                            <div
                              className="childlabel side-panel"
                              onClick={() => setLifts(!lifts)}
                            >
                              <label
                                className="option-bg-color"
                                style={
                                  lifts === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                + Lift
                              </label>
                            </div>

                            <div
                              className="childlabel side-panel"
                              onClick={() => setPool(!pool)}
                            >
                              <label
                                className="option-bg-color"
                                style={
                                  pool === true
                                    ? {
                                        border: " 1px solid #a3daff",
                                        color: "#091e42",
                                        fontWeight: "600",
                                        background: "#f0f9ff",
                                      }
                                    : {}
                                }
                              >
                                + Swimming Pool
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {/* <div
                className="flex-between m-10"
                style={{ borderBottom: "1px solid #ddd", padding: "10px" }}
              >
                <div> Properties with photos</div>
                <Switch defaultChecked />
              </div> */}
                    {/* <div
                className="flex-between m-10"
                style={{ borderBottom: "1px solid #ddd", padding: "10px" }}
              >
                <div> Properties with videos</div>
                <Switch defaultChecked />
              </div> */}
                    <div className="m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setFurnishingStatusShow(!furnishingStatusShow)
                        }
                      >
                        <h6 className="font-style">furnishing Status</h6>
                        {!furnishingStatusShow ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>
                      {!furnishingStatusShow ? (
                        <></>
                      ) : (
                        <>
                          <div
                            className="flex-start"
                            style={{
                              marginTop: "-2px",
                              flexWrap: "wrap",
                              padding: "0px",
                            }}
                          >
                            {furnishingStatus.map((data, index) => (
                              <div
                                className="childlabel side-panel"
                                key={index}
                                onClick={() => {
                                  handleFurnishedPress(data.name);
                                }}
                              >
                                <label
                                  className="option-bg-color "
                                  style={
                                    selectedFurnished.includes(data.name)
                                      ? {
                                          border: " 1px solid #a3daff",
                                          color: "#091e42",
                                          fontWeight: "600",
                                          background: "#f0f9ff",
                                        }
                                      : {}
                                  }
                                >
                                  {data.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="m-10">
                      <div
                        className="heading"
                        style={{ cursor: "pointer" }}
                        onClick={() => setReraStatusShow(!reraStatusShow)}
                      >
                        <h6 className="font-style">RERA Approved</h6>
                        {!reraStatusShow ? (
                          <>
                            <FaChevronDown />
                          </>
                        ) : (
                          <>
                            <FaChevronUp />
                          </>
                        )}
                      </div>
                      {!reraStatusShow ? (
                        <></>
                      ) : (
                        <>
                          <div
                            className="flex-start"
                            style={{
                              marginTop: "-2px",
                              flexWrap: "wrap",
                              padding: "0px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {rearaStatus.map((data, index) => (
                              <div
                                className="childlabel side-panel"
                                key={index}
                                onClick={() => {
                                  handleReraPress(data.name);
                                }}
                              >
                                <label
                                  className="option-bg-color "
                                  style={
                                    selectReraapprovels.includes(data.name)
                                      ? {
                                          border: " 1px solid #a3daff",
                                          color: "#091e42",
                                          fontWeight: "600",
                                          background: "#f0f9ff",
                                        }
                                      : {}
                                  }
                                >
                                  {data.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {loadingdata ? (
            <>
              <div
                style={{
                  width: "100%",
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
              </div>
            </>
          ) : (
            <>
              <div className="searchcont_rightbox">
                {showProperty.length > 0 ? (
                  <>
                    {params.supercat === "Commercial" ? (
                      <>
                        <h2 className="font-style mainsearchtoptext">
                          Commercial property for sale in Hyderabad
                        </h2>
                      </>
                    ) : (
                      <>
                        <h2 className="font-style mainsearchtoptext">
                          {showProperty.length} results | Property in Hyderabad
                          for{" "}
                          {params.subcat === "Plot-Land" ? (
                            <>{params.subcat}</>
                          ) : (
                            <>{params.cat}</>
                          )}
                        </h2>
                      </>
                    )}

                    <div className="filterdisplayflex">
                      <div className="filtermain" onClick={handleOpen}>
                        <CiFilter size="18" />
                        <p className="filtertext">Filters</p>
                      </div>
                      <div className="filtermain">
                        <FaSort size="18" />
                        <p className="filtertext">Sort</p>
                      </div>
                      <div className="filtermain">
                        <HiMiniUserCircle size="18" />
                        <p className="filtertext">Owner</p>
                      </div>
                    </div>

                    <BottomSheet
                      isOpen={isBottomSheetOpen}
                      onClose={handleClose}
                    >
                      {/* new component here */}
                      <MainBottomSheetComponent
                        filterComponentIndex={filterComponentIndex}
                        changeFilterCompoIndex={changeFilterCompoIndex}
                        onClose={handleClose}
                      />
                    </BottomSheet>

                    {showProperty.map((property, index) => (
                      <>
                        {params.cat === "Pg" ? (
                          <>
                            <div className="search_propertybox" key={index}>
                              <div
                                className="search_propertyboxTop"
                                onClick={(e) => PropertyinfoClick(e, property)}
                              >
                                <div className="search_pro_imageBox">
                                  {property.DeskImg.length > 0 ? (
                                    <>
                                      <Link className="geodir-category-img_item">
                                        <img
                                          src={property.DeskImg[0]}
                                          className="searchProperty-img"
                                          alt=""
                                        />
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <Link className="geodir-category-img_item">
                                        <img
                                          src="/assets/images/noimage1.png"
                                          alt=""
                                        />
                                      </Link>
                                    </>
                                  )}
                                </div>
                                <div className="search_pro_Detail_box">
                                  <h4
                                    className="name_appartment"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {property.apartment}
                                  </h4>
                                  <div className="Detail_box_name">
                                    <div className="Detail_box_namebox">
                                      <h4
                                        style={{
                                          textTransform: "capitalize",
                                          paddingTop: "6px",
                                        }}
                                      >
                                        {" PG/Paying Guest in " +
                                          property.locality +
                                          ", " +
                                          property.city}
                                      </h4>
                                    </div>
                                    <div className="Detail_box_name_icons">
                                      <MdBlock className="icon_name" />{" "}
                                      <CiStar className="icon_name" />
                                    </div>
                                  </div>
                                  <div className="price_squre_box">
                                    <div className="squre_box_one">
                                      <div className="squre_box_main">
                                        <div className="squre_box_top">
                                          <h1>₹ {property.visiblePrice}</h1>
                                        </div>
                                        {/* <div className="squre_box_bottom">
                                          <h4>
                                            ₹ {property.pricePerSqft} per sq.ft.
                                          </h4>
                                        </div> */}
                                      </div>
                                    </div>
                                    <div className="squre_box_two">
                                      <div className="squre_box_main">
                                        <div className="squre_box_top">
                                          <h1>
                                            {property.typeofRoom === "Sharing"
                                              ? "Shared Room"
                                              : "Private Room"}
                                          </h1>
                                        </div>
                                        <div className="squre_box_bottom">
                                          {/* <h2> Shared by </h2> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="nearby_box">
                                    {property.closetoMetroStation === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Metro Station
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoSchool === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To School
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoHospital === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Hospital
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoMarket === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Market
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoRailwayStation === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Railway Station
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoAirport === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Airport
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoMall === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Mall
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoHighway === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Highway
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="proprty_detail_blog">
                                    {index === currentPropertyIndex ? (
                                      <>
                                        <h6>
                                          {property.unicProperty}
                                          <span
                                            onClick={() =>
                                              setCurrentPropertyIndex("")
                                            }
                                          >
                                            less
                                          </span>
                                        </h6>
                                      </>
                                    ) : (
                                      <>
                                        {property.unicProperty.length >= 70 ? (
                                          <>
                                            <h6>
                                              {property.unicProperty.slice(
                                                0,
                                                70
                                              )}
                                              ...{" "}
                                              <span
                                                onClick={() =>
                                                  setCurrentPropertyIndex(index)
                                                }
                                              >
                                                more
                                              </span>
                                            </h6>
                                          </>
                                        ) : (
                                          <>
                                            <h6>{property.unicProperty}</h6>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>

                                  <div className="property_sale_detail">
                                    <div className="sale_detail_button">
                                      {property.availabilityStatus}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="search_propertybox_Bottom">
                                <div className="propertybox_Bottom_left">
                                  <div className="squre_box_main">
                                    <div className="Bottom_left_top">
                                      <h4>
                                        Posted on{" "}
                                        {moment(property.createdAt).format(
                                          "Do MMM YY"
                                        )}{" "}
                                        by {property.postUserPosition}
                                      </h4>
                                    </div>
                                    <div className="Bottom_left_bottom">
                                      <h1>{property.postUserName}</h1>
                                    </div>
                                  </div>
                                </div>
                                {isAuth ? (
                                  <>
                                    <div className="propertybox_Bottom_right">
                                      {loginData.creadits >= 1 ? (
                                        <>
                                          <div
                                            className="vianumber_button"
                                            onClick={(e) =>
                                              toggleClick(e, property)
                                            }
                                          >
                                            View Phone Number
                                          </div>
                                          <div
                                            className="contact_button"
                                            onClick={(e) =>
                                              toggleClick(e, property)
                                            }
                                          >
                                            Contact {property.postUserPosition}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="vianumber_button"
                                            onClick={() => {
                                              navigate("/dashboard");
                                              dispatch(pageStatusChange(9));
                                            }}
                                          >
                                            View Phone Number
                                          </div>

                                          <div
                                            className="contact_button"
                                            onClick={() => {
                                              navigate("/dashboard");
                                              dispatch(pageStatusChange(9));
                                            }}
                                          >
                                            Contact {property.postUserPosition}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="propertybox_Bottom_right">
                                      <div
                                        className="vianumber_button"
                                        onClick={() => verifyLogin()}
                                      >
                                        View Phone Number
                                      </div>
                                      <div
                                        className="contact_button"
                                        onClick={() => verifyLogin()}
                                      >
                                        Contact {property.postUserPosition}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ) : params.cat === "Rent" ? (
                          <>
                            <div className="search_propertybox" key={index}>
                              <div
                                className="search_propertyboxTop"
                                onClick={(e) => PropertyinfoClick(e, property)}
                              >
                                <div className="search_pro_imageBox">
                                  {property.DeskImg.length > 0 ? (
                                    <>
                                      <Link className="geodir-category-img_item">
                                        <img
                                          src={property.DeskImg[0]}
                                          className="searchProperty-img"
                                          alt=""
                                        />
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <Link className="geodir-category-img_item">
                                        <img
                                          src="/assets/images/noimage1.png"
                                          alt=""
                                        />
                                      </Link>
                                    </>
                                  )}
                                </div>
                                <div className="search_pro_Detail_box">
                                  <h4
                                    className="name_appartment"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {property.apartment}
                                  </h4>
                                  <div className="Detail_box_name">
                                    <div className="Detail_box_namebox">
                                      <h4
                                        style={{
                                          textTransform: "capitalize",
                                          paddingTop: "6px",
                                        }}
                                      >
                                        {property.subCategory +
                                          " in " +
                                          property.locality +
                                          ", " +
                                          property.city}
                                      </h4>
                                    </div>
                                    <div className="Detail_box_name_icons">
                                      <MdBlock className="icon_name" />{" "}
                                      <CiStar className="icon_name" />
                                    </div>
                                  </div>
                                  <div className="price_squre_box">
                                    <div className="squre_box_one">
                                      <div className="squre_box_main">
                                        <div className="squre_box_top">
                                          <h1>
                                            ₹ {property.visiblePrice}
                                            {/* <span>Cr</span> */}
                                          </h1>
                                        </div>
                                        <div className="squre_box_bottom">
                                          <h4> per Month</h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="squre_box_two">
                                      <div className="squre_box_main">
                                        <div className="squre_box_top">
                                          <h1>
                                            {property.carpetArea}{" "}
                                            <span>
                                              {" "}
                                              {
                                                property.carpetAreaMesurmentType
                                              }{" "}
                                            </span>
                                          </h1>
                                        </div>
                                        <div className="squre_box_bottom">
                                          <h4> Carpet Area</h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="squre_box_three">
                                      <div className="squre_box_main">
                                        <div className="squre_box_top">
                                          <h1>{property.noofBedrooms} BHK</h1>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="nearby_box">
                                    {property.closetoMetroStation === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Metro Station
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoSchool === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To School
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoHospital === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Hospital
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoMarket === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Market
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoRailwayStation === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Railway Station
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoAirport === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Airport
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoMall === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Mall
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoHighway === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Highway
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>

                                  <div className="proprty_detail_blog">
                                    {index === currentPropertyIndex ? (
                                      <>
                                        <h6>
                                          {property.unicProperty}
                                          <span
                                            onClick={() =>
                                              setCurrentPropertyIndex("")
                                            }
                                          >
                                            less
                                          </span>
                                        </h6>
                                      </>
                                    ) : (
                                      <>
                                        {property.unicProperty.length >= 70 ? (
                                          <>
                                            <h6>
                                              {property.unicProperty.slice(
                                                0,
                                                70
                                              )}
                                              ...{" "}
                                              <span
                                                onClick={() =>
                                                  setCurrentPropertyIndex(index)
                                                }
                                              >
                                                more
                                              </span>
                                            </h6>
                                          </>
                                        ) : (
                                          <>
                                            <h6>{property.unicProperty}</h6>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>

                                  <div className="property_sale_detail">
                                    <div className="sale_detail_button">
                                      {property.availabilityStatus}
                                    </div>
                                    {/* <div className="sale_detail_button">resale</div> */}
                                  </div>
                                </div>
                              </div>

                              <div className="search_propertybox_Bottom">
                                <div className="propertybox_Bottom_left">
                                  <div className="squre_box_main">
                                    <div className="Bottom_left_top">
                                      <h4>
                                        Posted on{" "}
                                        {moment(property.createdAt).format(
                                          "Do MMM YY"
                                        )}{" "}
                                        by {property.postUserPosition}
                                      </h4>
                                    </div>
                                    <div className="Bottom_left_bottom">
                                      <h1>{property.postUserName}</h1>
                                    </div>
                                  </div>
                                </div>
                                {isAuth ? (
                                  <>
                                    <div className="propertybox_Bottom_right">
                                      {loginData.creadits >= 1 ? (
                                        <>
                                          <div
                                            className="vianumber_button"
                                            onClick={(e) =>
                                              toggleClick(e, property)
                                            }
                                          >
                                            View Phone Number
                                          </div>
                                          <div
                                            className="contact_button"
                                            onClick={(e) =>
                                              toggleClick(e, property)
                                            }
                                          >
                                            Contact {property.postUserPosition}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="vianumber_button"
                                            onClick={() => {
                                              navigate("/dashboard");
                                              dispatch(pageStatusChange(9));
                                            }}
                                          >
                                            View Phone Number
                                          </div>

                                          <div
                                            className="contact_button"
                                            onClick={() => {
                                              navigate("/dashboard");
                                              dispatch(pageStatusChange(9));
                                            }}
                                          >
                                            Contact {property.postUserPosition}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="propertybox_Bottom_right">
                                      <div
                                        className="vianumber_button"
                                        onClick={() => verifyLogin()}
                                      >
                                        View Phone Number
                                      </div>
                                      <div
                                        className="contact_button"
                                        onClick={() => verifyLogin()}
                                      >
                                        Contact {property.postUserPosition}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ) : params.supercat === "Commercial" ? (
                          <>
                            <div className="search_propertybox" key={index}>
                              <div
                                className="search_propertyboxTop"
                                onClick={(e) => PropertyinfoClick(e, property)}
                              >
                                <div className="search_pro_imageBox">
                                  {property.DeskImg.length > 0 ? (
                                    <>
                                      <Link className="geodir-category-img_item">
                                        <img
                                          src={property.DeskImg[0]}
                                          className="searchProperty-img"
                                          alt=""
                                        />
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <Link className="geodir-category-img_item">
                                        <img
                                          src="/assets/images/noimage1.png"
                                          alt=""
                                        />
                                      </Link>
                                    </>
                                  )}
                                </div>
                                <div className="search_pro_Detail_box">
                                  <h4
                                    className="name_appartment"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {property.apartment === ""
                                      ? property.locality
                                      : property.apartment}
                                  </h4>

                                  <div className="Detail_box_name">
                                    <div className="Detail_box_namebox">
                                      <h4
                                        style={{
                                          textTransform: "capitalize",
                                          paddingTop: "6px",
                                        }}
                                      >
                                        {property.subCategory +
                                          " in " +
                                          property.locality +
                                          ", " +
                                          property.city}
                                      </h4>
                                    </div>
                                    <div className="Detail_box_name_icons">
                                      <MdBlock className="icon_name" />{" "}
                                      <CiStar className="icon_name" />
                                    </div>
                                  </div>
                                  <div className="price_squre_box">
                                    <div className="squre_box_one">
                                      <div className="squre_box_main">
                                        <div className="squre_box_top">
                                          <h1>
                                            ₹ {property.visiblePrice}
                                            {/* <span>Cr</span> */}
                                          </h1>
                                        </div>
                                        <div className="squre_box_bottom">
                                          <h4>
                                            {" "}
                                            ₹ {property.pricePerSqft} per sq.ft.
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="squre_box_two">
                                      <div className="squre_box_main">
                                        <div className="squre_box_top">
                                          <h1>
                                            {property.carpetArea}{" "}
                                            <span>
                                              {" "}
                                              {
                                                property.carpetAreaMesurmentType
                                              }{" "}
                                            </span>
                                          </h1>
                                        </div>
                                        <div className="squre_box_bottom">
                                          <h4> Carpet Area</h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="nearby_box">
                                    {property.closetoMetroStation === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Metro Station
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoSchool === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To School
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoHospital === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Hospital
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoMarket === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Market
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoRailwayStation === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Railway Station
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoAirport === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Airport
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoMall === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Mall
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoHighway === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Highway
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="proprty_detail_blog">
                                    {index === currentPropertyIndex ? (
                                      <>
                                        <h6>
                                          {property.unicProperty}
                                          <span
                                            onClick={() =>
                                              setCurrentPropertyIndex("")
                                            }
                                          >
                                            less
                                          </span>
                                        </h6>
                                      </>
                                    ) : (
                                      <>
                                        {property.unicProperty.length >= 70 ? (
                                          <>
                                            <h6>
                                              {property.unicProperty.slice(
                                                0,
                                                70
                                              )}
                                              ...{" "}
                                              <span
                                                onClick={() =>
                                                  setCurrentPropertyIndex(index)
                                                }
                                              >
                                                more
                                              </span>
                                            </h6>
                                          </>
                                        ) : (
                                          <>
                                            <h6>{property.unicProperty}</h6>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>

                                  <div className="property_sale_detail">
                                    <div className="sale_detail_button">
                                      {property.availabilityStatus}
                                    </div>
                                    {/* <div className="sale_detail_button">resale</div> */}
                                  </div>
                                </div>
                              </div>
                              <div className="search_propertybox_Bottom">
                                <div className="propertybox_Bottom_left">
                                  <div className="squre_box_main">
                                    <div className="Bottom_left_top">
                                      <h4>
                                        Posted on{" "}
                                        {moment(property.createdAt).format(
                                          "Do MMM YY"
                                        )}{" "}
                                        by {property.postUserPosition}
                                      </h4>
                                    </div>
                                    <div className="Bottom_left_bottom">
                                      <h1>{property.postUserName}</h1>
                                    </div>
                                  </div>
                                </div>
                                {isAuth ? (
                                  <>
                                    <div className="propertybox_Bottom_right">
                                      {loginData.creadits >= 1 ? (
                                        <>
                                          <div
                                            className="vianumber_button"
                                            onClick={(e) =>
                                              toggleClick(e, property)
                                            }
                                          >
                                            View Phone Number
                                          </div>
                                          <div
                                            className="contact_button"
                                            onClick={(e) =>
                                              toggleClick(e, property)
                                            }
                                          >
                                            Contact {property.postUserPosition}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="vianumber_button"
                                            onClick={() => {
                                              navigate("/dashboard");
                                              dispatch(pageStatusChange(9));
                                            }}
                                          >
                                            View Phone Number
                                          </div>

                                          <div
                                            className="contact_button"
                                            onClick={() => {
                                              navigate("/dashboard");
                                              dispatch(pageStatusChange(9));
                                            }}
                                          >
                                            Contact {property.postUserPosition}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="propertybox_Bottom_right">
                                      <div
                                        className="vianumber_button"
                                        onClick={() => verifyLogin()}
                                      >
                                        View Phone Number
                                      </div>
                                      <div
                                        className="contact_button"
                                        onClick={() => verifyLogin()}
                                      >
                                        Contact {property.postUserPosition}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ) : params.subcat === "Plot-Land" ? (
                          <>
                            <div className="search_propertybox" key={index}>
                              <div
                                className="search_propertyboxTop"
                                onClick={(e) => PropertyinfoClick(e, property)}
                              >
                                <div className="search_pro_imageBox">
                                  {property.DeskImg.length > 0 ? (
                                    <>
                                      <Link className="geodir-category-img_item">
                                        <img
                                          src={property.DeskImg[0]}
                                          className="searchProperty-img"
                                          alt=""
                                        />
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <Link className="geodir-category-img_item">
                                        <img
                                          src="/assets/images/noimage1.png"
                                          alt=""
                                        />
                                      </Link>
                                    </>
                                  )}
                                </div>
                                <div className="search_pro_Detail_box">
                                  <h4
                                    className="name_appartment"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {property.apartment}
                                  </h4>
                                  <div className="Detail_box_name">
                                    <div className="Detail_box_namebox">
                                      <h4
                                        style={{
                                          textTransform: "capitalize",
                                          paddingTop: "6px",
                                        }}
                                      >
                                        {property.subCategory +
                                          " in " +
                                          property.locality +
                                          ", " +
                                          property.city}
                                      </h4>
                                    </div>
                                    <div className="Detail_box_name_icons">
                                      <MdBlock className="icon_name" />{" "}
                                      <CiStar className="icon_name" />
                                    </div>
                                  </div>
                                  <div className="price_squre_box">
                                    <div className="squre_box_one">
                                      <div className="squre_box_main">
                                        <div className="squre_box_top">
                                          <h1>
                                            ₹ {property.visiblePrice}
                                            {/* <span>Cr</span> */}
                                          </h1>
                                        </div>
                                        <div className="squre_box_bottom">
                                          <h4>
                                            {" "}
                                            ₹ {property.pricePerSqft} per sq.ft.
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="squre_box_two">
                                      <div className="squre_box_main">
                                        <div className="squre_box_top">
                                          <h1>
                                            {property.carpetArea}{" "}
                                            <span>
                                              {" "}
                                              {
                                                property.carpetAreaMesurmentType
                                              }{" "}
                                            </span>
                                          </h1>
                                        </div>
                                        <div className="squre_box_bottom">
                                          <h4> Carpet Area</h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="squre_box_three">
                                      <div className="squre_box_main">
                                        <div className="squre_box_top">
                                          <h1>
                                            {property.noofBedrooms} Plot/Land
                                          </h1>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="nearby_box">
                                    {property.closetoMetroStation === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Metro Station
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoSchool === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To School
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoHospital === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Hospital
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoMarket === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Market
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoRailwayStation === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Railway Station
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoAirport === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Airport
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoMall === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Mall
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoHighway === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Highway
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="proprty_detail_blog">
                                    {index === currentPropertyIndex ? (
                                      <>
                                        <h6>
                                          {property.unicProperty}
                                          <span
                                            onClick={() =>
                                              setCurrentPropertyIndex("")
                                            }
                                          >
                                            less
                                          </span>
                                        </h6>
                                      </>
                                    ) : (
                                      <>
                                        {property.unicProperty.length >= 70 ? (
                                          <>
                                            <h6>
                                              {property.unicProperty.slice(
                                                0,
                                                70
                                              )}
                                              ...{" "}
                                              <span
                                                onClick={() =>
                                                  setCurrentPropertyIndex(index)
                                                }
                                              >
                                                more
                                              </span>
                                            </h6>
                                          </>
                                        ) : (
                                          <>
                                            <h6>{property.unicProperty}</h6>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>

                                  {/* <div className="property_sale_detail">
                                    <div className="sale_detail_button">
                                      {property.availabilityStatus}
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                              <div className="search_propertybox_Bottom">
                                <div className="propertybox_Bottom_left">
                                  <div className="squre_box_main">
                                    <div className="Bottom_left_top">
                                      <h4>
                                        Posted on{" "}
                                        {moment(property.createdAt).format(
                                          "Do MMM YY"
                                        )}{" "}
                                        by {property.postUserPosition}
                                      </h4>
                                    </div>
                                    <div className="Bottom_left_bottom">
                                      <h1>{property.postUserName}</h1>
                                    </div>
                                  </div>
                                </div>
                                {isAuth ? (
                                  <>
                                    <div className="propertybox_Bottom_right">
                                      {loginData.creadits >= 1 ? (
                                        <>
                                          <div
                                            className="vianumber_button"
                                            onClick={(e) =>
                                              toggleClick(e, property)
                                            }
                                          >
                                            View Phone Number
                                          </div>
                                          <div
                                            className="contact_button"
                                            onClick={(e) =>
                                              toggleClick(e, property)
                                            }
                                          >
                                            Contact {property.postUserPosition}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="vianumber_button"
                                            onClick={() => {
                                              navigate("/dashboard");
                                              dispatch(pageStatusChange(9));
                                            }}
                                          >
                                            View Phone Number
                                          </div>

                                          <div
                                            className="contact_button"
                                            onClick={() => {
                                              navigate("/dashboard");
                                              dispatch(pageStatusChange(9));
                                            }}
                                          >
                                            Contact {property.postUserPosition}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="propertybox_Bottom_right">
                                      <div
                                        className="vianumber_button"
                                        onClick={() => verifyLogin()}
                                      >
                                        View Phone Number
                                      </div>
                                      <div
                                        className="contact_button"
                                        onClick={() => verifyLogin()}
                                      >
                                        Contact {property.postUserPosition}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="search_propertybox" key={index}>
                              <div
                                className="search_propertyboxTop"
                                onClick={(e) => PropertyinfoClick(e, property)}
                              >
                                <div className="search_pro_imageBox">
                                  {property.DeskImg.length > 0 ? (
                                    <>
                                      <Link className="geodir-category-img_item">
                                        <img
                                          src={property.DeskImg[0]}
                                          className="searchProperty-img"
                                          alt=""
                                        />
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <Link className="geodir-category-img_item">
                                        <img
                                          src="/assets/images/noimage1.png"
                                          alt=""
                                        />
                                      </Link>
                                    </>
                                  )}
                                </div>

                                <div className="search_pro_Detail_box">
                                  <h4
                                    className="name_appartment"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {property.apartment}
                                  </h4>
                                  <div className="Detail_box_name">
                                    <div className="Detail_box_namebox">
                                      {property.subCategory === "Plot" ||
                                      property.subCategory === "Land" ? (
                                        <>
                                          <h4
                                            style={{
                                              textTransform: "capitalize",
                                              paddingTop: "6px",
                                            }}
                                          >
                                            {property.subCategory +
                                              " in " +
                                              property.locality +
                                              ", " +
                                              property.city}
                                          </h4>
                                        </>
                                      ) : (
                                        <>
                                          <h4
                                            style={{
                                              textTransform: "capitalize",
                                              paddingTop: "6px",
                                            }}
                                          >
                                            {property.noofBedrooms +
                                              " BHK" +
                                              property.subCategory +
                                              " in " +
                                              property.locality +
                                              ", " +
                                              property.city}
                                          </h4>
                                        </>
                                      )}
                                    </div>
                                    <div className="Detail_box_name_icons">
                                      <MdBlock className="icon_name" />{" "}
                                      <CiStar className="icon_name" />
                                    </div>
                                  </div>
                                  <div className="price_squre_box">
                                    <div className="squre_box_one">
                                      <div className="squre_box_main">
                                        <div className="squre_box_top">
                                          <h1>
                                            ₹ {property.visiblePrice}
                                            {/* <span>Cr</span> */}
                                          </h1>
                                        </div>
                                        <div className="squre_box_bottom">
                                          <h4>
                                            {" "}
                                            ₹ {property.pricePerSqft} per sq.ft.
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="squre_box_two">
                                      <div className="squre_box_main">
                                        <div className="squre_box_top">
                                          <h1>
                                            {property.carpetArea}{" "}
                                            <span>
                                              {" "}
                                              {
                                                property.carpetAreaMesurmentType
                                              }{" "}
                                            </span>
                                          </h1>
                                        </div>
                                        <div className="squre_box_bottom">
                                          {property.superbuildupArea > 0 ? (
                                            <>
                                              <h4>
                                                ({property.superbuildupArea}{" "}
                                                {
                                                  property.superbuildupAreaMesurmentType
                                                }{" "}
                                                .) Super built-up Area
                                              </h4>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="squre_box_three">
                                      <div className="squre_box_main">
                                        <div className="squre_box_top">
                                          {/* <h1>{property.subCategory} subCategory</h1> */}
                                          {property.subCategory === "Plot" ||
                                          property.subCategory === "Land" ? (
                                            <>
                                              <h1>Plot/Land</h1>
                                            </>
                                          ) : (
                                            <>
                                              <h1>
                                                {property.noofBedrooms} BHK
                                              </h1>
                                            </>
                                          )}
                                        </div>

                                        {property.subCategory === "Plot" ||
                                        property.subCategory === "Land" ? (
                                          <></>
                                        ) : (
                                          <>
                                            <div className="squre_box_bottom">
                                              <h4>
                                                {property.noofBathrooms} Baths
                                              </h4>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="nearby_box">
                                    {property.closetoMetroStation === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Metro Station
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoSchool === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To School
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoHospital === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Hospital
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoMarket === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Market
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoRailwayStation === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Railway Station
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoAirport === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Airport
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoMall === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Mall
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {property.closetoHighway === true ? (
                                      <>
                                        <div className="nearby_boxone">
                                          <CgArrowTopRight
                                            style={{
                                              color: "#28ad35",
                                              fontSize: "13px",
                                            }}
                                          />{" "}
                                          Close To Highway
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="proprty_detail_blog">
                                    {index === currentPropertyIndex ? (
                                      <>
                                        <h6>
                                          {property.unicProperty}
                                          <span
                                            onClick={() =>
                                              setCurrentPropertyIndex("")
                                            }
                                          >
                                            less
                                          </span>
                                        </h6>
                                      </>
                                    ) : (
                                      <>
                                        {property.unicProperty.length >= 70 ? (
                                          <>
                                            <h6>
                                              {property.unicProperty.slice(
                                                0,
                                                70
                                              )}
                                              ...{" "}
                                              <span
                                                onClick={() =>
                                                  setCurrentPropertyIndex(index)
                                                }
                                              >
                                                more
                                              </span>
                                            </h6>
                                          </>
                                        ) : (
                                          <>
                                            <h6>{property.unicProperty}</h6>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>

                                  <div className="property_sale_detail">
                                    {property.subCategory === "Plot" ||
                                    property.subCategory === "Land" ? (
                                      <>
                                        <h1></h1>
                                      </>
                                    ) : (
                                      <>
                                        <div className="sale_detail_button">
                                          {property.availabilityStatus}
                                        </div>
                                      </>
                                    )}

                                    {/* <div className="sale_detail_button">resale</div> */}
                                  </div>
                                </div>
                              </div>

                              <div className="search_propertybox_Bottom">
                                <div className="propertybox_Bottom_left">
                                  <div className="squre_box_main">
                                    <div className="Bottom_left_top">
                                      <h4>
                                        Posted on{" "}
                                        {moment(property.createdAt).format(
                                          "Do MMM YY"
                                        )}{" "}
                                        by {property.postUserPosition}
                                      </h4>
                                    </div>
                                    <div className="Bottom_left_bottom">
                                      <h1>{property.postUserName}</h1>
                                    </div>
                                  </div>
                                </div>
                                {isAuth ? (
                                  <>
                                    <div className="propertybox_Bottom_right">
                                      {loginData.creadits >= 1 ? (
                                        <>
                                          <div
                                            className="vianumber_button"
                                            onClick={(e) =>
                                              toggleClick(e, property)
                                            }
                                          >
                                            View Phone Number
                                          </div>
                                          <div
                                            className="contact_button"
                                            onClick={(e) =>
                                              toggleClick(e, property)
                                            }
                                          >
                                            Contact {property.postUserPosition}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="vianumber_button"
                                            onClick={() => {
                                              navigate("/dashboard");
                                              dispatch(pageStatusChange(9));
                                            }}
                                          >
                                            View Phone Number
                                          </div>

                                          <div
                                            className="contact_button"
                                            onClick={() => {
                                              navigate("/dashboard");
                                              dispatch(pageStatusChange(9));
                                            }}
                                          >
                                            Contact {property.postUserPosition}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="propertybox_Bottom_right">
                                      <div
                                        className="vianumber_button"
                                        onClick={() => verifyLogin()}
                                      >
                                        View Phone Number
                                      </div>
                                      <div
                                        className="contact_button"
                                        onClick={() => verifyLogin()}
                                      >
                                        Contact {property.postUserPosition}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <div>
                      <div
                        style={{
                          background: "#fffbf3",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          display: "flex",
                          padding: "15px",
                          borderRadius: "4px",
                        }}
                      >
                        <img
                          src="/assets/images/newimages/pricehome.png"
                          alt="image"
                          style={{
                            width: "30px",
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                        />
                        <span
                          style={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "500",
                            alignItems: "center",
                          }}
                        >
                          <font color="#0078DB">
                            No property found <FaArrowRightLong />
                          </font>
                        </span>
                      </div>

                      <div
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          padding: "30px",
                        }}
                      >
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "flex-start",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <MdOutlineSearchOff size={30} />
                            <span
                              style={{
                                color: "#000",
                                fontSize: "22px",
                                fontWeight: "700",
                              }}
                            >
                              No results matching your search
                            </span>
                          </div>
                          <div
                            // style={{
                            //   justifyContent: "flex-start",
                            //   display: "flex",
                            // }}
                            onClick={() => clearAllClick()}
                          >
                            <button className="clear-all-filters-button">
                              Clear all filters
                            </button>
                          </div>
                        </div>
                        <div
                          style={{
                            justifyContent: "flex-start",
                            display: "grid",
                            marginTop: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "#000",
                              fontSize: "18px",
                              fontWeight: "400",
                            }}
                          >
                            Try removing some filters:
                          </span>

                          <div style={{ display: "flex", marginTop: "15px" }}>
                            {allselectedFilters.map((data, index) => (
                              <div
                                className=""
                                style={{
                                  background: "#f0f9ff",
                                  border: "1px solid #a3daff",
                                  borderRadius: "50px",
                                  padding: "5px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                  paddingRight: "10px",
                                  paddingLeft: "10px",
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                <p
                                  className=""
                                  style={{ color: "#000", margin: 0 }}
                                >
                                  {data}
                                </p>
                                <RxCross2 color="#0078db" size={20} />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="geodir-category-footer fl-wrap">
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="modalmainnew"
          style={{}}
        >
          <div className="model-wrappernew">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
              <PropertyDetailsModel
                closePropdet={closePropDetailModal}
                propertyprop={singleProperty}
                changeView={changeView}
              />
            </ModalBody>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SearchPropertyy;
