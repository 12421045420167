import React, { useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import InterestingReads1 from './IntrestingReades1'
import OwnersandDealers from './OwnersandDealers'
import SimplePricing from '../home/SimplePricing';

const PostProperty = () => {

    const [acordion1, setAcordion1] = useState(false);
    const [acordion2, setAcordion2] = useState(false);
    const [acordion3, setAcordion3] = useState(false);
    const [acordion4, setAcordion4] = useState(false);
    const [acordion5, setAcordion5] = useState(false);

    const toggleAccordion1 = () => {
        setAcordion1(!acordion1);
    };
    const toggleAccordion2 = () =>{
        setAcordion2(!acordion2);
    };
    const toggleAccordion3 = () =>{
        setAcordion3(!acordion3);
    };
    const toggleAccordion4 = () =>{
        setAcordion4(!acordion4);
    };
    const toggleAccordion5 = () =>{
        setAcordion5(!acordion5);
    };

    return (
        <>
            <div className='post-property-main-wrapper'>
                <div className='pp-firstpage'>
                    <div className='pp-firstpage-graybox'></div>
                    <div className='top-in-mainwrapper'>
                        <div className='top-in-first-mainwrapper' id='mainwrapper_gradiant'>
                            <div className='top-in-first-mainwrapper-1'>
                                <div className='pp-inhead'>
                                    <div className='pp-inhead-bold'>
                                        Sell or Rent your Property
                                        <span className='pp-blue-text'> faster</span> with Mygoldenhomes.com
                                    </div>
                                </div>
                                <span className='pp-in-container'>
                                    <span className='ppt-green-tick'></span>
                                    <span className='ppt-container-subtext'>Advertise for FREE</span>
                                </span>
                                <span className='pp-in-container'>
                                    <span className='ppt-green-tick'></span>
                                    <span className='ppt-container-subtext'>Get unlimited enquiries</span>
                                </span>
                                <span className='pp-in-container'>
                                    <span className='ppt-green-tick'></span>
                                    <span className='ppt-container-subtext'>Get shortisted buyers and tenants</span>
                                    <span className='ppt-important'>*</span>
                                </span>
                                <span className='pp-in-container'>
                                    <span className='ppt-green-tick'></span>
                                    <span className='ppt-container-subtext'>Assistance in co-ordinating site visits</span>
                                    <span className='ppt-important'>*</span>
                                </span>
                                <img src="/assets/images/sliderImg/Desktop_Animation_01.GIF" alt="" className='ppt-bg-img' />
                                <div className='ppt-btm-text'>
                                    * Available with Owner Assist Plans
                                </div>
                            </div>
                            <div className='ppt-dummy-left'></div>
                            <div className='top-in-mainwrapper-form-section'>
                                <div className='mainwrapper-form-section-bold'>Start posting your property, it’s free</div>
                                <div className='mainwrapper-form-section-semibold'>Add Basic Details</div>
                                <div className='ppt-card-step1'>
                                    <div className='ppt-card-step1-inner'>
                                        <div>
                                            <div className='step1-inner-boldtext'>You're looking to <span>...</span></div>
                                            <div className='ppt-card-step1-component' id='preference-input'>
                                                <div className='ppt-card-step1-component-items'>
                                                    <div id='ppt' className='page-component-ppt active'>
                                                        <span>Sell</span>
                                                    </div>
                                                    <div id='ppt' className='page-component-ppt'>
                                                        <span>Rent / Lease</span>
                                                    </div>
                                                    <div id='ppt' className='page-component-ppt'>
                                                        <span>PG</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='spacer'></div>
                                            <div className='ppt-card-step2-component super-property-ppt'>
                                                <div>
                                                    <div className='super-property-boldtext'>And it's a <span>...</span></div>
                                                    <div className='super-property-component' id='ppt-input'>
                                                        <div className='super-property-component-in'>
                                                            <div className='super-property-component-in1'>
                                                                <div>
                                                                    <input type="radio" label='Residential' design='system' name='Residential-ppt' value="Residential" />
                                                                    <label htmlFor="Residential">Residential</label>
                                                                </div>
                                                            </div>
                                                            <div className='super-property-component-in1'>
                                                                <div>
                                                                    <input type="radio" label='Commercial' design='system' name='Commercial-ppt' value="Commercial" />
                                                                    <label htmlFor="Commercial">Commercial</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id='super-property-type-input' className='super-property-component-mainwrap'>
                                                        <div className='multiple-input tags-wrapper-ppt'>
                                                            <div id='1' className='super-property-component-in1 radio-input-text-only'><span>Flat/Apartment</span></div>
                                                            <div id='2' className='super-property-component-in1 radio-input-text-only'><span>Independent House / Villa</span></div>
                                                            <div id='3' className='super-property-component-in1 radio-input-text-only'><span>Independent / Builder Floor</span></div>
                                                            <div id='4' className='super-property-component-in1 radio-input-text-only'><span>Plot / Land</span></div>
                                                        </div>
                                                        <div className='hyperlinks-medium-ppt'>
                                                            4 more
                                                            <i><BiChevronDown /></i>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className='ppt-button-button'>
                                                        <div>
                                                            <div className='button-ppt'><button className='button-semi-1'><span>Start now</span></button></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='details-knowmore'>
                            <div className='details-knowmore-inner'>
                                <a href='#DesktopSiteDetails'><span className='caption-strong-large'>Know More</span></a>
                                <i><BiChevronDown /></i>
                            </div>
                        </div>
                        <div id='DesktopSiteDetails' className='postPropSteps_ppfSteps'>
                            <h2 className='postPropSteps_stepsTitle'>
                                <div className='input_label_inactive'>HOW TO POST</div>
                                <div className='display_xxl_bold'>
                                    <div>Post Your Property in <br /> 3 Simple Steps</div>
                                </div>
                            </h2>
                            <div className='postPropSteps_stepsCol'>
                                <div>
                                    <div>
                                        <img src="/assets/images/sliderImg/TransactionPrices2.png" alt="" />
                                    </div>
                                    <h3 className='title_semiBold'>
                                        <span className='postPropSteps_blueText'>
                                            01.
                                        </span> Add details of your property
                                    </h3>
                                    <div className='caption_strong_large'>
                                        Begin by telling us the few basic details about your property like your property type, location,
                                        No. of rooms etc
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <img src="/assets/images/sliderImg/Gallery.png" alt="" />
                                    </div>
                                    <h3 className='title_semiBold'>
                                        <span className='postPropSteps_blueText'>
                                            01.
                                        </span> Add details of your property
                                    </h3>
                                    <div className='caption_strong_large'>
                                        Begin by telling us the few basic details about your property like your property type, location,
                                        No. of rooms etc
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <img src="/assets/images/sliderImg/Bank.png" alt="" />
                                    </div>
                                    <h3 className='title_semiBold'>
                                        <span className='postPropSteps_blueText'>
                                            01.
                                        </span> Add details of your property
                                    </h3>
                                    <div className='caption_strong_large'>
                                        Begin by telling us the few basic details about your property like your property type, location,
                                        No. of rooms etc
                                    </div>
                                </div>
                            </div>
                            <div className='postPropSteps_btnCont'>
                                <button className='buttons_primaryRegular'>
                                    <span>Begin to Post your Property</span>
                                </button>
                            </div>
                        </div>
                        <div className='visitorsBanner_visitorBanner'>
                            <div className='visitorsBanner_visBannerImg'>
                                <img src="/assets/images/sliderImg/visBanner.png" alt="" />
                            </div>
                            <div className='visitorsBanner_bannerCont'>
                                <div className='display_l_bold'>
                                    With over 7 million unique visitors monthly, your property gets maximum visibility on <span>Mygoldenhomes.com</span>
                                </div>
                                <div className='banner-spacer'></div>
                                <div className='visitorsBanner_bannerContCol'>
                                    <div>
                                        <div className='input_label_inactive'>OVER</div>
                                        <div className='display_xxl_semiBold'>1M</div>
                                        <div className='caption_strong_large'>Property Listings</div>
                                    </div>
                                    <div>
                                        <div className='input_label_inactive'>OVER</div>
                                        <div className='display_xxl_semiBold'>5.5M</div>
                                        <div className='caption_strong_large'>Monthly Searches</div>
                                    </div>
                                    <div>
                                        <div className='input_label_inactive'>OVER</div>
                                        <div className='display_xxl_semiBold'>200K</div>
                                        <div className='caption_strong_large'>Owners advertising monthly</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='additionalBenefits_addBenefits'>
                            <div className='input_label_inactive'>ADDITIONAL BENEFITS</div>
                            <div className='additionalBenefits_addBenPartition'>
                                <div>
                                    <h2 className='display_xxl_bold'>
                                        Everything Mygoldenhomes does to sell or <br /> rent out your property faster…
                                    </h2>
                                    <div className='additionalBenefits_listings'>
                                        <div className='caption_strong_large'>
                                            You can easily sell, rent out or lease a property online with Mygoldenhomes.com, India's best property portal.
                                            You can find the ideal buyers and tenants quickly and conveniently. Post your free advertisement to sell or rent out a house, flat, apartment, villa, land, shop,
                                            office or showroom and all other types of residential and commercial real estate to get qualified enquiries.
                                        </div>
                                        <div className='caption_strong_large'>
                                            Whether you are an owner, builder or broker or have a property for sale or office spaces for rent or lease,
                                            shops for rent/lease, put your independent house or flat on rent, you can advertise your property for free, on Mygoldenhomes.com,
                                            the best real estate property site.
                                        </div>
                                        <div className='caption_strong_large'>
                                            Mygoldenhomes.com is one of the most trustworthy channels to search buyers and tenants online for flats, independent houses,
                                            offices, shops, showrooms, warehouses and factories. What makes Mygoldenhomes.com unique is its high-quality website traffic and
                                            its reach to millions of households across India and abroad who are looking to rent or buy a residential or commercial property across India.
                                        </div>
                                        <div className='caption-spacer'></div>
                                        <button className='buttons_additionalBenefits'>
                                            <span>Begin to Post your Property</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '70%', padding: '40px', marginBottom: '10px' }}>
                            <div className='input_label_inactive'>KNOW MORE ABOUT POSTING ON Mygoldenhomes</div>
                            <h2 className='display_xxl_bold'>
                                Frequently Asked Questions
                            </h2>
                            <div className='accordion_acco_wrapper'>
                                <div className='accordion_content_accord_container'>
                                    <div className='accordion_content_accord_head accordion_content_accord_cont' onClick={toggleAccordion1}>
                                        <h3 className='accordion_content_fullWidth title_semiBold accordion_content_accord_head_label__open'>
                                            What type of property I can post on Mygoldenhomes.com for selling/renting?
                                        </h3>
                                        <i className='up-icon_Common'><BiChevronDown /></i>
                                    </div>
                                    {acordion1 &&
                                        <div className='accordion_content_accord_inner accordion_content_openAccordionContent'>
                                            <p>
                                                As an owner, agent, or builder, you can post all types of residential and commercial properties for rent, lease, or sale.
                                                On Mygoldenhomes.com,
                                                millions of people search for flats, houses, plots, office space, shops, showrooms, warehouses,
                                                commercial land, and agricultural land, among others. Mygoldebhomes.
                                                com is the best property portal to sell or rent out your property fast!
                                            </p>
                                        </div>}

                                </div>
                                <div className='accordion_content_accord_container'>
                                    <div className='accordion_content_accord_head accordion_content_accord_cont' onClick={toggleAccordion2}>
                                        <h3 className='accordion_content_fullWidth title_semiBold accordion_content_accord_head_label__open'>
                                            Is posting property for selling/renting on Mygoldenhomes.com FREE?
                                        </h3>
                                        <i className='up-icon_Common'><BiChevronDown /></i>
                                    </div>
                                    {acordion2 &&
                                        <div className='accordion_content_accord_inner accordion_content_openAccordionContent'>
                                            <p>
                                                Yes, you can post your property listing online for selling/renting on Mygoldenhomes.com for FREE.
                                                There are no charges involved. All buyer/tenant enquiries will be shared with you completely free of cost.<br />
                                                You can drop a ‘Hi’ on WhatsApp at 882 682 1122 to post easily for FREE You can also explore our 
                                                 <a href='simplepricing' style={{color: 'blue'}}> SimplePricing</a> additional services to attract even more buyers and tenants.
                                            </p>
                                        </div>
                                    }

                                </div>
                                <div className='accordion_content_accord_container'>
                                    <div className='accordion_content_accord_head accordion_content_accord_cont' onClick={toggleAccordion3}>
                                        <h3 className='accordion_content_fullWidth title_semiBold accordion_content_accord_head_label__open'>
                                            Can I sell/rent out my property on my own without paying brokerage?
                                        </h3>
                                        <i className='up-icon_Common'><BiChevronDown /></i>
                                    </div>
                                    {acordion3 &&
                                        <div className='accordion_content_accord_inner accordion_content_openAccordionContent'>
                                            <p>
                                                Yes, you can easily rent out or sell your property as an owner on Mygoldenhomes.com by posting your property ad for free.<br />
                                                Your property will be visible to thousands of buyers and tenants visiting us daily, without involving any real estate agents.
                                            </p>
                                        </div>
                                    }

                                </div>
                                <div className='accordion_content_accord_container'>
                                    <div className='accordion_content_accord_head accordion_content_accord_cont' onClick={toggleAccordion4}>
                                        <h3 className='accordion_content_fullWidth title_semiBold accordion_content_accord_head_label__open'>
                                            How can I rent/sell my property faster?
                                        </h3>
                                        <i className='up-icon_Common'><BiChevronDown /></i>
                                    </div>
                                    {acordion4 &&
                                        <div className='accordion_content_accord_inner accordion_content_openAccordionContent'>
                                            <p>
                                                Make your property listings as detailed and informative as possible to get more visibility and relevant buyers or tenants.<br />
                                                Mention all the details available such as exact location, property size, cost/rent, and things that set your property apart.​<br /><br />
                                                Please add photos to your property as they attract more buyers and tenants.​<br /><br />
                                                You can also upgrade your free property listing to a premium listing to get more visibility. With the premium listing plan, your property features at the top. Moreover,
                                                our relationship manager helps you shortlist serious buyers & tenants,
                                                plan site visits and conduct professional photoshoots & videography.
                                            </p>
                                        </div>
                                    }

                                </div>
                                <div className='accordion_content_accord_container'>
                                    <div className='accordion_content_accord_head accordion_content_accord_cont' onClick={toggleAccordion5}>
                                        <h3 className='accordion_content_fullWidth title_semiBold accordion_content_accord_head_label__open'>
                                            Will I get genuine buyers & tenants even with free property listings?
                                        </h3>
                                        <i className='up-icon_Common'><BiChevronDown /></i>
                                    </div>
                                    {acordion5 &&
                                        <div className='accordion_content_accord_inner accordion_content_openAccordionContent'>
                                            <p>
                                                Yes, you will be able to get genuine enquires even on free property listings.
                                                <span style={{ fontWeight: '600px' }}> Mygoldenhomes.com is the only platform where you get unlimited enquiries (with no cap) even on a free property ad.</span>
                                                You should add detailed information and appealing photos receive maximum enquires from prospective buyers or tenants.
                                            </p>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className='intresting-reades-ppt'>
                            <InterestingReads1 />
                        </div>
                        <div className='owners-dealers-ppt'>
                            <OwnersandDealers />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PostProperty