import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

const AddRoomDetailsTemp = (props) => {
  const { PropertyProfile, BasicDetails, singleProperty } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();

  const [noofBedrooms, setNoofBedrooms] = useState(singleProperty.noofBedrooms);
  const [addmoreBedroomsvalue, setAddmoreBedroomsValue] = useState("");
  const [addmoreBedroomsShow, setAddmoreBedroomsShow] = useState(false);

  const [noofBathrooms, setNoofBathrooms] = useState(singleProperty.noofBathrooms);
  const [addmoreBathroomsvalue, setAddmoreBathroomsValue] = useState("");
  const [addmoreBathroomsShow, setAddmoreBathroomsShow] = useState(false);

  const [balconies, setBalconies] = useState(singleProperty.balconies);

  //errors

  const [roomDetailsError, setRoomDetailsError] = useState("");

  const [BedroomsError, setBedroomsError] = useState("");
  const [BathroomsError, setBathroomsError] = useState("");
  const [BalconiesError, setBalconiesError] = useState("");

  useEffect(() => {
    if (PropertyProfile !== "") {
      setNoofBedrooms(PropertyProfile.noofBedrooms);
      setAddmoreBedroomsValue(PropertyProfile.addmoreBedroomsvalue);
      setAddmoreBedroomsShow(PropertyProfile.addmoreBedroomsShow);

      setNoofBathrooms(PropertyProfile.noofBathrooms);
      setAddmoreBathroomsValue(PropertyProfile.addmoreBathroomsvalue);
      setAddmoreBathroomsShow(PropertyProfile.addmoreBathroomsShow);

      setBalconies(PropertyProfile.balconies);
    }
  }, [PropertyProfile]);

  useEffect(() => {
    if (noofBedrooms !== "") {
      setBedroomsError("");
    }
    if (noofBathrooms !== "") {
      setBathroomsError("");
    }
    if (balconies !== "") {
      setBalconiesError("");
    }
  }, [noofBedrooms, noofBathrooms, balconies]);

  return (
    <>
      <div className="widgetbox_white_box__1v7xz undefined false false">
        <div className="widgetbox_header_text__2BXc_">
          <div className="xidHeading_PageHeadingwrap__3z0kL">
            <h4>
              <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                <div>
                  <span
                    className="xidHeading_headingText__Ulc2a"
                    style={{ display: "inline" }}
                  >
                    Add Room Details
                  </span>
                </div>
              </div>
            </h4>
          </div>
          <p style={{ color: "red", fontSize: "12px" }}>
            {props.roomDetailsError}
          </p>
        </div>
        <div className="false">
          <div
            id="bedroomNum-input"
            className="pageComponent"
            style={{ textAlign: "left" }}
          >
            <span className="labels_semiBold radioInput_bubbleLable__1g8PH">
              No. of Bedrooms
              <div className="screeningActions_iconWrapper__dB1NM"></div>
            </span>
            <div
              className="tagWrapper_tags_wrapper__KIRJJ  multiple_input"
              id="bedroomNum"
              style={{ marginTop: "8px" }}
            >
              <div
                className={
                  noofBedrooms === "1"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                id="1"
                onClick={() => {
                  setNoofBedrooms("1");
                  props.getBedRoom("1");
                  setAddmoreBedroomsValue("");
                }}
              >
                <span>1</span>
              </div>
              <div
                className={
                  noofBedrooms === "2"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                id="2"
                onClick={() => {
                  setNoofBedrooms("2");
                  props.getBedRoom("2");
                  setAddmoreBedroomsValue("");
                }}
              >
                <span>2</span>
              </div>
              <div
                className={
                  noofBedrooms === "3"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                id="3"
                onClick={() => {
                  setNoofBedrooms("3");
                  props.getBedRoom("3");
                  setAddmoreBedroomsValue("");
                }}
              >
                <span>3</span>
              </div>
              <div
                className={
                  noofBedrooms === "4"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                id="4"
                onClick={() => {
                  setNoofBedrooms("4");
                  props.getBedRoom("4");
                  setAddmoreBedroomsValue("");
                }}
              >
                <span>4</span>
              </div>
              {noofBedrooms > 4 ? (
                <>
                  <div
                    className={
                      noofBedrooms > 4
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL "
                    }
                    id="5"
                    onClick={() => {
                      setAddmoreBedroomsShow(true);
                    }}
                  >
                    <span>{noofBedrooms}</span>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div
                className="hyperlinks_medium AddOther_addOtherLink__3jZ8s"
                style={{ display: "block" }}
              >
                {addmoreBedroomsShow === false &&
                addmoreBedroomsvalue === "" ? (
                  <>
                    <i
                      className="icon_bluePlusIcon"
                      style={{ verticalAlign: "middle" }}
                    >
                      <BiPlus />
                    </i>
                    <span
                      className="AddOther_toggleLabel__YwU_k"
                      onClick={() => setAddmoreBedroomsShow(true)}
                      style={{ cursor: "pointer" }}
                    >
                      Add other
                    </span>
                  </>
                ) : (
                  <></>
                )}

                <p style={{ color: "red", fontSize: "12px" }}>
                  {props.noOfBedroomsError}
                </p>
              </div>
              {addmoreBedroomsShow === true ? (
                <>
                  {" "}
                  <div className="AddOther_inputWrap__3J562">
                    <div className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined">
                      {/* <div className="textInput_iconsWrapper__2cgSX">
                                  <div className="screeningActions_iconWrapper__dB1NM"></div>
                                </div> */}
                      <div>
                        <input
                          type="text"
                          className="pageComponenet"
                          style={{
                            width: "60%",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                          value={addmoreBedroomsvalue}
                          onChange={(e) =>
                            setAddmoreBedroomsValue(
                              e.target.value.replace(/\D/g, "")
                            )
                          }
                        />
                        <div></div>
                        <div className="textInput_placeholder__1KRsF caption_strong_large textInput_placeholderWithoutError__3FjSB">
                          <span className="undefined"></span>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="buttons_primaryRegular__2swW5 buttons_semi__32O7H undefined"
                        onClick={() => {
                          setAddmoreBedroomsShow(false);
                          setNoofBedrooms(addmoreBedroomsvalue);
                          props.getBedRoom(addmoreBedroomsvalue);
                        }}
                      >
                        <span>
                          <span>Done</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            id="bathroomNum-input"
            className="pageComponent"
            style={{ textAlign: "left" }}
          >
            <span className="labels_semiBold radioInput_bubbleLable__1g8PH">
              No. of Bathrooms
              <div className="screeningActions_iconWrapper__dB1NM"></div>
            </span>
            <div
              className="tagWrapper_tags_wrapper__KIRJJ  multiple_input"
              id="bedroomNum"
              style={{ marginTop: "8px" }}
            >
              <div
                id="1"
                className={
                  noofBathrooms === "1"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofBathrooms("1");
                  setAddmoreBathroomsValue("");
                  props.getBathroom("1");
                }}
              >
                <span>1</span>
              </div>
              <div
                className={
                  noofBathrooms === "2"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofBathrooms("2");
                  props.getBathroom("2");
                  setAddmoreBathroomsValue("");
                }}
                id="2"
              >
                <span>2</span>
              </div>
              <div
                className={
                  noofBathrooms === "3"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofBathrooms("3");
                  props.getBathroom("3");
                  setAddmoreBathroomsValue("");
                }}
                id="3"
              >
                <span>3</span>
              </div>
              <div
                className={
                  noofBathrooms === "4"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setNoofBathrooms("4");
                  props.getBathroom("4");
                  setAddmoreBathroomsValue("");
                }}
                id="4"
              >
                <span>4</span>
              </div>
              {noofBathrooms > 4 ? (
                <>
                  <div
                    className={
                      noofBathrooms > 4
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL "
                    }
                    id="5"
                    onClick={() => {
                      setAddmoreBathroomsShow(true);
                    }}
                  >
                    <span>{noofBathrooms}</span>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div
                className="hyperlinks_medium AddOther_addOtherLink__3jZ8s"
                style={{ display: "block" }}
              >
                {addmoreBathroomsShow === false &&
                addmoreBathroomsvalue === "" ? (
                  <>
                    <i
                      className="icon_bluePlusIcon"
                      style={{ verticalAlign: "middle" }}
                    >
                      <BiPlus />
                    </i>
                    <span
                      className="AddOther_toggleLabel__YwU_k"
                      onClick={() => setAddmoreBathroomsShow(true)}
                      style={{ cursor: "pointer" }}
                    >
                      Add other
                    </span>
                  </>
                ) : (
                  <></>
                )}

                <p style={{ color: "red", fontSize: "12px" }}>
                  {props.noOfBathroomsError}
                </p>
              </div>
              {addmoreBathroomsShow === true ? (
                <>
                  {" "}
                  <div className="AddOther_inputWrap__3J562">
                    <div className="textInput_inputContainer__2PwgZ  textInput_multiple_input__1JPry undefined">
                      {/* <div className="textInput_iconsWrapper__2cgSX">
                                  <div className="screeningActions_iconWrapper__dB1NM"></div>
                                </div> */}
                      <div>
                        <input
                          type="text"
                          className="pageComponenet"
                          style={{
                            width: "60%",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                          value={addmoreBathroomsvalue}
                          onChange={(e) =>
                            setAddmoreBathroomsValue(
                              e.target.value.replace(/\D/g, "")
                            )
                          }
                        />
                        <div></div>
                        <div className="textInput_placeholder__1KRsF caption_strong_large textInput_placeholderWithoutError__3FjSB">
                          <span className="undefined"></span>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="buttons_primaryRegular__2swW5 buttons_semi__32O7H undefined"
                        onClick={() => {
                          setAddmoreBathroomsShow(false);
                          setNoofBathrooms(addmoreBathroomsvalue);
                          props.getBathroom(addmoreBathroomsvalue);
                        }}
                      >
                        <span>
                          <span>Done</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            id="bedroomNum-input"
            className="pageComponent"
            style={{ textAlign: "left" }}
          >
            <span className="labels_semiBold radioInput_bubbleLable__1g8PH">
              Balconies
              <div className="screeningActions_iconWrapper__dB1NM"></div>
            </span>
            <div
              className="tagWrapper_tags_wrapper__KIRJJ  multiple_input"
              id="bedroomNum"
              style={{ marginTop: "8px" }}
            >
              <div
                className={
                  balconies === "0"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setBalconies("0");
                  props.getBalcony("0");
                }}
                id="0"
              >
                <span>0</span>
              </div>
              <div
                className={
                  balconies === "1"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setBalconies("1");
                  props.getBalcony("1");
                }}
                id="1"
              >
                <span>1</span>
              </div>
              <div
                className={
                  balconies === "2"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setBalconies("2");
                  props.getBalcony("2");
                }}
                id="2"
              >
                <span>2</span>
              </div>
              <div
                className={
                  balconies === "3"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setBalconies("3");
                  props.getBalcony("3");
                }}
                id="3"
              >
                <span>3</span>
              </div>

              <div
                className={
                  balconies === "More than 3"
                    ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                    : "pageComponent radioInput_textOnly__1r7GL "
                }
                onClick={() => {
                  setBalconies("More than 3");
                  props.getBalcony("More than 3");
                }}
                id="more"
              >
                <span>More than 3</span>
              </div>

              <p style={{ color: "red", fontSize: "12px" }}>
                {props.noOfBalconiesError}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRoomDetailsTemp;
