import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

import { Autoplay, FreeMode, Pagination } from "swiper/modules";

export default function SwiperOurClientSays() {
  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        freeMode={true}
        loop={true}
        draggable={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },

          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },

          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },

          840: {
            slidesPerView: 2,
            spaceBetween: 10,
          },

          1000: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
        modules={[FreeMode, Pagination, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="slick-item">
            <div className="text-carousel-item fl-wrap">
              <div className="text-carousel-item-header fl-wrap">
                <div className="popup-avatar">
                  <img src="./assets/images/avatar/1.jpeg" alt="" />
                </div>
                <div className="review-owner fl-wrap">Karan Rajput</div>
                <div
                  className="listing-rating card-popup-rainingvis"
                  data-starrating2={5}
                ></div>
              </div>
              <div className="text-carousel-content fl-wrap">
                <p>
                  "We are genuinely impressed with the outstanding level of
                  service your team has provided. Your dedication to
                  understanding our needs and going above and beyond to deliver
                  exceptional results is truly commendable. Working with you has
                  been a refreshing and enriching experience, and we look
                  forward to continuing this successful partnership."
                </p>
                {/* <a href="" className="testim-link color-bg">
                  Via Facebook
                </a> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slick-item">
            <div className="text-carousel-item fl-wrap">
              <div className="text-carousel-item-header fl-wrap">
                <div className="popup-avatar">
                  <img src="./assets/images/avatar/2.jpg" alt="" />
                </div>
                <div className="review-owner fl-wrap">Aashvi Agarwal</div>
                <div
                  className="listing-rating card-popup-rainingvis"
                  data-starrating2={4}
                ></div>
              </div>
              <div className="text-carousel-content fl-wrap">
                <p>
                  "Your professionalism, expertise, and commitment to excellence
                  have exceeded our expectations, and we are excited about the
                  positive impact your contributions are bringing to our
                  project. Thank you for consistently raising the bar and
                  setting new standards of quality."
                </p>
                {/* <a href="" className="testim-link color-bg">
                  Via Twitter
                </a> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slick-item">
            <div className="text-carousel-item fl-wrap">
              <div className="text-carousel-item-header fl-wrap">
                <div className="popup-avatar">
                  <img src="./assets/images/avatar/3.jpg" alt="" />
                </div>
                <div className="review-owner fl-wrap">Karan Soren</div>
                <div
                  className="listing-rating card-popup-rainingvis"
                  data-starrating2={4}
                ></div>
              </div>
              <div className="text-carousel-content fl-wrap">
                <p>
                  "We are genuinely impressed with the outstanding level of
                  service your team has provided. Your dedication to
                  understanding our needs and going above and beyond to deliver
                  exceptional results is truly commendable. Working with you has
                  been a refreshing and enriching experience, and we look
                  forward to continuing this successful partnership."
                </p>
                {/* <a href="" className="testim-link color-bg">
                  Via Facebook
                </a> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slick-item">
            <div className="text-carousel-item fl-wrap">
              <div className="text-carousel-item-header fl-wrap">
                <div className="popup-avatar">
                  <img src="./assets/images/avatar/4.jpg" alt="" />
                </div>
                <div className="review-owner fl-wrap">Aryan Khan</div>
                <div
                  className="listing-rating card-popup-rainingvis"
                  data-starrating2={5}
                ></div>
              </div>
              <div className="text-carousel-content fl-wrap">
                <p>
                  "Your professionalism, expertise, and commitment to excellence
                  have exceeded our expectations, and we are excited about the
                  positive impact your contributions are bringing to our
                  project. Thank you for consistently raising the bar and
                  setting new standards of quality."
                </p>
                {/* <a href="" className="testim-link color-bg">
                  Via Twitter
                </a> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
