import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

import { Autoplay, FreeMode } from "swiper/modules";

export default function OwnersandDealers() {
  return (
    <>
      <div className="owners-dealers">
        <div className="input_label_inactive">TESTIMONIAL</div>
        <div className="display_xxl_bold">This is what other Owners & <br /> Dealers have to say...</div>
      </div>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        freeMode={true}
        loop={true}
        draggable={true}
        pagination={{
          clickable: false,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },

          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },

          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },

          840: {
            slidesPerView: 2,
            spaceBetween: 10,
          },

          1000: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
        modules={[FreeMode, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="slick-item">
            <div className="text-carousel-item fl-wrap">
              <div className="text-carousel-item-header fl-wrap">
                <div className="popup-avatar">
                  <img src="./assets/images/avatar/1.jpeg" alt="" />
                </div>
                <div className="review-owner fl-wrap">Anuj Velankar</div>
                <div className="review-owner-place fl-wrap"><p>Owner, Gurgaon</p></div>
              </div>
              <div className="text-carousel-content fl-wrap">
                <p className="ownersanddealers-p">
                  Great efforts and regular follow-up to get leads for my rental apartment.
                  Because of your enthusiasm and dedication, various parties who saw the house were in a positive frame of mind.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slick-item">
            <div className="text-carousel-item fl-wrap">
              <div className="text-carousel-item-header fl-wrap">
                <div className="popup-avatar">
                  <img src="./assets/images/avatar/2.jpg" alt="" />
                </div>
                <div className="review-owner fl-wrap">Mohan Rao</div>
                <div className="review-owner-place fl-wrap"><p>Owner, Hyderabad</p></div>
              </div>
              <div className="text-carousel-content fl-wrap">
                <p className="ownersanddealers-p">
                  I found Relationship Managers of 99acres beneficial as they responded to my issues very promptly.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slick-item">
            <div className="text-carousel-item fl-wrap">
              <div className="text-carousel-item-header fl-wrap">
                <div className="popup-avatar">
                  <img src="./assets/images/avatar/3.jpg" alt="" />
                </div>
                <div className="review-owner fl-wrap">Ranganath RK</div>
                <div className="review-owner-place fl-wrap"><p>Owner, Bangalore</p></div>
              </div>
              <div className="text-carousel-content fl-wrap">
                <p className="ownersanddealers-p">
                  The team was constantly looking for the exact match of tenants and coordinating with both parties to get the deal fixed.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slick-item">
            <div className="text-carousel-item fl-wrap">
              <div className="text-carousel-item-header fl-wrap">
                <div className="popup-avatar">
                  <img src="./assets/images/avatar/4.jpg" alt="" />
                </div>
                <div className="review-owner fl-wrap">Mr. Danasekar</div>
                <div className="review-owner-place fl-wrap"><p>Property Agent, Chennai</p></div>
              </div>
              <div className="text-carousel-content fl-wrap">
                <p className="ownersanddealers-p">
                  Our Experience with 99acres has been very fruitful.
                  We got a lot of enquires and we also had really good conversions. We are very happy as 99acers helped us in reaching a diversified audience.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
