import React from "react";
import { RxCross1 } from "react-icons/rx";

const RecallListingsModal = (props) => {
  return (
    <>
      <div className="bg-white">
        <div
          className="ps-3"
          style={{
            background: "#1b2c43",
            display: "flex",
            justifyContent: "space-between",
            alignItems:"center",
            padding: "6px",
          }}
        >
          <h5 className="text-white recallmaintexttop">Recall your Listing</h5>
          <span
            onClick={() => props.toggle()}
            style={{ cursor: "pointer", marginRight: "10px" }}
          >
            <RxCross1 color="#fff" size={18} />
          </span>
        </div>

        <div className="mainrecallbox">
          <div className="youarespedning">
            <h4>You are spending</h4>
          </div>

          <div className="row pt-3">
            <div className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <p className="recall-text">For Posting</p>
              <p className="recall-text">For Boosting</p>
              <p className="recall-text">Tootal Spend</p>
              <p className="remaining-text">Remaining Balance:</p>
            </div>
            <div className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <p className="recall-text">1.0 Listings/ 159.0 Credits</p>
              <p className="recall-text">1.0 Listing(159.0 Credits)</p>
              <p className="recall-text">2.0 Listings(318.0 Credits)</p>
              <p className="listings">
                <span className="bistingbold">222.0 Listings </span>
                (35488.0 Credits)
              </p>
            </div>
          </div>
          <div className="row mt-2 mb-1">
            <div className="col-12">
              <h4 className="areyoutext">
                Are you sure you want to continue
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-12 align-items-end justify-content-end d-flex" style={{display:"flex",justifyContent:"flex-end",alignItems:"flex-end"}}>
              <button
                className="btn btn-default text-white buttonprevois"
                style={{ background: "#01618b", marginRight: "10px" }}
              >
                Previous
              </button>
              <button
                className="btn btn-default text-white buttonboost"
                style={{ background: "#01618b" }}
              >
                Boost & Recall
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecallListingsModal;
