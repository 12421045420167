import React from "react";
import { ChoosingSubcription } from "./skipPost/ChoosingSubcription";
import PropertyFeatures from "./skipPost/PropertyFeatures";
import { SkipToPost } from "./skipPost/SkipToPost";

const MainSkipPage = ({
  toggleClick,
  propertyPostClick,
  skipPageIndex,
  setSkipPageChange,
  setexperiMounthChange,
  setNoOfBoostingChange,
}) => {
  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return (
          <PropertyFeatures
            toggleClick={toggleClick}
            propertyPostClick={propertyPostClick}
            skipPageIndex={skipPageIndex}
            setSkipPageChange={setSkipPageChange}
          />
        );
      case 1:
        return (
          <ChoosingSubcription
            toggleClick={toggleClick}
            propertyPostClick={propertyPostClick}
            skipPageIndex={skipPageIndex}
            setSkipPageChange={setSkipPageChange}
          />
        );
      case 2:
        return (
          <SkipToPost
            toggleClick={toggleClick}
            propertyPostClick={propertyPostClick}
            skipPageIndex={skipPageIndex}
            setSkipPageChange={setSkipPageChange}
            setexperiMounthChange={setexperiMounthChange}
            setNoOfBoostingChange={setNoOfBoostingChange}
          />
        );
      default:
        return (
          <PropertyFeatures
            toggleClick={toggleClick}
            propertyPostClick={propertyPostClick}
            skipPageIndex={skipPageIndex}
            setSkipPageChange={setSkipPageChange}
          />
        );
        break;
    }
  };

  return <>{renderSwitch(skipPageIndex)}</>;
};

export default MainSkipPage;
