import React, { useEffect, useState } from "react";
import {
  BiArrowBack,
  BiInfoCircle,
  BiChevronDown,
  BiChevronUp,
  BiPlus,
} from "react-icons/bi";
import { BsQuestionCircle } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";
import { GoAlert } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  setPostPropertyStepsStatus,
  setPriceotherDetails,
} from "../../../../../redux/property/PropertySlice";
import UploadPhotos from "../../../finalPriceComponets/UploadPhotos";
import PriceDetails from "../../../finalPriceComponets/PriceDetails";
import SecurityDeposit from "../../../finalPriceComponets/SecurityDeposit";
import MinimumAgreementDuration from "../../../finalPriceComponets/MinimumAgreementDuration";
import Unique from "../../../finalPriceComponets/Unique";

const PgPriceOthers = () => {
  const { PropertyProfile, PriceotherDetails } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();
  const entryTIme = [
    { name: "7 Pm" },
    { name: "8 Pm" },
    { name: "9 Pm" },
    { name: "10 Pm" },
    { name: "11 Pm" },
    { name: "12 Pm" },
  ];

  const [expectedRent, setExpectedRent] = useState("");
  const [morePriceDetailsShow, setMorePriceDetailsShow] = useState(false);
  const [maintenance, setMaintenance] = useState("");
  const [maintenanceMonthly, setMaintenanceMonthly] = useState("Monthly");
  const [maintenanceMonthlyShow, setMaintenanceMonthlyShow] = useState("");
  const [bookingAmount, setBookingAmount] = useState("");
  const [membershipCharges, setMembershipCharges] = useState("");

  const [securityDeposit, setSecurityDeposit] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [noofMonths, setNoofMonths] = useState("");


  const [laundary, setLaundary] = useState(false);
  const [electricity, setElectricity] = useState(false);
  const [water, setWater] = useState(false);
  const [wifi, setWifi] = useState(false);
  const [houseKeeping, setHouseKeeping] = useState(false);
  const [dth, setDth] = useState(false);
  const [noneofTheabove, setNoneofTheabove] = useState(false);

  const [expectedPrice, setExpectedPrice] = useState("");
  const [pricepersqft, setPricepersqft] = useState("");
  const [inclusivePrice, setInclusivePrice] = useState(false);
  const [taxgovCharges, setTaxgovCharges] = useState(false);
  const [priceNegotiable, setPriceNegotiable] = useState(false);

  const [showKeeping, setShowKeeping] = useState(false);
  const [houseKeepingMonth, setHouseKeepingMonth] = useState("");

  const [maintenanceCharges, setMaintenanceCharges] = useState("");
  const [expectedRental, setExpectedRental] = useState("");
  const [bookingRental, setBookingRental] = useState("");
  const [annualDues, setAnnualDues] = useState("");

  const [FoodDetails, setFoodDetails] = useState(false);
  const [mealtype, setMealtype] = useState(false);

  const [lunch, setLunch] = useState(false);
  const [breakfast, setBreakfast] = useState(false);
  const [dinner, setDinner] = useState(false);

  const [lunchweekends, setLunchweekends] = useState(false);
  const [breakfastweekends, setBreakfastweekends] = useState(false);
  const [dinnerweekends, setDinnerweekends] = useState(false);

  const [foodCharges, setFoodCharges] = useState("");
  const [lunchAmount, setLunchAmount] = useState("");
  const [breakfastAmount, setBreakfastAmount] = useState("");
  const [dinnerAmount, setDinnerAmount] = useState("");
  const [fixedAmountFood, setFixedAmountFood] = useState("");

  const [maintenanceMonthlyduration, setMaintenanceMonthlyDuration] =
    useState("Months");
  const [maintenanceMonthlyDurationShow, setMaintenanceMonthlyDurationShow] =
    useState("");

  const [notiesMonths, setNoticeMonths] = useState("");

  const [earlyLeavingCharges, setEarlyLeavingCharges] = useState("");
  const [earlyFixedCharges, setEarlyFixedCharges] = useState("");
  const [earlyMonths, setEarlyMonths] = useState("");

  const [pets, setPets] = useState(false);
  const [visitors, setVisitors] = useState(false);
  const [smoking, setSmoking] = useState(false);
  const [alchol, setAlchol] = useState(false);
  const [party, setParty] = useState(false);

  const [durationAgreement, setDurationAgreement] = useState("");

  const [lastEntryTime, setLastEntryTime] = useState(false);
  const [showLastEntryTime, setShowLastEntryTime] = useState("Last Entry Time");
  const [otherRulePg, setOtherRulePg] = useState("");

  const [uniquePropertyText, setUniquePropertyText] = useState("");
  const [uniquePropertyTextUse, setUniquePropertyTextUse] = useState("");

  //========Error ===========
  const [priceDetailsError, setPriceDetailsError] = useState("");

  const [earlyLivingChargesError, setEarlyLivingChargesError] = useState("");
  const [fixedChargesError, setFixedChargesError] = useState("");
  const [MulipleChargesError, setMulipleChargesError] = useState("");
  const [uniquePropertyError, setUniquePropertyError] = useState("");

  const handleExpectedPrice = (e) => {
    const result = e;
    setExpectedRent(result);
  };
  const pricepersqftPress = (e) => {
    const result = e;
    setPricepersqft(result);
  };

  const handleElectricity = (result) => {
    setElectricity(result);
  };

  const handleInclusivePricePress = (result) => {
    setInclusivePrice(result);
  };
  const handlePricePress = (result) => {
    setPriceNegotiable(result);
  };
  const handletaxGovtPress = (result) => {
    setTaxgovCharges(result);
  };
  const handlesetUniqueTextArea = (result) => {
    setUniquePropertyText(result);
  };

  useEffect(() => {
    if (PriceotherDetails !== "") {
      setExpectedRent(PriceotherDetails.expectedRent);
      setMorePriceDetailsShow(PriceotherDetails.morePriceDetailsShow);
      setMaintenance(PriceotherDetails.maintenance);
      setMaintenanceMonthly(PriceotherDetails.maintenanceMonthly);
      setMaintenanceMonthlyShow(PriceotherDetails.maintenanceMonthlyShow);
      setBookingAmount(PriceotherDetails.bookingAmount);
      setMembershipCharges(PriceotherDetails.membershipCharges);

      setSecurityDeposit(PriceotherDetails.securityDeposit);
      setDepositAmount(PriceotherDetails.depositAmount);
      setNoofMonths(PriceotherDetails.noofMonths);

      setLaundary(PriceotherDetails.laundary);
      setElectricity(PriceotherDetails.electricity);
      setWater(PriceotherDetails.water);
      setWifi(PriceotherDetails.wifi);
      setDth(PriceotherDetails.dth);
      setNoneofTheabove(PriceotherDetails.noneofTheabove);

      setFoodDetails(PriceotherDetails.FoodDetails);
      setMealtype(PriceotherDetails.mealtype);

      setLunch(PriceotherDetails.lunch);
      setBreakfast(PriceotherDetails.breakfast);
      setDinner(PriceotherDetails.dinner);

      setLunchweekends(PriceotherDetails.lunchweekends);
      setBreakfastweekends(PriceotherDetails.breakfastweekends);
      setDinnerweekends(PriceotherDetails.dinnerweekends);

      setFoodCharges(PriceotherDetails.foodCharges);
      setLunchAmount(PriceotherDetails.lunchAmount);
      setBreakfastAmount(PriceotherDetails.breakfastAmount);
      setDinnerAmount(PriceotherDetails.dinnerAmount);
      setFixedAmountFood(PriceotherDetails.fixedAmountFood);

      setMaintenanceMonthlyDuration(
        PriceotherDetails.maintenanceMonthlyduration
      );
      setMaintenanceMonthlyDurationShow(
        PriceotherDetails.maintenanceMonthlyDurationShow
      );

      setNoticeMonths(PriceotherDetails.notiesMonths);

      setEarlyLeavingCharges(PriceotherDetails.earlyLeavingCharges);

      setEarlyFixedCharges(PriceotherDetails.earlyFixedCharges);
      setEarlyMonths(PriceotherDetails.earlyMonths);

      setPets(PriceotherDetails.pets);
      setVisitors(PriceotherDetails.visitors);
      setSmoking(PriceotherDetails.smoking);
      setAlchol(PriceotherDetails.alchol);
      setParty(PriceotherDetails.party);

      setUniquePropertyText(PriceotherDetails.uniquePropertyText);
    }
  }, [PriceotherDetails]);

  useEffect(() => {
    if (expectedRent !== "") {
      setPriceDetailsError("");
    }
    if (earlyLeavingCharges !== "") {
      setEarlyLivingChargesError("");
    }
    if (earlyFixedCharges !== "" || earlyMonths !== "") {
      setFixedChargesError("");
      setMulipleChargesError("");
    }
    if (uniquePropertyText !== "") {
      setUniquePropertyError("");
    }
  }, [
    expectedRent,
    earlyLeavingCharges,
    earlyFixedCharges,
    earlyMonths,
    uniquePropertyText,
  ]);

  const pricingOthersClick = async (e) => {
    e.preventDefault();
    setPriceDetailsError("");
    setEarlyLivingChargesError("");
    setFixedChargesError("");
    setMulipleChargesError("");
    setUniquePropertyError("");

    if (expectedRent !== "") {
      setPriceDetailsError("");
    } else {
      setPriceDetailsError("Please Enter Price Details");
    }
    if (earlyLeavingCharges !== "") {
      setEarlyLivingChargesError("");
    } else {
      setEarlyLivingChargesError("Please Select Early Living Details");
    }
    if (earlyFixedCharges !== "" || earlyMonths !== "") {
      setFixedChargesError("");
      setMulipleChargesError("");
    } else {
      setFixedChargesError("Please Enter Fixed Amount");
      setMulipleChargesError("Please Enter No .of Months");
    }
    if (uniquePropertyText !== "") {
      setUniquePropertyError("");
    } else {
      setUniquePropertyError("Please Enter Unique Property Details");
    }

    if (
      expectedRent !== "" &&
      earlyLeavingCharges !== "" &&
      uniquePropertyText !== ""
    ) {
      const priceOtherDetails = {
        expectedPrice: expectedRent,
        pricePerSqft: pricepersqft,
        morePriceDetailsShow: morePriceDetailsShow,

        allinclusiveprice: inclusivePrice,
        taxandgovtchargesexcluded: taxgovCharges,
        priceNegotiable: priceNegotiable,

        maintenance: maintenance,
        maintenanceMonthly: maintenanceMonthly,
        maintenanceMonthlyShow: maintenanceMonthlyShow,
        bookingAmount: bookingAmount,
        membershipCharges: membershipCharges,

        securityDeposit: securityDeposit,
        depositAmount: depositAmount,
        noofMonths: noofMonths,

        laundary: laundary,
        electricity: electricity,
        water: water,
        wifi: wifi,
        houseKeeping: houseKeeping,
        dth: dth,
        noneofTheabove: noneofTheabove,

        houseKeepingExtraCharge: houseKeepingMonth,

        FoodDetails: FoodDetails,
        mealtype: mealtype,

        lunch: lunch,
        breakfast: breakfast,
        dinner: dinner,

        lunchweekends: lunchweekends,
        breakfastweekends: breakfastweekends,
        dinnerweekends: dinnerweekends,

        foodCharges: foodCharges,
        lunchAmount: lunchAmount,
        breakfastAmount: breakfastAmount,
        dinnerAmount: dinnerAmount,
        fixedAmountFood: fixedAmountFood,
        durationAgreement: durationAgreement,

        maintenanceMonthlyduration: maintenanceMonthlyduration,
        maintenanceMonthlyDurationShow: maintenanceMonthlyDurationShow,

        notiesMonths: notiesMonths,

        earlyLeavingCharges: earlyLeavingCharges,
        earlyFixedCharges: earlyFixedCharges,
        earlyMonths: earlyMonths,

        pets: pets,
        visitors: visitors,
        smoking: smoking,
        alchol: alchol,
        party: party,

        showLastEntryTime: showLastEntryTime,
        otherRulePg: otherRulePg,

        uniquePropertyText: uniquePropertyText,
      };
      dispatch(setPriceotherDetails(priceOtherDetails));
      dispatch(setPostPropertyStepsStatus(5));
    }
  };

  return (
    <>
      {/* <HeaderNew /> */}
      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA">
          <div className="page_ppf_step1__1icHe  pageComponent pricing_othersinner">
            <UploadPhotos />

            <PriceDetails
              handleExpectedPrice={handleExpectedPrice}
              pricepersqftPress={pricepersqftPress}
              handleInclusivePricePress={handleInclusivePricePress}
              handleElectricity={handleElectricity}
              handlePricePress={handlePricePress}
              handletaxGovtPress={handletaxGovtPress}
              priceDetailsError={priceDetailsError}
              morePricingDetails={setMaintenance}
              setMaintenanceMonthly={setMaintenanceMonthly}
              setBookingAmount={setBookingAmount}
            />

            <SecurityDeposit
              getSecurityDeposite={setSecurityDeposit}
              setDepositAmount={setDepositAmount}
              setNoofMonths={setNoofMonths}
            />

            <div className="Ownership-widget" style={{ marginTop: "10px" }}>
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div
                  className="widgetbox_header_text__2BXc_"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Total Price Includes
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="false">
                <div
                  className="pagecomponent"
                  id="ownership-input"
                  style={{ textAlign: "left" }}
                >
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        laundary === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setLaundary(!laundary)}
                      id="1"
                    >
                      {laundary === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Laundary</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        electricity === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setElectricity(!electricity)}
                      id="2"
                    >
                      {electricity === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Electricity</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        water === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setWater(!water)}
                      id="3"
                    >
                      {water === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Water</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        wifi === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setWifi(!wifi)}
                      id="4"
                    >
                      {wifi === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>Wifi</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        houseKeeping === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setHouseKeeping(!houseKeeping)}
                      id="5"
                    >
                      {houseKeeping === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>HouseKeeping</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        dth === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setDth(!dth)}
                      id="6"
                    >
                      {dth === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>DTH</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        noneofTheabove === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setNoneofTheabove(!noneofTheabove)}
                      id="7"
                    >
                      {noneofTheabove === true ? (
                        <>
                          {" "}
                          <i className="iconS_Common_20 icon_blueRightTick">
                            <img src="./assets/images/all/tick.png" alt="" />
                          </i>
                        </>
                      ) : (
                        <>
                          <i className="iconS_PPF_20 icon_plusIcon">
                            <BiPlus />
                          </i>
                        </>
                      )}
                      <span>None Of The Above</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="CommercialR2MFacilities-widget Preference-widget Preference_widget_11">
              <div className="xidHeading_PageHeadingwrap__3z0kL">
                <h5>
                  <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                    <div>
                      <span
                        className="xidHeading_headingText__Ulc2a"
                        style={{ display: "inline" }}
                      >
                        Services Excluding Price
                      </span>
                    </div>
                  </div>
                </h5>
              </div>
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <div>
                          <span
                            className="xidHeading_headingText__Ulc2a"
                            style={{ display: "inline" }}
                          >
                            House Keeping
                          </span>
                        </div>
                      </div>
                    </h4>
                  </div>
                </div>
                <div className="false">
                  <div
                    className="pageComponent facilities__available"
                    style={{ textAlign: "left" }}
                  >
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className="radioInput_inline_radio__qveVO radioInput_marginBtm__VxOrF "
                        id="p"
                      >
                        <div style={{ display: "flex" }}>
                          <div className="pageComponent" data-label="VALUE">
                            <div onClick={() => setShowKeeping(false)}>
                              <input
                                type="radio"
                                id="flex1"
                                name="available"
                                defaultChecked
                              />
                              <label htmlFor="flex1">Not Available</label>
                            </div>
                          </div>
                          <div className="pageComponent" data-label="VALUE">
                            <div onClick={() => setShowKeeping(true)}>
                              <input type="radio" id="flex2" name="available" />
                              <label htmlFor="flex2">
                                Available for Extra Charge
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {showKeeping ? (
                      <>
                        <div className="false">
                          <div id="DepositValue-input">
                            <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                              <div className="false">
                                <input
                                  type="text"
                                  placeholder="Enter Number of Months"
                                  value={houseKeepingMonth}
                                  onChange={(e) =>
                                    setHouseKeepingMonth(e.target.value)
                                  }
                                />
                                <div className="numberInput_iconsWrapper__3Kpm5">
                                  <i></i>
                                  <div className="screeningActions_iconWrapper__dB1NM"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="Ownership-widget" style={{ marginTop: "10px" }}>
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div
                  className="widgetbox_header_text__2BXc_"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Food Detailes
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="false">
                <div
                  className="pagecomponent"
                  id="ownership-input"
                  style={{ textAlign: "left" }}
                >
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        FoodDetails === true
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setFoodDetails(true)}
                      id="1"
                    >
                      <span>Available</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        FoodDetails === false
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setFoodDetails(false)}
                      id="2"
                    >
                      <span>Not Available</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {FoodDetails === true ? (
              <>
                <div className="Ownership-widget" style={{ marginTop: "10px" }}>
                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div
                      className="widgetbox_header_text__2BXc_"
                      style={{ marginBottom: "-20px" }}
                    >
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h5>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <span
                              className="xidHeading_headingText__Ulc2a"
                              style={{ display: "inline" }}
                            >
                              Meal Type
                            </span>
                            <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                              (Optional)
                            </em>
                          </div>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="false">
                    <div
                      className="pagecomponent"
                      id="ownership-input"
                      style={{ textAlign: "left" }}
                    >
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            mealtype === true
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() => setMealtype(true)}
                          id="1"
                        >
                          <span>Only Veg</span>
                        </div>
                      </div>
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            mealtype === false
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() => setMealtype(false)}
                          id="2"
                        >
                          <span>Veg & Non Veg</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Ownership-widget" style={{ marginTop: "10px" }}>
                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div
                      className="widgetbox_header_text__2BXc_"
                      style={{ marginBottom: "-20px" }}
                    >
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <span
                              className="xidHeading_headingText__Ulc2a"
                              style={{ display: "inline" }}
                            >
                              Avalibility of meal of weekdays{" "}
                            </span>
                            <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                              (Optional)
                            </em>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="false">
                    <div
                      className="pagecomponent"
                      id="ownership-input"
                      style={{ textAlign: "left" }}
                    >
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            breakfast === true
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() => setBreakfast(!breakfast)}
                          id="1"
                        >
                          {breakfast === true ? (
                            <>
                              {" "}
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Breakfast</span>
                        </div>
                      </div>
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            lunch === true
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() => setLunch(!lunch)}
                          id="2"
                        >
                          {lunch === true ? (
                            <>
                              {" "}
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Lunch</span>
                        </div>
                      </div>
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            dinner === true
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() => setDinner(!dinner)}
                          id="3"
                        >
                          {dinner === true ? (
                            <>
                              {" "}
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Dinner</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Ownership-widget" style={{ marginTop: "10px" }}>
                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div
                      className="widgetbox_header_text__2BXc_"
                      style={{ marginBottom: "-20px" }}
                    >
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <span
                              className="xidHeading_headingText__Ulc2a"
                              style={{ display: "inline" }}
                            >
                              Avalible of Meal on Weekends
                            </span>
                            <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                              (Optional)
                            </em>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="false">
                    <div
                      className="pagecomponent"
                      id="ownership-input"
                      style={{ textAlign: "left" }}
                    >
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            breakfastweekends === true
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() =>
                            setBreakfastweekends(!breakfastweekends)
                          }
                          id="1"
                        >
                          {breakfastweekends === true ? (
                            <>
                              {" "}
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Break Fast</span>
                        </div>
                      </div>
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            lunchweekends === true
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() => setLunchweekends(!lunchweekends)}
                          id="2"
                        >
                          {lunchweekends === true ? (
                            <>
                              {" "}
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Lunch</span>
                        </div>
                      </div>
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            dinnerweekends === true
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() => setDinnerweekends(!dinnerweekends)}
                          id="3"
                        >
                          {dinnerweekends === true ? (
                            <>
                              {" "}
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Dinner</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Ownership-widget" style={{ marginTop: "10px" }}>
                  <div className="widgetbox_white_box__1v7xz undefined false false">
                    <div
                      className="widgetbox_header_text__2BXc_"
                      style={{ marginBottom: "-20px" }}
                    >
                      <div className="xidHeading_PageHeadingwrap__3z0kL">
                        <h4>
                          <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                            <span
                              className="xidHeading_headingText__Ulc2a"
                              style={{ display: "inline" }}
                            >
                              Charges For Food{" "}
                            </span>
                            <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                              (Optional)
                            </em>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="false">
                    <div
                      className="pagecomponent"
                      id="ownership-input"
                      style={{ textAlign: "left" }}
                    >
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            foodCharges === "Include"
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() => setFoodCharges("Include")}
                          id="2"
                        >
                          {foodCharges === "Include" ? (
                            <>
                              {" "}
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Include Rent</span>
                        </div>
                      </div>
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            foodCharges === "For meal Base"
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() => setFoodCharges("For meal Base")}
                          id="3"
                        >
                          {foodCharges === "For meal Base" ? (
                            <>
                              {" "}
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>For meal Base</span>
                        </div>
                      </div>
                      <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                        <div
                          className={
                            foodCharges === "Fixed Monthly Based"
                              ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                              : "pageComponent radioInput_textOnly__1r7GL"
                          }
                          onClick={() => setFoodCharges("Fixed Monthly Based")}
                          id="4"
                        >
                          {foodCharges === "Fixed Monthly Based" ? (
                            <>
                              {" "}
                              <i className="iconS_Common_20 icon_blueRightTick">
                                <img
                                  src="./assets/images/all/tick.png"
                                  alt=""
                                />
                              </i>
                            </>
                          ) : (
                            <>
                              <i className="iconS_PPF_20 icon_plusIcon">
                                <BiPlus />
                              </i>
                            </>
                          )}
                          <span>Fixed Monthly Based</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {foodCharges === "For meal Base" ? (
                    <>
                      <div className="false">
                        <div id="DepositValue-input">
                          <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                            <div className="false">
                              <input
                                type="text"
                                value={breakfastAmount}
                                onChange={(e) =>
                                  setBreakfastAmount(
                                    e.target.value.replace(/\D/g, "")
                                  )
                                }
                                placeholder="BreakFast"
                              />
                              <div className="numberInput_iconsWrapper__3Kpm5">
                                <i></i>
                                <div className="screeningActions_iconWrapper__dB1NM"></div>
                              </div>
                            </div>
                            <div className="false">
                              <input
                                type="text"
                                value={lunchAmount}
                                onChange={(e) =>
                                  setLunchAmount(
                                    e.target.value.replace(/\D/g, "")
                                  )
                                }
                                placeholder="Lunch"
                              />
                              <div className="numberInput_iconsWrapper__3Kpm5">
                                <i></i>
                                <div className="screeningActions_iconWrapper__dB1NM"></div>
                              </div>
                            </div>
                            <div className="false">
                              <input
                                type="text"
                                value={dinnerAmount}
                                onChange={(e) =>
                                  setDinnerAmount(
                                    e.target.value.replace(/\D/g, "")
                                  )
                                }
                                placeholder="Dinner"
                              />
                              <div className="numberInput_iconsWrapper__3Kpm5">
                                <i></i>
                                <div className="screeningActions_iconWrapper__dB1NM"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {foodCharges === "Fixed Monthly Based" ? (
                    <>
                      <div className="false">
                        <div id="DepositValue-input">
                          <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                            <div className="false">
                              <input
                                type="text"
                                value={fixedAmountFood}
                                onChange={(e) =>
                                  setFixedAmountFood(
                                    e.target.value.replace(/\D/g, "")
                                  )
                                }
                                placeholder="Enter food Charges"
                              />
                              <div className="numberInput_iconsWrapper__3Kpm5">
                                <i></i>
                                <div className="screeningActions_iconWrapper__dB1NM"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}

            <MinimumAgreementDuration
              getDurationAgreement={setDurationAgreement}
            />

            <div className="Ownership-widget" style={{ marginTop: "10px" }}>
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div
                  className="widgetbox_header_text__2BXc_"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Early Leaving Charges
                        </span>
                      </div>
                    </h4>
                  </div>
                  <p style={{ color: "red" }}>{earlyLivingChargesError}</p>
                </div>
              </div>
              <div className="false">
                <div
                  className="pagecomponent"
                  id="ownership-input"
                  style={{ textAlign: "left" }}
                >
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        earlyLeavingCharges === "None"
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => {
                        setEarlyLeavingCharges("None");
                        setEarlyMonths("");
                        setEarlyFixedCharges("");
                      }}
                      id="1"
                    >
                      <span>None</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        earlyLeavingCharges === "Fixed"
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => {
                        setEarlyLeavingCharges("Fixed");
                      }}
                      id="2"
                    >
                      <span>Fixed</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        earlyLeavingCharges === "Multiple Of Rent"
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => setEarlyLeavingCharges("Multiple Of Rent")}
                      id="3"
                    >
                      <span>Multiple Of Rent</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* for fixed */}
              {earlyLeavingCharges === "Fixed" ? (
                <>
                  <div className="false">
                    <p style={{ color: "red" }}>{fixedChargesError}</p>
                    <div id="DepositValue-input">
                      <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                        <div className="false">
                          <input
                            type="text"
                            value={earlyFixedCharges}
                            onChange={(e) => {
                              setEarlyFixedCharges(
                                e.target.value.replace(/\D/g, "")
                              );
                              setEarlyMonths("");
                            }}
                            placeholder="Enter Fixed Amount"
                          />
                          <div className="numberInput_iconsWrapper__3Kpm5">
                            <i></i>
                            <div className="screeningActions_iconWrapper__dB1NM"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* for Multiple of Months */}
              {earlyLeavingCharges === "Multiple Of Rent" ? (
                <>
                  <p style={{ color: "red" }}>{MulipleChargesError}</p>
                  <div className="false">
                    <div id="DepositValue-input">
                      <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                        <div className="false">
                          <input
                            type="text"
                            value={earlyMonths}
                            onChange={(e) => {
                              setEarlyMonths(e.target.value.replace(/\D/g, ""));
                              setEarlyFixedCharges("");
                            }}
                            placeholder="Enter No of Months"
                          />
                          <div className="numberInput_iconsWrapper__3Kpm5">
                            <i></i>
                            <div className="screeningActions_iconWrapper__dB1NM"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            <div id="CommercialR2MFacilities-widget Preference-widget Preference_widget_11">
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div className="widgetbox_header_text__2BXc_">
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <div>
                          <span
                            className="xidHeading_headingText__Ulc2a"
                            style={{ display: "inline" }}
                          >
                            Some House Rules
                          </span>
                          <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                            (Optional)
                          </em>
                        </div>
                      </div>
                    </h4>
                  </div>
                </div>
                <div className="false">
                  <div
                    className="pageComponent facilities__available"
                    style={{ textAlign: "left" }}
                  >
                    <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                      <div
                        className="radioInput_inline_radio__qveVO radioInput_marginBtm__VxOrF "
                        id="p"
                      >
                        <div className="body_med margin_radio">
                          Pets Allowed
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="pageComponent" data-label="VALUE">
                            <div>
                              <input
                                type="radio"
                                checked={pets === true}
                                onChange={() => setPets(!pets)}
                                style={{ cursor: "pointer" }}
                                aria-label="Available"
                              />
                              <label>Yes</label>
                            </div>
                          </div>
                          <div className="pageComponent" data-label="VALUE">
                            <div>
                              <input
                                type="radio"
                                checked={pets === false}
                                onChange={() => setPets(!pets)}
                                style={{ cursor: "pointer" }}
                                aria-label="Not Available"
                              />
                              <label>No</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="radioInput_inline_radio__qveVO radioInput_marginBtm__VxOrF "
                        id="q"
                      >
                        <div className="body_med margin_radio">
                          Visitors Allowed
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="pageComponent" data-label="VALUE">
                            <div>
                              <input
                                type="radio"
                                checked={visitors === true}
                                onChange={() => setVisitors(!visitors)}
                                style={{ cursor: "pointer" }}
                                aria-label="Available"
                              />
                              <label>Yes</label>
                            </div>
                          </div>
                          <div className="pageComponent" data-label="VALUE">
                            <div>
                              <input
                                type="radio"
                                checked={visitors === false}
                                onChange={() => setVisitors(!visitors)}
                                style={{ cursor: "pointer" }}
                                aria-label="Not Available"
                              />
                              <label>No</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="radioInput_inline_radio__qveVO radioInput_marginBtm__VxOrF "
                        id="r"
                      >
                        <div className="body_med margin_radio">
                          Smoking Allowed
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="pageComponent" data-label="VALUE">
                            <div>
                              <input
                                type="radio"
                                checked={smoking === true}
                                onChange={() => setSmoking(!smoking)}
                                style={{ cursor: "pointer" }}
                                aria-label="Available"
                              />
                              <label>Yes</label>
                            </div>
                          </div>
                          <div className="pageComponent" data-label="VALUE">
                            <div>
                              <input
                                type="radio"
                                checked={smoking === false}
                                onChange={() => setSmoking(!smoking)}
                                style={{ cursor: "pointer" }}
                                aria-label="Not Available"
                              />
                              <label>No</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="radioInput_inline_radio__qveVO radioInput_marginBtm__VxOrF "
                        id="s"
                      >
                        <div className="body_med margin_radio">
                          Alchol Allowed
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="pageComponent" data-label="VALUE">
                            <div>
                              <input
                                type="radio"
                                checked={alchol === true}
                                onChange={() => setAlchol(!alchol)}
                                style={{ cursor: "pointer" }}
                                aria-label="Available"
                              />
                              <label>Yes</label>
                            </div>
                          </div>
                          <div className="pageComponent" data-label="VALUE">
                            <div>
                              <input
                                type="radio"
                                checked={alchol === false}
                                onChange={() => setAlchol(!alchol)}
                                style={{ cursor: "pointer" }}
                                aria-label="Not Available"
                              />
                              <label>No</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="radioInput_inline_radio__qveVO radioInput_marginBtm__VxOrF "
                        id="t"
                      >
                        <div className="body_med margin_radio">
                          Party/Event Allowed
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="pageComponent" data-label="VALUE">
                            <div>
                              <input
                                type="radio"
                                checked={party === true}
                                onChange={() => setParty(!party)}
                                style={{ cursor: "pointer" }}
                                aria-label="Available"
                              />
                              <label>Yes</label>
                            </div>
                          </div>
                          <div className="pageComponent" data-label="VALUE">
                            <div>
                              <input
                                type="radio"
                                checked={party === false}
                                onChange={() => setParty(!party)}
                                style={{ cursor: "pointer" }}
                                aria-label="Not Available"
                              />
                              <label>No</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="LockIn-widget" style={{ marginTop: "10px" }}>
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div
                  className="widgetbox_header_text__2BXc_"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Last Entry Time
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="false">
                <div id="floorNum-input">
                  <div>
                    <div
                      id="maintenanceCriteria-input"
                      className="pageComponent"
                    >
                      <div>
                        <div
                          className="selectInput_custom_input_wrapper__MqLE9 selectInput_multiple_input__1oijd  pageComponent"
                          onClick={() => {
                            setLastEntryTime(!lastEntryTime);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                          <div
                            className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf"
                            style={{ border: "none" }}
                          >
                            {showLastEntryTime}
                          </div>
                          <div className="selectInput_iconsWrapper__1L2kR">
                            <span className="icon_downArrow">
                              <img
                                src="/assets/images/all/arrow_down.png"
                                alt=""
                              />
                            </span>
                            <div className="screeningActions_iconWrapper__dB1NM"></div>
                          </div>
                        </div>
                        {lastEntryTime === true ? (
                          <>
                            <div className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown">
                              {entryTIme.map((mentaina, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    setShowLastEntryTime(mentaina.name);
                                    setLastEntryTime(false);
                                  }}
                                >
                                  <span>{mentaina.name}</span>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Yearly-widget" style={{ marginTop: "10px" }}>
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div
                  className="widgetbox_header_text__2BXc_"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Have any Other Rule ?
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="false">
                <div id="DepositValue-input">
                  <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                    <div className="false">
                      <input
                        type="text"
                        placeholder="Type Any other rule and guests should follow "
                        value={otherRulePg}
                        onChange={(e) => setOtherRulePg(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="Description-widget Pricing-widget">
              <div
                className="widgetbox_white_box__1v7xz undefined undefined undefined"
                style={{ textAlign: "left" }}
              >
                <Unique
                  handlesetUniqueTextArea={handlesetUniqueTextArea}
                  expectedRent={expectedRent}
                  priceNegotiable={priceNegotiable}
                  propertyUniqueError={uniquePropertyError}
                />
                <div
                  className="PhotoDetailsButtonsWrapper-widget"
                  style={{ textAlign: "left", marginTop: "20px" }}
                >
                  <div id="PhotoDetailsButton-button" className="pageComponent">
                    <div>
                      <div className="buttonInput_continue_btn__1gb5M ">
                        <button
                          type="button"
                          onClick={(e) => pricingOthersClick(e)}
                          id="PhotoDetailsButton"
                          className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                        >
                          <span>
                            <span>Continue</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="rightSection"
          className="rightSection_rightSection__36zjv"
          style={{ minHeight: "calc(100vh - 54px)" }}
        >
          <div className="needHelp_helpBox__1b_kG" id="helpBox">
            <div className="needHelp_needHelpWrap__1eIlT">
              <div className="needHelp_helpHead__2mtNV">
                <i className="iconS_PPFDesk_16 icon_callIcon">
                  <IoMdCall />
                </i>
                <div className="caption_subdued_large">Need help ? </div>
              </div>
              <div className="caption_subdued_medium">
                You can email us at
                <a className="hyperlinks_small"> service@mygoldenhomes.com </a>
                or call us at
                <a className="hyperlinks_small"> 1800 41 99099</a>
                (IND Toll-Free).
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PgPriceOthers;
