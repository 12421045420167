import React from 'react'

const CreditUsageHistory = () => {
  return (
    <>
      <div className='modifyProfile__cms_mainContainer'>
        <div style={{ margin: "5%" }}></div>
        <div className='modifyProfile__filterWrap'>
          <div className='modifyProfile__calenderSection'>
            <div style={{ display: "block" }}>
              <span style={{ color: "rgb(153, 153, 153)" }}>START DATE:</span>
              <div className='modifyProfile__date'>
                <input type="date" name='form' value={"2023-08-29"} />
              </div>
            </div>
            <div style={{ display: "block" }}>
              <span style={{ color: "rgb(153, 153, 153)" }}>END DATE:</span>
              <div className='modifyProfile__date'>
                <input type="date" name='to' value={"2023-09-29"} />
              </div>
            </div>
            <div className='undefined undefined'>
              <div className='modifyProfile__cms_history' style={{ backgroundColor: "rgb(174, 214, 240);", pointerEvents: "none" }}>
                Show History
              </div>
            </div>
          </div>
        </div>
        <div className=''>
          <h1 className='modifyProfile__cms_mainHeading'>Credit Usage Summary</h1>
        </div>
        <div>
        <div className='modifyProfile__cms_summary'>
          <ul>
            <li className='modifyProfile__cms_heading list-item_1'>
              <div>Date</div>
              <div className='modifyProfile__cms_heading modifyProfile__cms_content'>29/8/2023-29/9/2023</div>
            </li>
            <li className='modifyProfile__cms_heading'>
              <div>Starting Credits</div>
              <div className='modifyProfile__cms_heading modifyProfile__cms_content'>0</div>
            </li>
            <li className='modifyProfile__cms_heading'>
              <div>Added Credits</div>
              <div className='modifyProfile__cms_heading modifyProfile__cms_content' style={{color: "rgb(39, 174, 96)", paddingLeft:"26px"}}>+0</div>
            </li>
            <li className='modifyProfile__cms_heading'>
              <div>Consumed Credits</div>
              <div className='modifyProfile__cms_heading modifyProfile__cms_content' style={{color: "rgb(247, 33, 59)", paddingLeft:"26px"}}>-0</div>
            </li>
          </ul>
        </div>
        <div className='modifyProfile__cms_credits'>
          <ul>
            <li className='modifyProfile__cms_c_heading'>Balance Credits</li>
          </ul>
          <ul>
            <li className='modifyProfile__cms_c_heading modifyProfile__cms_c_content'>0</li>
          </ul>
        </div>
      </div>
        <h2 className='modifyProfile__cms_mainHeading'>Transaction History</h2>
      </div>
    </>
  )
}

export default CreditUsageHistory