import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { BsStar } from 'react-icons/bs'
import { Link } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode } from 'swiper/modules';

const ViewedProjects = () => {
    return (
        <>
            <div className="viewed-projects">
                <div className='viewed-projects-head'>
                    <h1>Viewed Properties</h1>
                    <p>Contact now to close the deal</p>
                </div>
                <div className="viewed-projects-swiper">
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={130}
                        freeMode={true}
                        pagination={{
                            clickable: false,
                        }}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            480: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },

                            640: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            840: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            1000: {
                                slidesPerView: 4,
                                spaceBetween: 130,
                            },
                        }}
                        modules={[FreeMode]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="viewed-projects-swiper-card">
                                    <div className="viewed-projects-swiper-card-text-top">
                                        <div className='viewed-projects-swiper-card-text-top-in'>
                                            <div className='viewed-projects-top-in-img'>
                                                <img src="/assets/images/sliderImg/img011.jpg" alt="" />
                                                <div className='viewed-projects-card-img-tags'>
                                                    <div className='viewed-projects-card-img-tags-holder'>
                                                        <div className='img-tick-icon'>
                                                            <div className='img-tick-icon-star'><i><BsStar /></i></div>
                                                            <div className='img-tick-icon-text'>₹ 1.05 Cr</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='viewed-projects-swiper-card-text-top-in1'>
                                            <div>
                                                <a>Aliens HUB</a>
                                                <p>Residential Land in Srisailam Highway, Hyderabad</p>
                                                <div>₹ 36 Lac to 1.44 Crore</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div classname="slick-slide-item">
                                <div className="viewed-projects-swiper-card">
                                    <div className="viewed-projects-swiper-card-text-top">
                                        <div className='viewed-projects-swiper-card-text-top-in'>
                                            <div className='viewed-projects-top-in-img'>
                                                <img src="/assets/images/sliderImg/img022.jpg" alt="" />
                                                <div className='viewed-projects-card-img-tags'>
                                                    <div className='viewed-projects-card-img-tags-holder'>
                                                        <div className='img-tick-icon'>
                                                            <div className='img-tick-icon-text'>₹ 90 L</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='viewed-projects-swiper-card-text-top-in1'>
                                            <div>
                                                <a>Aliens HUB</a>
                                                <p>Residential Land in Srisailam Highway, Hyderabad</p>
                                                <div>₹ 36 Lac to 1.44 Crore</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default ViewedProjects