import React from "react";
import { useSelector } from "react-redux";
import PgPriceOthers from "./componenets/residential/Pg/PgPriceOthers";
import RentPricePage from "./componenets/residential/rentandlease/RentPricePage";
import PricingandOthers from "./componenets/PricingandOthers";
import SellRetailPricingDetails from "./componenets/commericial/sell/retail/SellRetailPricingDetails";
import PlotLandPriceSell from "./componenets/commericial/sell/plotland/PlotLandPriceSell";
import StoragePricingSell from "./componenets/commericial/sell/storage/StoragePricingSell";
import IndustryPricingSell from "./componenets/commericial/sell/industry/IndustryPricingSell";
import HospitalPricingsell from "./componenets/commericial/sell/hospitality/HospitalPricingsell";
import RetailPricingDetails from "./componenets/commericial/rentlease/retail/RetailPricingDetails";
import PlotLandPricingDetails from "./componenets/commericial/rentlease/plotandland/PlotLandPricingDetails";
import StoragePricingDetails from "./componenets/commericial/rentlease/storage/StoragePricingDetails";
import IndustryPricingDetails from "./componenets/commericial/rentlease/industry/IndustryPricingDetails";
import HospitalityPricingDetails from "./componenets/commericial/rentlease/hospitality/HospitalityPricingDetails";
import OfficePricingDetailsSell from "./componenets/commericial/sell/Office/OfficePricingDetailsSell";
import OfficePriceRentAndLease from "./componenets/commericial/rentlease/office/OfficePriceRentAndLease";

const MainPriceOthers = () => {
  const { BasicDetails } = useSelector((state) => state.Property);
  return (
    <>
      {BasicDetails.supercatName === "Residential" ? (
        <>
          {BasicDetails.catName === "Pg" ? (
            <>
              <PgPriceOthers />
            </>
          ) : (
            <>
              {BasicDetails.catName === "Rent" ||
              BasicDetails.catName === "Lease" ? (
                <>
                  <RentPricePage />
                </>
              ) : (
                <>
                  <PricingandOthers />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {BasicDetails.catName === "Sell" ? (
            <>
              {BasicDetails.subcatName === "Office" ? (
                <>
                  <OfficePricingDetailsSell />
                </>
              ) : (
                <>
                  {BasicDetails.subcatName === "Retail" ? (
                    <>
                      <SellRetailPricingDetails />
                    </>
                  ) : (
                    <>
                      {BasicDetails.subcatName === "Plot" ||
                      BasicDetails.subcatName === "Land" ? (
                        <>
                          <PlotLandPriceSell />
                        </>
                      ) : (
                        <>
                          {BasicDetails.subcatName === "Storage" ? (
                            <>
                              <StoragePricingSell />
                            </>
                          ) : (
                            <>
                              {BasicDetails.subcatName === "Industry" ? (
                                <>
                                  <IndustryPricingSell />
                                </>
                              ) : (
                                <>
                                  {BasicDetails.subcatName === "Hospitality" ? (
                                    <>
                                      <HospitalPricingsell />
                                    </>
                                  ) : (
                                    <>
                                      <HospitalPricingsell />
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {BasicDetails.subcatName === "Office" ? (
                <>
                  <OfficePriceRentAndLease />
                </>
              ) : (
                <>
                  {BasicDetails.subcatName === "Retail" ? (
                    <>
                      <RetailPricingDetails />
                    </>
                  ) : (
                    <>
                      {BasicDetails.subcatName === "Plot" ||
                      BasicDetails.subcatName === "Land" ? (
                        <>
                          <PlotLandPricingDetails />
                        </>
                      ) : (
                        <>
                          {BasicDetails.subcatName === "Storage" ? (
                            <>
                              <StoragePricingDetails />
                            </>
                          ) : (
                            <>
                              {BasicDetails.subcatName === "Industry" ? (
                                <>
                                  <IndustryPricingDetails />
                                </>
                              ) : (
                                <>
                                  {BasicDetails.subcatName === "Hospitality" ? (
                                    <>
                                      <HospitalityPricingDetails />
                                    </>
                                  ) : (
                                    <>
                                      <HospitalityPricingDetails />
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default MainPriceOthers;
