import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  superCatTotal: [],

  isLoading: true,
  supercatLoading: true,
};

export const getSuperCategory = createAsyncThunk(
  "SuperCategory/getSuperCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/supercategory/all`;
      const resp = await axios(url);
      return resp.data.supercategories;
    } catch (error) {
      return thunkAPI.rejectWithValue("supercategories Not Found");
    }
  }
);

const SuperCategorySlice = createSlice({
  name: "superCategory",
  initialState,
  extraReducers: {
    [getSuperCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [getSuperCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.supercatLoading = false;
      state.superCatTotal = action.payload;
    },
    [getSuperCategory.rejected]: (state, action) => {
      state.isLoading = true;
    },
  },
});

export default SuperCategorySlice.reducer;
