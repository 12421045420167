import React, { useState } from "react";
import { LuCheck } from "react-icons/lu";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";

function BoostListing({ boostToggle, setChangeIndexValue }) {
  const [selectoption, setSelectoption] = useState(false);
  const { loginData } = useSelector((store) => store.Athentication);

  return (
    <>
      <div className="boost-listing">
        <div className="boost-header">
          <div style={{ fontSize: "12px" }}>Boost Your Listing</div>
          <button onClick={() => boostToggle()} style={{ cursor: "pointer" }}>
            <RxCross2 />
          </button>
        </div>
        <div className="boost-body">
          <div style={{ fontSize: "14px", fontWeight: "600", color: "#555" }}>
            Choose subscription:
          </div>
          <div
            className="boost-body-subscription"
            onClick={() => setSelectoption(!selectoption)}
            style={{ cursor: "pointer" }}
          >
            <div className="boost-body-subscription-top">
              <div className="boost-body-subscription-toppart">
                <div
                  className={selectoption === true ? "checkmark" : "checkmark1"}
                ></div>
                <div>
                  <div style={{ fontSize: "12px", padding: "15px" }}>
                    Hyderabad
                  </div>
                </div>
              </div>
              <div
                className="boost-body-subscription-text pb-2"
                style={
                  selectoption === true
                    ? { color: "#5ad2b6" }
                    : { color: "gray" }
                }
              >
                <div style={{ fontSize: "16px" }}>{loginData.PostCreadits}</div>
                <div>{loginData.packageType} Listings</div>
                {/* <div style={{ fontSize: "12px" }}>(30519 credits)</div> */}
              </div>
            </div>
            <div
              style={{ borderTop: "1px solid #7acab5" }}
              className={
                selectoption === true
                  ? "boost-body-subscription-text"
                  : "boost-body-subscription-text1"
              }
            >
              Valid till now{" "}
              {moment(loginData.PostCreaditsExpire).format("Do MMM YY")}
            </div>
          </div>
          <div className="boost-body-footer-text">
            Note: Listing Packs purchased from 8th April 2018 onwards support
            resale/ renting listing types only.
            {selectoption === true ? (
              <>
                <button
                  onClick={() => setChangeIndexValue(1)}
                  style={{ cursor: "pointer" }}
                >
                  Next
                </button>
              </>
            ) : (
              <>
                <button
                  style={{ cursor: "not-allowed" }}
                >
                  Next
                </button>
              </>
            )}
          </div>
          <div className="check-mark">
            <LuCheck />
          </div>
        </div>
      </div>
    </>
  );
}

export default BoostListing;
