import React, { useEffect, useId, useState } from "react";
import Login from "../Logins/Login";
import Registration from "../Logins/Registration";
import { Link } from "react-router-dom";
import {
  setModelShow,
  authActions,
} from "../../redux/athentication/Athentication";
import { useDispatch, useSelector } from "react-redux";
import { MdHeadsetMic } from "react-icons/md";
import { MdCall } from "react-icons/md";
import { MdPhoneCallback } from "react-icons/md";
import {
  pageStatusChange,
  setuserIdRemove,
} from "../../redux/property/PropertySlice";
import { useNavigate } from "react-router-dom/dist";
import { IoSearchSharp } from "react-icons/io5";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { setsearchObjects } from "../../redux/search/SearchSlice";

const Header = () => {
  const { modelShow } = useSelector((store) => store.Athentication);
  const { loginData, isAuth } = useSelector((store) => store.Athentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { catTotal } = useSelector((store) => store.Category);

  const [buychange, setBuychange] = useState(true);
  const [rentchange, setRentchange] = useState(false);

  const [menubar, setMenubar] = useState(false);

  const [supercat, setSupercat] = useState("Residential");
  const [cat, setCat] = useState("Buy");

  const [propertyId, setPropertyId] = useState("");
  const [searchError, setSearchError] = useState("");

  const [propertySearch, setPropertySearch] = useState([]);
  const [showresults, setShowresults] = useState(false);
  const [propertySearchProject, setPropertySearchProject] = useState([]);

  const InputClick = () => {
    const PropertyId = propertyId;
    if (PropertyId === "") {
      setSearchError("Please enter the property ID.");
    } else {
      setSearchError("");
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setPropertyId(inputValue);
    if (searchError !== "") {
      setSearchError("");
    }
  };

  const SearchBtn = async () => {
    if (propertyId !== "") {
      const paramstext = propertyId.toUpperCase();
      const url = `${Baseurl}/api/v1/property/propertybyprojectid/${paramstext}`;
      const fetchProperty = await axios.get(url);
      try {
        if (fetchProperty.data.success === true) {
          const searchresult = fetchProperty.data.property;
          if (searchresult.apartment !== "") {
            navigate(
              `/property-info/${searchresult.apartment}/${searchresult.propertyID}`
            );
          } else {
            navigate(`/property-info/commercial/${searchresult.propertyID}`);
          }
          setMenubar(false);
          setPropertyId("");
        }
      } catch (error) {}
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      if (propertyId !== "") {
        const paramstext = propertyId.toUpperCase();

        const url = `${Baseurl}/api/v1/property/propertybyprojectid/${paramstext}`;
        const fetchProperty = await axios.get(url);
        try {
          if (fetchProperty.data.success === true) {
            const searchresult = fetchProperty.data.property;
            if (searchresult.apartment !== "") {
              navigate(
                `/property-info/${searchresult.apartment}/${searchresult.propertyID}`
              );
            } else {
              navigate(`/property-info/commercial/${searchresult.propertyID}`);
            }
            setMenubar(false);
            setPropertyId("");
          }
        } catch (error) {}
      }
    }
  };

  const residential = (e, value) => {
    setBuychange(false);
    setRentchange(false);

    setSupercat("Residential");
    // setSubcat("All");
    if (value === "Buy") {
      setCat("Buy");
      setBuychange(true);
    } else if (value === "Rent") {
      setCat("Rent");
      setRentchange(true);
    }
  };

  const logoutClick = () => {
    dispatch(authActions.signout());
    dispatch(setuserIdRemove());
    navigate("/");
    window.location.reload(false);
  };
  const buyHomeClick = (supercategory, cate, mysubcat) => {
    let findsearch = "Hyderabad";
    let finalLocalityArray = ["Hyderabad"];
    let checkmysubcat = "All";

    let checkboxArray = [mysubcat];
    if (checkboxArray.length > 0) {
      checkmysubcat = checkboxArray.join();
      checkmysubcat = checkmysubcat.replaceAll(",", "-");
      checkmysubcat = checkmysubcat.replaceAll("/", "-");
      checkmysubcat = checkmysubcat.replaceAll(" ", "-");
    }

    const searchFormdta = {
      selectedSubcat: [mysubcat],
      selectedCity: finalLocalityArray,
      selectedBedrooms: [],
      selectedConustruction: [],
      selectedPostedBy: [],
      propertyPrice: [],
      selectedSharing: [],
      selectedAvailables: [],
      selectedFurnished: [],
      selectedSeatType: [],
      selectedPlan: [],
      selectedNoSeat: [],
      selectLand: [],
      selectedArea: [],
      selectProjectStatus: [],
    };
    dispatch(setsearchObjects(searchFormdta));

    navigate(
      `/property-search/${supercategory}/${cate}/${checkmysubcat}/${findsearch}`
    );
  };

  const residentialPlotClick = (supercategory, cate, mysubcat) => {
    let findsearch = "Hyderabad";
    let finalLocalityArray = ["Hyderabad"];
    let checkmysubcat = "All";

    let checkboxArray = [mysubcat];
    if (checkboxArray.length > 0) {
      checkmysubcat = checkboxArray.join();
      checkmysubcat = checkmysubcat.replaceAll(",", "-");
      checkmysubcat = checkmysubcat.replaceAll("/", "-");
      checkmysubcat = checkmysubcat.replaceAll(" ", "-");
    }

    const searchFormdta = {
      selectedSubcat: [mysubcat],
      selectedCity: finalLocalityArray,
      selectedBedrooms: [],
      selectedConustruction: [],
      selectedPostedBy: [],
      propertyPrice: [],
      selectedSharing: [],
      selectedAvailables: [],
      selectedFurnished: [],
      selectedSeatType: [],
      selectedPlan: [],
      selectedNoSeat: [],
      selectLand: [],
      selectedArea: [],
      selectProjectStatus: [],
    };
    dispatch(setsearchObjects(searchFormdta));

    navigate(
      `/property-search/${supercategory}/${cate}/${checkmysubcat}/${findsearch}`
    );
  };
  const commercialClick = (supercategory, cate, mysubcat) => {
    let findsearch = "Hyderabad";
    let finalLocalityArray = ["Hyderabad"];
    let checkmysubcat = "All";

    let checkboxArray = [mysubcat];
    if (checkboxArray.length > 0) {
      checkmysubcat = checkboxArray.join();
      checkmysubcat = checkmysubcat.replaceAll(",", "-");
      checkmysubcat = checkmysubcat.replaceAll("/", "-");
      checkmysubcat = checkmysubcat.replaceAll(" ", "-");
    }

    const searchFormdta = {
      selectedSubcat: [mysubcat],
      selectedCity: finalLocalityArray,
      selectedBedrooms: [],
      selectedConustruction: [],
      selectedPostedBy: [],
      propertyPrice: [],
      selectedSharing: [],
      selectedAvailables: [],
      selectedFurnished: [],
      selectedSeatType: [],
      selectedPlan: [],
      selectedNoSeat: [],
      selectLand: [],
      selectedArea: [],
      selectProjectStatus: [],
    };
    dispatch(setsearchObjects(searchFormdta));

    navigate(
      `/property-search/${supercategory}/${cate}/${checkmysubcat}/${findsearch}`
    );
  };

  const residentialPopularAreaClick = (supercategory, cate, mysubcat, area) => {
    let findsearch = "Hyderabad";
    let finalLocalityArray = [area];
    let checkmysubcat = "All";

    let checkboxArray = [mysubcat];
    if (checkboxArray.length > 0) {
      checkmysubcat = checkboxArray.join();
      checkmysubcat = checkmysubcat.replaceAll(",", "-");
      checkmysubcat = checkmysubcat.replaceAll("/", "-");
      checkmysubcat = checkmysubcat.replaceAll(" ", "-");
    }

    const searchFormdta = {
      selectedSubcat: [],
      selectedCity: finalLocalityArray,
      selectedBedrooms: [],
      selectedConustruction: [],
      selectedPostedBy: [],
      propertyPrice: [],
      selectedSharing: [],
      selectedAvailables: [],
      selectedFurnished: [],
      selectedSeatType: [],
      selectedPlan: [],
      selectedNoSeat: [],
      selectLand: [],
      selectedArea: [],
      selectProjectStatus: [],
    };
    dispatch(setsearchObjects(searchFormdta));

    navigate(
      `/property-search/${supercategory}/${cate}/${checkmysubcat}/${findsearch}`
    );
  };
  const residentialPgClick = (supercategory, cate, mysubcat) => {
    let findsearch = "Hyderabad";
    let finalLocalityArray = ["Hyderabad"];
    let checkmysubcat = "All";

    let checkboxArray = [mysubcat];
    if (checkboxArray.length > 0) {
      checkmysubcat = checkboxArray.join();
      checkmysubcat = checkmysubcat.replaceAll(",", "-");
      checkmysubcat = checkmysubcat.replaceAll("/", "-");
      checkmysubcat = checkmysubcat.replaceAll(" ", "-");
    }

    const searchFormdta = {
      selectedSubcat: [],
      selectedCity: finalLocalityArray,
      selectedBedrooms: [],
      selectedConustruction: [],
      selectedPostedBy: [],
      propertyPrice: [],
      selectedSharing: [],
      selectedAvailables: [],
      selectedFurnished: [],
      selectedSeatType: [],
      selectedPlan: [],
      selectedNoSeat: [],
      selectLand: [],
      selectedArea: [],
      selectProjectStatus: [],
    };
    dispatch(setsearchObjects(searchFormdta));

    navigate(
      `/property-search/${supercategory}/${cate}/${checkmysubcat}/${findsearch}`
    );
  };

  return (
    <>
      <header className="main-header">
        <div className="logo-holder">
          <Link to="/">
            <img src="/assets/images/logo3.png" alt="" />
          </Link>
        </div>

        <div className="nav-button-wrap color-bg nvminit">
          <div className="nav-button">
            <span />
            <span />
            <span />
          </div>
        </div>

        <div className="nav-holder main-menu">
          <nav>
            <ul className="no-list-style">
              <li>
                <Link>
                  <button
                    type="button"
                    // data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    style={{
                      border: "none",
                      background: "none",
                      margin: "5px",
                    }}
                    onClick={() => setMenubar(!menubar)}
                  >
                    <img
                      src="/assets/images/newimages/menu.png"
                      alt=""
                      style={{ height: "26px", width: "26px" }}
                    />
                  </button>
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        {isAuth === true ? (
          <>
            <div className="add-list_wrap">
              <Link to="/PostPropertyHome" className="add-list color-bg">
                <i className="fal fa-plus" />
                <span>
                  Post Property
                  <span
                    style={{
                      background: "#219653",
                      color: "#fff",
                      padding: "2px 5px",
                      borderRadius: "3px",
                      fontWeight: "700",
                      lineHeight: "9px",
                      letterSpacing: ".02em",
                      textTransform: "uppercase",
                      fontSize: "10px",
                    }}
                  >
                    Free
                  </span>
                </span>
              </Link>
            </div>
          </>
        ) : (
          <>
            <div
              className="add-list_wrap modal-open"
              onClick={() => dispatch(setModelShow(true))}
            >
              <Link className="add-list color-bg">
                <i className="fal fa-plus" />{" "}
                <span>
                  Post Property
                  <span
                    style={{
                      background: "#219653",
                      color: "#fff",
                      padding: "2px 5px",
                      borderRadius: "3px",
                      fontWeight: "700",
                      lineHeight: "9px",
                      letterSpacing: ".02em",
                      textTransform: "uppercase",
                      fontSize: "10px",
                    }}
                  >
                    Free
                  </span>
                </span>
              </Link>
            </div>
          </>
        )}

        {isAuth === true ? (
          <>
            <div className="nav-holder main-menu">
              <nav>
                <ul className="no-list-style">
                  <li>
                    <Link>
                      {loginData.name}
                      <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li>
                        <Link
                          to="/dashboard"
                          style={{
                            fontSize: "14px",
                            color: "goldenrod",
                            fontWeight: "600",
                          }}
                        >
                          My MGH
                        </Link>
                      </li>
                      <li>
                        <Link to="">Recently Searched</Link>
                      </li>
                      <li>
                        <Link to="">Recently Viewed</Link>
                      </li>
                      <li>
                        <Link to="">Shortlisted</Link>
                      </li>
                      <li>
                        <Link to="">Contacted</Link>
                      </li>
                      <li>
                        <Link to="">Rent Transaction</Link>
                      </li>
                      <li
                        onClick={() => {
                          navigate("/dashboard");
                          dispatch(pageStatusChange(4));
                        }}
                      >
                        <Link to="">Manage Listings</Link>
                      </li>
                      <li
                        onClick={() => {
                          navigate("/dashboard");
                          dispatch(pageStatusChange(21));
                        }}
                      >
                        <Link to="">View All Responses</Link>
                      </li>
                      <li onClick={() => logoutClick()}>
                        <Link to="">Logout</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </>
        ) : (
          <>
            <div
              className="show-reg-form modal-open"
              onClick={() => dispatch(setModelShow(true))}
            >
              <i className="fas fa-user" />
              <span>Sign In</span>
            </div>
          </>
        )}

        <div className="nav-holder main-menu">
          <nav>
            <ul className="no-list-style">
              <li>
                <Link>
                  <MdHeadsetMic />
                </Link>
                <ul>
                  <li>
                    <Link>Contact us</Link>
                  </li>
                  <li>
                    <Link>
                      <div className="classforheadfon">
                        <div className="headfonIcon">
                          <MdCall color="#253858" fontSize="18px" />
                        </div>
                        <div className="headfonCall">
                          <div className="tollfree">
                            Toll Free | 9 AM - 8 PM IST
                          </div>
                          <div className="tollfreeNumber">+91 9063483711</div>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <div className="classforheadfon1">
                        <div className="requestcallIcon">
                          <MdPhoneCallback className="requestIconCo" />
                        </div>
                        <div className="requestcall">Request a Call Back</div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>

        {/* navigation  start */}
        <div className="nav-holder main-menu">
          <nav>
            <ul className="no-list-style">
              <li>
                <Link>
                  For Buyers
                  <i className="fa fa-caret-down" />
                </Link>
                <ul>
                  <li>
                    <Link>
                      BUY A HOME <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li
                        onClick={() =>
                          buyHomeClick("Residential", "Sell", "Flat/apartment")
                        }
                      >
                        <Link to="">Flats in Hyderabad</Link>
                      </li>
                      <li
                        onClick={() =>
                          buyHomeClick("Residential", "Sell", "Villa")
                        }
                      >
                        <Link to="">Villas in Hyderabad</Link>
                      </li>
                      <li
                        onClick={() =>
                          buyHomeClick(
                            "Residential",
                            "Sell",
                            "Independent house"
                          )
                        }
                      >
                        <Link to="">Independent House</Link>
                      </li>
                      <li
                        onClick={() =>
                          buyHomeClick(
                            "Residential",
                            "Sell",
                            "Independent/builder floor"
                          )
                        }
                      >
                        <Link to="">Builder Floors</Link>
                      </li>
                      <li
                        onClick={() =>
                          buyHomeClick("Residential", "Sell", "Plot")
                        }
                      >
                        <Link to="">Plots in Hyderabad</Link>
                      </li>
                      <li
                        onClick={() =>
                          buyHomeClick(
                            "Residential",
                            "Sell",
                            "Service apartment"
                          )
                        }
                      >
                        <Link to="">Serviced Apartments</Link>
                      </li>
                      <li
                        onClick={() =>
                          buyHomeClick(
                            "Residential",
                            "Sell",
                            "1 rk/studio apartment"
                          )
                        }
                      >
                        <Link to="">Studio Apartments/1 RK Flats</Link>
                      </li>
                      <li
                        onClick={() =>
                          buyHomeClick("Residential", "Sell", "Farmhouse")
                        }
                      >
                        <Link to="">Farm Houses</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link>
                      LAND/PLOT <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li
                        onClick={() =>
                          residentialPlotClick("Residential", "Sell", "Plot")
                        }
                      >
                        <Link to="">Gated Community Plots in Hyderabad </Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPlotClick("Residential", "Sell", "Plot")
                        }
                      >
                        <Link to="">Corner Plots in Hyderabad </Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPlotClick("Residential", "Sell", "Plot")
                        }
                      >
                        <Link to="">East Facing Plots in Hyderabad </Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPlotClick("Residential", "Sell", "Plot")
                        }
                      >
                        <Link to="">Freehold Plots in Hyderabad </Link>
                      </li>

                      <li
                        onClick={() =>
                          residentialPlotClick("Commercial", "Sell", "Land")
                        }
                      >
                        <Link to="">
                          Commercial Land for sale in Hyderabad{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link>
                      COMMERCIAL <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li
                        onClick={() =>
                          commercialClick("Commercial", "Sell", "Office")
                        }
                      >
                        <Link to="">Ready to Move office spaces</Link>
                      </li>

                      {/* <li
                        onClick={() =>
                          commercialClick("Commercial", "Sell", "Office")
                        }
                      >
                        <Link to="">Co-working office spaces</Link>
                      </li> */}

                      {/* <li
                        onClick={() =>
                          commercialClick("Commercial", "Sell", "Plot")
                        }
                      >
                        <Link to="">Factory</Link>
                      </li> */}

                      <li
                        onClick={() =>
                          commercialClick("Commercial", "Sell", "Hospitality")
                        }
                      >
                        <Link to="">Hospitality</Link>
                      </li>
                      <li
                        onClick={() =>
                          commercialClick("Commercial", "Sell", "Storage")
                        }
                      >
                        <Link to="">Storage</Link>
                      </li>
                      <li
                        onClick={() =>
                          commercialClick("Commercial", "Sell", "Plot")
                        }
                      >
                        <Link to="">Industrial Lands/Plots</Link>
                      </li>
                      <li
                        onClick={() =>
                          commercialClick("Commercial", "Sell", "Land")
                        }
                      >
                        <Link to="">Agricultural/Farm Land</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link>
                      POPULAR AREAS <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li
                        onClick={() =>
                          residentialPopularAreaClick(
                            "Residential",
                            "Sell",
                            "All",
                            "Kondapur"
                          )
                        }
                      >
                        <Link to="">Property in Kondapur</Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPopularAreaClick(
                            "Residential",
                            "Sell",
                            "All",
                            "Gachibowli"
                          )
                        }
                      >
                        <Link to="">Property in Gachibowli</Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPopularAreaClick(
                            "Residential",
                            "Sell",
                            "All",
                            "Manikonda"
                          )
                        }
                      >
                        <Link to="">Property in Manikonda</Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPopularAreaClick(
                            "Residential",
                            "Sell",
                            "All",
                            "Miyapur"
                          )
                        }
                      >
                        <Link to="">Property in Miyapur</Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li>
                    <Link to="">
                      ARTICLES & NEWS <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li>
                        <Link to="">Articles For Buyers</Link>
                      </li>
                      <li>
                        <Link to="">Real Estate News</Link>
                      </li>
                      <li>
                        <Link to="">Buyer Guide</Link>
                      </li>
                      <li>
                        <Link to="">Home Interior Guides</Link>
                      </li>
                      <li>
                        <Link to="">Policies (GST, RERA, PMAY, Budget)</Link>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </li>

              <li>
                <Link to="">
                  For Tenants
                  <i className="fa fa-caret-down" />
                </Link>
                <ul>
                  <li>
                    <Link>
                      RENT A HOME
                      <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li
                        onClick={() =>
                          buyHomeClick("Residential", "Sell", "Flat/apartment")
                        }
                      >
                        <Link to="">Flats in Hyderabad</Link>
                      </li>
                      <li
                        onClick={() =>
                          buyHomeClick("Residential", "Sell", "Villa")
                        }
                      >
                        <Link to="">Villas in Hyderabad</Link>
                      </li>
                      {/* <li
                        onClick={() =>
                          buyHomeClick(
                            "Residential",
                            "Sell",
                            "Independent house"
                          )
                        }
                      >
                        <Link to="">Independent House</Link>
                      </li> */}
                      <li
                        onClick={() =>
                          buyHomeClick(
                            "Commercial",
                            "Sell",
                            "Independent/builder floor"
                          )
                        }
                      >
                        <Link to="">Builder Floors</Link>
                      </li>
                      <li
                        onClick={() =>
                          buyHomeClick("Commercial", "Sell", "Land")
                        }
                      >
                        <Link to="">Lands in Hyderabad</Link>
                      </li>
                      <li
                        onClick={() =>
                          buyHomeClick("Commercial", "Sell", "Retail")
                        }
                      >
                        <Link to="">Retail Properties</Link>
                      </li>
                      <li
                        onClick={() =>
                          buyHomeClick(
                            "Commercial",
                            "Sell",
                            "1 rk/studio apartment"
                          )
                        }
                      >
                        <Link to="">Studio Apartments/1 RK Flats</Link>
                      </li>
                      <li
                        onClick={() =>
                          buyHomeClick("Residential", "Sell", "Farmhouse")
                        }
                      >
                        <Link to="">Farm Houses</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link>
                      PG/CO-LIVING <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li
                        onClick={() =>
                          residentialPgClick("Residential", "Pg", "All")
                        }
                      >
                        <Link to="">PG for girls in Hyderabad</Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPgClick("Residential", "Pg", "All")
                        }
                      >
                        <Link to="">PG for boys in Hyderabad</Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPgClick("Residential", "Pg", "All")
                        }
                      >
                        <Link to="">Single Room PG in Hyderabad</Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPgClick("Residential", "Pg", "All")
                        }
                      >
                        <Link to="">Double Sharing PG in Hyderabad</Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPgClick("Residential", "Pg", "All")
                        }
                      >
                        <Link to="">Triple Sharing PG in Hyderabad</Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPgClick("Residential", "Pg", "All")
                        }
                      >
                        <Link to="">PG in Hyderabad</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="">
                      COMMERCIAL <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li
                        onClick={() =>
                          commercialClick("Commercial", "Sell", "Office")
                        }
                      >
                        <Link to="">Ready to Move office spaces</Link>
                      </li>

                      <li
                        onClick={() =>
                          commercialClick("Commercial", "Sell", "Plot")
                        }
                      >
                        <Link to="">Hospitality</Link>
                      </li>
                      <li
                        onClick={() =>
                          commercialClick("Commercial", "Sell", "Hospitality")
                        }
                      >
                        <Link to="">Storage</Link>
                      </li>
                      <li
                        onClick={() =>
                          commercialClick("Commercial", "Sell", "Storage")
                        }
                      >
                        <Link to="">Industrial Lands/Plots</Link>
                      </li>
                      <li
                        onClick={() =>
                          commercialClick("Commercial", "Sell", "Land")
                        }
                      >
                        <Link to="">Agricultural/Farm Land</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="">
                      POPULAR AREAS <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li
                        onClick={() =>
                          residentialPopularAreaClick(
                            "Commercial",
                            "Sell",
                            "All",
                            "Kondapur"
                          )
                        }
                      >
                        <Link to="">Property in Kondapur</Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPopularAreaClick(
                            "Commercial",
                            "Sell",
                            "All",
                            "Gachibowli"
                          )
                        }
                      >
                        <Link to="">Property in Gachibowli</Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPopularAreaClick(
                            "Commercial",
                            "Sell",
                            "All",
                            "Manikonda"
                          )
                        }
                      >
                        <Link to="">Property in Manikonda</Link>
                      </li>
                      <li
                        onClick={() =>
                          residentialPopularAreaClick(
                            "Commercial",
                            "Sell",
                            "All",
                            "Miyapur"
                          )
                        }
                      >
                        <Link to="">Property in Miyapur</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="">
                  For Owners
                  <i className="fa fa-caret-down" />
                </Link>

                <ul>
                  <li>
                    <Link>
                      OWNER OFFERINGS <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      {isAuth ? (
                        <>
                          <li>
                            <Link to="/postPropertyHome">
                              Post Property for Free
                            </Link>
                          </li>
                        </>
                      ) : (
                        <>
                          <li onClick={() => dispatch(setModelShow(true))}>
                            <Link>Post Property for Free</Link>
                          </li>
                        </>
                      )}

                      <li>
                        <Link>Two Post Free</Link>
                      </li>

                      {isAuth ? (
                        <>
                          <li
                            onClick={() => {
                              navigate("/dashboard");
                              dispatch(pageStatusChange(21));
                            }}
                          >
                            <Link>View Responses</Link>
                          </li>
                        </>
                      ) : (
                        <>
                          <li onClick={() => dispatch(setModelShow(true))}>
                            <Link>View Responses</Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <Link>
                  For Dealers / Builders
                  <i className="fa fa-caret-down" />
                </Link>

                <ul>
                  <li>
                    <Link>
                      DEALER OFFERINGS <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      {isAuth ? (
                        <>
                          <li>
                            <Link to="/postPropertyHome">Post Property</Link>
                          </li>
                          <li
                            onClick={() => {
                              navigate("/dashboard");
                              dispatch(pageStatusChange(9));
                            }}
                          >
                            <Link to="">Dealer Services</Link>
                          </li>
                          <li
                            onClick={() => {
                              navigate("/dashboard");
                              dispatch(pageStatusChange(21));
                            }}
                          >
                            <Link to="">View Responses</Link>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <Link onClick={() => dispatch(setModelShow(true))}>
                              Post Property
                            </Link>
                          </li>
                          <li onClick={() => dispatch(setModelShow(true))}>
                            <Link to="">Dealer Services</Link>
                          </li>
                          <li onClick={() => dispatch(setModelShow(true))}>
                            <Link to="">View Responses</Link>
                          </li>
                        </>
                      )}

                      {/* <li>
                        <Link to="">Make an advance payment</Link>
                      </li> */}
                    </ul>
                  </li>
                  <li>
                    <Link>
                      RESEARCH AND ADVICE <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li>
                        <Link to="/contactus">Customer Services & FAQs</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              {/* <li>
                <Link to="">
                  Insights
                  <i className="fa fa-caret-down" />
                </Link>
                <ul>
                  <li>
                    <Link to="">
                      LOCALITY INSIGHTS <i className="fa fa-caret-down" />
                    </Link>

                    <ul>
                      <li>
                        <Link to="">Gachibowli Overview</Link>
                      </li>
                      <li>
                        <Link to="">Kondapur Overview</Link>
                      </li>
                      <li>
                        <Link to="">Manikonda Overview</Link>
                      </li>
                      <li>
                        <Link to="">Kokapet Overview</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="">
                      PRICE TRENDS <i className="fa fa-caret-down" />
                    </Link>

                    <ul>
                      <li>
                        <Link to="">Property Rates in Srisailam Highway</Link>
                      </li>
                      <li>
                        <Link to="">Property Rates in Somajiguda</Link>
                      </li>
                      <li>
                        <Link to="">Property Rates in Puppalaguda</Link>
                      </li>
                      <li>
                        <Link to="">Property Rates in Appa Junction</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="">
                      TRANSACTION PRICES <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li>
                        <Link to="">Transaction Prices in Gachibowli</Link>
                      </li>
                      <li>
                        <Link to="">Transaction Prices in Kondapur</Link>
                      </li>
                      <li>
                        <Link to="">Transaction Prices in Manikonda</Link>
                      </li>
                      <li>
                        <Link to="">Transaction Prices in Kokapet</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="">
                      REVIEWS <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li>
                        <Link to="">Reviews of Madhapur</Link>
                      </li>
                      <li>
                        <Link to="">Reviews of Kukatpally</Link>
                      </li>
                      <li>
                        <Link to="">Reviews of Gachibowli</Link>
                      </li>
                      <li>
                        <Link to="">Reviews of LB Nagar</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> */}
            </ul>
          </nav>
        </div>
        {/* navigation  end */}

        {/* search-wrapper */}
        <div className="header-search-wrapper novis_search">
          <div className="header-serach-menu">
            <div className="custom-switcher fl-wrap">
              <div className="fieldset fl-wrap">
                <input
                  type="radio"
                  name="duration-1"
                  id="buy_sw"
                  className="tariff-toggle"
                  defaultChecked
                />
                <label htmlFor="buy_sw">Buy</label>
                <input
                  type="radio"
                  name="duration-1"
                  className="tariff-toggle"
                  id="rent_sw"
                />
                <label htmlFor="rent_sw" className="lss_lb">
                  Rent
                </label>
                <span className="switch color-bg" />
              </div>
            </div>
          </div>
          <div className="custom-form">
            <form name="registerform">
              <label>Keywords </label>
              <input
                type="text"
                placeholder="Address , Street , State..."
                defaultValue=""
              />
              <label>Categories</label>
              <select
                data-placeholder="Categories"
                className="chosen-select nice-select on-radius no-search-select"
              >
                <option>All Categories</option>
                <option>House</option>
                <option>Apartment</option>
                <option>Hotel</option>
                <option>Villa</option>
                <option>Office</option>
              </select>
              <label style={{ marginTop: 10 }}>Price Range</label>
              <div className="price-rage-item fl-wrap">
                <input
                  type="text"
                  className="price-range"
                  data-min={100}
                  data-max={100000}
                  name="price-range1"
                  data-step={1}
                  defaultValue={1}
                  data-prefix="₹"
                />
              </div>
              <button type="submit" className="btn float-btn color-bg">
                <i className="fal fa-search" /> Search
              </button>
            </form>
          </div>
        </div>
        {/*search-wrapper end  */}
      </header>

      {menubar === true ? (
        <>
          <div className=" menubar">
            <div className="dashbard-menu-close">
              <i className="fal fa-times" />
            </div>
            <div className="dashbard-menu-container">
              <div className="user-profile-menu">
                <h3 style={{ textTransform: "capitalize" }}>
                  {loginData.name}
                </h3>
                <ul className="no-list-style">
                  <li
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/dashboard")}
                  >
                    <a>
                      <i className="fal fa-chart-line" />
                      Dashboard
                    </a>
                  </li>
                  <li
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/dashboard");
                      dispatch(pageStatusChange(9));
                    }}
                  >
                    <a>
                      <i className="fal fa-user-edit" />
                      Packages
                    </a>
                  </li>
                </ul>
              </div>
              <div className="user-profile-menu">
                <h3>Listings</h3>
                <ul className="no-list-style">
                  <li
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/dashboard");
                      dispatch(pageStatusChange(4));
                    }}
                  >
                    <a>
                      <i className="fal fa-th-list" /> Your Properties
                    </a>
                  </li>
                  <li
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/dashboard");
                      dispatch(pageStatusChange(21));
                    }}
                  >
                    <a className="user-profile-act">
                      <i className="fal fa-calendar-check" /> All Responses
                    </a>
                  </li>
                </ul>
              </div>
              <div className="container searchcon">
                <div className="menusearch">
                  <input
                    type="text "
                    id="propertyId"
                    style={{
                      textTransform: "uppercase",
                      fontSize: "10px",
                      background: "none",
                    }}
                    value={propertyId}
                    onChange={handleChange}
                    onClick={InputClick}
                    placeholder="Search by Property ID"
                    className="menuseachinput"
                    onKeyDown={handleKeyDown}
                  />
                  <IoSearchSharp
                    onClick={SearchBtn}
                    size={24}
                    style={{ cursor: "pointer" }}
                    className="menusearchicon"
                  />
                </div>
              </div>
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                {searchError}
              </p>
            </div>

            <div className="dashbard-menu-footer">
              <p className="strong_medium">
                Toll Free Number:
                <span style={{ color: "#0078db" }}>+91 90634 83711. </span>
                For international number
              </p>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {/* for model */}
      <div
        className="main-register-wrap modal"
        style={modelShow === true ? { display: "block" } : { display: "none" }}
      >
        {/* <div className="reg-overlay" /> */}
        <div className="main-register-holder tabs-act">
          <div
            className={
              modelShow
                ? "main-register-wrapper modal_main fl-wrap vis_mr"
                : "main-register-wrapper modal_main fl-wrap"
            }
          >
            <div className="main-register-header color-bg">
              <div className="main-register-logo fl-wrap">
                <img src="./assets/images/white-logo1.png" alt="" />
              </div>
              <div className="main-register-bg">
                <div className="mrb_pin" />
                <div className="mrb_pin mrb_pin2" />
              </div>
              <div className="mrb_dec" />
              <div className="mrb_dec mrb_dec2" />
            </div>
            <div className="main-register">
              <div className="close-reg">
                <i className="fal fa-times" />
              </div>
              <ul className="tabs-menu fl-wrap no-list-style">
                <li className="current">
                  <a href="#tab-1">
                    <i className="fal fa-sign-in-alt" /> Login
                  </a>
                </li>
                <li>
                  <a href="#tab-2">
                    <i className="fal fa-user-plus" /> Register
                  </a>
                </li>
              </ul>

              <div className="tabs-container">
                <div className="tab">
                  <div id="tab-1" className="tab-content first-tab">
                    <Login />
                  </div>

                  <div className="tab">
                    <div id="tab-2" className="tab-content">
                      <Registration />
                    </div>
                  </div>
                </div>

                <div
                  className="log-separator fl-wrap"
                  style={{ visibility: "hidden", opacity: "0" }}
                >
                  <span>or</span>
                </div>
                <div
                  className="soc-log fl-wrap"
                  style={{ visibility: "hidden", opacity: "0" }}
                >
                  <p>For faster login or register use your social account.</p>
                  <Link to="#" className="facebook-log">
                    Facebook
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
