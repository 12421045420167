import React from "react";
import { useSelector } from "react-redux";
import CommercialSearchComponent from "./CommercialSearchComponent";
import CommercialtwoSearchComponent from "./CommercialtwoSearchComponent";

const MainComersialPage = () => {
  const { commercialStatus } = useSelector((state) => state.Search);
  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return <CommercialtwoSearchComponent />;
      case 1:
        return <CommercialSearchComponent />;
      default:
        return <CommercialtwoSearchComponent />;
        break;
    }
  };
  return <>{renderSwitch(commercialStatus)}</>;
};

export default MainComersialPage;
