import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  catTotal: [],
  catLoading: false,
  categoryLoading: false,
  appliedFilters: [],
};

export const getCategory = createAsyncThunk(
  "Category/getCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/category/all`;
      const resp = await axios(url);
      return resp.data.category;
    } catch (error) {
      return thunkAPI.rejectWithValue("Category Not Found");
    }
  }
);

const CategorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state) => {
        state.catLoading = true;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.catLoading = false;
        state.categoryLoading = false;
        state.catTotal = action.payload;
      })
      .addCase(getCategory.rejected, (state) => {
        state.catLoading = true;
      });
  },
});

export const categoryActions = CategorySlice.actions;
export default CategorySlice.reducer;
