import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { read, utils } from "xlsx";
import { propertyUpdate } from "../redux/property/PropertySlice";
import moment from "moment";

const UpdatePropertyAll = () => {
  const { singleProperty } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [uploadedProductName, setUploadedProductName] = useState("");

  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    console.log(datas);
    setData(datas);
  };

  const updatePropertyClick = async (e) => {
    e.preventDefault();
    let numbers = 0;
    let errordata = [];
    const finaldata = data;
    for (let i in finaldata) {
      let myrow = finaldata[i];
      numbers = i;

      let pricePerSqft = 0;
      if (Number(myrow.expectedPrice) > 0) {
        let value = Number(myrow.expectedPrice) / Number(myrow.carpetArea);

        value = Math.round(value);
        pricePerSqft = value;
      }

      let unicText = "";
      if (myrow.superCategory === "Commercial") {
        unicText = `This ${myrow.subCategory} is available for ${
          myrow.category
        } in ${myrow.apartment}, one of the most prominent projects for ${
          myrow.subCategory
        } in ${myrow.apartment} is available for ${myrow.category} in ${
          myrow.locality
        }, ${myrow.city}. Constructed on a Carpet Area of ${
          myrow.carpetArea + " " + myrow.carpetAreaMesurmentType
        }. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
      } else {
        if (myrow.category === "Pg") {
          unicText = `This ${myrow.subCategory} is available for ${
            myrow.category
          } in ${myrow.apartment}, one of the most prominent projects for ${
            myrow.subCategory
          } in ${myrow.apartment} is available for ${myrow.category} in ${
            myrow.locality
          }, ${myrow.city}. Constructed on a Carpet Area of ${
            myrow.carpetArea + " " + myrow.carpetAreaMesurmentType
          }, the ${myrow.subCategory} comprises ${
            myrow.noofBedrooms
          } bedroom(s), ${myrow.noofBathrooms} bathrooms and ${
            myrow.balconies
          } balcony. The ${myrow.subCategory} has a total of ${
            myrow.noofFloor
          } floors and this property is situated on ${
            myrow.propertyOnFloor
          } floor. This is a Ready To Move project and the property is ${
            myrow.propertyAge
          } year old. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
        } else {
          if (
            myrow.subCategory === "Office" ||
            myrow.subCategory === "Retail"
          ) {
            if (myrow.availabilityStatus === "Ready To Move") {
              unicText = `This ${myrow.subCategory} is available for ${
                myrow.category
              } in ${myrow.apartment}, one of the most prominent projects for ${
                myrow.subCategory
              } in ${myrow.apartment} is available for ${myrow.category} in ${
                myrow.locality
              }, ${myrow.city}. Constructed on a Carpet Area of ${
                myrow.carpetArea + " " + myrow.carpetAreaMesurmentType
              }, the ${myrow.subCategory} comprises ${
                myrow.noofBedrooms
              } bedroom(s), ${myrow.noofBathrooms} bathrooms and ${
                myrow.balconies
              } balcony. The ${myrow.subCategory} has a total of ${
                myrow.noofFloor
              } floors  ${
                myrow.subCategory !== "Villa"
                  ? myrow.propertyOnFloor +
                    "and this property is situated on floor"
                  : ""
              } . This is a Ready To Move project and the property is ${
                myrow.propertyAge
              } year old. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
            } else {
              unicText = `This ${myrow.subCategory} is available for ${
                myrow.category
              } in ${myrow.apartment}, one of the most prominent projects for ${
                myrow.subCategory
              } in ${myrow.apartment} is available for ${myrow.category} in ${
                myrow.locality
              }, ${myrow.city}. Constructed on a Carpet Area of ${
                myrow.carpetArea + " " + myrow.carpetAreaMesurmentType
              }, the ${myrow.subCategory} comprises ${
                myrow.noofBedrooms
              } bedroom(s), ${myrow.noofBathrooms} bathrooms and ${
                myrow.balconies
              } balcony. The ${myrow.subCategory} has a total of ${
                myrow.noofFloor
              } floors ${
                myrow.subCategory !== "Villa"
                  ? myrow.propertyOnFloor +
                    "and this property is situated on floor"
                  : ""
              } . This is a Under Construction project and the property is ${
                myrow.propertyAge
              } year old. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
            }
          } else if (
            myrow.subCategory === "Plot" ||
            myrow.subCategory === "Land"
          ) {
            if (myrow.availabilityStatus === "Ready To Move") {
              unicText = `This ${myrow.subCategory} is available for ${
                myrow.category
              } in ${myrow.apartment}, one of the most prominent projects for ${
                myrow.subCategory
              } in ${myrow.apartment} is available for ${myrow.category} in ${
                myrow.locality
              }, ${myrow.city}. Constructed on a Carpet Area of ${
                myrow.carpetArea + " " + myrow.carpetAreaMesurmentType
              }. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
            } else {
              unicText = `This ${myrow.subCategory} is available for ${
                myrow.category
              } in ${myrow.apartment}, one of the most prominent projects for ${
                myrow.subCategory
              } in ${myrow.apartment} is available for ${myrow.category} in ${
                myrow.locality
              }, ${myrow.city}. Constructed on a Carpet Area of ${
                myrow.carpetArea + " " + myrow.carpetAreaMesurmentType
              }. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
            }
          } else {
            if (myrow.availabilityStatus === "Ready To Move") {
              unicText = `This ${myrow.noofBedrooms} BHK ${
                myrow.subCategory
              } is available for ${myrow.category} in ${
                myrow.apartment
              }, one of the most prominent projects for ${
                myrow.subCategory
              } in ${myrow.apartment} is available for ${myrow.category} in ${
                myrow.locality
              }, ${myrow.city}. Constructed on a Carpet Area of ${
                myrow.carpetArea + " " + myrow.carpetAreaMesurmentType
              }, the ${myrow.subCategory} comprises ${
                myrow.noofBedrooms
              } bedroom(s), ${myrow.noofBathrooms} bathrooms and ${
                myrow.balconies
              } balcony. The ${myrow.subCategory} has a total of ${
                myrow.noofFloor
              } floors ${
                myrow.subCategory !== "Villa"
                  ? myrow.propertyOnFloor +
                    "and this property is situated on floor"
                  : ""
              } . This is a Ready To Move project and the property is ${
                myrow.propertyAge
              } year old. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
            } else {
              unicText = `This ${myrow.noofBedrooms} BHK ${
                myrow.subCategory
              } is available for ${myrow.category} in ${
                myrow.apartment
              }, one of the most prominent projects for ${
                myrow.subCategory
              } in ${myrow.apartment} is available for ${myrow.category} in ${
                myrow.locality
              }, ${myrow.city}. Constructed on a Carpet Area of ${
                myrow.carpetArea + " " + myrow.carpetAreaMesurmentType
              }, the ${myrow.subCategory} comprises ${
                myrow.noofBedrooms
              } bedroom(s), ${myrow.noofBathrooms} bathrooms and ${
                myrow.balconies
              } balcony. The ${myrow.subCategory} has a total of ${
                myrow.noofFloor
              } floors  ${
                myrow.subCategory !== "Villa"
                  ? myrow.propertyOnFloor +
                    "and this property is situated on floor"
                  : ""
              } . This is a Under Construction project and the property is ${
                myrow.propertyAge
              } year old. Many of the modern amenities being offered will provide a pleasant living experience for you.`;
            }
          }
        }
      }
      unicText = unicText.trim();

      let formattedDate = moment(myrow.availableDate, "DD-MM-YYYY").format(
        "DD MMM YYYY"
      );

      const formdata = {
        propertyId: myrow._id,
        apartmentType: "",
        noofBedrooms: myrow.noofBedrooms,
        noofBathrooms: myrow.noofBathrooms,
        balconies: myrow.balconies,

        carpetArea: Number(myrow.carpetArea),
        carpetAreaMesurmentType: myrow.carpetAreaMesurmentType,

        buildupArea: Number(myrow.builtupArea),
        buildupAreaMesurmentType: myrow.typeofBuiltupArea,

        superbuildupArea: Number(myrow.superbuildupArea),
        superbuildupAreaMesurmentType: myrow.typeofSuperBuiltupArea,

        plotArea: Number(myrow.plotArea),
        plotAreaMesurmentType: myrow.plotAreaMesurmentType,

        lengthofPlot: myrow.lengthofPlot,
        widthofPlot: myrow.widthofPlot,
        allowedNoofFloors: myrow.allowedNoofFloors,
        wallboundryAvaliable: myrow.wallboundryAvaliable,
        noofOpenSides: myrow.noofOpenSides,
        ConustructionOnthisProperty: myrow.ConustructionOnthisProperty,

        typeofRoom: myrow.typeofRoom,
        noOfpeoplesharing: myrow.noOfpeoplesharing,
        noOfBeds: myrow.noOfBeds,
        noOfAvailbleBeds: myrow.noOfAvailbleBeds,
        AttachedBathrooms: myrow.AttachedBathrooms,
        Attachedbalcony: myrow.Attachedbalcony,

        //ofc
        minNoOfSeats: myrow.minNoOfSeats,
        maxNoofSeats: myrow.maxNoofSeats,
        noofCabins: myrow.noofCabins,
        noofMeatingRooms: myrow.noofMeatingRooms,

        washRoomAvalible: myrow.washRoomAvalible,
        privateWashroom: myrow.privateWashroom,
        sharedWashroom: myrow.sharedWashroom,

        ConferenceRoom: myrow.ConferenceRoom,
        receptionArea: myrow.receptionArea,
        pantryType: myrow.pantryType,
        pantrySize: myrow.pantrySize,
        typeofpantrySize: myrow.typeofpantrySize,

        fireExtinguisher: myrow.fireExtinguisher,
        fireSensors: myrow.fireSensors,
        sprinklers: myrow.sprinklers,
        fireHose: myrow.fireHose,

        pglift: myrow.lifts,
        servicelifts: myrow.servicelifts,
        passengerLifts: myrow.passengerLifts,
        modernLift: myrow.modernLift,

        //retail

        entranceWidth: myrow.entranceWidth,
        typeofentranceWidth: myrow.typeofentranceWidth,
        cellingHeight: myrow.cellingHeight,
        typeofcellingHeight: myrow.typeofcellingHeight,

        entrance: myrow.entrance,
        elevator: myrow.elevator,
        stairs: myrow.stairs,

        poojaRoom: myrow.poojaroom,
        studyRoom: myrow.studyroom,
        servantRoom: myrow.servantroom,
        storeRoom: myrow.storeroom,

        furnishType: myrow.furnishType,
        availableFor: myrow.availableFor,
        availableDate: formattedDate,
        family: myrow.family,
        man: myrow.man,
        women: myrow.women,
        areyouOk: myrow.areyouOk,

        numberoflight: String(myrow.numberoflight),
        numberoffans: String(myrow.numberoffans),
        numberofac: String(myrow.numberofac),
        numberoftv: String(myrow.numberoftv),
        numberofbeds: String(myrow.numberofbeds),
        numberofwardrobe: String(myrow.numberofwardrobe),
        numberofgeyser: String(myrow.numberofgeyser),
        sofa: myrow.sofa,
        washingMachine: myrow.washingMachine,
        stove: myrow.stove,
        fridge: myrow.fridge,
        waterPurifier: myrow.waterPurifier,
        microwave: myrow.microwave,
        modularKitchen: myrow.modularKitchen,
        chimney: myrow.chimney,
        dinningTable: myrow.dinningTable,
        curtains: myrow.curtains,
        exhaustFan: myrow.exhaustFan,

        numberofCoveredParking: String(myrow.numberofCoveredParking),
        numberofOpenParking: String(myrow.numberofOpenParking),

        noofFloor: myrow.noofFloor,
        propertyOnFloor: myrow.propertyOnFloor,

        availabilityStatus: myrow.availabilityStatus,
        propertyAge: myrow.propertyAge,

        propertyPossessionBy: myrow.propertyPossessionBy,
        plotRoadwidth: myrow.plotRoadwidth,
        facingRoad: myrow.facingRoad,
        noofRating: myrow.noofRating,

        ownerShip: myrow.ownerShip,
        preferedAgreement: myrow.preferedAgreement,
        expectedPrice: myrow.expectedPrice,
        visiblePrice: myrow.visiblePrice,
        pricePerSqft: Number(pricePerSqft),

        inclusivePrice: myrow.inclusivePrice,

        maintenanceAmount: myrow.maintenanceAmount,
        maintenanceTerm: myrow.maintenanceTerm,
        expectedRental: myrow.expectedRental,
        bookingAmount: myrow.bookingAmount,
        annualPayable: myrow.annualPayable,
        membershipCharge: myrow.membershipCharge,
        securityDeposit: myrow.securityDeposit,
        depositAmount: myrow.depositAmount,
        noofMonths: myrow.noofMonths,

        houseKeepingExtraCharge: myrow.houseKeepingExtraCharge,
        FoodDetails: myrow.FoodDetails,
        mealtype: myrow.mealtype,
        lunch: myrow.lunch,
        breakfast: myrow.breakfast,
        dinner: myrow.dinner,
        lunchweekends: myrow.lunchweekends,
        breakfastweekends: myrow.breakfastweekends,
        dinnerweekends: myrow.dinnerweekends,
        foodCharges: myrow.foodCharges,
        lunchAmount: myrow.lunchAmount,
        breakfastAmount: myrow.breakfastAmount,
        dinnerAmount: myrow.dinnerAmount,
        fixedAmountFood: myrow.fixedAmountFood,

        durationAgreementPg: myrow.durationAgreementPg,
        maintenanceMonthlyduration: myrow.maintenanceMonthlyduration,
        notiesMonths: myrow.notiesMonths,

        earlyLeavingCharges: myrow.earlyLeavingCharges,
        earlyFixedCharges: myrow.earlyFixedCharges,
        earlyMonths: myrow.earlyMonths,

        showLastEntryTimePg: myrow.showLastEntryTimePg,
        otherRulePg: myrow.otherRulePg,
        uniquePropertyCommercial: myrow.uniquePropertyCommercial,
        dTCP: myrow.dTCP,
        gHMC: myrow.gHMC,
        hMDA: myrow.hMDA,
        preleasedCommercial: myrow.preleasedCommercial,
        rentpermonthCommercial: myrow.rentpermonthCommercial,
        leaseyearsCommercial: myrow.leaseyearsCommercial,
        leasePercentageCommercial: myrow.leasePercentageCommercial,
        businessTypeCommercial: myrow.businessTypeCommercial,
        lockInPeriodCommercial: myrow.lockInPeriodCommercial,
        yearlyRentCommercial: myrow.yearlyRentCommercial,
        nocCommercial: myrow.nocCommercial,
        ocCertificateCommercial: myrow.ocCertificateCommercial,
        officeUsedfor: myrow.officeUsedfor,

        separateentryforservantroom: myrow.separateentryforservantroom,
        noopendrainagearound: myrow.noopendrainagearound,
        bankAttachedProperty: myrow.bankAttachedProperty,
        lowDensitySociety: myrow.lowDensitySociety,
        municipalcorporation: myrow.municipalcorporation,
        borewellTank: myrow.borewellTank,
        twentyfourbysevenWater: myrow.twentyfourbysevenWater,

        powerBackup: myrow.powerBackup,
        propertyFacing: myrow.propertyFacing,
        typeofFlooring: myrow.typeofFlooring,
        roadWidth: myrow.roadWidth,
        roadWidthType: myrow.roadWidthType,
        typeOfland: myrow.typeOfland,

        unicProperty: unicText,
      };

      const newObject = {};
      for (const key in formdata) {
        newObject[key] =
          formdata[key] === null ||
          formdata[key] === "NULL" ||
          formdata[key] === undefined
            ? ""
            : formdata[key];
      }

      console.log(newObject, "formdata");
      const data = await dispatch(propertyUpdate(formdata));
    }
  };

  return (
    <div>
      <input
        type="file"
        placeholder="enter name....."
        onChange={(e) => handlefile(e)}
      />
      <div
        style={{
          border: "1px solid blue",
          background: "blue",
          padding: "10px",
          color: "white",
          cursor: "pointer",
        }}
        onClick={(e) => updatePropertyClick(e)}
      >
        Upadte Properties
      </div>
    </div>
  );
};

export default UpdatePropertyAll;
