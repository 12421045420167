import React from 'react'

const ManagePayments = () => {
    return (
        <>
            <div className='component__responceWrapper'>
                <div className='pageComponent'></div>
                <div className='fslBooking__noResult'>
                    <h1>Sorry, No Results !</h1>
                    <p>We cannot find the item you are searching for.</p>
                    <div className='fslBooking__noResults'></div>
                </div>
            </div>
        </>
    )
}

export default ManagePayments