import React from "react";
import { useSelector } from "react-redux";
import BasicDetails from "./componenets/BasicDetails";
import PhotoDetails from "./componenets/PhotoDetails";
import AmentiesSection from "./componenets/AmentiesSection";
import MainPropertyProfile from "./componenets/MainPropertyProfile";
import MainPriceOthers from "./MainPriceOthers";
import MainLocationPage from "./MainLocationPage";

const MainPostComponent = () => {
  const { postPropertyStepsStatus } = useSelector((state) => state.Property);

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return <BasicDetails />;
      case 1:
        return <MainLocationPage />;
      case 2:
        return <MainPropertyProfile />;
      case 3:
        return <PhotoDetails />;
      case 4:
        return <MainPriceOthers />;
      case 5:
        return <AmentiesSection />;
      default:
        return <BasicDetails />;
        break;
    }
  };

  return <>{renderSwitch(postPropertyStepsStatus)}</>;
  
};

export default MainPostComponent;
