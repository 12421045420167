import React from 'react'
import ViewedProjects from './ViewedProjects'
import ViewedProjects1 from './ViewedProjexts1'

const Viewed = () => {
  return (
    <>
        <div>
            <div><ViewedProjects/></div>
            <div><ViewedProjects1/></div>
        </div>
    </>
  )
}

export default Viewed