import React, { useState } from "react";
// import ToggleSwitch from "react-switch"; // Assuming you're using a React switch library for web
import Check from "react-icons/ai";
import Info from "react-icons/fi";
import { FaCircleInfo } from "react-icons/fa6";
import { IoIosCheckmark } from "react-icons/io";
import { Switch } from "@mui/material";

const Verified = () => {
  //   const [isVerified, setIsVerified] = useState(false);

  //   const handleToggle = () => {
  //     setIsVerified(!isVerified);
  //   };

  return (
    <div className="mainbarfiltercomponents">
      <div className="box">
        <div className="topbox">
          <div className="topverifiedbox">
            <div className="greenbarcolors">
              <IoIosCheckmark color="#fff" size={20} />
              <p className="verifiedgreentext">Verified</p>
            </div>
            <p className="bymgh">by MGH verification team</p>
            <FaCircleInfo color="gray" />
          </div>
        </div>
        <div
          className="propertyboxes"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Switch defaultChecked /> <p>View verified</p>
        </div>
      </div>
    </div>
  );
};

export default Verified;
