import { FaCheck } from "react-icons/fa6";
import { IoKeySharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const PostSuccess = () => {
  const { currentPost } = useSelector((store) => store.Property);

  const navigate = useNavigate();
  useEffect(() => {
    if (currentPost === "") {
      navigate("/");
    }
  }, [currentPost]);

  return (
    <>
      <div className="postSuccess-main">
        <div className="postSuccess-heading">
          <span></span>
          <div className="postSuccess-heading-title">
            <div>
              {/* <img src="/assets/images/check.png" height='40px' width='40px' alt="" /> */}
            </div>
            <div>
              <em>Congratulations!</em> Your property has been posted{" "}
            </div>
          </div>
        </div>
        <div className="property-expiry">
          <div>
            <span>ID:</span>
            {currentPost.propertyID}
          </div>
          <div>
            <span>Expiry on:</span>
            {moment(currentPost.ExpiryDate).format("DD MMMM YYYY")}
          </div>
          {/* <div>
            <span>Status:</span>Aprroved.
          </div> */}
        </div>
        <div className="property-advertise">
          <p>
            Your listing will be shown higher in search, once verified. Please
            self-verify or call us on <br />
            +91-{currentPost.postUserMobile} to verify your property
          </p>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <em style={{ textAlign: "left" }}>
            Share your property on Facebook & increase your listing validity.
          </em>
          {/* <img src="/assets/images/facebook-share.jpg"  className="facebook-logo" alt="" /> */}
        </div>
        <div className="brand-promotion">
          <h3 style={{ textAlign: "left" }}>
            Connect with the Roght Leads Instantly
          </h3>
          <div className="brand-promotion-content">
            <div className="brand-promotion-leftpanel">
              <div>Get Advantage Now</div>
              <div className="brand-promotion-btn">Get Started</div>
            </div>
            <div style={{ textAlign: "left" }} className="brand-promotion-text">
              <div>
                <FaCheck style={{ color: "green", marginRight: "15px" }} />
                <em>Smart</em>
                tool to access quality leads
              </div>
              <div>
                <FaCheck style={{ color: "green", marginRight: "15px" }} />
                <em>Exclusive</em>
                insights for personalized sales
              </div>
              <div>
                <FaCheck style={{ color: "green", marginRight: "15px" }} />
                <em>Interactive</em>
                dashboard to manage leads
              </div>
            </div>
            <div className="brand-promotion-btn" onClick={() => navigate("/")}>
              Go To Home
            </div>
          </div>
        </div>
      </div>
      <div className="postSuccess-footer">
        <div className="postSuccess-footer-heading">
          <h2>Get Your Property Verified For FREE</h2>
          <p style={{ textAlign: "center" }}>Key Benifits of verification</p>
        </div>
        <div className="postSuccess-footer-end">
          <div>
            <p className="strong-text">FREE HD photoshoot</p>
            <p> for your property.</p>
          </div>
          <div>
            <p>Exclusive</p>
            <p className="strong-text">Verified tag.</p>
          </div>
          <div>
            <p className="strong-text">Upto 5 times</p>
            <p>higher responses.</p>
          </div>
        </div>
      </div>
      <div className="propertyScore">
        <div className="propertyScore-main ">
          <div className="content-space">
            <div className="propertyScore-main-title">
              <h3>{currentPost.postUserName} why missout 3% property score?</h3>
              <p>
                Other sellers got upto 2 times more response! just by taking
                some of the steps below
              </p>
            </div>
            <div className="propertyScore-main-cntborder">
              <div className="propertyScore-main-cntborder-top">
                <IoKeySharp className="key-icon" />
                <div>Add Ownership and Approvals</div>
              </div>
              <div className="propertyScore-main-cntborder-bottom">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  +{" "}
                  <span className="percentage">
                    {Number(100) - currentPost.percentageCompletion} %
                  </span>
                </div>
                <div className="add-details">Add Details</div>
              </div>
            </div>
          </div>
        </div>
        <div className="propertyScore-footer ">
          <div className="content-space">
            <div>
              <h3>How well do you Know About Narsingi?</h3>
              <p style={{ textAlign: "center" }}>
                Post a review about the locality & share your knowledge
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostSuccess;
