import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({ percentage }) => {
    return (
        <>
            <div>
                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                        textSize: '16px',
                        fontWeight: 'bold',
                        textColor: '#000',
                        pathColor: `rgba(49, 171, 61)`,
                        trailColor: '#31ab3',
                    })}
                />
            </div>
        </>
    )
}

export default CircularProgress