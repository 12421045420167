import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

import "swiper/css";
import "swiper/css/pagination";

const InterestingReads = () => {
    return (
        <>
            <div className="interesting-reads">
                <div className='interesting-reads-head'>
                    <h1>Interesting Reads</h1>
                    <a>Read realty news, guides & articles</a>
                </div>
                <div className="interesting-reads-body">
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={0}
                        pagination={{
                            clickable: false,
                        }}
                        breakpoints={{
                            360: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            720: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },

                            1080: {
                                slidesPerView: 4,
                                spaceBetween: 0,
                            },
                        }}
                        modules={[]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/N01.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>All about the Green Line Hyderabad Metro 2023</h3>
                                            <a>Aug 18, 2023</a>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/N02.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>Red Line Metro Hyderabad: Check stations, timings & extensions in 2023</h3>
                                            <a>Aug 11, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/N03.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>Why invest in Shamshabad, Hyderabad?</h3>
                                            <a>Jul 11, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/N04.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>Top emerging localities to invest in Hyderabad</h3>
                                            <a> Jun 29, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/N05.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>Eco Nest By E Avenues: A plotted development project in Shamirpet, Secunderabad</h3>
                                            <a> Jun 23, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/N06.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>Aparna Cyber Shine: A new housing society in Osman Nagar, Hyderabad</h3>
                                            <a>Jun 22, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/N07.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>GP layouts vs HMDA land: Which is better for buying plots in Hyderabad?</h3>
                                            <a>Apr 26, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/N08.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>Sand price in Hyderabad</h3>
                                            <a>Apr 22, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/N09.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>Everything about Hyderabad Airport Metro line</h3>
                                            <a>Apr 10, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slick-slide-item">
                                <div className="">
                                    <div className="interesting-reads-item">
                                        <div className="interesting-reads-img">
                                            <img src="/assets/images/sliderImg/N10.JPG" alt="" />
                                        </div>
                                        <div className="interesting-reads-img-name">
                                            <h3>Cost of living in Hyderabad: Check out detailed list of monthly expenses</h3>
                                            <a>Apr 06, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default InterestingReads