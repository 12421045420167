import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    BiArrowBack,
} from "react-icons/bi";
import { GoAlert } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";

const UploadPhotos = () => {
    return (
        <>
            <div className="PrimaryDetailsHeader-desktopHeader">
                <div className="desktopHeader_iconInlineHead__W8_RL">
                    <div className="caption_subdued_medium">
                        <span
                            className="desktopHeader_backArrow__1lVxQ iconS_PPFDesk_24 icon_backGrey"
                            style={{ fontSize: "20px", marginRight: "5px" }}
                        >
                            <BiArrowBack />
                        </span>
                        <div style={{ fontSize: "14px" }}>Back</div>
                    </div>
                    <div className="desktopHeader_text__3iyVt">
                        <div className="undefined title_l_semiBold">
                            Add pricing and details...
                        </div>
                    </div>
                </div>
            </div>
            <div className="PricingBanner-widget Pricing-widget">
                <div className="PricingBanner_bannerWrapper__1NFLg pageComponent">
                    <div className="alertBanner_alertBanner__1EZXm alertBanner_yellowBlue__2064q   pageComponent">
                        <i className="iconS_PPFDesk_24 icon_yellowWarning alertBanner_alertIcn__2C2MJ">
                            <GoAlert />
                        </i>
                        <div className="undefined alertBanner_content__1r5zv">
                            <span className="caption_strong_large vert-align-middle">
                                You might get{" "}
                                <b style={{ fontWeight: "bold" }}>Low responses</b>,as
                                your listing has no photos. Rank up your listing by adding
                                pictures{" "}
                                <span className="hyperlinks_medium Ng800 d-inline vert-align-middle">
                                    UploadNow
                                </span>
                            </span>
                        </div>
                        <i className="iconS_Common_16 icon_close pageComponent alertBanner_closeIcon__1zCYE">
                            <AiOutlineClose />
                        </i>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UploadPhotos;
