import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsFillSearchHeartFill, BsMicFill } from "react-icons/bs";
import { BiCurrentLocation } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import { Badge } from "react-bootstrap";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { searchPost } from "../../../redux/search/SearchSlice";
import ReactSlider from "react-slider";

const RentSearchComponent = (props) => {
  const { subCatTotal } = useSelector((store) => store.SubCategory);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();

  const [showResults, setShowResults] = useState(false);
  const [toggle, setToggle] = useState(false);

  const [showSubcat, setShowSubcat] = useState([]);
  const [selectedSubcat, setSelectedSubcat] = useState([]);
  const [selectedBedrooms, setSelectedBedrooms] = useState([]);
  const [selectedPostedBy, setSelectedPostedBy] = useState([]);
  const [propertyPrice, setPropertyPrice] = useState([]);
  const [selectedFurnished, setSelectedFurnished] = useState([]);

  useEffect(() => {
    props.getSubcatDetails(selectedSubcat);
    props.getBudgetDetails(propertyPrice);
    props.getBedRoomDetails(selectedBedrooms);
    props.getPostedDetailsDetails(selectedPostedBy);
    props.getFurnishedDetails(selectedFurnished);
  }, [
    selectedSubcat,
    propertyPrice,
    selectedBedrooms,
    selectedPostedBy,
    selectedFurnished,
  ]);

  useEffect(() => {
    const filterSubcat = subCatTotal.filter(
      (data) => data.superCategory === "Residential" && data.category === "Pg"
    );
    setShowSubcat(filterSubcat);
  }, [subCatTotal]);

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);
  
  useEffect(() => {
    setSelectedSubcat([...showSubcat.map((item) => item.name)]);
  }, [showSubcat]);

  const handleSubcatPress = (evt) => {
    if (selectedSubcat.includes(evt.target.value)) {
      setSelectedSubcat(
        selectedSubcat.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setSelectedSubcat((selectedSubcat) => [
        ...selectedSubcat,
        evt.target.value,
      ]);
    }
  };

  const handleBedroomPress = (selectvalue) => {
    if (selectedBedrooms.includes(selectvalue)) {
      setSelectedBedrooms(
        selectedBedrooms.filter((value) => value !== selectvalue)
      );
    } else {
      setSelectedBedrooms([...selectedBedrooms, selectvalue]);
    }
  };
  const handleFurnishedPress = (selectvalue) => {
    if (selectedFurnished.includes(selectvalue)) {
      setSelectedFurnished(
        selectedFurnished.filter((value) => value !== selectvalue)
      );
    } else {
      setSelectedFurnished([...selectedFurnished, selectvalue]);
    }
  };
  const handlePostedByPress = (selectvalue) => {
    if (selectedPostedBy.includes(selectvalue)) {
      setSelectedPostedBy(
        selectedPostedBy.filter((value) => value !== selectvalue)
      );
    } else {
      setSelectedPostedBy([...selectedPostedBy, selectvalue]);
    }
  };

  const bhkapartments = [
    { id: "1", name: "+ 1 RK / 1 BHK" },
    { id: "2", name: "+ 2 BHK" },
    { id: "3", name: "+ 3 BHK" },
    { id: "4", name: "+ 4 BHK" },
    { id: "5", name: "+ 5 BHK" },
  ];

  const furnishingStatus = [
    { id: "1", name: "Furnishing" },
    { id: "2", name: "Semi Furnished" },
    { id: "3", name: "Un Furnishied" },
  ];
  const propertyPost = [
    { id: "1", name: "Owner" },
    { id: "2", name: "Builder" },
    { id: "3", name: "Dealer" },
  ];

  return (
    <>
      <div className="dropdownbox "  style={{paddingTop:"0"}}>
        <div className="clear-div">
          <p className="">Clear</p>
        </div>
        <div className="showsubcatbanner">
          {showSubcat.map((subcat, index) => (
            <div className="subcatBoxbann">
              <div className="childlabel" key={index}>
                <input
                  type="checkbox"
                  id="flat"
                  name="flat"
                  value={subcat.name}
                  checked={selectedSubcat.includes(subcat.name)}
                  onChange={(e) => handleSubcatPress(e, subcat.name)}
                />
                <label className="" htmlFor="flat">
                  {subcat.name}
                </label>
              </div>
            </div>
          ))}
        </div>
        <div className="label-caption">
          <p>
            Looking for commercial properties ?<a href="#">Click here</a>
          </p>
          <hr />
        </div>
        <div className="dropdown-lists">
          <div className="parent-box">
            <div
              className={
                toggle === "Budget"
                  ? "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                  : "main-boxes radioInput_textOnly__1r7GL "
              }
              onClick={() => setToggle("Budget")}
            >
              <label className="">Budget</label>
              {toggle === "Budget" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>
            {toggle === "Budget" ? (
              <>
                <div className="range-box">
                  <div className="range-head">
                    <h6 className="">Select Price Range</h6>
                  </div>
                  <div className="range-bar">
                    <label htmlFor="customRange2" className="">
                      0 - 100 Crores+
                    </label>
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="example-thumb"
                      trackClassName="example-track"
                      defaultValue={[0, 1000000000]}
                      max={1000000000}
                      min={0}
                      renderThumb={(props, state) => (
                        <div {...props}>{state.valueNow}</div>
                      )}
                      onChange={(value, index) => setPropertyPrice(value)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className="parent-box">
            <div
              className={
                toggle === "Bedroom"
                  ? "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                  : "main-boxes radioInput_textOnly__1r7GL "
              }
              onClick={() => setToggle("Bedroom")}
            >
              <label className="">Bedroom </label>
              {toggle === "Bedroom" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>

            {toggle === "Bedroom" ? (
              <>
                <div className="bedroombox mediabedroom">
                  <div className="bedroom-head">
                    <h6 className="">Number of Bedrooms</h6>
                  </div>
                  <div className="boxes-parent" style={{ marginTop: "-2px" }}>
                    {bhkapartments.map((bhk, index) => (
                      <div
                        key={index}
                        className={
                          selectedBedrooms.includes(bhk.id)
                            ? "  tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0 "
                            : "  tags-and-chips_textOnly__2k10B pageComponent "
                        }
                        onClick={() => {
                          handleBedroomPress(bhk.id);
                        }}
                      >
                        <label className=""> {bhk.name} </label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className="parent-box">
            <div
              className={
                toggle === "Posted"
                  ? "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                  : "main-boxes radioInput_textOnly__1r7GL "
              }
              onClick={() => setToggle("Posted")}
            >
              <label className="">Posted By</label>
              {toggle === "Posted" ? (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              )}
            </div>
            {toggle === "Posted" ? (
              <>
                <div className="bedroombox">
                  <div className="posted-head">
                    <h6 className="">Posted By</h6>
                  </div>
                  <div className="posted-main" style={{ marginTop: "-2px" }}>
                    {propertyPost.map((data, index) => (
                      <div
                        className={
                          selectedPostedBy.includes(data.name)
                            ? "  tags-and-chips_textOnly__2k10B pageComponent tags-and-chips_active__37Gi0 "
                            : "  tags-and-chips_textOnly__2k10B pageComponent "
                        }
                        onClick={() => {
                          handlePostedByPress(data.name);
                        }}
                      >
                        <label className="">{data.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>

          <div className="parent-box">
            <div
              className={
                toggle === "Furnishing"
                  ? "main-boxes radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                  : "main-boxes radioInput_textOnly__1r7GL "
              }
              onClick={() => setToggle("Furnishing")}
            >
              <label className="">Furnishing</label>
              {toggle === " Furnishing" ? (
                <>
                  <BsChevronDown className="arrowicon" />
                </>
              ) : (
                <>
                  <BsChevronUp className="arrowicon" />
                </>
              )}
            </div>
            {toggle === "Furnishing" ? (
              <>
                <div className="bedroombox">
                  <div className="construction-head">
                    <h6 className="">Furnishing</h6>
                  </div>
                  <div
                    className="construction-boxes"
                    style={{ marginTop: "-2px" }}
                  >
                    {furnishingStatus.map((data, index) => (
                      <div
                        key={index}
                        className={
                          selectedFurnished.includes(data.name)
                            ? "  tags-and-chips_textOnly__2k10B pageComponent  tags-and-chips_active__37Gi0 "
                            : "  tags-and-chips_textOnly__2k10B pageComponent  "
                        }
                        onClick={() => {
                          handleFurnishedPress(data.name);
                        }}
                      >
                        <label className="">{data.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>
          <div className=""></div>
        </div>
      </div>
    </>
  );
};

export default RentSearchComponent;
