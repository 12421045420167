import React from "react";
import { useSelector } from "react-redux";
import PgPriceOthersTemp from "./editPriceOthers/PgPriceOthersTemp";
import RentPricePageTemp from "./editPriceOthers/RentPricePageTemp";
import PricingandOthersTemp from "./editPriceOthers/PricingandOthersTemp";
import OfficePricingDetailsSellTemp from "./editPriceOthers/OfficePricingDetailsSellTemp";
import SellRetailPricingDetailsTemp from "./editPriceOthers/SellRetailPricingDetailsTemp";
import PlotLandPriceSellTemp from "./editPriceOthers/PlotLandPriceSellTemp";
import StoragePricingSellTemp from "./editPriceOthers/StoragePricingSellTemp";
import IndustryPricingSellTemp from "./editPriceOthers/IndustryPricingSellTemp";
import HospitalPricingsellTemp from "./editPriceOthers/HospitalPricingsellTemp";
import OfficePriceRentAndLeaseTemp from "./editPriceOthers/OfficePriceRentAndLeaseTemp";
import RetailPricingDetailsTemp from "./editPriceOthers/RetailPricingDetailsTemp";
import PlotLandPricingDetailsTemp from "./editPriceOthers/PlotLandPricingDetailsTemp";
import StoragePricingDetailsTemp from "./editPriceOthers/StoragePricingDetailsTemp";
import IndustryPricingDetailsTemp from "./editPriceOthers/IndustryPricingDetailsTemp";
import HospitalityPricingDetailsTemp from "./editPriceOthers/HospitalityPricingDetailsTemp";

const EditPriceOthersTemp = () => {
  const { BasicDetails } = useSelector((state) => state.Property);
  return (
    <>
      {BasicDetails.supercatName === "Residential" ? (
        <>
          {BasicDetails.catName === "Pg" ? (
            <>
              {/* done */}
              <PgPriceOthersTemp />
            </>
          ) : (
            <>
              {BasicDetails.catName === "Rent" ||
              BasicDetails.catName === "Lease" ? (
                <>
                  {/* done */}
                  <RentPricePageTemp />
                </>
              ) : (
                <>
                {/* done */}
                  <PricingandOthersTemp />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {BasicDetails.catName === "Sell" ? (
            <>
              {BasicDetails.subcatName === "Office" ? (
                <>
                {/* done */}
                  <OfficePricingDetailsSellTemp />
                </>
              ) : (
                <>
                  {BasicDetails.subcatName === "Retail" ? (
                    <>
                    {/* done */}
                      <SellRetailPricingDetailsTemp />
                    </>
                  ) : (
                    <>
                      {BasicDetails.subcatName === "Plot" ||
                      BasicDetails.subcatName === "Land" ? (
                        <>
                         {/* done */}
                          <PlotLandPriceSellTemp />
                        </>
                      ) : (
                        <>
                          {BasicDetails.subcatName === "Storage" ? (
                            <>
                            {/* done */}
                              <StoragePricingSellTemp />
                            </>
                          ) : (
                            <>
                              {BasicDetails.subcatName === "Industry" ? (
                                <>
                                {/* done */}
                                  <IndustryPricingSellTemp />
                                </>
                              ) : (
                                <>
                                  {BasicDetails.subcatName === "Hospitality" ? (
                                    <>
                                    {/* done */}
                                      <HospitalPricingsellTemp />
                                    </>
                                  ) : (
                                    <>
                                      <HospitalPricingsellTemp />
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {BasicDetails.subcatName === "Office" ? (
                <>
                {/* done */}
                  <OfficePriceRentAndLeaseTemp />
                </>
              ) : (
                <>
                  {BasicDetails.subcatName === "Retail" ? (
                    <>
                     {/* done */}
                      <RetailPricingDetailsTemp />
                    </>
                  ) : (
                    <>
                      {BasicDetails.subcatName === "Plot" ||
                      BasicDetails.subcatName === "Land" ? (
                        <>
                        {/* done */}
                          <PlotLandPricingDetailsTemp />
                        </>
                      ) : (
                        <>
                          {BasicDetails.subcatName === "Storage" ? (
                            <>
                              {/* done */}
                              <StoragePricingDetailsTemp />
                            </>
                          ) : (
                            <>
                              {BasicDetails.subcatName === "Industry" ? (
                                <>
                                 {/* done */}
                                  <IndustryPricingDetailsTemp />
                                </>
                              ) : (
                                <>
                                  {BasicDetails.subcatName === "Hospitality" ? (
                                    <>
                                      <HospitalityPricingDetailsTemp />
                                    </>
                                  ) : (
                                    <>
                                      <HospitalityPricingDetailsTemp />
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditPriceOthersTemp;
