import React from 'react'

const CustomerService = () => {
    return (
        <>
            <div>
                <div className='fixedHeight'>
                    <div className='sticky'>
                        <header className='bg'>
                            <div className='background-photo-container'>

                            </div>
                        </header>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerService