import React from "react";

const SimplePricing = () => {
  return (
    <>
      <section
        className="gray-bg small-padding "
        style={{ paddingTop: "100px" }}
      >
        <div className="container">
          <div className="pricing-switcher-header pricing-head">
            Subscription Types
          </div>
          {/* <div className="pricing-switcher">
            <div className="fieldset color-bg">
              <input
                type="radio"
                name="duration-1"
                id="monthly-1"
                className="tariff-toggle"
                defaultChecked
              />
              <label htmlFor="monthly-1">Monthly Tariff</label>
              <input
                type="radio"
                name="duration-1"
                className="tariff-toggle"
                id="yearly-1"
              />
              <label htmlFor="yearly-1">Yearly Tariff</label>
              <span className="switch" />
            </div>
          </div> */}
          <div className="clearfix" />
          <div className="row">
            <div className="col-md-4">
              <div className="pricing-column fl-wrap">
                <div className="pricing-header">
                  <h3>
                    <span>01.</span>Owner
                  </h3>
                  <div className="price-num price-item fl-wrap">
                    <div className="price-num-item">
                      <span className="mouth-cont">
                        <span className="curen">₹</span>0
                      </span>
                      <span className="year-cont">
                        <span className="curen">₹</span>0
                      </span>
                    </div>
                    <div className="price-num-desc">
                      <span className="mouth-cont">Per Month</span>
                      <span className="year-cont">Per Year</span>
                    </div>
                  </div>
                  <p>Lorem ipsum dolor amet, consectetur adipiscing elit.</p>
                </div>
                <div className="pricing-content fl-wrap">
                  <ul className="no-list-style">
                    <li>One Listing</li>
                    <li className="not-included">90 Days Availability</li>
                    <li className="not-included">Non-Featured</li>
                    <li className="not-included">Limited Support</li>
                  </ul>
                  <a href="#" className="price-link color-bg fl-wrap">
                    Choose Free
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="pricing-column fl-wrap">
                <div className="pricing-header">
                  <h3>
                    <span>02.</span>Dealer
                  </h3>
                  <div className="price-num price-item fl-wrap">
                    <div className="price-num-item">
                      <span className="mouth-cont">
                        <span className="curen">Creadits</span>500
                      </span>
                    </div>
                  </div>
                  <p>Lorem ipsum dolor amet, consectetur adipiscing elit.</p>
                </div>
                <div className="pricing-content fl-wrap">
                  <ul className="no-list-style">
                    <li>Unlimited Listings</li>
                    <li>220 Days Availability</li>
                    <li>Non-Featured</li>
                    <li>Limited Support</li>
                  </ul>
                  <a href="#" className="price-link color-bg fl-wrap">
                    Choose Extended
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pricing-column fl-wrap">
                <div className="pricing-header">
                  <h3>
                    <span>03.</span>Builder
                  </h3>
                  <div className="price-num price-item fl-wrap">
                    <div className="price-num-item">
                      <span className="mouth-cont">
                        <span className="curen">Creadits</span>1000
                      </span>
                    </div>
                  </div>
                  <p>Lorem ipsum dolor amet, consectetur adipiscing elit.</p>
                </div>
                <div className="pricing-content fl-wrap">
                  <ul className="no-list-style">
                    <li>Unlimited Listings</li>
                    <li>Lifetime Availability</li>
                    <li>Featured In Search Results</li>
                    <li>24/7 Support</li>
                  </ul>
                  <a href="#" className="price-link color-bg fl-wrap">
                    Choose Premium
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SimplePricing;
