import React from "react";

const Bathrooms = () => {
  const bathrooms = [
    { id: "1", name: "1+" },
    { id: "2", name: "2+" },
    { id: "3", name: "3+" },
    { id: "4", name: "4+" },
    { id: "5", name: "5+" },
  ];
  return (
    <div className="mainbarfiltercomponents">
      {bathrooms.map((bathroom, index) => (
        <div className="propertyboxes" key={index}>
          <input type="radio" />
          <p className="propertytextpara">{bathroom.name}</p>
        </div>
      ))}

      <div>
        <p className="clearalltext">Clear</p>
      </div>
    </div>
  );
};

export default Bathrooms;