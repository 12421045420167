import React, { useState } from 'react';
import { useSpring, animated } from "react-spring";

const BottomSheet = ({ isOpen, onClose, children }) => {
  const animation = useSpring({
    transform: isOpen ? "translateY(0%)" : "translateY(100%)",
  });

  return (
    <animated.div className="bottom-sheet" style={animation}>
      {children}
    </animated.div>
  );
};

export default BottomSheet;
