import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl, Baseurl2 } from "../../config/BaseUrl";

const initialState = {
  propertyAll: [],
  propertyAllById: [],

  sellProperty: [],
  rentProperty: [],
  leaseProperty: [],
  pgProperty: [],

  desktopimage: [],
  readytomovestatus: "",
  currentPost: "",
  userId: "",
  propertySearchBySelectionCat: "",

  propertyScooring: 0,

  basicdetailScooring: 0,
  LocalityScooring: 0,
  mainpropertyScooring: 0,
  photoScooring: 0,
  pricingScooring: 0,
  aminitiesScooring: 0,

  pageStatus: 0,
  listpageStatus: 0,

  postPropertyStepsStatus: 0,
  postPropertyStepsData: localStorage.getItem("postPropertyStepsData")
    ? JSON.parse(localStorage.getItem("postPropertyStepsData"))
    : "",
  BasicDetails: localStorage.getItem("BasicDetails")
    ? JSON.parse(localStorage.getItem("BasicDetails"))
    : "",
  LocationDetails: localStorage.getItem("LocationDetails")
    ? JSON.parse(localStorage.getItem("LocationDetails"))
    : "",
  PropertyProfile: localStorage.getItem("PropertyProfile")
    ? JSON.parse(localStorage.getItem("PropertyProfile"))
    : "",
  PhotoDetails: localStorage.getItem("PhotoDetails")
    ? JSON.parse(localStorage.getItem("PhotoDetails"))
    : [],
  PriceotherDetails: localStorage.getItem("PriceotherDetails")
    ? JSON.parse(localStorage.getItem("PriceotherDetails"))
    : "",
  AmentiesDetails: localStorage.getItem("AmentiesDetails")
    ? JSON.parse(localStorage.getItem("AmentiesDetails"))
    : "",
  singleProperty: localStorage.getItem("singleProperty")
    ? JSON.parse(localStorage.getItem("singleProperty"))
    : "",
  editPriceDetails: "",
  singlePropertyResponse: "",
  propertyInfoSingleProperty: "",
  propertyInfoSingleCheck: false,

  isLoading: true,
  propertybyIdLoading: true,
  isPropertyLoading: false,
  ispropertydeskimageLoading: true,
};
//get property
export const getProperty = createAsyncThunk(
  "Property/getProperty",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl2}/api/v1/property/all`;
      const resp = await axios(url);
      return resp.data.properties;
    } catch (error) {
      return thunkAPI.rejectWithValue("supercategories Not Found");
    }
  }
);

export const recommendedPropertyByCity = createAsyncThunk(
  "Property/recommendedPropertyByCity",
  async (city, thunkAPI) => {
    let resp = {
      success: false,
      message: "new city",
    };
    try {
      const url = `${Baseurl}/api/v1/property/recommended/${city}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);
//get propertybyId
export const getPropertybyId = createAsyncThunk(
  "Property/propertybyId",
  async (id, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/property/propertyby/${id}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("user Not Found");
    }
  }
);

//post property images
export const propertyImages = createAsyncThunk(
  "property/propertyImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/property/propertyimages`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("property Not create");
    }
  }
);

export const propertyPost = createAsyncThunk(
  "property/propertyPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/property/new`;

      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("property Not create");
    }
  }
);

//property update
export const propertyUpdate = createAsyncThunk(
  "Property/propertyUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const url = `${Baseurl}/api/v1/property/updateby/${formData.propertyId}`;

      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Property Not Updated");
    }
  }
);

const PropertySlice = createSlice({
  name: "Property",
  initialState,
  reducers: {
    updateDesktopImages(state, action) {
      state.desktopimage = action.payload;
      state.ispropertydeskimageLoading = false;
    },
    pageStatusChange(state, action) {
      state.pageStatus = action.payload;
    },

    setpropertyScooring(state, action) {
      state.propertyScooring =
        state.basicdetailScooring +
        state.LocalityScooring +
        state.mainpropertyScooring +
        state.photoScooring +
        state.pricingScooring +
        state.aminitiesScooring;
    },
    setPropertySearchBySelectionCat(state, action) {
      state.propertySearchBySelectionCat = action.payload;
    },
    setbasicdetailScooring(state, action) {
      state.basicdetailScooring = action.payload;
    },
    setLocalityScooring(state, action) {
      state.LocalityScooring = action.payload;
    },
    setmainpropertyScooring(state, action) {
      state.mainpropertyScooring = action.payload;
    },
    setphotoScooring(state, action) {
      state.photoScooring = action.payload;
    },
    setpricingScooring(state, action) {
      state.pricingScooring = action.payload;
    },
    setaminitiesScooring(state, action) {
      state.aminitiesScooring = action.payload;
    },
    desktopimageremove(state, action) {
      state.desktopimage = [];
    },
    listPageStatusChange(state, action) {
      state.listpageStatus = action.payload;
    },
    setcurrentPost(state, action) {
      state.currentPost = action.payload;
    },
    setPostPropertyStepsStatus(state, action) {
      state.postPropertyStepsStatus = action.payload;
    },
    setpostPropertyStepsData(state, action) {
      state.postPropertyStepsData = action.payload;
      localStorage.setItem(
        "postPropertyStepsData",
        JSON.stringify(state.postPropertyStepsData)
      );
    },
    setBasicDetails(state, action) {
      state.BasicDetails = action.payload;
      localStorage.setItem("BasicDetails", JSON.stringify(state.BasicDetails));
    },
    setLocationDetails(state, action) {
      state.LocationDetails = action.payload;
      localStorage.setItem(
        "LocationDetails",
        JSON.stringify(state.LocationDetails)
      );
    },
    setPropertyProfile(state, action) {
      state.PropertyProfile = action.payload;
      localStorage.setItem(
        "PropertyProfile",
        JSON.stringify(state.PropertyProfile)
      );
    },
    setPhotoDetails(state, action) {
      state.PhotoDetails = action.payload;
      localStorage.setItem("PhotoDetails", JSON.stringify(state.PhotoDetails));
    },

    setPriceotherDetails(state, action) {
      state.PriceotherDetails = action.payload;
      localStorage.setItem(
        "PriceotherDetails",
        JSON.stringify(state.PriceotherDetails)
      );
    },
    setAmentiesDetails(state, action) {
      state.AmentiesDetails = action.payload;
      localStorage.setItem(
        "AmentiesDetails",
        JSON.stringify(state.AmentiesDetails)
      );
    },
    setsingleProperty(state, action) {
      state.singleProperty = action.payload;
      localStorage.setItem(
        "singleProperty",
        JSON.stringify(state.singleProperty)
      );
    },
    setReadytomoveStatus(state, action) {
      state.readytomovestatus = action.payload;
    },
    setuserId(state, action) {
      state.userId = action.payload;
    },
    setuserIdRemove(state, action) {
      state.userId = "";
    },
    seteditPriceDetails(state, action) {
      state.editPriceDetails = action.payload;
    },
    setSinglePropertyResponse(state, action) {
      state.singlePropertyResponse = action.payload;
    },
    setPropertyInfoSingleProperty(state, action) {
      state.propertyInfoSingleProperty = action.payload;
    },
    setPropertyInfoSinglePropertyCheck(state, action) {
      state.propertyInfoSingleCheck = action.payload;
    },
  },
  extraReducers: {
    //get all properties
    [getProperty.pending]: (state) => {
      state.isLoading = true;
    },
    [getProperty.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.propertyAll = action.payload;
      state.propertyAll = state.propertyAll.filter(
        (property) => property.userId !== state.userId
      );

      state.sellProperty = state.propertyAll.filter(
        (sell) => sell.category === "Sell"
      );
      state.rentProperty = state.propertyAll.filter(
        (sell) => sell.category === "Rent"
      );
      state.leaseProperty = state.propertyAll.filter(
        (sell) => sell.category === "Lease"
      );
      state.pgProperty = state.propertyAll.filter(
        (sell) => sell.category === "Pg"
      );
    },
    [getProperty.rejected]: (state, action) => {
      state.isLoading = true;
    },
    // get property by Id
    [getPropertybyId.pending]: (state) => {
      state.propertybyIdLoading = true;
    },
    [getPropertybyId.fulfilled]: (state, action) => {
      state.propertybyIdLoading = false;
      state.propertyAllById = action.payload.property;
    },
    [getPropertybyId.rejected]: (state) => {
      state.propertybyIdLoading = true;
    },

    //post property images
    [propertyImages.pending]: (state) => {
      state.ispropertydeskimageLoading = true;
    },
    [propertyImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.desktopimage = [
          ...state.desktopimage,
          action.payload.desktopImages,
        ];
      }
      state.ispropertydeskimageLoading = false;
    },
    [propertyImages.rejected]: (state) => {
      state.ispropertydeskimageLoading = true;
    },
    //post property field
    [propertyPost.pending]: (state) => {
      state.isPropertyLoading = true;
    },

    [propertyPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.propertyAll = [...state.propertyAll, action.payload.property];
        state.propertyAll = state.propertyAll.filter(
          (property) => property.userId !== state.userId
        );
      }
      state.isPropertyLoading = false;
    },

    [propertyPost.rejected]: (state, action) => {
      state.isPropertyLoading = true;
    },

    //property upadted
    [propertyUpdate.pending]: (state) => {
      state.isPropertyLoading = true;
    },

    [propertyUpdate.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.propertyAll = state.propertyAll.filter(
          (property) => property._id !== action.payload.property._id
        );
        state.propertyAll = [...state.propertyAll, action.payload.property];
        state.propertyAll = state.propertyAll.filter(
          (property) => property.userId !== state.userId
        );
      }
      state.isPropertyLoading = false;
    },

    [propertyUpdate.rejected]: (state, action) => {
      state.isPropertyLoading = true;
    },
  },
});
export const {
  updateDesktopImages,
  pageStatusChange,
  listPageStatusChange,
  setPostPropertyStepsStatus,
  setpostPropertyStepsData,
  setBasicDetails,
  setLocationDetails,
  setPropertyProfile,
  setPhotoDetails,
  setPriceotherDetails,
  setAmentiesDetails,
  setReadytomoveStatus,
  setuserId,
  setuserIdRemove,
  desktopimageremove,
  setcurrentPost,
  setpropertyScooring,
  setPropertySearchBySelectionCat,
  setbasicdetailScooring,
  setLocalityScooring,
  setmainpropertyScooring,
  setphotoScooring,
  setpricingScooring,
  setaminitiesScooring,
  setsingleProperty,
  seteditPriceDetails,
  setSinglePropertyResponse,
  setPropertyInfoSingleProperty,
  setPropertyInfoSinglePropertyCheck,
} = PropertySlice.actions;
export default PropertySlice.reducer;
