import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { FcPhoneAndroid, FcInfo, FcIdea } from "react-icons/fc";
import { GrMail } from "react-icons/gr";
import { MdDoNotDisturbAlt } from "react-icons/md";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ResponseModel from "./responseModel/ResponseModel";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { authActions } from "../../../redux/athentication/Athentication";
import { toast } from "react-toastify";
import { viewdStatuschange } from "../../../redux/userViewdProperty/UserViewdPropertySlice";
import { propertyUpdate } from "../../../redux/property/PropertySlice";
import { IoStar } from "react-icons/io5";
import { IoStarOutline } from "react-icons/io5";
import ResponsesNewModal from "./propertyReCalmodel/ResponsesNewModal";

const AllResponse = () => {
  const { loginData } = useSelector((store) => store.Athentication);

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [singleProperty, setSingleProperty] = useState("");
  const [response, setResponse] = useState([]);
  const [temploading, setTemploading] = useState(true);
  const toggle = () => setModal(!modal);

  const closePropDetailModal = () => {
    setModal(false);
  };

  useEffect(() => {
    try {
      const fetchdata = async () => {
        const url = `${Baseurl}/api/v1/viewed/responsebypostuser/${loginData._id}`;
        const fetchResponse = await axios(url);
        if (fetchResponse.data.success === true) {
          let singleResponse = fetchResponse.data.userResponseByPostUser;
          setResponse(singleResponse);
          setTemploading(false);
        }
      };
      fetchdata();
    } catch (error) {}
  }, []);

  const viewResponseClick = async (e, property) => {
    const propertyVerifyData = {
      propertyId: property.propertyId,
      postUserId: loginData._id,
    };
    const config = {
      Headers: { "Content-Type": "application/json" },
    };
    const verifyurl = `${Baseurl}/api/v1/viewed/viewedpropertyverifynew`;
    const propertyVerify = await axios.post(
      verifyurl,
      propertyVerifyData,
      config
    );
    try {
      if (propertyVerify.data.success === true) {
        if (propertyVerify.data.userViewedProperty.length > 0) {
          const propertyformdata = {
            id: property._id,
            leadsView: true,
          };

          const viewupdateurl = `${Baseurl}/api/v1/viewed/update/${propertyformdata.id}`;
          let fetchData = await axios.put(viewupdateurl, propertyformdata);
          if (fetchData.data.success === true) {
            let updateview = response;
            updateview = updateview.map((data) => {
              if (data._id === property._id) {
                data.leadsView = true;
              }

              return data;
            });
            setResponse(updateview);
          }
        } else {
          const formdata = {
            mobile: loginData.mobile,
            creadittospent: 1,
          };
          const url = `${Baseurl}/api/v1/user/shopUserByMobile`;
          let fetchData = await axios.put(url, formdata);
          try {
            const authenticForm = {
              ...fetchData.data.user,
              isAuth: true,
            };
            dispatch(authActions.signIn(authenticForm));
            dispatch(viewdStatuschange(property));
            const propertyformdata = {
              id: property._id,
              leadsView: true,
            };

            const viewupdateurl = `${Baseurl}/api/v1/viewed/update/${propertyformdata.id}`;
            let fetchData = await axios.put(viewupdateurl, propertyformdata);

            if (fetchData.data.success === true) {
              let updateview = response;
              updateview = updateview.map((data) => {
                if (data._id === property._id) {
                  data.leadsView = true;
                }

                return data;
              });
              setResponse(updateview);
            } else {
              toast.error("Please Recharge on Wallet", {
                position: "top-center",
                autoClose: 1000,
              });
            }
          } catch (error) {}
        }
      } else {
      }
    } catch (error) {
      const formdata = {
        mobile: loginData.mobile,
        creadittospent: 1,
      };
      const url = `${Baseurl}/api/v1/user/shopUserByMobile`;
      let fetchData = await axios.put(url, formdata);
      try {
        const authenticForm = {
          ...fetchData.data.user,
          isAuth: true,
        };
        dispatch(authActions.signIn(authenticForm));
      } catch (error) {}
      if (fetchData.data.success) {
        setModal(!modal);
        setSingleProperty(property);
      } else {
        toast.error("Please Recharge on Wallet", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    }
  };

  return (
    <>
      {temploading ? (
        <></>
      ) : (
        <>
          <div className="component__allListingInfo">
            <div className="component__listingCount">
              {response.length > 0 && response.length <= 1 ? (
                <>
                  <span>{response.length}</span> Property
                </>
              ) : (
                <>
                  <span>{response.length}</span> Properties
                </>
              )}
            </div>
          </div>
          <div className="false component__premiumFilters">
            <span className="component__mg_ryt_20">Showing In:</span>
            <div className="tags-and-chips__textOnly undefined  tags-and-chips__active pageComponent tags-and-chips__mr2 pageComponent">
              <span className="tags-and-chips__badgeParent "></span>
              <span>ALL</span>
              <i className="iconS_Common_20 icon_closeBlue ">
                <AiOutlineClose />
              </i>
            </div>
            <label className="component__clearAll">Clear All Filters</label>
          </div>
          <div
            className="d-flex justify-content-end align-items-end mb-2"
            style={{ position: "relative", textAlign: "right" }}
          >
            <button
              className="btn btn-secondary"
              onClick={() => setModal(!modal)}
              style={{ background: "#3270fc", border: "1px solid #3270fc" }}
            >
              Download Responses
            </button>
          </div>
          {modal === true ? (
            <>
              <div className="modalopen">
                <ResponsesNewModal response={response} toggle={toggle} />
              </div>
            </>
          ) : (
            <></>
          )}

          {response.length > 0 ? (
            <>
              {response.map((view, index) => (
                <div className="card-blue-main" key={index}>
                  <div className="row">
                    <div className="col-4 col-md-4 col-sm-12 col-xs-12 firstboxres">
                      <p className="responsesone-text">
                        <span style={{ fontWeight: "500" }}>
                          {view.userName} ({view.propertyName})
                        </span>{" "}
                      </p>

                      <p
                        className="responsesone-text"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <span>+91-{view.userMobile} |</span>{" "}
                        <img
                          src="../assets/images/correct.png"
                          alt="caution"
                          style={{
                            width: "15px",
                            height: "15px",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        />
                        <span
                          style={{
                            color: "#207faf",
                            fontWeight: "500",
                          }}
                        >
                          {view.userEmail}
                        </span>
                      </p>
                      {/* </div> */}
                    </div>
                    <div className="col-4 col-md-4 col-sm-12 col-xs-12 secondboxres">
                      <p
                        className="responsesone-text align-items-center"
                        style={{ display: "flex" }}
                      >
                        <div>
                          <span style={{ fontWeight: "500" }}>3.0</span>
                        </div>
                        <div className="ms-2 me-2">
                          <IoStar color="#f6af39" size={18} />
                          <IoStar color="#f6af39" size={18} />
                          <IoStar color="#f6af39" size={18} />
                          <IoStarOutline color="#f6af39" size={18} />
                          <IoStarOutline color="#f6af39" size={18} />
                        </div>
                        <div>
                          <span style={{ fontWeight: "500" }}>
                            View Behaviour
                          </span>
                        </div>
                      </p>
                      <p className="responsesone-text">
                        <span style={{ fontWeight: "500" }}>
                          Viewed Contact
                        </span>{" "}
                        on {moment(view.createdAt).format("DD MMM YYYY")}
                      </p>
                    </div>
                    <div className="col-4 col-md-4 col-sm-12 col-xs-12 thirdboxres">
                      <p className="responsesone-text">
                        <span
                          style={{
                            fontWeight: "500",
                          }}
                        >
                          {view.propertyId.slice(0, 8)}:{" "}
                        </span>
                        <span
                          style={{
                            color: "#207faf",
                            fontWeight: "500",
                          }}
                        >
                          {view.propertyStatus}
                        </span>
                      </p>
                      {view.noofBedrooms > 1 ? (
                        <>
                          <p className="responsesone-text">
                            Rs.
                            {view.price +
                              ", " +
                              view.noofBedrooms +
                              " Beds for " +
                              view.category +
                              " in " +
                              view.propertyStatus}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="responsesone-text">
                            Rs.
                            {view.price +
                              ", " +
                              view.noofBedrooms +
                              " Bed for " +
                              view.category +
                              " in " +
                              view.propertyStatus}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div className="component__responceWrapper">
                <div className="pageComponent"></div>
                <div className="fslBooking__noResult">
                  <h1>Sorry, No Results !</h1>
                  <p>We cannot find the item you are searching for.</p>
                  <div className="fslBooking__noResults"></div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AllResponse;
