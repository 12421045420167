
import React, { useEffect, useState } from "react";
import { BiArrowBack, BiPlus, BiMinus, BiInfoCircle } from "react-icons/bi";
import { GrCircleQuestion } from "react-icons/gr";
import { Tooltip } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setPostPropertyStepsStatus, setPropertyProfile } from "../../../../redux/property/PropertySlice";
import AddroomHospitalTemp from "../commonComponent/AddroomHospitalTemp";
import AddAreaDetailsTemp from "../commonComponent/AddAreaDetailsTemp";
import AvaliabilityStatusTemp from "../commonComponent/AvaliabilityStatusTemp";
import QualityOfRatingTemp from "../commonComponent/QualityOfRatingTemp";

const HospitalityPropertyDetailsTemp = () => {
  const { PropertyProfile, BasicDetails,singleProperty } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();

  const [noofrooms, setNoofrooms] = useState(singleProperty.noofBedrooms);
  const [addmoreBedroomsvalue, setAddmoreBedroomsValue] = useState("");
  const [addmoreBedroomsShow, setAddmoreBedroomsShow] = useState(false);

  const [noofBathrooms, setNoofBathrooms] = useState(singleProperty.noofBathrooms);
  const [addmoreBathroomsvalue, setAddmoreBathroomsValue] = useState("");
  const [addmoreBathroomsShow, setAddmoreBathroomsShow] = useState(false);

  const [carpetArea, setCarpetArea] = useState(singleProperty.carpetArea);
  const [typeofCarpetArea, setTypeofCarpetArea] = useState(singleProperty.carpetAreaMesurmentType);
  const [typeofCarpetAreaShow, setTypeofCarpetAreaShow] = useState(false);

  const [builtupArea, setBuiltupArea] = useState(singleProperty.buildupArea);
  const [typeofBuiltupArea, setTypeofBuiltupArea] = useState(singleProperty.buildupAreaMesurmentType);
  const [typeofBuiltupAreaShow, setTypeofBuiltupAreaShow] = useState(false);
  const [typeofBuiltupAreaBox, setTypeofBuiltupAreaBox] = useState(false);

  const [availableDate, setavailableDate] = useState("");

  const [superBuiltupArea, setSuperBuiltupArea] = useState(singleProperty.superbuildupArea);
  const [typeofSuperBuiltupArea, setTypeofSuperBuiltupArea] =
    useState(singleProperty.superbuildupAreaMesurmentType);
  const [typeofSuperBuiltupAreaShow, setTypeofSuperBuiltupAreaShow] =
    useState(false);
  const [typeofSuperBuiltupAreaBox, setTypeofSuperBuiltupAreaBox] =
    useState(false);

  const [readyToMove, setReadyToMove] = useState(false);
  const [underConstruction, setUnderConstruction] = useState(false);
  const [abilityStatus, setAbilityStatus] = useState(false);

  const [ageofProperty, setAgeofProperty] = useState(singleProperty.propertyAge);

  const [expectedby, setExpectedby] = useState(singleProperty.propertyPossessionBy);
  const [expectedbyShow, setExpectedbyShow] = useState(false);
  const [yearofExpectedBy, setYearofExpectedBy] = useState("");

  const [noofRating, setNoofRating] = useState(singleProperty.noofRating);
  
  //errors
  const [roomDetailsError, setRoomDetailsError] = useState("");
  const [noofRoomsError, setNoofRoomsError] = useState("");
  const [washroomError, setWashroomError] = useState("");

  const [areaDetailsError, setAreaDetailsError] = useState("");
  const [abilityError, setAbilityError] = useState("");

  const [abilityStatusError, setAbilityStatusError] = useState("");

  const [BedroomsError, setBedroomsError] = useState("");
  const [BathroomsError, setBathroomsError] = useState("");

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [availabilityError, setavailabilityError] = useState("");
  const [selectAgeError, setSelectedAgeError] = useState("");
  const [timePossessionError, settimePossessionError] = useState("");
  const [qualityError, setQualityError] = useState("");

  const carpetAreaChange = (e) => {
    const result = e;
    setCarpetArea(result);
  };

  const builtupChange = (e) => {
    const result = e;
    setBuiltupArea(result);
  };

  const superbuiltupChange = (e) => {
    const result = e;
    setSuperBuiltupArea(result);
  };

  const carpetAreaMesurment = (e) => {
    const result = e;
    setTypeofCarpetArea(result);
  };

  const builtupMesurment = (e) => {
    const result = e;
    setTypeofBuiltupArea(result);
  };
  const superbuiltupMesurment = (e) => {
    const result = e;
    setTypeofSuperBuiltupArea(result);
  };

  const getPropertyAvailability = (value) => {
    setAbilityStatus(value);
  };
  const getPropertyAge = (value) => {
    setAgeofProperty(value);
  };
  const getPropertyExpectedYear = (value) => {
    setYearofExpectedBy(value);
  };
  const getPropertyExpectedMonth = (value) => {
    setExpectedby(value);
  };
  const constructionOnProperty = (e) => {
    const result = e;
    setUnderConstruction(result);
  };
  const availableFromDate = (e) => {
    const result = e;
    setavailableDate(result);
  };

  const numberOfRooms = (e) => {
    const result = e;
    setNoofrooms(result);
  };
  const numberOfBathroom = (e) => {
    const result = e;
    setNoofBathrooms(result);
  };

  const addMoreBedroom = (e) => {
    const result = e;
    setAddmoreBedroomsValue(result);
  };
  const addMoreBedroomShow = (e) => {
    const result = e;
    setAddmoreBedroomsShow(result);
  };
  const addMoreBedroomValue = (e) => {
    const result = e;
    setAddmoreBedroomsValue(result);
  };
  const addmoreBathrooms = (e) => {
    const result = e;
    setNoofBathrooms(result);
  };
  const addMoreBathroomValue = (e) => {
    const result = e;
    setAddmoreBathroomsValue(result);
  };
  const addMoreBathroomShow = (e) => {
    const result = e;
    setAddmoreBathroomsShow(result);
  };

  const noOfRating = (e) => {
    const result = e;
    setNoofRating(result);
  };

  useEffect(() => {
    if (PropertyProfile !== "") {
      setNoofrooms(PropertyProfile.noofrooms);
      setAddmoreBedroomsValue(PropertyProfile.addmoreBedroomsvalue);
      setAddmoreBedroomsShow(PropertyProfile.addmoreBedroomsShow);

      setNoofBathrooms(PropertyProfile.noofBathrooms);
      setAddmoreBathroomsValue(PropertyProfile.addmoreBathroomsvalue);
      setAddmoreBathroomsShow(PropertyProfile.addmoreBathroomsShow);

      setCarpetArea(PropertyProfile.carpetArea);
      setTypeofCarpetArea(PropertyProfile.typeofCarpetArea);
      setTypeofCarpetAreaShow(PropertyProfile.typeofCarpetAreaShow);

      setBuiltupArea(PropertyProfile.builtupArea);
      setTypeofBuiltupArea(PropertyProfile.typeofBuiltupArea);
      setTypeofBuiltupAreaShow(PropertyProfile.typeofBuiltupAreaShow);
      setTypeofBuiltupAreaBox(PropertyProfile.typeofBuiltupAreaBox);

      setSuperBuiltupArea(PropertyProfile.superBuiltupArea);
      setTypeofSuperBuiltupArea(PropertyProfile.typeofSuperBuiltupArea);
      setTypeofSuperBuiltupAreaShow(PropertyProfile.typeofSuperBuiltupAreaShow);
      setTypeofSuperBuiltupAreaBox(PropertyProfile.typeofSuperBuiltupAreaBox);

      setReadyToMove(PropertyProfile.readyToMove);
      setUnderConstruction(PropertyProfile.underConstruction);

      setAgeofProperty(PropertyProfile.ageofProperty);

      setExpectedby(PropertyProfile.expectedby);
      setExpectedbyShow(PropertyProfile.expectedbyShow);

      setNoofRating(PropertyProfile.noofRating);
    }
  }, [PropertyProfile]);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    if (noofrooms !== "" && noofBathrooms !== "") {
      setRoomDetailsError("");
    }
    if (noofrooms !== "") {
      setNoofRoomsError("");
    }
    if (noofBathrooms !== "") {
      setWashroomError("");
    }
    if (carpetArea !== "") {
      setAreaDetailsError("");
    }
    if (ageofProperty !== "" || expectedby !== "") {
      setavailabilityError("");
      setSelectedAgeError("");
      settimePossessionError("");
    }
    if (noofRating !== "") {
      setQualityError("");
    }
  }, [
    noofrooms,
    noofBathrooms,
    carpetArea,
    ageofProperty,
    expectedby,
    noofRating,
  ]);

  const propertyProfileClick = async (e) => {
    e.preventDefault();
    setRoomDetailsError("");
    setNoofRoomsError("");
    setWashroomError("");
    setAreaDetailsError("");
    setavailabilityError("");
    setSelectedAgeError("");
    settimePossessionError("");
    setQualityError("");

    if (noofrooms !== "" && noofBathrooms !== "") {
      setRoomDetailsError("");
    } else {
      setRoomDetailsError("All fields are required");
    }
    if (noofrooms !== "") {
      setNoofRoomsError("");
    } else {
      setNoofRoomsError("Please Select Your Room Type");
    }
    if (noofBathrooms !== "") {
      setWashroomError("");
    } else {
      setWashroomError("Please Select Your Room Type");
    }
    if (carpetArea !== "") {
      setAreaDetailsError("");
    } else {
      setAreaDetailsError("Please Enter area details");
    }
    if (ageofProperty !== "" || expectedby !== "") {
      setavailabilityError("");
      setSelectedAgeError("");
      settimePossessionError("");
    } else {
      setavailabilityError("Selact Availibility Status ");
      setSelectedAgeError("Select Property Age");
      settimePossessionError("Select Expected Date");
    }
    if (noofRating !== "") {
      setQualityError("");
    } else {
      setQualityError("Please Select Rating");
    }

    if (
      noofrooms !== "" &&
      noofBathrooms !== "" &&
      carpetArea !== "" &&
      abilityStatus !== "" &&
      noofRating !== ""
    ) {
      const propertyform = {
        noofBedrooms: noofrooms,
        addmoreBedroomsvalue: addmoreBedroomsvalue,
        addmoreBedroomsShow: addmoreBedroomsShow,

        noofBathrooms: noofBathrooms,
        addmoreBathroomsvalue: addmoreBathroomsvalue,
        addmoreBathroomsShow: addmoreBathroomsShow,

        carpetArea: carpetArea,
        typeofCarpetArea: typeofCarpetArea,
        typeofCarpetAreaShow: typeofCarpetAreaShow,

        builtupArea: builtupArea,
        typeofBuiltupArea: typeofBuiltupArea,
        typeofBuiltupAreaShow: typeofBuiltupAreaShow,
        typeofBuiltupAreaBox: typeofBuiltupAreaBox,

        superBuiltupArea: superBuiltupArea,
        typeofSuperBuiltupArea: typeofSuperBuiltupArea,
        typeofSuperBuiltupAreaShow: typeofSuperBuiltupAreaShow,
        typeofSuperBuiltupAreaBox: typeofSuperBuiltupAreaBox,

        readyToMove: abilityStatus,
        underConstruction: underConstruction,

        ageofProperty: ageofProperty,

        expectedby: expectedby,
        expectedbyShow: expectedbyShow,

        noofRating: noofRating,
      };
      dispatch(setPropertyProfile(propertyform));
      dispatch(setPostPropertyStepsStatus(3));
    }
  };

  return (
    <>
      {/* <HeaderNew /> */}
      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA">
          <div className="page_ppf_step1__1icHe  pageComponent">
            <div className="PrimaryDetailsHeader-desktopHeader">
              <div className="desktopHeader_iconInlineHead__W8_RL">
                <div className="caption_subdued_medium">
                  <span
                    className="desktopHeader_backArrow__1lVxQ iconS_PPFDesk_24 icon_backGrey"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  >
                    <BiArrowBack />
                  </span>
                  <div style={{ fontSize: "14px" }}>Back</div>
                </div>
                <div className="desktopHeader_text__3iyVt">
                  <div className="undefined title_l_semiBold">
                    Tell us about your property
                  </div>
                </div>
              </div>
              <div className="FloorPlans-widget"></div>
              <div className="Configuration-widget">
                <AddroomHospitalTemp
                  numberOfRooms={numberOfRooms}
                  addMoreBedroom={addMoreBedroom}
                  addMoreBedroomShow={addMoreBedroomShow}
                  addMoreBedroomValue={addMoreBedroomValue}
                  numberOfBathroom={numberOfBathroom}
                  addmoreBathrooms={addmoreBathrooms}
                  addMoreBathroomValue={addMoreBathroomValue}
                  addMoreBathroomShow={addMoreBathroomShow}
                  roomDetailsError={roomDetailsError}
                  noofRoomsError={noofRoomsError}
                  noofWashroomsError={washroomError}
                />

                <AddAreaDetailsTemp
                  carpetAreaChange={carpetAreaChange}
                  builtupChange={builtupChange}
                  superbuiltupChange={superbuiltupChange}
                  carpetAreaMesurment={carpetAreaMesurment}
                  builtupMesurment={builtupMesurment}
                  superbuiltupMesurment={superbuiltupMesurment}
                  areaDetailsError={areaDetailsError}
                />

                <AvaliabilityStatusTemp
                  getPropertyAvailability={getPropertyAvailability}
                  getPropertyAge={getPropertyAge}
                  abilityStatusError={availabilityError}
                  selectAgeError={selectAgeError}
                  timePossessionError={timePossessionError}
                  getPropertyExpectedYear={getPropertyExpectedYear}
                  getPropertyExpectedMonth={getPropertyExpectedMonth}
                  constructionOnProperty={constructionOnProperty}
                />

                <QualityOfRatingTemp
                  noOfRating={noOfRating}
                  qualityRatingError={qualityError}
                />
              </div>
            </div>
            <div id="Location-widget">
              <div id="PrimaryDetailsButtonsWrapper-widget">
                <div className="dummyFormWidget_flexContainer__2vVMv">
                  <div
                    id="PrimaryDetailsButton-button"
                    className="pageComponent"
                  >
                    <div>
                      <div className="buttonInput_continue_btn__1gb5M ">
                        <button
                          type="button"
                          className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                          onClick={(e) => propertyProfileClick(e)}
                        >
                          <span>
                            <span>Continue</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div id='rightSection' className='rightSection_rightSection__36zjv' style={{ minHeight: "calc(100vh - 54px)" }}>
                    <div className='needHelp_helpBox__1b_kG' id='helpBox'>
                        <div className='needHelp_needHelpWrap__1eIlT'>
                            <div className='needHelp_helpHead__2mtNV'>
                                <i className='iconS_PPFDesk_16 icon_callIcon'><IoMdCall /></i>
                                <div className='caption_subdued_large'>Need help ? </div>
                            </div>
                            <div className='caption_subdued_medium'>
                                You can email us at
                                <a className='hyperlinks_small'> service@mygoldenhomes.com </a>
                                or call us at
                                <a className='hyperlinks_small'> 1800 41 99099</a>
                                (IND Toll-Free).
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    </>
  );
};

export default HospitalityPropertyDetailsTemp;
