import React, { useState } from 'react'

const BoostModel = () => {

    return (
        <>
            <div className='boost_component__modal_window_homeLoan component__settings component__popCSS'>
                <div className='boost_component__mwBody component__settings'>
                    <div className='boost_pageComponent dashboard__content'>
                        <div className='boost_dashboard__content'>
                            <h4>Are you sure to boost Property?</h4>
                            <div className='boost_dashboard__buttonWrapper' style={{ display: "flex", marginTop: "30px", justifyContent: "center" }}>
                                <div className='boost_dashboard__ngdialogButtonPrimary boost_dashboard__ngdialogButton' style={{ width: '30%', marginRight: "30%", textAlign: "center", borderRadius: "5px" }}>
                                    <span><input type="button" value="Cancel" /></span>
                                </div>
                                <div className='boost_dashboard__ngdialogButtonSecondary boost_dashboard__ngdialogButton' style={{ width: '30%', textAlign: "center", borderRadius: "5px" }}>
                                    <span><input type="button" value="Boost" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BoostModel