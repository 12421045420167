import React from "react";

const PurchaseType = () => {
  const purchasetype = [
    { id: "1", name: "Resale" },
    { id: "2", name: "New Booking" },
  ];
  return (
    <div className="mainbarfiltercomponents">
      {purchasetype.map((purchase, index) => (
        <div className="propertyboxes" key={index}>
          <input type="checkbox" />
          <p className="propertytextpara">{purchase.name}</p>
        </div>
      ))}

      <div>
        <p className="clearalltext">Select All</p>
      </div>
    </div>
  );
};

export default PurchaseType;