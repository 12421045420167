import React from "react";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Baseurl } from "../../../config/BaseUrl";
import { authActions } from "../../../redux/athentication/Athentication";
import { propertyUpdate } from "../../../redux/property/PropertySlice";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function BoostListingStep3({
  boostToggle,
  setChangeIndexValue,
  setselectBoostValue,
  selectBoost,
  singlePropertyId,
}) {
  const { loginData } = useSelector((store) => store.Athentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const BoostClick = async (e) => {
    const formdata = {
      PostCreadits: loginData.PostCreadits - Number(selectBoost),
    };
    const url = `${Baseurl}/api/v1/user/updateuser/${loginData._id}`;
    let fetchData = await axios.put(url, formdata);
    try {
      const authenticForm = {
        ...fetchData.data.user,
        isAuth: true,
      };
      dispatch(authActions.signIn(authenticForm));
    } catch (error) {}
    if (fetchData.data.success) {
      var date = new Date();
      date.setDate(date.getDate() + 5);
      var pdate = new Date();
      pdate.setDate(pdate.getDate() + 180);

      const propertyformdata = {
        propertyId: singlePropertyId,
        boosted: true,
        NoofBoosting: Number(selectBoost),
      };
      dispatch(propertyUpdate(propertyformdata));
      boostToggle(false);
      toast.success("Property Boosted Successfully", {
        position: "top-center",
        autoClose: 1500,
      });
    } else {
      //not sufficient balance
      toast.error("Please Recharge on Wallet", {
        position: "top-center",
        autoClose: 1000,
      });
      // navigate("/subcription");
    }
  };

  return (
    <>
      <div className="boostlisting-step3">
        <div className="boostlisting-step3-header">
          <div>Boost Your Listing</div>
          <button>
            <RxCross2 />
          </button>
        </div>
        <div className="boostlisting-step3-body">
          <div
            className="boostlisting-step3-body-subhead"
            style={{ fontSize: "14px", fontWeight: "600" }}
          >
            You are spending
          </div>
          <div className="boostlisting-step3-body-subhead">
            <div className="display-between ">
              <div>For Boosting</div>
              <div style={{ paddingRight: "30px" }}>
                {selectBoost}Listings(159.0 Credits)
              </div>
            </div>
            <div className="display-between">
              <div>Remaining Balance</div>
              <div>{loginData.PostCreadits - Number(selectBoost)} Listings</div>
            </div>
          </div>
          <div className="boost-surity boostlisting-step3-body-subhead">
            Are you sure you want to continue?
          </div>
          <div className="boostlisting-step3-footer">
            <div>Step 2 of 4</div>
            <div>
              <button
                className="boosting-btn"
                onClick={() => setChangeIndexValue(1)}
                style={{ cursor: "pointer" }}
              >
                Previous
              </button>
              <button
                className="boosting-btn"
                onClick={(e) => BoostClick(e)}
                style={{ cursor: "pointer" }}
              >
                Boost
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BoostListingStep3;
