import React, { useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { FcPhoneAndroid, FcInfo } from "react-icons/fc";
import { GrMail } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import AllResponse from "./components/AllResponse";
import AllRespond from "./components/AllRespond";
import { listPageStatusChange } from "../../redux/property/PropertySlice";
import AllListing from "./components/AllListing";

const AllResponseListing = () => {

  const { listpageStatus } = useSelector((store) => store.Property);
  const dispatch = useDispatch();
  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return (
          <>
            <AllResponse />
          </>
        );
      case 1:
        return (
          <>
            <AllResponse />
          </>
        );
      case 2:
        return (
          <>
            <AllRespond />
          </>
        );

      default:
        return (
          <>
            <AllListing />
          </>
        );
        break;
    }
  };

  return (
    <>
      <div className="pageComponent">
        <ul className="component__pageFilters_allistings component__fs_13 ">
          <li className="">
            <div className="activationStatus">
              <label className="">ACTIVATION STATUS</label>
              <ol className="component__filterList">
                <li
                  className={
                    listpageStatus === 0 ? "component__activefilter" : "null"
                  }
                  onClick={() => dispatch(listPageStatusChange(0))}
                >
                  All
                </li>
                {/* <li
                  className={
                    listpageStatus === 1 ? "component__activefilter" : "null"
                  }
                  onClick={() => dispatch(listPageStatusChange(1))}
                >
                  Response
                </li> */}
               
                {/* <li
                  className={
                    listpageStatus === 2 ? "component__activefilter" : "null"
                  }
                  onClick={() => dispatch(listPageStatusChange(2))}
                >
                  Respond
                </li> */}
              </ol>
            </div>
          </li>
          {/* <li className="localityFilterMy99">
            <div className="">
              <div className="search-my">
                <i
                  className="search-icon"
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    verticalAlign: "middle",
                    borderBottom: "solid 1px #ccc",
                    padding: "3px 5px",
                  }}
                >
                  <CiSearch />
                </i>
                <input
                  className="component__noInputBorder component__localityFilterMy99"
                  type="text"
                  placeholder="Enter Locality"
                />
              </div>
            </div>
          </li>
          <li className="sortFilters">
            <div className="">
              <span className="component__slidingCategory">Sort</span>
              <select className="component__selectInput">
                <option
                  value="New/Newest First"
                  className="component__selectInput"
                >
                  Newest First
                </option>
                <option value="New/Newest First">Expiring Last</option>
                <option value="Old/Oldest First">Oldest First</option>
                <option value="EXPIRING_FIRST/Expiring First">
                  Expiring First
                </option>
              </select>
            </div>
          </li> */}
         
        </ul>
      </div>

      {renderSwitch(listpageStatus)}

      <div className="component__card_bottomresult">
        <p>Displaying 1 - 1 of 1 results</p>
      </div>

      <div className="getInTouch__getInTouch">
        <h4>FOR QUERIES YOU CAN REACH US AT:</h4>
        <div className="getInTouch__block">
          <ul>
            <li>
              <i className="getInTouch__icon">
                <FcPhoneAndroid />
              </i>
              <p>
                <b>Call us at:</b> 1800 41 99099
              </p>
              <p>
                For international numbers click
                <a>here</a>
              </p>
              <p>
                <b>SMS:</b> BUY as 56070
              </p>
            </li>
            <li className="getInTouch__mail">
              <i className="getInTouch__icon">
                <GrMail />
              </i>
              <p>
                <b>Mail us for Sales/Service/General Enquiries:</b>
                <a>services@mygoldenhomes.com</a>
              </p>
              <p>
                For international numbers click
                <a>here</a>
              </p>
            </li>
            <li className="getInTouch__request">
              <i className="getInTouch__icon">
                <FcInfo />
              </i>
              <p>
                <b>Request for Information:</b>
                <br />
                Ask us for information about our services.
                <a>Request Info</a>
              </p>
              <p>
                For international numbers click
                <a>here</a>
              </p>
            </li>
          </ul>
        </div>
        <div className="getInTouch__greyblock">
          <b>Office & Locations :</b>
          <a>Click here</a>
          for Branch Address
        </div>
      </div>
    </>
  );
};

export default AllResponseListing;
