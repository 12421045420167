import React, { useEffect, useState } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

const AddAreaDetails = (props) => {
  const { PropertyProfile, BasicDetails } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();
  const [carpetArea, setCarpetArea] = useState("");
  const [typeofCarpetArea, setTypeofCarpetArea] = useState("sq.ft.");
  const [typeofCarpetAreaShow, setTypeofCarpetAreaShow] = useState(false);

  const [builtupArea, setBuiltupArea] = useState("");
  const [typeofBuiltupArea, setTypeofBuiltupArea] = useState("sq.ft.");
  const [typeofBuiltupAreaShow, setTypeofBuiltupAreaShow] = useState(false);
  const [typeofBuiltupAreaBox, setTypeofBuiltupAreaBox] = useState(false);

  const [superBuiltupArea, setSuperBuiltupArea] = useState("");
  const [typeofSuperBuiltupArea, setTypeofSuperBuiltupArea] =
    useState("sq.ft.");
  const [typeofSuperBuiltupAreaShow, setTypeofSuperBuiltupAreaShow] =
    useState(false);
  const [typeofSuperBuiltupAreaBox, setTypeofSuperBuiltupAreaBox] =
    useState(false);

  const [plotArea, setPlotArea] = useState("");
  const [typeofPlotArea, setTypeofPlotArea] = useState("sq.ft.");
  const [typeofPlotAreaShow, setTypeofPlotAreaShow] = useState(false);
  const [typeofPlotAreaBox, setTypeofPlotAreaBox] = useState(false);

  //errors

  const [areaDetailsError, setAreaDetailsError] = useState("");

  useEffect(() => {
    if (PropertyProfile !== "") {
      setCarpetArea(PropertyProfile.carpetArea);
      setTypeofCarpetArea(PropertyProfile.typeofCarpetArea);
      setTypeofCarpetAreaShow(PropertyProfile.typeofCarpetAreaShow);

      setBuiltupArea(PropertyProfile.builtupArea);
      setTypeofBuiltupArea(PropertyProfile.typeofBuiltupArea);
      setTypeofBuiltupAreaShow(PropertyProfile.typeofBuiltupAreaShow);
      setTypeofBuiltupAreaBox(PropertyProfile.typeofBuiltupAreaBox);

      setSuperBuiltupArea(PropertyProfile.superBuiltupArea);
      setTypeofSuperBuiltupArea(PropertyProfile.typeofSuperBuiltupArea);
      setTypeofSuperBuiltupAreaShow(PropertyProfile.typeofSuperBuiltupAreaShow);
      setTypeofSuperBuiltupAreaBox(PropertyProfile.typeofSuperBuiltupAreaBox);
    }
  }, [PropertyProfile]);

  const areaMesurments = [
    { name: "sq.ft." },
    { name: "sq.yards" },
    { name: "sq.m." },
    { name: "acres" },
    { name: "marla" },
    { name: "cents" },
    { name: "bigha" },
    { name: "kottah" },
    { name: "kanal" },
    { name: "grounds" },
    { name: "ares" },
    { name: "biswa" },
    { name: "guntha" },
    { name: "aankadam" },
    { name: "hectares" },
    { name: "rood" },
    { name: "chataks" },
    { name: "perch" },
  ];

  useEffect(() => {
    if (carpetArea !== "" && typeofCarpetArea !== "") {
      setAreaDetailsError("");
    }
  }, [carpetArea, typeofCarpetArea]);

  const propertyProfileClick = async (e) => {
    e.preventDefault();

    setAreaDetailsError("");

    if (carpetArea !== "" && typeofCarpetArea !== "") {
      setAreaDetailsError("");
    } else {
      setAreaDetailsError("Atleast one area type mandatory");
    }
  };
  return (
    <>
      <div className="Area-widget">
        <div className="widgetbox_white_box__1v7xz undefined undefined undefined">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span
                      className="xidHeading_headingText__Ulc2a"
                      style={{ display: "inline" }}
                    >
                      Add Area Details{" "}
                      <i
                        style={{
                          fontSize: "18px",
                          verticalAlign: "middle",
                        }}
                      >
                        {" "}
                        <BiInfoCircle />
                      </i>
                    </span>
                  </div>
                </div>
                <p style={{ color: "red", fontSize: "12px" }}>
                  {props.areaDetailsError}
                </p>
              </h4>
            </div>
          </div>
          <div className="" style={{ width: "70%" }}>
            <div className="carpetArea-widget">
              <div className="splitWidget_inputFiledWrap__1vX9m  undefined">
                <div className="pageComponent" id="carpet-input">
                  <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                    <div className="false">
                      <input
                        type="text"
                        value={carpetArea}
                        onChange={(e) => {
                          setCarpetArea(e.target.value.replace(/\D/g, ""));
                          props.carpetAreaChange(
                            e.target.value.replace(/\D/g, "")
                          );
                        }}
                        placeholder="Carpet Area"
                        className="false false "
                      />
                      <div></div>
                    </div>
                    <div className="numberInput_iconsWrapper__3Kpm5">
                      <i></i>
                      <div className="screeningActions_iconWrapper__dB1NM"></div>
                    </div>
                  </div>
                </div>
                <div className="pageComponent" id="carpet-input">
                  <div style={{}}>
                    <div className="selectInput_custom_input_wrapper__MqLE9 false  pageComponent">
                      <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                      <div
                        className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf "
                        style={{ border: "none", cursor: "pointer" }}
                        onClick={(e) =>
                          setTypeofCarpetAreaShow(!typeofCarpetAreaShow)
                        }
                      >
                        {typeofCarpetArea}
                      </div>
                      <div
                        className="selectInput_iconsWrapper__1L2kR"
                        onClick={(e) => {
                          setTypeofCarpetAreaShow(!typeofCarpetAreaShow);
                        }}
                      >
                        {typeofCarpetAreaShow ? (
                          <span className="icon_downArrow">
                            <img src="/assets/images/all/arrow_up.png" alt="" />
                          </span>
                        ) : (
                          <span className="icon_downArrow">
                            <img
                              src="/assets/images/all/arrow_down.png"
                              alt=""
                            />
                          </span>
                        )}
                      </div>
                    </div>
                    {typeofCarpetAreaShow && (
                      <>
                        <div className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown">
                          {areaMesurments.map((mesurment, index) => (
                            <div key={index}>
                              <span
                                onClick={() => {
                                  setTypeofCarpetArea(mesurment.name);
                                  setTypeofCarpetAreaShow(false);
                                  props.carpetAreaMesurment(mesurment.name);
                                }}
                              >
                                {mesurment.name}
                              </span>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              {typeofBuiltupAreaBox === false ? (
                <>
                  {" "}
                  <div
                    className="hyperlinks_medium areaWidget_areaLink__2QO3z"
                    style={{ marginRight: "8px", cursor: "pointer" }}
                    onClick={() =>
                      setTypeofBuiltupAreaBox(!typeofBuiltupAreaBox)
                    }
                  >
                    + Add Built-up Area
                  </div>
                </>
              ) : (
                <></>
              )}
              {typeofSuperBuiltupAreaBox === false ? (
                <>
                  <div
                    className="hyperlinks_medium areaWidget_areaLink__2QO3z"
                    style={{ marginRight: "8px", cursor: "pointer" }}
                    onClick={() =>
                      setTypeofSuperBuiltupAreaBox(!typeofSuperBuiltupAreaBox)
                    }
                  >
                    + Add Super Built-up Area
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            {BasicDetails.subcatName === "Villa" ||
            BasicDetails.subcatName === "Independent House" ? (
              <>
                {typeofPlotAreaBox === false ? (
                  <>
                    <div
                      className="hyperlinks_medium areaWidget_areaLink__2QO3z"
                      style={{ cursor: "pointer" }}
                      onClick={() => setTypeofPlotAreaBox(!typeofPlotAreaBox)}
                    >
                      + Add Plot Area
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {typeofBuiltupAreaBox === true ? (
              <>
                {" "}
                <div className="builtupArea-widget">
                  <div className="splitWidget_inputFiledWrap__1vX9m  undefined">
                    <div className="pageComponent" id="carpet-input">
                      <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                        <div className="false">
                          <input
                            type="text"
                            value={builtupArea}
                            onChange={(e) => {
                              setBuiltupArea(e.target.value.replace(/\D/g, ""));
                              props.builtupChange(
                                e.target.value.replace(/\D/g, "")
                              );
                            }}
                            placeholder=" Built-up Area"
                            class="false false "
                          />
                          <div></div>
                          {/* <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT">
                                      <span className="undefined">
                                        Built-up Area
                                      </span>
                                    </div> */}
                        </div>
                        <div className="numberInput_iconsWrapper__3Kpm5">
                          <i></i>
                          <div className="screeningActions_iconWrapper__dB1NM"></div>
                        </div>
                      </div>
                    </div>
                    <div className="pageComponent" id="carpet-input">
                      <div style={{}}>
                        <div
                          className="selectInput_custom_input_wrapper__MqLE9 false  pageComponent"
                          onClick={(e) =>
                            setTypeofBuiltupAreaShow(!typeofBuiltupAreaShow)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                          <div
                            className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf "
                            style={{ border: "none" }}
                          >
                            {typeofBuiltupArea}
                          </div>
                          <div className="selectInput_iconsWrapper__1L2kR">
                            <span className="icon_downArrow">
                              <img
                                src="/assets/images/all/arrow_down.png"
                                alt=""
                              />
                            </span>
                          </div>
                        </div>

                        {typeofBuiltupAreaShow === true ? (
                          <>
                            {" "}
                            <div className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown">
                              {areaMesurments.map((mesurment, index) => (
                                <div key={index}>
                                  <span
                                    onClick={() => {
                                      setTypeofBuiltupArea(mesurment.name);
                                      setTypeofBuiltupAreaShow(false);
                                      props.builtupMesurment(mesurment.name);
                                    }}
                                  >
                                    {mesurment.name}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {typeofSuperBuiltupAreaBox === true ? (
              <>
                <div className="superBuiltupArea-widget">
                  <div className="splitWidget_inputFiledWrap__1vX9m  undefined">
                    <div className="pageComponent" id="carpet-input">
                      <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                        <div className="false">
                          <input
                            type="text"
                            value={superBuiltupArea}
                            onChange={(e) => {
                              setSuperBuiltupArea(
                                e.target.value.replace(/\D/g, "")
                              );
                              props.superbuiltupChange(
                                e.target.value.replace(/\D/g, "")
                              );
                            }}
                            placeholder="Super Built-up Area"
                            className="false false "
                          />
                          <div></div>
                          {/* <div className="numberInput_placeholder__2f6Rh caption_strong_large numberInput_placeholderWithoutError__1-IPT">
                                      <span className="undefined">
                                        Super built-up Area
                                      </span>
                                    </div> */}
                        </div>
                        <div className="numberInput_iconsWrapper__3Kpm5">
                          <i></i>
                          <div className="screeningActions_iconWrapper__dB1NM"></div>
                        </div>
                      </div>
                    </div>
                    <div className="pageComponent" id="carpet-input">
                      <div style={{}}>
                        <div
                          className="selectInput_custom_input_wrapper__MqLE9 false  pageComponent"
                          onClick={(e) =>
                            setTypeofSuperBuiltupAreaShow(
                              !typeofSuperBuiltupAreaShow
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                          <div
                            className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf "
                            style={{ border: "none" }}
                          >
                            {typeofSuperBuiltupArea}
                          </div>
                          <div className="selectInput_iconsWrapper__1L2kR">
                            <span className="icon_downArrow">
                              <img
                                src="/assets/images/all/arrow_down.png"
                                alt=""
                              />
                            </span>
                          </div>
                        </div>

                        {typeofSuperBuiltupAreaShow === true ? (
                          <>
                            {" "}
                            <div className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown">
                              {areaMesurments.map((mesurment, index) => (
                                <div key={index}>
                                  <span
                                    onClick={() => {
                                      setTypeofSuperBuiltupArea(mesurment.name);
                                      setTypeofSuperBuiltupAreaShow(false);
                                      props.superbuiltupMesurment(
                                        mesurment.name
                                      );
                                    }}
                                  >
                                    {mesurment.name}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {typeofPlotAreaBox === true ? (
              <>
                <div className="superBuiltupArea-widget">
                  <div className="splitWidget_inputFiledWrap__1vX9m  undefined">
                    <div className="pageComponent" id="carpet-input">
                      <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                        <div className="false">
                          <input
                            type="text"
                            value={plotArea}
                            onChange={(e) => {
                              setPlotArea(e.target.value.replace(/\D/g, ""));
                              props.plotAreaChange(
                                e.target.value.replace(/\D/g, "")
                              );
                            }}
                            placeholder="Plot Area"
                            className="false false "
                          />
                        </div>
                        <div className="numberInput_iconsWrapper__3Kpm5">
                          <i></i>
                          <div className="screeningActions_iconWrapper__dB1NM"></div>
                        </div>
                      </div>
                    </div>
                    <div className="pageComponent" id="carpet-input">
                      <div>
                        <div
                          className="selectInput_custom_input_wrapper__MqLE9 false  pageComponent"
                          onClick={() =>
                            setTypeofPlotAreaShow(!typeofPlotAreaShow)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div className="selectInput_label__Sr6HY caption_subdued_large selectInput_selectedValue__2RT2F "></div>
                          <div
                            className="selectInput_custom_input__DdGZD input_placeholder_completed selectInput_select__32l4L selectInput_validInput__3tjrf "
                            style={{ border: "none" }}
                          >
                            {typeofPlotArea}
                          </div>
                          <div className="selectInput_iconsWrapper__1L2kR">
                            <span className="icon_downArrow">
                              <img
                                src="/assets/images/all/arrow_down.png"
                                alt=""
                              />
                            </span>
                          </div>
                        </div>

                        {typeofPlotAreaShow === true ? (
                          <>
                            <div className="selectInput_dropdownList__AuMXU selectInput_showDropdown__fZ1YM pageComponent select-dropdown">
                              {areaMesurments.map((mesurment, index) => (
                                <div key={index}>
                                  <span
                                    onClick={() => {
                                      setTypeofPlotArea(mesurment.name);
                                      setTypeofPlotAreaShow(false);
                                      props.plotAreaMesurment(mesurment.name);
                                    }}
                                  >
                                    {mesurment.name}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAreaDetails;
