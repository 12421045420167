import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FcPhoneAndroid, FcInfo, FcIdea } from "react-icons/fc";
import { GrMail } from "react-icons/gr";
import { MdDoNotDisturbAlt } from "react-icons/md";
import { useSelector } from "react-redux";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment/moment";

const Boosted = () => {
  const { loginData } = useSelector((store) => store.Athentication);
  const { searchProperties } = useSelector((store) => store.Search);


  const [allListing, setAllListing] = useState([]);
  const [showProperty, setshowProperty] = useState([]);
  const [searchProperty, setSearchProperty] = useState([]);
  const [propertyloading, setPropertyloading] = useState(true);

  useEffect(() => {
    const userId = loginData._id;
    try {
      const fetchdata = async () => {
        const url = `${Baseurl}/api/v1/property/propertyby/${userId}`;
        const fetchProperty = await axios(url);
        if (fetchProperty.data.success === true) {
          let reportedProperty = fetchProperty.data.property;
          setAllListing(reportedProperty);
          const boostedProperty = reportedProperty.filter(
            (property) => property.boosted === true
          );
          setshowProperty(boostedProperty);
          setPropertyloading(false);
        }
      };
      fetchdata();
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (propertyloading === false) {
      let search = searchProperties;
      let searchList = [];
      if (search.length > 1) {
        let singleProperty = showProperty.filter((property) =>
          property.locality.toLowerCase().match(search.toLowerCase())
        );
        searchList = [...searchList, ...singleProperty];
        let singleProperty1 = showProperty.filter((property) =>
          property.propertyID.toLowerCase().match(search.toLowerCase())
        );
        searchList = [...searchList, ...singleProperty1];

        setSearchProperty(searchList);
      } else {
        setSearchProperty(showProperty);
      }
    }
  }, [propertyloading, searchProperties, showProperty]);
  return (
    <>
      <div className="component__allListingInfo">
        <div className="component__listingCount">
        {searchProperty.length > 1 ? (
            <>
              <span>{searchProperty.length}</span> Properties
            </>
          ) : (
            <>
              <span>{searchProperty.length}</span> Property
            </>
          )}
        </div>
      </div>

      <div className="false component__premiumFilters">
        <span className="component__mg_ryt_20">Showing In:</span>
        <div className="tags-and-chips__textOnly undefined  tags-and-chips__active pageComponent tags-and-chips__mr2 pageComponent">
          <span className="tags-and-chips__badgeParent "></span>
          <span>Boosted</span>
          <i className="iconS_Common_20 icon_closeBlue ">
            <AiOutlineClose />
          </i>
        </div>
        <label className="component__clearAll">Clear All Filters</label>
      </div>

      {searchProperty.length > 0 ? (
        <>
          {searchProperty.map((property, index) => (
            <div className="component__card" key={index}>
              <div className="component__card_container1">
                <div className="card_in01">
                  {property.apartment === "" ? (
                    <>
                      <label>
                        {property.subCategory +
                          " for sale in " +
                          property.locality +
                          " , " +
                          property.city +
                          " , " +
                          property.state}
                      </label>
                    </>
                  ) : (
                    <>
                      <label>
                        {property.noofBedrooms +
                          " BHK " +
                          property.subCategory +
                          " for sale in " +
                          property.locality +
                          " , " +
                          property.city +
                          " , " +
                          property.state}
                      </label>
                    </>
                  )}
                </div>
                <div className="card_in02">
                  <span style={{ background: "green", borderRadius: 4 }}>
                    Boosted Property
                  </span>
                </div>
                <div className="card_in03">
                  <i>
                    <MdDoNotDisturbAlt />
                  </i>
                </div>
              </div>
              <div className="component__card_container2">
                <p>
                  price: <span>Rs {property.visiblePrice},</span>| Super
                  built-up Area :<span> {property.carpetArea} sq.ft.</span>
                </p>
              </div>
              <div className="component__card_container3">
                <p>
                  <span className="span_number">{property.propertyID} : </span>
                  <span className="span_expired coloringRoot">
                    {property.propertyStatus}
                  </span>
                  | Posted On :
                  <span className="span_date">
                    {" "}
                    {moment(property.createdAt).format("DD MMMM YYYY")}
                  </span>
                </p>
              </div>
              <div className="component__card_container4">
                <div className="card_container4-1">
                  <p>
                    Expiry On:{" "}
                    <span>
                      {moment(property.ExpiryDate).format("DD MMMM YYYY")}
                    </span>{" "}
                    {/* <a>Recall</a> */}
                  </p>
                </div>
                <div className="card_container4-2">
                  <p> Property Viewed: {property.noofPropertyViewed}</p>
                </div>
              </div>
              <div className="component__card_container5">
                <h4>
                  <span className="card_icon_idea">
                    <FcIdea />
                  </span>
                  Need more buyers/tenants for your property, simply
                  <span className="span_getleads"> Get Leads</span>
                </h4>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <div className="component__responceWrapper">
            <div className="pageComponent"></div>
            <div className="fslBooking__noResult">
              <h1>Sorry, No Results !</h1>
              <p>We cannot find the item you are searching for.</p>
              <div className="fslBooking__noResults"></div>
            </div>
          </div>
        </>
      )}

      <div className="component__card_bottomresult">
        <p>Displaying 1 - 1 of 1 results</p>
      </div>
    </>
  );
};

export default Boosted;
