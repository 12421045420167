import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiArrowBack, BiInfoCircle, BiChevronDown } from "react-icons/bi";
import { MdCloudUpload, MdOutlineDeleteForever } from "react-icons/md";
import { HiOutlineLightBulb } from "react-icons/hi";
import { TbDeviceMobileUp } from "react-icons/tb";
import { RiWhatsappFill } from "react-icons/ri";
import { IoMdCall } from "react-icons/io";
import { GoAlert } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { FiZoomIn } from "react-icons/fi";
import HeaderNew from "../../../components/layouts/HeaderNew";

import { render } from "react-dom";

// Import react-circular-progressbar module and styles
import { CircularProgressbar } from "react-circular-progressbar";
import {
  propertyImages,
  setLocalityScooring,
  setPhotoDetails,
  setPostPropertyStepsStatus,
  setaminitiesScooring,
  setbasicdetailScooring,
  setmainpropertyScooring,
  setphotoScooring,
  setpricingScooring,
  setpropertyScooring,
  updateDesktopImages,
} from "../../../redux/property/PropertySlice";

const percentage = 66;

const EditPhotoDetailsTemp = () => {
  const {
    singleProperty,
    PhotoDetails,
    desktopimage,
    PropertyProfile,
    BasicDetails,
    basicdetailScooring,
    LocalityScooring,
    photoScooring,
    pricingScooring,
    aminitiesScooring,
    mainpropertyScooring,
  } = useSelector((state) => state.Property);
  const { loginData } = useSelector((state) => state.Athentication);
  const { subCatTotal } = useSelector((state) => state.SubCategory);


  const dispatch = useDispatch();

  const [selectImage, setSelectImage] = useState("");
  const [showImagePlaceIndex, setShowImagePlaceIndex] = useState("");
  const [construcStatusId, setConstrucStatusId] = useState("");
  const [construcStatus, setConstrucStatus] = useState("");

  const [imageNames, setImageNames] = useState([]);

  const [imageWithName, setImageWithName] = useState([]);
  useEffect(() => {
    let photonameset = [
      {
        name: "Villa",
      },
      {
        name: "Kitchen",
      },
      // {
      //   name: "Balcony",
      // },
      {
        name: "Floor Plan",
      },
      // {
      //   name: "Bedroom",
      // },
      {
        name: "Others",
      },
      {
        name: "Entrance",
      },
      {
        name: "Property Layout",
      },
      {
        name: "Hall",
      },
      // {
      //   name: "Bathroom",
      // },
      {
        name: "Master Plan",
      },
      {
        name: "Location Map",
      },
      {
        name: "Pooja Room",
      },
    ];

    let findCurrectSubcat = subCatTotal.find(
      (data) => data.name === BasicDetails.subcatName
    );

    if (findCurrectSubcat !== "") {
      let newObject = {};
      newObject = {
        name: findCurrectSubcat.name,
      };

      photonameset = [...photonameset, newObject];
    }

    const bathroom = PropertyProfile.noofBathrooms;
    const bedroom = PropertyProfile.noofBedrooms;
    const balconies = PropertyProfile.balconies;

    let bathroomArray = [];
    let bedroomArray = [];
    let balconyArray = [];

    for (let r = 0; r < bathroom; r++) {
      let bathroomObject = {};
      bathroomObject = {
        name: `Bathroom ${r + 1}`,
      };
      bathroomArray = [...bathroomArray, bathroomObject];
    }
    photonameset = [...photonameset, ...bathroomArray];

    for (let i = 0; i < bedroom; i++) {
      let bedroomObject = {};
      bedroomObject = {
        name: `Bedroom ${i + 1}`,
      };
      bedroomArray = [...bedroomArray, bedroomObject];
    }
    photonameset = [...photonameset, ...bedroomArray];

    for (let j = 0; j < balconies; j++) {
      let balconyObject = {};
      balconyObject = {
        name: `Balcony ${j + 1}`,
      };
      balconyArray = [...balconyArray, balconyObject];
    }
    photonameset = [...photonameset, ...balconyArray];

    setImageNames(photonameset);
  }, [subCatTotal, BasicDetails, PropertyProfile]);

  useEffect(() => {
    let imagechange = singleProperty.DeskImg.map(
      (images) =>
        (images = {
          image: images,
          imageName: BasicDetails.subcatName,
          imageIndex: 0,
        })
    );

    setImageWithName(imagechange);
  }, [singleProperty.DeskImg]);

  const imageChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(propertyImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const imageDeleteClick = (e, selectimage, index) => {
    const removeimage = singleProperty.DeskImg.filter((image) => image !== selectimage);
    const imageArray = imageWithName.filter(
      (image) => image.image !== selectimage
    );
    setImageWithName(imageArray);
    dispatch(updateDesktopImages(removeimage));
  };

  useEffect(() => {
    let countTotal = 0;
    let countimageWithName = 0;
    if (imageWithName.length > 0) {
      countimageWithName = 10;
    } else {
      countimageWithName = 0;
    }
    countTotal = countimageWithName;
    dispatch(setmainpropertyScooring(mainpropertyScooring));
    dispatch(setbasicdetailScooring(12));
    dispatch(setLocalityScooring(LocalityScooring));
    dispatch(setphotoScooring(countimageWithName));
    dispatch(setpricingScooring(pricingScooring));
    dispatch(setaminitiesScooring(aminitiesScooring));
    dispatch(setpropertyScooring());
  }, [imageWithName]);

  useEffect(() => {
    if (PhotoDetails.length > 0) {
      dispatch(updateDesktopImages(PhotoDetails));
    }
  }, [PhotoDetails]);

  const photoDetailsClick = async () => {
    if (singleProperty.DeskImg.length > 0) {
      dispatch(setPhotoDetails(singleProperty.DeskImg));
      dispatch(setPostPropertyStepsStatus(4));
    }
  };

  const withoutphotoDetailsClick = async () => {
    dispatch(setPhotoDetails([]));
    dispatch(setPostPropertyStepsStatus(4));
  };

  const ConstructionChange = (e, text, index) => {
    let changeName = imageWithName.map((data) => {
      if (data.image === selectImage) {
        data.imageName = text.name;
      }
      return data;
    });

    setImageWithName(changeName);
    setSelectImage("");
    setShowImagePlaceIndex("");
  };

  return (
    <>
      {/* <HeaderNew /> */}
      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA ppfDesktop_mainWrapper__inner">
          <div className="page_ppf_step1__1icHe  pageComponent">
            <div className="PrimaryDetailsHeader-desktopHeader">
              <div className="desktopHeader_iconInlineHead__W8_RL">
                <div className="caption_subdued_medium">
                  <span
                    className="desktopHeader_backArrow__1lVxQ iconS_PPFDesk_24 icon_backGrey"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  >
                    <BiArrowBack />
                  </span>
                  <div style={{ fontSize: "14px" }}>Back</div>
                </div>
                <div className="desktopHeader_text__3iyVt">
                  <div
                    className="undefined title_l_semiBold"
                    style={{ display: "flex" }}
                  >
                    Add photos of your property
                    <em
                      className="desktopHeader_italics__3klzF caption_subdued_medium d-inline-block"
                      style={{
                        display: "inline-block",
                        paddingTop: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      (Optional)
                    </em>
                  </div>
                  <div className="caption_subdued_large">
                    A picture is worth a thousand words. 87% of buyers look at
                    photos before buying
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="" id="photoUpload-widget">
            <div className="widgetbox_white_box__1v7xz undefined undefined undefined">
              <div className="false">
                <p className="list_header_semiBold">
                  <span>Upload from desktop</span>
                </p>
                <div className="reviewPhotoGallery_banner__1sG_u photo_details_inner">
                  <div className="alertBanner_alertBanner__1EZXm alertBanner_yellowBlue__2064q   pageComponent">
                    <i className="iconS_PPFDesk_24 icon_yellowWarning alertBanner_alertIcn__2C2MJ">
                      <GoAlert />
                    </i>
                    <div className="undefined alertBanner_content__1r5zv">
                      <span className="list_header_semiBold Ng800 d-inline">
                        Less photos added!{" "}
                        <span
                          className="body_med Ng800"
                          style={{ display: "inline" }}
                        >
                          Show your complete property by adding other area
                          photos too,e.g: Kitchen, balcony, etc
                        </span>
                        <span className="hyperlinks_medium d-inline-block">
                          Add more
                        </span>
                      </span>
                    </div>
                    <i className="iconS_Common_16 icon_close pageComponent alertBanner_closeIcon__1zCYE">
                      <AiOutlineClose />
                    </i>
                  </div>
                </div>

                {singleProperty.DeskImg.length > 0 ? (
                  <>
                    <div className="undefined  pageComponent photo_details_inner">
                      <div className="spacer20">
                        <div className="reviewPhotoGallery_uploaded_cover_photo_wrap__3n_9r">
                          <form
                            action=""
                            onClick={() =>
                              document.querySelector(".upload").click()
                            }
                          >
                            <div className="reviewPhotoGallery_addMorePhotos__26_ff pageComponent">
                              <i className="iconS_PPFDesk_48 icon_addImages">
                                <img
                                  src="/assets/images/all/Gallery.png"
                                  width={"50"}
                                  alt=""
                                />
                              </i>
                              <input
                                type="file"
                                className="upload"
                                onChange={(e) => imageChange(e)}
                                multiple
                                required
                                accept="image/*"
                                hidden
                                // style={{ display: "none" }}
                              />
                              <span className="caption_strong_medium">
                                Add more photos
                              </span>
                              <span
                                className="caption_strong_small"
                                style={{ textAlign: "center", padding: "5px" }}
                              >
                                Upload upto 50 photos of max size 10 mb in
                                format png, jpg, jpeg, gif, webp, heic, heif
                              </span>
                              <button className="pageComponent buttons_secondaryRegular__3R1_d buttons_semi__1it_o undefined">
                                <span>
                                  <span>Upload Photos</span>
                                </span>
                              </button>
                            </div>
                          </form>
                          {imageWithName.map((image, index) => (
                            <div
                              className=" photo_photo_box__232Mj"
                              key={index}
                            >
                              <div className="photo_imgBox__nUNh6">
                                <img src={image.image} alt="" />
                                <div
                                  className="photo_del_icn__GAXsL"
                                  onClick={(e) =>
                                    imageDeleteClick(e, image.image, index)
                                  }
                                >
                                  <i className="iconS_PPF_24 icon_delIcon">
                                    <img src="/assets/images/all/DeleteIcon.png"></img>
                                  </i>
                                </div>
                                {/* <div className="photo_magnify__1TD2U">
                                  <i className="iconS_PPF_24 icon_zoomin">
                                    <img src="/assets/images/all/zoomin.png"></img>
                                  </i>
                                </div> */}
                                {/* <div className="photo_magnify__17yut7">
                                  <i className="iconS_PPF_24 icon_zoomin">
                                    <input type="checkbox" />
                                  </i>
                                </div> */}
                              </div>

                              <div className="photoTagDropdown_dropdownWrapper__3O8Wu photo_photoTagDropdown__2hIcQ undefined">
                                <p
                                  className="labels_semiBold d-inline-block undefined"
                                  onClick={() => {
                                    setShowImagePlaceIndex(index);
                                    setSelectImage(image.image);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span>{image.imageName}</span>

                                  <i className="iconS_Common_16 icon_downArrow undefined">
                                    <BiChevronDown />
                                  </i>
                                </p>
                                {showImagePlaceIndex === index ? (
                                  <>
                                    <div className="photoTagDropdown_listWrap__1emKw undefined">
                                      {imageNames.map((photoname, index) => (
                                        <div
                                          className="photoTagDropdown_dropdownText__2Ub9s caption_strong_large"
                                          key={index}
                                          onClick={(e) =>
                                            ConstructionChange(
                                              e,
                                              photoname,
                                              index
                                            )
                                          }
                                        >
                                          <span className="list_header_semiBold undefined">
                                            {photoname.name}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <form
                      action=""
                      onClick={() => document.querySelector(".upload").click()}
                    >
                      <div
                        className="addPhotos_addPhotosbox__QBIaz   pageComponent photo_details_inner"
                        id="photo-upload"
                      >
                        <input
                          type="file"
                          className="upload"
                          onChange={(e) => imageChange(e)}
                          multiple
                          required
                          accept="image/*"
                          hidden
                          //   style={{ display: "none" }}
                        />
                        <span className="iconS_PPFDesk_48 icon_uploadIcon">
                          <i style={{ fontSize: "30px", color: "" }}>
                            <MdCloudUpload />
                          </i>
                        </span>
                        <div className="addPhotos_textContainer__2iQJ8 caption_strong_medium">
                          <span style={{ verticalAlign: "middle" }}>
                            Drag and drop your photos here
                          </span>
                        </div>
                        <div className="addPhotos_textContainer__2iQJ8 caption_strong_small">
                          Upload 50 photos of max size 10 mb in format png, jpg,
                          jpeg, gif, webp, heic, heif.
                        </div>
                        <div className="addPhotos_orWrap__1U9mB">
                          <span className="caption_strong_small">OR</span>
                        </div>
                        <div className='class="addPhotos_textContainer__2iQJ8 caption_strong_medium"'>
                          <button
                            className="pageComponent buttons_secondaryRegular__3R1_d buttons_semi__1it_o undefined"
                            type="button"
                          >
                            <span>
                              <span>Upload Photos Now</span>
                            </span>
                          </button>
                        </div>
                        {/* <div className="addPhotos_toolTipWrap__1kA_Y">
                        <div className="blueToolTip_blueToolTipWrap__1Zfkv ">
                          <i
                            className="iconS_PPFDesk_16 icon_bulbGray"
                            style={{ color: "#fff" }}
                          >
                            <HiOutlineLightBulb />
                          </i>
                          <span className="body_med">
                            Add
                            <span className="list_header_bold d_inlineBlock addPhotos_verticleAlignUnset__293lG">
                              4+ property photos
                            </span>
                            & increase responses upto 21%
                          </span>
                        </div>
                      </div> */}
                      </div>
                    </form>
                  </>
                )}

                <div className="addPhotos_inputGroupLabel__2918f">
                  <i className="" style={{ fontSize: "24px" }}>
                    <TbDeviceMobileUp />
                  </i>
                  <div
                    className="list_header_semiBold"
                    style={{ marginLeft: "15px" }}
                  >
                    Now you can also upload photos directly
                    <br />
                    from your phone
                  </div>
                </div>
                <div className="addPhotos_mobileOptionContainer__2CV2T pageComponent">
                  <span className="body_small">
                    With your registered number +91-{loginData.mobile}
                  </span>
                  {/* <div
                    className="addPhotos_option__1cU3a pageComponent false"
                    id="whatsapp-upload"
                  >
                    <div>
                      <input type="radio" label="" design="system" />
                    </div>
                    <span
                      className="caption_strong_large"
                      style={{ marginLeft: "10px" }}
                    >
                      Send photos over{" "}
                      <i style={{ color: "green", fontSize: "14px" }}>
                        <RiWhatsappFill />
                      </i>{" "}
                      WhatsApp
                    </span>
                  </div>
                  <div
                    className="addPhotos_option__1cU3a pageComponent false"
                    id="whatsapp-upload"
                  >
                    <div>
                      <input type="radio" label="" design="system" />
                    </div>
                    <span
                      className="caption_strong_large"
                      style={{ marginLeft: "10px" }}
                    >
                      Get photo upload link over SMS
                    </span>
                  </div> */}
                  <div style={{ textAlign: "left" }}>
                    <div className="alertBanner_alertBanner__1EZXm alertBanner_yellow__1BBZE photoUpload_width65__3zWwl  pageComponent">
                      <i
                        className="iconS_PPFDesk_16 icon_InfoOrange alertBanner_alertIcn__2C2MJ"
                        style={{ color: "orange" }}
                      >
                        <BiInfoCircle />
                      </i>
                      <div className="caption_strong_medium alertBanner_content__1r5zv">
                        Without photos your ad will be ignored by buyers
                      </div>
                    </div>
                  </div>
                </div>

                {singleProperty.DeskImg.length > 0 ? (
                  <>
                    {" "}
                    <div
                      className="PhotoDetailsButtonsWrapper-widget"
                      style={{ textAlign: "left", marginTop: "20px" }}
                    >
                      <div
                        id="PhotoDetailsButton-button"
                        className="pageComponent"
                      >
                        <div>
                          <div className="buttonInput_continue_btn__1gb5M ">
                            <button
                              type="button"
                              id="PhotoDetailsButton"
                              className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                              onClick={() => photoDetailsClick()}
                            >
                              <span>
                                <span>Continue with less photos</span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div
                      className="PhotoDetailsButtonsWrapper-widget"
                      style={{ textAlign: "left", marginTop: "20px" }}
                    >
                      <div
                        id="PhotoDetailsButton-button"
                        className="pageComponent"
                      >
                        <div>
                          <div className="buttonInput_continue_btn__1gb5M ">
                            <button
                              type="button"
                              id="PhotoDetailsButton"
                              className="pageComponent buttons_primaryRegular__rU-bm buttons_semi__1it_o undefined"
                              onClick={() => withoutphotoDetailsClick()}
                            >
                              <span>
                                <span>Continue without photos</span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          id="rightSection"
          className="rightSection_rightSection__36zjv"
          style={{ minHeight: "calc(100vh - 54px)" }}
        >
          <div className="sectionInfo_contentWrap__35b6q">
            <img
              src="/assets/images/all/photosdetails.png"
              alt=""
              style={{ width: "160px" }}
            />
            <div
              className="sectionInfo_textSection__Zm4lg sectionInfo_textCenter__1-nk4"
              style={{ padding: "0px 50px 0px 50px" }}
            >
              <div className="list_header_semiBold">
                Make your picture perfect!
              </div>
              <ul
                className="sectionInfo_listWrapper__uCZdJ"
                style={{ paddingLeft: "35" }}
              >
                <li className="caption_strong_large sectionInfo_subText__1alcp">
                  Capture photos in landscape mode.
                </li>
                <li className="caption_strong_large sectionInfo_subText__1alcp">
                  Try clicking photos during the day. Avoid using flash.
                </li>
                <li className="caption_strong_large sectionInfo_subText__1alcp">
                  Tidy up for better impact.
                </li>
                <li className="caption_strong_large sectionInfo_subText__1alcp">
                  Edit with 99acres filters for finish.
                </li>
                <li className="caption_strong_large sectionInfo_subText__1alcp">
                  To learn morea <a href="">click here</a>
                </li>
              </ul>
              <div></div>
            </div>
          </div>
          <div className="needHelp_helpBox__1b_kG" id="helpBox">
            <div className="needHelp_needHelpWrap__1eIlT">
              <div className="needHelp_helpHead__2mtNV">
                <i className="iconS_PPFDesk_16 icon_callIcon">
                  <IoMdCall />
                </i>
                <div className="caption_subdued_large">Need help ? </div>
              </div>
              <div className="caption_subdued_medium">
                You can email us at
                <a className="hyperlinks_small"> service@mygoldenhomes.com </a>
                or call us at
                <a className="hyperlinks_small"> 1800 41 99099</a>
                (IND Toll-Free).
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPhotoDetailsTemp;
