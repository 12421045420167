import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const SecurityDeposit = (props) => {
  const { PropertyProfile, PriceotherDetails } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();

  const [securityDeposit, setSecurityDeposit] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [noofMonths, setNoofMonths] = useState("");

  useEffect(() => {
    if (PriceotherDetails !== "") {
      setSecurityDeposit(PriceotherDetails.securityDeposit);
      setDepositAmount(PriceotherDetails.depositAmount);
      setNoofMonths(PriceotherDetails.noofMonths);
    }
  }, [PriceotherDetails]);

  return (
    <>
      {/* <HeaderNew /> */}
      <div
        className="ppfDesktop_mainWrapper__k7Xv0 null"
        style={{ marginTop: "0px" }}
      >
        <div className="false ppfDesktop_formSection__ZzvPA">
          <div className="page_ppf_step1__1icHe  pageComponent pricing_othersinner">
            <div className="Ownership-widget" style={{ marginTop: "10px" }}>
              <div className="widgetbox_white_box__1v7xz undefined false false">
                <div
                  className="widgetbox_header_text__2BXc_"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="xidHeading_PageHeadingwrap__3z0kL">
                    <h4>
                      <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                        <span
                          className="xidHeading_headingText__Ulc2a"
                          style={{ display: "inline" }}
                        >
                          Security deposit
                        </span>
                        <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                          (Optional)
                        </em>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="false">
                <div
                  className="pagecomponent"
                  id="ownership-input"
                  style={{ textAlign: "left" }}
                >
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        securityDeposit === "Fixed"
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => {
                        setSecurityDeposit("Fixed");
                        props.getSecurityDeposite("Fixed");
                        props.setNoofMonths("");
                      }}
                      id="1"
                    >
                      <span>Fixed</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        securityDeposit === "Multiple"
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => {
                        setSecurityDeposit("Multiple");
                        props.getSecurityDeposite("Multiple");
                        props.setDepositAmount("");
                      }}
                      id="2"
                    >
                      <span>Multiple of Rent</span>
                    </div>
                  </div>
                  <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                    <div
                      className={
                        securityDeposit === "None"
                          ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                          : "pageComponent radioInput_textOnly__1r7GL"
                      }
                      onClick={() => {
                        setSecurityDeposit("None");
                        setNoofMonths("");
                        setDepositAmount("");
                        props.getSecurityDeposite("None");
                        props.setNoofMonths("");
                        props.setDepositAmount("");
                      }}
                      id="3"
                    >
                      <span>None</span>
                    </div>
                  </div>
                </div>
                {securityDeposit === "Fixed" ? (
                  <>
                    <div id="DepositValue-input">
                      <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                        <div className="false">
                          <input
                            type="text"
                            value={depositAmount}
                            onChange={(e) => {
                              setDepositAmount(
                                e.target.value.replace(/\D/g, "")
                              );
                              setNoofMonths("");
                              props.setDepositAmount(
                                e.target.value.replace(/\D/g, "")
                              );
                              props.setNoofMonths("");
                            }}
                            placeholder="Deposit Value"
                          />
                          <div className="numberInput_iconsWrapper__3Kpm5">
                            <i></i>
                            <div className="screeningActions_iconWrapper__dB1NM"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {securityDeposit === "Multiple" ? (
                  <>
                    <div id="bookingAmount-input">
                      <div className="numberInput_inputContainer__CHL96  numberInput_multiple_input__33UOZ">
                        <div className="false">
                          <input
                            type="text"
                            value={noofMonths}
                            onChange={(e) => {
                              setNoofMonths(e.target.value.replace(/\D/g, ""));
                              setDepositAmount("");
                              props.setNoofMonths(
                                e.target.value.replace(/\D/g, "")
                              );
                              props.setDepositAmount("");
                            }}
                            placeholder="No. of months (Max 30)"
                          />
                          <div className="numberInput_iconsWrapper__3Kpm5">
                            <i></i>
                            <div className="screeningActions_iconWrapper__dB1NM"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecurityDeposit;
