import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FcPhoneAndroid, FcInfo, } from 'react-icons/fc';
import { GrMail } from 'react-icons/gr';
import { CiSearch } from 'react-icons/ci';
import { Link, useNavigate } from "react-router-dom";
import DashBoard from './DashBoard';

const AllLeads = () => {
    return (
        <>
            <div className='pageComponent'>
                <ul className='component__pageFilters_allistings component__fs_13 '>
                    <li className='progListingFilter'>
                        <div className=''>
                            <span className='component__slidingCategory'>DURATION</span>
                            <select className='component__selectInput'>
                                <option value="All/6months" className='component__selectInput'>All(Last 6 months)</option>
                                <option value="Last 90 days">Last 90 days</option>
                                <option value="Last 30 days">Last 30 days</option>
                                <option value="Sep 2023">Sep 2023</option>
                                <option value="Aug 2023">Aug 2023</option>
                                <option value="July 2023">July 2023</option>
                                <option value="July 2023">July 2023</option>
                                <option value="June 2023">June 2023</option>
                                <option value="May 2023">May 2023</option>
                                <option value="April 2023">April 2023</option>
                                <option value="March 2023">March 2023</option>
                            </select>
                        </div>
                    </li>
                    <li className='progListingFilter'>
                        <div className=''>
                            <span className='component__slidingCategory'>FOLLOW UP STATUS</span>
                            <select className='component__selectInput'>
                                <option value="Follow Up" className='component__selectInput'>Follow Up</option>
                                <option value="Requirements Diff">Requirements Diff</option>
                                <option value="Site Visit">Site Visit</option>
                                <option value="Later">Later</option>
                                <option value="Invalid">Invalid</option>
                                <option value="New">New</option>
                                <option value="Closed">Closed</option>
                                <option value="Contacted">Contacted</option>
                                <option value="Not Interested">Not Interested</option>
                            </select>
                        </div>
                    </li>
                    <li className='sortFilters'>
                        <div className=''>
                            <span className='component__slidingCategory'>Sort</span>
                            <select className='component__selectInput'>
                                <option value="Oldest First" className='component__selectInput'>Oldest First</option>
                                <option value="Highest Score First">Highest Score First</option>
                                <option value="Latest First">Latest First</option>
                            </select>
                        </div>
                    </li>
                    <li className='sortFilters'>
                        <div className=''>
                            <span className='component__slidingCategory'>LEAD SCORE</span>
                            <select className='component__selectInput'>
                                <option value="1/All" className='component__selectInput'>All</option>
                                <option value="2/>=2"> >=2 </option>
                                <option value="3/>=3"> >=3 </option>
                                <option value="4/>=4"> >=4 </option>
                            </select>
                        </div>
                    </li>
                </ul>
            </div>

            <div className='component__responceWrapper'>
                <div className='pageComponent'></div>
                <div className='fslBooking__noResult'>
                    <h1>Sorry, No Results !</h1>
                    <p>We cannot find the item you are searching for.</p>
                    <div className='fslBooking__noResults'></div>
                </div>
            </div>

            <div className="getInTouch__getInTouch">
                <h4>FOR QUERIES YOU CAN REACH US AT:</h4>
                <div className="getInTouch__block">
                    <ul>
                        <li>
                            <i className="getInTouch__icon"><FcPhoneAndroid /></i>
                            <p>
                                <b>Call us at:</b> 1800 41 99099
                            </p>
                            <p>
                                For international numbers click
                                <a>here</a>
                            </p>
                            <p>
                                <b>SMS:</b>  BUY as 56070
                            </p>
                        </li>
                        <li className="getInTouch__mail">
                            <i className="getInTouch__icon"><GrMail /></i>
                            <p>
                                <b>Mail us for Sales/Service/General Enquiries:</b>
                                <a>services@mygoldenhomes.com</a>
                            </p>
                            <p>
                                For international numbers click
                                <a>here</a>
                            </p>
                        </li>
                        <li className="getInTouch__request">
                            <i className="getInTouch__icon"><FcInfo /></i>
                            <p>
                                <b>Request for Information:</b>
                                <br />
                                Ask us for information about our services.
                                <a>Request Info</a>
                            </p>
                            <p>
                                For international numbers click
                                <a>here</a>
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="getInTouch__greyblock">
                    <b>Office & Locations :</b>
                    <a>Click here</a>
                    for Branch Address
                </div>
            </div>
        </>
    )
}

export default AllLeads