import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";


const percentage = 66;

const OtherRoomsTemp = (props) => {
  const { PropertyProfile, BasicDetails,singleProperty } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();

  const [poojaroom, setPoojaroom] = useState(singleProperty.poojaRoom);
  const [studyroom, setStudyroom] = useState(singleProperty.studyRoom);
  const [servantroom, setServantroom] = useState(singleProperty.servantRoom);
  const [storeroom, setStoreroom] = useState(singleProperty.storeRoom);
  

  useEffect(() => {
    if (PropertyProfile !== "") {
      setPoojaroom(PropertyProfile.poojaroom);
      setStudyroom(PropertyProfile.studyroom);
      setServantroom(PropertyProfile.servantroom);
      setStoreroom(PropertyProfile.storeroom);
    }
  }, [PropertyProfile]);

  return (
    <>
      <div className="Furnishing-widget">
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span className="xidHeading_headingText__Ulc2a">
                      Other rooms
                    </span>
                    <em className="xidHeading_italics__Bo3w8 caption_subdued_large">
                      (Optional)
                    </em>
                  </div>
                </div>
              </h4>
            </div>
          </div>
          <div className="false">
            <div
              id="additionalRooms-input"
              className="pageComponent"
              style={{ textAlign: "left" }}
            >
              <div className="checkBox_tags_wrapper__2gksy  undefined multiple_input">
                <div
                  className={
                    poojaroom === true
                      ? "tags-and-chips_textOnly__2k10B tags-and-chips_active__37Gi0 pageComponent"
                      : "tags-and-chips_textOnly__2k10B  pageComponent"
                  }
                  onClick={() => {
                    setPoojaroom(!poojaroom);
                    props.getpoojaroom(!poojaroom);
                  }}
                >
                  {poojaroom === true ? (
                    <>
                      {" "}
                      <i className="iconS_Common_20 icon_blueRightTick">
                        <img src="./assets/images/all/tick.png" alt="" />
                      </i>
                    </>
                  ) : (
                    <>
                      <i className="iconS_PPF_20 icon_plusIcon">
                        <BiPlus />
                      </i>
                    </>
                  )}

                  <span>Pooja Room</span>
                </div>
                <div
                  className={
                    studyroom === true
                      ? "tags-and-chips_textOnly__2k10B tags-and-chips_active__37Gi0 pageComponent"
                      : "tags-and-chips_textOnly__2k10B  pageComponent"
                  }
                  onClick={() => {
                    setStudyroom(!studyroom);
                    props.getstudyroom(!studyroom);
                  }}
                >
                  {studyroom === true ? (
                    <>
                      {" "}
                      <i className="iconS_Common_20 icon_blueRightTick">
                        <img src="./assets/images/all/tick.png" alt="" />
                      </i>
                    </>
                  ) : (
                    <>
                      <i className="iconS_PPF_20 icon_plusIcon">
                        <BiPlus />
                      </i>
                    </>
                  )}
                  <span>Study Room</span>
                </div>
                <div
                  className={
                    servantroom === true
                      ? "tags-and-chips_textOnly__2k10B tags-and-chips_active__37Gi0 pageComponent"
                      : "tags-and-chips_textOnly__2k10B  pageComponent"
                  }
                  onClick={() => {
                    setServantroom(!servantroom);
                    props.getservantroom(!servantroom);
                  }}
                >
                  {servantroom === true ? (
                    <>
                      {" "}
                      <i className="iconS_Common_20 icon_blueRightTick">
                        <img src="./assets/images/all/tick.png" alt="" />
                      </i>
                    </>
                  ) : (
                    <>
                      <i className="iconS_PPF_20 icon_plusIcon">
                        <BiPlus />
                      </i>
                    </>
                  )}
                  <span>Servant Room</span>
                </div>
                <div
                  className={
                    storeroom === true
                      ? "tags-and-chips_textOnly__2k10B tags-and-chips_active__37Gi0 pageComponent"
                      : "tags-and-chips_textOnly__2k10B  pageComponent"
                  }
                  onClick={() => {
                    setStoreroom(!storeroom);
                    props.getstoreroom(!storeroom);
                  }}
                >
                  {storeroom === true ? (
                    <>
                      {" "}
                      <i className="iconS_Common_20 icon_blueRightTick">
                        <img src="./assets/images/all/tick.png" alt="" />
                      </i>
                    </>
                  ) : (
                    <>
                      <i className="iconS_PPF_20 icon_plusIcon">
                        <BiPlus />
                      </i>
                    </>
                  )}
                  <span>Store Room</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherRoomsTemp;
