import React from "react";
import { useSelector } from "react-redux";
import OfficeLocationDetails from "./componenets/commericial/rentlease/office/OfficeLocationDetails";
import PlotLandLocationDetails from "./componenets/commericial/rentlease/plotandland/PlotLandLocationDetails";
import LocationDetails from "./componenets/LocationDetails";

const MainLocationPage = () => {
  const { BasicDetails } = useSelector((state) => state.Property);
  return (
    <>
      {BasicDetails.supercatName === "Residential" ? (
        <>
          <LocationDetails />
        </>
      ) : (
        <>
          {BasicDetails.subcatName === "Office" ? (
            <>
              <OfficeLocationDetails />
            </>
          ) : (
            <>
              <PlotLandLocationDetails />
            </>
          )}
        </>
      )}
    </>
  );
};

export default MainLocationPage;
