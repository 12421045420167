import React from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';
import { useState } from 'react';

const RequestaProblem = () => {

  const [isclicked, setIsClicked] = useState(false);

  return (
    <>
      <div>
        <Header />
        <div style={{ paddingLeft: "23%", marginTop: "100px", marginBottom: "24px", marginLeft: "-50%", }}>
          <a href="" style={{ fontsize: "12px", color: "#42526E" }}>Home > </a>
          <a href="" style={{ fontsize: "12px", color: "#42526E" }}>Request A Problem</a>
        </div>
        <div className='css-1dbjc4n' style={{ paddingLeft: "23%", paddingRight: "23%", overflowx: "hidden" }}>
          <div className='css-1dbjc4n r-xovx4i r-1xfd6ze r-bijgke'>
            <div className='css-1dbjc4n r-1awozwy r-6koalj r-eqz5dr'>
              <div className='css-1dbjc4n r-1uopjct r-145lgeb r-64el8z r-1kz6sp'>
                <div className='css-1dbjc4n r-1mlwlqe r-1uopjct r-145lgeb r-64el8z r-1udh08x r-1kz6sp r-417010 safety_guide'>
                  <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                  >
                    <img src="./assets/images/all/feedback.png" alt="" />
                  </div>
                </div>
              </div>
              <h1 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-1x35g6 r-13uqrnb r-b88u0q r-37tt59 r-1v456y7' style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "20px" }}>
                Feedback
              </h1>
            </div>
          </div>
          <div className='styles__form'>
            <div className='styles__intro'>
              <h2> We would love to hear you!</h2>
            </div>
            <div className='undefined inputField__inputContainer undefined undefined undefined'>
              <input type="text" className='pageComponent inputField__inputField  false undefined' label="Name" />
              <div className='inputField__placeholder caption_subdued_large'>
                <span>
                  Name
                  <span style={{ fontStyle: "italic", opacity: "0.6" }}></span>
                </span>
              </div>
            </div>
            <div className='undefined inputField__inputContainer undefined undefined undefined'>
              <input type="email" className='pageComponent inputField__inputField  false undefined' label="Name" />
              <div className='inputField__placeholder caption_subdued_large'>
                <span>
                  Email
                  <span style={{ fontStyle: "italic", opacity: "0.6" }}></span>
                </span>
              </div>
            </div>
            <div>
              <div className='styles__starHeading'>Rate Mygoldenhomes mobile app</div>
              <div className='styles__content'>
                <div className='styles__starimg'><img src="./assets/images/all/star_unfilled.png" style={{ width: "36px", height: "36px" }} alt="" /></div>
                <div className='styles__starimg'><img src="./assets/images/all/star_unfilled.png" style={{ width: "36px", height: "36px" }} alt="" /></div>
                <div className='styles__starimg'><img src="./assets/images/all/star_unfilled.png" style={{ width: "36px", height: "36px" }} alt="" /></div>
                <div className='styles__starimg'><img src="./assets/images/all/star_unfilled.png" style={{ width: "36px", height: "36px" }} alt="" /></div>
                <div className='styles__starimg'><img src="./assets/images/all/star_unfilled.png" style={{ width: "36px", height: "36px" }} alt="" /></div>
              </div>
            </div>
            <div className='styles__cityInputStyle'>
              <div className='inputWrap__inputWrap '>
                <div className='selectBox__selectBox'>
                  <div className=' undefined inputField__inputContainer undefined undefined undefined' onClick={e => setIsClicked(!isclicked)}>
                    <input type="text" className='pageComponent inputField__inputField  inputField__cursortNone undefined' />
                    <div className='inputField__placeholder caption_subdued_large'>
                      <span>
                        Select Feedback
                        <span style={{ fontStyle: "italic", opacity: "0.6" }}></span>
                      </span>
                    </div>
                  </div>
                  <i className='iconS_Common_24 icon_downArrow'>
                    {isclicked ? <MdArrowDropUp /> : <MdArrowDropDown />}
                  </i>
                </div>
                {isclicked && (<>
                  <div className='feedback_select'>
                    <div><span>  I want to report a problem</span></div>
                    <div><span>  I have a suggestion</span></div>
                    <div><span>  I want to compliment Mygoldenhomes</span></div>
                    <div><span>  Other</span></div>
                  </div>
                </>)}
              </div>
            </div>
            <div className='inputField__inputContainer undefined false null'>
              <textarea name="" id="" cols="30" rows="10" style={{ height: "80px", marginBottom: "16px", borderColor: "#EBECF0" }}></textarea>
              <div className='inputField__placeholder caption_subdued_large'>
                <span>Type your feedback</span>
              </div>
            </div>
            <button className='pageComponent buttons__primaryRegular   styles__submitButton  pageComponent '>
              <span className=''>Send Feedback</span>
            </button>
            <div className='styles__feebackBarWidth '>
              <div className='SnackBar__snackbarcontainer '></div>
            </div>
          </div>
        </div>
        <Footer />
      </div >
    </>
  )
}

export default RequestaProblem