import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";

function BoostListingstep2({
  boostToggle,
  setChangeIndexValue,
  setselectBoostValue,
}) {
  const [selectBoost, setselectBoost] = useState("");

  return (
    <>
      <div className="boostlisting-step2">
        <div className="boostlisting-step2-header">
          <div>Boost Your Listing</div>
          <button onClick={() => boostToggle()} style={{ cursor: "pointer" }}>
            <RxCross2 />
          </button>
        </div>
        <div className="boostlisting-step2-body">
          <div className="alldata">
            <p>
              uhmm! Our data shows Tellapur,Hyderabad has tough competition!{" "}
            </p>
            <p>Here is a Snapshot.</p>
            <div className="display-between">
              <div>
                <div className="alldata-header">538</div>
                <div>No. of Listings</div>
              </div>
              <div>
                <div className="alldata-header">233</div>
                <div>No. of Advertisers</div>
              </div>
              <div>
                <div className="alldata-header">348 Adevertisers</div>
                <div>Boosted their listings</div>
              </div>
              <div>
                <div className="alldata-header">689 Credits</div>
                <div>Credits used by top 10%</div>
              </div>
            </div>
          </div>
          <div className="boost-for-visibility">
            <div className="boost-for-visibility-header">
              Boost your Listing for a chance at better visibility
            </div>
            <div className="boost-for-visibility-boxbody">
              <div
                className="boost-for-visibility-singlebox"
                style={
                  selectBoost === "1" ? { border: "1px solid #5ad2b6" } : {}
                }
                onClick={() => {
                  setselectBoost("1");
                  setselectBoostValue("1");
                }}
              >
                <div>1 Listing</div>
                <div>159 Credits</div>
              </div>
              <div
                className="boost-for-visibility-singlebox"
                style={
                  selectBoost === "3" ? { border: "1px solid #5ad2b6" } : {}
                }
                onClick={() => {
                  setselectBoost("3");
                  setselectBoostValue("3");
                }}
              >
                <div>3 Listing</div>
                <div>477 Credits</div>
              </div>
              <div
                className="boost-for-visibility-singlebox"
                style={
                  selectBoost === "4" ? { border: "1px solid #5ad2b6" } : {}
                }
                onClick={() => {
                  setselectBoost("4");
                  setselectBoostValue("4");
                }}
              >
                <div>4 Listing</div>
                <div>636 Credits</div>
              </div>
              <div
                className="boost-for-visibility-singlebox"
                style={
                  selectBoost === "5" ? { border: "1px solid #5ad2b6" } : {}
                }
                onClick={() => {
                  setselectBoost("5");
                  setselectBoostValue("5")
                }}
              >
                <div>5 Listing</div>
                <div>795 Credits</div>
              </div>
              <div className="boost-for-visibility-lastbox">
                <input type="number" value="0" />
                Credits
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                fontSize: "12px",
                padding: "10px 0px",
              }}
            >
              To change the Credit value,edit the text box above
            </div>
            <p className="boosting-tnc">
              While boosting may increase listing visibility .it{" "}
              <strong>does not guarantee responses.</strong>The number of
              responses depends on various factors like search Volume,Listing
              Quality,Builder Reputation, Demand, Pricing etc.{" "}
            </p>
            <div className="boosting-step2-footer">
              <div>Step 2 of 4</div>
              <div>
                <button
                  className="boosting-btn"
                  onClick={() => setChangeIndexValue(0)}
                  style={{ cursor: "pointer" }}
                >
                  Previous
                </button>
                {selectBoost !== "" ? (
                  <>
                    <button
                      className="boosting-btn"
                      onClick={() => setChangeIndexValue(2)}
                      style={{ cursor: "pointer" }}
                    >
                      Boost
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="boosting-btn"
                      style={{ cursor: "not-allowed" }}
                    >
                      Boost
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BoostListingstep2;
