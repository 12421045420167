import { useState } from "react"
import Footer from "../../components/layouts/Footer";
import Header from "../../components/layouts/Header";

const TermsConditions = () => {

  const [iscontent, setIsContent] = useState(false);

  return (
    <>
      <div>
        <Header/>
        <div style={{ paddingLeft: "23%", marginTop: "100px", marginBottom: "24px", marginLeft: "-65%" }}>
          <a href="" style={{ fontsize: "12px", color: "#42526E" }}>Home</a>
          <a href="" style={{ fontsize: "12px", color: "#42526E" }}>Terms & Conditions</a>
        </div>
        <div className='css-1dbjc4n terms_conditions'>
          <div className='css-1dbjc4n r-xovx4i r-1xfd6ze r-bijgke'>
            <div className='css-1dbjc4n r-1awozwy r-6koalj r-eqz5dr'>
              <div className='css-1dbjc4n r-1uopjct r-145lgeb r-64el8z r-1kz6sp'>
                <div className='css-1dbjc4n r-1mlwlqe r-1uopjct r-145lgeb r-64el8z r-1udh08x r-1kz6sp r-417010 safety_guide'>
                  <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                  >
                    <img src="./assets/images/all/safety.png" alt="" />
                  </div>
                </div>
              </div>
              <div className='css-1dbjc4n r-7sv4c2'></div>
              <h1 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-1x35g6 r-13uqrnb r-b88u0q r-37tt59 r-1v456y7' style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Safety Guide
              </h1>
            </div>
          </div>
          <div className='css-1dbjc4n r-eu3ka'></div>
          <div className='css-1dbjc4n r-r3u836 r-1xfd6ze r-rs99b7 r-98loyc r-13uqrnb r-mlyl9t r-le9fof'>
            <div className='css-1dbjc4n r-1awozwy r-xovx4i r-1dzdj1l r-6koalj r-18u37iz r-98loyc r-13uqrnb r-117bsoe r-1oqcu8e' style={{ background: "#fff", padding: "0%", margin: "5px", display: "flex" }}>
              <div style={{ marginRight: "5px", marginTop: "-70px" }}>
                <img src="./assets/images/all/gpp_maybe.png" style={{ width: "15px" }} alt="" />
              </div>
              <div className='css-901oao r-1514gq1 r-98loyc r-1enofrn r-13uqrnb r-14yzgew r-1b5uinu r-puj83k r-11g3r6m r-1knelpx' style={{ margin: "5px", fontsize: "12px", fontWeight: "400" }}>
                Please read this document carefully. By accessing or using Mygoldenhomes, you agree to be bound by the terms and conditions set forth below.
                If you do not agree with any of these terms and conditions, you should not access or use Mygoldenhomes.<br /><br />
                If you have any questions about these terms, please contact <span className='css-901oao css-16my406 r-gi2tka r-1kfrs79'>legal@mygoldenhomes.com</span>
              </div>
            </div>
          </div>
          <div className='css-1dbjc4n'>
            <div className='css-1dbjc4n r-eu3ka'></div>
            <div className='css-1dbjc4n'>
              <h2 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-xd6kpl' style={{ fontSize: "20px", fontWeight: "600px", marginBottom: "5px", lineHeight: "32px" }}>Introduction</h2>
              <div className='css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb'>
                This website Mygoldenhomes.com , including any subdomains thereof, and any other websites through which its services are made available, our mobile, tablet and other smart device applications,
                and application program interfaces etc, , (hereinafter collectively referred to as "Mygoldenhomes") is owned, hosted and operated by Info Edge (India) Ltd (hereinafter referred to as IEIL),
                a company incorporated in India under the Companies Act, 1956 and having its registered office at Ground Floor, GF-12A, 94, Meghdoot, Nehru Place, New Delhi - 110 020.
                <span className='css-901oao css-16my406 r-1kfrs79'>These terms and conditions, privacy policy and community guidelines regulating use of these Services constitute a legally binding agreement between Mygoldenhomes and the User (the “Agreement”).</span>
              </div>
              <div className='css-1dbjc4n r-1vxqurs'></div>
              <div className='css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb'>
                Mygoldenhomes and/or any other website(s) linked to this website is an online information and communications service provided to you,
                <span className='css-901oao css-16my406 r-1kfrs79'>subject to your compliance with the terms and conditions set forth below.</span>
              </div>
              <div className='css-1dbjc4n r-1vxqurs'></div>
              <div className='css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb'>
                IEIL may amend/modify these terms and conditions at any time, and such modifications shall be effective immediately
                upon posting of the modified terms and conditions on Mygoldenhomes. You may review the modified terms and conditions periodically to be aware of such modifications and your continued access or use of Mygoldenhomes,
                shall be deemed conclusive proof of your acceptance of these terms and conditions, as amended/modified from time to time.
                <span className='css-901oao css-16my406 r-1kfrs79'>IEIL</span>
                may also suspend the operation of Mygoldenhomes for support or technical upgradation, maintenance work, in order to update the content or for any other reason.
              </div>
              <div className='css-1dbjc4n r-1vxqurs'></div>
              <div className='css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb'>
                If you utilize Mygoldenhomes in a manner inconsistent with these terms and conditions,
                <span className='css-901oao css-16my406 r-1kfrs79'>IEIL</span>
                may terminate your access, block your future access and/or seek such additional relief as the circumstances of your misuse may be deemed to be fit and proper.
              </div>
              <section>
                <div className='css-1dbjc4n r-1awozwy r-byiv6 r-1xfd6ze r-6koalj r-18u37iz r-98loyc r-13uqrnb r-1wtj0ep r-11rk87y r-gu0qjt r-85oauj r-fd4yh7 r-13qz1uu'>
                  <div className='css-901oao r-98loyc r-ubezar r-1kfrs79 r-135wba7'>Contents</div>
                  <div className='css-1dbjc4n r-fov093 r-1mlwlqe r-3da1kt r-1ijha98 r-zj9f58 r-1udh08x r-s5r7i3 r-1mcorv5 r-417010' onClick={e => setIsContent(!iscontent)}>
                    <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'></div>
                    {iscontent ?
                      <img src="./assets/images/all/arrow_up.png" alt="" />
                      :
                      <img src="./assets/images/all/arrow_down.png" alt="" />
                    }
                  </div>
                </div>
                {iscontent && (<>
                  <div className='css-1dbjc4n'>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>1.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Definitions</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>2.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Subsmission and administration of listings/advertisements</div>
                      </div>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu' style={{ paddingLeft: "36px" }}>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj' style={{ marginLeft: "20px" }}>2.1</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Payment Terms</div>
                      </div>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu' style={{ paddingLeft: "36px" }}>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj' style={{ marginLeft: "20px" }}>2.2</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Refund in failed transactions</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>3.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Rental property services terms</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>4.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Rental payment</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>5.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Rental agreement</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>6.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Tenant verification</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>7.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Video Community Guidelines</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>8.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Use of information</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>9.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Intellectual property rights</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>10.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Restriction/ Prohibitions</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>11.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Links to third party web sites</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>12.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Disclaimer and warranties</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>13.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Limitation of liability</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>14.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Termination</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>15.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Indemnification</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>16.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Privacy policy</div>
                      </div>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu' style={{ paddingLeft: "36px" }}>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj' style={{ marginLeft: "20px" }}>16.1</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Terms of use for users</div>
                      </div>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu' style={{ paddingLeft: "36px" }}>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj' style={{ marginLeft: "20px" }}>16.2</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Terms of conditions specific for dealers/ builders/ banks/Payment Gateways</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>17.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Arbitration</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>18.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Severability of provisions</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>19.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Waiver</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>20.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Governing law</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>21.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Jurisdiction</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>22.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Grievances</div>
                      </div>
                    </div>
                    <div className='css-1dbjc4n'>
                      <div className='css-1dbjc4n r-byiv6 r-hpenpt r-qklmqi r-6koalj r-18u37iz r-kzbkwu r-z7j6nc r-85oauj r-ttdzmv r-13qz1uu'>
                        <div className='css-901oao r-cqee49 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>23.</div>
                        <div className='css-901oao r-gi2tka r-1loqt21 r-98loyc r-ubezar r-13uqrnb r-16dba41 r-rjixqe r-1pcdyqj'>Amendment</div>
                      </div>
                    </div>
                  </div>
                </>)}
              </section>
              <div className='css-1dbjc4n'>
                <h2 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-xd6kpl' style={{ marginBottom: "10px", fontSize: "20px", fontWeight: "600" }}>Definitions</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  The term
                  <span className="css-901oao css-16my406 r-1kfrs79"> Subscriber </span>
                  would include any person, whether an individual or a legal entity who has subscribed to the Services of Mygoldenhomes (whether on a paid or free basis), and to whom the access to Mygoldenhomes is restricted by the use of a sign in user name and a password. The user name and password are either allotted by Mygoldenhomes or chosen by the Subscriber or agreed upon by Mygoldenhomes.
                  It is made abundantly clear that only the authorized User has the right to access the Services so offered by Mygoldenhomes.
                </div>
                <div className='css-1dbjc4n r-1vxqurs'></div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  The term Browser/Visitor will mean and include a person who utilizes any of the Services offered by Mygoldenhomes,
                  without the need or a requirement to create an account i.e. visits non-restricted portions of Mygoldenhomes.
                </div>
                <div className='css-1dbjc4n r-1vxqurs'></div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  The use and access to Mygoldenhomes shall be subject to these terms and conditions and community guidelines.
                  For the purposes of this Agreement, any person who does not have a legal or a contractual right to access the Services, but does so, will fall within the definition of an '
                  <span className="css-901oao css-16my406 r-1kfrs79">unauthorized user</span>
                  ' and will nevertheless be subject to the terms and conditions regulating the usage of Mygoldenhomes, and expressly so with respect to respecting the intellectual property rights of the IEIL, and abiding by terms and conditions below mentioned.
                </div>
                <div className='css-1dbjc4n r-1vxqurs'></div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  The terms '
                  <span className="css-901oao css-16my406 r-1kfrs79">User</span>' and '<span className="css-901oao css-16my406 r-1kfrs79"> Customer </span>
                  ' would include both the Subscriber/Advertiser(s) and Browser/Visitor(s).
                </div>
                <div className='css-1dbjc4n r-1vxqurs'></div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  The terms '
                  <span className="css-901oao css-16my406 r-1kfrs79">Service</span>' or '<span className="css-901oao css-16my406 r-1kfrs79">Services </span>
                  ' would mean to include the interactive online information service offered by Mygoldenhomes on the internet through which the user may access information carried by Mygoldenhomes in the database maintained by it. The terms would also include to mean the search tools through which the User can search through the hosted databases and information using a number of search tools that are present with a selection matching their search criteria. The term would also include services by way of space used by customers for advertisements such as listings and banners. Users then select one or more of the items presented to view the full document/ record.
                  The term Service does not extend to Mygoldenhomes acting as an agent either express or implied on behalf of any User/Customer and is merely acting as a medium of information exchange.
                </div>
                <div className='css-1dbjc4n r-1vxqurs'></div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  The term
                  <span className="css-901oao css-16my406 r-1kfrs79"> RERDA </span> shall mean and include the
                  <span className="css-901oao css-16my406 r-17iqy7s r-1kfrs79"> Real Estate (Regulation and Development) Act, 2016 </span>
                  (http://mhupa.gov.in/User_Panel/UserView.aspx?TypeID=1535) as amended read with any rules or regulations that might be framed thereunder.
                </div>
              </div>
              <div className='css-1dbjc4n r-1vxqurs'></div>
              <div className="css-1dbjc4n">
                <h2 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-xd6kpl' style={{ marginBottom: "10px", fontSize: "20px", fontWeight: "600" }}>Submission and administration of listings/advertisements</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  User agrees not to submit any property descriptions, photographs, financial, contact or other information contained in each property's data to Mygoldenhomes unless the
                  <span className="css-901oao css-16my406 r-1kfrs79">User submitting such a Listing/Advertisement</span>
                  has acquired and received all necessary rights and authorizations from the owner of such property or the power-of-attorney holder,
                  including from the photographer and/or copyright owner of any photographs, to publish and advertise the said Property(s) on the User's website or on Mygoldenhomes. Similarly, Mygoldenhomes does not take any ownership,
                  directly or indirectly towards any person whatsoever, with respect to banners hosted on its website by its customers, which are strictly in the nature of sale of space by 99acre & it has not carried out any independent verification on the authenticity or compliance requirements,
                  as may have been required under any law for the time being in force, of such images/ banners/ listings. Some listings may contain third party video content (“Video Content”) provided for general informational purposes only and such content does not constitute a recommendation or
                  solicitation or intends to influence any type of purchase or decision. No guarantees are made by Mygoldenhomes or the providers of the video content as to its accuracy or completeness. In case a User is covered under the RERDA, it shall obtain all requisite approvals, licenses and permit
                  s issued by the competent authorities with respect to the Project/Land/Apartment/Plot in the listing, by following the due process of law. Further, the User shall, at all times,
                  remain in compliance with all applicable Laws in relation to the Project/Land/Apartment/Plot in the listing. The User shall disclose all material information as required by
                  <span className="css-901oao css-16my406 r-1kfrs79">LMA</span>
                  and in respect of their registrations under RERDA and all such property(s) submitted including the present status and nature of such property and shall also disclose whether the property is free from all encumbrances or not.
                  The User shall disclose all material factual information as regarding the property being advertised, its correct dimensions as also in respect of their registrations under RERDA and all such property(s) submitted including the present status and nature of such property
                  and shall also disclose whether the property is free from all encumbrances or not. The User in addition shall furnish a brief background of such property in respect of title, ownership and possession, IEIL may also require the User to support his/her claims with respect
                  to the status of the property with such documents as may be specified by it from time to time. The User may further be required to substantiate his claims as to the nature and status of the property by swearing an affidavit stating the authenticity of the information/data
                  so provided/displayed. IEIL may, at its sole discretion but without any obligation to, search for such and remove properties that are alleged to have been submitted in violation of this provision. In addition, IEIL may require additional evidence of compliance with this
                  provision from Users who are alleged to have submitted properties or other information/data in violation of these terms and conditions. IEIL will, in its sole discretion, terminate the accounts of, and refuse Service to, any User who repeatedly or intentionally violates
                  these terms and conditions. Additionally, the User agrees to allow the Property listing, or any part of it, to be searched, displayed, accessed, downloaded, copied, and otherwise referred to by users of the User's website or the Mygoldenhomes website. IEIL shall have the sole
                  authority to choose the manner in which any Property will be searchable, displayed, accessed, downloaded, copied,
                  and otherwise used on Mygoldenhomes and IEIL shall have the right to modify the property listing in the exercise of its rights under these terms and conditions. In addition to the terms aforementioned the User agrees
                </div>
                <div className='css-1dbjc4n r-1vxqurs'></div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  (a) To represent and warrant that all Properties and associated information provided by the User will be accurate;
                  <br /><br />
                  (b) That the User will not permit the posting of a property on Mygoldenhomes under a name other than the name of a real estate agent who has been duly authorized and engaged by the owner of the property in this regard;
                </div>
                <div className='css-1dbjc4n r-1vxqurs'></div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  <span className="css-901oao css-16my406 r-1kfrs79">IEIL </span>
                  shall place the information relating to properties at defined sections of Mygoldenhomes or such other mirror or parallel site(s). IEIL reserves, in a manner consistent with reasonable commercial business practices,
                  the right to remove all or any part of the Properties posted on the User's website or on Mygoldenhomes without notice. IEIL accepts no responsibility for checking/verifying the accuracy of reports or data files submitted by the User.
                  While IEIL shall take all reasonable efforts for data backup and business resumption,
                  the User will be solely responsible for retaining back-up copies of all information, photographs and other materials furnished/submitted to Mygoldenhomes.
                </div>
                <div className='css-1dbjc4n r-1vxqurs'></div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  Users, who are buying/renting property/properties through Mygoldenhomes, must verify details the property/properties as well as its right, title, ownership, lien etc. on their own. The ‘verified’ tag visible on property/properties on Mygoldenhomes merely indicates the verification of the existence
                  of the property in the manner it has been advertised. At no point shall the ‘verified’ tag be construed to be a verification of any documentation, ownership details, area or pricing details of the property/properties.
                </div>
              </div>
              <div className='css-1dbjc4n r-1vxqurs'></div>
              <div className="css-1dbjc4n">
                <h3 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-xd6kpl" style={{ fontSize: "20px", fontWeight: "600px", marginBottom: "5px", lineHeight: "32px" }}>Payment Terms</h3>
                <div className="css-1dbjc4n r-eqz5dr">
                  <div className="css-1dbjc4n r-18u37iz">
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      Payments for the Services offered by Mygoldenhomes shall be on a 100% advance basis. The payment for Service once subscribed to by the subscriber is not refundable and any amount paid shall stand appropriated.
                      Refund if any will be at the sole discretion of Info Edge (India) Limited. IEIL offers no guarantees whatsoever for the accuracy or timeliness of the refunds reaching the Users card/bank accounts. User acknowledges and agrees that IEIL/Mygoldenhomes.com,
                      at its sole discretion and without prejudice to other rights and remedies that it may have under the applicable laws, shall be entitled to set off the amount paid or payable by a subscriber/user against any amount(s) payable by User to IEIL under any other agreement or commercial relationship towards other products/services.
                      Info Edge (India) Ltd gives no guarantees of server uptime or applications working properly. All is on a best effort basis and liability is limited to refund of amount only. Info Edge (India) Ltd. undertakes no liability for free Services. Info Edge (India) Ltd. reserves its right to amend / alter or change all or any disclaimers or
                      terms of agreements at any time without any prior notice. All terms / disclaimers whether specifically mentioned or not shall be deemed to be included if any reference is made to them.
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz">
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      Info Edge (India) Limited uses the maximum care as is possible to ensure that all or any data / information in respect of electronic transfer of money does not fall in the wrong hands.
                      For completing online transactions involving payments a user is directed to a Payment Gateway, Info Edge India Ltd does not store or keep credit card data. Since the transaction happens on a third-party network not controlled by Info Edge India Limited, once a credit card transaction has been completed,
                      the payment information is not accessible to anyone at Info Edge (India) Limited after completion of the on-line transaction at the Payment Gateway,
                      this ensures the maximum security.
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz">
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      Info Edge India Limited shall not be liable for any loss or damage sustained by reason of any disclosure (inadvertent or otherwise) of any information concerning the user's account and / or information relating to or
                      regarding online transactions using credit cards / debit cards and / or their verification process and particulars nor for any error,
                      omission or inaccuracy with respect to any information so disclosed and used whether or not in pursuance of a legal process or otherwise.
                    </div>
                  </div>
                </div>
              </div>
              <div className='css-1dbjc4n r-1vxqurs'></div>
              <div className="css-1dbjc4n">
                <h3 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-xd6kpl" style={{ marginBottom: "10px", fontSize: "20px", fontWeight: "600" }}>Refund in failed transactions</h3>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  Though IEIL payment reconciliation team works on a 24 x 7 basis, IEIL offers no guarantees whatsoever for the accuracy or timeliness of the refunds reaching the Subscribers card/bank accounts. This is on account of the multiplicity of organizations involved in processing of online transactions,
                  the problems with Internet infrastructure currently available and working days/holidays of financial institutions. Refunds in the event of wrong/objectionable property content being posted on the site would be at the discretion of Mygoldenhomes.
                </div>
                <div className="css-1dbjc4n r-6zzn7w"></div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  If you utilize Mygoldenhomes in a manner inconsistent with these terms and conditions, IEIL may terminate your access,
                  block your future access and/or seek such additional relief as the circumstances of your misuse may be deemed to be fit and proper.
                </div>
              </div>
              <div className='css-1dbjc4n r-eu3ka'></div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-xd6kpl" style={{ fontSize: "20px", fontWeight: "600px", marginBottom: "5px", lineHeight: "32px" }}>Rental property services terms</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  The Rental Property Services shall not be used for any purpose other than payment of or charging rent or for preparing and registering rental agreement and User not transmit any information received through the Rental Property Services to third parties.
                  All responsibility for information furnished and its accuracy shall reside upon the User, and the User shall be liable for any liability incurred and shall hold Mygoldenhomes or its affiliates and employees harmless against any claim or action by any person/financial institution/regulatory bodies.
                  By utilizing this serviceUser agrees that Mygoldenhomes may share the information provided to third parties for processing on the User’s behalf, as may be required from time to time.
                  User shall not submit any false or fraudulent information through any of the Services offered.
                </div>
              </div>
              <div className='css-1dbjc4n r-eu3ka'></div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-xd6kpl" style={{ fontSize: "20px", fontWeight: "600px", marginBottom: "5px", lineHeight: "32px" }}>Rental payment</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb" style={{ marginBottom: "25px" }}>
                  The Rental Property Services shall not be used for any purpose other than payment of or charging rent or for preparing and registering rental agreement and User not transmit any information received through the Rental Property Services to third parties.
                  All responsibility for information furnished and its accuracy shall reside upon the User, and the User shall be liable for any liability incurred and shall hold Mygoldenhomes or its affiliates and employees harmless against any claim or action by any person/financial institution/regulatory bodies.
                  By utilizing this serviceUser agrees that Mygoldenhomes may share the information provided to third parties for processing on the User’s behalf, as may be required from time to time.
                  User shall not submit any false or fraudulent information through any of the Services offered.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb" style={{ marginBottom: "25px" }}>
                  By making payment, User provides an authorization to Mygoldenhomes to hold, receive and disburse funds in accordance with the User’s payment instructions provided through Mygoldenhomes for the purposes of facilitating the transfer of money to the intended beneficiary as specified by the User.
                  In the event the processing of transaction is affected due to incomplete/incorrect details being furnished by the User, Mygoldenhomes reserves the right to deduct any applicable service charge, processing fees, or any other fees incurred by it in the processing of this transaction.
                  Mygoldenhomes or its affiliates and employees shall not be responsible for any incorrect/unintended transaction, in any form or manner.
                  Mygoldenhomes may, depending on the security or financial risk associated with the transaction, delay, suspend or reject a transaction.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb" style={{ marginBottom: "25px" }}>
                  The amount of a transaction may be charged back (hereinafter referred as “Chargeback”) if the transaction is disputed, reversed or not authorized, is unlawful or not in accordance with these terms
                  (e.g. the holder of the payment instrument used for initiating the transaction raises a dispute). In case of a Chargeback, User shall immediately pay any amount owed, including additional fines or penalties levied in this regard by the payment gateway or issuer.
                  User agrees to provide complete assistance in the event of any investigation in this regard.
                </div>
              </div>
              <div className='css-1dbjc4n r-eu3ka'></div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-xd6kpl" style={{ fontSize: "20px", fontWeight: "600px", marginBottom: "5px", lineHeight: "32px" }}>Rental agreement</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  By accepting these terms, the User and the property owner shall consent to provide any personal information that is uploaded by the User shall be so uploaded only after obtaining the requisite consents from the owner(s) of the personal information for such use.
                  The User shall be solely liable in this regard and for ensuring its accuracy and completeness,
                  as required for lawful and complete registration of rental agreement in the applicable jurisdictions, along with additional information as and when required.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  User agrees by using these Services, to bear all stamp duty charges or any other charges levied that may arise as per applicable law,
                  and shall not hold Mygoldenhomes or its affiliates and employees liable for any fines/penalties that may arise due to payment of insufficient charges.
                </div>
              </div>
              <div className='css-1dbjc4n r-eu3ka'></div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-xd6kpl" style={{ fontSize: "20px", fontWeight: "600px", marginBottom: "5px", lineHeight: "32px" }}>Tenant verification</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  User expressly permits all details shared for verification purposes, to be shared with Verification Partner (for the purposes of these terns,
                  “Verification Partner” shall mean any third party engaged for providing tenant verification services) including contact details for the purpose of availing the services. User is solely responsible for verifying the quality of Tenant Verification services offered by Verification Partner and Mygoldenhomes shall not be liable to any party for any Services provided by the Verification Partner.
                  User is responsible for obtaining express consent of the Lessee before sharing personal documents and information with the Verification Partner.
                </div>
              </div>
              <div className='css-1dbjc4n r-eu3ka'></div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-xd6kpl" style={{ fontSize: "20px", fontWeight: "600px", marginBottom: "5px", lineHeight: "32px" }}>Video Community Guidelines</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  <span className="css-901oao css-16my406 r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-117bsoe r-ub6tad">User agrees to comply with the following community guidelines while submitting video content for listings/advertisements:</span>
                  <div className="css-1dbjc4n r-xoduu5 r-eqz5dr">
                    <div className="css-1dbjc4n r-xoduu5 r-18u37iz">
                      <span className="css-901oao css-16my406 r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">•</span>
                      <span className="css-901oao css-16my406 r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Video should be original, free from any copyright issue
                      </span>
                    </div>
                    <div className="css-1dbjc4n r-xoduu5 r-18u37iz">
                      <span className="css-901oao css-16my406 r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">•</span>
                      <span className="css-901oao css-16my406 r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Video should not contain any personally identifiable information of the User/Agency
                      </span>
                    </div>
                    <div className="css-1dbjc4n r-xoduu5 r-18u37iz">
                      <span className="css-901oao css-16my406 r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">•</span>
                      <span className="css-901oao css-16my406 r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Video content should not be obscene/inappropriate
                      </span>
                    </div>
                    <div className="css-1dbjc4n r-xoduu5 r-18u37iz">
                      <span className="css-901oao css-16my406 r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">•</span>
                      <span className="css-901oao css-16my406 r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Video should not contain language which is detrimental to any community/segment
                      </span>
                    </div>
                  </div>
                </div>
                <div className="css-1dbjc4n r-6zzn7w"></div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-1kfrs79 r-37tt59 r-15d164r r-kc8jnq">
                  Video Content Screening
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-117bsoe r-ub6tad">
                  Videos shared by user shall go through multiple stages of screening (human and technology driven) before going live on the platform.
                  In case of violations with the community guidelines, the video content shall be duly removed from the listing.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  <span className="css-901oao css-16my406 r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">
                    Additionally, the video screening process shall also be carried out in the following cases:
                    <br />
                  </span>
                  <div className="css-1dbjc4n r-xoduu5 r-eqz5dr">
                    <div className="css-1dbjc4n r-xoduu5 r-18u37iz">
                      <span className="css-901oao css-16my406 r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">•</span>
                      <span className="css-901oao css-16my406 r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">User reporting the video to toll free/email ids</span>
                    </div>
                    <div className="css-1dbjc4n r-xoduu5 r-18u37iz">
                      <div className="css-1dbjc4n r-xoduu5 r-18u37iz">
                        <span className="css-901oao css-16my406 r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">•</span>
                        <span className="css-901oao css-16my406 r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">Infringement claim by agent/user</span>
                      </div>
                    </div>
                  </div>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-117bsoe r-ub6tad">
                    The video shall be removed from the platform in case the claim is found to be correct. Final discretion on video screening and removal shall always lie with Platform.
                  </div>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-117bsoe r-ub6tad">
                    Agents shall have the option to edit or remove videos during the course of the listing duration.
                  </div>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-117bsoe r-ub6tad">
                    Mygoldenhomes shall utilize 3rd party services such as You-tube for hosting and streaming these videos. While doing so, the site and the users on the site shall be compliant with respect to You-tube Terms of Service
                    <span className="css-901oao css-16my406 r-17iqy7s r-1loqt21 r-98loyc r-1b43r93 r-13uqrnb r-1kfrs79 r-hbpseb r-11c0sde"> https://www.youtube.com/t/terms </span>
                    and Youtube API Services - Developer Policies.
                  </div>
                </div>
                <div className='css-1dbjc4n r-eu3ka'></div>
                <div className="css-1dbjc4n">
                  <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-xd6kpl" style={{ fontSize: "20px", fontWeight: "600px", marginBottom: "5px", lineHeight: "32px" }}>Use of Information</h2>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-15zivkp">
                    User agrees to treat all information obtained from the Service, including listings, member directory,
                    and any information otherwise made available to User in the Service ("Content") as proprietary to Mygoldenhomes. User agrees that Content reserved for members will be maintained as confidential and shall be protected as a trade secret of Mygoldenhomes.
                    Mygoldenhomes does not ensure the accuracy of, endorse or recommend any Content and a User uses such Content at the User's own risk. A User may access the listings/advertisements in the Service solely to obtain initial information from which further evaluation and investigation may commence. User shall limit access to and use of listings to personal and internal use, and shall not use listings obtained from the Service for further distribution, publication,
                    public display, or preparation of derivative works or facilitate any of these activities in any way.
                  </div>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-15zivkp">
                    User shall not use or reproduce any Content that is obtained from the Service, or that is otherwise made available to User in the Service,
                    for or in connection with any other listing/advertising Service or device. User further shall not use the Service provided by the company in any other manner for or in connection with any other listing Service or device.
                    Users violating these specific terms, specifically those Users searching the Service in an abusive or excessive manner,
                    by automated or manual means, shall be subject to immediate termination of their membership without notice.
                  </div>
                </div>
                <div className='css-1dbjc4n r-eu3ka'></div>
                <div className="css-1dbjc4n">
                  <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-xd6kpl" style={{ fontSize: "20px", fontWeight: "600px", marginBottom: "5px", lineHeight: "32px" }}>Intellectual property rights</h2>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb" style={{ marginBottom: "25px" }}>
                    All logos, brands, trade marks and Service marks ("Marks") appearing in Mygoldenhomes are the properties either owned or used under license by IEIL and / or its associates.
                    All rights accruing from the same, statutory or otherwise, wholly vest with IEIL / its associates. The access to Mygoldenhomes does not confer upon the User any license or right to use in respect of these Marks and therefore the use of these Marks in any form or manner, whatsoever is strictly prohibited.
                    Any violation of the above would constitute an offence under the prevailing laws of India.
                  </div>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb" style={{ marginBottom: "25px" }}>
                    IEIL respects the Intellectual Property Rights of all, it has and will continue to adhere to all the laws applicable in India in this respect.
                    IEIL shall protect and respect the Intellectual Property Rights of the users as well as third parties to the best of its ability. In a case where a User(s) are found to be using Mygoldenhomes as a platform to infringe the Intellectual Property Rights of others,
                    IEIL will be free to terminate this Agreement forthwith without any notice to the user.
                  </div>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                    By allowing Users to access Mygoldenhomes, IEIL grants the Users a limited, non-exclusive,
                    non-assignable, revocable license (the "License") to access and use the Services,
                    provided that the User is in compliance with the terms and conditions of the Agreement.
                  </div>
                </div>
                <div className='css-1dbjc4n r-eu3ka'></div>
                <div className="css-1dbjc4n">
                  <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-xd6kpl" style={{ fontSize: "20px", fontWeight: "600px", marginBottom: "5px", lineHeight: "32px" }}>Restrictions/Prohibitions</h2>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-5oul0u" style={{ marginBottom: "10px" }}>
                    1. The following actions will inter alia constitute a misuse of Mygoldenhomes and are strictly prohibited:
                  </div>
                  <div className="css-1dbjc4n">
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">a.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Utilising the Services offered by Mygoldenhomes in any manner so as to impair the interests and functioning   of IEIL/Mygoldenhomes and which is non-compliant with laws and regulations including RERDA.
                      </div>
                    </div>
                  </div>
                  <div className="css-1dbjc4n">
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">b.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Copying, extracting, downloading, sharing, modifying, selling,
                        storing, distributing, making derivate works from or otherwise exploiting any content, data, information, including profiles, personal details, photographs and/or graphics, available on Mygoldenhomes and/or any services or products of the IEIL, in any manner or for any purpose which is not,
                        consistent with in accordance with the Terms of Use.
                      </div>
                    </div>
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-117bsoe r-ub6tad r-1ah4tor" style={{ paddingLeft: "33px" }}>
                      Users are expressly prohibited from using or exploiting Mygoldenhomes and/or any content or data provided therein for:
                    </div>
                    <div className="css-1dbjc4n r-eqz5dr">
                      <div className="css-1dbjc4n r-18u37iz" style={{ paddingLeft: "29px" }}>
                        <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                        <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                          Any commercial purposes such as creating alternate databases, extending access to Mygoldenhomes to third parties without prior written consent of the IEIL; and/or
                        </div>
                      </div>
                      <div className="css-1dbjc4n r-18u37iz" style={{ paddingLeft: "29px" }}>
                        <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                        <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                          Undertaking any business activity which is in competition with the business of IEIL; and/or
                        </div>
                      </div>
                      <div className="css-1dbjc4n r-18u37iz" style={{ paddingLeft: "29px" }}>
                        <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                        <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                          Sharing access with persons who are not contracted with the IEIL.
                        </div>
                      </div>
                      <div className="css-1dbjc4n r-18u37iz" style={{ paddingLeft: "29px" }}>
                        <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                        <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                          Reselling the products/services offered by the IEIL.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="css-1dbjc4n">
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">c.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Using or attempting to use any automated program, software or system or any similar or equivalent process (including spiders, robots, crawlers etc.) to access, navigate, search, copy, monitor, download, scrape, crawl or otherwise extract in any manner,
                        any data or content including but not limited to adding or downloading profiles, contact details, or send or redirect messages from Mygoldenhomes;
                      </div>
                    </div>
                  </div>
                  <div className="css-1dbjc4n">
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">d.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Gaining or attempting to gain unauthorized access (inter alia by hacking, password “mining” or any other means) to: (a) any portion or feature of Mygoldenhomes or any of the services or
                        products offered on or through Mygoldenhomes which are not intended for you; (b) any server, website, program or computer systems of the IEIL or any other third parties and/or Users;
                      </div>
                    </div>
                  </div>
                  <div className="css-1dbjc4n">
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">e.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Modifying the services provided through Mygoldenhomes or their appearance using any technology or overlay any additional offering on top of such services or simulate Mygoldenhomes’s
                        services or its functions in any manner whatsoever without explicit consent obtained in writing by approach us at
                        <span className="css-901oao css-16my406 r-17iqy7s r-1loqt21 r-vw2c0b" style={{ fontWeight: "bold" }}> Product@mygoldenhomes.com</span>
                      </div>
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-eqz5dr">
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">f.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Accessing Mygoldenhomes through interfaces other than those expressly provided by IEIL;
                      </div>
                    </div>
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">g.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Attempting to breach or breaching any security or authentication measures set up by the IEIL in relation to Mygoldenhomes and/or attempting to probe, scan or test the vulnerability of the IEIL’s system or network;
                      </div>
                    </div>
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">h.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Scraping, downloading (including bulk- downloading), replicating or otherwise extracting any information or data from Mygoldenhomes (by any process, whether automatic or manual) to offer any products or services which are similar to or may in any manner compete with the products or services of the IEIL;
                      </div>
                    </div>
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">i.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Reverse engineering, decompiling, disassembling, deciphering or otherwise attempting to do any of the aforesaid or deriving the source code for the Site or Application or any related technology or any part thereof;
                      </div>
                    </div>
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">j.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Circumventing or attempting to circumvent any technological protections used or employed by the IEIL or by any third party in order to protect the content on Mygoldenhomes and/or to exclude robots, spiders etc.
                        from crawling and /or scraping content from Mygoldenhomes.
                      </div>
                    </div>
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">k.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Bypassing or circumventing or trying to circumvent any service limits including but not limited to Search limits, Captcha limits and occurrences on different triggers
                      </div>
                    </div>
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">l.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Interfering with or disrupting or attempting to interfere with or disrupt (including by using any device, software or routine), the use of Mygoldenhomes or any computer networks connected to Mygoldenhomes, by any other User;
                      </div>
                    </div>
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">m.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Developing, using or attempting to use any automated program, scripts, robots, third party software or system or any similar or equivalent process (including spiders, robots, crawlers, browser plug-ins/extensions/add-ons, iframes on third party sites, mirroring, HTML parsers etc.)
                        to access, navigate, search, copy, monitor, download, scrape, crawl or otherwise extract or modify in any manner, any data or content from Mygoldenhomes without explicit consent obtained in writing by approach us at
                        <span class="css-901oao css-16my406 r-17iqy7s r-1loqt21 r-vw2c0b" style={{ fontWeight: "bold" }}> legal@mygoldenhomes.com</span>
                      </div>
                    </div>
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">n.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Impersonating any other person or entity, or making any misrepresentation as to your employment by or affiliation with any person or entity;
                      </div>
                    </div>
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">o.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Forging headers or in any manner manipulating identifiers in order to disguise the origin of any user information;
                      </div>
                    </div>
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">p.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Stalking, threatening, or in any manner harassing any other User;
                      </div>
                    </div>
                    <div className="css-1dbjc4n r-18u37iz">
                      <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">q.</div>
                      <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                        Imposing an unreasonable or disproportionately large load on Mygoldenhomes’ infrastructure;
                      </div>
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz">
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">r.</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      Engaging in “framing,” “mirroring,” or otherwise simulating the appearance or function of Mygoldenhomes (or any part thereof) and providing deep links into Mygoldenhomes (or any part thereof) without prior permission of IEIL;
                    </div>
                  </div>
                </div>
                <div className="css-1dbjc4n r-18u37iz">
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">s.</div>
                  <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                    Spamming Mygoldenhomes/IEIL or any other Users including by uploading, posting, emailing, SMS, transmitting or otherwise making available either directly or indirectly,
                    any unsolicited bulk e-mail or unsolicited commercial e-mail.
                  </div>
                </div>
              </div>
              <div className="css-1dbjc4n r-18u37iz">
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-1jkjb r-ub6tad r-1janqcz">t.</div>
                <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                  Hosting, modifying, uploading, posting, transmitting, publishing, or distributing any material or information that:
                </div>
              </div>
              <div className="css-1dbjc4n r-eqz5dr">
                <div className="css-1dbjc4n r-18u37iz" style={{ paddingLeft: "18px" }}>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                  <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                    Violates any applicable local, provincial, state, national or international law, statute, ordinance, rule or regulation for the time being in force;
                  </div>
                </div>
                <div className="css-1dbjc4n r-18u37iz" style={{ paddingLeft: "18px" }}>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                  <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                    Belongs to another person and to which you have no right;
                  </div>
                </div>
                <div className="css-1dbjc4n r-18u37iz" style={{ paddingLeft: "18px" }}>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                  <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                    Infringes, breaches or otherwise contravenes the rights of the IEIL or any third party, including any copyright, trademark, patent, rights of privacy or publicity or any other proprietary rights;
                  </div>
                </div>
                <div className="css-1dbjc4n r-18u37iz" style={{ paddingLeft: "18px" }}>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                  <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                    Contains computer viruses, or other computer code, files or programs designed to disrupt, destroy or interfere with or limit the functioning of Mygoldenhomes, or any other computer system or resource;
                  </div>
                </div>
                <div className="css-1dbjc4n r-18u37iz" style={{ paddingLeft: "18px" }}>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                  <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                    Is grossly harmful, harassing, invasive of another's privacy including bodily privacy, hateful, disparaging, relating to or encouraging money laundering or gambling in any manner, any content which is or may potentially be perceived as being harmful, threatening, abusive, harassing basis of gender, libelous, blasphemous, vulgar, pornographic, paedophilic, obscene, or racially, ethnically,
                    promoting enmity between different groups on the grounds of religion or caste with the intent to incite violence or otherwise unlawful in any manner whatsoever;
                  </div>
                </div>
                <div className="css-1dbjc4n r-18u37iz" style={{ paddingLeft: "18px" }}>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                  <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                    Which constitutes or encourages conduct that would constitute a criminal offence, give rise to other liability, or otherwise violate applicable law;
                  </div>
                </div>
                <div className="css-1dbjc4n r-18u37iz" style={{ paddingLeft: "18px" }}>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                  <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                    That deceives or misleads the addressee about the origin of such messages or communicates any Misinformation or information which is grossly offensive or menacing in nature;
                  </div>
                </div>
                <div className="css-1dbjc4n r-18u37iz" style={{ paddingLeft: "18px" }}>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                  <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                    That harms minors in any way;
                  </div>
                </div>
                <div className="css-1dbjc4n r-18u37iz" style={{ paddingLeft: "18px" }}>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                  <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                    That threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement
                    to the commission of any cognisable offence or prevents investigation of any offence or is insulting to any other nation.
                  </div>
                </div>
              </div>
              <div className='css-1dbjc4n r-eu3ka'></div>
              <div className="css-1dbjc4n">
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-117bsoe r-ub6tad" style={{ marginBottom: "5px" }}>
                  You hereby expressly agree not to:
                </div>
                <div className="css-1dbjc4n r-eqz5dr">
                  <div className="css-1dbjc4n r-18u37iz">
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">1.</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      Copy and distribute this information on any other server, or modify or re-use information, text, graphics, sound, etc.
                      on Mygoldenhomes to any other system. No reproduction of any part of Mygoldenhomes may be sold or distributed for commercial gain nor shall it be modified or incorporated in any other work,
                      publication or Mygoldenhomes, whether in hard copy or electronic format, including postings to any other Mygoldenhomes; or
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz">
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">2.</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      Remove any copyright, trade mark or other intellectual property notices contained in the original material from any material copied or printed off from Mygoldenhomes or link to Mygoldenhomes, without our express written consent.
                    </div>
                  </div>
                  <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">
                    Violations of system or network security may result in civil or criminal liability.
                  </div>
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-2o02ov r-xd6kpl" style={{ fontSize: "20px", fontWeight: "600px", marginBottom: "5px", lineHeight: "32px" }}>Links to third party web sites</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  Mygoldenhomes may provide links to other third-party World Wide Web sites or resources. IEIL makes no representations whatsoever about any other Web site you may access through Mygoldenhomes.
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-2o02ov r-xd6kpl" style={{ fontSize: "20px", fontWeight: "600px", marginBottom: "5px", lineHeight: "32px" }}>Disclaimer and warranties</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb">
                  Mygoldenhomes is an intermediary as defined under sub-clause (w) of Section 2 of the
                  <span className="css-901oao css-16my406 r-vw2c0b" style={{ fontWeight: "bold" }}> Information Technology Act, 2000.</span>
                </div>
                <div className="css-901oao r-98loyc r-1b43r93 r-13uqrnb r-hbpseb" style={{ fontWeight: "200", marginTop: "25px" }}>
                  THE CONTENT OF Mygoldenhomes IS PROVIDED "AS IS" AND ON AN "AS AVAILABLE" BASIS,
                  WITHOUT WARRANTIES OR REPRESENTATIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-1kfrs79 r-hbpseb" style={{ marginTop: "25px", paddingRight: "14px" }}>
                  IEIL and third party providing materials, services or content to this website, disclaims all warranties, express or implied, statutory or otherwise icluding,
                  but not limited to, implied warranties of merchantability, fitness for a partiular purpose, non-infringement of third party rights, completeness or accuracy of the information, update or correctness of the information,
                  freedom from computer viruses, other violation of rights regarding services, products, material and contents of Mygoldenhomes.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb" style={{ marginTop: "25px", paddingRight: "14px" }}>
                  Views expressed by the Users are their own, IEIL does not endorse the same and shall not be responsible for them.
                  No claim as to the accuracy and correctness of the information on the site is made although every attempt is made to ensure that the content is not misleading/ offensive/ inappropriate.
                  In case any inaccuracy is or otherwise improper content is sighted on the website, please report it to report abuse.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb" style={{ marginTop: "25px", paddingRight: "14px" }}>
                  It is solely your responsibility to evaluate the accuracy, completeness and usefulness of all opinions, advice, Services, real estate and other related information listed on the website.
                  IEIL does not warrant that the access to website will be uninterrupted or error-free or that defects in website will be corrected.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb" style={{ marginTop: "25px", paddingRight: "14px" }}>
                  IEIL offers no guarantee no warrantees that there would be satisfactory response or any response at all, once the listing/banner is put on display.
                  Any payments made to IEIL/Mygoldenhomes are solely for the purposes of display of the property advertised.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb" style={{ marginTop: "25px", paddingRight: "14px" }}>
                  Users are strongly advised to independently verify the authenticity of any Pre-Launch offers received by them. IEIL does not endorse investment in any projects which have not received official sanction and have not been launched by the Builder/Promoter,
                  users dealing in such projects shall be doing so entirely at their risk and responsibility.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb" style={{ marginTop: "25px", paddingRight: "14px" }}>
                  No information contained herein shall constitute an invitation or an offer to invest in IEIL or any of its Affiliates. Further,
                  nothing contained in Mygoldenhomes should be construed as a recommendation to use any product, process, equipment or formulation, in conflict with any patent, or otherwise and IEIL makes no representation or warranty
                  , express or implied that, the use thereof will not infringe any patent, or otherwise.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb" style={{ marginTop: "25px", paddingRight: "14px" }}>
                  Mygoldenhomes is controlled and operated from India and IEIL makes no representation that the materials are appropriate or will be available for use in other parts of the World. If you use Mygoldenhomes from outside India,
                  you are entirely responsible for compliance with all applicable local laws as well as international conventions and treaties.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb" style={{ marginTop: "25px", paddingRight: "14px" }}>
                  IEIL offers products, Services, content and various other functionalities through Mygoldenhomes and its affiliate sites to specific regions worldwide. The Services offered in one region may differ from those in other regions due to availability, local or regional laws or legal impediments and other considerations/factors. IEIL does not make any warranty or representation that a User in one region may also obtain the Services as provided in another region. Information on Mygoldenhomes may contain references to products, programs or Services that are not announced or available in your country.
                  Such references do not in any manner imply that IEIL intends to announce, launch or provide such products, programs or Services in your country.
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Limitation of liability</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px" }}>
                  IEIL will not be liable for any damages of any kind arising out of or relating to the use or the inability to use Mygoldenhomes,
                  its content or links, including but not limited to damages caused by or related to errors, omissions, interruptions, defects, delay in operation or transmission,
                  computer virus, line failure and all other direct, indirect, special, incidental, punitive, loss of profit, exemplary or consequential damages whether based on warranty, contract, tort or any other legal theory including Force Majeure, and whether or not,
                  such organizations or entities were intimated or advised of the possibility of such damages.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">
                  IEIL assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communication line failure, theft or destruction or unauthorized access to or alteration of User's data/information.
                  IEIL shall not be responsible for any problem or technical malfunction on-line-systems, servers or providers, computer equipment, software, failure of e-mail or players on account of technical problem or traffic congestion on the Internet or at any website or combination thereof,
                  including injury or damage to any User and/or Members or to any other person's computer related to or resulting from participating or downloading materials/information from the website.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">
                  For the purposes of the Consumer Protection Act, 2019 (CPA), the term Consumer will be limited to paid Customers who have not subscribed to Mygoldenhomes for purposes of commercial gain (business purposes) and their intent to utilize the services for individual use only.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">
                  Unless otherwise specified and notwithstanding anything contained in any other agreement or arrangement, by whatever name called, the performance obligation of IEIL (service provider) is to provide access of its on-line portal to the customer for the duration of the subscription period & reference to any usage, by whatever name called or any other performance obligation, if any, is to provide the upper limit for consumption, which by itself, does not create any additional performance obligation upon IEIL.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">
                  IEIL will not be liable on account of any inaccuracy of information on this web site. Any breach of privacy or of the information provided by the consumer to IEIL to be placed on the website by technical or any other means is not the responsibility of IEIL and IEIL does not guarantee confidentiality of information provided to it by any person acquiring/using all/any information displayed on Mygoldenhomes website or any of its other websites / domains owned and operated by IEIL.
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Termination</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px" }}>
                  IEIL may, without notice in its sole discretion, and at any time, terminate or restrict your use or access to Mygoldenhomes (or any part thereof) for any reason, including, without limitation, that IEIL based on its judgement and perception believes you have violated or acted inconsistently with the letter or spirit of these terms and conditions or any applicable law.
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Indemnification</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  Submissions and unauthorized use of any information(s)/materials contained on Mygoldenhomes may violate copyright laws, trademark laws, the laws of privacy and publicity, certain communications statutes and regulations thereto and other applicable laws, statutes and its rules and regulations. You alone are responsible for your actions or the actions of any person using your user name and/or password.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  You agree to defend, indemnify, and hold harmless, IEIL and/ or its associates, subsidiaries, their officers, directors, employees, affiliates, licensees, business partners and agents, from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, alleging or resulting from your use of Mygoldenhomes material or your breach of these terms and conditions or any applicable law.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  IEIL will not be party to any legal proceedings between parties contracted through these Services. In case IEIL is sought to implicated in any legal proceedings, costs will be recovered from the party that names IEIL as a party to such proceedings. IEIL shall abide with any court order served on it through due process.
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Privacy Policy</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  The Privacy Policy of the Platform explains how we may use your personal data, we will at all times respect and ensure adherence to the privacy policy, additionally various settings are provided to help you to be able to control the manner in which others may be able to view your information as chosen by you to be displayed on your profile and the manner in which you may have chosen to be contacted. Any feedback provided by a User shall be deemed as non-confidential to the user.
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Terms of use for Users</h2>
                <div className="css-1dbjc4n r-eqz5dr">
                  <div className="css-1dbjc4n r-18u37iz" style={{ marginBottom: "5px" }}>
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      When you indicate your interest in a Real Estate Listing or express interest in related services on Mygoldenhomes,
                      you authorize Mygoldenhomes to contact you (even though you may have registered with NDNC/DNC)
                      , and are sending your profile consisting of your personal details and application information including
                      relevant documents to Mygoldenhomes, and you are requesting and authorizing Mygoldenhomes to make available such
                      information contained in your response to the applicable Advertiser/seller(s) for such Real Estate Listing(s).
                      You provide consent for your data to be provided to such Advertisers/service providers and others ,
                      who may further contact you over the phone/email/whatsapp etc.
                      Please note that our privacy policy does not govern the use of your data by third parties once it is shared.
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz" style={{ marginBottom: "5px" }}>
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      In addition, by using Mygoldenhomes, you agree that Mygoldenhomes is not responsible for the content of the Advertiser/seller’s/broker’s/builder’s application form, messages, screener questions, testing assessments; required documents, or their format or method of delivery.
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz" style={{ marginBottom: "5px" }}>
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      You consent to your application, documents and any responses sent to you by the Advertiser/seller or vice versa through
                      Mygoldenhomes being processed and analyzed by Mygoldenhomes according to these terms of use and Mygoldenhomes’s Privacy Policy.
                      Mygoldenhomes shall store and process such information regardless of whether a Real Estate that had been advertised earlier
                      continues to remain available or not. Mygoldenhomes may use your application materials (including public profile consisting of
                      your personal details and responses to advertiser/seller’s questions) to determine whether you may be interested in a
                      Real Estate Listing, and Mygoldenhomes may reach out to you about such Real Estate Listing
                      (even though you may have registered with NDNC).
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz" style={{ marginBottom: "5px" }}>
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      Information you post in public areas of Mygoldenhomes sites or applications or make visible in your profile, may be accessed, used, and stored by others around the world, including those in countries that might not have legislation that guarantees adequate protection of personal information as defined by your country of residence. While Mygoldenhomes takes measures to safeguard your information from unauthorized access or inappropriate use, Mygoldenhomes does not control these third parties and we are not responsible for their use of information you give to us. Accordingly, you should not post sensitive information or any other information you would not want made public, to any portion of Mygoldenhomes or application or to a public website.
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz" style={{ marginBottom: "5px" }}>
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      Additionally, it shall be the sole responsibility of the user to ensure that it uses the ‘Opt-out’ options as it deems fit to debar / refuse access of the data fed by it, to dealers. Info Edge shall not be responsible for such insertions / data being accessed by its subscribers or users whose access has not been specifically blocked /debarred by the user while using the privacy settings.
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz" style={{ marginBottom: "5px" }}>
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      In order to use Mygoldenhomes, you may sign in using your Facebook/Google login. If you do so, you authorize us to access and use certain Facebook/Google account information, including but not limited to your public Facebook profile and posts. For more details regarding the information we collect from you and how we use it, please visit our Privacy Policy.
                    </div>
                  </div>
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Terms of conditions specific for dealers/ builders/ banks/Payment Gateways</h2>
                <div className="css-1dbjc4n r-eqz5dr">
                  <div className="css-1dbjc4n r-18u37iz" style={{ marginBottom: "5px" }}>
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      You will comply with all applicable Data Protection Laws in relation to the Processing of personal data; and not process personal data which is irrelevant, in an unlawful manner and excessive with regard to agreed purposes as defined in the privacy policy and these terms and conditions
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz" style={{ marginBottom: "5px" }}>
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      You shall implement adequate technical and organizational controls to protect the shared personal data obtained from Mygoldenhomes against unauthorized or unlawful processing and against accidental loss, destruction, damage, alteration or disclosure
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz" style={{ marginBottom: "5px" }}>
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      You agree to provide reasonable assistance as is necessary to facilitate the handling of any Data Security Breach (as applicable the privacy law as applicable) in an expeditious and compliant manner
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz" style={{ marginBottom: "5px" }}>
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      You agree that the responsibility for complying with a the request of an individual with respect to his/her personal data lies with the Party which holds/processes the personal data collected/shared
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz" style={{ marginBottom: "5px" }}>
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      You warrant and represent that the institution shall not disclose or transfer Personal Data obtained from Mygoldenhomes to any sub-processors without ensuring that adequate and equivalent safeguards to the Personal Data.
                    </div>
                  </div>
                  <div className="css-1dbjc4n r-18u37iz" style={{ marginBottom: "5px" }}>
                    <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad">•</div>
                    <div className="css-901oao r-1514gq1 r-13awgt0 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad r-f727ji r-1ah4tor">
                      You shall retain or process shared personal data for no longer than is necessary to carry out the agreed purposes.
                    </div>
                  </div>
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Arbitration</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  If any dispute arises between a user/users and IEIL arising out of use of the website or thereafter, in connection with the validity,
                  interpretation, implementation or alleged breach of any provision of these terms and conditions,
                  the dispute shall be referred to a sole Arbitrator who shall be an independent and neutral third party.
                  Decision of the Arbitrator shall be final and binding on both the parties to the dispute. The place of arbitration shall be Delhi
                  . The Arbitration & Conciliation Act, 1996, shall govern the arbitration proceedings.
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Severability of provisions</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  THIS AGREEMENT between you and IEIL governs your use of Mygoldenhomes.
                  If any provision of these Mygoldenhomes terms and conditions or part thereof is inconsistent with or is held to be
                  invalid/void by or under any law, rule, order or regulation of any Government or by the final adjudication of any court, such inconsistency or invalidity shall not affect the enforceability of any other provision of the terms and conditions.
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Waiver</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  The failure of IEIL to exercise or enforce any right or provision of the terms and conditions of use shall not constitute a waiver of its right to enforce such right or provision subsequently.
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Governing law</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  By accessing Mygoldenhomes you agree that the laws prevailing in India shall be the governing laws in all matters relating to Mygoldenhomes as well as these terms and conditions.
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Jurisdiction</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  Courts at New Delhi, India alone shall have the exclusive jurisdiction in all matters relating to Mygoldenhomes and these terms and conditions, irrespective of the territory and jurisdiction of your access to Mygoldenhomes.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  IEIL does not routinely monitor your postings to the web site but reserves the right to do so. However, if IEIL becomes aware of an inappropriate use of Mygoldenhomes or any of its Services, IEIL will respond in any way that, in its sole discretion, IEIL deems appropriate. You acknowledge that IEIL will have the right to report to law enforcement authorities any actions that may be considered illegal, as well as any information it receives of such illegal conduct.
                  When requested, IEIL will co-operate fully with law enforcement agencies in any investigation of alleged illegal activity on the Internet.
                </div>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  IEIL reserves all other rights.
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Grievances</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  In case you have any complaints and/or grievances in relation to any grievances, you can send your complaints via our grievance portal located at:
                </div>
                <div className="css-901oao r-17iqy7s r-1loqt21 r-98loyc r-1b43r93 r-13uqrnb r-1kfrs79 r-hbpseb r-11c0sde" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  https://www.Mygoldenhomes.com/load/Company/grievances?lstAcn=T&C&lstAcnId=0&src=FTR
                </div>
              </div>
              <div className="css-1dbjc4n">
                <h2 className="css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-13uqrnb r-1kfrs79 r-37tt59 r-5oul0u r-2o02ov r-xd6kpl">Amendment</h2>
                <div className="css-901oao r-1514gq1 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-hbpseb r-ub6tad" style={{ paddingRight: "14px", marginBottom: "25px" }}>
                  Please report any violations of these terms and conditions to IEIL at
                  <span className="css-901oao css-16my406 r-17iqy7s r-1loqt21 r-vw2c0b" style={{ fontWeight: "bold" }}> legal@mygoldenhomes.com</span>
                </div>
                <div className="css-901oao r-17iqy7s r-98loyc r-1b43r93 r-13uqrnb r-vw2c0b r-hbpseb r-11c0sde" style={{ fontWeight: "bold" }}>Effective from: 25th March 2021</div>
              </div>
            </div>
          </div>
        </div>
        <div className='css-1dbjc4n r-11c0sde'>
          <h4 className='css-4rbku5 css-901oao r-kze2mb r-98loyc r-adyw6z r-b88u0q r-vrz42v' style={{ fontWeight: "bold", marginBottom: "5px", fontWeight: "600", fontSize: "24px", justifyContent: "center", alignItems: "center", display: "flex" }}>Need to get in touch?</h4>
        </div>
        <div className='css-1dbjc4n r-6koalj r-18u37iz r-1777fci r-1kidu6m terms_conditionbottom_main'>
          <div className='css-1dbjc4n r-r3u836 r-1xfd6ze r-rs99b7 r-1rw7m1n r-1t2hasf r-1aminmm r-18f9sac r-1b3ntt7 terms_conditionbottom terms_conditionbottom_mainin'>
            <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-b88u0q r-waer2z'>TOLL FREE NUMBER</div>
            <div className='css-1dbjc4n r-1awozwy r-18u37iz'>
              <div className='css-1dbjc4n r-1hjwoze r-y3t9qe r-19qrga8 r-hudz2g r-ktdv3k r-12ym1je'>
                <div className='css-1dbjc4n r-1mlwlqe r-1hjwoze r-1udh08x r-12ym1je r-417010'>
                  <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                    style={{ backgroundImage: 'url(&quot;/universalhp/img/callIcon.shared.png&quot;)' }}></div>
                  <img src="/assets/images/all/callIcon.png" alt="" />
                </div>
              </div>
              <div className='css-1dbjc4n'>
                <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'>9 AM - 8 PM IST</div>
                <div className='css-901oao r-gi2tka r-98loyc r-ubezar r-13uqrnb r-1kfrs79 r-waer2z'>+91 90634 83711</div>
              </div>
            </div>
          </div>
          <div className='css-1dbjc4n r-r3u836 r-1xfd6ze r-rs99b7 r-1t2hasf r-1y8ork0 r-c9eks5 r-1b3ntt7 terms_conditions_mainin1'>
            <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-b88u0q r-waer2z terms' style={{ marginBottom: "5px" }}>INTERNATIONAL NUMBERS</div>
            <div className='css-1dbjc4n r-6koalj r-18u37iz terms_conditionbottom_maininner'>
              <div className='css-1dbjc4n r-1awozwy r-18u37iz' style={{ marginRight: "5px" }}>
                <div className='css-1dbjc4n r-1hjwoze r-y3t9qe r-19qrga8 r-hudz2g r-ktdv3k r-12ym1je' style={{ marginRight: "5px", marginLeft: "5px" }}>
                  <div className='css-1dbjc4n r-1mlwlqe r-1hjwoze r-1udh08x r-12ym1je r-417010'>
                    <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                      style={{ backgroundImage: 'url(&quot;/universalhp/img/callIcon.shared.png&quot;)' }}></div>
                    <img src="/assets/images/all/callIcon.png" alt="" />
                  </div>
                </div>
                <div className='css-1dbjc4n'>
                  <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'><div dir="auto" class="css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z">United Arab Emirates</div></div>
                  <div className='css-901oao r-gi2tka r-98loyc r-ubezar r-13uqrnb r-1kfrs79 r-waer2z'>1800-41-99099</div>
                </div>
              </div>
              <div className='css-1dbjc4n r-1awozwy r-18u37iz' style={{ marginRight: "5px" }}>
                <div className='css-1dbjc4n r-1hjwoze r-y3t9qe r-19qrga8 r-hudz2g r-ktdv3k r-12ym1je' style={{ marginRight: "5px", marginLeft: "5px" }}>
                  <div className='css-1dbjc4n r-1mlwlqe r-1hjwoze r-1udh08x r-12ym1je r-417010'>
                    <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                      style={{ backgroundImage: 'url(&quot;/universalhp/img/callIcon.shared.png&quot;)' }}></div>
                    <img src="/assets/images/all/callIcon.png" alt="" />
                  </div>
                </div>
                <div className='css-1dbjc4n'>
                  <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'>United States of America</div>
                  <div className='css-901oao r-gi2tka r-98loyc r-ubezar r-13uqrnb r-1kfrs79 r-waer2z'>1800-41-99099</div>
                </div>
              </div>
              <div className='css-1dbjc4n r-1awozwy r-18u37iz' style={{ marginRight: "5px" }}>
                <div className='css-1dbjc4n r-1hjwoze r-y3t9qe r-19qrga8 r-hudz2g r-ktdv3k r-12ym1je' style={{ marginRight: "5px", marginLeft: "5px" }}>
                  <div className='css-1dbjc4n r-1mlwlqe r-1hjwoze r-1udh08x r-12ym1je r-417010'>
                    <div className='css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw'
                      style={{ backgroundImage: 'url(&quot;/universalhp/img/callIcon.shared.png&quot;)' }}></div>
                    <img src="/assets/images/all/callIcon.png" alt="" />
                  </div>
                </div>
                <div className='css-1dbjc4n'>
                  <div className='css-901oao r-1wy1o97 r-98loyc r-1b43r93 r-13uqrnb r-16dba41 r-waer2z'>United Kingdom</div>
                  <div className='css-901oao r-gi2tka r-98loyc r-ubezar r-13uqrnb r-1kfrs79 r-waer2z'>1800-41-99099</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='css-1dbjc4n r-eu3ka'></div>
        <Footer/>
      </div >
    </>
  )
}

export default TermsConditions