import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="main-footer fl-wrap">
        <div className="footer-inner fl-wrap">
          <div className="container">
            <div className="row">
              {/* footer widget*/}
              <div className="col-md-4">
                <div className="footer-widget fl-wrap">
                  <div className="footer-widget-logo fl-wrap">
                    <img src="./assets/images/logo2.png" alt="" />
                  </div>

                  <div className="fw_hours fl-wrap">
                    <p>
                      "Discover your dream home on our website. Immerse in
                      high-res images, immersive tours. Explore diverse listings
                      with intuitive search. Benefit from expert neighborhood
                      insights. Trust in verified, curated properties. Your
                      journey to exceptional real estate starts here."
                    </p>
                    <span>
                      Monday - Friday:<strong> 8am - 6pm</strong>
                    </span>
                    <span>
                      Saturday - Sunday:<strong> 9am - 3pm</strong>
                    </span>
                  </div>
                </div>
              </div>
              {/* footer widget end*/}
              {/* footer widget*/}
              <div className="col-md-4">
                <div className="footer-widget fl-wrap">
                  <div className="footer-widget-title fl-wrap">
                    <h4>Helpful links</h4>
                  </div>
                  <ul className="footer-list fl-wrap">
                    <li>
                      <Link to="/aboutus">About Us</Link>
                    </li>
                    <li>
                    <Link to="/contactus">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/termsandconditions">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/feedback">Feedback</Link>
                    </li>
                    <li>
                      <Link to="/privacypolicy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/summonsnotices">Summons/Notices</Link>
                    </li>
                    <li>
                      <Link to="/Grievance">Grievances</Link>
                    </li>
                    <li>
                      <Link to="/safetyGuide">Safety Guide</Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* footer widget end*/}
              {/* footer widget*/}
              <div className="col-md-4">
                <div className="footer-widget fl-wrap">
                  <div className="footer-widget-title fl-wrap">
                    <h4>Contacts Info</h4>
                  </div>
                  <ul className="footer-contacts fl-wrap">
                    <li>
                      <span>
                        <i className="fal fa-envelope" /> Mail :
                      </span>
                      <a href="mailto:info@mygoldenhome.com">
                        info@mygoldenhome.com
                      </a>
                    </li>
                    <li>
                      <span>
                        <i className="fal fa-map-marker" /> Adress :
                      </span>
                      <Link to="" target="_blank">
                        Hyderabad
                      </Link>
                    </li>
                    <li>
                      <span>
                        <i className="fal fa-phone" /> Phone :
                      </span>
                      <Link to="">+91 9063483711</Link>
                    </li>
                  </ul>
                  <div className="footer-social fl-wrap">
                    <ul>
                      <li>
                        <Link to="" target="_blank">
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="" target="_blank">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="" target="_blank">
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link to="" target="_blank">
                          <i className="fab fa-vk" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* footer widget end*/}
              {/* footer widget*/}
              {/* <div className="col-md-3">
                <div className="footer-widget fl-wrap">
                  <div className="footer-widget-title fl-wrap">
                    <h4>Download our APP</h4>
                  </div>
                  <div className="api-links fl-wrap">
                    <p>
                      Start working with My Golden Homes that can provide
                      everything you need
                    </p>
                    <Link to="" className="api-btn color-bg">
                      <i className="fab fa-apple" /> App Store
                    </Link>
                    <Link to="" className="api-btn color-bg">
                      <i className="fab fa-google-play" /> Play Market
                    </Link>
                  </div>
                </div>
              </div> */}
              {/* footer widget end*/}
            </div>
          </div>
        </div>
        {/*sub-footer*/}
        <div className="sub-footer gray-bg fl-wrap">
          <div className="container">
            <div className="copyright">
            © 2023 Dexterous Technology | All rights reserved.
            </div>
            <div className="subfooter-nav">
              <ul className="no-list-style">
                <li>
                  <Link to="/termsandconditions">Terms of use</Link>
                </li>
                <li>
                  <Link to="/privacypolicy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="">Blog</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/*sub-footer end */}
      </footer>
    </>
  );
};

export default Footer;
