import React, { useState, useEffect, useRef } from "react";
import { BiSearch } from "react-icons/bi";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { Link } from "react-router-dom";

const LeadSearchModifyModel = () => {
  // const [minselected, setMinSelected] = useState(false);

  const [isactivecom, setIsActiveCom] = useState(false);
  const [isactivebed, setIsActiveBed] = useState(false);
  const [isactiveminvalue, setIsActiveMinvalue] = useState(false);
  const [isactivemaxvalue, setIsActiveMaxvalue] = useState(false);

  let menuRef = useRef();
  let bedmenuRef = useRef();
  let minvalRef = useRef();
  let maxvalRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setIsActiveCom(false);
        
      }
      if (!bedmenuRef.current.contains(e.target)) {
        setIsActiveBed(false);
      }
      if (!minvalRef.current.contains(e.target)) {
        setIsActiveMinvalue(false);
      }
      if (!maxvalRef.current.contains(e.target)) {
        setIsActiveMaxvalue(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [selectedMinValue, setSelectedMinValue] = useState("Min Price");

  const handleMinValueSelect = (value) => {
    setSelectedMinValue(value);
    setIsActiveMinvalue(false);
  };

  const [selectedMaxvalue, setSelectedMaxValue] = useState("Max Price");

  const handleMaxValueSelect = (value) => {
    setSelectedMaxValue(value);
    setIsActiveMinvalue(false);
  };

  return (
    <>
      <div
        className="lmsSearch__c lmsSearch__content lmsSearch__mTopFix "
        style={{ padding: "0px", marginTop: "0px" }}
      >
        <div
          className="lmsSearch__lmsSrchParent lmsSearch__normalState"
          //   style={{ border: "none" }}
        >
          <div
            className="lmsSearch__buyerSrch lmsSearch__sSrch undefined lmsSearch__mb10 lmsSearch__bSrch lmsSearch__box"
            style={{ display: "contents" }}
          >
            <div
              className="lmsSearch__searchPad"
              style={{
                marginBottom: "40px",
                marginTop: "40px",
                border: "none",
                // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                // borderRadius: "6px",
              }}
            >
              <div
                className="lmsSearch__lmsSearchPrefer"
                style={{ marginTop: "40px" }}
              >
                <span className="lmsSearch__f18 undefined">Residential</span>
                <div
                  className="lmsSearch__nnHtmlRadio lmsSearch__twoCtrl"
                  style={{ zIndex: "1" }}
                >
                  <div className="lmsSearch__f13 lmsSearch__simpleToggler">
                    <form action="">
                      <div className="radio_buttons">
                        <input
                          className="buy_radio"
                          type="radio"
                          value="Buy"
                          name="select"
                        />{" "}
                        Buy
                        <input
                          className="rent_radio"
                          type="radio"
                          value="Rent"
                          name="select"
                        />{" "}
                        Rent
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="lmsSearch__clr"
                style={{ marginTop: "25px" }}
              ></div>
              <div className="lmsSearch__LMSSearchCont">
                <form
                  action=""
                  className=" component__searchWrapper "
                  style={{
                    height: "100%",
                    width: "59%",
                    marginLeft: "33px",
                  }}
                >
                  <input
                    type="hidden"
                    name="preference"
                    id="preference"
                    value="S"
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="refSection"
                    id="referrer_section"
                    value="GNB"
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="budget_min"
                    id="budget_min"
                    value
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="budget_max"
                    id="budget_max"
                    value
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="searchform"
                    id="searchform"
                    value="1"
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="class"
                    id="class"
                    value
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="bedroom_num"
                    id="bedroom_num"
                    value
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="availability"
                    id="availability"
                    value
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="res_com"
                    id="res_com"
                    value="R"
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="property_type"
                    id="property_type"
                    value
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="situatedIn"
                    id="situatedIn"
                    value
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="businessType"
                    id="businessType"
                    value
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="seatTypes"
                    id="seatTypes"
                    value
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="planType"
                    id="planType"
                    value
                    autoComplete="off"
                  />
                  <input
                    type="hidden"
                    name="seatCountRng"
                    id="seatCountRng"
                    value
                    autoComplete="off"
                  />
                  <div></div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="component__showMenu">
                      <div
                        className="component__searchTag component__searchTag2"
                        style={{
                          whiteSpace: "initial",
                          zIndex: "10",
                          marginLeft: "0px",
                        }}
                      >
                        <div className="pageComponent component__searchInputWrapper component__noCityFilter">
                          <input
                            type="text"
                            name="keyword"
                            className="list_header_semiBold"
                            placeholder="Enter Locality / Project / Society / Landmark"
                          />
                        </div>
                        <div className="component__searchRight">
                          <span className="component__upside">|</span>
                          <i
                            className="component__iconSearch iconS_Common_24 icon_search"
                            style={{ cursor: "pointer" }}
                          >
                            <BiSearch />
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="component__heightCollapse ">
                    <div>
                      <ul className="component__autoSuggestion component__autoSuggSlideRemove"></ul>
                    </div>
                  </div>
                </form>
                <div
                  className="DropDownCheckBox__rel DropDownCheckBox__lf undefined undefined undefined undefined DropDownCheckBox__mr10 DropDownCheckBox__propertyTYpe"
                  ref={menuRef}
                >
                  <a
                    style={{ width: "230px" }}
                    id="DropDownCheckBox__property_type_wrap_a"
                    className="DropDownCheckBox__f13 DropDownCheckBox__dropDown DropDownCheckBox__rel DropDownCheckBox__selClick undefined"
                    onClick={(e) => setIsActiveCom(!isactivecom)}
                  >
                    <span className="DropDownCheckBox__apType">
                      All Residential
                    </span>
                    <span>|</span>
                    {isactivecom ? (
                      <i>
                        <AiOutlineCaretUp />
                      </i>
                    ) : (
                      <i>
                        <AiOutlineCaretDown />
                      </i>
                    )}
                  </a>
                  {isactivecom && (
                    <div
                      style={{ width: "230px", display: "block" }}
                      className="DropDownCheckBox__abs DropDownCheckBox__z2 DropDownCheckBox__r05 DropDownCheckBox__ulCont undefined undefined"
                    >
                      <div
                        style={{ height: "210px", clear: "both" }}
                        className="DropDownCheckBox__scrollbar1 DropDownCheckBox__scrollbar96 DropDownCheckBox__mti5"
                      >
                        <div
                          className="DropDownCheckBox__viewport"
                          style={{
                            overflow: "initial",
                            height: "200px",
                          }}
                        >
                          <div
                            className="DropDownCheckBox__overview"
                            style={{ top: "0px" }}
                          >
                            <ul>
                              <li>
                                <input type="checkbox" id="R" value="R" />
                                <label
                                  htmlFor=""
                                  className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                  for="R"
                                >
                                  <span className="DropDownCheckBox__selected">
                                    All Residential
                                  </span>
                                </label>
                              </li>
                              <ul>
                                <li>
                                  <input type="checkbox" id="1" value="1" />
                                  <label
                                    htmlFor=""
                                    className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                    for="1"
                                  >
                                    <span className="DropDownCheckBox__selected">
                                      Residential Apartment
                                    </span>
                                  </label>
                                </li>
                                <li>
                                  <input type="checkbox" id="4" value="4" />
                                  <label
                                    htmlFor=""
                                    className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                    for="4"
                                  >
                                    <span className="DropDownCheckBox__selected">
                                      Independent/Builder Floor
                                    </span>
                                  </label>
                                </li>
                                <li>
                                  <input type="checkbox" id="2" value="2" />
                                  <label
                                    htmlFor=""
                                    className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                    for="2"
                                  >
                                    <span className="DropDownCheckBox__selected">
                                      Independent House/Villa
                                    </span>
                                  </label>
                                </li>
                                <li>
                                  <input type="checkbox" id="3" value="3" />
                                  <label
                                    htmlFor=""
                                    className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                    for="3"
                                  >
                                    <span className="DropDownCheckBox__selected">
                                      Residential Land
                                    </span>
                                  </label>
                                </li>
                                <li>
                                  <input type="checkbox" id="90" value="90" />
                                  <label
                                    htmlFor=""
                                    className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                    for="90"
                                  >
                                    <span className="DropDownCheckBox__selected">
                                      1 RK/ Studio Apartment:
                                    </span>
                                  </label>
                                </li>
                                <li>
                                  <input type="checkbox" id="5" value="5" />
                                  <label
                                    htmlFor=""
                                    className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                    for="5"
                                  >
                                    <span className="DropDownCheckBox__selected">
                                      Farm House
                                    </span>
                                  </label>
                                </li>
                                <li>
                                  <input type="checkbox" id="22" value="22" />
                                  <label
                                    htmlFor=""
                                    className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                    for="22"
                                  >
                                    <span className="DropDownCheckBox__selected">
                                      Serviced Apartments:
                                    </span>
                                  </label>
                                </li>
                              </ul>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="lmsSearch__inFix pageComponent"
                style={{
                  marginLeft: "-5px",
                  height: "60px",
                  marginTop: "20px",
                }}
              >
                <div style={{ display: "inline-flex", float: "left" }}>
                  <div
                    style={{
                      display: "inline-flex",
                      position: "relative",
                    }}
                  >
                    <div
                      className="DropDownCheckBox__rel DropDownCheckBox__lf  undefined undefined undefined undefined DropDownCheckBox__mr10 DropDownCheckBox__propertyTYpe"
                      ref={minvalRef}
                    >
                      <a
                        style={{ width: "115px" }}
                        id="DropDownCheckBox__property_type_wrap_a"
                        className="DropDownCheckBox__f13 DropDownCheckBox__dropDown DropDownCheckBox__rel DropDownCheckBox__selClick undefined"
                        onClick={(e) => setIsActiveMinvalue(!isactiveminvalue)}
                      >
                        <span className="DropDownCheckBox__apType">
                          {selectedMinValue}
                        </span>
                        {isactiveminvalue ? (
                          <i>
                            <AiOutlineCaretUp />
                          </i>
                        ) : (
                          <i>
                            <AiOutlineCaretDown />
                          </i>
                        )}
                      </a>
                      {isactiveminvalue && (
                        <div
                          style={{ width: "115px", display: "block" }}
                          className="DropDownCheckBox__abs DropDownCheckBox__z2 DropDownCheckBox__r05 DropDownCheckBox__ulCont undefined undefined"
                        >
                          <div
                            style={{ height: "150px", clear: "both" }}
                            className="DropDownCheckBox__scrollbar2 DropDownCheckBox__scrollbar96 DropDownCheckBox__mti5"
                          >
                            <div
                              className="DropDownCheckBox__viewport dropdown_new_viewport"
                              style={{
                                overflow: "initial",
                                height: "150px",
                                overflowX: "hidden",
                              }}
                            >
                              <div
                                className="DropDownCheckBox__overview dropdown_new_overview"
                                style={{ top: "0px" }}
                              >
                                <ul>
                                  <li>
                                    <span className="DropDownCheckBox__selected">
                                      Min Price
                                    </span>
                                  </li>
                                  <ul>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("5 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        5 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("10 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        10 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("15 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        15 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("20 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        20 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("25 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        25 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("30 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        30 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("35 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        35 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("40 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        40 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("45 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        45 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("50 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        50 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("55 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        55 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("60 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        60 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("65 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        65 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("70 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        70 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("75 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        75 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("80 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        80 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("85 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        85 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("90 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        90 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("95 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        95 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("1 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        1 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("10 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        10 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("20 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        20 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("30 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        30 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("40 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        40 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("50 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        50 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("60 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        60 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("70 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        70 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("80 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        80 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("90 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        90 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("100 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        100 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMinValueSelect("100+ Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        100+ Crore
                                      </span>
                                    </li>
                                  </ul>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          color: "#666",
                        }}
                      >
                        To
                      </span>
                    </div>
                    <div
                      className="DropDownCheckBox__rel DropDownCheckBox__lf  undefined undefined undefined undefined DropDownCheckBox__mr10 DropDownCheckBox__propertyTYpe"
                      ref={maxvalRef}
                    >
                      <a
                        style={{ width: "115px" }}
                        id="DropDownCheckBox__property_type_wrap_a"
                        className="DropDownCheckBox__f13 DropDownCheckBox__dropDown DropDownCheckBox__rel DropDownCheckBox__selClick undefined"
                        onClick={(e) => setIsActiveMaxvalue(!isactivemaxvalue)}
                      >
                        <span className="DropDownCheckBox__apType">
                          {selectedMaxvalue}
                        </span>
                        {isactivemaxvalue ? (
                          <i>
                            <AiOutlineCaretUp />
                          </i>
                        ) : (
                          <i>
                            <AiOutlineCaretDown />
                          </i>
                        )}
                      </a>
                      {isactivemaxvalue && (
                        <div
                          style={{ width: "115px", display: "block" }}
                          className="DropDownCheckBox__abs DropDownCheckBox__z2 DropDownCheckBox__r05 DropDownCheckBox__ulCont undefined undefined"
                        >
                          <div
                            style={{ height: "150px", clear: "both" }}
                            className="DropDownCheckBox__scrollbar2 DropDownCheckBox__scrollbar96 DropDownCheckBox__mti5"
                          >
                            <div
                              className="DropDownCheckBox__viewport dropdown_new_viewport"
                              style={{
                                overflow: "initial",
                                height: "150px",
                                overflowX: "hidden",
                              }}
                            >
                              <div
                                className="DropDownCheckBox__overview dropdown_new_overview"
                                style={{ top: "0px" }}
                              >
                                <ul>
                                  <li>
                                    <span className="DropDownCheckBox__selected">
                                      Max Price
                                    </span>
                                  </li>
                                  <ul>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("5 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        5 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("10 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        10 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("15 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        15 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("20 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        20 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("25 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        25 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("30 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        30 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("35 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        35 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("40 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        40 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("45 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        45 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("50 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        50 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("55 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        55 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("60 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        60 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("65 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        65 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("70 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        70 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("75 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        75 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("80 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        80 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("85 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        85 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("90 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        90 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("95 Lacs")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        95 Lacs
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("1 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        1 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("10 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        10 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("20 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        20 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("30 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        30 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("40 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        40 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("50 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        50 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("60 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        60 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("70 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        70 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("80 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        80 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("90 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        90 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("100 Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        100 Crore
                                      </span>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleMaxValueSelect("100+ Cr")
                                      }
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        100+ Crore
                                      </span>
                                    </li>
                                  </ul>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      marginLeft: "15px",
                    }}
                  >
                    <div
                      className="DropDownCheckBox__rel DropDownCheckBox__lf  undefined undefined undefined undefined DropDownCheckBox__mr10 DropDownCheckBox__propertyTYpe"
                      ref={bedmenuRef}
                    >
                      <a
                        style={{ width: "143px" }}
                        id="DropDownCheckBox__property_type_wrap_a"
                        className="DropDownCheckBox__f13 DropDownCheckBox__dropDown DropDownCheckBox__rel DropDownCheckBox__selClick undefined"
                        onClick={(e) => setIsActiveBed(!isactivebed)}
                      >
                        <span className="DropDownCheckBox__apType">
                          Bedroom
                        </span>
                        <span>|</span>
                        {isactivebed ? (
                          <i>
                            <AiOutlineCaretUp />
                          </i>
                        ) : (
                          <i>
                            <AiOutlineCaretDown />
                          </i>
                        )}
                      </a>
                      {isactivebed && (
                        <div
                          style={{ width: "143px", display: "block" }}
                          className="DropDownCheckBox__abs DropDownCheckBox__z2 DropDownCheckBox__r05 DropDownCheckBox__ulCont undefined undefined"
                        >
                          <div
                            style={{ height: "150px", clear: "both" }}
                            className="DropDownCheckBox__scrollbar2 DropDownCheckBox__scrollbar96 DropDownCheckBox__mti5"
                          >
                            <div
                              className="DropDownCheckBox__viewport"
                              style={{
                                overflow: "initial",
                                height: "150px",
                                overflowX: "hidden",
                              }}
                            >
                              <div
                                className="DropDownCheckBox__overview"
                                style={{ top: "0px" }}
                              >
                                <ul>
                                  <li>
                                    <input type="checkbox" id="R" value="R" />
                                    <label
                                      htmlFor=""
                                      className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                      for="R"
                                    >
                                      <span className="DropDownCheckBox__selected">
                                        1 Bedroom
                                      </span>
                                    </label>
                                  </li>
                                  <ul>
                                    <li>
                                      <input type="checkbox" id="1" value="1" />
                                      <label
                                        htmlFor=""
                                        className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                        for="1"
                                      >
                                        <span className="DropDownCheckBox__selected">
                                          2 Bedrooms
                                        </span>
                                      </label>
                                    </li>
                                    <li>
                                      <input type="checkbox" id="4" value="4" />
                                      <label
                                        htmlFor=""
                                        className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                        for="4"
                                      >
                                        <span className="DropDownCheckBox__selected">
                                          3 Bedrooms
                                        </span>
                                      </label>
                                    </li>
                                    <li>
                                      <input type="checkbox" id="2" value="2" />
                                      <label
                                        htmlFor=""
                                        className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                        for="2"
                                      >
                                        <span className="DropDownCheckBox__selected">
                                          4 Bedrooms
                                        </span>
                                      </label>
                                    </li>
                                    <li>
                                      <input type="checkbox" id="3" value="3" />
                                      <label
                                        htmlFor=""
                                        className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                        for="3"
                                      >
                                        <span className="DropDownCheckBox__selected">
                                          5 Bedrooms
                                        </span>
                                      </label>
                                    </li>
                                    <li>
                                      <input
                                        type="checkbox"
                                        id="90"
                                        value="90"
                                      />
                                      <label
                                        htmlFor=""
                                        className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                        for="90"
                                      >
                                        <span className="DropDownCheckBox__selected">
                                          6 Bedrooms
                                        </span>
                                      </label>
                                    </li>
                                    <li>
                                      <input type="checkbox" id="5" value="5" />
                                      <label
                                        htmlFor=""
                                        className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                        for="5"
                                      >
                                        <span className="DropDownCheckBox__selected">
                                          7 Bedrooms
                                        </span>
                                      </label>
                                    </li>
                                    <li>
                                      <input
                                        type="checkbox"
                                        id="22"
                                        value="22"
                                      />
                                      <label
                                        htmlFor=""
                                        className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                        for="22"
                                      >
                                        <span className="DropDownCheckBox__selected">
                                          8 Bedrooms
                                        </span>
                                      </label>
                                    </li>
                                    <li>
                                      <input
                                        type="checkbox"
                                        id="22"
                                        value="22"
                                      />
                                      <label
                                        htmlFor=""
                                        className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                        for="22"
                                      >
                                        <span className="DropDownCheckBox__selected">
                                          9 Bedrooms
                                        </span>
                                      </label>
                                    </li>
                                    <li>
                                      <input
                                        type="checkbox"
                                        id="22"
                                        value="22"
                                      />
                                      <label
                                        htmlFor=""
                                        className="DropDownCheckBox__mr5 DropDownCheckBox__subCatLi"
                                        for="22"
                                      >
                                        <span className="DropDownCheckBox__selected">
                                          9+ Bedrooms
                                        </span>
                                      </label>
                                    </li>
                                  </ul>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <Link to="/LeadSearchNew">
                      <input
                        type="button"
                        name="Search"
                        value="Search"
                        style={{
                          width: "170px",
                          cursor: "pointer",
                          height: "43px",
                          marginLeft: "20px",
                          padding: "0",
                        }}
                        className="lmsSearch__inline lmsSearch__rf lmsSearch__btn lmsSearch__blue lmsSearch__resSrchBtn undefined lmsSearch__b lmsSearch__mr8 pageComponent"
                      />
                    </Link>
                    <Link to="">
                      <input
                        type="button"
                        name="Search"
                        value="Save Search"
                        style={{
                          width: "170px",
                          cursor: "pointer",
                          height: "43px",
                          marginLeft: "20px",
                          padding: "0",
                        }}
                        className="lmsSearch__inline lmsSearch__rf lmsSearch__btn lmsSearch__blue lmsSearch__resSrchBtn undefined lmsSearch__b lmsSearch__mr8 pageComponent"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadSearchModifyModel;
