import React from 'react'

const ExploreOurServices = () => {
  return (
    <>
      <div className='explore-container'>
        <div className='explore-head'>
          <p>My Golden Homes</p>
          <h1>Explore Our Services</h1>
        </div>
        <div className='explore-card'>
          <div className='row'>
            <div className='col-inner col-md-4'>
              <div className='inner-box-1'>
                <div className='col-img col-4'>
                  <img src='/assets/images/sliderImg/E11.jpg' alt=''></img>
                </div>
                <div className='col-text col-8'>
                  <h2>Buying a commercial property</h2>
                  <p>Shops, offices, land, factories, warehouses and more</p>
                  <hr className='hr' />
                </div>
              </div>
              <div className='inner-box-1'>
                <div className='col-img'>
                  <img src='/assets/images/sliderImg/E22.jpg' alt=''></img>
                </div>
                <div className='col-text'>
                  <h2>Renting a home</h2>
                  <p>Apartments, builder floors, villas and more</p>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-inner col-md-4'>
              <div className='inner-box-1'>
                <div className='col-img col-4'>
                  <img src='/assets/images/sliderImg/E33.jpg' alt=''></img>
                </div>
                <div className='col-text col-8'>
                  <h2>Leasing a commercial property</h2>
                  <p>Shops, offices, land, factories, warehouses and more</p>
                  <hr />
                </div>
              </div>
              <div className='inner-box-1'>
                <div className='col-img col-4'>
                  <img src='/assets/images/sliderImg/E44.jpg' alt=''></img>
                </div>
                <div className='col-text col-8'>
                  <h2>PG and co-living</h2>
                  <p>Organised, owner and broker listed PGs</p>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-inner col-md-4'>
              <div className='inner-box-1'>
                <div className='col-img col-4'>
                  <img src='/assets/images/sliderImg/E55.jpg' alt=''></img>
                </div>
                <div className='col-text col-8'>
                  <h2>Buy Plots/Land</h2>
                  <p>Residential Plots, Agricultral Farm lands, Inst. Lands and more</p>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExploreOurServices