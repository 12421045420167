import React, { useState, useEffect } from "react";
import {
  BiArrowBack,
  BiInfoCircle,
  BiChevronDown,
  BiPlus,
} from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { BsLightbulb } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";
import { HiOutlineLightBulb } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import HeaderNew from "../../components/layouts/HeaderNew";
import CircularProgress from "./componenets/CircularProgress";
import MainPostComponent from "./MainPostComponent";
import {
  setPostPropertyStepsStatus,
  setpropertyScooring,
} from "../../redux/property/PropertySlice";

const PostPropertyHome = () => {
  const {
    postPropertyStepsStatus,
    BasicDetails,
    LocationDetails,
    PropertyProfile,
    PhotoDetails,
    PriceotherDetails,
    AmentiesDetails,
    propertyScooring,
  } = useSelector((state) => state.Property);
  const [progress, setProgress] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      // Simulating progress update
      setProgress((prevProgress) =>
        prevProgress < 100 ? prevProgress + 10 : 0
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    dispatch(setpropertyScooring());
  }, []);

  const basicDetails = () => {
    if (postPropertyStepsStatus >= 0) {
      dispatch(setPostPropertyStepsStatus(0));
    }
  };
  const locationDetails = () => {
    if (postPropertyStepsStatus >= 1) {
      dispatch(setPostPropertyStepsStatus(1));
    }
  };
  const propertyProfile = () => {
    if (postPropertyStepsStatus >= 2) {
      dispatch(setPostPropertyStepsStatus(2));
    }
  };
  const photosChange = () => {
    if (postPropertyStepsStatus >= 3) {
      dispatch(setPostPropertyStepsStatus(3));
    }
  };
  const pricingOthers = () => {
    if (postPropertyStepsStatus >= 4) {
      dispatch(setPostPropertyStepsStatus(4));
    }
  };
  const amentiessection = () => {
    if (postPropertyStepsStatus >= 5) {
      dispatch(setPostPropertyStepsStatus(5));
    }
  };

  return (
    <>
      <HeaderNew />

      <div className="ppt_section_main">
        <div className="ppt_section_1">
          <div className="ppfDesktop_mainWrapper__k7Xv0 ppt_leftsection null">
            <div className="stepperSection_leftSection__3I9hL post_ppt_left">
              <div>
                <div className="stepperSection_stepper__1_WZM">
                  <div className="mobile_trackingmap">
                    <div className="mobile_trackingmap">
                      <div className="d-flex flex-row justify-content-between align-items-center align-content-center confirm mobile_trackingmap_nav">
                        <span className="d-flex justify-content-center align-items-center big-dot dot">
                          <i className="fa fa-check tick_color" />
                        </span>
                        <hr
                          className={
                            postPropertyStepsStatus >= 1
                              ? "flex-fill track-line track-line_m track-line1"
                              : "flex-fill track-line track-line_m track-line3"
                          }
                        />
                        <span
                          className={
                            postPropertyStepsStatus >= 1
                              ? "d-flex justify-content-center align-items-center big-dot dot"
                              : "d-flex justify-content-center align-items-center big-dot-empty dot"
                          }
                        >
                          <i className="fa fa-check tick_color" />
                        </span>
                        <hr
                          className={
                            postPropertyStepsStatus >= 2
                              ? "flex-fill track-line track-line_m track-line1"
                              : "flex-fill track-line track-line_m track-line3"
                          }
                        />
                        <span
                          className={
                            postPropertyStepsStatus >= 2
                              ? "d-flex justify-content-center align-items-center big-dot dot"
                              : "d-flex justify-content-center align-items-center big-dot-empty dot"
                          }
                        >
                          <i className="fa fa-check tick_color" />
                        </span>
                        <hr
                          className={
                            postPropertyStepsStatus >= 3
                              ? "flex-fill track-line track-line_m track-line1"
                              : "flex-fill track-line track-line_m track-line3"
                          }
                        />
                        <span
                          className={
                            postPropertyStepsStatus >= 3
                              ? "d-flex justify-content-center align-items-center big-dot dot"
                              : "d-flex justify-content-center align-items-center big-dot-empty dot"
                          }
                        >
                          <i className="fa fa-check tick_color" />
                        </span>
                        <hr
                          className={
                            postPropertyStepsStatus >= 4
                              ? "flex-fill track-line track-line_m track-line1"
                              : "flex-fill track-line track-line_m track-line3"
                          }
                        />
                        <span
                          className={
                            postPropertyStepsStatus >= 4
                              ? "d-flex justify-content-center align-items-center big-dot dot"
                              : "d-flex justify-content-center align-items-center big-dot-empty dot"
                          }
                        >
                          <i className="fa fa-check tick_color" />
                        </span>

                        {postPropertyStepsStatus === 5 ||
                        AmentiesDetails !== "" ? (
                          <>
                            <hr
                              className={
                                postPropertyStepsStatus >= 5
                                  ? "flex-fill track-line track-line_m track-line1"
                                  : "flex-fill track-line track-line_m track-line3"
                              }
                            />
                            <span
                              className={
                                postPropertyStepsStatus >= 5
                                  ? "d-flex justify-content-center align-items-center big-dot dot"
                                  : "d-flex justify-content-center align-items-center big-dot-empty dot"
                              }
                            >
                              <i className="fa fa-check tick_color" />
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="d-flex flex-row justify-content-between align-items-center mobile_trackingmap_navtext">
                        <div className="d-flex flex-column align-items-center track-line_n">
                          <span
                            className="track_head"
                            style={
                              postPropertyStepsStatus === 0
                                ? {
                                    cursor: "pointer",
                                    color: "#000",
                                    fontWeight: "600",
                                  }
                                : { cursor: "pointer" }
                            }
                            onClick={() => basicDetails()}
                          >
                            Basic Details
                          </span>
                          <div>
                            Step 1
                            {/* {BasicDetails !== "" ? (
                              <>
                                <span className="track_sub">Edit</span>
                              </>
                            ) : (
                              <></>
                            )} */}
                          </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center track-line_n">
                          <span
                            className="track_head"
                            style={
                              postPropertyStepsStatus === 1
                                ? {
                                    cursor: "pointer",
                                    color: "#000",
                                    fontWeight: "600",
                                  }
                                : { cursor: "pointer" }
                            }
                            onClick={() => locationDetails()}
                          >
                            Location Details
                          </span>
                          <div>
                            Step 2{" "}
                            {/* {LocationDetails !== "" ? (
                              <>
                                <span className="track_sub">Edit</span>
                              </>
                            ) : (
                              <></>
                            )} */}
                          </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center track-line_n">
                          <span
                            className="track_head"
                            style={
                              postPropertyStepsStatus === 2
                                ? {
                                    cursor: "pointer",
                                    color: "#000",
                                    fontWeight: "600",
                                  }
                                : { cursor: "pointer" }
                            }
                            onClick={() => propertyProfile()}
                          >
                            Property Profile
                          </span>
                          <div>
                            Step 3{" "}
                            {/* {PropertyProfile !== "" ? (
                              <>
                                <span className="track_sub">Edit</span>
                              </>
                            ) : (
                              <></>
                            )} */}
                          </div>
                        </div>
                        <div className="d-flex flex-column track-line_n">
                          <span
                            className="track_head"
                            style={
                              postPropertyStepsStatus === 3
                                ? {
                                    cursor: "pointer",
                                    color: "#000",
                                    fontWeight: "600",
                                  }
                                : { cursor: "pointer" }
                            }
                            onClick={() => photosChange()}
                          >
                            Photos
                          </span>
                          <div>
                            Step 4{" "}
                            {/* {PhotoDetails.length > 0 ? (
                              <>
                                <span className="track_sub">Edit</span>
                              </>
                            ) : (
                              <></>
                            )} */}
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-end track-line_n">
                          <span
                            className="track_head"
                            style={
                              postPropertyStepsStatus === 4
                                ? {
                                    cursor: "pointer",
                                    color: "#000",
                                    fontWeight: "600",
                                  }
                                : { cursor: "pointer" }
                            }
                            onClick={() => pricingOthers()}
                          >
                            Pricing & Others
                          </span>
                          <div>
                            Step 5{" "}
                            {/* {PriceotherDetails !== "" ? (
                              <>
                                <span className="track_sub">Edit</span>
                              </>
                            ) : (
                              <></>
                            )} */}
                          </div>
                        </div>

                        {postPropertyStepsStatus === 5 ||
                        AmentiesDetails !== "" ? (
                          <>
                            <div className="d-flex flex-column align-items-end track-line_n6">
                              <span
                                className="track_head"
                                style={
                                  postPropertyStepsStatus === 5
                                    ? {
                                        cursor: "pointer",
                                        color: "#000",
                                        fontWeight: "600",
                                      }
                                    : { cursor: "pointer" }
                                }
                                onClick={() => amentiessection()}
                              >
                                Amenties section
                              </span>
                              <div>
                                Step 6{" "}
                                {/* {AmentiesDetails !== "" ? (
                                  <>
                                    <span className="track_sub">Edit</span>
                                  </>
                                ) : (
                                  <></>
                                )} */}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="qualityScoreWidget_propertyScore__28KaJ">
                <div className="qualityScoreWidget_scoreGraph__3nVN7">
                  <div className="circular_progress" style={{ zIndex: "9" }}>
                    <CircularProgress percentage={propertyScooring} />
                  </div>
                </div>
                <div>
                  <div className="list_header_semiBold">Property Score</div>
                  <div className="caption_subdued_medium">
                    Better your property score, greater your visibility
                  </div>
                </div>
                <div className="stepperSection_srpiteLoadDiv__3ddGf"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="ppt_section_2">
          <div
            className="false ppfDesktop_formSection__ZzvPA ppt_section2_inner"
            style={{ marginTop: "40px", background: "" }}
          >
            <MainPostComponent />
          </div>
        </div>
        <div className="ppt_section_3">
          <div
            id="rightSection"
            className="rightSection_rightSection__36zjv"
            style={{ minHeight: "calc(100vh - 54px)" }}
          >
            <div className="sectionInfo_contentWrap__35b6q">
              <img
                src="./assets/images/all/Amenities.png"
                alt=""
                style={{ width: "160px" }}
              />
              <div
                className="sectionInfo_textSection__Zm4lg sectionInfo_textCenter__1-nk4"
                style={{ padding: "0px 50px 0px 50px" }}
              >
                <div className="caption_strong_large sectionInfo_subText__1alcp">
                  These are the features that buyers look for. Highlighting them
                  attracts more responses.
                </div>
              </div>
            </div>
            <div className="needHelp_helpBox__1b_kG" id="helpBox">
              <div className="needHelp_needHelpWrap__1eIlT">
                <div className="needHelp_helpHead__2mtNV">
                  <i className="iconS_PPFDesk_16 icon_callIcon">
                    <IoMdCall />
                  </i>
                  <div className="caption_subdued_large">Need help ? </div>
                </div>
                <div className="caption_subdued_medium capsub_footer">
                  You can email us at
                  <a className="hyperlinks_small">
                    {" "}
                    service@mygoldenhomes.com{" "}
                  </a>
                  or call us at
                  <a className="hyperlinks_small"> 1800 41 99099</a>
                  (IND Toll-Free).
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostPropertyHome;
