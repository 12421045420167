import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  userViewPropertyAll: [],

  userViewResponse: [],
  userViewRespond: [],

  viewPropertyLoading: false,
  userViewPropertyLoading: false,
};

export const getUserViewProperty = createAsyncThunk(
  "UserViewProperty/getUserViewProperty",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/viewed/all`;
      const resp = await axios(url);
      return resp.data.viewedProperty;
    } catch (error) {
      return thunkAPI.rejectWithValue("ViewedProperty Not Found");
    }
  }
);

export const userViewPropertyPost = createAsyncThunk(
  "UserViewProperty/userViewPropertyPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/viewed/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("UserViewProperty Not create");
    }
  }
);

export const getuserViewPropertyBYId = createAsyncThunk(
  "UserViewProperty/getuserViewPropertyBYId",
  async (id, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/viewed/responsebypostuser/${id}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Response User Not Found");
    }
  }
);

const UserViewPropertySlice = createSlice({
  name: "UserViewProperty",
  initialState,
  reducers: {
    viewdStatuschange(state, action) {
      state.userViewResponse = state.userViewResponse.filter(
        (view) => view._id !== action.payload._id
      );
      state.userViewRespond = [...state.userViewRespond, action.payload];
    },
  },
  extraReducers: {
    [userViewPropertyPost.pending]: (state) => {
      state.userViewPropertyLoading = true;
    },

    [userViewPropertyPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.userViewPropertyAll = [
          ...state.userViewPropertyAll,
          action.payload.userResponseByPostUser,
        ];
        // state.userViewResponse = state.userViewPropertyAll.filter(
        //   (view) => view.leadsView === false
        // );
        // state.userViewRespond = state.userViewPropertyAll.filter(
        //   (view) => view.leadsView === true
        // );
      }
      state.userViewPropertyLoading = false;
    },

    [userViewPropertyPost.rejected]: (state, action) => {
      state.userViewPropertyLoading = true;
    },

    //get
    [getuserViewPropertyBYId.pending]: (state) => {
      state.viewPropertyLoading = true;
    },
    [getuserViewPropertyBYId.fulfilled]: (state, action) => {
      state.userViewPropertyAll = action.payload.userResponseByPostUser;
      state.userViewResponse = state.userViewPropertyAll.filter(
        (view) => view.leadsView === false
      );
      state.userViewRespond = state.userViewPropertyAll.filter(
        (view) => view.leadsView === true
      );

      state.viewPropertyLoading = false;
    },
    [getuserViewPropertyBYId.rejected]: (state, action) => {
      state.viewPropertyLoading = true;
    },
  },
});
export const { viewdStatuschange } = UserViewPropertySlice.actions;
export default UserViewPropertySlice.reducer;
